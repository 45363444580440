/* eslint-disable @typescript-eslint/naming-convention */
import Papa from 'papaparse'

import { AnimalFilterProps } from 'app/core/types/animal'
import {
  DiagnosticAnimal,
  DiagnosticCreateData,
  DiagnosticCreateRequestData,
  DiagnosticProps,
  DiagnosticReadResponseData,
  DiagnosticReportResponse,
  DiagnosticResponseData,
  DiagnosticTypeResponse,
  GetDiagnosticsFilters,
  UpdateDiagnosticInputs,
} from 'app/core/types/breeding'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/reproductive_diagnostics/'

const postCreateDiagnostic = async ({
  date,
  responsible,
}: DiagnosticCreateRequestData): Promise<DiagnosticProps> => {
  const response = await http.post(`${baseUrl}group/`, {
    date,
    responsible,
    diagnostics: [],
  })
  return response.data
}

const getDiagnostics = async (
  filters: GetDiagnosticsFilters,
  page?: number,
  size?: number
): Promise<DiagnosticReadResponseData> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getDiagnosticsDetails = async (
  id: number,
  filters?: Partial<AnimalFilterProps>
): Promise<DiagnosticProps> => {
  const response = await http.get(`${baseUrl}${id}/`, {
    params: { ...removeEmptyFilters(filters) },
  })

  return response.data
}

const postAddDiagnostic = async (
  groupId: number,
  payload: DiagnosticCreateData[]
): Promise<DiagnosticResponseData> => {
  const response = await http.post(
    `${baseUrl}${groupId}/add_diagnostics/`,
    payload
  )

  return response.data
}

const patchUpdateDiagnostic = async ({
  groupId,
  diagnosticId,
  ...payload
}: UpdateDiagnosticInputs): Promise<DiagnosticAnimal> => {
  const response = await http.patch(
    `${baseUrl}${groupId}/${diagnosticId}/update/`,
    payload
  )
  return response.data
}

const deleteDiagnostic = async (
  groupId: number,
  diagnosticId: number
): Promise<void> => {
  const response = await http.delete(`${baseUrl}${groupId}/${diagnosticId}/`)

  return response.data
}

const deleteGroupDiagnostic = async (groupId: number): Promise<void> => {
  const response = await http.delete(`${baseUrl}${groupId}/`)

  return response.data
}

const getDiagnosticType = async (
  animalId: number,
  diagnosticDate: string
): Promise<DiagnosticTypeResponse> => {
  const response = await http.get(`${baseUrl}type/`, {
    params: {
      animal_id: animalId,
      diagnostic_date: diagnosticDate,
    },
  })
  return response.data
}

const getDiagnosticsReport = async (
  filters: GetDiagnosticsFilters,
  page?: number,
  size?: number
): Promise<DiagnosticReportResponse[]> => {
  const response = await http.get(`${baseUrl}report`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })

  const diagnostics: DiagnosticReportResponse[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    diagnostics.push({
      diagnostic_group_id: Number(item[0]),
      diagnostic_group_date: new Date(item[1]),
      responsible: item[2],
      animal_id: Number(item[3]),
      stock_number: item[4],
      electronic_eartag: item[5],
      birth_number: item[6],
      diagnostic_id: Number(item[7]),
      diagnostic_status: item[8] === 'True',
      diagnostic_date: new Date(item[9]),
      diagnostic_type: item[10],
      insemination_date: new Date(item[11]),
      gestation_days: Number(item[12]),
      birth_forecast: new Date(item[13]),
    })
  }

  return diagnostics
}

export {
  deleteDiagnostic,
  deleteGroupDiagnostic,
  getDiagnosticType,
  getDiagnostics,
  getDiagnosticsDetails,
  patchUpdateDiagnostic,
  postAddDiagnostic,
  postCreateDiagnostic,
  getDiagnosticsReport,
}
