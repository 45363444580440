import React, { FunctionComponent } from 'react'

import classNames from 'classnames'

import { Typography, TypographyVariant } from 'components/atoms'
import { Status as InputStatus } from 'components/enums/status'
import { IInputProps } from 'components/types/input'

import styles from './styles.module.scss'

export interface ILabeledInputProps {
  className?: string
  input: React.ReactElement<IInputProps>
  label: string
  status: InputStatus
  helperText?: string
  htmlFor?: string
  /**
   * When label is empty, this flag can be used to create to create space to keep inputs aligned
   */
  emptyLabelSpacing?: boolean
}

const LabeledInput: FunctionComponent<ILabeledInputProps> = ({
  className,
  input,
  label,
  status,
  helperText,
  htmlFor,
  emptyLabelSpacing,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <label
        htmlFor={htmlFor}
        className={styles.label}
        aria-labelledby={htmlFor}
      >
        {!label && emptyLabelSpacing && (
          <span className={styles.emptyLabelSpacing} />
        )}

        <Typography
          status={status}
          text={label}
          variant={TypographyVariant.label}
        />
      </label>
      <span className={styles.container}>{input}</span>
      {helperText ? (
        <span
          className={classNames(styles.helperText, styles[InputStatus[status]])}
        >
          {helperText}
        </span>
      ) : null}
    </div>
  )
}

export { LabeledInput, InputStatus }
