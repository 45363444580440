import { Dispatch, SetStateAction } from 'react'

const handleInputFilter = (
  setFilters: Dispatch<SetStateAction<Record<string, string>>>,
  filterName: string,
  filterValue: string
): void => {
  const value = filterValue && filterValue !== 'all' ? filterValue : null

  setFilters(previousFilters => {
    return Object.assign(previousFilters, {
      [filterName]: value,
    })
  })
}

const removeEmptyFilters = (
  object?: Record<string, unknown>,
  optionalFields: string[] = []
): Record<string, unknown> => {
  if (!object) {
    return {}
  }

  let cleanFilters = {}

  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null && 'value' in value) {
      cleanFilters = {
        ...cleanFilters,
        [key]: (value as { value: unknown }).value,
      }
    } else if (value || optionalFields.includes(key)) {
      cleanFilters = { ...cleanFilters, [key]: value }
    }
  })

  return cleanFilters
}

export { handleInputFilter, removeEmptyFilters }
