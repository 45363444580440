import React from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

import classNames from 'classnames'

import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { FontSize } from 'components/enums'

import styles from './styles.module.scss'

export interface ICustomDrawerProps {
  isOpen: boolean
  onClose: () => void
  direction?: 'left' | 'right' | 'top' | 'bottom'
  size?: number | string
  title?: string
  className?: string
  scrollableDrawer?: boolean
  reset?: () => void
}

const CustomDrawer: React.FC<ICustomDrawerProps> = ({
  isOpen,
  onClose,
  direction = 'right',
  size = '75vw',
  title,
  className,
  scrollableDrawer,
  children,
  reset,
}) => {
  return (
    <Drawer
      duration={100}
      open={isOpen}
      onClose={onClose}
      direction={direction}
      size={size}
      className={classNames(
        styles.drawerContainer,
        scrollableDrawer ? styles.scrollableDrawer : '',
        className
      )}
    >
      <div className={styles.drawerClose}>
        <Icon name={IconNames.close} onClick={onClose} />
      </div>
      <div className={styles.drawerTitleWrapper}>
        {title && (
          <Typography
            text={title}
            variant={TypographyVariant.h4}
            className={styles.drawerTitle}
          />
        )}
        {reset && (
          <Typography
            text="Resetar filtros"
            variant={TypographyVariant.link}
            className={styles.resetButton}
            fontSize={FontSize.extrasmall}
            onClick={(): void => reset()}
          />
        )}
      </div>
      {children}
    </Drawer>
  )
}

export { CustomDrawer }
