import { ColumnDef } from '@tanstack/react-table'

import { Chip, SelectItemProps } from 'components/atoms'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { DiagnosticRow } from 'app/core/types/breeding'
import { dateTimeFormat } from 'app/core/utils'

export const DiagnosticColumnsFeedback = (): ColumnDef<
  Partial<DiagnosticRow>
>[] => [
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Data do diagnóstico',
    accessorKey: 'diagnostic_date',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue() as string
      return <span>{dateTimeFormat(value)}</span>
    },
  },
  {
    header: 'Diagnóstico',
    accessorKey: 'status',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue() as SelectItemProps
      if (!value) return <span>-</span>
      return <Chip label={value.label as string} selected={false} />
    },
  },
  {
    header: 'Data da inseminação',
    accessorKey: 'insemination_date',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Tipo do diagnóstico',
    accessorKey: 'type',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Dias de gestação',
    accessorKey: 'gestation_days',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Previsão de parto',
    accessorKey: 'birth_forecast',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
]
