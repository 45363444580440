import React from 'react'

import { Chip, ChipColorVariant } from 'components/atoms'
import { Table, TableActionCell } from 'components/organisms'
import { Column } from 'components/organisms/table/types'
import { CHIP_SEX } from 'components/templates/animal-layout/consts'

import { AnimalListProps } from 'app/core/types/animal'
import { dateTimeFormat } from 'app/core/utils'

type AnimalTableColumn<AnimalListProps> = {
  header?: JSX.Element | string
  renderRowCell?: (row: AnimalListProps) => JSX.Element
}

const cellEmptyState = (value: string | JSX.Element): JSX.Element => {
  return <span>{value || '-'}</span>
}

export const AnimalTable: React.FC<{
  animals: AnimalListProps[]
  isLoading: boolean
}> = ({ animals, isLoading }) => {
  const navigateTo = (animalId: number): void => {
    window.open(`/animal/${animalId}/`, '_blank')
  }

  const columns: AnimalTableColumn<AnimalListProps>[] = [
    {
      header: 'Nº de nascimento',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          {cellEmptyState(row.birth_number)}
        </TableActionCell>
      ),
    },
    {
      header: 'Nº de plantel',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          {cellEmptyState(row.stock_number)}
        </TableActionCell>
      ),
    },
    {
      header: 'Brinco eletrônico',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          {cellEmptyState(row.electronic_eartag?.toString())}
        </TableActionCell>
      ),
    },
    {
      header: 'Nascimento',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          {cellEmptyState(dateTimeFormat(row.birth_date as string))}
        </TableActionCell>
      ),
    },
    {
      header: 'Sexo do animal',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          <Chip colorVariant={CHIP_SEX[row.sex as string]} />
        </TableActionCell>
      ),
    },
    {
      header: 'Setor',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          <Chip label={row.sector_name as string} selected={false} />
        </TableActionCell>
      ),
    },
    {
      header: 'Último parto',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          {row.last_calving
            ? cellEmptyState(dateTimeFormat(row.last_calving))
            : '-'}
        </TableActionCell>
      ),
    },
    {
      header: 'Tipo do último parto',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          <Chip label={row.calving_type} />
        </TableActionCell>
      ),
    },
    {
      header: 'Próximo parto',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          {row.upcoming_calving
            ? cellEmptyState(dateTimeFormat(row.upcoming_calving))
            : '-'}
        </TableActionCell>
      ),
    },
    {
      header: 'Status',
      renderRowCell: (row): JSX.Element => (
        <TableActionCell onClick={(): void => navigateTo(row.id)}>
          <Chip
            colorVariant={
              row.is_active
                ? ChipColorVariant.statusActive
                : ChipColorVariant.statusInactive
            }
          />
        </TableActionCell>
      ),
    },
  ]

  return (
    <Table
      data={animals}
      idPropertyName="id"
      columns={columns as Column[]}
      isHeaderSticky={true}
      isLoading={isLoading}
    />
  )
}
