import { CoreRouter } from 'app/core/routes'

import ErrorBoundary from './error-boundary'

const App = (): JSX.Element => {
  return (
    <ErrorBoundary displayMessage="An unexpected error occurred">
      <CoreRouter />
    </ErrorBoundary>
  )
}

export default App
