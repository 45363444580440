import React, { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { BreedingGroupReadResponseData } from 'app/core/types/breeding'
import { breedingGroupIdFormat } from 'app/core/utils'

import styles from './styles.module.scss'

type BreedingGroupHistoryProps = {
  groups?: BreedingGroupReadResponseData
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  femaleToMaleProportion: number
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  isLoading: boolean
  handleDeleteModalClose: () => void
  handleDeleteModalConfirm: () => Promise<void>
  deleteBreedingGroupId: number | undefined
  setDeleteBreedingGroupId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  isLoadingExport: boolean
  onExportClick: () => void
  onCreateClick: () => void
  activeFilters: number
  onDetailsClick: (groupId: number) => void
  onResetClick: () => void
  form: FormType
  breedingStations: SelectItemProps[]
  farms: SelectItemProps[]
  sectors: SelectItemProps[]
}

const BreedingGroupHistoryTemplate: React.FC<BreedingGroupHistoryProps> = ({
  groups,
  currentPage,
  setCurrentPage,
  femaleToMaleProportion,
  isDrawerOpen,
  handleToggleDrawer,
  isLoading,
  handleDeleteModalClose,
  handleDeleteModalConfirm,
  deleteBreedingGroupId,
  setDeleteBreedingGroupId,
  isLoadingExport,
  onExportClick,
  onCreateClick,
  activeFilters,
  onDetailsClick,
  onResetClick,
  form: Form,
  breedingStations,
  farms,
  sectors,
}) => {
  const animalProportionHint = (males: number): string =>
    `A proporção entre fêmeas e machos é de ${femaleToMaleProportion}:1.\nÉ necessário adicionar um total de ${
      femaleToMaleProportion * males
    } fêmeas ou remover machos para corrigir.`

  return (
    <>
      <BoxedLayout
        title="Grupos de Repasse"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
        headerLargeButtonLabel="Criar"
        headerLargeButtonAction={onCreateClick}
        currentPage={currentPage}
        totalPages={groups?.pages}
        setCurrentPage={setCurrentPage}
        headerButtonLabel="Exportar"
        headerButtonIcon={<Icon name={IconNames.download} />}
        headerButtonAction={onExportClick}
        headerButtonLoading={isLoadingExport}
      >
        {withLoading(
          <>
            <DataCounter
              counter={groups?.total}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div className={styles.groupsContainer}>
              {groups?.items.map((group, i) => (
                <ExpandCard
                  id={breedingGroupIdFormat(group.id)}
                  expandable={false}
                  title={group.name}
                  initDate={group.init_date}
                  endDate={group.final_date}
                  animalsMale={group.males}
                  animalsFemale={group.females}
                  animalsProportion={
                    group.females / group.males === femaleToMaleProportion
                  }
                  animalsProportionHint={animalProportionHint(group.males)}
                  breedingStation={group.breeding_station_name}
                  isActive={group.is_active}
                  navigation={(): void => onDetailsClick(group.id)}
                  key={`group-${i}`}
                  {...(isAdmin() && {
                    secondaryButtonAction: (): void =>
                      setDeleteBreedingGroupId(group.id),
                    secondaryButtonLabel: 'Excluir',
                    secondaryButtonIcon: IconNames.close,
                  })}
                />
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={onResetClick}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            <Form.InputText
              label="Período de Nascimento"
              name="init_date"
              htmlType="date"
            />

            <Form.InputText
              emptyLabelSpacing
              name="final_date"
              htmlType="date"
            />
          </DrawerRow>

          <DrawerRow>
            {farms && (
              <DrawerCol>
                <Form.Select label="Fazenda" name="farm_id" options={farms} />
              </DrawerCol>
            )}
            {sectors && (
              <DrawerCol>
                <Form.Select label="Setor" name="sector_id" options={sectors} />
              </DrawerCol>
            )}
            {breedingStations && (
              <DrawerCol>
                <Form.Select
                  label="Estação de monta"
                  name="breeding_station_id"
                  options={breedingStations}
                />
              </DrawerCol>
            )}
          </DrawerRow>

          <DrawerRow>
            <Form.InputText
              label="ID Grupo de Repasse"
              name="breeding_group_id"
              placeholder="Pesquisar nº ID do Grupo de Repasse"
              htmlType="number"
              min={1}
            />
            <DrawerCol>
              <Form.InputText
                label="Nome do Grupo de Repasse"
                name="breeding_group_name"
                placeholder="Pesquisar nome do Grupo de Repasse"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              label="Aplicar"
              type={ButtonType.primary}
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={!!deleteBreedingGroupId}
        handleCloseModal={handleDeleteModalClose}
        modalHeader={`Excluir Grupo de Repasse`}
        modalText={`Você tem certeza que deseja excluir este grupo de repasse?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteModalConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModalClose}
      />
    </>
  )
}

export { BreedingGroupHistoryTemplate }
