/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table'
import { useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  InputText,
  Select,
  SelectItemProps,
} from 'components/atoms'

import { HandledAnimalProps, HandlingTypes } from 'app/core/types/handling'
import { IProductProps, PRODUCT_QUANTITY_UNIT } from 'app/core/types/products'
import { calcAverageDailyGain, onlyAlphanumericString } from 'app/core/utils'

import { getAnimal } from './helpers'
import styles from './styles.module.scss'

const handlingEditableColumns = (
  handlingType: HandlingTypes,
  handlingDate: string,
  productsDroplist: SelectItemProps[]
): Partial<ColumnDef<HandledAnimalProps>> => {
  const columns: Partial<ColumnDef<HandledAnimalProps>> = {
    cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
      const initialValue = getValue()

      const { birth_weight, birth_date, average_daily_gain } = original

      const [value, setValue] = useState(initialValue)
      const [count, setCount] = useState<number | undefined>(
        original.product_count
      )

      const hasStoredProducts =
        original.product && Object.keys(original.product).length

      const productFilteredList = original.product
        ? productsDroplist.filter(
            e =>
              !Object.keys(original.product as IProductProps).includes(e.label)
          )
        : productsDroplist

      const onBlur = useCallback(
        (event): void => {
          if (event.target.value === '') {
            return
          }

          if (id === 'current_weight') {
            const avg = calcAverageDailyGain(
              birth_weight as number,
              value as number,
              birth_date as string,
              handlingDate
            )

            table.options.meta?.updateData(index, 'average_daily_gain', avg)
          }

          table.options.meta?.updateData(index, id, value)
        },
        [birth_date, birth_weight, id, index, table.options.meta, value]
      )

      const handleProductCount = (): void => {
        const maxCount = 10

        const hasCountLimit = count && count <= maxCount
        const isLastProductEmpty =
          count && original.product && !Object.keys(original.product)[count - 1]

        if (hasCountLimit && !isLastProductEmpty) {
          setCount(prevState => (prevState ? prevState + 1 : 1))
          table.options.meta?.updateData(
            index,
            'product_count',
            (count as number) + 1
          )
        }
      }

      const onProductNameSelect = (
        selectedProductName: string,
        productInputIndex: number
      ): void => {
        const hasSelectedProduct =
          original.product && Object.keys(original.product)[productInputIndex]

        const currentProducts = (): IProductProps => {
          if (hasStoredProducts && !hasSelectedProduct) {
            return { ...original.product, [selectedProductName]: null }
          }

          if (hasStoredProducts && hasSelectedProduct) {
            const allProducts = Object.entries(
              original.product as IProductProps
            ).map(([name, quantity], i) => {
              if (i === productInputIndex) {
                return [selectedProductName, quantity || null]
              }
              return [name, quantity]
            })

            const updatedProductObj = Object.fromEntries(allProducts)
            return updatedProductObj
          }

          return { [selectedProductName]: null }
        }

        table.options.meta?.updateData(index, 'product', currentProducts())
      }

      const onProductQuantitySelect = useCallback(
        (selectedProductQuantity: string, productInputIndex: number): void => {
          if (selectedProductQuantity === '') {
            return
          }

          const selectedProductName = Object.keys(
            original.product as IProductProps
          )[productInputIndex]

          const currentProducts = (): IProductProps => {
            const allProducts = Object.entries(
              original.product as IProductProps
            ).map(([name, quantity], i) => {
              if (i === productInputIndex) {
                return [selectedProductName, selectedProductQuantity]
              }
              return [name, quantity]
            })

            const updatedProductObj = Object.fromEntries(allProducts)
            return updatedProductObj
          }

          table.options.meta?.updateData(index, 'product', currentProducts())
        },
        [index, original.product, table.options.meta]
      )

      const onProductUnitSelect = (
        selectedProductUnit: string,
        productInputIndex: number
      ): void => {
        const selectedProduct = Object.entries(
          original.product as IProductProps
        )[productInputIndex]

        const selectedProductName = selectedProduct[0]
        const selectedProductQuantity = selectedProduct[1]

        const currentProducts = (): IProductProps => {
          const allProducts = Object.entries(
            original.product as IProductProps
          ).map(([name, quantity], i) => {
            if (i === productInputIndex) {
              return [
                selectedProductName,
                `${selectedProductQuantity}${selectedProductUnit}`,
              ]
            }
            return [name, quantity]
          })

          const updatedProductObj = Object.fromEntries(allProducts)
          return updatedProductObj
        }

        table.options.meta?.updateData(index, 'product', currentProducts())
      }

      const handleProductDelete = (productInputIndex: number): void => {
        const { product } = original
        const productName = product && Object.keys(product)[productInputIndex]

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [productName as string]: _, ...updatedProduct } =
          product as Record<string, string>

        table.options.meta?.updateData(index, 'product', updatedProduct)

        if (count && count > 1) {
          setCount(prevState => (prevState ? prevState - 1 : prevState))
          table.options.meta?.updateData(index, 'product_count', count - 1)
        }
      }

      const handleDisabled = (id: string): boolean => {
        const isAddedFromFilter = original.added_from_filter === true

        if (handlingType !== HandlingTypes.first_handling) {
          return (
            (id === 'electronic_eartag' || id === 'birth_number') &&
            isAddedFromFilter
          )
        }

        return id === 'birth_number' && isAddedFromFilter
      }

      useEffect(() => {
        setValue(initialValue)
      }, [initialValue])

      const filterInputArray: (keyof HandledAnimalProps)[] = [
        'birth_number',
        ...(handlingType === HandlingTypes.first_handling
          ? []
          : ['electronic_eartag']),
      ]
      const numberInputArray: (keyof HandledAnimalProps)[] = [
        'electronic_eartag',
        'current_weight',
        'product_quantity',
      ]

      if (filterInputArray.includes(id)) {
        return (
          <span className={styles.input}>
            <InputText
              name={id}
              onBlur={(e): Promise<void> =>
                getAnimal(index, id, e.currentTarget.value, table)
              }
              defaultValue={value as string}
              className={styles.tableInput}
              placeholder={'Filtrar um animal existente'}
              htmlType={id === 'electronic_eartag' ? 'number' : undefined}
              disabled={handleDisabled(id)}
            />
          </span>
        )
      }

      if (id === 'average_daily_gain') {
        return (
          <span
            className={average_daily_gain ? styles.avgNumber : styles.avgEmpty}
          >
            {average_daily_gain || '0.00'}
            {' kg'}
          </span>
        )
      }

      if (id === 'product_name') {
        return (
          <div className={styles.productNameWrapper}>
            <span className={styles.productNameList}>
              {count &&
                [...Array(count)].map((_, index) => {
                  const currentProductName = original.product
                    ? Object.keys(original.product)[index]
                    : undefined

                  const canRemove = count > 1 || currentProductName

                  return (
                    <span className={styles.productNameInput}>
                      <Select
                        id={id}
                        name={id}
                        options={productFilteredList}
                        onChange={(value): void =>
                          onProductNameSelect(
                            (value as SelectItemProps).value,
                            index
                          )
                        }
                        centerText
                        value={
                          Array(
                            productsDroplist.find(
                              e => e.label === currentProductName
                            )
                          ) as SelectItemProps[]
                        }
                      />
                      {canRemove && (
                        <Icon
                          name={IconNames.close}
                          onClick={(): void => handleProductDelete(index)}
                        />
                      )}
                    </span>
                  )
                })}
            </span>
            <span className={styles.productNameButtonWrapper}>
              <Button
                label={'Adicionar'}
                icon={<Icon name={IconNames['add-circle']} />}
                onClick={handleProductCount}
                size={ButtonSize.small}
                type={ButtonType.ghost}
              />
            </span>
          </div>
        )
      }

      if (id === 'product_quantity') {
        return (
          <div className={styles.productQuantityWrapper}>
            {count &&
              [...Array(count)].map((_, index) => {
                return (
                  <span
                    className={classNames(
                      styles.input,
                      styles.productQuantityList
                    )}
                  >
                    <InputText
                      name={id}
                      onBlur={(e): void =>
                        onProductQuantitySelect(
                          e.currentTarget.value as string,
                          index
                        )
                      }
                      defaultValue={
                        onlyAlphanumericString(
                          String(
                            Object.values(original.product as IProductProps)[
                              index
                            ]
                          )
                        ) || undefined
                      }
                      className={styles.tableInput}
                      placeholder={'000'}
                      htmlType={'number'}
                    />
                    <Select
                      id={id}
                      name={id}
                      options={PRODUCT_QUANTITY_UNIT}
                      onChange={(value): void =>
                        onProductUnitSelect(
                          (value as SelectItemProps).value,
                          index
                        )
                      }
                      centerText
                      value={
                        Array(
                          PRODUCT_QUANTITY_UNIT.find(
                            e =>
                              e.value ===
                              Object.values(original.product as IProductProps)[
                                index
                              ]?.replace(/\d+/, '')
                          )
                        ) as SelectItemProps[]
                      }
                    />
                  </span>
                )
              })}
          </div>
        )
      }

      return (
        <span className={styles.input}>
          {id === 'stock_number'}
          <InputText
            name={id}
            onChange={(e): void => setValue(e.target.value)}
            defaultValue={value as string}
            onBlur={onBlur}
            className={styles.tableInput}
            placeholder={'Insira aqui'}
            htmlType={numberInputArray.includes(id) ? 'number' : undefined}
            disabled={handleDisabled(id)}
          />
          {id === 'current_weight' && ' kg.'}
        </span>
      )
    },
  }
  return columns
}

export { handlingEditableColumns }
