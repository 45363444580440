import { Card, Typography, TypographyVariant } from 'components/atoms'
import { Col, InfoCard, Row } from 'components/templates/animal-layout/helpers'

import {
  AnimalEvaluations as AnimalEvaluationsType,
  EvaluationProps,
} from 'app/core/types/evaluation'
import { dateTimeFormat, formatWeight } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type AnimalEvaluationsProps = {
  isLastEvaluation?: boolean
  evaluations?: EvaluationProps[]
}

const Evaluation: React.FC<{
  evaluation: EvaluationProps
  isLastEvaluation?: boolean
}> = ({ evaluation, isLastEvaluation }) => {
  const evaluationCol = (): JSX.Element => (
    <Col>
      <Typography
        text="Avaliação"
        variant={TypographyVariant.caption3}
        altColor
      />
      <Typography text={evaluation.evaluation} variant={TypographyVariant.p} />
    </Col>
  )

  const hasEvaluationText = evaluation.evaluation

  return (
    <>
      <Row className={styles.animalEvaluationsRow}>
        <Col>
          <Typography
            text="Data"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Typography
            text={dateTimeFormat(evaluation.date)}
            variant={TypographyVariant.p}
          />
        </Col>

        <Col>
          <Typography
            text="Nota"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Typography
            text={AnimalEvaluationsType[evaluation.score]}
            variant={TypographyVariant.p}
          />
        </Col>

        <Col>
          <Typography
            text="GMD da avaliação"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Typography
            text={formatWeight(evaluation.average_daily_gain)}
            variant={TypographyVariant.p}
          />
        </Col>

        {!isLastEvaluation && hasEvaluationText && evaluationCol()}
      </Row>

      {isLastEvaluation && hasEvaluationText && (
        <Row className={styles.animalEvaluationsRow}>{evaluationCol()}</Row>
      )}
    </>
  )
}

const AnimalEvaluations: React.FC<AnimalEvaluationsProps> = ({
  isLastEvaluation,
  evaluations,
}) => (
  <InfoCard
    title={isLastEvaluation ? 'Última avaliação' : 'Avaliações do animal'}
    {...(isLastEvaluation && { fullWidth: false })}
  >
    {evaluations?.length ? (
      isLastEvaluation ? (
        <Evaluation evaluation={evaluations[0]} isLastEvaluation />
      ) : (
        evaluations.map(evaluation => (
          <Card key={evaluation.id}>
            <Evaluation evaluation={evaluation} />
          </Card>
        ))
      )
    ) : (
      <p className={styles.empty}>{Messages.EVALUATION_EMPTY_DATA}</p>
    )}
  </InfoCard>
)

export { AnimalEvaluations }
