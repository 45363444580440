import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useServiceOrder } from 'providers/service-orders'

import { getAuthUser } from 'app/core/services'
import { UserEntity } from 'app/core/types/user'

type UserContextProps = {
  user: UserEntity | undefined
  selectedFarm: SelectedFarmProps | undefined
  setSelectedFarm: React.Dispatch<
    React.SetStateAction<SelectedFarmProps | undefined>
  >
}

export const UserContext = createContext<UserContextProps | undefined>(
  undefined
)

type SelectedFarmProps = {
  id: string
  name: string
}

export const UserProvider: React.FC = ({ children }) => {
  const { setFilters } = useServiceOrder()

  const [user, setUser] = useState<UserEntity>()
  const [selectedFarm, setSelectedFarm] = useState<SelectedFarmProps>()

  const fetchUser = useCallback(async () => {
    const user = await getAuthUser()
    setUser(user)
    setSelectedFarm({
      id: String(user.farm_id),
      name: user.farm_name,
    })
    setFilters(prevState => ({
      ...prevState,
      farm_id: user.farm_id,
    }))
  }, [setFilters])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <UserContext.Provider value={{ user, selectedFarm, setSelectedFarm }}>
      {children}
    </UserContext.Provider>
  )
}

export const useAuthUser = (): UserContextProps => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useAuthUser must be used within a UserProvider')
  }

  return context
}
