import { SelectedFiles } from 'use-file-picker'

import { ImportCheckResponseData, ImportEntity } from 'app/core/types/system'
import { parseSelectedFileToBlob } from 'app/core/utils'
import { http } from 'interfaces/http'

const postImportCheck = async (
  file: SelectedFiles,
  entity: ImportEntity
): Promise<ImportCheckResponseData> => {
  const url =
    entity === ImportEntity.EXECUTORS
      ? `/auth/check/`
      : `/system_parameters/${entity}/check/`

  const formData = new FormData()
  const fileBlob = parseSelectedFileToBlob(file)

  formData.append('file', fileBlob.data, fileBlob.name)

  const response = await http.post(url, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export { postImportCheck }
