import React, { useState } from 'react'

import { Chip } from 'components/atoms'

import { DashboardAnimalCount } from 'app/core/types/dashboard'

import { DashboardCountAgeGroupChart } from './age-group-chart'
import styles from './styles.module.scss'
import { DashboardCountTotalChart } from './total-chart'

type DashboardCountChartsProps = {
  animalCount?: DashboardAnimalCount[]
}

enum ChartType {
  ageGroup = 'ageGroup',
  total = 'total',
}

const DashboardCountCharts: React.FC<DashboardCountChartsProps> = ({
  animalCount,
}) => {
  const [chartType, setChartType] = useState<ChartType>(ChartType.ageGroup)
  return (
    <>
      <div className={styles.chartChipWrapper}>
        <Chip
          label="Grupo de Idade"
          selected={chartType === ChartType.ageGroup}
          onClick={(): void => setChartType(ChartType.ageGroup)}
        />
        <Chip
          label="Quantidade Total"
          selected={chartType === ChartType.total}
          onClick={(): void => setChartType(ChartType.total)}
        />
      </div>
      {chartType === ChartType.ageGroup ? (
        <DashboardCountAgeGroupChart animalCount={animalCount} />
      ) : (
        <DashboardCountTotalChart animalCount={animalCount} />
      )}
    </>
  )
}

export { DashboardCountCharts }
