import { HandlingTypes } from 'app/core/types/handling'

export enum EntryOptions {
  handlingCreate = 'Registro de manejo',
  animalCreate = 'Cadastro de animais',
  animalShutdown = 'Baixas de animais',
  collectiveMovement = 'Movimentações coletivas',
  electronicEartag = 'Atualização de brinco eletrônico',
  stockNumber = 'Atualização de número de plantel',
}

export const ENTRY_OPTIONS = Object.values(EntryOptions).map(value => ({
  label: value,
  value: value,
}))

export const HANDLING_TYPES = Object.entries(HandlingTypes).map(
  ([key, value]) => ({ label: value, value: key })
)
