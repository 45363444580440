import { useCallback, useEffect, useMemo, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import {
  getReadAllFarms,
  getReadZipCode,
  postCreateFarm,
  postEditFarm,
} from 'app/core/services/system'
import { FarmHook } from 'app/core/types/hooks'
import {
  FarmCreateRequestData,
  FarmProps,
  ZipCodeResponseData,
} from 'app/core/types/system'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

const useFarm = (): FarmHook => {
  const [farms, setFarms] = useState<FarmProps[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const farmsDroplist = farms.map(farm => {
    return {
      label: farm.name,
      value: `${farm.id}`,
    }
  }) as SelectItemProps[]

  const activeFarmsDroplist = useMemo(
    () =>
      farms
        .map(farm => {
          if (farm.status) {
            return {
              label: farm.name,
              value: `${farm.id}`,
            }
          }
        })
        .filter(farm => farm !== undefined),
    [farms]
  ) as SelectItemProps[]

  const activeFarms = farms.filter(farm => farm.status)

  const readAllFarms = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)
      const data = await getReadAllFarms()
      setFarms(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const addFarm = useCallback(
    async (request: FarmCreateRequestData): Promise<void> => {
      if (request) {
        try {
          await postCreateFarm(request)
          addToast({
            message: Messages.SYSTEM_FARM_CREATE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  const getAddress = useCallback(
    async (zipCode: number): Promise<ZipCodeResponseData> => {
      try {
        return getReadZipCode(zipCode)
      } catch (e) {
        addToast({ message: Messages.SYSTEM_FARM_READ_ZIP_CODE_ERROR })
        throw e
      }
    },
    []
  )

  const editFarm = useCallback(
    async (farmId: number, request: FarmCreateRequestData): Promise<void> => {
      if (request) {
        try {
          await postEditFarm(farmId, request)
          addToast({
            message: Messages.SYSTEM_FARM_EDIT_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  useEffect(() => {
    readAllFarms()
  }, [readAllFarms])

  return {
    activeFarms,
    farms,
    farmsDroplist,
    activeFarmsDroplist,
    addFarm,
    isLoading,
    getAddress,
    editFarm,
  }
}

export { useFarm }
