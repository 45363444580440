/* eslint-disable @typescript-eslint/naming-convention */
import { ColumnDef } from '@tanstack/react-table'
import { Dispatch } from 'react'

import { MultipleRowCheckbox } from '../components'
import { ButtonIconPosition, Icon, IconNames, Tooltip } from 'components/atoms'
import { RemoveButton } from 'components/molecules'
import { ISelectButtonItem } from 'components/organisms'
import { cellEmptyStaticValue } from 'components/organisms/custom-table/helpers'
import SelectButton from 'components/organisms/select-button'

import {
  ImplantUseList,
  ProtocolActionProps,
  ScheduleAnimalProps,
  ScheduleUpdateAnimalRequestData,
} from 'app/core/types/hormonal'
import { dateTimeFormat } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

const scheduleColumns = (
  animals: ScheduleAnimalProps[],
  updateAnimal: (
    requestBody: ScheduleUpdateAnimalRequestData[]
  ) => Promise<void>,
  actions: ProtocolActionProps[],
  selectedAction: ProtocolActionProps | undefined,
  setSelectedAction: Dispatch<
    React.SetStateAction<ProtocolActionProps | undefined>
  >,
  handleRemoveAnimal: (animalId: number) => void
): ColumnDef<ScheduleAnimalProps>[] => {
  const SCHEDULED_ANIMAL_OBJECTS: ScheduleUpdateAnimalRequestData[] =
    animals?.map(animal => {
      const { id, body_condition_score, implanted, implant_used, actions } =
        animal

      const dayActions = actions.filter(
        action => action.hormonal_action_day === selectedAction?.day
      )

      return {
        id,
        body_condition_score,
        implanted,
        implant_used,
        actions: dayActions ? dayActions : [],
      }
    })

  const dayLabel = selectedAction ? `D${selectedAction?.day}` : 'D0'

  const actionDaysOptions: ISelectButtonItem[] = []
  const uniqueDaysSet: Set<number> = new Set()

  actions.forEach(action => {
    if (!uniqueDaysSet.has(action.day)) {
      actionDaysOptions.push({
        name: `D${action.day}`,
        action: () => {
          setSelectedAction(action)
        },
      })
      uniqueDaysSet.add(action.day)
    }
  })

  const implantUsedOptions: ISelectButtonItem[] = Object.keys(
    ImplantUseList
  ).map(implant => ({
    name: implant,
    action: (): void => {
      const animalsUpdatedImplant = SCHEDULED_ANIMAL_OBJECTS.map(animal => ({
        ...animal,
        implant_used: implant,
      }))
      updateAnimal(animalsUpdatedImplant)
    },
  }))

  return [
    {
      header: 'Nº de Plantel',
      accessorKey: 'stock_number',
      cell: (info): JSX.Element =>
        cellEmptyStaticValue(info.row.original.animal.stock_number),
    },
    {
      header: 'Nº de Brinco Eletrônico',
      accessorKey: 'electronic_eartag',
      cell: (info): JSX.Element =>
        cellEmptyStaticValue(info.row.original.animal.electronic_eartag),
    },
    {
      header: (): JSX.Element => (
        <span className={styles.customHeader}>
          <MultipleRowCheckbox
            animals={SCHEDULED_ANIMAL_OBJECTS}
            updateAnimal={updateAnimal}
          />
          <SelectButton
            icon={<Icon name={IconNames['chevron-down']} />}
            iconPosition={ButtonIconPosition.right}
            label={dayLabel}
            items={actionDaysOptions}
            className={styles.selectButton}
          />
        </span>
      ),
      accessorKey: 'executed',
    },
    {
      header: 'Produtos Aplicados',
      accessorKey: 'actions',
      cell: (): JSX.Element => (
        <ul className={styles.actionsList}>
          {actions
            .filter(action => action.day === selectedAction?.day)
            .map(action => (
              <li>{action.action}</li>
            ))}
        </ul>
      ),
    },
    {
      header: 'Score Corporal',
      accessorKey: 'body_condition_score',
    },
    {
      header: (): JSX.Element => (
        <Tooltip
          label="Implante Perdido"
          tooltip={Messages.SCHEDULE_TOOLTIP_IMPLANTED}
        />
      ),
      accessorKey: 'implanted',
    },
    {
      header: (): JSX.Element => (
        <SelectButton
          icon={<Icon name={IconNames['chevron-down']} />}
          label="Uso do Implante"
          items={implantUsedOptions}
          className={styles.selectButton}
        />
      ),
      accessorKey: 'implant_used',
    },
    {
      header: 'Último parto',
      accessorKey: 'last_calving',
      cell: (info): JSX.Element => {
        const { last_calving } = info.row.original.animal
        return <span>{last_calving ? dateTimeFormat(last_calving) : '-'}</span>
      },
    },
    {
      header: '',
      accessorKey: 'id',
      cell: (info): JSX.Element => (
        <RemoveButton
          itemId={info.row.original.id}
          handleRemoveItem={handleRemoveAnimal}
        />
      ),
    },
  ]
}

export { scheduleColumns }
