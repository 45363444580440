/* eslint-disable no-sparse-arrays */
import { format, parseISO } from 'date-fns'
import * as XLSX from 'xlsx'

import {
  AnimalCreateProps,
  AnimalFailedResponseData,
  AnimalSex,
  AnimalSexLabel,
} from 'app/core/types/animal'
import { StorageKeys } from 'app/core/types/storage'
import { SectorProps } from 'app/core/types/system'
import {
  addToast,
  dateForFileName,
  dateTimeFormat,
  getPlural,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const displayErrorMessage = (
  failedCount: number,
  createdCount: number
): void => {
  const createdMessage = `${getPlural(
    'animal',
    createdCount,
    true
  )} ${getPlural('cadastrado', createdCount)} ${Messages.ANIMAL_CREATE_SUCCESS}`
  const failedMessage = `${Messages.ANIMAL_CREATE_FAIL} ${getPlural(
    'animal',
    failedCount,
    true
  )}.`

  addToast({
    message: createdCount
      ? `${createdMessage} ${failedMessage}`
      : `${failedMessage}`,
    type: 'warning',
  })
}

const getFailedAnimals = (
  _failedAnimals: AnimalFailedResponseData[]
): AnimalCreateProps[] => {
  const failedAnimals = _failedAnimals.map(animal => ({
    ...animal.animal,
    error_message: animal.error_message,
    birth_date: dateTimeFormat(animal.animal.birth_date as string, true),
  }))

  return failedAnimals
}

const reviewKeyManagement = (
  positiveFeedback: AnimalCreateProps[],
  sector: SectorProps
): void => {
  localStorage.removeItem(StorageKeys.animals_review)
  localStorage.removeItem(StorageKeys.animals_sector_review)
  localStorage.setItem(
    StorageKeys.animals_last_review,
    JSON.stringify(positiveFeedback)
  )
  localStorage.setItem(
    StorageKeys.animals_sector_last_review,
    JSON.stringify(sector)
  )
  localStorage.setItem(
    StorageKeys.animals_date_last_review,
    new Date().toISOString()
  )
}

const generateHerdDynamicSheet = (
  startDate: string,
  endDate: string,
  data: string[][]
): void => {
  const workbook = XLSX.utils.book_new()
  const sheetName = 'Dinâmica de Rebanho'
  const worksheet = XLSX.utils.aoa_to_sheet([])

  const formattedStartDate = format(parseISO(startDate), 'dd/MM/yyyy')
  const formattedEndDate = format(parseISO(endDate), 'dd/MM/yyyy')

  const regExp = /[a-zA-Z]/g
  const formattedData = data.map(row =>
    row.map(rowValue => (regExp.test(rowValue) ? rowValue : Number(rowValue)))
  )

  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        { v: 'Fazenda', t: 's' },
        { v: 'Setor', t: 's' },
        { v: 'Categoria', t: 's' },
        { v: 'Início', t: 's' },
        { v: 'Entradas', t: 's' },
        '',
        '',
        '',
        { v: 'Saídas', t: 's' },
        '',
        '',
        '',
        '',
        { v: 'Fim', t: 's' },
      ],
      [
        '',
        '',
        '',
        { v: formattedStartDate, t: 's' },
        { v: 'Nasc.', t: 's' },
        { v: 'Inclusão/Compra', t: 's' },
        { v: 'Mud. cat.', t: 's' },
        { v: 'Mud. grupo', t: 's' },
        { v: 'Morte', t: 's' },
        { v: 'Venda', t: 's' },
        { v: 'Outros', t: 's' },
        { v: 'Mud. cat.', t: 's' },
        { v: 'Mud. grupo', t: 's' },
        { v: formattedEndDate, t: 's' },
      ],
    ],
    { origin: 'A1' }
  )

  XLSX.utils.sheet_add_aoa(worksheet, formattedData, { origin: 'A3' })

  // Merging cells
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
    { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
    { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
    { s: { r: 0, c: 2 }, e: { r: 0, c: 2 } },
    { s: { r: 0, c: 3 }, e: { r: 0, c: 6 } },
    { s: { r: 0, c: 7 }, e: { r: 0, c: 11 } },
    { s: { r: 0, c: 12 }, e: { r: 0, c: 12 } },
  ]

  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

  XLSX.writeFile(workbook, `dinamica-rebanho-${dateForFileName()}.xlsx`)
}

const getLastBirthSexOrNull = (
  lastGivenBirthSex: AnimalSexLabel | undefined
): string | null => {
  if (!lastGivenBirthSex) return null

  return lastGivenBirthSex === AnimalSexLabel.female
    ? AnimalSex.female
    : AnimalSex.male
}

export {
  displayErrorMessage,
  generateHerdDynamicSheet,
  getFailedAnimals,
  getLastBirthSexOrNull,
  reviewKeyManagement,
}
