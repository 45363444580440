import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useInsemination, useInseminationAnimal } from 'app/core/hooks'
import { IParamProps, NavigateList } from 'app/core/routes/routes'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { inseminationReviewColumns } from '../tables'
import { InseminationReviewTemplate } from './template'

const InseminationReview: React.FC = () => {
  const history = useHistory<NavigateList>()
  const { inseminationId } = useParams<IParamProps>()

  const [page, setPage] = useState(1)

  const { insemination } = useInsemination({ inseminationId })
  const { animals, createInsemination, isLoading, removeAnimal } =
    useInseminationAnimal({
      inseminationId,
      page,
    })

  const handleCreateInsemination = async (): Promise<void> => {
    if (animals?.items.length === 0) {
      addToast({ message: Messages.INSEMINATION_ANIMALS_REQUIRED })
      return
    }

    await createInsemination()

    history.push(`${NavigateList.inseminationFeedback}${inseminationId}`)
  }

  const columns = useMemo(
    () => inseminationReviewColumns(removeAnimal, insemination?.type),
    [removeAnimal, insemination?.type]
  )

  const table = useReactTable({
    data: animals?.items || [],
    columns,
    getCoreRowModel: useMemo(() => getCoreRowModel(), []),
  })

  return (
    <InseminationReviewTemplate
      table={table}
      animals={animals}
      insemination={insemination}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      handleCreateInsemination={handleCreateInsemination}
    />
  )
}

export { InseminationReview }
