import React from 'react'

import classNames from 'classnames'

import { ITypographyDefaultProps } from '..'
import styles from './styles.module.scss'

export enum TypographyCaptionSize {
  caption1 = 'caption1',
  caption2 = 'caption2',
  caption3 = 'caption3',
}

export interface ICaptionProps extends ITypographyDefaultProps {
  /**
   * The caption size: caption1, caption2 or caption3
   */
  size?: TypographyCaptionSize
  /**
   * Classname to add custom css
   */
  className?: string
  /**
   * Choose if the caption should have an alternative color
   */
  altColor?: boolean
}

const Caption = ({
  text,
  size = TypographyCaptionSize.caption1,
  className,
  altColor,
  ...props
}: ICaptionProps): JSX.Element => {
  const componentProps = {
    className: classNames(
      styles.caption,
      styles[size],
      altColor ? styles.altColor : '',
      className
    ),
    ...props,
  }

  return React.createElement('span', { ...componentProps }, text)
}

export { Caption }
