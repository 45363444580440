import classNames from 'classnames'

import { FontSize } from 'components/enums/font-size'

import { ITypographyDefaultProps } from '..'
import styles from './styles.module.scss'

export enum TypographyTextDecoration {
  underline = 'underline',
  none = 'none',
}

export interface ILinkProps extends ITypographyDefaultProps {
  /**
   * Boolean for adding a text-decoration to the link text
   */
  textDecoration?: TypographyTextDecoration
  /**
   * The font size for the link text
   */
  fontSize?: FontSize
  /**
   * Optional click handler
   */
  onClick?: () => void
}

const Link = ({
  text = 'Link',
  textDecoration = TypographyTextDecoration.underline,
  fontSize = FontSize.normal,
  className,
  onClick,
}: ILinkProps): JSX.Element => {
  return (
    <span
      className={classNames(
        styles.link,
        styles[textDecoration],
        styles[fontSize],
        className
      )}
      role="link"
      onClick={onClick}
    >
      {text}
    </span>
  )
}

export { Link }
