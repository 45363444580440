import React from 'react'
import { useHistory } from 'react-router-dom'

import { useMeasuringStation } from 'app/core/hooks/measuring-station'
import { NavigateList } from 'app/core/routes/routes'

import { MeasuringStationListTemplate } from './template'

const MeasuringStationList: React.FC = () => {
  const history = useHistory<NavigateList>()
  const { allMeasuringStations, isLoading, delMeasuringStation } =
    useMeasuringStation()

  const handleMeasuringStationCreate = (): void =>
    history.push(NavigateList.measuringStationCreate)

  const handleMeasuringStationEdit = (measuringStationId: number): void =>
    history.push(`${NavigateList.measuringStationEdit}${measuringStationId}`)

  const handleMeasuringStationDelete = (
    measuringStationId: number
  ): Promise<void> => delMeasuringStation(measuringStationId)

  return (
    <MeasuringStationListTemplate
      isLoading={isLoading}
      measuringStations={allMeasuringStations || []}
      onCreateClick={handleMeasuringStationCreate}
      onEditClick={handleMeasuringStationEdit}
      onDeleteClick={handleMeasuringStationDelete}
    />
  )
}

export { MeasuringStationList }
