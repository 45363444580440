import React, { useEffect, useState } from 'react'

import { useFarm } from 'app/core/hooks'
import { useMeasuringStation } from 'app/core/hooks/measuring-station'
import { getAnimalsCount, getAnimalsUnit } from 'app/core/services'
import {
  AnimalUnitPeriodTypes,
  DashboardAnimalCount,
  DashboardAnimalUnit,
} from 'app/core/types/dashboard'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { DashboardTemplate } from './template'

const Dashboard: React.FC = () => {
  const [animalCount, setAnimalCount] = useState<
    DashboardAnimalCount[] | undefined
  >(undefined)

  const [animalUnit, setAnimalUnit] = useState<
    DashboardAnimalUnit[] | undefined
  >(undefined)

  const { activeFarms } = useFarm()

  useEffect(() => {
    getAnimalsCount()
      .then(data => {
        setAnimalCount(data)
      })
      .catch(e => {
        if (e?.response?.data?.detail === 'business_exception') {
          addToast({
            message: Messages.DASHBOARD_COUNT_REQUIREMENTS_FAIL,
            type: 'error',
          })
          return
        }
        addToast({ message: Messages.ERROR_MESSAGE, type: 'error' })
      })
  }, [])

  const populateAnimalUnit = async (
    periodType: AnimalUnitPeriodTypes
  ): Promise<void> => {
    setAnimalUnit(undefined)
    getAnimalsUnit(periodType)
      .then(data => {
        setAnimalUnit(data)
      })
      .catch(e => {
        if (e?.response?.data?.detail === 'business_exception') {
          addToast({
            message: Messages.DASHBOARD_ANIMAL_UNIT_REQUIREMENTS_FAIL,
            type: 'error',
          })
          return
        }
        addToast({ message: Messages.ERROR_MESSAGE, type: 'error' })
      })
  }

  const { measuringStationsOptions } = useMeasuringStation()

  return (
    <DashboardTemplate
      animalCount={animalCount}
      animalUnit={animalUnit}
      populateAnimalUnit={populateAnimalUnit}
      farms={activeFarms}
      measurementStationOptions={measuringStationsOptions}
    />
  )
}

export { Dashboard }
