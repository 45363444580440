import React, { ReactNode } from 'react'

import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell } from 'recharts'

import { CHART_COLORS } from 'app/core/pages/dashboard/constants'
import { AnimalSexLabel } from 'app/core/types/animal'
import { DashboardAnimalUnit } from 'app/core/types/dashboard'
import { FarmProps } from 'app/core/types/system'
import { numberWithThousandDotSeparatorFormat } from 'app/core/utils/number'

import styles from './styles.module.scss'

type DashboardAnimalUnitCategoriesPieChartProps = {
  sex: AnimalSexLabel
  farm?: FarmProps
  animalUnit?: DashboardAnimalUnit[]
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  payload,
  percent,
}: {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  index: number
  payload: { name: string; value: number }
  percent: number
}): JSX.Element => {
  const RADIAN = Math.PI / 180
  const value = numberWithThousandDotSeparatorFormat(payload.value)
  const textWidth = value.length
  const radius = textWidth + innerRadius + (outerRadius - innerRadius) * 0.5
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const x = cx + radius * cos
  const y = cy + radius * sin
  const pathX1 = cx + outerRadius * cos
  const pathY1 = cy + outerRadius * sin
  const pathX2 = cx + (outerRadius + 30) * cos
  const pathY2 = cy + (outerRadius + 30) * sin
  const pathX3 = pathX2 + (cos >= 0 ? 1 : -1) * 22
  const pathY3 = pathY2
  const textAnchor = cos >= 0 ? 'start' : 'end'
  const label = payload.name
  const labelRows = label.split(' ').reduce((acc, item) => {
    const [currentList] = acc.slice(-1)
    const newList = [...(currentList ?? []), item]
    if (newList.join(' ').length > 8) {
      return [...acc, [item]]
    }
    return [...acc.slice(0, -1), newList]
  }, [] as string[][])
  const textX = pathX3 + (cos >= 0 ? 1 : -1) * 12
  const textY = labelRows.length === 1 ? pathY3 : pathY3 - labelRows.length * 5

  return (
    <g>
      {percent > 0.05 && (
        <>
          <text
            x={x}
            y={y}
            textAnchor="middle"
            dominantBaseline="central"
            className={styles.contentLabelList}
          >
            {`${numberWithThousandDotSeparatorFormat(
              Math.round(payload.value)
            )}`}
          </text>

          <path
            d={`M${pathX1},${pathY1}L${pathX2},${pathY2}L${pathX3},${pathY3}`}
            stroke="rgba(97, 97, 98, 0.3)"
            strokeWidth={1}
            fill="none"
          />
          <text
            x={textX}
            y={textY}
            textAnchor={textAnchor}
            dominantBaseline="central"
            className={styles.contentLabelList}
          >
            {labelRows.map((item, index) => (
              <tspan key={index} x={textX} dy={index === 0 ? 0 : '1.2em'}>
                {item.join(' ')}
              </tspan>
            ))}
          </text>
        </>
      )}
    </g>
  )
}

const parseChartData = (
  animalUnit: DashboardAnimalUnit[],
  sex: AnimalSexLabel,
  farm?: FarmProps
): { name: string; value: number }[] => {
  const dataIndex = animalUnit?.reduce((currentDataIndex, item) => {
    if (item.animal_sex !== sex) {
      return currentDataIndex
    }
    if (farm && farm.name !== item.farm_name) {
      return currentDataIndex
    }
    const currentDate = currentDataIndex[item.date] ?? {}
    item.age_grouped_animal_units.forEach(ageGroup => {
      currentDate[ageGroup.label] =
        (currentDate[ageGroup.label] ?? 0) + ageGroup.animal_unit
    })

    return {
      ...currentDataIndex,
      [item.date]: currentDate,
    }
  }, {} as Record<string, Record<string, number>>)
  const [lastDataIndex] = Object.values(dataIndex ?? {}).slice(-1)
  return Object.entries(lastDataIndex ?? {}).map(([name, value]) => ({
    name,
    value,
  }))
}

const renderTooltip = ({
  payload,
}: {
  payload?: { name?: string; value?: number; payload?: { fill: string } }[]
}): ReactNode => {
  const name = payload?.[0]?.name
  const value = numberWithThousandDotSeparatorFormat(
    Number(((payload?.[0]?.value as number) ?? 0).toFixed(2)) || 0
  )
  return (
    <div className={styles.tooltip}>
      <div
        className={styles.tooltipBox}
        style={{ background: payload?.[0]?.payload?.fill }}
      />
      <span className={styles.tooltipText}>
        {name}: <span className={styles.tooltipTextHighlight}>{value}</span>
      </span>
    </div>
  )
}

const DashboardAnimalUnitCategoriesPieChart: React.FC<DashboardAnimalUnitCategoriesPieChartProps> =
  ({ animalUnit, farm, sex }) => {
    const data = parseChartData(animalUnit ?? [], sex, farm)
    return (
      <ResponsiveContainer
        width="100%"
        height={370}
        className={styles.container}
      >
        {data.length ? (
          <PieChart>
            <Tooltip content={renderTooltip} />
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={55}
              outerRadius={130}
              labelLine={false}
              label={renderCustomizedLabel}
              stroke="none"
            >
              {data.map((_entry, index) => (
                <Cell
                  style={{ outline: 'none' }}
                  key={`cell-${index}`}
                  fill={CHART_COLORS[index]}
                />
              ))}
            </Pie>
            <circle
              cx="50%"
              cy="50%"
              r={65}
              fill="rgba(255, 255, 255, 0.2)"
              stroke="none"
            />
            ,
          </PieChart>
        ) : (
          <span className={styles.contentLabelList}>
            Não há dados a serem exibidos para a fazenda selecionada.
          </span>
        )}
      </ResponsiveContainer>
    )
  }

export { DashboardAnimalUnitCategoriesPieChart }
