import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'
import * as yup from 'yup'

import { ButtonType, IconNames, SelectItemProps } from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { CustomModal, ExpandCard } from 'components/molecules'
import { BoxedTable, useForm } from 'components/organisms'

import { AgeGroup, AgeGroupHook } from 'app/core/types/age-group'
import { ANIMAL_SEX_OPTIONS } from 'app/core/types/animal'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './../../styles.module.scss'
import { getAgeGroupColumns } from './table'

const AgeGroupCard: React.FC<AgeGroupHook> = ({
  getAgeGroups,
  ageGroups,
  updateAgeGroup,
  createAgeGroup,
  deleteAgeGroup,
}) => {
  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState<AgeGroup | undefined>()
  const [patchItem, setPatchItem] = useState<AgeGroup | undefined>()
  const validationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    start_day: yup
      .number()
      .typeError(Messages.YUP_NUMBER_REQUIRED)
      .required(Messages.YUP_REQUIRED_FIELD),
    finish_day: yup
      .number()
      .typeError(Messages.YUP_NUMBER_REQUIRED)
      .required(Messages.YUP_REQUIRED_FIELD),
    average_weight: yup
      .number()
      .typeError(Messages.YUP_NUMBER_REQUIRED)
      .required(Messages.YUP_REQUIRED_FIELD),
    sex: yup.mixed<SelectItemProps>().required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleDeleteModalConfirm = async (): Promise<void> => {
    try {
      const id = deleteItem?.id
      if (!id) {
        throw new Error('Missing delete item id')
      }
      handleDeleteModalClose()
      await deleteAgeGroup(id)
      await getAgeGroups()
      addToast({ message: 'Item deletado com sucesso', type: 'success' })
    } catch (err) {
      addToast({ message: 'Aconteceu um erro inesperado', type: 'error' })
    }
  }

  const handleDeleteModalClose = (): void => {
    setDeleteItem(undefined)
  }

  const handleUpsertModalClose = (): void => {
    setPatchItem(undefined)
    setIsUpsertModalOpen(false)
  }

  const handleAddClick = (): void => {
    setIsUpsertModalOpen(true)
    reset({})
  }

  const handleFormSubmit = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      try {
        const id = patchItem?.id
        handleUpsertModalClose()

        const ageGroupData = { ...data } as AgeGroup
        ageGroupData.sex = (data.sex as SelectItemProps).value

        id
          ? await updateAgeGroup(id as number, ageGroupData)
          : await createAgeGroup(ageGroupData)

        addToast({
          message: id
            ? 'Alteração realizada com sucesso'
            : 'Adicionado com sucesso',
          type: 'success',
        })

        await getAgeGroups()
        return
      } catch (err) {
        addToast({ message: 'Aconteceu um erro inesperado', type: 'error' })
      }
    },
    [createAgeGroup, getAgeGroups, patchItem?.id, updateAgeGroup]
  )

  const { Form, handleSubmit, reset } = useForm({
    onSubmit: handleFormSubmit,
    validationSchema,
  })

  const handleEditClick = useCallback(
    (item: AgeGroup): void => {
      reset({
        name: item.name,
        start_day: item.start_day,
        finish_day: item.finish_day,
        average_weight: item.average_weight,
        sex: ANIMAL_SEX_OPTIONS.filter(e => e.value == item.sex),
      })
      setIsUpsertModalOpen(true)
      setPatchItem(item)
    },
    [reset]
  )

  const handleRemoveClick = useCallback((item: AgeGroup): void => {
    setDeleteItem(item)
  }, [])

  const ageGroupColumns = useMemo<ColumnDef<AgeGroup>[]>(
    () => getAgeGroupColumns(handleEditClick, handleRemoveClick),
    [handleEditClick, handleRemoveClick]
  )
  const ageGroupsTable = useReactTable({
    data: ageGroups ?? [],
    columns: ageGroupColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value
    const limitedValue = value.slice(0, 5)
    e.target.value = limitedValue
  }

  return (
    <>
      <ExpandCard className={styles.settingsExpandCard} title="Grupos de idade">
        <div className={styles.cardContent}>
          <Divider darker />

          <BoxedTable
            className={styles.settingsBoxedTable}
            data={ageGroups}
            title="Grupos cadastrados"
            table={ageGroupsTable}
            hideQuantityLabel
            mainButtonLabel="Incluir"
            mainButtonIcon={IconNames['add-circle']}
            mainButtonAction={handleAddClick}
            disableNavigation
          />
        </div>

        <CustomModal
          modalIsOpen={!!isUpsertModalOpen}
          handleCloseModal={handleUpsertModalClose}
          modalHeader={
            patchItem ? `Editar Grupo de Idade` : 'Incluir Grupo de Idade'
          }
          primaryButtonLabel="SALVAR"
          primaryButtonAction={handleSubmit(handleFormSubmit)}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleUpsertModalClose}
        >
          <div className={styles.form}>
            <Form>
              <div
                className={classNames(styles.formFieldset, styles.ageGroupRow)}
              >
                <div className={styles.formFieldsetField}>
                  <Form.InputText
                    name="name"
                    placeholder="Inserir nome"
                    label="Nome"
                    className={styles.ageGroupNameField}
                  />
                </div>
              </div>

              <div
                className={classNames(styles.formFieldset, styles.ageGroupRow)}
              >
                <Form.InputText
                  name="start_day"
                  placeholder="00 dias"
                  label="Idade inicial"
                  htmlType="number"
                  className={styles.ageGroupAgeField}
                  appendHelperText="Dias"
                  onChange={(e): void => handleOnChange(e)}
                />
                <Form.InputText
                  name="finish_day"
                  placeholder="00 dias"
                  label="Idade final"
                  htmlType="number"
                  className={styles.ageGroupAgeField}
                  appendHelperText="Dias"
                  onChange={(e): void => handleOnChange(e)}
                />
              </div>

              <div
                className={classNames(styles.formFieldset, styles.ageGroupRow)}
              >
                <Form.InputText
                  name="average_weight"
                  label="Peso médio"
                  htmlType="number"
                  className={styles.ageGroupAgeField}
                  appendHelperText="Kg"
                />
                <div className={styles.formFieldsetField}>
                  <Form.Select
                    name="sex"
                    placeholder="Selecione"
                    label="Sexo"
                    options={ANIMAL_SEX_OPTIONS as unknown as SelectItemProps[]}
                    className={styles.ageGroupSexField}
                  />
                </div>
              </div>
            </Form>
          </div>
        </CustomModal>

        <CustomModal
          modalIsOpen={!!deleteItem}
          handleCloseModal={handleDeleteModalClose}
          modalHeader={`Excluir Grupo de Idade`}
          modalText={`Você tem certeza que deseja excluir o Grupo de Idade ${deleteItem?.name}?`}
          primaryButtonLabel="EXCLUIR"
          primaryButtonAction={handleDeleteModalConfirm}
          primaryButtonType={ButtonType.destructive}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleDeleteModalClose}
        />
      </ExpandCard>
    </>
  )
}

export { AgeGroupCard }
