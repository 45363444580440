import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useShutdown } from 'app/core/hooks/shutdown'
import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import {
  ShutdownCreateProps,
  ShutdownDescriptionStorageProps,
  ShutdownReasonStorageProps,
} from 'app/core/types/shutdown'
import { StorageKeys } from 'app/core/types/storage'

import { shutdownColumns } from '../columns'
import { ShutdownReviewTemplate } from './template'

const ShutdownReview: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = (path): void => history.push(path)

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const { addShutdown, shutdownDate, isLoading } = useShutdown()

  const savedShutdown = JSON.parse(
    localStorage.getItem(StorageKeys.shutdown_review) as string
  ) as ShutdownCreateProps[]
  const savedShutdownType = JSON.parse(
    localStorage.getItem(StorageKeys.shutdown_type_review) as string
  ) as ShutdownReasonStorageProps
  const shutdownDescriptions = JSON.parse(
    localStorage.getItem(StorageKeys.shutdown_descriptions) as string
  ) as ShutdownDescriptionStorageProps

  const handleToggleModal = (): void => {
    setModalIsOpen(!modalIsOpen)
  }

  const table = useReactTable({
    data: savedShutdown,
    columns: shutdownColumns({
      shutdownType: savedShutdownType?.shutdown_type,
      shutdownDescriptions,
    }),
    getCoreRowModel: getCoreRowModel(),
  })

  if (!savedShutdownType || !savedShutdown || !shutdownDescriptions) {
    navigateTo(NavigateList.animalList)
    return null
  }

  const handleShutdown = (): void => {
    addShutdown({
      animals: savedShutdown,
      shutdownType: savedShutdownType.shutdown_type,
      shutdownDescription: savedShutdownType.shutdown_description,
      route: NavigateList.shutdownFeedback,
    })
  }

  return (
    <ShutdownReviewTemplate
      handleToggleModal={handleToggleModal}
      handleShutdown={handleShutdown}
      modalIsOpen={modalIsOpen}
      savedShutdown={savedShutdown}
      table={table}
      shutdownDate={shutdownDate}
      isLoading={isLoading}
    />
  )
}

export { ShutdownReview }
