import { ChangeEvent, useState } from 'react'

import { Typography, TypographyVariant } from 'components/atoms'

import { AnimalListProps, IAnimalEntity } from 'app/core/types/animal'
import { EVALUATION_OPTIONS } from 'app/core/types/evaluation'
import { calcAverageDailyGain, dateToday } from 'app/core/utils'

import { FormType } from '../form/types'
import styles from './styles.module.scss'

type EvaluateAnimalProps = {
  form: FormType
  animal?: IAnimalEntity | AnimalListProps
}

const EvaluateAnimal: React.FC<EvaluateAnimalProps> = ({
  form: Form,
  animal,
}) => {
  const [weight, setWeight] = useState<string>()
  const [averageDailyGain, setAverageDailyGain] = useState<number>()

  const isIAnimalEntity = (
    animal: EvaluateAnimalProps['animal']
  ): animal is IAnimalEntity => {
    return (animal as IAnimalEntity).header !== undefined
  }

  const handleWeightChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const maxWeightLength = 4
    const { value } = event.currentTarget

    if (value === '' || !animal) {
      setWeight('')
      setAverageDailyGain(undefined)
      return
    }

    if (value.length > maxWeightLength) return

    setWeight(value)

    const birthWeight = isIAnimalEntity(animal)
      ? animal.additional_information.birth_weight
      : animal.birth_weight
    const birthDate = isIAnimalEntity(animal)
      ? animal.basic_information.birth_date
      : animal.birth_date

    setAverageDailyGain(
      calcAverageDailyGain(
        birthWeight,
        Number(event.currentTarget.value),
        birthDate,
        dateToday
      )
    )
  }

  const hasAverageDailyGain = averageDailyGain != null && averageDailyGain !== 0

  return (
    <div className={styles.evaluationForm}>
      <Form>
        <div className={styles.evaluationFormRow}>
          <div className={styles.evaluationFormCol}>
            <Form.InputText
              name="date"
              label="Data"
              htmlType="date"
              value={dateToday}
              disabled
            />
          </div>
          <div className={styles.evaluationFormCol}>
            <Form.InputText
              label="Peso"
              name="weight"
              htmlType="number"
              appendHelperText="Kg"
              onChange={handleWeightChange}
              value={weight}
            />
          </div>
          {hasAverageDailyGain && (
            <div className={styles.evaluationFormCol}>
              <div className={styles.avg}>
                <Typography
                  variant={TypographyVariant.label}
                  text="Ganho médio diário"
                />
                <div className={styles.avgContainer}>
                  <Typography
                    variant={TypographyVariant.p}
                    text={averageDailyGain}
                  />
                  <div className={styles.avgHelperText}>Kg</div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.evaluationFormRow}>
          <div className={styles.evaluationFormCol}>
            <Form.RadioButton
              chipButtonMode
              name="score"
              title="Nota"
              options={EVALUATION_OPTIONS}
            />
          </div>
        </div>

        <div className={styles.evaluationFormRow}>
          <div className={styles.evaluationFormColSingle}>
            <Form.Textarea
              name="evaluation"
              label="Avaliação"
              placeholder="Forneça informações detalhadas sobre essa avaliação..."
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

export { EvaluateAnimal }
