import React, { useState } from 'react'

import { useSchedule, useBreedingStation, useProtocol } from 'app/core/hooks'
import { ScheduleCreateRequestFormatData } from 'app/core/types/hormonal'
import { dateTimeFormat } from 'app/core/utils'

import { ScheduleCreateTemplate } from './template'

const ScheduleCreate: React.FC = () => {
  const { stations, stationsDroplistOptions } = useBreedingStation({
    size: 500,
  })
  const { protocolsDroplist } = useProtocol({ size: 500 })
  const { addSchedule } = useSchedule({})

  const [stationDate, setStationDate] =
    useState<{ initDate: string; finalDate: string }>()

  const handleSelectStation = (stationId: number): void => {
    const selectedStation = stations?.items?.find(e => e.id === stationId)
    const initDate = dateTimeFormat(selectedStation?.init_date as string, true)
    const finalDate = dateTimeFormat(
      selectedStation?.final_date as string,
      true
    )
    setStationDate({ initDate, finalDate })
  }

  const handleCreateSchedule = async (data: unknown): Promise<void> => {
    addSchedule(data as ScheduleCreateRequestFormatData)
  }

  return (
    <ScheduleCreateTemplate
      stationsList={stationsDroplistOptions}
      stationDate={stationDate}
      protocolsList={protocolsDroplist}
      handleSelectStation={handleSelectStation}
      onSubmit={handleCreateSchedule}
    />
  )
}

export { ScheduleCreate }
