import React, { useState } from 'react'

import classNames from 'classnames'

import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import styles from './styles.module.scss'

type InfoCardProps = {
  className?: string
  fullWidth?: boolean
  title: string
  collapsible?: boolean
  defaultCollapsed?: boolean
}

const InfoCard: React.FC<InfoCardProps> = ({
  children,
  className,
  collapsible,
  defaultCollapsed,
  fullWidth,
  title,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed)

  const handleCollapse = (): void => {
    setCollapsed(!collapsed)
  }

  return (
    <div
      className={classNames(
        styles.infoCard,
        className,
        fullWidth && styles.infoCardFullWidth
      )}
    >
      <InfoCardHeader
        text={title}
        collapsed={collapsed}
        collapsible={collapsible}
        setCollapse={handleCollapse}
      />

      {!collapsed && <>{children}</>}
    </div>
  )
}

const InfoCardHeader: React.FC<{
  text: string
  collapsible?: boolean
  collapsed?: boolean
  setCollapse: () => void
}> = ({ collapsible, collapsed, text, setCollapse }) => (
  <div
    className={classNames(
      styles.infoCardHeader,
      collapsible && styles.infoCardHeaderCollapsible
    )}
    {...(collapsible && { onClick: (): void => setCollapse() })}
  >
    <Typography
      text={text}
      variant={TypographyVariant.h5}
      className={styles.infoCardTitle}
    />

    {collapsible && (
      <Icon
        name={collapsed ? IconNames['chevron-up'] : IconNames['chevron-down']}
        size="18px"
      />
    )}
  </div>
)

const InfoCardSubject: React.FC<{ text: string }> = ({ text }) => (
  <div className={styles.infoCardSubjectContainer}>
    <Typography
      text={text}
      variant={TypographyVariant.h6}
      className={styles.infoCardSubject}
    />
  </div>
)

const Row: React.FC<{ className?: string }> = ({ className, children }) => (
  <div className={classNames(styles.infoCardRow, className)}>{children}</div>
)

const RowSingleItem: React.FC = ({ children }) => (
  <div className={styles.infoCardRowSingleItem}>{children}</div>
)

const Col: React.FC<{ className?: string }> = ({ className, children }) => (
  <div className={classNames(styles.infoCardCol, className)}>{children}</div>
)

const ColGroup: React.FC<{ justifyEnd?: boolean }> = ({
  children,
  justifyEnd,
}) => (
  <div
    className={classNames(
      styles.infoCardColGroup,
      justifyEnd ? styles.justifyEnd : ''
    )}
  >
    {children}
  </div>
)

const BreedCol: React.FC = ({ children }) => (
  <div className={classNames(styles.infoCardCol, styles.breedCol)}>
    {children}
  </div>
)

export {
  BreedCol,
  Col,
  ColGroup,
  InfoCard,
  InfoCardHeader,
  InfoCardSubject,
  Row,
  RowSingleItem,
}
