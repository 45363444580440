import { SelectItemProps } from 'components/atoms'

import { HistoricalPrecipitationMeanCard } from './cards/historical-mean'
import { PrecipitationTotalCard } from './cards/total'
import { YearPrecipitationMeanCard } from './cards/year-mean'

type PrecipitationTabProps = {
  measurementStationOptions: SelectItemProps[]
}

const PrecipitationTab: React.FC<PrecipitationTabProps> = ({
  measurementStationOptions,
}) => {
  return (
    <>
      <YearPrecipitationMeanCard stations={measurementStationOptions} />
      <HistoricalPrecipitationMeanCard stations={measurementStationOptions} />
      <PrecipitationTotalCard stations={measurementStationOptions} />
    </>
  )
}

export { PrecipitationTab }
