import { ColumnDef } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { ServiceProps } from 'app/core/types/system'

import styles from './styles.module.scss'

const servicesListColumns = (
  handleEdit: (service: ServiceProps) => void,
  handleDelete: (service: ServiceProps) => void
): ColumnDef<ServiceProps, unknown>[] => [
  {
    header: 'Serviço',
    accessorKey: 'name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Tipo de serviço',
    accessorKey: 'type',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Fazenda',
    accessorKey: 'farm_name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <div className={styles.tableActions}>
        <Icon
          testId="edit-service"
          name={IconNames.pencil}
          onClick={(): void => handleEdit(info.row.original)}
        />
        <Icon
          testId="delete-service"
          name={IconNames.close}
          onClick={(): void => handleDelete(info.row.original)}
        />
      </div>
    ),
  },
]

export { servicesListColumns }
