import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'

import { BoxedTable } from 'components/organisms'

import { MeasuringStationProps } from 'app/core/types/measuring-station'

import styles from '../styles.module.scss'
import { MeasuringStationColumn } from './column'

type MeasuringStationTableProps = {
  measuringStations: MeasuringStationProps[]
  onEditClick?: (measuringStationId: number) => void
  onDeleteClick?: (measuringStationId: number) => void
}

const MeasuringStationTable: React.FC<MeasuringStationTableProps> = ({
  measuringStations,
  onEditClick,
  onDeleteClick,
}) => {
  const measuringStationTable = useReactTable({
    data: measuringStations,
    columns: MeasuringStationColumn(
      measuringStationId => {
        if (onEditClick) onEditClick(measuringStationId)
      },
      onDeleteClick
        ? (measuringStationId): void => {
            if (onDeleteClick) onDeleteClick(measuringStationId)
          }
        : undefined
    ),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <BoxedTable
        className={styles.measuringStationTable}
        data={measuringStations}
        table={measuringStationTable}
        hideQuantityLabel
        disableNavigation
      />
    </>
  )
}

export { MeasuringStationTable }
