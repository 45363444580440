import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import {
  ButtonIconPosition,
  Chip,
  Divider,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { BoxedTable, ISelectButtonItem } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  CollectiveMomentsAnimalsResponseData,
  CollectiveMovementProps,
  CollectiveMovementsCreateProps,
} from 'app/core/types/collective-movements'

import styles from '../create/styles.module.scss'

type CollectiveMovementsFeedbackTemplateProps = {
  animals: CollectiveMomentsAnimalsResponseData | undefined
  collectiveMovement: CollectiveMovementProps | undefined
  table: Table<CollectiveMovementsCreateProps>
  isLoading: boolean
  page: number
  setPage: Dispatch<SetStateAction<number>>
  handleFinishCollectiveMovement: () => void
  headerSelectButtonItems: ISelectButtonItem[]
}

const CollectiveMovementsFeedbackTemplate: React.FC<CollectiveMovementsFeedbackTemplateProps> =
  ({
    animals,
    isLoading,
    table,
    collectiveMovement,
    page,
    setPage,
    handleFinishCollectiveMovement,
    headerSelectButtonItems,
  }) => {
    const dropdownProps = {
      headerSelectButtonLabel: 'Planilha',
      headerSelectButtonItems: headerSelectButtonItems,
      headerSelectButtonIcon: <Icon name={IconNames['chevron-down']} />,
      headerSelectButtonIconPosition: ButtonIconPosition.right,
    }

    const singleButtonProps =
      headerSelectButtonItems.length === 1
        ? {
            headerButtonLabel: headerSelectButtonItems[0].name,
            headerButtonIcon: (
              <Icon name={headerSelectButtonItems[0].icon as IconNames} />
            ),
            headerButtonAction: headerSelectButtonItems[0].action,
          }
        : null

    return (
      <BoxedLayout
        title="Movimentação coletiva"
        date={new Date().toISOString()}
        headerHighlightedLabel="Setor"
        headerHighlightedData={collectiveMovement?.destinationSector?.label}
        headerLargeButtonAction={handleFinishCollectiveMovement}
        headerLargeButtonLabel="Finalizar"
        {...(headerSelectButtonItems.length > 1
          ? dropdownProps
          : singleButtonProps)}
      >
        <div className={styles.reviewFarm}>
          <div>
            <Typography text="Origem" variant={TypographyVariant.h5} />
            <Divider className={styles.reviewFarmDivider} />

            <div className={styles.selectedItem}>
              <Typography
                text="Fazenda"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip
                label={collectiveMovement?.originFarm?.label}
                selected={false}
              />
            </div>

            <div className={styles.selectedItem}>
              <Typography
                text="Setor"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip
                label={collectiveMovement?.originSector?.label}
                selected={false}
              />
            </div>
          </div>

          <div>
            <Typography text="Destino" variant={TypographyVariant.h5} />
            <Divider className={styles.reviewFarmDivider} />

            <div className={styles.selectedItem}>
              <Typography
                text="Fazenda"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip label={collectiveMovement?.destinationFarm?.label} />
            </div>

            <div className={styles.selectedItem}>
              <Typography
                text="Setor"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip label={collectiveMovement?.destinationSector?.label} />
            </div>
          </div>
        </div>

        {animals && (
          <>
            <BoxedTable
              data={animals?.items || []}
              table={table}
              quantityLabel="Entradas"
              title="Animais atualizados com sucesso"
              noDataMessage="Nenhum animal atualizado com sucesso."
              currentPage={page}
              totalPages={animals?.pages}
              totalItems={animals?.total}
              setPage={setPage}
              isLoading={isLoading}
              disableNavigation
            />
          </>
        )}
      </BoxedLayout>
    )
  }

export { CollectiveMovementsFeedbackTemplate }
