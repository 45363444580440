import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { SearchTable } from 'components/molecules'
import { CustomTable } from 'components/organisms'

import { NavigateList } from 'app/core/routes/routes'
import { FarmProps } from 'app/core/types/system'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type FarmsTabTemplateProps = {
  farms: FarmProps[] | undefined
  table: Table<FarmProps>
  setGlobalFilter: Dispatch<SetStateAction<string>>
  isLoading: boolean
}

const FarmsTabTemplate: React.FC<FarmsTabTemplateProps> = ({
  farms,
  table,
  setGlobalFilter,
  isLoading,
}) => {
  const history = useHistory<NavigateList>()

  if (!farms)
    return (
      <Typography text={Messages.EMPTY_DATA} variant={TypographyVariant.p} />
    )

  return (
    <>
      <header className={styles.farmsHeader}>
        <SearchTable setGlobalFilter={setGlobalFilter} />

        <button
          className={styles.farmsHeaderButton}
          type="button"
          onClick={(): void => history.push(NavigateList.farmList)}
        >
          <Icon name={IconNames['information']} />
          <span>
            Fazendas são atualizadas no sistema <b>Gestão de Rebanhos.</b>
          </span>
        </button>
      </header>

      <CustomTable table={table} isLoading={isLoading} disableNavigation />
    </>
  )
}

export { FarmsTabTemplate }
