import classNames from 'classnames'

import {
  Button,
  ButtonSize,
  ButtonType,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { withLoading } from 'components/molecules'
import { Col, InfoCard, Row } from 'components/templates/animal-layout/helpers'

import { AnimalListProps } from 'app/core/types/animal'
import { AnimalEvaluations } from 'app/core/types/evaluation'
import { dateTimeFormat } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from '../styles.module.scss'
import { LastCalfLoadingTemplate } from './loading'

type LastCalfCardProps = {
  animal?: AnimalListProps
  handleSelectedCalf: (calfId: number) => void
  isLoading: boolean
  onClick: (event: React.MouseEvent<HTMLDivElement>, calfId: number) => void
}

const LastCalf: React.FC<LastCalfCardProps> = ({
  animal,
  handleSelectedCalf,
  isLoading,
  onClick,
}) => {
  const isCalfInactive = animal && !animal.is_active

  return (
    <InfoCard title="Última cria">
      {withLoading(
        <>
          {animal ? (
            <div onClick={(e): void => onClick(e, animal.id)}>
              <Row className={styles.lastCalfRow}>
                <Col>
                  <Typography
                    text="Nº de Plantel"
                    variant={TypographyVariant.caption3}
                    altColor
                  />
                  <Typography
                    text={animal.stock_number || 'Sem número'}
                    variant={TypographyVariant.p}
                  />
                </Col>
                <Col>
                  <Typography
                    text="Nº de Nascimento"
                    variant={TypographyVariant.caption3}
                    altColor
                  />
                  <Typography
                    text={animal.birth_number || 'Sem número'}
                    variant={TypographyVariant.p}
                  />
                </Col>

                <Col>
                  <Typography
                    text="Data de Nascimento"
                    variant={TypographyVariant.caption3}
                    altColor
                  />
                  <Typography
                    text={dateTimeFormat(animal.birth_date)}
                    variant={TypographyVariant.p}
                  />
                </Col>

                {animal.evaluations && animal.evaluations?.length > 0 && (
                  <>
                    <Col>
                      <Typography
                        text="Últ. Nota"
                        variant={TypographyVariant.caption3}
                        altColor
                      />
                      <Typography
                        text={AnimalEvaluations[animal?.evaluations[0]?.score]}
                        variant={TypographyVariant.p}
                      />
                    </Col>

                    <Col>
                      <Typography
                        text="Últ. Avaliação"
                        variant={TypographyVariant.caption3}
                        altColor
                      />
                      <Typography
                        text={dateTimeFormat(animal?.evaluations[0]?.date)}
                        variant={TypographyVariant.p}
                      />
                    </Col>
                  </>
                )}

                <Col>
                  <Button
                    disabled={isCalfInactive}
                    type={ButtonType.outline}
                    label="Avaliar"
                    size={ButtonSize.medium}
                    {...(isCalfInactive && {
                      tooltip: Messages.EVALUATION_INACTIVE_ANIMAL_MESSAGE,
                    })}
                    {...(!isCalfInactive && {
                      onClick: (): void => handleSelectedCalf(animal.id),
                    })}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <p className={classNames(styles.empty, styles.emptyCard)}>
              Não há registro de crias para este animal.
            </p>
          )}
        </>
      )(isLoading, LastCalfLoadingTemplate)}
    </InfoCard>
  )
}

export { LastCalf }
