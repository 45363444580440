import { useServiceOrderFilters } from 'providers/service-orders/filters'

import { CheckboxButton } from 'components/atoms'

import { ServiceType } from 'app/core/types/system'

import styles from './styles.module.scss'

const ServiceOrderServiceTypeFilter: React.FC = () => {
  const {
    mechanizationCheckbox,
    setMechanizationCheckbox,
    generalServiceCheckbox,
    setGeneralServiceCheckbox,
  } = useServiceOrderFilters()

  return (
    <div className={styles.checkbox}>
      <CheckboxButton
        name="mecanizacao"
        options={[
          {
            label: ServiceType.mechanization,
            value: mechanizationCheckbox,
          },
        ]}
        defaultChecked={{
          label: ServiceType.mechanization,
          value: mechanizationCheckbox,
        }}
        onChange={(e): void =>
          setMechanizationCheckbox(e.currentTarget.checked.toString())
        }
      />
      <CheckboxButton
        name="servico-gerais"
        options={[
          {
            label: ServiceType.generalService,
            value: generalServiceCheckbox,
          },
        ]}
        defaultChecked={{
          label: ServiceType.generalService,
          value: generalServiceCheckbox,
        }}
        onChange={(e): void =>
          setGeneralServiceCheckbox(e.currentTarget.checked.toString())
        }
      />
    </div>
  )
}

export { ServiceOrderServiceTypeFilter }
