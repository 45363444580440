import React from 'react'

import classNames from 'classnames'

import { Icon, IconNames } from 'components/atoms'
import { IInputProps } from 'components/types/input'

import { AnimalSex } from 'app/core/types/animal'

import { Typography, TypographyVariant } from '../typography'
import styles from './styles.module.scss'

export interface ICheckboxButtonOption {
  label?: string
  value: string
}

export interface ICheckboxButtonProps extends IInputProps {
  testId?: string
  title?: string
  name: string
  options: ICheckboxButtonOption[]
  defaultChecked?: ICheckboxButtonOption
  containerClassName?: string
  /**
   * Change the checkbox style to Chip-like buttons
   */
  chipButtonMode?: boolean
}

const CheckboxButton = React.forwardRef<HTMLInputElement, ICheckboxButtonProps>(
  (
    {
      testId,
      title,
      options,
      name,
      onChange,
      onBlur,
      defaultChecked,
      chipButtonMode,
      containerClassName,
      ...restProps
    },
    ref
  ): JSX.Element => {
    const animalSexIconMapping = {
      [AnimalSex.female]: IconNames.female,
      [AnimalSex.male]: IconNames.male,
    }

    return (
      <div
        data-testid={testId}
        className={
          chipButtonMode
            ? styles.chipButtonContainer
            : styles.checkboxButtonContainer
        }
      >
        {title && <Typography text={title} variant={TypographyVariant.label} />}

        <div
          className={classNames(
            styles.checkboxButtonWrapper,
            containerClassName
          )}
        >
          {options.map((option, i) => (
            <label
              htmlFor={`input-${name}-${option.value}`}
              className={styles.checkboxButtonLabel}
              key={i}
            >
              <input
                id={`input-${name}-${option.value}`}
                type="checkbox"
                value={option.value}
                onChange={onChange}
                onBlur={onBlur}
                {...restProps}
                name={name}
                ref={ref}
                className={styles.checkboxButton}
                defaultChecked={defaultChecked?.value === option.value}
              />
              {option.label && (
                <span className={styles.checkboxValue}>
                  {Object.values(AnimalSex).includes(
                    option.label as AnimalSex
                  ) && (
                    <Icon
                      name={animalSexIconMapping[option.label as AnimalSex]}
                    />
                  )}
                  {option.label}
                </span>
              )}
            </label>
          ))}
        </div>
      </div>
    )
  }
)

export { CheckboxButton }
