import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  StockNumberAnimalsResponseData,
  StockNumberCreateProps,
} from 'app/core/types/animal'
import { Messages } from 'config/messages'

type StockNumberReviewTemplateProps = {
  animals: StockNumberAnimalsResponseData | undefined
  handleGoToFeedback: () => void
  isLoading: boolean
  page: number
  setPage: Dispatch<SetStateAction<number>>
  table: Table<StockNumberCreateProps>
}

const StockNumberReviewTemplate: React.FC<StockNumberReviewTemplateProps> = ({
  animals,
  handleGoToFeedback,
  isLoading,
  page,
  setPage,
  table,
}) => {
  return (
    <BoxedLayout
      backButton
      title="Atualização de número de plantel"
      headerLargeButtonAction={handleGoToFeedback}
      headerLargeButtonLabel="Salvar"
      headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
    >
      <BoxedTable
        data={animals?.items || []}
        table={table}
        quantityLabel="Entradas"
        title="Animais"
        noDataMessage={Messages.ANIMAL_NOT_ADDED}
        currentPage={page}
        totalPages={animals?.pages}
        totalItems={animals?.total}
        setPage={setPage}
        isLoading={isLoading}
        disableLastCellNavigation
      />
    </BoxedLayout>
  )
}

export { StockNumberReviewTemplate }
