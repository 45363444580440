import { AnimalSexLabel } from './animal'

export type DashboardAnimalCount = {
  farm_name: string
  animal_sex: AnimalSexLabel
  age_grouped_counts: {
    label: string
    count: number
    animal_sex: AnimalSexLabel
  }[]
} & Record<string, number>

export type DashboardAnimalUnit = {
  farm_name: string
  animal_sex: AnimalSexLabel
  date: string
  age_grouped_animal_units: {
    label: string
    animal_unit: number
    animal_sex: AnimalSexLabel
  }[]
} & Record<string, number>

export type HistoricalPrecipitationPeriod = Record<string, number>

export type PrecipitationsResponseData = Record<
  string,
  Record<string, Record<string, number>>
>

export type HistoricalPrecipitationsResponseData = {
  historical: HistoricalPrecipitationPeriod
  current: HistoricalPrecipitationPeriod
}

export type PrecipitationsPeriodRequest = {
  start_date: string
  end_date: string
  station_ids?: number | number[]
  farm_id: number
}

export enum HistoricalPrecipitationsType {
  current_year = 0,
  last_three_years = 1,
}

export type HistorialPrecipitationsPeriodRequest = {
  type: HistoricalPrecipitationsType
  station_id: number
}

export enum PrecipitationsChartType {
  year = 'year',
  month = 'month',
}

export enum PrecipitationsFilterType {
  farm = 'farm',
  station = 'stations',
}

export type PrecipitationsProps = {
  period: string
  mean: number
  total: number
  monthNumber?: number
  yearNumber?: number
  accumulatedMean?: number
}

export type HistorialPrecipitationsPeriodProps = {
  month: string
  monthFullName?: string
  monthYear?: string
  mean: number | null
  historicalMean?: number
}

export type HistorialPrecipitationsProps = {
  historical: HistorialPrecipitationsPeriodProps[]
  current:
    | HistorialPrecipitationsPeriodProps[][]
    | HistorialPrecipitationsPeriodProps[]
}

export type PrecipitationsMultiProps = {
  label: string
  stacks: Record<
    string,
    {
      label: string
      payload: {
        mean: number
      }
    }
  >
}

export type PrecipitationsPayloadFormatProps = {
  startDate: Date
  endDate: Date
  chartType: PrecipitationsChartType
}

export enum AnimalUnitPeriodTypes {
  last_twelve_months = 'last_twelve_months',
  last_three_years = 'last_three_years',
  last_five_years = 'last_five_years',
  since_beginning = 'since_beginning',
}

export enum PrecipitationPeriodTypes {
  last_twelve_months = 'last_twelve_months',
  last_five_years = 'last_five_years',
  current_year = 'current_year',
  since_beginning = 'since_beginning',
}
