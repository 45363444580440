import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import styles from './styles.module.scss'

type ServiceOrderCountProps = {
  total: number
}

const ServiceOrderCount: React.FC<ServiceOrderCountProps> = ({ total }) => {
  return (
    <div className={styles.bulletin}>
      <Icon name={IconNames['file-tray']} />
      <Typography
        text={`${total} Boletins lançados`}
        variant={TypographyVariant.h6}
        className={styles.bulletinText}
      />
    </div>
  )
}

export { ServiceOrderCount }
