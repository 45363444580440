import { useCallback, useEffect, useState } from 'react'

import { getAllUsers, patchUpdateUser } from 'app/core/services'
import { UserHook } from 'app/core/types/hooks'
import {
  IUpdateUserRequestData,
  UserEntity,
  UserType,
} from 'app/core/types/user'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

const useUser = (listInactiveUsers?: boolean): UserHook => {
  const [users, setUsers] = useState<UserEntity[]>()
  const [isLoading, setIsLoading] = useState(false)

  const readAllUsers = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)

      const users = await getAllUsers({
        user_type: [UserType.regular, UserType.admin],
      })

      const filteredUsers = users
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(user => user.is_active || listInactiveUsers)

      setUsers(filteredUsers)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [listInactiveUsers])

  const updateUser = useCallback(
    async (id: number, data: IUpdateUserRequestData): Promise<UserEntity> => {
      try {
        setIsLoading(true)

        const user = await patchUpdateUser(id, data)

        addToast({
          message: Messages.USER_UPDATE_SUCCESS,
          type: 'success',
        })

        return user
      } catch (e) {
        handleHttpError(e)
        throw e
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    readAllUsers()
  }, [readAllUsers])

  return {
    users,
    updateUser,
    isLoading,
  }
}

export { useUser }
