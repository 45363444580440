import { ColumnDef } from '@tanstack/react-table'

import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { ServiceTypeProps } from '../..'

const serviceTypesListColumns = (): ColumnDef<ServiceTypeProps, unknown>[] => [
  {
    header: 'Tipo de serviço',
    accessorKey: 'name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
]

export { serviceTypesListColumns }
