import { ToastContainer } from 'react-toastify'

import { UserProvider } from './auth-user'
import { MenuProvider } from './menu'
import { ServiceOrderProvider } from './service-orders'

const AppProviders: React.FC = ({ children }) => (
  <>
    <ServiceOrderProvider>
      <UserProvider>
        <MenuProvider>{children}</MenuProvider>
        <ToastContainer />
      </UserProvider>
    </ServiceOrderProvider>
  </>
)

export { AppProviders }
