const scrollToTop = (isDisabled?: boolean): void => {
  if (!isDisabled) {
    window.scrollTo(0, 0)
  }
}

const scrollToLeft = (): void => {
  window.scrollTo({ left: 0, behavior: 'smooth' })
}

export { scrollToTop, scrollToLeft }
