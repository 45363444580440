import classNames from 'classnames'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Card,
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { Col, InfoCard, Row } from 'components/templates/animal-layout/helpers'

import { MatingRecommendationProps } from 'app/core/types/mating-recommendation'
import { dateTimeFormat } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from '../styles.module.scss'

type AnimalRecommendationsProps = {
  animalRecommendations?: MatingRecommendationProps[]
  handleSelectedRecommendation: (
    recommendation: MatingRecommendationProps
  ) => void
}

const AnimalRecommendations: React.FC<AnimalRecommendationsProps> = ({
  animalRecommendations,
  handleSelectedRecommendation,
}) => (
  <InfoCard title="Animais recomendados para acasalamento" fullWidth>
    {animalRecommendations?.length ? (
      animalRecommendations?.map(recommendation => (
        <Card key={recommendation.id}>
          <Row>
            <Col>
              <Typography
                text="Nome"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={recommendation?.male_name || '-'}
                variant={TypographyVariant.p}
              />
            </Col>

            <Col>
              <Typography
                text="Nº de Plantel"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={recommendation?.male_stock_number || 'Sem número'}
                variant={TypographyVariant.p}
              />
            </Col>

            <Col>
              <Typography
                text="Nº de Nascimento"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={recommendation?.male_birth_number || 'Sem número'}
                variant={TypographyVariant.p}
              />
            </Col>

            <Col className={styles.pullLeft}>
              <Typography
                text="Data"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography
                text={dateTimeFormat(recommendation?.date)}
                variant={TypographyVariant.p}
              />
            </Col>

            {recommendation?.male_is_active && (
              <Col>
                <Typography
                  text="Status"
                  variant={TypographyVariant.caption3}
                  altColor
                />
                <Chip
                  colorVariant={
                    recommendation?.male_is_active
                      ? ChipColorVariant.statusActive
                      : ChipColorVariant.statusInactive
                  }
                />
              </Col>
            )}

            <Col>
              <Button
                type={ButtonType.ghost}
                label="Editar"
                size={ButtonSize.medium}
                iconPosition={ButtonIconPosition.left}
                icon={<Icon name={IconNames.pencil} />}
                onClick={(): void =>
                  handleSelectedRecommendation(recommendation)
                }
              />
            </Col>
          </Row>
        </Card>
      ))
    ) : (
      <p className={classNames(styles.empty, styles.emptyCard)}>
        {Messages.MATING_RECOMMENDATION_EMPTY_DATA}
      </p>
    )}
  </InfoCard>
)

export { AnimalRecommendations }
