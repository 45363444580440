import { CustomArrowProps } from 'react-slick'

import classNames from 'classnames'

import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { CustomCarousel } from 'components/molecules'
import { CHIP_SEX } from 'components/templates/animal-layout/consts'

import { AnimalListProps } from 'app/core/types/animal'
import { AnimalEvaluations } from 'app/core/types/evaluation'
import { dateTimeFormat, formatWeight } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type CalfProps = {
  calf: AnimalListProps
  onClick: (event: React.MouseEvent<HTMLDivElement>, calfId: number) => void
  handleSelectedCalf: (calfId: number) => void
}

const CarouselPrev = (props: CustomArrowProps): JSX.Element => {
  const { className, onClick } = props

  return (
    <button
      className={classNames(
        className,
        styles.evaluationsCarouselArrow,
        styles.evaluationsCarouselArrowPrev
      )}
      onClick={onClick}
    >
      <Icon name={IconNames['chevron-back']} />
    </button>
  )
}

const CarouselNext = (props: CustomArrowProps): JSX.Element => {
  const { className, onClick } = props

  return (
    <button
      className={classNames(
        className,
        styles.evaluationsCarouselArrow,
        styles.evaluationsCarouselArrowNext
      )}
      onClick={onClick}
    >
      <Icon name={IconNames['chevron-forward']} />
    </button>
  )
}

const Calf: React.FC<CalfProps> = ({ calf, onClick, handleSelectedCalf }) => {
  const isCalfInactive = !calf.is_active
  const calfHasEvaluations = calf.evaluations && calf.evaluations.length > 0

  const carouselSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CarouselPrev />,
    nextArrow: <CarouselNext />,
    className: styles.evaluationsCarousel,
  }

  return (
    <Card>
      <div
        className={styles.calfCard}
        onClick={(e): void => onClick(e, calf.id)}
      >
        <div className={styles.calfStatus}>
          <div>
            <Chip
              colorVariant={
                calf.is_active
                  ? ChipColorVariant.statusActive
                  : ChipColorVariant.statusInactive
              }
            />
          </div>

          <Button
            disabled={isCalfInactive}
            type={ButtonType.outline}
            label="Avaliar"
            size={ButtonSize.medium}
            onClick={(): void => handleSelectedCalf(calf.id)}
            {...(isCalfInactive && {
              tooltip: Messages.EVALUATION_INACTIVE_ANIMAL_MESSAGE,
            })}
          />
        </div>

        <div className={styles.calfInfo}>
          <div className={styles.calfInfoItem}>
            <Typography
              text="Nº de Plantel"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={calf.stock_number || 'Sem número'}
              variant={TypographyVariant.p}
            />
          </div>

          <div className={styles.calfInfoItem}>
            <Typography
              text="Nº de Nascimento"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={calf.birth_number || 'Sem número'}
              variant={TypographyVariant.p}
            />
          </div>

          <div className={classNames(styles.calfInfoItem, styles.calfFather)}>
            <Typography
              text="Pai/Grupo de repasse"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={calf.father_stock_number || '-'}
              variant={TypographyVariant.p}
            />
          </div>

          <div className={styles.calfInfoItem}>
            <Typography
              text="Sexo"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Chip colorVariant={CHIP_SEX[calf.sex]} />
          </div>

          {calfHasEvaluations && (
            <>
              <div className={styles.calfInfoItem}>
                <Typography
                  text="Peso na desmama"
                  variant={TypographyVariant.caption3}
                  altColor
                />
                <Typography
                  text={formatWeight(
                    calf.evaluations && calf.evaluations[0]?.weaning_weight
                  )}
                  variant={TypographyVariant.p}
                />
              </div>

              <div className={styles.calfInfoItem}>
                <Typography
                  text="GMD"
                  variant={TypographyVariant.caption3}
                  altColor
                />
                <Typography
                  text={formatWeight(
                    calf.evaluations && calf.evaluations[0]?.average_daily_gain
                  )}
                  variant={TypographyVariant.p}
                />
              </div>
            </>
          )}
        </div>

        {calfHasEvaluations ? (
          <div>
            <Typography
              text="Avaliações"
              variant={TypographyVariant.caption3}
              altColor
            />

            <div className={styles.calfEvaluations}>
              <CustomCarousel {...carouselSettings}>
                {calf.evaluations?.map(evaluation => (
                  <div className={styles.evaluationCard} key={evaluation?.id}>
                    <div className={styles.evaluationCardInfo}>
                      <Typography
                        text="Nota"
                        variant={TypographyVariant.caption3}
                        altColor
                      />
                      <Typography
                        text={AnimalEvaluations[evaluation?.score]}
                        variant={TypographyVariant.h4}
                        altColor
                      />
                      <Typography
                        className={styles.evaluationCardDate}
                        text={dateTimeFormat(evaluation?.date)}
                        variant={TypographyVariant.caption3}
                      />
                    </div>
                    <Typography
                      text={evaluation?.evaluation}
                      variant={TypographyVariant.p}
                      altColor
                    />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.calfEvaluationsEmpty}>
              {Messages.EVALUATION_CALF_EMPTY_DATA}
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export { Calf }
