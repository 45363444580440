/* eslint-disable @typescript-eslint/naming-convention */
import * as XLSX from 'xlsx'

import { getReadAllAnimals } from 'app/core/services'
import {
  AnimalCategory,
  AnimalFilterProps,
  AnimalSex,
  ImportedShutdownProps,
} from 'app/core/types/animal'
import { ShutdownCreateProps } from 'app/core/types/shutdown'
import { parseNumberFromSheet } from 'app/core/utils'

const xlsxToShutdown = async (
  content: string
): Promise<Partial<ShutdownCreateProps>[]> => {
  const data = new Uint8Array(content as unknown as ArrayBuffer)
  const workbook = XLSX.read(data, {
    type: 'array',
    cellText: false,
    cellDates: true,
  })

  const shutdowns = [] as Partial<ShutdownCreateProps>[]

  await Promise.all(
    workbook.SheetNames.map(async sheetName => {
      const worksheet = workbook.Sheets[sheetName]
      const rows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false,
        dateNF: 'd"/"m"/"yyyy',
        raw: true,
      }) as ImportedShutdownProps[]

      for (let i = 1; i < rows.length; i++) {
        const row = rows[i]

        const importedStockNumber = String(row[0] || '').trim()
        const importedElectronicEartag = String(row[0] || '').trim()
        const importedBirthNumber = String(row[2] || '').trim()
        const weight = row[3] ? parseNumberFromSheet(row[3]) : null

        const filters = {
          stock_number_or_birth_number_or_electronic_eartag: String(
            row[0] || row[1] || row[2]
          )
            .trim()
            .replace(/\n/g, ''),
        } as AnimalFilterProps

        const animal = (await getReadAllAnimals(filters)).items[0]

        const shutdown: Partial<ShutdownCreateProps> = animal
          ? {
              birth_number: animal.birth_number,
              electronic_eartag: animal.electronic_eartag,
              sex: animal.sex,
              stock_number: animal.stock_number,
              category: animal.category as AnimalCategory,
              id: animal.id,
              weight: weight || undefined,
            }
          : {
              sex: AnimalSex.male,
              category: AnimalCategory.growing,
              birth_number: importedBirthNumber,
              electronic_eartag:
                importedElectronicEartag !== ''
                  ? importedElectronicEartag
                  : undefined,
              stock_number: importedStockNumber,
              weight: weight || undefined,
              error_message: 'Animal não encontrado.',
            }

        shutdowns.push(shutdown)
      }
    })
  )

  return shutdowns
}

export { xlsxToShutdown }
