import React from 'react'

import classNames from 'classnames'

import { IInputProps } from 'components/types/input'

import { AnimalSex } from 'app/core/types/animal'

import { Icon, IconNames } from '../icon'
import { Typography, TypographyVariant } from '../typography'
import styles from './styles.module.scss'

export interface IRadioButtonOption {
  label: string
  subLabel?: string
  value: string
  disabled?: boolean
}

export interface IRadioButtonProps extends IInputProps {
  title: string
  name: string
  options: IRadioButtonOption[]
  defaultChecked?: IRadioButtonOption
  vertical?: boolean
  className?: string
  classNameTitle?: string
  /**
   * Change the radio style to Chip-like buttons
   */
  chipButtonMode?: boolean
  /**
   * Change the radio style to buttons
   */
  buttonMode?: boolean
}

const animalSexIconMapping = {
  [AnimalSex.female]: IconNames.female,
  [AnimalSex.male]: IconNames.male,
}

const RadioButton = React.forwardRef<HTMLInputElement, IRadioButtonProps>(
  (
    {
      title,
      options,
      name,
      onChange,
      onBlur,
      defaultChecked,
      vertical,
      className,
      classNameTitle,
      chipButtonMode,
      buttonMode,
      ...restProps
    },
    ref
  ): JSX.Element => (
    <div
      className={
        chipButtonMode || buttonMode
          ? styles.chipButtonContainer
          : classNames(styles.radioButtonContainer, className)
      }
    >
      <Typography
        text={title}
        variant={TypographyVariant.label}
        className={classNameTitle}
      />

      <div
        className={styles.radioButtonWrapper}
        style={
          vertical
            ? { flexDirection: 'column', gap: '15px' }
            : { flexDirection: 'row' }
        }
      >
        {options.map((option, i) => (
          <label
            htmlFor={`input-${name}-${option.value}`}
            className={classNames(
              styles.radioButtonLabel,
              (restProps.disabled || option.disabled) &&
                styles.radioButtonLabelDisabled
            )}
            key={i}
          >
            <input
              id={`input-${name}-${option.value}`}
              type="radio"
              value={option.value}
              onChange={onChange}
              onBlur={onBlur}
              {...restProps}
              name={name}
              ref={ref}
              className={styles.radioButton}
              defaultChecked={defaultChecked?.value === option.value}
              disabled={restProps.disabled || option.disabled}
            />
            {chipButtonMode ? (
              <span className={styles.radioValue}>
                {chipButtonMode &&
                  Object.values(AnimalSex).includes(
                    option.label as AnimalSex
                  ) && (
                    <Icon
                      name={animalSexIconMapping[option.label as AnimalSex]}
                    />
                  )}
                {option.label}
              </span>
            ) : buttonMode ? (
              <span className={styles.buttonValue}>
                {option.subLabel ? (
                  <div>
                    <span>{option.label}</span>
                    <span className={styles.subLabel}>{option.subLabel}</span>
                  </div>
                ) : (
                  <span>{option.label}</span>
                )}
              </span>
            ) : (
              option.label
            )}
          </label>
        ))}
      </div>
    </div>
  )
)

export { RadioButton }
