import React from 'react'

import { Icon, IconNames, Select, SelectItemProps } from 'components/atoms'
import { withLoading } from 'components/molecules'

import {
  AnimalUnitPeriodTypes,
  DashboardAnimalUnit,
} from 'app/core/types/dashboard'
import { FarmProps } from 'app/core/types/system'

import { DashboardLoadingTemplate } from '../../loading'
import { DashboardAnimalUnitCategoriesCard } from './cards/category'
import { DashboardAnimalUnitPerHectareCard } from './cards/per-hectare'
import { DashboardAnimalUnitTotalCard } from './cards/total'
import styles from './styles.module.scss'

type DashboardAnimalUnitTabProps = {
  animalUnit?: DashboardAnimalUnit[]
  populateAnimalUnit: (periodType: AnimalUnitPeriodTypes) => Promise<void>
  defaultAnimalUnitPeriod: AnimalUnitPeriodTypes
  farms?: FarmProps[]
}

const selectOptions = [
  {
    label: 'Últimos 12 meses',
    value: AnimalUnitPeriodTypes.last_twelve_months,
  },
  { label: 'Últimos 3 anos', value: AnimalUnitPeriodTypes.last_three_years },
  { label: 'Últimos 5 anos', value: AnimalUnitPeriodTypes.last_five_years },
  { label: 'Desde o início', value: AnimalUnitPeriodTypes.since_beginning },
]

const DashboardAnimalUnitTab: React.FC<DashboardAnimalUnitTabProps> = ({
  animalUnit,
  populateAnimalUnit,
  defaultAnimalUnitPeriod,
  farms,
}) => {
  return (
    <>
      <div className={styles.fields}>
        <div className={styles.field}>
          <span className={styles.fieldLabel}>Período</span>
          <div className={styles.fieldWrapper}>
            <Icon className={styles.fieldIcon} name={IconNames.calendar} />
            <Select
              disabled={animalUnit === undefined}
              name={'period'}
              centerText={false}
              options={selectOptions}
              height="40px"
              valueMargin="0 0 0 30px"
              onChange={(value): void => {
                if ((value as SelectItemProps).value) {
                  populateAnimalUnit(
                    (value as SelectItemProps).value as AnimalUnitPeriodTypes
                  )
                }
              }}
              defaultValue={selectOptions.find(
                option => option.value === defaultAnimalUnitPeriod
              )}
            />
          </div>
        </div>
      </div>
      {withLoading(
        <>
          <DashboardAnimalUnitTotalCard animalUnit={animalUnit} farms={farms} />
          <DashboardAnimalUnitCategoriesCard
            animalUnit={animalUnit}
            farms={farms}
          />
          <DashboardAnimalUnitPerHectareCard
            animalUnit={animalUnit}
            farms={farms}
          />
        </>
      )(animalUnit === undefined, DashboardLoadingTemplate)}
    </>
  )
}

export { DashboardAnimalUnitTab }
