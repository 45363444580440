import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'

import * as yup from 'yup'

import { IconNames, SelectItemProps } from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { CustomModal, ExpandCard } from 'components/molecules'
import { BoxedTable, useForm } from 'components/organisms'

import { useFarm } from 'app/core/hooks'
import { InseminatorsHook } from 'app/core/types/hooks'
import { InseminatorProps } from 'app/core/types/inseminator'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './../../styles.module.scss'
import { getInseminatorColumns } from './table'

type InseminatorFormProps = {
  name: string
  farm_id: SelectItemProps
}

const InseminatorsCard: React.FC<InseminatorsHook> = ({
  inseminators,
  readInseminators,
  createInseminator,
  updateInseminator,
  exportInseminators,
  isLoading,
}) => {
  const { activeFarmsDroplist } = useFarm()

  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState<boolean>(false)
  const [patchItem, setPatchItem] = useState<InseminatorProps | undefined>()
  const validationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    farm_id: yup.mixed<SelectItemProps>().required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleUpsertModalClose = (): void => {
    setPatchItem(undefined)
    setIsUpsertModalOpen(false)
  }

  const handleAddClick = (): void => {
    setIsUpsertModalOpen(true)
    reset()
  }

  const handleFormSubmit = useCallback(
    async (data: unknown): Promise<void> => {
      const { name, farm_id } = data as InseminatorFormProps

      try {
        const id = patchItem?.id

        handleUpsertModalClose()

        if (!id) {
          await createInseminator({ name, farm_id: Number(farm_id.value) })

          addToast({
            message: Messages.INSEMINATOR_CREATE_SUCCESS,
            type: 'success',
          })

          await readInseminators()

          return
        }

        await updateInseminator(id, {
          name,
          farm_id: Number(farm_id.value),
        })

        addToast({
          message: Messages.INSEMINATOR_UPDATE_SUCCESS,
          type: 'success',
        })
        await readInseminators()
      } catch (err) {
        handleHttpError(err, false)
      }
    },
    [createInseminator, patchItem?.id, readInseminators, updateInseminator]
  )

  const { Form, handleSubmit, setValue, reset } = useForm({
    onSubmit: handleFormSubmit,
    validationSchema,
  })

  const handleEditClick = useCallback(
    (item: InseminatorProps): void => {
      reset()
      setValue('name', item.name)
      setValue('farm_id', {
        label: item.farm_name,
        value: item.farm_id,
      })
      setIsUpsertModalOpen(true)
      setPatchItem(item)
    },
    [setValue, reset]
  )

  const columns = useMemo<ColumnDef<InseminatorProps>[]>(
    () => getInseminatorColumns(handleEditClick),
    [handleEditClick]
  )

  const table = useReactTable({
    data: inseminators?.items ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <ExpandCard className={styles.settingsExpandCard} title="Inseminadores">
        <div className={styles.cardContent}>
          <Divider darker />

          <BoxedTable
            className={styles.settingsBoxedTable}
            data={inseminators?.items ?? []}
            title=""
            quantityLabel="Registros"
            table={table}
            mainButtonLabel="Incluir"
            mainButtonIcon={IconNames['add-circle']}
            mainButtonAction={handleAddClick}
            secondaryButtonLabel="Exportar"
            secondaryButtonIcon={IconNames.download}
            secondaryButtonAction={exportInseminators}
            disableNavigation
            isLoading={isLoading}
          />
        </div>

        <CustomModal
          modalIsOpen={!!isUpsertModalOpen}
          handleCloseModal={handleUpsertModalClose}
          modalHeader={
            patchItem ? `Editar inseminador` : 'Cadastrar inseminador'
          }
          primaryButtonLabel="SALVAR"
          primaryButtonAction={handleSubmit(handleFormSubmit)}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleUpsertModalClose}
        >
          <div className={styles.form}>
            <Form>
              <div>
                <div className={styles.formField}>
                  <Form.InputText
                    name="name"
                    placeholder="Inserir nome"
                    label="Nome do inseminador"
                    className={styles.productNameField}
                  />
                </div>
                <div className={styles.formField}>
                  <Form.Select
                    label="Fazenda"
                    name="farm_id"
                    options={activeFarmsDroplist}
                  />
                </div>
              </div>
            </Form>
          </div>
        </CustomModal>
      </ExpandCard>
    </>
  )
}

export { InseminatorsCard }
