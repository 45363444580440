import { SelectItemProps } from 'components/atoms'

import { ImplantUseList } from 'app/core/types/hormonal'

const maximumScore = 5

export const SCORE_DROPLIST: SelectItemProps[] = Array.from(
  Array(maximumScore).keys()
).map(score => ({
  label: (score + 1).toString(),
  value: (score + 1).toString(),
}))

export const IMPLANT_DROPLIST: SelectItemProps[] = Object.keys(
  ImplantUseList
).map(implant => ({
  label: implant,
  value: implant,
}))
