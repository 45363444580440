import { SelectedFiles } from 'use-file-picker'

import {
  ImportLocationResponse,
  LocationCreateRequestData,
  LocationProps,
  LocationUpdateRequestData,
} from 'app/core/types/system'
import { parseSelectedFileToBlob } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/location/'

const getAllLocations = async (farmId?: string): Promise<LocationProps[]> => {
  const params = farmId ? { farm_id: farmId } : null
  const response = await http.get(baseUrl, { params })
  return response.data
}

const postCreateLocation = async (
  request: LocationCreateRequestData
): Promise<LocationProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const patchUpdateLocation = async (
  locationId: number,
  request: LocationUpdateRequestData
): Promise<LocationProps> => {
  const response = await http.patch(`${baseUrl}${locationId}/`, request)
  return response.data
}

const deleteLocation = async (locationId: number): Promise<void> => {
  const response = await http.delete(`${baseUrl}${locationId}/`)
  return response.data
}

const postImportLocation = async (
  file: SelectedFiles
): Promise<ImportLocationResponse[]> => {
  const formData = new FormData()

  const fileBlob = parseSelectedFileToBlob(file)

  formData.append('file', fileBlob.data, fileBlob.name)

  const response = await http.post(`${baseUrl}import/`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export {
  deleteLocation,
  getAllLocations,
  patchUpdateLocation,
  postCreateLocation,
  postImportLocation,
}
