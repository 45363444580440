import Papa from 'papaparse'

import {
  HandlingCreateRequestData,
  HandlingEntity,
  HandlingReadResponseData,
  HandlingResponseData,
  HandlingStreamProps,
} from 'app/core/types/handling'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/handlings/'

const getHandlings = async (
  page?: number,
  filters?: Record<string, unknown>
): Promise<HandlingReadResponseData> => {
  const response = await http.get(baseUrl, {
    params: {
      ...removeEmptyFilters(filters),
      page,
    },
  })

  return response.data
}

const getHandling = async (
  handlingId: number,
  filters: Record<string, unknown>
): Promise<HandlingEntity> => {
  const response = await http.get(`${baseUrl}${handlingId}/`, {
    params: {
      ...removeEmptyFilters(filters),
    },
  })

  return response.data
}

const postAddHandling = async ({
  date,
  type,
  farm_id,
  handlings,
}: HandlingCreateRequestData): Promise<HandlingResponseData> => {
  const response = await http.post(`${baseUrl}`, {
    date,
    type,
    farm_id,
    handlings,
  })

  return response.data
}

const getReadHandlingsStream = async (
  page?: number,
  filters?: Record<string, unknown>
): Promise<HandlingStreamProps[]> => {
  const response = await http.get(`${baseUrl}stream`, {
    params: {
      ...removeEmptyFilters(filters),
      page,
    },
  })

  const handlings: HandlingStreamProps[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    handlings.push({
      type: item[0],
      date: item[1] ? new Date(item[1]) : undefined,
      electronic_eartag: item[2],
      birth_number: item[3],
      stock_number: item[4],
      is_active: item[5] == 'True' ? true : false,
      current_weight: Number(item[6]),
      average_daily_gain: Number(item[7]),
    })
  }

  return handlings
}

export { getHandling, getHandlings, getReadHandlingsStream, postAddHandling }
