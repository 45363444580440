import { Icon, IconNames } from 'components/atoms'
import { CustomModal } from 'components/molecules'
import {
  AnimalEvaluations,
  AnimalInfo,
  AnimalInfoData,
  EvaluateAnimal,
  GenealogyTree,
  SearchAnimal,
} from 'components/organisms'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'
import {
  AnimalHistory,
  InfoCard,
} from 'components/templates/animal-layout/helpers'

import { NavigateList } from 'app/core/routes/routes'
import { AnimalHistoryTypes, IAnimalEntity } from 'app/core/types/animal'
import { EvaluationProps } from 'app/core/types/evaluation'

import styles from './styles.module.scss'

type BirthHistoryTemplateProps = {
  animal?: IAnimalEntity
  animalEvaluations?: EvaluationProps[]
  onSubmitEvaluation: () => void
  isEvaluationModalOpen: boolean
  handleEvaluationModal: () => void
  form: FormType
}

const EvaluationDetailsTemplate: React.FC<BirthHistoryTemplateProps> = ({
  animal,
  animalEvaluations,
  onSubmitEvaluation,
  isEvaluationModalOpen,
  handleEvaluationModal,
  form: Form,
}) => {
  const historyFieldsToDisplay = [
    AnimalHistoryTypes.first_handling,
    AnimalHistoryTypes.fourth_month_handling,
    AnimalHistoryTypes.weaning_handling,
    AnimalHistoryTypes.sanitary_handling,
    AnimalHistoryTypes.fattening_handling,
  ]

  return (
    <>
      <BoxedLayout
        title="Avaliação"
        backButton
        headerLargeButtonAction={handleEvaluationModal}
        headerLargeButtonLabel="Nova avaliação"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
      >
        <SearchAnimal navigation={NavigateList.evaluationDetails} />

        {animal && (
          <section className={styles.animalEvaluation}>
            <AnimalInfo
              animal={animal}
              show={[
                AnimalInfoData.stockNumber,
                AnimalInfoData.birthNumber,
                AnimalInfoData.birthDate,
                AnimalInfoData.sex,
                AnimalInfoData.status,
                AnimalInfoData.averageDailyGain,
                AnimalInfoData.weaningWeight,
              ]}
            />

            <AnimalEvaluations
              evaluations={animalEvaluations}
              isLastEvaluation
            />

            <InfoCard title="Pedigree" collapsible fullWidth>
              <GenealogyTree genealogy={animal.genealogy} />
            </InfoCard>

            <AnimalEvaluations evaluations={animalEvaluations} />

            {animal.history.some(item =>
              historyFieldsToDisplay.includes(item.type)
            ) && (
              <section className={styles.animalHistory}>
                <AnimalHistory
                  history={animal.history}
                  fieldsToDisplay={historyFieldsToDisplay}
                />
              </section>
            )}
          </section>
        )}
      </BoxedLayout>

      <CustomModal
        modalIsOpen={isEvaluationModalOpen}
        handleCloseModal={handleEvaluationModal}
        modalHeader="Nova avaliação de cria"
        primaryButtonLabel="Avaliar"
        primaryButtonAction={onSubmitEvaluation}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={handleEvaluationModal}
      >
        <div className={styles.evaluationFields}>
          <EvaluateAnimal animal={animal} form={Form} />
        </div>
      </CustomModal>
    </>
  )
}

export { EvaluationDetailsTemplate }
