/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react'
import ReactSelect, {
  ActionMeta,
  FormatOptionLabelMeta,
  GroupTypeBase,
  OptionsType,
  components,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import { Option } from 'react-select/src/filters'

import { IInputProps } from 'components/types/input'

export type SelectItemProps = {
  label: string
  subLabel?: string
  value: string
}

export type GroupedOption = {
  label: string
  options: SelectItemProps[]
}

export interface ISelectProps
  extends Omit<
    IInputProps,
    'ref' | 'value' | 'onChange' | 'onBlur' | 'disabled'
  > {
  /**
   * The select options
   */
  options: SelectItemProps[] | undefined | GroupedOption[]
  /**
   * The React input ref
   * This ref usually is used to integrate with react-hook-forms
   */
  ref?: React.Ref<ReactSelect>
  /**
   * The React input ref
   * This ref usually is used to integrate with react-hook-forms
   */
  value?: SelectItemProps[]
  /**
   * Are the options loading
   */
  isLoading?: boolean
  /**
   * Select value function
   */
  onChange?: (
    value: SelectItemProps | OptionsType<SelectItemProps> | null,
    actionMeta: ActionMeta<SelectItemProps>
  ) => void
  /**
   * Default text to display if no option gets selected
   */
  placeholder?: string
  /**
   * Default value
   */
  defaultValue?: SelectItemProps | SelectItemProps[]
  /**
   * Custom width size
   */
  width?: string | number
  /**
   * Disable react-select menuPortalTarget, which forces the input to be attached to document.body
   */
  disableMenuPortalTarget?: boolean
  /**
   * Define disabled state
   */
  disabled?: boolean
  /**
   * Centralize dropdown controller text
   */
  centerText?: boolean
  /**
   * Set justify content - space between
   */
  spaceBetween?: boolean
  /**
   * Custom height size
   */
  height?: string
  /**
   * The margin for the select value
   */
  valueMargin?: string
  /**
   * The select accept multiple choices
   */
  isMulti?: boolean
  /**
   * The select options is default open
   */
  defaultMenuIsOpen?: boolean
  /**
   * Custom format of label
   */
  formatOptionLabel?: (
    option: SelectItemProps,
    labelMeta?: FormatOptionLabelMeta<SelectItemProps, boolean>
  ) => ReactNode
  /**
   * Custom format of group
   */
  formatGroupLabel?: (
    group: GroupedOption | GroupTypeBase<SelectItemProps>
  ) => React.ReactNode
  /**
   * Display a custom content inside the menu
   */
  customMenuListElement?: JSX.Element
  /**
   * Custom no options message
   */
  noOptionsMessage?: string
  /**
   * Flag to work with async API calls
   */
  isAsync?: boolean
  /**
   * Default options
   */
  defaultOptions?: SelectItemProps[] | undefined | GroupedOption[]
  /**
   * Load options
   */
  loadOptions?: (inputValue: string) => Promise<SelectItemProps[]>
  /**
   * The selected can be cleared
   */
  isClearable?: boolean
}

export const Select = React.forwardRef<ReactSelect, ISelectProps>(
  (
    {
      name,
      id,
      placeholder = 'Selecione...',
      defaultValue,
      width = 230,
      height = '50px',
      disableMenuPortalTarget,
      disabled,
      centerText,
      spaceBetween,
      valueMargin = '0',
      isMulti,
      defaultMenuIsOpen,
      formatOptionLabel,
      formatGroupLabel,
      customMenuListElement,
      noOptionsMessage = 'Nenhuma opção encontrada',
      isAsync,
      defaultOptions,
      isLoading,
      loadOptions,
      isClearable,
      ...restProps
    },
    ref
  ): JSX.Element => {
    const NoOptionsMessage = (props: any): JSX.Element => {
      return (
        <components.NoOptionsMessage {...props}>
          <span>{noOptionsMessage}</span>
        </components.NoOptionsMessage>
      )
    }

    const CustomMenuList = (props: any): JSX.Element => {
      return (
        <div>
          <components.MenuList {...props} />
          {customMenuListElement}
        </div>
      )
    }

    const isSearchTextInOption = (
      option: Option,
      searchText: string
    ): boolean => {
      return (
        option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
        option.data.subLabel
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
    }

    const baseSelectStyles = {
      control: (
        baseStyles: any,
        state: { isFocused: any; isDisabled: any }
      ): any => ({
        ...baseStyles,
        minHeight: height,
        borderColor: state.isFocused
          ? 'var(--color-primary-200) !important'
          : state.isDisabled
          ? 'var(--color-neutral-100) !important'
          : 'var(--color-neutral-200) !important',
        backgroundColor: state.isDisabled
          ? 'var(--color-neutral-light-500) !important'
          : 'transparent',
        fontWeight: 'var(--color-neutral-light-500) !important',
        cursor: 'text',
        minWidth: width,
        boxShadow: 'none',
      }),
      container: (baseStyles: any): any => ({
        ...baseStyles,
        zIndex: 0,
      }),
      singleValue: (baseStyles: any, state: { isDisabled: any }): any => ({
        ...baseStyles,
        width: 'calc(100% - 8px)',
        color: state.isDisabled
          ? 'var(--color-neutral-200) !important'
          : 'var(--color-grayish) !important',
        fontWeight: state.isDisabled
          ? 'var(--font-weight-normal) !important'
          : 'var(--font-weight-semibold) !important',
      }),
      valueContainer: (baseStyles: any): any => ({
        ...baseStyles,
        fontSize: 'var(--font-size-normal)',
        letterSpacing: 'var(--letter-space-sm)',
        justifyContent: centerText
          ? 'center'
          : spaceBetween
          ? 'space-between'
          : 'flex-start',
        margin: valueMargin,
      }),
      placeholder: (baseStyles: any, state: { isDisabled: any }): any => ({
        ...baseStyles,
        color: 'var(--color-neutral-300) !important',
        fontWeight: state.isDisabled
          ? 'var(--font-weight-normal) !important'
          : 'var(--font-weight-semibold) !important',
      }),
      dropdownIndicator: (
        baseStyles: any,
        state: { isDisabled: any }
      ): any => ({
        ...baseStyles,
        color: state.isDisabled
          ? 'var(--color-neutral-200) !important'
          : 'var(--color-grayish) !important',
      }),
      indicatorSeparator: (baseStyles: any): any => ({
        ...baseStyles,
        display: 'none',
      }),
      option: (
        baseStyles: any,
        state: { isSelected: any; isFocused: any }
      ): any => ({
        ...baseStyles,
        fontSize: 'var(--font-size-normal)',
        letterSpacing: 'var(--letter-space-sm)',
        backgroundColor: state.isSelected
          ? 'var(--color-primary)'
          : state.isFocused
          ? 'var(--color-primary-50)'
          : 'white',
        cursor: 'pointer',
      }),
      menuPortal: (baseStyles: any): any => ({
        ...baseStyles,
        backgroundColor: 'var(--color-primary)',
        zIndex: 160,
      }),
    }

    const defaultselectProps = {
      inputId: id ?? name,
      classNamePrefix: 'react-select',
      name: name,
      menuPortalTarget: disableMenuPortalTarget ? undefined : document.body,
      components: { NoOptionsMessage, MenuList: CustomMenuList },
      placeholder: placeholder,
      isDisabled: disabled,
      loadingMessage: (): string => 'Carregando...',
      formatOptionLabel: formatOptionLabel,
      formatGroupLabel: formatGroupLabel,
      filterOption: isSearchTextInOption,
      styles: baseSelectStyles,
      isMulti: isMulti,
      defaultValue: defaultValue,
      defaultMenuIsOpen: defaultMenuIsOpen,
      isLoading: isLoading,
      isClearable: isClearable,
    }

    if (isAsync) {
       const restPropsWithoutRef = { ...restProps, ref: undefined }

      return (
        <AsyncSelect
          cacheOptions
          defaultOptions={defaultOptions}
          menuPosition="fixed"
          loadOptions={loadOptions}
          {...defaultselectProps}
          {...restPropsWithoutRef}
        />
      )
    }

    return (
      <ReactSelect
        ref={ref}
        menuPosition="fixed"
        {...defaultselectProps}
        {...restProps}
      />
    )
  }
)
