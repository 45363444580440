import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { BoxedTable } from 'components/organisms'

import { FarmProps } from 'app/core/types/system'

import styles from '../style.module.scss'
import { farmSectorColumn } from './column'

type FarmSectorTableProps = {
  farm: FarmProps
  onSectorEditClick: (sectorId: number) => void
}

const FarmSectorTable: React.FC<FarmSectorTableProps> = ({
  farm,
  onSectorEditClick,
}) => {
  const sectorTable = useReactTable({
    data: farm.sectors,
    columns: farmSectorColumn(onSectorEditClick),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <BoxedTable
        className={styles.sectorTable}
        data={farm.sectors}
        table={sectorTable}
        hideQuantityLabel
        disableNavigation
      />
    </>
  )
}

export { FarmSectorTable }
