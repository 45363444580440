import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { ReproductionLayout } from 'components/templates'

import {
  InseminationAnimalProps,
  InseminationAnimalResponseData,
  InseminationProps,
} from 'app/core/types/hormonal'

type InseminationFeedbackTemplateProps = {
  insemination?: InseminationProps
  animals?: InseminationAnimalResponseData
  table: Table<InseminationAnimalProps>
  setPage: Dispatch<SetStateAction<number>>
  page: number
  isLoading: boolean
  handleFinishInsemination: () => void
  exportFailedInseminations: () => void
  failedInseminations: Partial<InseminationAnimalProps>[]
}

const InseminationFeedbackTemplate: React.FC<InseminationFeedbackTemplateProps> =
  ({
    insemination,
    animals,
    table,
    page,
    setPage,
    isLoading,
    handleFinishInsemination,
    exportFailedInseminations,
    failedInseminations,
  }) => {
    return (
      <ReproductionLayout
        title="Cobertura/Inseminação"
        inseminationType={insemination?.type}
        breedingStationId={insemination?.breeding_station_id}
        breedingStationName={insemination?.breeding_station_name}
        iatfScheduleId={insemination?.iatfschedule_id}
        iatfScheduleName={insemination?.iatfschedule_name}
        headerLargeButtonAction={handleFinishInsemination}
        headerLargeButtonLabel="Finalizar"
        {...(failedInseminations.length > 0 && {
          headerButtonAction: exportFailedInseminations,
          headerButtonLabel: 'Planilha de inconsistências',
          headerButtonIcon: <Icon name={IconNames['alert']} />,
        })}
      >
        <BoxedTable
          data={animals?.items || []}
          table={table}
          currentPage={page}
          totalPages={animals?.pages}
          totalItems={animals?.total}
          setPage={setPage}
          title="Animais atualizados com sucesso"
          noDataMessage="Nenhum animal atualizado com sucesso."
          isTableHeaderSticky
          isLoading={isLoading}
        />
      </ReproductionLayout>
    )
  }

export { InseminationFeedbackTemplate }
