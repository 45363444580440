import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import { getAllUsers } from 'app/core/services'
import { ExecuterHook } from 'app/core/types/hooks'
import { UserEntity, UserType } from 'app/core/types/user'
import { handleHttpError } from 'app/core/utils'

const useExecuter = (farmId?: string): ExecuterHook => {
  const [executers, setExecuters] = useState<UserEntity[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const executersDroplist = executers.map(executer => {
    return {
      label: executer.name,
      subLabel: `${executer.registration_number}`,
      value: `${executer.id}`,
    }
  }) as SelectItemProps[]

  const readAllExecuters = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)
      const data = await getAllUsers({
        user_type: UserType.executer,
        farm_id: Number(farmId),
      })
      setExecuters(data.filter(user => user.is_active))
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [farmId])

  useEffect(() => {
    readAllExecuters()
  }, [readAllExecuters])

  return {
    executers,
    executersDroplist,
    readAllExecuters,
    isLoading,
  }
}

export { useExecuter }
