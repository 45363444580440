import { Table } from '@tanstack/react-table'
import React from 'react'

import { ButtonIconPosition, Icon, IconNames } from 'components/atoms'
import { withLoading } from 'components/molecules'
import {
  AnimalDrawer,
  CustomTable,
  ISelectButtonItem,
} from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { AnimalDrawerType } from 'app/core/types/animal'
import { HandledAnimalProps, HandlingEntity } from 'app/core/types/handling'

type HandlingDetailsTemplateProps = {
  handling?: HandlingEntity
  table: Table<HandledAnimalProps>
  onReset?: () => void
  filterIsActive: boolean
  isLoading: boolean
  isDrawerOpen: boolean
  toggleDrawer: () => void
  activeFilters?: number
  headerSelectButtonItems: ISelectButtonItem[]
  onSubmit: IFormProps['onSubmit']
}

const HandlingDetailsTemplate: React.FC<HandlingDetailsTemplateProps> = ({
  handling,
  table,
  onReset,
  isLoading,
  isDrawerOpen,
  toggleDrawer,
  activeFilters,
  headerSelectButtonItems,
  onSubmit,
}) => {
  if (!handling) {
    return (
      <BoxedLayout title={''} backButton>
        {withLoading(<></>)(true)}
      </BoxedLayout>
    )
  }

  return (
    <>
      <BoxedLayout
        backButton
        title={handling.type}
        date={handling.date}
        dateLabel="Data do Manejo"
        quantity={handling.handlings?.length}
        isChildrenSticky
        headerButtonLabel="Filtrar"
        headerButtonAction={toggleDrawer}
        headerButtonIcon={<Icon name={IconNames.filter} />}
        headerButtonFilterActiveFilters={activeFilters}
        headerSelectButtonLabel="Planilha"
        headerSelectButtonItems={headerSelectButtonItems}
        headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
        headerSelectButtonIconPosition={ButtonIconPosition.right}
      >
        {withLoading(<CustomTable table={table} isHeaderSticky />)(isLoading)}
      </BoxedLayout>

      <AnimalDrawer
        isOpen={isDrawerOpen}
        onToggle={toggleDrawer}
        onReset={onReset}
        onSubmit={onSubmit}
        drawerType={AnimalDrawerType.filter}
      />
    </>
  )
}

export default HandlingDetailsTemplate
