import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Divider,
  Icon,
  IconNames,
  Select,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { Status } from 'components/enums'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  DrawerTitle,
  LabeledSelect,
} from 'components/molecules'
import { BoxedTable, ISelectButtonItem, useForm } from 'components/organisms'
import { FormType, IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import {
  CollectiveMomentsAnimalsResponseData,
  CollectiveMovementProps,
  CollectiveMovementsCreateProps,
} from 'app/core/types/collective-movements'
import {
  ANIMAL_CATEGORY_FILTER,
  ANIMAL_SEX_FILTER,
  ASSOCIATION_ANIMALS_FILTER,
  FilterSections,
  REPRODUCTIVE_STATUS_FILTER,
} from 'app/core/types/filters'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type CollectiveMovementsCreateTemplateProps = {
  animals: CollectiveMomentsAnimalsResponseData | undefined
  collectiveMovement: CollectiveMovementProps | undefined
  handleSelectedItem: (
    name: keyof CollectiveMovementProps,
    value: SelectItemProps
  ) => void
  handleToggleDrawer: () => void
  handleGoToReview: () => void
  table: Table<CollectiveMovementsCreateProps>
  farmsDroplist: SelectItemProps[] | undefined
  isDrawerOpen: boolean
  isLoading: boolean
  originSectors: SelectItemProps[]
  destinationSectors: SelectItemProps[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  onSubmit: IFormProps['onSubmit']
  filterSections: FilterSections
  setActiveFilterSection: Dispatch<SetStateAction<string[]>>
  handleChange: (section: string[] | undefined) => void
  handleDisabled: (name: string) => boolean
  headerSelectButtonItems: ISelectButtonItem[]
  locationForm: FormType
}

const CollectiveMovementsCreateTemplate: React.FC<CollectiveMovementsCreateTemplateProps> =
  ({
    animals,
    collectiveMovement,
    handleSelectedItem,
    handleToggleDrawer,
    handleGoToReview,
    farmsDroplist,
    isDrawerOpen,
    isLoading,
    table,
    originSectors,
    destinationSectors,
    page,
    setPage,
    onSubmit,
    handleDisabled,
    handleChange,
    filterSections,
    setActiveFilterSection,
    headerSelectButtonItems,
    locationForm: LocationForm,
  }) => {
    const { Form, reset } = useForm({ onSubmit })

    const resetFilters = (): void => {
      setActiveFilterSection([])
      reset()
    }

    const hasNoOriginSectors = originSectors?.length === 0
    const hasNoDestinationSectors = destinationSectors?.length === 0

    const selectedOriginSector = collectiveMovement?.originSector?.value

    return (
      <BoxedLayout
        date={new Date().toISOString()}
        title="Movimentação coletiva"
        headerLargeButtonAction={handleGoToReview}
        headerLargeButtonLabel="Revisar"
        headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
        headerLargeButtonIconPosition={ButtonIconPosition.right}
        headerSelectButtonLabel="Planilha"
        headerSelectButtonItems={headerSelectButtonItems}
        headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
        headerSelectButtonIconPosition={ButtonIconPosition.right}
      >
        <div className={styles.filterFarm}>
          <LocationForm>
            <div>
              <Typography text="Origem" variant={TypographyVariant.h5} />
              <Divider className={styles.filterFarmDivider} />
              <LabeledSelect
                label="Fazenda"
                status={Status.default}
                input={
                  <Select
                    name="originFarm"
                    options={farmsDroplist}
                    defaultValue={collectiveMovement?.originFarm}
                    onChange={(value): void => {
                      handleSelectedItem('originFarm', value as SelectItemProps)
                    }}
                    placeholder="Selecione uma fazenda de origem"
                  />
                }
              />
              <LocationForm.Select
                label="Setor"
                name="originSector"
                options={originSectors}
                disabled={hasNoOriginSectors}
                defaultValue={collectiveMovement?.originSector}
                onChange={(value): void =>
                  handleSelectedItem('originSector', value as SelectItemProps)
                }
                placeholder={
                  hasNoOriginSectors
                    ? 'Selecione uma fazenda de origem'
                    : 'Selecione um setor de origem'
                }
              />
            </div>

            <div>
              <Typography text="Destino" variant={TypographyVariant.h5} />
              <Divider className={styles.filterFarmDivider} />
              <LabeledSelect
                label="Fazenda"
                status={Status.default}
                input={
                  <Select
                    name="destinationFarm"
                    defaultValue={collectiveMovement?.destinationFarm}
                    options={farmsDroplist}
                    onChange={(value): void =>
                      handleSelectedItem(
                        'destinationFarm',
                        value as SelectItemProps
                      )
                    }
                    placeholder="Selecione uma fazenda de destino"
                  />
                }
              />
              <LocationForm.Select
                label="Setor"
                name="destinationSector"
                defaultValue={collectiveMovement?.destinationSector}
                disabled={hasNoDestinationSectors}
                options={destinationSectors}
                onChange={(value): void =>
                  handleSelectedItem(
                    'destinationSector',
                    value as SelectItemProps
                  )
                }
                placeholder={
                  hasNoDestinationSectors
                    ? 'Selecione uma fazenda de destino'
                    : 'Selecione um setor de destino'
                }
              />
            </div>
          </LocationForm>
        </div>

        {
          <>
            <BoxedTable
              data={animals?.items || []}
              table={table}
              quantityLabel="Entradas"
              title="Animais"
              mainButtonLabel="Incluir"
              mainButtonIcon={IconNames['add-circle']}
              mainButtonAction={handleToggleDrawer}
              mainButtonProps={{
                disabled: !selectedOriginSector,
                ...(!selectedOriginSector
                  ? { tooltip: Messages.COLLECTIVE_MOVEMENTS_SELECT_ORIGIN }
                  : {}),
              }}
              noDataMessage={Messages.ANIMAL_NOT_ADDED}
              currentPage={page}
              totalPages={animals?.pages}
              totalItems={animals?.total}
              setPage={setPage}
              isLoading={isLoading}
              disableNavigation
            />

            <CustomDrawer
              title="Filtrar"
              isOpen={isDrawerOpen}
              onClose={handleToggleDrawer}
              reset={resetFilters}
            >
              <Form>
                <DrawerTitle text="Informações gerais" />

                <DrawerRow>
                  <DrawerCol>
                    <Form.InputText
                      label="Nº de Brinco Eletrônico"
                      placeholder="Pesquisar nº de brinco eletrônico"
                      name="electronic_eartag"
                      disabled={handleDisabled('electronic_eartag')}
                      onChange={(): void =>
                        handleChange(filterSections?.generalInfoSection)
                      }
                    />
                  </DrawerCol>
                  <DrawerCol>
                    <Form.InputText
                      label="Nº de Nascimento"
                      placeholder="Pesquisar nº de nascimento"
                      name="birth_number"
                      disabled={handleDisabled('birth_number')}
                      onChange={(): void =>
                        handleChange(filterSections?.generalInfoSection)
                      }
                    />
                  </DrawerCol>
                  <DrawerCol>
                    <Form.InputText
                      label="Nº de Plantel"
                      placeholder="Pesquisar nº de plantel"
                      name="stock_number"
                      disabled={handleDisabled('stock_number')}
                      onChange={(): void =>
                        handleChange(filterSections?.generalInfoSection)
                      }
                    />
                  </DrawerCol>
                </DrawerRow>

                <DrawerRow>
                  <DrawerCol>
                    <Form.Select
                      label="Categoria"
                      name="categories"
                      options={ANIMAL_CATEGORY_FILTER}
                      disabled={handleDisabled('categories')}
                      onChange={(): void =>
                        handleChange(filterSections?.generalInfoSection)
                      }
                    />
                  </DrawerCol>
                  <DrawerCol>
                    <Form.InputText
                      label="Nome do Animal"
                      placeholder="Pesquisar nome"
                      name="name"
                      disabled={handleDisabled('name')}
                      onChange={(): void =>
                        handleChange(filterSections?.generalInfoSection)
                      }
                    />
                  </DrawerCol>
                  <DrawerCol column>
                    <label htmlFor="init_date">Período de Nascimento</label>
                    <div>
                      <Form.InputText
                        name="init_date"
                        htmlType="date"
                        disabled={handleDisabled('init_date')}
                        onChange={(): void =>
                          handleChange(filterSections?.generalInfoSection)
                        }
                      />

                      <Form.InputText
                        name="final_date"
                        htmlType="date"
                        disabled={handleDisabled('final_date')}
                        onChange={(): void =>
                          handleChange(filterSections?.generalInfoSection)
                        }
                      />
                    </div>
                  </DrawerCol>
                </DrawerRow>

                <DrawerRow>
                  <DrawerCol>
                    <Form.RadioButton
                      chipButtonMode
                      title="Sexo do Animal"
                      name="sex"
                      disabled={handleDisabled('sex')}
                      options={ANIMAL_SEX_FILTER}
                      onChange={(): void =>
                        handleChange(filterSections?.generalInfoSection)
                      }
                    />
                  </DrawerCol>
                </DrawerRow>

                <DrawerTitle text="Reprodução" />

                <DrawerRow>
                  <DrawerCol>
                    <Form.InputText
                      label="Desmamaram entre"
                      name="weaning_date_init"
                      htmlType="date"
                      disabled={handleDisabled('weaning_date_init')}
                      onChange={(): void =>
                        handleChange(filterSections?.reproductionSection)
                      }
                    />

                    <Form.InputText
                      emptyLabelSpacing
                      name="weaning_date_end"
                      htmlType="date"
                      disabled={handleDisabled('weaning_date_end')}
                      onChange={(): void =>
                        handleChange(filterSections?.reproductionSection)
                      }
                    />
                  </DrawerCol>
                  <DrawerCol>
                    <Form.CheckboxButton
                      emptyLabelSpacing
                      name="aptitude"
                      options={REPRODUCTIVE_STATUS_FILTER}
                      disabled={handleDisabled('aptitude')}
                      onChange={(): void =>
                        handleChange(filterSections?.reproductionSection)
                      }
                    />
                  </DrawerCol>
                  <DrawerCol>
                    <Form.CheckboxButton
                      emptyLabelSpacing
                      name="association_animal"
                      options={ASSOCIATION_ANIMALS_FILTER}
                      disabled={handleDisabled('association_animal')}
                      onChange={(): void =>
                        handleChange(filterSections?.reproductionSection)
                      }
                    />
                  </DrawerCol>
                </DrawerRow>

                <DrawerButtonContainer>
                  <Button
                    type={ButtonType.primary}
                    label="Aplicar"
                    size={ButtonSize.large}
                  />
                </DrawerButtonContainer>
              </Form>
            </CustomDrawer>
          </>
        }
      </BoxedLayout>
    )
  }

export { CollectiveMovementsCreateTemplate }
