import { debounce } from 'lodash-es'

import { Divider } from 'components/atoms/divider'
import { ExpandCard } from 'components/molecules'

import { FarmParametersHook } from 'app/core/types/hooks'
import { addToast } from 'app/core/utils'

import { Fieldset } from '../../fieldset'
import styles from './../../styles.module.scss'

const FarmCard: React.FC<FarmParametersHook> = ({
  updateFarmParameters,
  farmParameters,
}) => {
  const farmFields = [
    {
      label: 'UA média da fazenda',
      defaultValue: `${farmParameters?.avg_ua ?? ''}`,
      suffix: 'UA média',
      name: 'avg_ua',
    },
  ]

  const handleUpdate = debounce(
    async (name: string, value: string): Promise<void> => {
      if (!value) return
      await updateFarmParameters({ [name]: value })
      addToast({ message: 'Parâmetro atualizado com sucesso', type: 'success' })
    },
    500
  )

  return (
    <>
      <ExpandCard className={styles.settingsExpandCard} title="Fazenda">
        <div className={styles.cardContent}>
          <Divider darker />
          <Fieldset
            fields={farmFields}
            className={styles.reproductionFieldsetSingleField}
            onChange={handleUpdate}
          />
        </div>
      </ExpandCard>
    </>
  )
}
export { FarmCard }
