import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useElectronicEartag } from 'app/core/hooks/electronic-eartag'
import { NavigateList } from 'app/core/routes/routes'
import { ElectronicEartagCreateProps } from 'app/core/types/animal'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { electronicEartagListColumns } from '../tables'
import { ElectronicEartagReviewTemplate } from './template'

const ElectronicEartagReview: React.FC = () => {
  const history = useHistory<NavigateList>()

  const [page, setPage] = useState<number>(1)

  const { animals, isLoading, removeAnimal, updateAnimals } =
    useElectronicEartag({ page })

  const columns = useMemo<ColumnDef<ElectronicEartagCreateProps>[]>(
    () => electronicEartagListColumns(removeAnimal),
    [removeAnimal]
  )

  const table = useReactTable({
    data: (animals?.items as ElectronicEartagCreateProps[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleGoToFeedback = async (): Promise<void> => {
    if (animals?.items.length === 0) {
      addToast({ message: Messages.ANIMAL_NOT_ADDED })
      return
    }

    await updateAnimals()

    history.push(NavigateList.electronicEartagFeedback)
  }

  return (
    <ElectronicEartagReviewTemplate
      animals={animals}
      handleGoToFeedback={handleGoToFeedback}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      table={table}
    />
  )
}

export { ElectronicEartagReview }
