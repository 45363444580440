import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectItemProps } from 'components/atoms'

import { NavigateList } from 'app/core/routes/routes'
import {
  deleteBreedingStation,
  getBreedingStationReport,
  getReadAllAnimals,
  getReadAllBreedingStations,
  getReadBreedingStation,
  getReadBreedingStationFemales,
  getReadBreedingStationMales,
  patchUpdateBreedingStation,
  postAddBreedingStationAnimals,
  postCreateBreedingStation,
  postRemoveBreedingStationAnimal,
} from 'app/core/services'
import {
  AnimalFilterProps,
  AnimalRequestData,
  AnimalSex,
  AnimalSexLabel,
} from 'app/core/types/animal'
import {
  BreedingGroupAnimalResponseData,
  BreedingLoadingState,
  BreedingServiceDetailsProps,
  BreedingStationCreateRequestData,
  BreedingStationFilterProps,
  BreedingStationReadResponseData,
  BreedingStationUpdateRequestData,
} from 'app/core/types/breeding'
import {
  BreedingStationAnimalHook,
  BreedingStationHook,
  BreedingStationHookProps,
} from 'app/core/types/hooks'
import {
  DEFAULT_ITEMS_PER_PAGE_DETAILS,
  DEFAULT_ITEMS_PER_PAGE_LIST,
  FILTER_ANIMALS_SIZE,
} from 'app/core/types/system'
import {
  addToast,
  arrayToCsv,
  dateForFileName,
  dateTimeFormat,
  dateTimeISOFormat,
  downloadFile,
  getFormattedDateValueOrNull,
  getValueOrNull,
  handleHttpError,
  parseInputDateToISO,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import {
  BreedingAnimalReportFactory,
  createGetBreedingAnimalReportParams,
  exportBreedingAnimals,
} from '../breeding-animal-report-factory'

const useBreedingStation = ({
  stationId,
  filters,
  page,
  size,
}: BreedingStationHookProps): BreedingStationHook => {
  const history = useHistory<NavigateList>()

  const [stations, setStations] = useState<BreedingStationReadResponseData>()
  const [stationsDroplistOptions, setStationsDroplistOptions] =
    useState<SelectItemProps[]>()
  const [station, setStation] = useState<BreedingServiceDetailsProps>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllStations = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    try {
      const data = await getReadAllBreedingStations(
        page || 1,
        size || DEFAULT_ITEMS_PER_PAGE_LIST,
        filters
      )

      const STATION_OPTIONS = data.items.map(e => ({
        label: `${dateTimeFormat(e.init_date)}~${dateTimeFormat(
          e.final_date
        )}: ${e.name}`,
        value: `${e.id}`,
      }))

      if (data.total === 0) {
        addToast({
          message: Messages.BREEDING_STATION_NOT_FOUND,
          type: 'warning',
        })
      }

      setStations(data)
      setStationsDroplistOptions(STATION_OPTIONS)
      setIsLoading(false)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [page, size, filters])

  const readStation = useCallback(async (): Promise<void> => {
    if (stationId) {
      try {
        const data = await getReadBreedingStation(stationId)
        setStation(data)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [stationId])

  const addStation = useCallback(
    async (request: BreedingStationCreateRequestData): Promise<void> => {
      if (request) {
        const formatRequest = {
          name: request.name,
          init_date: dateTimeISOFormat(request.init_date),
          final_date: dateTimeISOFormat(request.final_date),
        } as BreedingStationCreateRequestData

        try {
          const data = await postCreateBreedingStation(formatRequest)
          history.push(`${NavigateList.breedingStationDetails}${data.id}`)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [history]
  )

  const updateStation = useCallback(
    async ({
      init_date,
      final_date,
      is_active,
    }: Partial<BreedingStationUpdateRequestData>): Promise<void> => {
      if (stationId) {
        const formatRequest = {
          ...(init_date && {
            init_date: parseInputDateToISO(init_date),
          }),
          ...(final_date && {
            final_date: parseInputDateToISO(final_date),
          }),
          is_active,
        }

        try {
          const data = await patchUpdateBreedingStation(
            stationId,
            formatRequest
          )

          setStation(data)

          addToast({
            message: Messages.BREEDING_STATION_UPDATE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e, false)
        }
      }
    },
    [stationId]
  )

  const deleteStation = useCallback(
    async (breedingStationId: number) => {
      if (breedingStationId) {
        try {
          await deleteBreedingStation(breedingStationId)

          readAllStations()

          addToast({
            message: Messages.BREEDING_STATION_DELETED_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e, false)
        }
      }
    },
    [readAllStations]
  )

  const exportBreedingStation = useCallback(async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getBreedingStationReport(
        filters as BreedingStationFilterProps
      )

      const getAnimalSex = (sex?: string): AnimalSex | '' => {
        if (sex !== undefined) {
          return sex === AnimalSex.female ? AnimalSex.female : AnimalSex.male
        }
        return ''
      }

      const stations = data.map(station => ({
        Nome: station.breeding_station_name,
        'Data início': getFormattedDateValueOrNull(station.init_date),
        'Data fim': getFormattedDateValueOrNull(station.final_date),
        Ativo: station.is_active ? 'Sim' : 'Não',
        'Nº de nascimento': getValueOrNull(station.birth_number),
        'Nº de Plantel': getValueOrNull(station.stock_number),
        'Brinco eletrônico': getValueOrNull(station.electronic_eartag),
        'Sx.': getAnimalSex(station.animal_sex),
      }))

      const blob = arrayToCsv(stations, ';')

      downloadFile({
        data: blob,
        fileName: `estações-de-monta-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({
        message: Messages.BREEDING_STATION_EXPORT_SUCCESS,
        type: 'success',
      })
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters])

  useEffect(() => {
    readAllStations()
    readStation()
  }, [readAllStations, readStation])

  return {
    station,
    stations,
    stationsDroplistOptions,
    addStation,
    updateStation,
    deleteStation,
    isLoading,
    isLoadingExport,
    exportBreedingStation,
  }
}

const useBreedingStationAnimal = ({
  stationId,
  femalePage,
  malePage,
}: BreedingStationHookProps): BreedingStationAnimalHook => {
  const [females, setFemales] = useState<BreedingGroupAnimalResponseData>()
  const [males, setMales] = useState<BreedingGroupAnimalResponseData>()
  const [isLoading, setIsLoading] = useState<BreedingLoadingState>({
    female: false,
    male: false,
  })

  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false)

  const [filterIsActive, setFilterIsActive] = useState<BreedingLoadingState>({
    female: false,
    male: false,
  })

  const readFemales = useCallback(
    async (filters?: Record<string, unknown>): Promise<void> => {
      if (stationId) {
        try {
          const data = await getReadBreedingStationFemales(
            stationId,
            femalePage || 1,
            DEFAULT_ITEMS_PER_PAGE_DETAILS,
            filters
          )

          setFemales(data)
        } catch (e) {
          const message = (e as Error).message
          addToast({ message })
          throw new Error(message)
        }
      }
    },
    [stationId, femalePage]
  )

  const readMales = useCallback(
    async (filters?: Record<string, unknown>): Promise<void> => {
      if (stationId) {
        try {
          const data = await getReadBreedingStationMales(
            stationId,
            malePage || 1,
            DEFAULT_ITEMS_PER_PAGE_DETAILS,
            filters
          )
          setMales(data)
        } catch (e) {
          const message = (e as Error).message
          addToast({ message })
          throw new Error(message)
        }
      }
    },
    [stationId, malePage]
  )

  const addAnimal = useCallback(
    async (filters: AnimalRequestData): Promise<void> => {
      if (stationId) {
        try {
          if (filters.sex === AnimalSexLabel.female) {
            setIsLoading({ ...isLoading, female: true })
          } else {
            setIsLoading({ ...isLoading, male: true })
          }

          const newFilters = {
            ...filters,
            breeding_station: 'true',
            aptitude: 'true',
            is_active: 'true',
          } as AnimalFilterProps

          const animals = await getReadAllAnimals(
            newFilters,
            1,
            FILTER_ANIMALS_SIZE
          )
          const animalIds = animals?.items.map(animal => animal.id)

          if (!animalIds.length) {
            addToast({ message: Messages.NOT_FOUND_BREEDING_ANIMALS_FILTER })

            setIsLoading({ ...isLoading, female: false, male: false })

            return
          }

          addToast({
            message: Messages.BREEDING_STATION_ADDING_ANIMALS,
            type: 'info',
          })

          await postAddBreedingStationAnimals(stationId, animalIds)

          readFemales()
          readMales()

          setIsLoading({ ...isLoading, female: false, male: false })

          addToast({
            message: Messages.BREEDING_ANIMALS_ADDED,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading({ ...isLoading, female: false, male: false })
        }
      }
    },
    [stationId, readFemales, readMales, isLoading]
  )

  const removeAnimal = useCallback(
    async (animalId: number): Promise<void> => {
      if (stationId) {
        try {
          await postRemoveBreedingStationAnimal(stationId, animalId)
          readFemales()
          readMales()
        } catch (e) {
          const message = (e as Error).message
          addToast({ message })
          throw new Error(message)
        }
      }
    },
    [stationId, readFemales, readMales]
  )

  const filterAnimals = useCallback(
    async (filters?: AnimalRequestData): Promise<void> => {
      if (stationId) {
        try {
          const newFilters = {
            ...filters,
            size: DEFAULT_ITEMS_PER_PAGE_DETAILS,
          } as AnimalFilterProps

          const sex = filters?.sex

          sex == AnimalSexLabel.female
            ? setIsLoading({ ...isLoading, female: true })
            : setIsLoading({ ...isLoading, male: true })

          sex == AnimalSexLabel.female
            ? await readFemales(newFilters)
            : await readMales(newFilters)

          const filtersWithoutSex = { ...filters } as Record<string, unknown>
          delete filtersWithoutSex['sex']

          setFilterIsActive(prevState => ({
            ...prevState,
            [sex == AnimalSexLabel.female
              ? AnimalSexLabel.female
              : AnimalSexLabel.male]: !!Object.keys(filtersWithoutSex).length,
          }))
        } catch (e) {
          const message = (e as Error).message
          addToast({ message })
          throw new Error(message)
        } finally {
          setIsLoading({ ...isLoading, female: false, male: false })
        }
      }
    },
    [stationId, readFemales, readMales, isLoading]
  )

  const exportAnimals = async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      const params = createGetBreedingAnimalReportParams(
        stationId as number,
        females?.total ?? 0,
        males?.total ?? 0
      )

      const breedingStationAnimalsFactory =
        (): BreedingAnimalReportFactory => ({
          getFemales: (
            stationId,
            page,
            total
          ): Promise<BreedingGroupAnimalResponseData> =>
            getReadBreedingStationFemales(stationId, page, total),
          getMales: (
            stationId,
            page,
            total
          ): Promise<BreedingGroupAnimalResponseData> =>
            getReadBreedingStationMales(stationId, page, total),
        })

      await exportBreedingAnimals(
        breedingStationAnimalsFactory(),
        params,
        `estacao-monta-${dateForFileName()}`
      )
    } catch (e) {
      handleHttpError(e, false)
    } finally {
      setIsLoadingExport(false)
    }
  }

  useEffect(() => {
    readFemales()
    readMales()
  }, [readFemales, readMales])

  return {
    females,
    males,
    addAnimal,
    removeAnimal,
    isLoading,
    filterAnimals,
    filterIsActive,
    isLoadingExport,
    exportAnimals,
  }
}

export { useBreedingStation, useBreedingStationAnimal }
