import React from 'react'

import * as yup from 'yup'

import { Button, ButtonSize, ButtonType } from 'components/atoms'
import {
  CustomModal,
  LabeledCard,
  LabeledCardButtonRow,
  LabeledCardRow,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { dateToday } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type DiagnosticsCreateTemplateProps = {
  handleCreate: (data: unknown) => Promise<void>
  isSuccessModalOpen: boolean
  navigateToDetail: () => void
}

const DiagnosticsCreateTemplate: React.FC<DiagnosticsCreateTemplateProps> = ({
  handleCreate,
  isSuccessModalOpen,
  navigateToDetail,
}) => {
  const validationSchema = yup.object({
    date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    responsible: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })
  const { Form } = useForm({
    onSubmit: handleCreate,
    validationSchema,
    initialValues: {
      date: '',
      responsible: '',
    },
  })

  return (
    <BoxedLayout title="Novo Diagnóstico Reprodutivo">
      <LabeledCard title="Informações gerais">
        <Form>
          <LabeledCardRow>
            <Form.InputText
              label="Data do diagnóstico"
              name="date"
              htmlType="date"
              labeledInputClassName={styles.field}
              max={dateToday}
            />
            <Form.InputText
              label="Responsável"
              name="responsible"
              labeledInputClassName={styles.field}
            />
          </LabeledCardRow>

          <LabeledCardButtonRow>
            <Button
              type={ButtonType.primary}
              label="Salvar"
              size={ButtonSize.large}
            />
          </LabeledCardButtonRow>
        </Form>
      </LabeledCard>
      <CustomModal
        modalIsOpen={isSuccessModalOpen}
        handleCloseModal={navigateToDetail}
        modalHeader="Novo Diagnóstico Reprodutivo criado com sucesso"
        modalText="Em seguida selecione e inclua os animais participantes."
        primaryButtonLabel="INCLUIR ANIMAIS"
        primaryButtonAction={navigateToDetail}
      />
    </BoxedLayout>
  )
}

export { DiagnosticsCreateTemplate }
