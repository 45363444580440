import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import Skeleton from 'react-loading-skeleton'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { ReproductionLayout } from 'components/templates'

import {
  DiagnosticAnimalReadResponseProps,
  DiagnosticRow,
  Diagnostics,
  DiagnosticsHeaderInfo,
} from 'app/core/types/breeding'
import { dateTimeFormat } from 'app/core/utils'

import styles from '../details/styles.module.scss'

type DiagnosticsFeedbackTemplateProps = {
  table: Table<Partial<DiagnosticRow>>
  diagnostics: DiagnosticAnimalReadResponseProps | undefined
  exportFailedDiagnostics: () => void
  failedDiagnostics: Diagnostics
  headerInfo: DiagnosticsHeaderInfo
  handleFinishDiagnostic: () => void
  page: number
  setPage: Dispatch<SetStateAction<number>>
}

const DiagnosticsFeedbackTemplate: React.FC<DiagnosticsFeedbackTemplateProps> =
  ({
    table,
    diagnostics,
    exportFailedDiagnostics,
    failedDiagnostics,
    headerInfo,
    handleFinishDiagnostic,
    page,
    setPage,
  }) => (
    <ReproductionLayout
      title="Diagnóstico Reprodutivo de Gestação"
      diagnosticDate={
        headerInfo.date ? (
          dateTimeFormat(headerInfo.date)
        ) : (
          <Skeleton width={100} height={18} />
        )
      }
      responsible={
        headerInfo.responsible || <Skeleton width={100} height={18} />
      }
      headerLargeButtonAction={handleFinishDiagnostic}
      headerLargeButtonLabel="Finalizar"
      {...(failedDiagnostics.length > 0 && {
        headerButtonAction: exportFailedDiagnostics,
        headerButtonLabel: 'Planilha de inconsistências',
        headerButtonIcon: <Icon name={IconNames['alert']} />,
      })}
    >
      {diagnostics?.items && (
        <BoxedTable
          title="Animais atualizados com sucesso"
          table={table}
          data={diagnostics?.items}
          className={styles.boxedTable}
          quantityLabel="Fêmea"
          noDataMessage="Nenhum animal atualizado com sucesso."
          isTableHeaderSticky
          currentPage={page}
          totalPages={diagnostics.pages}
          totalItems={diagnostics.total}
          setPage={setPage}
        />
      )}
    </ReproductionLayout>
  )

export { DiagnosticsFeedbackTemplate }
