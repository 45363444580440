import React, { useEffect, useState } from 'react'

import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { FormCol } from 'components/molecules'
import { useForm } from 'components/organisms'
import { LoginLayout } from 'components/templates'

import { IUserRequestData } from 'app/core/types/user'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'
import { http } from 'interfaces/http'

import styles from './styles.module.scss'

const ForgotPassword: React.FC = () => {
  const COUNTER_INIT_SECONDS = 20

  const [emailChecked, setEmailChecked] = useState<boolean>(false)
  const [emailInput, setEmailInput] = useState<string>('')
  const [buttonDisabled, setButtonDisable] = useState<boolean>(false)
  const [counter, setCounter] = useState<number>(COUNTER_INIT_SECONDS)

  const validationSchema = yup.object({
    email: yup
      .string()
      .label('E-mail')
      .email(Messages.YUP_INVALID_EMAIL)
      .required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleForgotPassword = async (data: unknown): Promise<void> => {
    const { email } = data as IUserRequestData

    await http
      .post('/auth/users/request-redefine-password', {
        email: email || emailInput,
      })
      .then(() => {
        setEmailInput(email)
        setEmailChecked(true)
        setButtonDisable(true)
      })
      .catch(e => {
        addToast({ message: e.message })
      })
  }

  const { Form } = useForm({ onSubmit: handleForgotPassword, validationSchema })

  useEffect(() => {
    if (buttonDisabled) {
      setTimeout(() => setButtonDisable(false), COUNTER_INIT_SECONDS * 1000)
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
    }

    if (!counter) {
      setCounter(COUNTER_INIT_SECONDS)
    }
  }, [buttonDisabled, counter])

  return (
    <LoginLayout
      header={emailChecked ? 'Verifique seu e-mail' : 'Esqueceu a senha'}
      subheader={
        emailChecked
          ? `O link para redefinir sua senha foi enviado para ${emailInput}`
          : 'Não se preocupe, preencha seu e-mail e enviaremos um link para redefinir sua senha.'
      }
    >
      <Form>
        <FormCol>
          <div>
            {!emailChecked && (
              <Form.InputText
                label="E-mail"
                name="email"
                placeholder="Inserir e-mail"
              />
            )}
          </div>

          <Button
            type={ButtonType.primary}
            label={emailChecked ? 'Enviar Novamente' : 'Enviar Link'}
            size={ButtonSize.large}
            disabled={buttonDisabled}
          />
        </FormCol>
        {counter > 0 && buttonDisabled && (
          <Typography
            text={`Por favor, aguarde ${counter} ${
              counter > 1 ? 'segundos' : 'segundo'
            }.`}
            variant={TypographyVariant.label}
            className={styles.counterMessage}
          />
        )}
      </Form>
    </LoginLayout>
  )
}

export { ForgotPassword }
