import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { NavigateList } from 'app/core/routes/routes'
import { StorageKeys } from 'app/core/types/storage'
import { SectorProps } from 'app/core/types/system'
import { addToast, getPlural } from 'app/core/utils'
import { Messages } from 'config/messages'

import { animalListColumns } from '../tables'
import { AnimalFeedbackTemplate } from './templates'

const AnimalFeedback: React.FC = () => {
  const history = useHistory()
  const navigateTo = (): void => history.push(NavigateList.animalList)

  const feedbackAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.animals_last_review) as string
  )
  const savedSector = JSON.parse(
    localStorage.getItem(StorageKeys.animals_sector_last_review) as string
  ) as SectorProps
  const savedDate = localStorage.getItem(StorageKeys.animals_date_last_review)

  const message = `${getPlural(
    'animal',
    feedbackAnimals.length,
    true
  )} ${getPlural('cadastrado', feedbackAnimals.length)} ${
    Messages.ANIMAL_CREATE_SUCCESS
  }`

  useEffect(() => {
    addToast({ message, type: 'success' })
    localStorage.removeItem(StorageKeys.animals_feedback_positive)
  }, [message])

  const table = useReactTable({
    data: feedbackAnimals,
    columns: animalListColumns({ hasAnimalId: true }),
    getCoreRowModel: getCoreRowModel(),
  })

  if (!savedDate || !savedSector || !feedbackAnimals) {
    navigateTo()
    return null
  }

  return (
    <AnimalFeedbackTemplate
      date={savedDate}
      sector={savedSector.name}
      animals={feedbackAnimals}
      table={table}
      handleFinish={navigateTo}
    />
  )
}

export { AnimalFeedback }
