import {
  MatingRecommendationCreateRequestData,
  MatingRecommendationEditRequestData,
  MatingRecommendationExportResponseData,
  MatingRecommendationFilterProps,
  MatingRecommendationProps,
  MatingRecommendationReadResponseData,
} from 'app/core/types/mating-recommendation'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/mating_recommendation'

const getReadAllMatingRecommendations = async (
  filters?: MatingRecommendationFilterProps,
  page?: number,
  size?: number
): Promise<MatingRecommendationReadResponseData> => {
  const response = await http.get(`${baseUrl}/`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const patchEditMatingRecommendation = async (
  recommendationId: number,
  request: MatingRecommendationEditRequestData
): Promise<MatingRecommendationProps> => {
  const response = await http.patch(`${baseUrl}/${recommendationId}/`, request)
  return response.data
}

const postCreateMatingRecommendation = async (
  request: MatingRecommendationCreateRequestData
): Promise<MatingRecommendationProps> => {
  const response = await http.post(`${baseUrl}/`, request)
  return response.data
}

const getExportMatingRecommendations = async (
  filters?: MatingRecommendationFilterProps
): Promise<MatingRecommendationExportResponseData[]> => {
  const response = await http.get(`${baseUrl}/export/`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

export {
  getExportMatingRecommendations,
  getReadAllMatingRecommendations,
  patchEditMatingRecommendation,
  postCreateMatingRecommendation,
}
