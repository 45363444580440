import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ButtonIconPosition, Icon, IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  useAnimal,
  useFarm,
  useSector,
  useShutdownReasons,
} from 'app/core/hooks'
import { useCollectiveMovements } from 'app/core/hooks/collective-movements'
import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import { AnimalFilterProps } from 'app/core/types/animal'
import { StorageKeys } from 'app/core/types/storage'
import { removeEmptyFilters } from 'app/core/utils'

import { EntryOptions } from '../../handlings/create/consts'
import { ExportHerdDynamic } from '../export'
import { AnimalListFilterDrawer } from './filter-drawer'
import { AnimalListNewEntry } from './new-entry'
import { AnimalTable } from './table'

const AnimalList: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = useCallback(
    (path, params): void => history.push(path, params),
    [history]
  )

  const { farms, farmsDroplist } = useFarm()
  const { sectors, sectorsDroplist } = useSector()
  const { removeStorageCollectiveMovement } = useCollectiveMovements({})
  const { allReasons: shutdownReasons } = useShutdownReasons()

  const [page, setPage] = useState<number>(1)
  const [filters, setFilters] = useState<AnimalFilterProps>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [isLoadingHerdDynamic, setIsLoadingHerdDynamic] = useState(false)

  const {
    animals: data,
    isLoading,
    exportAnimals,
    exportGeneralReport,
    isLoadingExport,
  } = useAnimal({
    filters,
    page,
  })

  const activeFilters = Object.entries(filters ?? {}).length
  const animals = data?.items ?? []
  const totalPages = data?.pages ?? 0
  const totalAnimals = data?.total ?? 0

  const toggleFilterDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
    setIsFilterDrawer(true)
  }

  const toggleNewEntryDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
    setIsFilterDrawer(false)
  }

  const handleFilterAnimals = useCallback(
    async (data: Record<string, unknown>) => {
      const selectedFilters = removeEmptyFilters(data) as AnimalFilterProps
      setFilters(selectedFilters)
      setPage(1)
      setIsDrawerOpen(false)
      setIsFilterDrawer(true)
    },
    []
  )

  const handleNewEntry = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const {
        newEntryType,
        selectedFarm,
        selectedSector,
        handlingType,
        selectedShutdownReason,
        selectedShutdownDescription,
      } = data

      if (newEntryType === EntryOptions.electronicEartag) {
        navigateTo(NavigateList.electronicEartagCreate)
      }

      if (newEntryType === EntryOptions.stockNumber) {
        navigateTo(NavigateList.stockNumberCreate)
      }

      if (newEntryType === EntryOptions.collectiveMovement) {
        removeStorageCollectiveMovement()

        navigateTo(NavigateList.collectiveMovementsCreate)
      }

      if (selectedFarm && newEntryType === EntryOptions.handlingCreate) {
        localStorage.removeItem(StorageKeys.handling_review)
        localStorage.removeItem(StorageKeys.handling_review_failed)
        localStorage.removeItem(StorageKeys.handling_farm_review)
        localStorage.removeItem(StorageKeys.handling_sector_review)

        localStorage.setItem(
          StorageKeys.handling_farm_review,
          JSON.stringify(selectedFarm)
        )

        localStorage.setItem(
          StorageKeys.handling_sector_review,
          JSON.stringify(selectedSector)
        )

        navigateTo(NavigateList.handlingCreate, {
          handlingType: handlingType as string,
        })
      }

      if (sectorsDroplist && newEntryType === EntryOptions.animalCreate) {
        localStorage.removeItem(StorageKeys.animals_review)
        localStorage.setItem(
          StorageKeys.animals_sector_review,
          JSON.stringify(selectedSector)
        )
        navigateTo(NavigateList.animalCreate)
      }

      if (
        selectedShutdownReason &&
        newEntryType === EntryOptions.animalShutdown
      ) {
        localStorage.removeItem(StorageKeys.shutdown_review)
        localStorage.removeItem(StorageKeys.shutdown_last_review)
        localStorage.removeItem(StorageKeys.shutdown_date_last_review)
        localStorage.setItem(
          StorageKeys.shutdown_type_review,
          JSON.stringify({
            shutdown_type: selectedShutdownReason,
            shutdown_description: selectedShutdownDescription,
          })
        )
        navigateTo(NavigateList.shutdownCreate)
      }
    },
    [removeStorageCollectiveMovement, navigateTo, sectorsDroplist]
  )

  const resetFilters = (): void => {
    setFilters(undefined)
  }

  const handleExportModal = (): void => {
    setIsExportModalOpen(!isExportModalOpen)
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Animais Totais',
      icon: IconNames.download,
      action: exportAnimals,
    },
    {
      name: 'Dinâmica de Rebanho',
      icon: IconNames.download,
      action: handleExportModal,
    },
    {
      name: 'Relatório Geral de Animais',
      icon: IconNames.download,
      action: exportGeneralReport,
      onlyAdmin: true,
    },
  ]

  return (
    <>
      <BoxedLayout
        title="Rebanho"
        quantity={totalAnimals}
        quantityLabel="Animais"
        headerButtonLabel="Filtrar"
        headerButtonAction={toggleFilterDrawer}
        headerButtonIcon={<Icon name={IconNames.filter} />}
        headerButtonFilterActiveFilters={activeFilters}
        headerLargeButtonLabel="Novo lançamento"
        headerLargeButtonAction={toggleNewEntryDrawer}
        currentPage={page}
        setCurrentPage={setPage}
        totalPages={totalPages}
        currentPageItems={animals.length}
        currentPageItemsLabel={'animal'}
        headerSelectButtonLabel="Exportar"
        headerSelectButtonItems={selectButtonItems}
        headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
        headerSelectButtonIconPosition={ButtonIconPosition.right}
        headerSelectButtonLoading={isLoadingExport || isLoadingHerdDynamic}
        isChildrenSticky
      >
        <AnimalTable animals={animals} isLoading={isLoading} />
      </BoxedLayout>

      <ExportHerdDynamic
        isOpen={isExportModalOpen}
        handleModal={handleExportModal}
        setIsLoadingHerdDynamic={setIsLoadingHerdDynamic}
      />

      {isFilterDrawer ? (
        <AnimalListFilterDrawer
          isOpen={isDrawerOpen}
          onToggle={toggleFilterDrawer}
          onReset={resetFilters}
          onSubmit={handleFilterAnimals}
        />
      ) : (
        <AnimalListNewEntry
          isOpen={isDrawerOpen}
          onClose={toggleFilterDrawer}
          onSubmit={handleNewEntry}
          farms={farms ?? []}
          farmsDroplist={farmsDroplist ?? []}
          sectors={sectors ?? []}
          sectorsDroplist={sectorsDroplist ?? []}
          shutdownReasons={shutdownReasons}
        />
      )}
    </>
  )
}

export { AnimalList }
