import TimeField from 'react-simple-timefield'

import styles from '../input-text/styles.module.scss'

export type InputTimeProps = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

const InputTime: React.FC<InputTimeProps> = ({ onChange, value }) => {
  return (
    <div className={styles.inputContainer}>
      <div className={styles.timeInput}>
        <TimeField
          value={value}
          onChange={onChange}
          colon=":"
          input={<input data-testid="inputTime" type="text" />}
        />
      </div>
    </div>
  )
}

export { InputTime }
