import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  ElectronicEartagAnimalsResponseData,
  ElectronicEartagCreateProps,
} from 'app/core/types/animal'
import { Messages } from 'config/messages'

type ElectronicEartagReviewTemplateProps = {
  animals: ElectronicEartagAnimalsResponseData | undefined
  handleGoToFeedback: () => void
  isLoading: boolean
  page: number
  setPage: Dispatch<SetStateAction<number>>
  table: Table<ElectronicEartagCreateProps>
}

const ElectronicEartagReviewTemplate: React.FC<ElectronicEartagReviewTemplateProps> =
  ({ animals, handleGoToFeedback, isLoading, page, setPage, table }) => {
    return (
      <BoxedLayout
        backButton
        title="Atualização de brinco eletrônico"
        headerLargeButtonAction={handleGoToFeedback}
        headerLargeButtonLabel="Salvar"
        headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
      >
        <BoxedTable
          data={animals?.items || []}
          table={table}
          quantityLabel="Entradas"
          title="Animais"
          noDataMessage={Messages.ANIMAL_NOT_ADDED}
          currentPage={page}
          totalPages={animals?.pages}
          totalItems={animals?.total}
          setPage={setPage}
          isLoading={isLoading}
          disableLastCellNavigation
        />
      </BoxedLayout>
    )
  }

export { ElectronicEartagReviewTemplate }
