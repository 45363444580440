import { AnimalCreateProps, AnimalSpecies } from 'app/core/types/animal'

const createDefaultEmptyRow = (sectorId: number): AnimalCreateProps[] => [
  {
    sector_id: sectorId,
    belongs_to_association: false,
    belongs_to_herd: true,
    birth_date: '',
    birth_weight: undefined,
    category: undefined,
    breed_first_percentage: '100',
    cowhide_color: undefined,
    current_weight: undefined,
    electronic_eartag: undefined,
    has_pedigree: false,
    imported_father: null,
    imported_mother: null,
    is_active: true,
    name: '',
    sex: undefined,
    species: AnimalSpecies.bovine,
    stock_number: '',
  },
]

export { createDefaultEmptyRow }
