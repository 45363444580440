import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useState } from 'react'

import { format } from 'date-fns'
import { useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useExecuter, useImport } from 'app/core/hooks'
import { ImportEntity, ImportedParameterType } from 'app/core/types/system'
import { downloadFile, generateXlsxTemplate } from 'app/core/utils'

import { executersListColumns } from './tables'
import { ExecuterTabTemplate } from './template'

const ExecuterTab: React.FC = () => {
  const HISTORY_ITEMS_VISIBLE = 5

  const { executers, readAllExecuters, isLoading } = useExecuter()
  const {
    activeImport,
    importExecutors,
    handleImportFile,
    toggleModal: toggleImportModal,
    isModalOpen: isImportModalOpen,
    isLoading: isImportLoading,
    importedFiles,
    loadImportedParameters,
    downloadImportedParameterFile,
  } = useImport()

  const [globalFilter, setGlobalFilter] = useState('')
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [historyCount, setHistoryCount] = useState(HISTORY_ITEMS_VISIBLE)

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file =>
      handleImportFile(file, ImportEntity.EXECUTORS),
  })

  const handleModalConfirm = useCallback(async () => {
    await importExecutors()
    await readAllExecuters()
  }, [importExecutors, readAllExecuters])

  const toggleHistoryModal = (): void => {
    setIsHistoryModalOpen(prevState => !prevState)
  }

  const handleShowMoreHistoryItems = (): void => {
    setHistoryCount(prevState => prevState + HISTORY_ITEMS_VISIBLE)
  }

  const table = useReactTable({
    data: executers || [],
    columns: executersListColumns(),
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const handleFileDownload = useCallback(
    async (fileName: string) => {
      downloadImportedParameterFile(fileName)
    },
    [downloadImportedParameterFile]
  )

  const sheetMenuOptions: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => openFileSelector(),
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          ['Nome', 'Crachá', 'Data de Nascimento', 'Filial'],
          ['Nome de exemplo', '123', format(new Date(), 'dd/MM/yyyy'), 1004],
        ])

        downloadFile({
          data: templateFile,
          fileName: 'template-executores',
        })
      },
    },
  ]

  useEffect(() => {
    loadImportedParameters(ImportedParameterType.executor)
  }, [loadImportedParameters])

  return (
    <ExecuterTabTemplate
      table={table}
      setGlobalFilter={setGlobalFilter}
      handleModalConfirm={handleModalConfirm}
      handleShowMoreHistoryItems={handleShowMoreHistoryItems}
      historyCount={historyCount}
      activeImport={activeImport}
      toggleHistoryModal={toggleHistoryModal}
      toggleImportModal={toggleImportModal}
      isHistoryModalOpen={isHistoryModalOpen}
      isImportModalOpen={isImportModalOpen}
      isImportLoading={isImportLoading}
      isLoading={isLoading}
      importedFiles={importedFiles}
      onImportFileClick={handleFileDownload}
      sheetMenuOptions={sheetMenuOptions}
    />
  )
}

export { ExecuterTab }
