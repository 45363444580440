import { ColumnDef } from '@tanstack/react-table'

import { Chip, ChipColorVariant } from 'components/atoms'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { AnimalSexLabel } from 'app/core/types/animal'
import { BirthCreateProps } from 'app/core/types/birth'
import { dateTimeFormat } from 'app/core/utils'

import styles from './styles.module.scss'

type BirthColumnsProps = {
  hasAnimalId?: boolean
}

const birthListColumns = ({
  hasAnimalId,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
BirthColumnsProps): ColumnDef<BirthCreateProps, any>[] => [
  {
    header: 'Registro de parto',
    meta: {
      colSpan: 5,
      mainHeader: true,
    },
    columns: [
      {
        header: '#',
        accessorKey: 'id',
        cell: (info): JSX.Element => (
          <span className={hasAnimalId ? '' : styles.orderCell}>
            {hasAnimalId ? info.row.original.id : info.row.index + 1}
          </span>
        ),
      },
      {
        header: 'Data do parto',
        accessorKey: 'date',
        cell: ({ getValue }): JSX.Element => {
          const value = getValue()
          return <span>{value ? dateTimeFormat(value) : '-'}</span>
        },
      },
      {
        header: 'Nº de Plantel',
        accessorKey: 'mother_stock_number',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
      {
        header: 'Nº de Brinco Eletrônico',
        accessorKey: 'mother_electronic_eartag',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
      {
        header: 'Tipo de parto',
        accessorKey: 'type',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
    ],
  },
  {
    header: 'Registro de cria',
    meta: {
      colSpan: 6,
      mainHeader: true,
    },
    columns: [
      {
        header: 'Nº de Nascimento',
        accessorKey: 'birth_number',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
      {
        header: 'Nome da cria',
        accessorKey: 'name',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
      {
        header: 'Sexo',
        accessorKey: 'sex',
        cell: ({ getValue }): JSX.Element => {
          const value = getValue()
          if (!value) return <span>-</span>
          return (
            <Chip
              colorVariant={
                value === AnimalSexLabel.female
                  ? ChipColorVariant.sexFemale
                  : ChipColorVariant.sexMale
              }
            />
          )
        },
      },
      {
        header: 'Cor/Pelagem',
        accessorKey: 'cowhide_color',
        cell: ({ getValue }): JSX.Element => {
          const value = getValue()
          return <Chip label={value} selected={false} />
        },
      },
      {
        header: 'Peso de nascimento',
        accessorKey: 'birth_weight',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
      {
        header: 'Pai/Grupo de repasse',
        accessorKey: 'father_name',
        cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      },
    ],
  },
]

export { birthListColumns }
