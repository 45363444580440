import { Table } from '@tanstack/react-table'

import { CustomTable } from 'components/organisms'
import { DefaultLayout } from 'components/templates'

import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import { UserEntity } from 'app/core/types/user'

import styles from './styles.module.scss'

type UserListTemplateProps = {
  navigateTo: NavigateParams
  table: Table<UserEntity>
  users?: UserEntity[]
  isLoading?: boolean
}

const UserListTemplate: React.FC<UserListTemplateProps> = ({
  navigateTo,
  isLoading,
  table,
  users,
}) => {
  return (
    <DefaultLayout
      header="Usuários"
      buttonLabel="Adicionar Usuário"
      buttonAction={(): void => navigateTo(NavigateList.userCreate)}
      {...(users && {
        subheader: `${users.length} ${
          users.length <= 1 ? 'usuário' : 'usuários'
        }`,
      })}
    >
      <CustomTable
        table={table}
        disableNavigation
        className={styles.userTable}
        isLoading={isLoading}
      />
    </DefaultLayout>
  )
}

export { UserListTemplate }
