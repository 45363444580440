import React from 'react'

import { CheckboxButton } from 'components/atoms'

import { ScheduleUpdateAnimalRequestData } from 'app/core/types/hormonal'

type MultipleRowCheckboxProps = {
  animals: ScheduleUpdateAnimalRequestData[]
  updateAnimal: (
    requestBody: ScheduleUpdateAnimalRequestData[]
  ) => Promise<void>
}

const MultipleRowCheckbox: React.FC<MultipleRowCheckboxProps> = ({
  animals,
  updateAnimal,
}) => {
  const isAllChecked = animals.every(animal => {
    return animal.actions.every(action => action.executed)
  })

  const toggleChecked = (checked: boolean): void => {
    const updatedAnimals = animals.map(animal => {
      return {
        ...animal,
        actions: animal.actions.map(action => ({
          ...action,
          executed: checked,
        })),
      }
    })

    updateAnimal(updatedAnimals)
  }

  return (
    <CheckboxButton
      name="day"
      options={[{ value: 'true' }]}
      defaultChecked={{ value: isAllChecked.toString() }}
      onChange={(e): void => toggleChecked(e.currentTarget.checked)}
    />
  )
}

export { MultipleRowCheckbox }
