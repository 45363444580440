import { CellContext, ColumnDef } from '@tanstack/react-table'

import {
  Icon,
  IconNames,
  InputText,
  Select,
  SelectItemProps,
} from 'components/atoms'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import {
  DiagnosticRow,
  NEGATIVE_OPTION,
  POSITIVE_OPTION,
} from 'app/core/types/breeding'
import { dateTimeFormat, dateToday } from 'app/core/utils'

export const DiagnosticColumns = (
  handleRemoveAnimal: (index: number) => void,
  handleRemoveDiagnostic: (diagnosticId: number) => void,
  onChangeRow: (index: number, field: string, value: unknown) => void,
  isAdmin?: boolean
): ColumnDef<Partial<DiagnosticRow>>[] => [
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Data do diagnóstico',
    accessorKey: 'diagnostic_date',
    cell: (info): JSX.Element => (
      <InputText
        name={`diagnostic_date${info.row.index}`}
        htmlType="date"
        onChange={(e): void =>
          onChangeRow(info.row.index, 'diagnostic_date', e.target.value)
        }
        defaultValue={dateTimeFormat(info.getValue() as string, true)}
        id={info.getValue() as string}
        max={dateToday}
      />
    ),
  },
  {
    header: 'Diagnóstico',
    accessorKey: 'status',
    cell: (info): JSX.Element => (
      <Select
        name={`status${info.row.index}`}
        value={[info.getValue() as SelectItemProps]}
        onChange={(value): void => {
          onChangeRow(
            info.row.index,
            'status',
            (value as SelectItemProps).value === 'positivo'
          )
        }}
        options={[NEGATIVE_OPTION, POSITIVE_OPTION]}
        centerText
      />
    ),
  },
  {
    header: 'Data da inseminação',
    accessorKey: 'insemination_date',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Tipo do diagnóstico',
    accessorKey: 'type',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Dias de gestação',
    accessorKey: 'gestation_days',
    cell: (info): JSX.Element =>
      info.row.original.status?.value === 'positivo' ? (
        <InputText
          name={`gestation_days${info.row.index}`}
          htmlType="number"
          onChange={(e): void =>
            onChangeRow(
              info.row.index,
              'gestation_days',
              Number(e.target.value)
            )
          }
          defaultValue={info.getValue() as string}
        />
      ) : (
        <span>-</span>
      ),
  },
  {
    header: 'Previsão de parto',
    accessorKey: 'birth_forecast',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (
      info: CellContext<Partial<DiagnosticRow>, unknown>
    ): JSX.Element | null => {
      const diagnosticId = info.row.original.id
      const alreadyCreated = typeof diagnosticId === 'number'

      return (
        <>
          {alreadyCreated && isAdmin && (
            <Icon
              name={IconNames.close}
              onClick={(): void => handleRemoveDiagnostic(Number(diagnosticId))}
            />
          )}
          {!alreadyCreated && (
            <Icon
              name={IconNames.close}
              onClick={(): void => handleRemoveAnimal(info.row.index)}
            />
          )}
        </>
      )
    },
  },
]
