import React from 'react'

import { BarChart, BarChartData } from 'components/molecules/bar-chart'

import { CHART_COLORS } from 'app/core/pages/dashboard/constants'
import { AnimalSexPlural } from 'app/core/types/animal'
import { DashboardAnimalCount } from 'app/core/types/dashboard'

import styles from './styles.module.scss'

type DashboardCountAgeGroupChartProps = {
  animalCount?: DashboardAnimalCount[]
}

const parseAnimalCount = (
  animalCount: DashboardAnimalCount[]
): { payloadKeys: string[]; stackKeys: string[]; data: BarChartData } => {
  const payloadKeys: string[] = []
  const stackKeys: string[] = []
  const dataIndex = animalCount.reduce((currentDataIndex, item) => {
    const currentFarm = currentDataIndex[item.farm_name] ?? {
      label: item.farm_name,
      stacks: {},
    }
    const currentFarmGender = currentFarm.stacks[item.animal_sex] ?? {
      label: AnimalSexPlural[item.animal_sex],
      payload: {},
    }
    if (!stackKeys.includes(item.animal_sex)) {
      stackKeys.push(item.animal_sex)
    }
    item.age_grouped_counts.forEach(ageGroupCount => {
      if (!payloadKeys.includes(ageGroupCount.label)) {
        payloadKeys.push(ageGroupCount.label)
      }
      currentFarmGender.payload[ageGroupCount.label] =
        (currentFarmGender.payload[ageGroupCount.label] ?? 0) +
        ageGroupCount.count
    })
    return {
      ...currentDataIndex,
      [item.farm_name]: {
        ...currentFarm,
        stacks: {
          ...currentFarm.stacks,
          [item.animal_sex]: currentFarmGender,
        },
      },
    }
  }, {} as Record<string, BarChartData[0]>)
  const data = Object.values(dataIndex)
  return { data, payloadKeys, stackKeys }
}

const DashboardCountAgeGroupChart: React.FC<DashboardCountAgeGroupChartProps> =
  ({ animalCount }) => {
    const { payloadKeys, stackKeys, data } = parseAnimalCount(animalCount ?? [])

    return (
      <BarChart
        containerProps={{
          width: '100%',
          height: 450,
          className: styles.container,
        }}
        barChartProps={{
          margin: { left: 50, bottom: 100, right: 50 },
          barGap: 8,
          barSize: 64,
        }}
        payloadKeys={payloadKeys}
        stackKeys={stackKeys}
        data={data}
        colors={CHART_COLORS}
      />
    )
  }

export { DashboardCountAgeGroupChart }
