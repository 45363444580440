import { useState } from 'react'

import { debounce } from 'lodash-es'

import {
  Button,
  ButtonType,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { CustomModal, ExpandCard } from 'components/molecules'

import { ReproductionParamsHook } from 'app/core/types/hooks'
import {
  ANESTRUS_MINIMUM,
  GESTATION_DIAGNOSTIC_MINIMUM,
} from 'app/core/types/system'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { Fieldset } from '../../fieldset'
import styles from './../../styles.module.scss'

type ReproductionCardProps = Pick<
  ReproductionParamsHook,
  'updateParams' | 'params'
>

const ReproductionCard: React.FC<ReproductionCardProps> = ({
  updateParams,
  params,
}) => {
  const gestationDaysFields = [
    {
      name: 'european_gestation_period',
      label: 'Europeu',
      defaultValue: `${params?.european_gestation_period ?? ''}`,
      suffix: 'dias',
    },
    {
      name: 'zebu_gestation_period',
      label: 'Zebu',
      defaultValue: `${params?.zebu_gestation_period ?? ''}`,
      suffix: 'dias',
    },
    {
      name: 'max_gestation_period',
      label: 'Período máximo de gestação',
      defaultValue: `${params?.max_gestation_period ?? ''}`,
      suffix: 'dias',
    },
  ]

  const birthWeightFields = [
    {
      label: 'Fêmeas',
      defaultValue: `${params?.female_birth_weight ?? ''}`,
      suffix: 'Kg.',
      name: 'female_birth_weight',
    },
    {
      label: 'Machos',
      defaultValue: `${params?.male_birth_weight ?? ''}`,
      suffix: 'Kg.',
      name: 'male_birth_weight',
    },
  ]

  const aptitudFields = [
    {
      label: 'Aptidão',
      defaultValue: `${params?.aptitude ?? ''}`,
      suffix: 'dias',
      description: 'Idade necessária para o animal estar apto à reprodução.',
      name: 'aptitude',
    },
  ]

  const anestrusFields = [
    {
      label: 'Limite de anestro',
      defaultValue: `${params?.anestrus_limit ?? ''}`,
      suffix: 'dias',
      description: `Mínimo de ${ANESTRUS_MINIMUM} dias.`,
      name: 'anestrus_limit',
    },
  ]

  const diagnosticFields = [
    {
      label: 'Limite de diagnósticos de gestação',
      defaultValue: `${params?.gestation_diagnosis_limit ?? ''}`,
      suffix: 'dias',
      description: `Mínimo de ${GESTATION_DIAGNOSTIC_MINIMUM} dias.`,
      name: 'gestation_diagnosis_limit',
    },
  ]

  const weanFields = [
    {
      label: 'Dias para listar animais a desmamar',
      defaultValue: `${params?.days_animals_wean ?? ''}`,
      suffix: 'dias',
      name: 'days_animals_wean',
    },
  ]

  const handleUpdate = debounce(
    async (name: string, value: string): Promise<void> => {
      const isAnestrusInvalid =
        name === anestrusFields[0].name && parseInt(value) < ANESTRUS_MINIMUM
      const isGestationInvalid =
        name === diagnosticFields[0].name &&
        parseInt(value) < GESTATION_DIAGNOSTIC_MINIMUM

      if (isAnestrusInvalid) {
        addToast({
          message: Messages.SYSTEM_REPRODUCTION_ANESTRUS_MINIMUM,
        })

        return
      }

      if (isGestationInvalid) {
        addToast({
          message: Messages.SYSTEM_REPRODUCTION_GESTATION_DIAGNOSTIC_MINIMUM,
        })

        return
      }

      if (!value) {
        return
      }

      await updateParams({ [name]: value })
    },
    500
  )

  const [periodData, setPeriodData] = useState({})
  const [showUpdatePeriodDialog, setShowUpdatePeriodDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const togglePeriodModal = (): void => {
    setShowUpdatePeriodDialog(!showUpdatePeriodDialog)
  }

  const handleUpdateReproductionPeriod = (
    name: string,
    value: string
  ): void => {
    setPeriodData({ ...periodData, [name]: value })
  }

  const handleUpdatePeriod = async (): Promise<void> => {
    setShowUpdatePeriodDialog(false)
    setIsLoading(true)
    addToast({
      message: Messages.SYSTEM_REPRODUCTION_UPDATE_BIRTH_FORECAST,
      type: 'warning',
    })

    try {
      await updateParams(periodData)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ExpandCard className={styles.settingsExpandCard} title="Reprodução">
        <div className={styles.cardContent}>
          <Divider darker />
          <div className={styles.reproductionPeriodHeader}>
            <Typography
              text={'Período de gestação'}
              variant={TypographyVariant.h5}
              className={styles.reproductionFieldsetTitle}
            />
            <Button
              label={'atualizar'}
              disabled={Object.values(periodData).length == 0}
              type={ButtonType.primary}
              onClick={togglePeriodModal}
              icon={<Icon name={IconNames.warning} />}
              loading={isLoading}
            />
          </div>
          <Fieldset
            fields={gestationDaysFields}
            onChange={handleUpdateReproductionPeriod}
          />
          <Divider darker className={styles.reproductionFieldsetsDivider} />
          <Fieldset
            fields={aptitudFields}
            className={styles.reproductionFieldsetSingleField}
            onChange={handleUpdate}
          />
          <Divider darker className={styles.reproductionFieldsetsDivider} />
          <Fieldset
            fields={birthWeightFields}
            title="Peso ao nascimento"
            onChange={handleUpdate}
          />
          <Divider darker className={styles.reproductionFieldsetsDivider} />
          <Fieldset
            fields={anestrusFields}
            className={styles.reproductionFieldsetSingleField}
            onChange={handleUpdate}
          />
          <Divider darker className={styles.reproductionFieldsetsDivider} />
          <Fieldset
            fields={diagnosticFields}
            className={styles.reproductionFieldsetSingleField}
            onChange={handleUpdate}
          />
          <Divider darker className={styles.reproductionFieldsetsDivider} />
          <Fieldset
            fields={weanFields}
            className={styles.reproductionFieldsetSingleField}
            onChange={handleUpdate}
          />
        </div>
      </ExpandCard>
      <CustomModal
        modalIsOpen={showUpdatePeriodDialog}
        handleCloseModal={togglePeriodModal}
        modalHeader={
          'Ao alterar os valores do período de gestação, todos os cálculos de previsão de parto serão atualizados. Deseja prosseguir?'
        }
        primaryButtonLabel="Sim"
        primaryButtonAction={handleUpdatePeriod}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={togglePeriodModal}
      />
    </>
  )
}

export { ReproductionCard }
