import React, { FunctionComponent } from 'react'

import classNames from 'classnames'

import { ISelectProps, Typography, TypographyVariant } from 'components/atoms'
import { Status as SelectStatus } from 'components/enums/status'

import styles from './styles.module.scss'

export interface ILabeledSelectProps {
  className?: string
  input: React.ReactElement<ISelectProps>
  label: string
  status: SelectStatus
  helperText?: string
  htmlFor?: string
}

const LabeledSelect: FunctionComponent<ILabeledSelectProps> = ({
  className,
  input,
  label,
  status,
  helperText,
  htmlFor,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        styles[SelectStatus[status]],
        className
      )}
    >
      <label
        htmlFor={htmlFor}
        className={styles.label}
        aria-labelledby={htmlFor}
      >
        <Typography
          status={status}
          text={label}
          variant={TypographyVariant.label}
        />
      </label>
      <span className={styles.container}>{input}</span>
      <span
        className={classNames(styles.helperText, styles[SelectStatus[status]])}
      >
        {helperText}
      </span>
    </div>
  )
}

export { LabeledSelect, SelectStatus }
