import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useProtocol } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { ProtocolHookProps } from 'app/core/types/hooks'

import { ProtocolHistoryTemplate } from './template'

const ProtocolHistory: React.FC = () => {
  const history = useHistory()
  const navigateTo = (): void =>
    history.push(NavigateList.hormonalProtocolCreate)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<ProtocolHookProps>({})
  const [page, setPage] = useState<number>(1)

  const { protocols, isLoading } = useProtocol({
    initDate: filters?.initDate,
    finalDate: filters?.finalDate,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterProtocol = async (data: unknown): Promise<void> => {
    const { initDate, finalDate } = data as ProtocolHookProps
    setPage(1)
    setFilters({ initDate, finalDate })
    handleToggleDrawer()
  }

  return (
    <ProtocolHistoryTemplate
      protocols={protocols}
      filters={filters}
      currentPage={page}
      setCurrentPage={setPage}
      navigateToCreate={navigateTo}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterProtocol}
      isLoading={isLoading}
    />
  )
}

export { ProtocolHistory }
