import { ChipColorVariant } from 'components/atoms'

import { AnimalCowReproductiveStatus } from 'app/core/types/animal'

export const CHIP_SHUTDOWN_REASON: Record<string, ChipColorVariant> = {
  sale: ChipColorVariant.shutdownSale,
  death: ChipColorVariant.shutdownDeath,
  other: ChipColorVariant.shutdownOther,
}

export const CHIP_SPECIES: Record<string, ChipColorVariant> = {
  Bovino: ChipColorVariant.speciesBovine,
  Equino: ChipColorVariant.speciesEquine,
}

export const CHIP_SEX: Record<string, ChipColorVariant> = {
  female: ChipColorVariant.sexFemale,
  male: ChipColorVariant.sexMale,
}

export const CHIP_CATEGORY: Record<string, ChipColorVariant> = {
  'Em crescimento': ChipColorVariant.categoryGrowing,
  Novilho: ChipColorVariant.categorySteer,
  Novilha: ChipColorVariant.categoryHeifer,
  Reprodutor: ChipColorVariant.categoryBreedingBull,
  Vaca: ChipColorVariant.categoryCow,
  Rufião: ChipColorVariant.categoryYearlingBull,
}

export const CHIP_COW_STATUS: Record<string, ChipColorVariant> = {
  [AnimalCowReproductiveStatus.empty]: ChipColorVariant.cowOpen,
  [AnimalCowReproductiveStatus.pregnant]: ChipColorVariant.cowPregnant,
  [AnimalCowReproductiveStatus.inseminated]: ChipColorVariant.cowInseminated,
}

export const CHIP_BREED_STATUS: Record<string, ChipColorVariant> = {
  Puro: ChipColorVariant.breedPurebred,
  Composto: ChipColorVariant.breedComposite,
}

export const CHIP_BREEDS: Record<string, ChipColorVariant> = {
  Zebu: ChipColorVariant.breedZebu,
  Europeu: ChipColorVariant.breedEuropean,
}
