import React, { useState } from 'react'

import { ButtonType } from 'components/atoms'
import { Label } from 'components/atoms/typography/label'
import { CustomModal, withLoading } from 'components/molecules'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { MeasuringStationProps } from 'app/core/types/measuring-station'

import { ListMeasuringStationLoadingTemplate } from './loading'
import styles from './styles.module.scss'
import { MeasuringStationTable } from './table/table'

type MeasuringStationTemplateProps = {
  isLoading: boolean
  measuringStations: MeasuringStationProps[]
  onCreateClick: () => void
  onEditClick: (measuringStationId: number) => void
  onDeleteClick: (measuringStationId: number) => void
}

const MeasuringStationListTemplate: React.FC<MeasuringStationTemplateProps> = ({
  isLoading,
  measuringStations,
  onCreateClick,
  onEditClick,
  onDeleteClick,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedStationId, setSelectedStationId] = useState<number | null>(
    null
  )

  const externalMeasuringStations = measuringStations.filter(
    station => station.icrop_station_id
  )
  const manualMeasuringStations = measuringStations.filter(
    station => !station.icrop_station_id
  )

  const toggleModal = (): void => setModalIsOpen(!modalIsOpen)

  const handleDeleteClick = (stationId: number): void => {
    setSelectedStationId(stationId)
    toggleModal()
  }

  const handleDeleteConfirmation = (): void => {
    if (selectedStationId) onDeleteClick(selectedStationId)
    setSelectedStationId(null)
    toggleModal()
  }

  return (
    <>
      <BoxedLayout
        title="Estações de medição"
        headerLargeButtonLabel={isAdmin() ? 'cadastrar' : undefined}
        headerLargeButtonAction={isAdmin() ? onCreateClick : undefined}
      >
        {withLoading(
          <div className={styles.table}>
            <Label text={'Estações iCrop'} className={styles.tableTitle} />
            <div className={styles.table}>
              <MeasuringStationTable
                measuringStations={externalMeasuringStations}
                onEditClick={isAdmin() ? onEditClick : undefined}
              />
            </div>
            {manualMeasuringStations.length > 0 && (
              <>
                <Label
                  text={'Estações Manuais'}
                  className={styles.tableTitle}
                />
                <div className={styles.table}>
                  <MeasuringStationTable
                    measuringStations={manualMeasuringStations}
                    onEditClick={isAdmin() ? onEditClick : undefined}
                    onDeleteClick={isAdmin() ? handleDeleteClick : undefined}
                  />
                </div>
              </>
            )}
          </div>
        )(isLoading, ListMeasuringStationLoadingTemplate)}
      </BoxedLayout>

      <CustomModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={toggleModal}
        modalHeader={'Deseja realmente excluir está estação de medição?'}
        primaryButtonLabel="Sim"
        primaryButtonAction={handleDeleteConfirmation}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={toggleModal}
      />
    </>
  )
}

export { MeasuringStationListTemplate }
