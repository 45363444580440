import { ColumnDef } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'

import { AgeGroup } from 'app/core/types/age-group'
import { ANIMAL_SEX_OPTIONS } from 'app/core/types/animal'

import styles from '../../styles.module.scss'

const getAgeGroupColumns = (
  handleEditClick: (item: AgeGroup) => void,
  handleRemoveClick: (item: AgeGroup) => void
): ColumnDef<AgeGroup, unknown>[] => [
  {
    header: 'Nome do grupo',
    accessorKey: 'name',
  },

  {
    header: 'Intervalo de idade',
    cell: (info): JSX.Element => (
      <span>
        de {info.row.original.start_day} a {info.row.original.finish_day} dias
      </span>
    ),
  },
  {
    header: 'Peso médio',
    accessorKey: 'average_weight',
  },
  {
    header: 'Sexo',
    cell: (info): JSX.Element => {
      const sexLabel = ANIMAL_SEX_OPTIONS.filter(
        e => e.value == info.row.original.sex
      )[0].label

      return <span>{sexLabel}</span>
    },
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <div className={styles.tableActions}>
        <Icon
          name={IconNames.pencil}
          onClick={(): void => handleEditClick(info.row.original)}
        />
        <Icon
          name={IconNames.close}
          onClick={(): void => handleRemoveClick(info.row.original)}
        />
      </div>
    ),
  },
]

export { getAgeGroupColumns }
