import React from 'react'

import classNames from 'classnames'

import { Icon, IconNames } from 'components/atoms'

import { getPlural } from 'app/core/utils'

import styles from './styles.module.scss'

export type QuantityBadgeProps = {
  /**
   * html id.
   */
  id?: string
  /**
   * Displays the quantity.
   */
  count: number
  /**
   * Displays a text besides quantity.
   * It automatically handles text plurals according to the related count amount.
   */
  label: string
  /**
   * If true, it triggers a warning text when hovering over the badge.
   */
  hasWarning?: boolean
  /**
   * A warning text to be displayed if hasWarning is true.
   */
  warning?: string
}

const QuantityBadge: React.FC<QuantityBadgeProps> = ({
  id,
  count,
  label,
  hasWarning,
  warning,
}) => {
  const hasWarningAndCount = hasWarning && count > 0

  return (
    <div
      id={id}
      className={classNames(
        styles.count,
        hasWarningAndCount ? styles.warning : '',
        count === 0 ? styles.empty : ''
      )}
      title={hasWarningAndCount ? warning : undefined}
    >
      <span className={styles.container}>
        {hasWarningAndCount && count > 0 && (
          <Icon name={IconNames.information} />
        )}
        <span className={styles.caption}>{count}</span>
        <span className={styles.caption}>{getPlural(label, count)}</span>
      </span>
    </div>
  )
}

export { QuantityBadge }
