import { ColumnDef, Row } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { format, parseISO } from 'date-fns'

import { Icon, IconNames } from 'components/atoms'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { ServiceOrderInfoProps } from 'app/core/types/service-order'

import styles from '../../styles.module.scss'
import { getCategory } from './helpers'

const serviceOrderListColumns = (
  handleModal: () => void,
  setActiveServiceOrder: Dispatch<
    SetStateAction<ServiceOrderInfoProps | undefined>
  >
): ColumnDef<ServiceOrderInfoProps, unknown>[] => {
  const DraftWarning = (): JSX.Element => (
    <div className={styles.serviceInfoDraft}>
      <span>Pendente</span>
      <Icon name={IconNames.alert} size={18} />
    </div>
  )

  const onCostCenterClassificationClick = (
    item: ServiceOrderInfoProps
  ): void => {
    handleModal()
    setActiveServiceOrder(item)
  }

  const filterFn = (
    row: Row<ServiceOrderInfoProps>,
    id: string,
    value: string
  ): boolean => value.includes((row.getValue(id) as string)?.toLowerCase())

  return [
    {
      header: 'Número',
      accessorFn: (row): string => row.bulletin_number.toString(),
      accessorKey: 'bulletin_number',
      cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      sortDescFirst: false,
      meta: {
        canFilter: true,
      },
    },
    {
      header: 'Data',
      accessorKey: 'date',
      cell: ({ getValue }): JSX.Element => {
        const value = getValue()
        return (
          <span className={styles.dateCol}>
            {format(parseISO(value as string), 'dd/MM/yy HH:mm')}
          </span>
        )
      },
    },
    {
      header: 'Executor',
      accessorFn: (row): string => row.executor_name,
      accessorKey: 'executor_id',
      cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      filterFn,
      meta: {
        canFilter: true,
        filterVariant: 'checkbox',
      },
    },
    {
      header: 'Serviço',
      accessorFn: (row): string => row.service_name,
      accessorKey: 'service_id',
      cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      filterFn,
      meta: {
        canFilter: true,
        filterVariant: 'checkbox',
      },
    },
    {
      header: 'Local',
      accessorKey: 'local_id',
      accessorFn: (row): string => row.local_name,
      cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
      filterFn,
      meta: {
        canFilter: true,
        filterVariant: 'checkbox',
      },
    },
    {
      header: 'Centro de Custo',
      accessorFn: (row): string => row.cost_center_name,
      accessorKey: 'cost_center_name',
      cell: ({ getValue, row }): JSX.Element => (
        <div
          data-testid="cost-center-classification-cell"
          className={styles.costCenterClassificationCell}
          onClick={(): void => onCostCenterClassificationClick(row.original)}
        >
          {row.original.cost_center_name ? (
            <span>{getValue()}</span>
          ) : (
            <DraftWarning />
          )}
        </div>
      ),
      filterFn,
      meta: {
        canFilter: true,
        filterVariant: 'checkbox',
      },
      sortingFn: (rowA, rowB): number =>
        rowA.original.cost_center_name.localeCompare(
          rowB.original.cost_center_name
        ),
    },
    {
      header: 'Classificação',
      accessorFn: (row): string => row.classification_name,
      accessorKey: 'classification_name',
      cell: ({ getValue, row }): JSX.Element => (
        <div
          data-testid="cost-center-classification-cell"
          className={styles.costCenterClassificationCell}
          onClick={(): void => onCostCenterClassificationClick(row.original)}
        >
          {row.original.classification_name ? (
            <span>{getValue()}</span>
          ) : (
            <DraftWarning />
          )}
        </div>
      ),
      filterFn,
      meta: {
        canFilter: true,
        filterVariant: 'checkbox',
      },
      sortingFn: (rowA, rowB): number =>
        rowA.original.classification_name.localeCompare(
          rowB.original.classification_name
        ),
    },
    {
      header: 'Categoria',
      accessorKey: 'classification_code',
      cell: ({ getValue }): JSX.Element => getCategory(getValue() as string),
    },
    {
      header: '',
      accessorKey: 'id',
      cell: (): JSX.Element => <></>,
    },
  ]
}

export { serviceOrderListColumns }
