import { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  IExpandCardProps,
  withLoading,
} from 'components/molecules'
import filterButtonStyles from 'components/molecules/data-counter/styles.module.scss'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import { InseminationReadResponseData } from 'app/core/types/hormonal'

import styles from './styles.module.scss'

type InseminationHistoryTemplateProps = {
  navigateTo: NavigateParams
  entries?: InseminationReadResponseData
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  page: number
  setPage: Dispatch<SetStateAction<number>>
  isLoading: boolean
  handleDeleteModalClose: () => void
  handleDeleteModalConfirm: () => Promise<void>
  deleteGroupInseminationId: number | undefined
  setDeleteGroupInseminationId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  onExportClick: () => Promise<void>
  isLoadingExport: boolean
  sectors: SelectItemProps[]
  form: FormType
  activeFilters: number
  onFormReset: () => void
  farms: SelectItemProps[]
  breedingStations: SelectItemProps[]
}

const InseminationHistoryTemplate: React.FC<InseminationHistoryTemplateProps> =
  ({
    navigateTo,
    entries,
    isDrawerOpen,
    handleToggleDrawer,
    page,
    setPage,
    isLoading,
    handleDeleteModalClose,
    handleDeleteModalConfirm,
    deleteGroupInseminationId,
    setDeleteGroupInseminationId,
    onExportClick,
    isLoadingExport,
    sectors,
    form: Form,
    activeFilters,
    onFormReset,
    farms,
    breedingStations,
  }) => {
    const editButtonProps = (
      entryId: number
    ): Pick<
      IExpandCardProps,
      'buttonLabel' | 'buttonIcon' | 'buttonAction'
    > => ({
      buttonLabel: 'Editar',
      buttonIcon: IconNames.pencil,
      buttonAction: (): void =>
        navigateTo(`${NavigateList.inseminationDetails}${entryId}`),
    })

    return (
      <>
        <BoxedLayout
          title="Lançamentos de Cobertura/Inseminação"
          headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
          headerLargeButtonLabel="Criar"
          headerLargeButtonAction={(): void =>
            navigateTo(NavigateList.inseminationCreate)
          }
          totalPages={entries?.pages}
          currentPage={page}
          setCurrentPage={setPage}
          headerButtonLabel="Exportar"
          headerButtonIcon={<Icon name={IconNames.download} />}
          headerButtonAction={onExportClick}
          headerButtonLoading={isLoadingExport}
        >
          {withLoading(
            <>
              <div className={styles.filterButtonContainer}>
                <DataCounter counter={entries?.total || 0} />

                <Button
                  label={activeFilters ? String(activeFilters) : 'Filtrar'}
                  size={ButtonSize.small}
                  icon={<Icon name={IconNames.filter} />}
                  type={ButtonType.ghost}
                  onClick={handleToggleDrawer}
                  {...(activeFilters && {
                    className: filterButtonStyles.activeItems,
                  })}
                />
              </div>

              <div className={styles.inseminationsContainer}>
                {entries?.items?.map((entry, i) => (
                  <ExpandCard
                    entryType={entry.type}
                    singleDate={entry.date}
                    animals={entry.animals_count}
                    breedingStation={entry.breeding_station_name}
                    expandable={false}
                    gap="2rem"
                    navigation={(): void =>
                      navigateTo(
                        `${NavigateList.inseminationDetails}${entry.id}`
                      )
                    }
                    {...editButtonProps(entry.id)}
                    key={`entry-${i}`}
                    {...(isAdmin() && {
                      secondaryButtonAction: (): void =>
                        setDeleteGroupInseminationId(entry.id),
                      secondaryButtonLabel: 'Excluir',
                      secondaryButtonIcon: IconNames.close,
                    })}
                  />
                ))}
              </div>
            </>
          )(isLoading)}

          <CustomDrawer
            title="Filtrar"
            isOpen={isDrawerOpen}
            onClose={handleToggleDrawer}
            reset={onFormReset}
          >
            <Form>
              <DrawerRow>
                <DrawerCol>
                  <Form.InputText
                    label="Nº de Brinco Eletrônico"
                    name="electronic_eartag"
                    placeholder="Pesquisar nº de brinco eletrônico"
                  />
                </DrawerCol>
                <DrawerCol>
                  <Form.InputText
                    label="Nº de Nascimento"
                    name="birth_number"
                    placeholder="Pesquisar nº de nascimento"
                  />
                </DrawerCol>
                <DrawerCol>
                  <Form.InputText
                    label="Nº de Plantel"
                    name="stock_number"
                    placeholder="Inserir número"
                  />
                </DrawerCol>
              </DrawerRow>

              <DrawerRow>
                {sectors && (
                  <DrawerCol>
                    <Form.Select
                      label="Setor"
                      name="sector_id"
                      options={sectors}
                    />
                  </DrawerCol>
                )}
                {farms && (
                  <DrawerCol>
                    <Form.Select
                      label="Fazenda"
                      name="farm_id"
                      options={farms}
                    />
                  </DrawerCol>
                )}
                <DrawerCol>
                  <Form.InputText
                    label="Nome do Animal"
                    name="animal_name"
                    placeholder="Pesquisar nome do animal"
                  />
                </DrawerCol>
              </DrawerRow>

              <DrawerRow>
                <Form.InputText
                  label="Período de Nascimento"
                  name="init_date"
                  htmlType="date"
                />

                <Form.InputText
                  emptyLabelSpacing
                  name="final_date"
                  htmlType="date"
                />
              </DrawerRow>

              <DrawerRow>
                {breedingStations && (
                  <DrawerCol>
                    <Form.Select
                      label="Estação de monta"
                      name="breeding_station_id"
                      options={breedingStations}
                    />
                  </DrawerCol>
                )}
              </DrawerRow>

              <DrawerRow>
                <Form.InputText
                  label="Período da Inseminação"
                  name="insemination_init_date"
                  htmlType="date"
                />

                <Form.InputText
                  emptyLabelSpacing
                  name="insemination_final_date"
                  htmlType="date"
                />
              </DrawerRow>

              <DrawerButtonContainer>
                <Button
                  label="Aplicar"
                  type={ButtonType.primary}
                  size={ButtonSize.large}
                />
              </DrawerButtonContainer>
            </Form>
          </CustomDrawer>
        </BoxedLayout>

        <CustomModal
          modalIsOpen={!!deleteGroupInseminationId}
          handleCloseModal={handleDeleteModalClose}
          modalHeader={`Excluir Grupo de Inseminações/Coberturas`}
          modalText={`Você tem certeza que deseja excluir este grupo de inseminações/coberturas?`}
          primaryButtonLabel="EXCLUIR"
          primaryButtonAction={handleDeleteModalConfirm}
          primaryButtonType={ButtonType.destructive}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleDeleteModalClose}
        />
      </>
    )
  }

export { InseminationHistoryTemplate }
