import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  SelectItemProps,
} from 'components/atoms'

import { useEvaluations } from 'app/core/hooks/evaluation'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalSexLabel } from 'app/core/types/animal'
import {
  EVALUATION_ANIMAL_SEARCH,
  EvaluationAnimalSearchType,
} from 'app/core/types/evaluation'
import { addToast, findAnimalId } from 'app/core/utils'
import { Messages } from 'config/messages'

import { useForm } from '../form'
import styles from './styles.module.scss'

type SearchAnimalProps = {
  findOnlyFemale?: boolean
  navigation: NavigateList
}

const SearchAnimal: React.FC<SearchAnimalProps> = ({
  findOnlyFemale,
  navigation,
}) => {
  const history = useHistory()
  const { getAnimal } = useEvaluations({})

  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = yup.object({
    term: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleSearchEvaluation = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      try {
        const animalType = data.animalType as SelectItemProps
        const searchTerm = data.term as string

        setIsLoading(true)

        const animal = await getAnimal(
          searchTerm,
          animalType.label as EvaluationAnimalSearchType
        )
        const isMale = animal?.sex === AnimalSexLabel.male

        if (findOnlyFemale && isMale) {
          addToast({
            message: Messages.ANIMAL_SEARCH_ONLY_FEMALE,
          })

          return
        }

        const animalId = findAnimalId(animal)

        if (animalId) {
          history.push(`${navigation}${animalId}`)
        }
      } catch (e) {
        addToast({ message: Messages.ERROR_MESSAGE })
      } finally {
        setIsLoading(false)
      }
    },
    [findOnlyFemale, getAnimal, history, navigation]
  )

  const { Form: SearchForm, setFocus } = useForm({
    onSubmit: handleSearchEvaluation,
    validationSchema,
    initialValues: {
      animalType: EVALUATION_ANIMAL_SEARCH[0],
      term: '',
    },
  })

  return (
    <section className={styles.search}>
      <SearchForm>
        <div className={styles.searchColumn}>
          <SearchForm.Select
            className={styles.searchSelect}
            defaultValue={EVALUATION_ANIMAL_SEARCH[0]}
            label="Animal"
            name="animalType"
            options={EVALUATION_ANIMAL_SEARCH}
            onChange={(): void => setFocus('term')}
          />
        </div>

        <div className={styles.searchInput}>
          <SearchForm.InputText
            autoFocus
            emptyLabelSpacing
            name="term"
            placeholder={Messages.ANIMAL_SEARCH_MULTIPLE_FILTERS}
          />
        </div>

        <div className={styles.searchSubmit}>
          <Button
            type={ButtonType.primary}
            label="Buscar"
            size={ButtonSize.large}
            loading={isLoading}
          />
        </div>
      </SearchForm>
    </section>
  )
}

export { SearchAnimal }
