import React from 'react'

import * as yup from 'yup'

import { Button, ButtonSize, ButtonType } from 'components/atoms'
import {
  LabeledCard,
  LabeledCardButtonRow,
  LabeledCardRow,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { Messages } from 'config/messages'

type ProtocolCreateTemplateProps = {
  onSubmit: IFormProps['onSubmit']
}

const ProtocolCreateTemplate: React.FC<ProtocolCreateTemplateProps> = ({
  onSubmit,
}) => {
  const validationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const { Form } = useForm({ onSubmit, validationSchema })

  return (
    <BoxedLayout title="Novo Protocolo Hormonal">
      <LabeledCard title="Informações gerais">
        <Form>
          <LabeledCardRow>
            <Form.InputText
              label="Nome"
              name="name"
              placeholder="Inserir nome"
              width={480}
            />
          </LabeledCardRow>

          <LabeledCardButtonRow>
            <Button
              type={ButtonType.primary}
              label="Salvar"
              size={ButtonSize.large}
            />
          </LabeledCardButtonRow>
        </Form>
      </LabeledCard>
    </BoxedLayout>
  )
}

export { ProtocolCreateTemplate }
