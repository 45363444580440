import React, { createContext, useContext } from 'react'

import { useServiceOrderFilters as useServiceOrderFiltersHook } from 'app/core/hooks'
import { FiltersHook } from 'app/core/types/hooks'

const ServiceOrderFiltersContext = createContext<FiltersHook | null>(null)

export const ServiceOrdersFiltersProvider: React.FC = ({ children }) => {
  const filtersHook = useServiceOrderFiltersHook()

  return (
    <ServiceOrderFiltersContext.Provider value={filtersHook}>
      {children}
    </ServiceOrderFiltersContext.Provider>
  )
}

export const useServiceOrderFilters = (): FiltersHook => {
  const context = useContext(ServiceOrderFiltersContext)

  if (!context)
    throw new Error(
      'useServiceOrderFilters must be used within a ServiceOrderFiltersProvider'
    )

  return context
}
