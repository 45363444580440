import { ColumnDef } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'

import { ProductListProps } from 'app/core/types/products'

import styles from '../../styles.module.scss'

const getProductColumns = (
  handleEditClick: (item: ProductListProps) => void,
  handleRemoveClick: (item: ProductListProps) => void
): ColumnDef<ProductListProps, unknown>[] => [
  {
    header: 'Código',
    accessorKey: 'code',
  },
  {
    header: 'Nome',
    accessorKey: 'name',
  },
  {
    header: 'Fabricante',
    accessorKey: 'manufacturer',
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <div className={styles.tableActions}>
        <Icon
          name={IconNames.pencil}
          onClick={(): void => handleEditClick(info.row.original)}
        />
        <Icon
          name={IconNames.close}
          onClick={(): void => handleRemoveClick(info.row.original)}
        />
      </div>
    ),
  },
]

export { getProductColumns }
