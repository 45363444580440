/* eslint-disable @typescript-eslint/naming-convention */
import * as XLSX from 'xlsx'

import { getDiagnosticType, getReadAllAnimals } from 'app/core/services'
import { AnimalFilterProps } from 'app/core/types/animal'
import { Diagnostic, ImportedDiagnosisProps } from 'app/core/types/breeding'
import { InseminationTypes } from 'app/core/types/hormonal'
import {
  dateTimeFormat,
  parseNumberFromSheet,
  parseXlsxDate,
} from 'app/core/utils'

const xlsxToDiagnostics = async (content: string): Promise<Diagnostic[]> => {
  const data = new Uint8Array(content as unknown as ArrayBuffer)
  const workbook = XLSX.read(data, {
    type: 'array',
    cellText: false,
    cellDates: true,
  })

  const diagnostics = [] as Diagnostic[]

  await Promise.all(
    workbook.SheetNames.map(async sheetName => {
      const worksheet = workbook.Sheets[sheetName]
      const rows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false,
        dateNF: 'd"/"m"/"yyyy',
        raw: true,
      }) as ImportedDiagnosisProps[]

      for (let i = 1; i < rows.length; i++) {
        const row = rows[i]

        const animalInfo = row[0] || row[1] || row[2]
        const diagnostic_date = parseXlsxDate(row[3])
        const status = String(row[4]).trim()
        const gestation_days = row[5] ? parseNumberFromSheet(row[5]) : undefined

        const filters = {
          stock_number_or_birth_number_or_electronic_eartag: String(animalInfo)
            .trim()
            .replace(/\n/g, ''),
        } as AnimalFilterProps

        const animal = (await getReadAllAnimals(filters)).items[0]

        let diagnostic: Diagnostic

        const isGestationDaysFilled = gestation_days
        const isNegativeDiagnostic = status === 'N'

        if (!animal) {
          diagnostic = {
            diagnostic_date: dateTimeFormat(diagnostic_date, true),
            status: status === 'P' ? true : false,
            gestation_days,
            type: gestation_days ? InseminationTypes.natural_cover : undefined,
            error_message: `Animal não encontrado: ${animalInfo}`,
          }

          diagnostics.push(diagnostic)

          continue
        }

        const updatedDiagnostic = await getDiagnosticType(
          animal?.id,
          diagnostic_date
        )

        /*
          - If gestation days from sheet is filled by user, then the insemination type is InseminationTypes.natural_cover
          - If gestation days from sheet is filled by user, then the "birth_forecast" is undefined (do not show)
          - If gestation days from sheet is filled by user, then the "insemination_date" is undefined (do not show)
          - If gestation days from sheet is filled by user, then insemination_date and birth_forecast is undefined (do not show)
          - If gestation days from sheet is empty, then search for diagnostic type and use API response "gestation_days", "birth_forecast" and "insemination_date" fields
          - If gestation days from sheet is empty and "gestation_days" API field is empty, then gestation_days is undefined
          - If user changes the "gestation_days" field, then the insemination type is InseminationTypes.natural_cover 
        */

        const resolveDiagnosticType = (): string | undefined => {
          if (isNegativeDiagnostic) return undefined
          return isGestationDaysFilled
            ? InseminationTypes.natural_cover
            : updatedDiagnostic.type || undefined
        }

        const resolveBirthForecast = (): string | undefined => {
          if (isGestationDaysFilled) return undefined
          return updatedDiagnostic.birth_forecast || undefined
        }

        const resolveInseminationDate = (): string | undefined => {
          if (isGestationDaysFilled) return undefined
          return updatedDiagnostic.insemination_date || undefined
        }

        diagnostic = {
          diagnostic_date: dateTimeFormat(diagnostic_date, true),
          status: status === 'P' ? true : false,
          gestation_days: isGestationDaysFilled
            ? gestation_days
            : updatedDiagnostic.gestation_days || undefined,
          type: resolveDiagnosticType(),
          birth_forecast: resolveBirthForecast(),
          insemination_date: resolveInseminationDate(),
          animal: {
            id: animal.id,
            electronic_eartag: animal.electronic_eartag,
            stock_number: animal.stock_number,
          },
        }

        diagnostics.push(diagnostic)
      }
    })
  )

  return diagnostics
}

export { xlsxToDiagnostics }
