import React from 'react'

import classNames from 'classnames'

import { IconNames } from './iconNames'
import styles from './styles.module.scss'

interface IIconProps {
  /**
   * Icon name
   */
  name?: IconNames
  /**
   * An alternative text about the icon to help assistive technology
   */
  alt?: string
  /**
   * Element extra information to be displayed on hover
   */
  title?: string
  /**
   * The icon size
   */
  size?: string | number
  /**
   * Icon color
   */
  color?: string
  /**
   * Classname to add custom css
   */
  className?: string
  /**
   * Optional click handler
   */
  onClick?: (() => void) | ((e: React.MouseEvent) => void)
  /**
   * Disable prop that disable the click handler
   */
  disabled?: boolean
  /**
   * Custom testId for testing
   */
  testId?: string
}

const Icon = ({
  name,
  alt,
  title,
  className,
  color,
  size = '24px',
  onClick,
  disabled,
  testId = 'icon',
}: IIconProps): JSX.Element => {
  if (!name) return <></>

  return (
    <i
      data-testid={testId}
      role="img"
      title={title}
      aria-label={alt}
      className={classNames(
        `icon-font-${name}`,
        className,
        onClick ? styles.clickable : '',
        title ? styles.tooltip : '',
        disabled && styles.disabled
      )}
      style={{ color, fontSize: size }}
      {...(!disabled && { onClick })}
    />
  )
}

export { Icon, IconNames }
export type { IIconProps }
