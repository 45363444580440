import React, { createContext, useContext, useState } from 'react'

type MenuContextProps = {
  submenuOpen: string
  setSubmenuOpen: (label: string) => void
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

export const MenuProvider: React.FC = ({ children }) => {
  const [submenuOpen, setSubmenuOpen] = useState<string>('')

  return (
    <MenuContext.Provider value={{ submenuOpen, setSubmenuOpen }}>
      {children}
    </MenuContext.Provider>
  )
}

export const useMenu = (): MenuContextProps => {
  const context = useContext(MenuContext)

  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider')
  }

  return context
}
