import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import { getAllClassifications } from 'app/core/services/system/classification'
import {
  ClassificationHook,
  ClassificationHookProps,
} from 'app/core/types/hooks'
import { ClassificationProps } from 'app/core/types/system'
import { handleHttpError } from 'app/core/utils'

const useClassification = ({
  costCenterId,
  farmId,
}: ClassificationHookProps): ClassificationHook => {
  const [classifications, setClassifications] = useState<ClassificationProps[]>(
    []
  )
  const [isLoading, setIsLoading] = useState(false)

  const classificationsDroplist = classifications.map(Classification => {
    return {
      label: Classification.name,
      value: `${Classification.id}`,
      subLabel: `${Classification.code}`,
    }
  }) as SelectItemProps[]

  const readAllClassifications = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)

      const data = await getAllClassifications({
        cost_center_id: costCenterId,
        farm_id: farmId,
      })

      if (data.length > 0) setClassifications(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [costCenterId, farmId])

  useEffect(() => {
    readAllClassifications()
  }, [readAllClassifications])

  return {
    classifications,
    classificationsDroplist,
    readAllClassifications,
    isLoading,
  }
}

export { useClassification }
