import { ReproductionParamsProps } from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/reproduction_params/'

const getReadReproductionParams =
  async (): Promise<ReproductionParamsProps> => {
    const response = await http.get(baseUrl)
    return response.data
  }

const patchUpdateReproductionParams = async (
  request: Partial<ReproductionParamsProps>
): Promise<ReproductionParamsProps> => {
  const response = await http.patch(baseUrl, request)
  return response.data
}

export { getReadReproductionParams, patchUpdateReproductionParams }
