import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { Chip, ChipColorVariant, Icon, IconNames } from 'components/atoms'
import {
  cellEmptyStaticValue,
  handleDeleteRow,
} from 'components/organisms/custom-table/helpers'
import { CHIP_CATEGORY } from 'components/templates/animal-layout/consts'

import { AnimalSexLabel } from 'app/core/types/animal'
import {
  ShutdownCreateProps,
  ShutdownDescriptionStorageProps,
  ShutdownType,
  ShutdownTypeKeys,
} from 'app/core/types/shutdown'

const columnHelper = createColumnHelper<ShutdownCreateProps>()

type ShutdownColumnsProps = {
  shutdownType: string
  animals?: ShutdownCreateProps[]
  setAnimals?: Dispatch<SetStateAction<ShutdownCreateProps[]>>
  shutdownDescriptions?: ShutdownDescriptionStorageProps
}

export const shutdownColumns = ({
  shutdownType,
  animals,
  setAnimals,
  shutdownDescriptions,
}: ShutdownColumnsProps): // eslint-disable-next-line @typescript-eslint/no-explicit-any
ColumnDef<ShutdownCreateProps, any>[] => [
  columnHelper.accessor('electronic_eartag', {
    header: 'Nº de Brinco Eletrônico',
    cell: (info): JSX.Element =>
      cellEmptyStaticValue(info.row.original.electronic_eartag),
  }),
  columnHelper.accessor('stock_number', {
    header: 'Nº de Plantel',
    cell: (info): JSX.Element =>
      cellEmptyStaticValue(info.row.original.stock_number),
  }),
  columnHelper.accessor('birth_number', {
    header: 'Nº de Nascimento',
    cell: (info): JSX.Element =>
      cellEmptyStaticValue(info.row.original.birth_number),
  }),
  columnHelper.accessor('sex', {
    header: 'Sexo',
    cell: (info): JSX.Element => (
      <Chip
        colorVariant={
          info.row.original.sex === AnimalSexLabel.female
            ? ChipColorVariant.sexFemale
            : ChipColorVariant.sexMale
        }
      />
    ),
  }),
  columnHelper.accessor('category', {
    header: 'Categoria',
    cell: (info): JSX.Element => (
      <Chip colorVariant={CHIP_CATEGORY[info.row.original.category]} />
    ),
  }),
  ...(shutdownType === ShutdownTypeKeys.sale
    ? [
        columnHelper.accessor('weight', {
          header: 'Peso de Venda',
          cell: (info): JSX.Element => (
            <span>{info.row.original.weight} kg</span>
          ),
        }),
      ]
    : []),
  ...(shutdownType !== ShutdownTypeKeys.sale
    ? [
        columnHelper.accessor('shutdown_description', {
          header: `${
            shutdownType === ShutdownType.other
              ? 'Causa da Baixa'
              : 'Causa da Morte'
          }`,
          cell: (info): JSX.Element => (
            <span>
              {
                shutdownDescriptions?.options.find(
                  e =>
                    e.value ===
                    (info.row.original.shutdown_description ||
                      shutdownDescriptions.selectedValue)
                )?.label
              }
            </span>
          ),
        }),
      ]
    : []),
  ...(animals && setAnimals
    ? [
        columnHelper.accessor('id', {
          header: '',
          cell: (info): JSX.Element => (
            <Icon
              name={IconNames.close}
              onClick={(): void =>
                handleDeleteRow(animals, setAnimals, info.row.index)
              }
            />
          ),
        }),
      ]
    : []),
]
