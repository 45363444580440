import React, { ReactNode } from 'react'

import classNames from 'classnames'

import { Typography, TypographyVariant } from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { BarChart, BarChartData } from 'components/molecules/bar-chart'

import { CHART_COLORS } from 'app/core/pages/dashboard/constants'
import { DashboardAnimalUnit } from 'app/core/types/dashboard'
import { FarmProps } from 'app/core/types/system'
import { dateShortMonthYearFormat } from 'app/core/utils'
import { numberWithThousandDotSeparatorFormat } from 'app/core/utils/number'

import styles from './styles.module.scss'

type DashboardAnimalUnitPerHectareCardProps = {
  animalUnit?: DashboardAnimalUnit[]
  farms?: FarmProps[]
}

const parseChartData = (
  animalUnit: DashboardAnimalUnit[],
  farms: FarmProps[]
): { payloadKeys: string[]; stackKeys: string[]; data: BarChartData } => {
  const payloadKeys: string[] = ['total']
  const stackKeys: string[] = []
  const farmsProductiveAreaIndex = farms.reduce(
    (acc, farm) => ({
      ...acc,
      [farm.name]: farm.productive_area,
    }),
    {} as Record<string, number>
  )
  const dataIndex = animalUnit.reduce((currentDataIndex, item) => {
    const formattedDate = dateShortMonthYearFormat(item.date)
    const currentDate = currentDataIndex[formattedDate] ?? {
      label: formattedDate,
      stacks: {},
    }
    const currentDateFarm = currentDate.stacks[item.farm_name] ?? {
      label: item.farm_name,
      payload: {},
    }
    if (!stackKeys.includes(item.farm_name)) {
      stackKeys.push(item.farm_name)
    }

    item.age_grouped_animal_units.forEach(ageGroupCount => {
      const farmProductiveArea = farmsProductiveAreaIndex[item.farm_name]
      if (!farmProductiveArea) {
        return
      }
      const sum =
        (currentDateFarm.payload.total ?? 0) +
        ageGroupCount.animal_unit / farmProductiveArea
      currentDateFarm.payload.total = Number(sum.toFixed(2))
    })
    return {
      ...currentDataIndex,
      [formattedDate]: {
        ...currentDate,
        stacks: {
          ...currentDate.stacks,
          [item.farm_name]: currentDateFarm,
        },
      },
    }
  }, {} as Record<string, BarChartData[0]>)
  const data = Object.values(dataIndex)
  return { data, payloadKeys, stackKeys }
}

const renderTooltip = ({
  payload,
}: {
  payload?: {
    color?: string
    value?: number
    name?: string
    payload?: {
      stacks: Record<
        string,
        {
          label: string
          payload: {
            total: number
          }
        }
      >
    }
  }[]
}): ReactNode => {
  const basePayload = payload?.[0]?.payload
  if (!basePayload) {
    return null
  }
  const hoveredDay = Object.values(basePayload.stacks)?.map(stack => {
    const farmName = stack.label
    const stackPayloadItem = payload?.find(
      payloadItem => payloadItem.name === `stacks.${farmName}.payload.total`
    )
    return {
      color: stackPayloadItem?.color,
      name: farmName,
      value: stackPayloadItem?.value,
    }
  })
  return (
    <div className={styles.tooltip}>
      {hoveredDay.map((day, index) => (
        <div key={index} className={styles.tooltipRow}>
          <div
            className={styles.tooltipBox}
            style={{ background: day?.color }}
          />
          <div className={styles.tooltipTextContainer}>
            <span className={styles.tooltipText}>{day?.name}</span>
            <span
              className={classNames(
                styles.tooltipText,
                styles.tooltipTextHighlight
              )}
            >
              {numberWithThousandDotSeparatorFormat(Number(day?.value) || 0)}
            </span>
          </div>
        </div>
      ))}
      <Divider className={styles.tooltipDivider} />
      <div className={styles.tooltipRow}>
        <div className={styles.tooltipBox} style={{ background: '#606062' }} />
        <div className={styles.tooltipTextContainer}>
          <span className={styles.tooltipText}>Total</span>
          <span
            className={classNames(
              styles.tooltipText,
              styles.tooltipTextHighlight
            )}
          >
            {numberWithThousandDotSeparatorFormat(
              hoveredDay.reduce((sum, day) => sum + (day?.value ?? 0), 0)
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

const DashboardAnimalUnitPerHectareCard: React.FC<DashboardAnimalUnitPerHectareCardProps> =
  ({ animalUnit, farms }) => {
    const { data, payloadKeys, stackKeys } = parseChartData(
      animalUnit ?? [],
      farms ?? []
    )

    return (
      <>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <Typography
              text="UA por Hectare"
              variant={TypographyVariant.h3}
              className={styles.cardTitle}
            />
          </div>
          <BarChart
            cartesianGridProps={{ vertical: true }}
            containerProps={{
              width: '100%',
              height: 450,
              className: styles.container,
            }}
            xAxisProps={{
              dy: 15,
            }}
            yAxisProps={{
              label: {
                value: 'TOTAL UA',
                angle: -90,
                position: 'insideCenter',
                dx: -20,
                className: styles.yAxisLabel,
              },
            }}
            barChartProps={{
              margin: { left: 50, bottom: 100, right: 50 },
              barGap: 0,
              barSize: 12,
            }}
            payloadKeys={payloadKeys}
            stackKeys={stackKeys}
            data={data}
            showStackLabel={false}
            colors={CHART_COLORS}
            legendProps={{
              wrapperStyle: {
                display: 'flex',
                bottom: 50,
              },
            }}
            showContentLabel={false}
            colorByPayload={false}
            tooltipProps={{
              cursor: {
                fill: 'rgba(160, 160, 160, 0.3)',
              },
              content: renderTooltip,
            }}
          />
        </div>
      </>
    )
  }

export { DashboardAnimalUnitPerHectareCard }
