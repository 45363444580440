import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { FormRow } from 'components/molecules'
import { useForm } from 'components/organisms'
import { LoginLayout } from 'components/templates'

import { isAuthentication } from 'app/core/auth'
import {
  ALLOW_BULLETIN_ROUTES,
  NavigateList,
  NavigateParams,
} from 'app/core/routes/routes'
import { postAuthUser } from 'app/core/services'
import { StorageKeys } from 'app/core/types/storage'
import { IUserRequestData } from 'app/core/types/user'
import { handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

const Login: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = (path): void => history.push(path)

  const validationSchema = yup.object({
    email: yup
      .string()
      .label('E-mail')
      .email(Messages.YUP_INVALID_EMAIL)
      .required(Messages.YUP_REQUIRED_FIELD),
    password: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleLogin = useCallback(async (data: unknown): Promise<void> => {
    try {
      const response = await postAuthUser(data as IUserRequestData)

      localStorage.setItem(StorageKeys.auth_token, response.token)
    } catch (e) {
      handleHttpError(e)
    }
  }, [])

  const { Form } = useForm({ onSubmit: handleLogin, validationSchema })

  useEffect(() => {
    if (isAuthentication()) {
      navigateTo(
        ALLOW_BULLETIN_ROUTES
          ? NavigateList.selectService
          : NavigateList.dashboard
      )
    }
  })

  return (
    <LoginLayout
      header="Login"
      subheader="Bem-vindo(a), por favor faça login com a sua conta"
    >
      <Form>
        <Form.InputText
          label="E-mail"
          name="email"
          placeholder="Inserir e-mail"
        />
        <Form.InputText
          label="Senha"
          name="password"
          htmlType="password"
          placeholder="Inserir senha"
        />

        <FormRow alignRight={true}>
          <Typography
            text="Esqueci minha senha"
            variant={TypographyVariant.link}
            onClick={(): void => navigateTo(NavigateList.forgotPassword)}
          />
        </FormRow>
        <Button
          type={ButtonType.primary}
          label="Entrar"
          size={ButtonSize.large}
        />
      </Form>
    </LoginLayout>
  )
}

export { Login }
