import { Table } from '@tanstack/react-table'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { ButtonIconPosition, Icon, IconNames } from 'components/atoms'
import {
  AnimalDrawer,
  BoxedTable,
  ISelectButtonItem,
} from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { ReproductionLayout } from 'components/templates'

import { AnimalDrawerType } from 'app/core/types/animal'
import {
  DiagnosticAnimalReadResponseProps,
  DiagnosticRow,
  DiagnosticsHeaderInfo,
} from 'app/core/types/breeding'
import { dateTimeFormat } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type DiagnosticsDetailsTemplateProps = {
  table: Table<Partial<DiagnosticRow>>
  diagnostics: DiagnosticAnimalReadResponseProps | undefined
  showActionButton: boolean
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  headerInfo: DiagnosticsHeaderInfo
  handleGoToReview: () => void
  isLoading: boolean
  page: number
  setPage: Dispatch<SetStateAction<number>>
  alreadyCreatedHeaderSelectButtonItems: ISelectButtonItem[]
  headerSelectButtonItems: ISelectButtonItem[]
  resetFilters?: () => void
  filterIsActive?: boolean
}

const DiagnosticsDetailsTemplate: React.FC<DiagnosticsDetailsTemplateProps> = ({
  table,
  diagnostics,
  showActionButton,
  isDrawerOpen,
  handleToggleDrawer,
  onSubmit,
  headerInfo,
  handleGoToReview,
  isLoading,
  page,
  setPage,
  alreadyCreatedHeaderSelectButtonItems,
  headerSelectButtonItems,
  resetFilters,
  filterIsActive,
}) => {
  const [openDrawerType, setOpenDrawerType] = useState<AnimalDrawerType>()

  const defaultBoxedTableProps = {
    mainButtonLabel: 'Incluir',
    mainButtonIcon: IconNames['add-circle'],
    noDataMessage: filterIsActive
      ? Messages.ANIMALS_NOT_FOUND
      : Messages.ANIMAL_NOT_ADDED,
  }

  const handleResetFilters = (): void => {
    resetFilters?.call(this)
  }

  const handleFilter = useCallback(
    (drawerType: AnimalDrawerType): void => {
      setOpenDrawerType(drawerType)
      handleToggleDrawer()
    },
    [handleToggleDrawer]
  )

  return (
    <>
      <ReproductionLayout
        backButton
        title="Diagnóstico Reprodutivo de Gestação"
        diagnosticDate={
          isLoading ? (
            <Skeleton width={100} height={18} />
          ) : headerInfo.date ? (
            dateTimeFormat(headerInfo.date)
          ) : (
            '-'
          )
        }
        responsible={
          isLoading ? (
            <Skeleton width={100} height={18} />
          ) : (
            headerInfo.responsible || '-'
          )
        }
        {...(showActionButton && {
          headerLargeButtonAction: handleGoToReview,
          headerLargeButtonLabel: 'Revisar',
          headerLargeButtonIcon: <Icon name={IconNames['chevron-forward']} />,
          headerSelectButtonLabel: 'Planilha',
          headerSelectButtonItems: headerSelectButtonItems,
          headerButtonIcon: <Icon name={IconNames['chevron-down']} />,
          headerSelectButtonIconPosition: ButtonIconPosition.right,
        })}
        {...(!showActionButton && {
          headerSelectButtonLabel: 'Planilha',
          headerSelectButtonItems: alreadyCreatedHeaderSelectButtonItems,
          headerSelectButtonIcon: <Icon name={IconNames['chevron-down']} />,
          headerSelectButtonIconPosition: ButtonIconPosition.right,
        })}
      >
        {diagnostics?.items && (
          <BoxedTable
            title="Animais"
            table={table}
            data={diagnostics.items}
            mainButtonAction={(): void =>
              handleFilter(AnimalDrawerType.include)
            }
            mainButtonProps={{
              disabled: !showActionButton,
              ...(!showActionButton
                ? {
                    tooltip: Messages.BREEDING_DIAGNOSTICS_INACTIVE_TOOLTIP,
                    tooltipPosition: 'left',
                  }
                : {}),
            }}
            isLoading={isLoading}
            isTableHeaderSticky
            currentPage={page}
            totalPages={diagnostics.pages}
            totalItems={diagnostics.total}
            setPage={setPage}
            className={styles.boxedTable}
            quantityLabel="Fêmea"
            disableLastCellNavigation
            disableCellsNavigationByIndex={[3, 4, 7]}
            secondaryButtonLabel={'Filtrar'}
            secondaryButtonIcon={IconNames.filter}
            secondaryButtonAction={(): void =>
              handleFilter(AnimalDrawerType.filter)
            }
            secondaryButtonClassName={
              filterIsActive ? styles.filterButtonActive : ''
            }
            {...defaultBoxedTableProps}
          />
        )}
      </ReproductionLayout>
      <AnimalDrawer
        isOpen={isDrawerOpen}
        onToggle={handleToggleDrawer}
        onReset={handleResetFilters}
        onSubmit={onSubmit}
        drawerType={openDrawerType}
        hasAnimalCategory={false}
        hasIATFScheduleDropdown
        hasWeaningDate={false}
      />
    </>
  )
}

export { DiagnosticsDetailsTemplate }
