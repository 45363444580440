import React from 'react'

import { useProtocol } from 'app/core/hooks'
import { ProtocolProps } from 'app/core/types/hormonal'

import { ProtocolCreateTemplate } from './template'

const ProtocolCreate: React.FC = () => {
  const { addProtocol } = useProtocol({})

  const handleCreateProtocol = async (data: unknown): Promise<void> => {
    const { name } = data as ProtocolProps
    addProtocol(name)
  }

  return <ProtocolCreateTemplate onSubmit={handleCreateProtocol} />
}

export { ProtocolCreate }
