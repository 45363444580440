import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import { getAllMachineries } from 'app/core/services/system/machinery'
import { MachineryHook } from 'app/core/types/hooks'
import { MachineryProps } from 'app/core/types/system'
import { handleHttpError } from 'app/core/utils'

const useMachinery = (farmId?: string): MachineryHook => {
  const [machineries, setMachineries] = useState<MachineryProps[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const machineriesDroplist = machineries.map(machinery => {
    return {
      label: machinery.name,
      value: `${machinery.id}`,
      subLabel: `${machinery.fleet_number}`,
    }
  }) as SelectItemProps[]

  const readAllMachinery = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)

      const data = await getAllMachineries({
        farm_id: Number(farmId),
      })
      setMachineries(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [farmId])

  useEffect(() => {
    readAllMachinery()
  }, [readAllMachinery])

  return {
    machineries,
    machineriesDroplist,
    readAllMachinery,
    isLoading,
  }
}

export { useMachinery }
