/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/naming-convention */
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBreedingGroup, useBreedingGroupAnimal } from 'app/core/hooks'
import { IParamProps } from 'app/core/routes/routes'
import {
  AnimalDrawerType,
  AnimalFilterProps,
  AnimalSexLabel,
} from 'app/core/types/animal'

import {
  breedingGroupFemalesColumns,
  breedingGroupMalesColumns,
} from '../tables'
import { BreedingGroupDetailsTemplate } from './template'

const BreedingGroupDetails: React.FC = () => {
  const { groupId } = useParams<IParamProps>()

  const [selectedSex, setSelectedSex] = useState<AnimalSexLabel>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [femalePage, setFemalePage] = useState<number>(1)
  const [malePage, setMalePage] = useState<number>(1)

  const { group } = useBreedingGroup({ groupId })
  const {
    males,
    females,
    addAnimal,
    removeAnimal,
    isLoading,
    filterAnimals,
    filterIsActive,
    isLoadingExport,
    exportBreedingGroup,
  } = useBreedingGroupAnimal({
    groupId,
    femalePage,
    malePage,
  })

  const handleToggleDrawer = (sex?: AnimalSexLabel): void => {
    if (sex) {
      setSelectedSex(sex)
    }
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleRemoveAnimal = (animalId: number): void => {
    removeAnimal(animalId)
  }

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      const filterType = filters['filterType']
      delete filters['filterType']

      const updatedFilters = {
        ...filters,
        sex: selectedSex,
        is_active: 'true',
      } as AnimalFilterProps

      filterType == AnimalDrawerType.filter
        ? await filterAnimals(updatedFilters)
        : addAnimal(updatedFilters)

      handleToggleDrawer()
    },
    [selectedSex]
  )

  const tableFemales = useReactTable({
    data: females?.items || [],
    columns: breedingGroupFemalesColumns(handleRemoveAnimal),
    getCoreRowModel: getCoreRowModel(),
  })

  const tableMales = useReactTable({
    data: males?.items || [],
    columns: breedingGroupMalesColumns(handleRemoveAnimal),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <BreedingGroupDetailsTemplate
      females={females}
      tableFemales={tableFemales}
      femalePage={femalePage}
      setFemalePage={setFemalePage}
      males={males}
      tableMales={tableMales}
      malePage={malePage}
      setMalePage={setMalePage}
      group={group}
      selectedSex={selectedSex}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterAnimal}
      isLoading={isLoading}
      resetFilters={async (): Promise<void> => {
        filterAnimals({
          sex: selectedSex,
        } as AnimalFilterProps)
      }}
      filterIsActive={filterIsActive}
      isLoadingExport={isLoadingExport}
      onExportClick={exportBreedingGroup}
    />
  )
}

export { BreedingGroupDetails }
