import React, { Dispatch, SetStateAction, useState } from 'react'

import { Icon, IconNames } from 'components/atoms'

import { scrollToTop } from 'app/core/utils'

import styles from './styles.module.scss'

export type PaginationProps = {
  pages: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  disableScrollTop?: boolean
}

const Pagination: React.FC<PaginationProps> = ({
  pages,
  page,
  setPage,
  disableScrollTop,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = (): void => {
    setIsExpanded(prevState => !prevState)
  }

  const togglePreviousPage = (): void => {
    setPage(prevState => prevState - 1)
    scrollToTop(disableScrollTop)
  }

  const toggleNextPage = (): void => {
    setPage(prevState => prevState + 1)
    scrollToTop(disableScrollTop)
  }

  const toggleSelectedPage = (selectedPage: number): void => {
    setPage(selectedPage)
    scrollToTop(disableScrollTop)
  }

  const canPreviousPage = (): boolean => page > 1

  const canNextPage = (): boolean => page < pages

  if (pages <= 1) {
    return <></>
  }

  const getPageButtons = (): JSX.Element[] => {
    const buttons = []

    if (isExpanded) {
      for (let i = 1; i <= pages; i++) {
        buttons.push(
          <li key={i}>
            <button
              onClick={(): void => setPage(i)}
              className={page === i ? styles.selected : ''}
            >
              {i}
            </button>
          </li>
        )
      }
    } else {
      const firstPage = 1
      const lastPage = pages
      const ellipsis = (
        <li key="ellipsis">
          <button onClick={toggleExpand}>...</button>
        </li>
      )

      buttons.push(
        <li key={firstPage}>
          <button
            onClick={(): void => toggleSelectedPage(firstPage)}
            className={page === firstPage ? styles.selected : ''}
          >
            {firstPage}
          </button>
        </li>
      )

      if (page - 2 > firstPage) {
        buttons.push(ellipsis)
      }

      for (
        let i = Math.max(2, page - 1);
        i <= Math.min(pages - 1, page + 1);
        i++
      ) {
        buttons.push(
          <li key={i}>
            <button
              onClick={(): void => toggleSelectedPage(i)}
              className={page === i ? styles.selected : ''}
            >
              {i}
            </button>
          </li>
        )
      }

      if (page + 2 < lastPage) {
        buttons.push(ellipsis)
      }

      buttons.push(
        <li key={lastPage}>
          <button
            onClick={(): void => toggleSelectedPage(lastPage)}
            className={page === lastPage ? styles.selected : ''}
          >
            {lastPage}
          </button>
        </li>
      )
    }

    return buttons
  }

  return (
    <div className={styles.pagination}>
      <button onClick={togglePreviousPage} disabled={!canPreviousPage()}>
        <Icon name={IconNames['chevron-back']} size={15} />
      </button>
      <ul className={styles.buttonWrapper}>{getPageButtons()}</ul>
      <button onClick={toggleNextPage} disabled={!canNextPage()}>
        <Icon name={IconNames['chevron-forward']} size={15} />
      </button>
    </div>
  )
}

export { Pagination }
