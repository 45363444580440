import { Table } from '@tanstack/react-table'
import React from 'react'

import { ButtonIconPosition, Icon, IconNames } from 'components/atoms'
import { BoxedTable, ISelectButtonItem } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { AnimalCreateProps } from 'app/core/types/animal'
import { Messages } from 'config/messages'

type AnimalCreateTemplateProps = {
  handleAddRow: () => void
  handleGoToReview: () => void
  sector: string
  animals: AnimalCreateProps[]
  table: Table<AnimalCreateProps>
  headerSelectButtonItems: ISelectButtonItem[]
}

const AnimalCreateTemplate: React.FC<AnimalCreateTemplateProps> = ({
  handleAddRow,
  handleGoToReview,
  sector,
  animals,
  table,
  headerSelectButtonItems,
}) => (
  <BoxedLayout
    title="Cadastro de animais"
    bottomLeftButtonAction={handleAddRow}
    bottomLeftButtonLabel="Adicionar animal"
    bottomLeftButtonIcon={<Icon name={IconNames['add-circle']} />}
    headerLargeButtonAction={handleGoToReview}
    headerLargeButtonLabel="Revisar"
    headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
    headerLargeButtonIconPosition={ButtonIconPosition.right}
    date={new Date().toISOString()}
    headerHighlightedLabel="Setor"
    headerHighlightedData={sector}
    isChildrenSticky
    headerSelectButtonLabel="Planilha"
    headerSelectButtonItems={headerSelectButtonItems}
    headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
    headerSelectButtonIconPosition={ButtonIconPosition.right}
  >
    <BoxedTable
      data={animals}
      table={table}
      isTableHeaderSticky
      title="Animais"
      quantityLabel="Entrada"
      noDataMessage={Messages.ANIMAL_NOT_ADDED}
      disableNavigation
    />
  </BoxedLayout>
)

export { AnimalCreateTemplate }
