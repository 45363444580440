import { postRevertShutdown } from "app/core/services"
import { addToast } from "app/core/utils"

const useRevertShutdown = async (animalId: number): Promise<void> => {
    try {
        await postRevertShutdown(animalId)
    } catch (e) {
        addToast({ message: (e as Error).message })
    }
}

export { useRevertShutdown }
