import { SuggestionProps } from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/suggestion/classification_cost_center/'

export type SuggestionRequestData = {
  locationId?: string,
  serviceId?: string
}

const getSuggestion = async (suggestionInput: SuggestionRequestData): Promise<SuggestionProps> => {
  const params = suggestionInput ?
    {
      location_id: suggestionInput.locationId,
      service_id: suggestionInput.serviceId
    } : null
  const response = await http.get(baseUrl, { params })
  return response.data;
}

export { getSuggestion }
