import { useState } from 'react'

import { useServiceOrderFilters } from 'providers/service-orders/filters'

import { Icon, IconNames, InputText } from 'components/atoms'

import styles from './styles.module.scss'

const ServiceOrderTextFilter: React.FC = () => {
  const { handleCleanContainsName, inputContainsName, onChangeContainsName } =
    useServiceOrderFilters()

  const [searchPlaceholder, setSearchPlaceholder] = useState('Buscar')

  return (
    <div className={styles.findInput}>
      <Icon
        className={styles.findInputIcon}
        name={IconNames['close-circle']}
        onClick={(): void => handleCleanContainsName()}
      />
      <InputText
        name="buscar"
        ref={inputContainsName}
        placeholder={searchPlaceholder}
        htmlType={'text'}
        className={styles.findInputText}
        onChange={(e): Promise<void> | undefined =>
          onChangeContainsName(e.currentTarget.value)
        }
        onFocus={(): void =>
          setSearchPlaceholder(
            'Busque por executor, serviço, classificação, etc'
          )
        }
        onBlur={(): void => setSearchPlaceholder('Buscar')}
      />
    </div>
  )
}

export { ServiceOrderTextFilter }
