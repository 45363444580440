import React, { createContext, useContext } from 'react'

import { useServiceOrder as useServiceOrderHook } from 'app/core/hooks'
import { ServiceOrderHook } from 'app/core/types/hooks'

import { ServiceOrdersFiltersProvider } from './filters'

export const ServiceOrderContext = createContext<ServiceOrderHook | null>(null)

export const ServiceOrderProvider: React.FC = ({ children }) => {
  const serviceOrderHook = useServiceOrderHook()

  return (
    <ServiceOrderContext.Provider value={serviceOrderHook}>
      <ServiceOrdersFiltersProvider>{children}</ServiceOrdersFiltersProvider>
    </ServiceOrderContext.Provider>
  )
}

export const useServiceOrder = (): ServiceOrderHook => {
  const context = useContext(ServiceOrderContext)

  if (!context)
    throw new Error(
      'useServiceOrderContext must be used within a ServiceOrderProvider'
    )

  return context
}
