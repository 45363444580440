import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useHistory } from 'react-router-dom'

import { useBirth } from 'app/core/hooks/births'
import { NavigateList } from 'app/core/routes/routes'
import { BirthCreateProps } from 'app/core/types/birth'

import { birthListColumns } from '../tables'
import { BirthFeedbackTemplate } from './template'

const BirthFeedback: React.FC = () => {
  const history = useHistory<NavigateList>()

  const {
    animals,
    failedAnimals,
    exportFailedAnimals,
    removeStorage,
    isLoading,
  } = useBirth({})

  const handleFinishBirth = async (): Promise<void> => {
    removeStorage()
    history.push(NavigateList.birthHistory)
  }

  const table = useReactTable({
    data: (animals as BirthCreateProps[]) || [],
    columns: birthListColumns({ hasAnimalId: false }),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <BirthFeedbackTemplate
      animals={animals}
      failedAnimals={failedAnimals}
      exportFailedAnimals={exportFailedAnimals}
      table={table}
      isLoading={isLoading}
      handleFinish={handleFinishBirth}
    />
  )
}

export { BirthFeedback }
