import { HandlingTypes, HandlingTypesFilter } from 'app/core/types/handling'

export const HANDLING_TYPES = Object.entries(HandlingTypes).map(
  ([key, value]) => ({ label: value, value: key })
)

export const HANDLING_TYPES_FILTER = Object.entries(HandlingTypesFilter).map(
  ([key, value]) => ({
    label: value,
    value: key === 'all' ? '' : key,
  })
)
