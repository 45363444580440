import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useShutdown } from 'app/core/hooks/shutdown'
import { NavigateList } from 'app/core/routes/routes'
import {
  ShutdownCreateProps,
  ShutdownDescriptionStorageProps,
} from 'app/core/types/shutdown'
import { StorageKeys } from 'app/core/types/storage'

import { shutdownColumns } from '../columns'
import { ShutdownFeedbackTemplate } from './template'

const ShutdownFeedback: React.FC = () => {
  const history = useHistory()
  const navigateTo = useCallback(
    (): void => history.push(NavigateList.animalList),
    [history]
  )

  const savedShutdown = JSON.parse(
    localStorage.getItem(StorageKeys.shutdown_review) as string
  ) as ShutdownCreateProps[]
  const savedShutdownType = localStorage.getItem(
    StorageKeys.shutdown_type_review
  ) as string
  const shutdownDescriptions = JSON.parse(
    localStorage.getItem(StorageKeys.shutdown_descriptions) as string
  ) as ShutdownDescriptionStorageProps

  const { exportFailedAnimals, failedAnimals, shutdownDate, removeStorage } =
    useShutdown()

  if (
    !savedShutdown ||
    !savedShutdownType ||
    !shutdownDate ||
    !shutdownDescriptions
  ) {
    navigateTo()
  }

  const table = useReactTable({
    data: savedShutdown,
    columns: shutdownColumns({
      shutdownType: savedShutdownType,
      shutdownDescriptions,
    }),
    getCoreRowModel: getCoreRowModel(),
  })

  const handleFinish = async (): Promise<void> => {
    removeStorage()
    history.push(NavigateList.animalList)
  }

  return (
    <ShutdownFeedbackTemplate
      savedShutdown={savedShutdown}
      shutdownDate={shutdownDate}
      table={table}
      exportFailedAnimals={exportFailedAnimals}
      failedAnimals={failedAnimals}
      handleFinish={handleFinish}
    />
  )
}

export { ShutdownFeedback }
