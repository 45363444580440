type DownloadFileProps = {
  data: Blob
  fileName: string
  extension?: string
}

const downloadFile = ({
  data,
  fileName,
  extension = 'xlsx',
}: DownloadFileProps): void => {
  const url = URL.createObjectURL(data)

  const anchor = document.createElement('a')
  anchor.href = url
  anchor.target = '_blank'
  anchor.download = `${fileName}.${extension}`
  anchor.click()

  URL.revokeObjectURL(url)
}

export { downloadFile }
