import { isTestEnvironment } from 'config/environment'

import { Typography, TypographyVariant } from '../typography'
import styles from './styles.module.scss'

const EnvironmentMessage: React.FC = () => {
  if (!isTestEnvironment) return null

  return (
    <div className={styles.headerMessage}>
      <div className={styles.headerMessageBackground}>
        <Typography text="Ambiente de testes" variant={TypographyVariant.p} />
      </div>
    </div>
  )
}

export { EnvironmentMessage }
