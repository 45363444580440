import { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonSize,
  ButtonType,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import {
  CustomModal,
  LabeledCard,
  LabeledCardButtonRow,
  LabeledCardRow,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { NavigateParams } from 'app/core/routes/routes'
import {
  HEAT_TYPE_OPTIONS,
  HeatTypes,
  INSEMINATION_TYPE_OPTIONS,
  InseminationTypes,
  ScheduleProps,
} from 'app/core/types/hormonal'

import styles from './styles.module.scss'

type InseminationCreateTemplateProps = {
  navigateTo: NavigateParams
  isModalOpen: boolean
  setSelectedScheduleId: Dispatch<SetStateAction<string | undefined>>
  schedule?: ScheduleProps
  schedulesDroplist?: SelectItemProps[]
  inseminationType?: InseminationTypes
  setInseminationType: Dispatch<SetStateAction<InseminationTypes | undefined>>
  heatType?: HeatTypes
  setHeatType: Dispatch<SetStateAction<HeatTypes | undefined>>
  breedingStationOptions?: SelectItemProps[]
  handleCloseModal: () => void
  onSubmit: IFormProps['onSubmit']
}

const InseminationCreateTemplate: React.FC<InseminationCreateTemplateProps> = ({
  isModalOpen,
  setSelectedScheduleId,
  schedule,
  schedulesDroplist,
  inseminationType,
  setInseminationType,
  setHeatType,
  breedingStationOptions,
  handleCloseModal,
  onSubmit,
}) => {
  const { Form, watch } = useForm({
    onSubmit,
  })

  const { heat_type } = watch()

  const showIATFSchedule = heat_type && heat_type !== HeatTypes.natural_heat
  const showBreedingStation =
    inseminationType === InseminationTypes.natural_cover ||
    heat_type === HeatTypes.natural_heat

  return (
    <BoxedLayout title="Nova Cobertura/Inseminação">
      <LabeledCard title="Informações gerais">
        <Form>
          <LabeledCardRow>
            <Form.Select
              label="Tipo de Cobertura/Inseminação"
              name="type"
              options={
                schedulesDroplist?.length
                  ? INSEMINATION_TYPE_OPTIONS
                  : undefined
              }
              isLoading={!schedulesDroplist?.length}
              onChange={(value): void =>
                setInseminationType(
                  ((value as SelectItemProps).value as InseminationTypes) || ''
                )
              }
            />

            {inseminationType &&
              inseminationType ===
                InseminationTypes.artificial_insemination && (
                <>
                  <Form.RadioButton
                    title="Tipo de cio"
                    name="heat_type"
                    options={HEAT_TYPE_OPTIONS}
                    onChange={(e): void =>
                      setHeatType((e?.target.value as HeatTypes) || '')
                    }
                  />

                  {showIATFSchedule && (
                    <Form.Select
                      label="Vincular à programação IATF"
                      name="iatfschedule_id"
                      isLoading={!schedulesDroplist?.length}
                      options={schedulesDroplist}
                      placeholder="Buscar Programação IATF"
                      onChange={(value): void =>
                        setSelectedScheduleId((value as SelectItemProps).value)
                      }
                    />
                  )}

                  {schedule && showIATFSchedule && (
                    <span className={styles.expandCardInfoColumn}>
                      <Typography
                        text="Estação de Monta"
                        variant={TypographyVariant.label}
                      />
                      <Typography
                        text={schedule.breeding_station_name || '-'}
                        variant={TypographyVariant.h6}
                      />
                    </span>
                  )}
                </>
              )}

            {inseminationType && showBreedingStation && (
              <Form.Select
                label="Estação de Monta"
                name="breeding_station_id"
                isLoading={!breedingStationOptions}
                options={breedingStationOptions}
              />
            )}
          </LabeledCardRow>

          <LabeledCardButtonRow>
            <Button
              type={ButtonType.primary}
              label="Salvar"
              size={ButtonSize.large}
            />
          </LabeledCardButtonRow>
        </Form>

        <CustomModal
          modalIsOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          modalHeader="Nova Cobertura/ Inseminação criada com sucesso"
          modalText={'Em seguida selecione e inclua os animais participantes.'}
          primaryButtonLabel="Incluir animais"
          primaryButtonAction={handleCloseModal}
        />
      </LabeledCard>
    </BoxedLayout>
  )
}

export { InseminationCreateTemplate }
