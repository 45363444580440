import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { getOr } from 'lodash/fp'

import styles from './styles.module.scss'
import { Column, ColumnType, Data } from './types'

interface IRowCellProps {
  column: Column
  row: Data
  onSelectRow: (row: Record<string | number, unknown>) => void
  isSelected?: boolean
}

const RowCell = React.memo(
  ({
    column,
    row,
    onSelectRow,
    isSelected = false,
  }: IRowCellProps): JSX.Element | null => {
    if (column.type === ColumnType.selectRowCheckbox) {
      return (
        <td>
          <input
            type="checkbox"
            onChange={(): void => onSelectRow(row)}
            checked={isSelected}
          />
        </td>
      )
    }

    if (column?.renderRowCell) {
      return column.renderRowCell(row)
    }

    if (!column.property) {
      return null
    }

    return <td>{getOr('', column.property, row) as React.ReactNode}</td>
  }
)

interface ITBodyProps {
  columns: Column[]
  data: Data[]
  onSelectRow: (row: Record<string | number, unknown>) => void
  selectedRows: Record<number | string, unknown>
  getIdOfRow: (row: Data) => number | string
  isLoading?: boolean
}

const TBody = ({
  columns,
  data,
  onSelectRow,
  selectedRows,
  getIdOfRow,
  isLoading,
}: ITBodyProps): JSX.Element => {
  const loadingRowsQuantity = 10

  const loadingRows = (): JSX.Element[] => {
    return Array(loadingRowsQuantity)
      .fill({})
      .map((_, i) => (
        <tr key={`row-${i}`} className={styles.loading}>
          {columns.map((_, index) => (
            <td key={index}>
              <Skeleton height={30} />
            </td>
          ))}
        </tr>
      ))
  }

  return (
    <tbody>
      {isLoading
        ? loadingRows()
        : data?.map(row => {
            const idOfRow = getIdOfRow(row)
            return (
              <tr id={`id-${idOfRow}`} key={idOfRow}>
                {columns?.map((col, colIndex) => {
                  return (
                    <RowCell
                      key={`${idOfRow}_${colIndex}`}
                      column={col}
                      row={row}
                      onSelectRow={onSelectRow}
                      isSelected={!!selectedRows?.[getIdOfRow(row)]}
                    />
                  )
                })}
              </tr>
            )
          })}
    </tbody>
  )
}

export { TBody }
