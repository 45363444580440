import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useCollectiveMovements } from 'app/core/hooks/collective-movements'
import { NavigateList } from 'app/core/routes/routes'
import { CollectiveMovementsCreateProps } from 'app/core/types/collective-movements'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { collectiveMovementsCreateColumns } from '../tables'
import { CollectiveMovementsReviewTemplate } from './template'

const CollectiveMovementsReview: React.FC = () => {
  const history = useHistory<NavigateList>()
  const [page, setPage] = useState<number>(1)

  const {
    animals,
    collectiveMovement,
    removeAnimal,
    createCollectiveMovement,
    isLoading,
  } = useCollectiveMovements({ page })

  const handleCreateCollectiveMovement = async (): Promise<void> => {
    if (animals?.items.length === 0) {
      addToast({ message: Messages.COLLECTIVE_MOVEMENTS_ANIMAL_NOT_ADDED })
      return
    }

    await createCollectiveMovement()

    history.push(NavigateList.collectiveMovementsFeedback)
  }

  const columns = useMemo<ColumnDef<CollectiveMovementsCreateProps>[]>(
    () =>
      collectiveMovementsCreateColumns({
        handleRemoveAnimal: removeAnimal,
        originFarm: collectiveMovement?.originFarm?.label,
        originSector: collectiveMovement?.originSector?.label,
        destinationFarm: collectiveMovement?.destinationFarm?.label,
        destinationSector: collectiveMovement?.destinationSector?.label,
      }),
    [removeAnimal, collectiveMovement]
  )

  const table = useReactTable({
    data: (animals?.items as CollectiveMovementsCreateProps[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <CollectiveMovementsReviewTemplate
      animals={animals}
      collectiveMovement={collectiveMovement}
      table={table}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      handleCreateCollectiveMovement={handleCreateCollectiveMovement}
    />
  )
}

export { CollectiveMovementsReview }
