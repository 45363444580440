import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useBirth } from 'app/core/hooks/births'
import { NavigateList } from 'app/core/routes/routes'

import { birthListColumns } from '../tables'
import { BirthReviewTemplate } from './template'

const BirthReview: React.FC = () => {
  const history = useHistory()

  const { animals, addBirth } = useBirth({})

  if (!animals) {
    history.push(NavigateList.birthHistory)
  }

  const defaultAnimalsTable = useReactTable({
    data: animals,
    columns: birthListColumns({ hasAnimalId: false }),
    getCoreRowModel: getCoreRowModel(),
  })

  const handleAnimals = async (): Promise<void> => {
    await addBirth({
      animals,
    })

    history.push(NavigateList.birthFeedback)
  }

  return (
    <BirthReviewTemplate
      handleAnimals={handleAnimals}
      animals={animals}
      table={defaultAnimalsTable}
    />
  )
}

export { BirthReview }
