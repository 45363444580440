import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'

import { NavigateList } from 'app/core/routes/routes'
import {
  deleteBreedingGroup,
  getBreedingGroupReport,
  getReadAllAnimals,
  getReadAllBreedingGroups,
  getReadBreedingGroup,
  getReadBreedingGroupFemales,
  getReadBreedingGroupMales,
  postAddGroupAnimal,
  postCreateBreedingGroup,
  postRemoveBreedingGroupAnimal,
} from 'app/core/services'
import {
  AnimalFilterProps,
  AnimalRequestData,
  AnimalSexLabel,
} from 'app/core/types/animal'
import {
  BreedingGroupAnimalResponseData,
  BreedingGroupCreateRequestData,
  BreedingGroupCreateRequestFormatData,
  BreedingGroupFilterProps,
  BreedingGroupProps,
  BreedingGroupReadResponseData,
  BreedingLoadingState,
} from 'app/core/types/breeding'
import {
  BreedingGroupAnimalHook,
  BreedingGroupHook,
  BreedingGroupHookProps,
} from 'app/core/types/hooks'
import {
  DEFAULT_ITEMS_PER_PAGE_DETAILS,
  DEFAULT_ITEMS_PER_PAGE_LIST,
  FILTER_ANIMALS_SIZE,
} from 'app/core/types/system'
import {
  addToast,
  arrayToCsv,
  breedingGroupIdFormat,
  dateForFileName,
  dateTimeISOFormat,
  downloadFile,
  getAnimalSex,
  getFormattedDateValueOrNull,
  getValueOrNull,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import {
  BreedingAnimalReportFactory,
  BreedingAnimalReportType,
  createGetBreedingAnimalReportParams,
  exportBreedingAnimals,
} from '../breeding-animal-report-factory'

const useBreedingGroup = ({
  groupId,
  filters,
  page,
  size,
}: BreedingGroupHookProps): BreedingGroupHook => {
  const history = useHistory<NavigateList>()

  const [groups, setGroups] = useState<BreedingGroupReadResponseData>()
  const [group, setGroup] = useState<BreedingGroupProps>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllGroups = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const data = await getReadAllBreedingGroups(
        filters,
        page,
        size || DEFAULT_ITEMS_PER_PAGE_LIST
      )

      if (data.total === 0) {
        addToast({
          message: Messages.BREEDING_GROUP_NOT_FOUND,
          type: 'warning',
        })
      }

      setGroups(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [filters, page, size])

  const readGroup = useCallback(async (): Promise<void> => {
    if (groupId) {
      try {
        const data = await getReadBreedingGroup(groupId)
        setGroup(data)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [groupId])

  const addGroup = useCallback(
    async (request: BreedingGroupCreateRequestData): Promise<void> => {
      if (request) {
        const formatRequest = {
          name: request.name,
          breeding_station_id: parseInt(request.breeding_station.value),
          init_date: dateTimeISOFormat(request.init_date),
          final_date: dateTimeISOFormat(request.final_date),
        } as BreedingGroupCreateRequestFormatData

        try {
          const data = await postCreateBreedingGroup(formatRequest)
          history.push(`${NavigateList.breedingGroupDetails}${data.id}`)
        } catch (err) {
          if (
            axios.isAxiosError(err) &&
            err.response?.data.message === 'Estação de monta inativa'
          ) {
            addToast({
              message: Messages.BREEDING_GROUP_CREATE_INACTIVE_STATION,
            })
            return
          }

          addToast({ message: Messages.ERROR_MESSAGE })
        }
      }
    },
    [history]
  )

  const deleteGroup = useCallback(
    async (breedingGroupId: number) => {
      if (breedingGroupId) {
        try {
          await deleteBreedingGroup(breedingGroupId)

          readAllGroups()

          addToast({
            message: Messages.BREEDING_GROUP_DELETED_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e, false)
        }
      }
    },
    [readAllGroups]
  )

  const exportBreedingGroups = useCallback(async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getBreedingGroupReport(
        filters as BreedingGroupFilterProps
      )

      const breedingGroups = data.map(group => ({
        'N° do Grupo de Repasse': breedingGroupIdFormat(
          group.breeding_group_id
        ),
        Nome: getValueOrNull(group.breeding_group_name),
        'Data início': getFormattedDateValueOrNull(group.init_date),
        'Data fim': getFormattedDateValueOrNull(group.final_date),
        Ativo: group.is_active ? 'Sim' : 'Não',
        'Nº de Nascimento': getValueOrNull(group.birth_number),
        'Nº de Plantel': getValueOrNull(group.stock_number),
        'Brinco eletrônico': getValueOrNull(group.electronic_eartag),
        'Sx.': getAnimalSex(group.animal_sex),
      }))

      downloadFile({
        data: arrayToCsv(breedingGroups, ';'),
        fileName: `grupos-repasse-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({
        message: Messages.BREEDING_GROUP_EXPORT_SUCCESS,
        type: 'success',
      })
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters])

  useEffect(() => {
    readAllGroups()
    readGroup()
  }, [readAllGroups, readGroup])

  return {
    groups,
    group,
    addGroup,
    deleteGroup,
    isLoading,
    isLoadingExport,
    exportBreedingGroups,
  }
}

const useBreedingGroupAnimal = ({
  groupId,
  femalePage,
  malePage,
}: BreedingGroupHookProps): BreedingGroupAnimalHook => {
  const { group } = useBreedingGroup({ groupId })

  const [females, setFemales] = useState<BreedingGroupAnimalResponseData>()
  const [males, setMales] = useState<BreedingGroupAnimalResponseData>()
  const [isLoading, setIsLoading] = useState<BreedingLoadingState>({
    female: false,
    male: false,
  })
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const [filterIsActive, setFilterIsActive] = useState<BreedingLoadingState>({
    female: false,
    male: false,
  })

  const readFemales = useCallback(
    async (filters?: Record<string, unknown>): Promise<void> => {
      if (groupId) {
        try {
          const data = await getReadBreedingGroupFemales(
            groupId,
            femalePage || 1,
            DEFAULT_ITEMS_PER_PAGE_DETAILS,
            filters
          )
          setFemales(data)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [groupId, femalePage]
  )

  const readMales = useCallback(
    async (filters?: Record<string, unknown>): Promise<void> => {
      if (groupId) {
        try {
          const data = await getReadBreedingGroupMales(
            groupId,
            malePage || 1,
            DEFAULT_ITEMS_PER_PAGE_DETAILS,
            filters
          )
          setMales(data)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [groupId, malePage]
  )

  const addAnimal = useCallback(
    async (filters: AnimalFilterProps): Promise<void> => {
      if (groupId) {
        try {
          if (filters.sex === AnimalSexLabel.female) {
            setIsLoading({ ...isLoading, female: true })
          } else {
            setIsLoading({ ...isLoading, male: true })
          }

          const newFilters = {
            ...filters,
            size: FILTER_ANIMALS_SIZE,
          }

          const animals = await getReadAllAnimals(
            newFilters,
            1,
            FILTER_ANIMALS_SIZE
          )
          const animalIds = animals?.items.map(animal => animal.id)

          if (!animalIds.length) {
            addToast({
              message: Messages.BREEDING_ANIMAL_NOT_FOUND,
            })

            setIsLoading({ ...isLoading, female: false, male: false })

            return
          }

          addToast({
            message: Messages.BREEDING_GROUP_ADDING_ANIMALS,
            type: 'info',
          })

          const data = await postAddGroupAnimal(groupId, animalIds)

          data?.forEach(animal => {
            const animalId = Object.keys(animal)
            const errorMessage = Object.values(animal)
            addToast({
              message: `${errorMessage}: ${
                animals.items.find(animal => animal.id === Number(animalId))
                  ?.stock_number || animalId
              }`,
            })
          })

          readFemales()
          readMales()
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading({ ...isLoading, female: false, male: false })
        }
      }
    },
    [groupId, readFemales, readMales, isLoading]
  )

  const removeAnimal = useCallback(
    async (animalId: number): Promise<void> => {
      if (groupId) {
        try {
          await postRemoveBreedingGroupAnimal(groupId, animalId)
          readFemales()
          readMales()
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [groupId, readFemales, readMales]
  )

  const filterAnimals = useCallback(
    async (filters?: AnimalRequestData): Promise<void> => {
      if (groupId) {
        try {
          const newFilters = {
            ...filters,
            size: DEFAULT_ITEMS_PER_PAGE_DETAILS,
          } as AnimalFilterProps

          const sex = filters?.sex

          sex == AnimalSexLabel.female
            ? setIsLoading({ ...isLoading, female: true })
            : setIsLoading({ ...isLoading, male: true })

          sex == AnimalSexLabel.female
            ? await readFemales(newFilters)
            : await readMales(newFilters)

          const filtersWithoutSex = { ...filters } as Record<string, unknown>
          delete filtersWithoutSex['sex']

          setFilterIsActive(prevState => ({
            ...prevState,
            [sex == AnimalSexLabel.female
              ? AnimalSexLabel.female
              : AnimalSexLabel.male]: !!Object.keys(filtersWithoutSex).length,
          }))
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading({ ...isLoading, female: false, male: false })
        }
      }
    },
    [groupId, readFemales, readMales, isLoading]
  )

  const exportBreedingGroup = async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      const params = createGetBreedingAnimalReportParams(
        groupId as number,
        females?.total ?? 0,
        males?.total ?? 0,
        BreedingAnimalReportType.group,
        group?.name
      )

      const breedingGroupAnimalsFactory = (): BreedingAnimalReportFactory => ({
        getFemales: (
          groupId,
          page,
          total
        ): Promise<BreedingGroupAnimalResponseData> =>
          getReadBreedingGroupFemales(groupId, page, total),
        getMales: (
          groupId,
          page,
          total
        ): Promise<BreedingGroupAnimalResponseData> =>
          getReadBreedingGroupMales(groupId, page, total),
      })

      await exportBreedingAnimals(
        breedingGroupAnimalsFactory(),
        params,
        `grupo-repasse-${dateForFileName()}`
      )
    } catch (e) {
      handleHttpError(e, false)
    } finally {
      setIsLoadingExport(false)
    }
  }

  useEffect(() => {
    readFemales()
    readMales()
  }, [readFemales, readMales])

  return {
    females,
    males,
    addAnimal,
    removeAnimal,
    isLoading,
    filterAnimals,
    filterIsActive,
    isLoadingExport,
    exportBreedingGroup,
  }
}

export { useBreedingGroup, useBreedingGroupAnimal }
