import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import * as yup from 'yup'

import { SelectItemProps } from 'components/atoms'
import { useForm } from 'components/organisms/form'

import { useFarm } from 'app/core/hooks'
import { IParamProps, NavigateList } from 'app/core/routes/routes'
import { BRAZILIAN_STATES, FarmCreateRequestData } from 'app/core/types/system'
import { cnpjMask, unMask, zipCodeMask } from 'app/core/utils/input_mask'
import { Messages, yupNumberFieldMax } from 'config/messages'

import { FarmCreateTemplate } from './template'

const FarmCreate: React.FC = () => {
  const { farmId } = useParams<IParamProps>()
  const isEdit = !!farmId

  const validationSchema = yup.object({
    name: yup
      .string()
      .max(50, yupNumberFieldMax('nome', 50))
      .required(Messages.YUP_REQUIRED_FIELD),
    zip_code: yup
      .string()
      .test('len', Messages.ZIP_CODE_EXACT, val => val?.toString().length === 9)
      .required(Messages.YUP_REQUIRED_FIELD),
    address: yup
      .string()
      .max(100, yupNumberFieldMax('endereço', 100))
      .required(Messages.YUP_REQUIRED_FIELD),
    address_number: yup
      .number()
      .test('len', yupNumberFieldMax('numero', 5), value =>
        value ? value.toString().length <= 5 : false
      )
      .typeError(Messages.YUP_NUMBER_REQUIRED)
      .required(Messages.YUP_REQUIRED_FIELD),
    neighborhood: yup
      .string()
      .max(50, yupNumberFieldMax('bairro', 50))
      .required(Messages.YUP_REQUIRED_FIELD),
    complement: yup
      .string()
      .max(20, yupNumberFieldMax('complemento', 20))
      .optional(),
    city: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    state: yup
      .mixed<SelectItemProps>()
      .oneOf(BRAZILIAN_STATES)
      .required(Messages.YUP_REQUIRED_FIELD),
    cnpj: yup
      .string()
      .max(18, yupNumberFieldMax('CNPJ', 18))
      .required(Messages.YUP_REQUIRED_FIELD),
    state_registration: yup
      .number()
      .test('len', yupNumberFieldMax('inscrição estadual', 20), value =>
        value ? value.toString().length <= 20 : false
      )
      .typeError(Messages.YUP_NUMBER_REQUIRED)
      .required(Messages.YUP_REQUIRED_FIELD),
    total_area: yup
      .number()
      .test('len', yupNumberFieldMax('área total', 15), value =>
        value ? value.toString().length <= 15 : false
      )
      .typeError(Messages.YUP_NUMBER_REQUIRED)
      .required(Messages.YUP_REQUIRED_FIELD),
    productive_area: yup
      .number()
      .test('len', yupNumberFieldMax('área produtiva', 15), value =>
        value ? value.toString().length <= 15 : false
      )
      .typeError(Messages.YUP_NUMBER_REQUIRED)
      .required(Messages.YUP_REQUIRED_FIELD),
    status: yup.boolean().required(Messages.YUP_REQUIRED_FIELD),
  })

  const history = useHistory<NavigateList>()

  const [farmStatus, setFarmStatus] = useState(true)

  const { addFarm, getAddress, farms, editFarm } = useFarm()

  const onSubmit = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const requestData = data as FarmCreateRequestData
      requestData.zip_code = +unMask(requestData.zip_code.toString())
      requestData.cnpj = unMask(requestData.cnpj)
      const state = data.state as SelectItemProps
      requestData.state = state.value

      isEdit ? await editFarm(+farmId, requestData) : await addFarm(requestData)
      history.replace(NavigateList.farmList)
    },
    [addFarm, history, editFarm, farmId, isEdit]
  )

  const { Form, setValue, setError } = useForm({
    onSubmit,
    validationSchema,
    initialValues: { status: farmStatus },
  })

  useEffect(() => {
    const farm = farms?.filter(farm => farm.id === +farmId)[0]

    if (farm) {
      setFarmStatus(farm.status)

      setValue('name', farm?.name)
      setValue('zip_code', zipCodeMask(farm?.zip_code.toString() ?? ''))
      setValue('address', farm?.address)
      setValue('address_number', farm?.address_number)
      setValue('neighborhood', farm?.neighborhood)
      if (farm.complement) setValue('complement', farm?.complement)
      setValue('city', farm?.city)
      setValue(
        'state',
        BRAZILIAN_STATES.find(e => e.value === farm.state)
      )
      setValue('cnpj', cnpjMask(farm?.cnpj ?? ''))
      setValue('state_registration', farm?.state_registration)
      setValue('total_area', farm?.total_area)
      setValue('productive_area', farm?.productive_area)
      setValue('status', farmStatus)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, farms])

  const onZipCodeChange = (zipCode: string): void => {
    setValue('zip_code', zipCodeMask(zipCode))
  }

  const onCnpjChange = (cnpj: string): void => {
    setValue('cnpj', cnpjMask(cnpj))
  }

  const onStatusChange = (status: boolean): void => {
    setFarmStatus(status)
    setValue('status', status)
  }

  const onZipCodeBlur = useCallback(
    async (zipCode: string): Promise<void> => {
      const rawZipCode = unMask(zipCode)

      if (rawZipCode.length == 8) {
        const response = await getAddress(+rawZipCode)

        setValue('address', response.street)
        setValue('neighborhood', response.neighborhood)
        setValue('city', response.city)
        setValue(
          'state',
          BRAZILIAN_STATES.find(e => e.value === response.state)
        )

        setError('address', { message: '' })
        setError('neighborhood', { message: '' })
        setError('city', { message: '' })
        setError('state', { message: '' })
      }
    },
    [getAddress, setValue, setError]
  )

  return (
    <FarmCreateTemplate
      form={Form}
      onZipCodeChange={onZipCodeChange}
      onZipCodeBlur={onZipCodeBlur}
      onCnpjChange={onCnpjChange}
      farmStatus={farmStatus}
      onFarmStatusChange={onStatusChange}
      isEdit={isEdit}
    />
  )
}

export { FarmCreate }
