import {
  EvaluationCreateRequestData,
  EvaluationFilterProps,
  EvaluationProps,
  EvaluationReadResponseData,
} from 'app/core/types/evaluation'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/evaluation'

const getReadAllEvaluations = async (
  filters?: Partial<EvaluationFilterProps>,
  page?: number,
  size?: number
): Promise<EvaluationReadResponseData> => {
  const response = await http.get(`${baseUrl}/`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const postCreateEvaluation = async (
  request: EvaluationCreateRequestData
): Promise<EvaluationProps> => {
  const response = await http.post(`${baseUrl}/`, request)
  return response.data
}

export { getReadAllEvaluations, postCreateEvaluation }
