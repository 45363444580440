import { JwtPayload } from 'jwt-decode'

import { IRadioButtonOption } from 'components/atoms'

export enum UserType {
  admin = 'admin',
  regular = 'regular',
  executer = 'executor',
}

type UserTypeLabel = {
  [key in UserType]: string
}

export const UserTypeLabel: UserTypeLabel = {
  [UserType.admin]: 'Administrador',
  [UserType.regular]: 'Usuário',
  [UserType.executer]: 'Executor',
}

export const USER_TYPE_OPTIONS: IRadioButtonOption[] = Object.values(
  UserType
).map(value => ({
  label: UserTypeLabel[value as keyof typeof UserTypeLabel],
  value,
  disabled: value === UserType.executer,
}))

export type UserEntity = {
  id: number
  name: string
  email: string
  password: string
  type: UserType
  farm_name: string
  farm_id: number
  is_active: boolean
  registration_number?: number
  birth_date?: string
}

export interface IUserRequestData extends Omit<UserEntity, 'farm_id'> {
  farm_id: number
}

export interface IUpdateUserRequestData
  extends Omit<IUserRequestData, 'birth_date'> {
  birth_date?: Date
}

export interface IUserPassword {
  new_password: string
  confirm_password: string
}

export interface IUserJwtPayload extends JwtPayload {
  user_email?: string
  user_id?: number
  user_name?: string
  user_type?: string
}

export type AuthResponseData = {
  token: string
}

export type UserRequestData = {
  name: string
  registration_number: number
  user_type: UserType[] | UserType
  farm_id: number
}
