import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { Button, ButtonSize, ButtonType } from 'components/atoms'
import {
  LabeledCard,
  LabeledCardButtonRow,
  LabeledCardDateRow,
  LabeledCardRow,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import { BreedingStationProps } from 'app/core/types/breeding'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'
import { http } from 'interfaces/http'

const BreedingStationCreate: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = useCallback(
    (path): void => history.push(path),
    [history]
  )

  const [initDate, setInitDate] = useState<string>()
  const [finalDate, setFinalDate] = useState<string>()

  const validationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    init_date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    final_date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const checkPermission = useCallback((): void => {
    if (!isAdmin()) {
      addToast({ message: Messages.BREEDING_STATION_ONLY_ADMIN_CAN_CREATE })

      return navigateTo(NavigateList.breedingStationHistory)
    }
  }, [navigateTo])

  const handleCreateBreedingStation = async (data: unknown): Promise<void> => {
    const {
      name,
      init_date: initDate,
      final_date: finalDate,
    } = data as BreedingStationProps

    const initDateFormat = new Date(initDate)
    const finalDateFormat = new Date(finalDate)

    await http
      .post(`/breeding_station/`, {
        name,
        init_date: initDateFormat.toISOString(),
        final_date: finalDateFormat.toISOString(),
      })
      .then(response => {
        const station = response.data as BreedingStationProps
        navigateTo(`${NavigateList.breedingStationDetails}${station.id}`)
      })
      .catch(e => {
        addToast({ message: e.message })
      })
  }

  const { Form } = useForm({
    onSubmit: handleCreateBreedingStation,
    validationSchema,
  })

  useEffect(() => {
    checkPermission()
  }, [checkPermission])

  return (
    <BoxedLayout title="Nova Estação de Monta">
      <LabeledCard title="Informações gerais">
        <Form>
          <LabeledCardRow>
            <Form.InputText
              label="Nome"
              name="name"
              placeholder="Inserir nome"
              width={480}
            />

            <LabeledCardDateRow>
              <Form.InputText
                label="Data de início e fim"
                name="init_date"
                htmlType="date"
                max={finalDate}
                onBlur={(e): void => {
                  setInitDate(e.currentTarget.value)
                }}
              />
              <Form.InputText
                emptyLabelSpacing
                name="final_date"
                htmlType="date"
                min={initDate}
                onBlur={(e): void => {
                  setFinalDate(e.currentTarget.value)
                }}
              />
            </LabeledCardDateRow>
          </LabeledCardRow>

          <LabeledCardButtonRow>
            <Button
              type={ButtonType.primary}
              label="Salvar"
              size={ButtonSize.large}
            />
          </LabeledCardButtonRow>
        </Form>
      </LabeledCard>
    </BoxedLayout>
  )
}

export { BreedingStationCreate }
