import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

import { useFarm } from 'app/core/hooks'

import { farmsListColumns } from './tables'
import { FarmsTabTemplate } from './template'

const FarmsTab: React.FC = () => {
  const { activeFarms: farms, isLoading } = useFarm()

  const [globalFilter, setGlobalFilter] = useState('')

  const table = useReactTable({
    data: farms || [],
    columns: farmsListColumns(),
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <FarmsTabTemplate
      farms={farms}
      isLoading={isLoading}
      table={table}
      setGlobalFilter={setGlobalFilter}
    />
  )
}

export { FarmsTab }
