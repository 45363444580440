import { PrecipitationPeriodTypes } from 'app/core/types/dashboard'

export const CHART_COLORS = [
  '#61A1C6',
  '#A2D673',
  '#F6D378',
  '#5fc3d7',
  '#d8e2dc',
  '#F1F8C3',
  '#f48c06',
  '#ef233c',
  '#495867',
]

export const HISTORICAL_CHART_COLORS = [
  '#A2D673',
  '#F6D378',
  '#ef233c',
  '#f48c06',
  '#61A1C6',
]

export const PERIOD_OPTIONS = [
  {
    label: 'Últimos 12 meses',
    value: PrecipitationPeriodTypes.last_twelve_months,
  },
  {
    label: 'Últimos 5 anos',
    value: PrecipitationPeriodTypes.last_five_years,
  },
  {
    label: 'Ano atual',
    value: PrecipitationPeriodTypes.current_year,
  },
  {
    label: 'Desde o começo',
    value: PrecipitationPeriodTypes.since_beginning,
  },
]
