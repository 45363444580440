import { ColumnDef } from '@tanstack/react-table'

import {
  InseminationAnimalProps,
  InseminationTypes,
} from 'app/core/types/hormonal'

import {
  artificialColumns,
  baseColumns,
  deleteColumn,
  naturalColumns,
} from '../list'

const inseminationReviewColumns = (
  handleRemoveAnimal: (animalId: number) => void,
  inseminationType?: InseminationTypes
): ColumnDef<InseminationAnimalProps>[] => {
  return [
    ...baseColumns,
    ...(inseminationType === InseminationTypes.natural_cover
      ? naturalColumns
      : artificialColumns),
    ...deleteColumn(handleRemoveAnimal),
  ]
}

export { inseminationReviewColumns }
