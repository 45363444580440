import React from 'react'

import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  SelectItemProps,
} from 'components/atoms'
import {
  LabeledCard,
  LabeledCardButtonRow,
  LabeledCardDateRow,
  LabeledCardRow,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { Messages } from 'config/messages'

type BreedingGroupCreateTemplateProps = {
  stationsList?: SelectItemProps[]
  stationDate?: { initDate: string; finalDate: string }
  handleSelectStation: (stationId: number) => void
  onSubmit: IFormProps['onSubmit']
}

const BreedingGroupCreateTemplate: React.FC<BreedingGroupCreateTemplateProps> =
  ({ stationsList, stationDate, handleSelectStation, onSubmit }) => {
    const validationSchema = yup.object({
      name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
      init_date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
      final_date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    })

    const { Form, watch } = useForm({
      onSubmit,
      validationSchema,
    })

    const { init_date: groupInitDate, final_date: groupFinalDate } = watch()

    return (
      <BoxedLayout title="Criar Grupo de Repasse">
        <LabeledCard title="Informações gerais">
          <Form>
            <LabeledCardRow>
              <Form.Select
                label="Selecionar Estação de Monta"
                name="breeding_station"
                isLoading={!stationsList}
                placeholder="Selecione uma estação de monta"
                options={stationsList}
                onChange={(value): void =>
                  handleSelectStation(
                    parseInt((value as SelectItemProps).value)
                  )
                }
                width={370}
              />

              {stationDate && (
                <>
                  <Form.InputText
                    label="Nome"
                    name="name"
                    placeholder="Inserir nome do Grupo de Repasse"
                    width={370}
                  />
                  <LabeledCardDateRow>
                    <Form.InputText
                      label="Data de início e fim"
                      name="init_date"
                      htmlType="date"
                      min={stationDate.initDate}
                      max={groupFinalDate || stationDate.finalDate}
                    />
                    <Form.InputText
                      emptyLabelSpacing
                      name="final_date"
                      htmlType="date"
                      min={groupInitDate || stationDate.initDate}
                      max={stationDate.finalDate}
                    />
                  </LabeledCardDateRow>
                </>
              )}
            </LabeledCardRow>

            {stationDate && (
              <LabeledCardButtonRow>
                <Button
                  label="Salvar"
                  type={ButtonType.primary}
                  size={ButtonSize.large}
                />
              </LabeledCardButtonRow>
            )}
          </Form>
        </LabeledCard>
      </BoxedLayout>
    )
  }

export default BreedingGroupCreateTemplate
