import { Table } from '@tanstack/react-table'
import React from 'react'

import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { AnimalCreateProps } from 'app/core/types/animal'

type AnimalFeedbackTemplateProps = {
  date: string
  sector: string
  animals: AnimalCreateProps[]
  table: Table<AnimalCreateProps>
  handleFinish: () => void
}

const AnimalFeedbackTemplate: React.FC<AnimalFeedbackTemplateProps> = ({
  date,
  sector,
  animals,
  table,
  handleFinish,
}) => (
  <BoxedLayout
    title="Lista de animais cadastrados"
    date={date}
    headerHighlightedLabel="Setor"
    headerHighlightedData={sector}
    headerLargeButtonAction={handleFinish}
    headerLargeButtonLabel="Finalizar"
    isChildrenSticky
  >
    <BoxedTable
      data={animals}
      table={table}
      isTableHeaderSticky
      title="Animais"
      quantityLabel="Entrada"
    />
  </BoxedLayout>
)

export { AnimalFeedbackTemplate }
