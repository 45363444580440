import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useDiagnostics } from 'app/core/hooks/breeding/diagnostics'
import {
  IParamProps,
  NavigateList,
  NavigateParams,
} from 'app/core/routes/routes'

import { DiagnosticColumnsFeedback } from '../tables'
import { DiagnosticsFeedbackTemplate } from './template'

const DiagnosticsFeedback: React.FC = () => {
  const history = useHistory<NavigateParams>()
  const { diagnosticGroupId } = useParams<IParamProps>()

  const [page, setPage] = useState(1)

  const {
    diagnostics,
    exportFailedDiagnostics,
    failedDiagnostics,
    headerInfo,
    tableRows,
    clearDiagnosticsStorage,
  } = useDiagnostics({
    diagnosticGroupId,
    page,
  })

  const columns = useMemo(() => DiagnosticColumnsFeedback(), [])

  const table = useReactTable({
    data: tableRows,
    columns,
    getCoreRowModel: useMemo(() => getCoreRowModel(), []),
  })

  const handleFinishDiagnostic = (): void => {
    clearDiagnosticsStorage()
    history.push(`${NavigateList.diagnosticsHistory}`)
  }

  return (
    <DiagnosticsFeedbackTemplate
      table={table}
      headerInfo={headerInfo}
      diagnostics={diagnostics}
      exportFailedDiagnostics={exportFailedDiagnostics}
      failedDiagnostics={failedDiagnostics}
      handleFinishDiagnostic={handleFinishDiagnostic}
      page={page}
      setPage={setPage}
    />
  )
}

export { DiagnosticsFeedback }
