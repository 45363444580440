import { ColumnDef } from '@tanstack/react-table'

import {
  InseminationAnimalProps,
  InseminationTypes,
} from 'app/core/types/hormonal'

import { artificialColumns, baseColumns, naturalColumns } from '../list'

const inseminationFeedbackColumns = (
  inseminationType?: InseminationTypes
): ColumnDef<InseminationAnimalProps>[] => {
  return [
    ...baseColumns,
    ...(inseminationType === InseminationTypes.natural_cover
      ? naturalColumns
      : artificialColumns),
  ]
}

export { inseminationFeedbackColumns }
