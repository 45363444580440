import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useStockNumber } from 'app/core/hooks/stock-number'
import { NavigateList } from 'app/core/routes/routes'
import { StockNumberCreateProps } from 'app/core/types/animal'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { stockNumberListColumns } from '../tables'
import { StockNumberReviewTemplate } from './template'

const StockNumberReview: React.FC = () => {
  const history = useHistory<NavigateList>()

  const [page, setPage] = useState<number>(1)

  const { animals, isLoading, removeAnimal, updateAnimals } = useStockNumber({
    page,
  })

  const columns = useMemo<ColumnDef<StockNumberCreateProps>[]>(
    () => stockNumberListColumns(removeAnimal),
    [removeAnimal]
  )

  const table = useReactTable({
    data: (animals?.items as StockNumberCreateProps[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const handleGoToFeedback = async (): Promise<void> => {
    if (animals?.items.length === 0) {
      addToast({ message: Messages.ANIMAL_NOT_ADDED })
      return
    }

    await updateAnimals()

    history.push(NavigateList.stockNumberFeedback)
  }

  return (
    <StockNumberReviewTemplate
      animals={animals}
      handleGoToFeedback={handleGoToFeedback}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      table={table}
    />
  )
}

export { StockNumberReview }
