import React, { useState } from 'react'

import { useBreedingGroup, useBreedingStation } from 'app/core/hooks'
import { BreedingGroupCreateRequestData } from 'app/core/types/breeding'
import { dateTimeFormat } from 'app/core/utils'

import BreedingGroupCreateTemplate from './template'

const BreedingGroupCreate: React.FC = () => {
  const { stations, stationsDroplistOptions } = useBreedingStation({
    size: 500,
  })
  const { addGroup } = useBreedingGroup({})

  const [stationDate, setStationDate] =
    useState<{ initDate: string; finalDate: string }>()

  const handleSelectStation = (stationId: number): void => {
    const selectedStation = stations?.items?.find(e => e.id === stationId)
    const initDate = dateTimeFormat(selectedStation?.init_date as string, true)
    const finalDate = dateTimeFormat(
      selectedStation?.final_date as string,
      true
    )
    setStationDate({ initDate, finalDate })
  }

  const handleCreateBreedingGroup = async (data: unknown): Promise<void> => {
    addGroup(data as BreedingGroupCreateRequestData)
  }

  return (
    <BreedingGroupCreateTemplate
      stationsList={stationsDroplistOptions}
      stationDate={stationDate}
      handleSelectStation={handleSelectStation}
      onSubmit={handleCreateBreedingGroup}
    />
  )
}

export { BreedingGroupCreate }
