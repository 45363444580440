import { ColumnDef, Getter } from '@tanstack/react-table'
import { ReactNode, ReactPortal } from 'react'

import { format, parseISO } from 'date-fns'

import { Icon, IconNames } from 'components/atoms'
import { formatNumber } from 'components/organisms/custom-table/helpers'

import { MeasuringStationProps } from 'app/core/types/measuring-station'
import { PrecipitationProps } from 'app/core/types/precipitation'

import styles from './styles.module.scss'

const formatDate = (getValue: Getter<ReactNode | ReactPortal>): JSX.Element => {
  const parsedDate = parseISO(getValue() as string)
  return <span>{format(parsedDate, 'dd/MM/yyyy - H:mm')}</span>
}

const precipitationColumns = (
  stations: MeasuringStationProps[],
  handleEditClick: (item: PrecipitationProps) => void,
  handleRemoveClick: (item: PrecipitationProps) => void
): ColumnDef<PrecipitationProps, unknown>[] => [
  {
    header: 'Data e hora',
    accessorKey: 'date',
    cell: ({ getValue }): JSX.Element => formatDate(getValue),
  },
  {
    header: 'Estação',
    accessorKey: 'station_id',
    cell: ({ getValue }): JSX.Element => {
      const stationId = getValue() as number
      return (
        <span>{stations.find(station => station.id === stationId)?.name}</span>
      )
    },
  },
  {
    header: 'T. Min (°C)',
    accessorKey: 'temp_min',
    cell: ({ getValue }): JSX.Element => formatNumber(getValue),
  },
  {
    header: 'T. Méd (°C)',
    accessorKey: 'temp_med',
    cell: ({ getValue }): JSX.Element => formatNumber(getValue),
  },
  {
    header: 'T. Máx (°C)',
    accessorKey: 'temp_max',
    cell: ({ getValue }): JSX.Element => formatNumber(getValue),
  },
  {
    header: 'Precip. (MM)',
    accessorKey: 'rain',
    cell: ({ getValue }): JSX.Element => formatNumber(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => {
      return info.row.original.editable ? (
        <div className={styles.tableActions}>
          <Icon
            name={IconNames.pencil}
            onClick={(): void => handleEditClick(info.row.original)}
          />
          <Icon
            name={IconNames.close}
            onClick={(): void => handleRemoveClick(info.row.original)}
          />
        </div>
      ) : (
        <></>
      )
    },
  },
]

export { precipitationColumns }
