import {
  MeasuringStationCreateRequestData,
  MeasuringStationProps,
} from 'app/core/types/measuring-station'
import { http } from 'interfaces/http'

const baseUrl = '/measuring_station/'

const getReadAllMeasuringStations = async (): Promise<
  MeasuringStationProps[]
> => {
  const response = await http.get(baseUrl)
  return response.data
}

const postCreateMeasuringStation = async (
  request: MeasuringStationCreateRequestData
): Promise<MeasuringStationProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const patchEditMeasuringStation = async (
  measuringStationId: number,
  request: MeasuringStationCreateRequestData
): Promise<MeasuringStationProps> => {
  const response = await http.patch(`${baseUrl}${measuringStationId}/`, request)
  return response.data
}

const deleteMeasuringStation = async (
  measuringStationId: number
): Promise<void> => {
  const response = await http.delete(`${baseUrl}${measuringStationId}/`)
  return response.data
}

export {
  getReadAllMeasuringStations,
  postCreateMeasuringStation,
  patchEditMeasuringStation,
  deleteMeasuringStation,
}
