import React, { ReactNode } from 'react'

import classNames from 'classnames'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Payload as LegendPayload } from 'recharts/types/component/DefaultLegendContent'
import {
  NameType,
  Payload as TooltipPayload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

import { Typography, TypographyVariant } from 'components/atoms'
import { Divider } from 'components/atoms/divider'

import { CHART_COLORS } from 'app/core/pages/dashboard/constants'
import { DashboardAnimalUnit } from 'app/core/types/dashboard'
import { FarmProps } from 'app/core/types/system'
import { dateShortMonthYearFormat } from 'app/core/utils'
import { numberWithThousandDotSeparatorFormat } from 'app/core/utils/number'

import styles from './styles.module.scss'

type DashboardAnimalUnitTotalCardProps = {
  animalUnit?: DashboardAnimalUnit[]
  farms?: FarmProps[]
}
type ChartData = { date: string } & Record<string, number>
type AnimalUnitChartData = {
  currentTotal: string
  farmsTotalUA: number
  data: ChartData[]
  mostRecentData: ChartData
  farms?: FarmProps[]
}

const parseAnimalUnitToChartData = (
  animalUnits: DashboardAnimalUnit[],
  farms?: FarmProps[]
): AnimalUnitChartData => {
  const dataIndexedByDate = animalUnits.reduce((indexedData, animalUnit) => {
    const farmName = animalUnit.farm_name
    const farmAnimalUnitSum = Object.values(
      animalUnit.age_grouped_animal_units
    ).reduce(
      (sum, ageGroupedAnimalUnit) => ageGroupedAnimalUnit.animal_unit + sum,
      0
    )
    const dateIndexedData = indexedData[animalUnit.date] ?? {}
    const dateFarmIndexedData = dateIndexedData[animalUnit.farm_name] ?? 0
    const newDateFarmIndexedData = Math.round(
      dateFarmIndexedData + farmAnimalUnitSum
    )

    return {
      ...indexedData,
      [animalUnit.date]: {
        ...dateIndexedData,
        date: animalUnit.date,
        [farmName]: newDateFarmIndexedData,
      } as ChartData,
    }
  }, {} as Record<string, ChartData>)
  const data = Object.values(dataIndexedByDate)
  const mostRecentData = data.slice(-1)[0] ?? {}
  const farmsTotalArea =
    farms?.reduce((sum, farm) => sum + farm.total_area, 0) || 0
  const farmsTotalUA =
    farms?.reduce(
      (sum, farm) => ((mostRecentData[farm.name] as number) ?? 0) + sum,
      0
    ) ?? 0
  const currentTotal = numberWithThousandDotSeparatorFormat(
    farmsTotalUA / farmsTotalArea
  )
  return { currentTotal, data, farmsTotalUA, mostRecentData }
}

type RenderTooltipProps = {
  active?: boolean
  label: string
  payload?: TooltipPayload<ValueType, NameType>[]
}
const renderTooltip = ({
  active,
  label,
  payload,
}: RenderTooltipProps): ReactNode => {
  if (!active) {
    return null
  }
  return (
    <div className={styles.tooltip}>
      <span className={styles.tooltipLabel}>
        {dateShortMonthYearFormat(label)}
      </span>
      {payload?.map((item, index) => (
        <div className={styles.tooltipRow} key={`tooltip-item-${index}`}>
          <div
            className={styles.tooltipBox}
            style={{ background: item.stroke }}
          />
          <span className={styles.tooltipText}>
            {item.dataKey}:{' '}
            <span className={styles.tooltipTextHighlight}>
              {new Intl.NumberFormat('pt-BR').format(Number(item.value))}
            </span>
          </span>
        </div>
      ))}
    </div>
  )
}

const getFarmsProductiveAreaData = (
  mostRecentData: ChartData,
  farms?: FarmProps[]
): { total: number; farmsProductiveAreaIndex: Record<string, number> } => {
  let total = 0
  const farmsProductiveAreaIndex = farms?.reduce((acc, farm) => {
    if (!farm.productive_area) {
      return acc
    }
    const farmProductiveAreaCalc =
      (mostRecentData[farm.name] ?? 0) / farm.productive_area
    total += farmProductiveAreaCalc
    return {
      ...acc,
      [farm.name]: farmProductiveAreaCalc,
    }
  }, {}) as Record<string, number>
  return { total, farmsProductiveAreaIndex }
}

const DashboardAnimalUnitTotalCard: React.FC<DashboardAnimalUnitTotalCardProps> =
  ({ animalUnit, farms }) => {
    const { currentTotal, data, farmsTotalUA, mostRecentData } =
      parseAnimalUnitToChartData(animalUnit ?? [], farms)
    const renderLegend = (payload?: LegendPayload[]): ReactNode => {
      const { total, farmsProductiveAreaIndex } = getFarmsProductiveAreaData(
        mostRecentData,
        farms
      )
      return (
        <div className={styles.legend}>
          <div className={styles.legendFarms}>
            {payload?.map((entry, index) => {
              return (
                <div key={`legend-${index}`} className={styles.legendItem}>
                  <div className={styles.legendLabel}>
                    <div
                      className={styles.legendIcon}
                      style={{ background: entry.color }}
                    />
                    <span className={styles.legendLabelText}>
                      {entry.value}
                    </span>
                  </div>
                  {total ? (
                    <div className={styles.legendLabelValue}>
                      {new Intl.NumberFormat('pt-BR').format(
                        Number(farmsProductiveAreaIndex[entry.value] ?? 0)
                      )}
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
          {total ? (
            <>
              <Divider className={styles.divider} darker vertical />
              <div className={styles.legendItem}>
                <div className={styles.legendLabel}>
                  <div
                    className={classNames(
                      styles.legendIcon,
                      styles.legendIconTotal
                    )}
                  />
                  <span className={styles.legendLabelText}>Total</span>
                </div>
                <div className={styles.legendLabelValue}>{currentTotal}</div>
              </div>
            </>
          ) : null}
        </div>
      )
    }

    return (
      <>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <Typography
              text="Total Atual"
              variant={TypographyVariant.h3}
              className={styles.cardTitle}
            />
            <Typography
              text={numberWithThousandDotSeparatorFormat(farmsTotalUA)}
              variant={TypographyVariant.h5}
              className={styles.totalValue}
            />
          </div>

          <ResponsiveContainer
            width="100%"
            minHeight={450}
            minWidth={1000}
            className={styles.container}
          >
            <LineChart
              data={Object.values(data ?? {})}
              margin={{ left: 50, right: 50 }}
            >
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={({ active, label, payload }): ReactNode =>
                  renderTooltip({ active, label, payload })
                }
              />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                tickLine={false}
                axisLine={false}
                dataKey="date"
                dy={15}
                className={styles.xAxis}
                tickFormatter={dateShortMonthYearFormat}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                label={{
                  value: 'TOTAL UA',
                  angle: -90,
                  position: 'insideCenter',
                  dx: -50,
                  className: styles.yAxisLabel,
                }}
                tickFormatter={numberWithThousandDotSeparatorFormat}
                className={styles.yAxis}
              />
              {farms?.map((farm, i) => (
                <Line
                  key={farm.name}
                  type="monotone"
                  dataKey={farm.name}
                  stroke={CHART_COLORS[i]}
                  strokeWidth={4}
                  dot={{ r: 5, strokeWidth: 3 }}
                />
              ))}

              <Legend
                content={({ payload }): ReactNode => renderLegend(payload)}
                wrapperStyle={{
                  display: 'flex',
                }}
                payload={farms?.map((farm, index) => ({
                  value: farm.name,
                  id: `farm${index}`,
                  color: CHART_COLORS[index],
                }))}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </>
    )
  }

export { DashboardAnimalUnitTotalCard }
