import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'

import * as yup from 'yup'

import { ButtonType, IconNames } from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { CustomModal, ExpandCard } from 'components/molecules'
import { BoxedTable, useForm } from 'components/organisms'

import { ProductHook } from 'app/core/types/hooks'
import { ProductListProps } from 'app/core/types/products'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './../../styles.module.scss'
import { getProductColumns } from './table'

const ProductCard: React.FC<ProductHook> = ({
  deleteProduct,
  readAllProducts,
  updateProduct,
  createProduct,
  products,
}) => {
  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState<ProductListProps | undefined>()
  const [patchItem, setPatchItem] = useState<ProductListProps | undefined>()
  const validationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    manufacturer: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    code: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleDeleteModalConfirm = async (): Promise<void> => {
    try {
      const id = deleteItem?.id
      if (!id) {
        throw new Error('Missing delete item id')
      }
      handleDeleteModalClose()
      await deleteProduct(id)
      await readAllProducts()

      addToast({ message: 'Item deletado com sucesso', type: 'success' })
    } catch (err) {
      handleHttpError(err, false)
    }
  }

  const handleDeleteModalClose = (): void => {
    setDeleteItem(undefined)
  }

  const handleUpsertModalClose = (): void => {
    setPatchItem(undefined)
    setIsUpsertModalOpen(false)
  }

  const handleAddClick = (): void => {
    setIsUpsertModalOpen(true)
    reset()
  }

  const handleFormSubmit = useCallback(
    async (data: unknown): Promise<void> => {
      try {
        const id = patchItem?.id
        handleUpsertModalClose()
        if (!id) {
          await createProduct(data as ProductListProps)
          addToast({ message: 'Adicionado com sucesso', type: 'success' })
          await readAllProducts()
          return
        }
        await updateProduct(id, data as ProductListProps)
        addToast({
          message: 'Alteração realizada com sucesso',
          type: 'success',
        })
        await readAllProducts()
      } catch (err) {
        handleHttpError(err, false)
      }
    },
    [createProduct, patchItem?.id, readAllProducts, updateProduct]
  )

  const { Form, handleSubmit, setValue, reset } = useForm({
    onSubmit: handleFormSubmit,
    validationSchema,
  })

  const handleEditClick = useCallback(
    (item: ProductListProps): void => {
      reset()
      setValue('name', item.name)
      setValue('code', item.code)
      setValue('manufacturer', item.manufacturer)
      setIsUpsertModalOpen(true)
      setPatchItem(item)
    },
    [setValue, reset]
  )

  const handleRemoveClick = useCallback((item: ProductListProps): void => {
    setDeleteItem(item)
  }, [])

  const columns = useMemo<ColumnDef<ProductListProps>[]>(
    () => getProductColumns(handleEditClick, handleRemoveClick),
    [handleEditClick, handleRemoveClick]
  )

  const table = useReactTable({
    data: products ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <ExpandCard className={styles.settingsExpandCard} title="Produtos">
        <div className={styles.cardContent}>
          <Divider darker />

          <BoxedTable
            className={styles.settingsBoxedTable}
            data={products ?? []}
            title=""
            quantityLabel="Produtos"
            table={table}
            mainButtonLabel="Incluir"
            mainButtonIcon={IconNames['add-circle']}
            mainButtonAction={handleAddClick}
            disableNavigation
          />
        </div>

        <CustomModal
          modalIsOpen={!!isUpsertModalOpen}
          handleCloseModal={handleUpsertModalClose}
          modalHeader={patchItem ? `Editar produto` : 'Cadastrar produto'}
          primaryButtonLabel="SALVAR"
          primaryButtonAction={handleSubmit(handleFormSubmit)}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleUpsertModalClose}
        >
          <div className={styles.form}>
            <Form>
              <div className={styles.formFieldset}>
                <Form.InputText
                  name="code"
                  placeholder="Inserir código"
                  label="Código"
                  className={styles.productCodeField}
                />
                <div className={styles.formFieldsetField}>
                  <Form.InputText
                    name="name"
                    placeholder="Inserir nome"
                    label="Nome do produto"
                    className={styles.productNameField}
                  />
                </div>
                <div className={styles.formFieldsetField}>
                  <Form.InputText
                    name="manufacturer"
                    placeholder="Inserir fabricante"
                    label="Fabricante"
                    className={styles.productProviderField}
                  />
                </div>
              </div>
            </Form>
          </div>
        </CustomModal>

        <CustomModal
          modalIsOpen={!!deleteItem}
          handleCloseModal={handleDeleteModalClose}
          modalHeader={`Excluir Produto`}
          modalText={`Você tem certeza que deseja excluir o Grupo de Idade ${deleteItem?.name}?`}
          primaryButtonLabel="EXCLUIR"
          primaryButtonAction={handleDeleteModalConfirm}
          primaryButtonType={ButtonType.destructive}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleDeleteModalClose}
        />
      </ExpandCard>
    </>
  )
}

export { ProductCard }
