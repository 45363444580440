import { useCallback, useEffect, useState } from 'react'

import {
  getFarmParameters as getFarmParameterService,
  patchFarmParameters,
} from 'app/core/services/system'
import { FarmParametersHook } from 'app/core/types/hooks'
import { FarmParametersProps } from 'app/core/types/system'
import { addToast } from 'app/core/utils'

const useFarmParameters = (): FarmParametersHook => {
  const [farmParameters, setFarmParameters] = useState<
    FarmParametersProps | undefined
  >()

  const readFarmParameters = useCallback(async (): Promise<void> => {
    try {
      const data = await getFarmParameterService()
      setFarmParameters(data)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }, [])

  const updateFarmParameters = useCallback(
    async (payload: Partial<FarmParametersProps>): Promise<void> => {
      try {
        await patchFarmParameters(payload)
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    []
  )

  useEffect(() => {
    readFarmParameters()
  }, [readFarmParameters])

  return {
    farmParameters,
    readFarmParameters,
    updateFarmParameters,
  }
}

export { useFarmParameters }
