import { ColumnDef } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'

import {
  ShutdownReasonResponseProps,
  ShutdownType,
} from 'app/core/types/shutdown'

import styles from '../../styles.module.scss'

const shutdownReasonColumns = (
  type: ShutdownType,
  handleEditClick: (
    type: ShutdownType,
    item: ShutdownReasonResponseProps
  ) => void,
  handleRemoveClick: (
    type: ShutdownType,
    item: ShutdownReasonResponseProps
  ) => void
): ColumnDef<ShutdownReasonResponseProps, unknown>[] => [
  {
    accessorKey: 'description',
  },
  {
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <div className={styles.tableActions}>
        <Icon
          name={IconNames.pencil}
          onClick={(): void => handleEditClick(type, info.row.original)}
        />
        <Icon
          name={IconNames.close}
          onClick={(): void => handleRemoveClick(type, info.row.original)}
        />
      </div>
    ),
  },
]

export { shutdownReasonColumns }
