import { useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'
import { format, subYears } from 'date-fns'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  Icon,
  IconNames,
  Select,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { useDashboard } from 'app/core/hooks'
import { HISTORICAL_CHART_COLORS } from 'app/core/pages/dashboard/constants'
import { getHistoricalPrecipitations } from 'app/core/services'
import {
  HistorialPrecipitationsPeriodProps,
  HistoricalPrecipitationsType,
} from 'app/core/types/dashboard'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { formatPrecipitation } from '../../helpers'
import { LoadingChart, LoadingDropdown } from '../../loading'
import styles from '../../styles.module.scss'

const renderTooltip = ({
  payload,
}: {
  payload?: {
    color?: string
    name?: number
    fill?: string
    payload?: {
      monthFullName?: string
      historicalMean?: number
      mean?: number
      [key: number]: number
    }
  }[]
}): React.ReactNode => {
  if (!payload) return null

  return (
    <div className={styles.tooltip}>
      {payload.map((item, index) => {
        const isHistorical = index === 0

        return (
          <>
            <div key={index} className={styles.tooltipRow}>
              <div
                className={styles.tooltipBox}
                style={{ background: item?.color }}
              />
              <div className={styles.tooltipTextContainer}>
                <span className={styles.tooltipText}>
                  {isHistorical ? 'Média mensal:' : item.payload?.monthFullName}
                </span>
                <span
                  className={classNames(
                    styles.tooltipText,
                    styles.tooltipTextHighlight
                  )}
                >
                  {item.payload &&
                    formatPrecipitation(
                      Number(
                        isHistorical
                          ? item.payload['historicalMean']
                          : item.payload['mean'] || 0
                      )
                    )}
                </span>
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}

type YearPrecipitationMeanCardProps = {
  stations: SelectItemProps[]
}

const YearPrecipitationMeanCard: React.FC<YearPrecipitationMeanCardProps> = ({
  stations,
}) => {
  const noStations = stations.length === 0
  const defaultStationId = Number(stations[0].value)
  const lastYear = format(subYears(new Date(), 1), 'yyyy')

  const { formatYearData } = useDashboard()

  const [data, setData] = useState<HistorialPrecipitationsPeriodProps[]>()
  const [stationId, setStationId] = useState<number>(defaultStationId)
  const [isLoading, setIsLoading] = useState(false)

  const getData = useCallback(async () => {
    try {
      setIsLoading(true)

      const payload = {
        type: HistoricalPrecipitationsType.current_year,
        station_id: stationId,
      }

      const precipitations = await getHistoricalPrecipitations(payload)

      const formattedPrecipitations = formatYearData(precipitations)

      setData(formattedPrecipitations)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }, [formatYearData, stationId])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <>
      <div className={styles.card}>
        <div className={styles.chartHeader}>
          <div>
            <Typography
              text="Índices Pluviométricos"
              variant={TypographyVariant.h5}
              className={styles.cardTitle}
            />
            <Typography text="Ano atual" variant={TypographyVariant.p} />
          </div>
          <div className={styles.fields}>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>Estação</span>
              {noStations || isLoading ? (
                <LoadingDropdown />
              ) : (
                <div className={styles.fieldWrapper}>
                  <Icon
                    className={styles.fieldIcon}
                    name={IconNames.information}
                  />
                  <Select
                    name="station"
                    centerText={false}
                    options={stations}
                    onChange={(value): void =>
                      setStationId(Number((value as SelectItemProps).value))
                    }
                    height="40px"
                    valueMargin="0 0 0 30px"
                    defaultValue={stations.find(
                      station => Number(station.value) === stationId
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {isLoading ? (
          <LoadingChart />
        ) : (
          <ResponsiveContainer width="100%" height="100%" minHeight={400}>
            <ComposedChart
              className="recharts-precipitation"
              width={500}
              height={300}
              data={data}
              margin={{ left: 30, top: 30, bottom: 15 }}
            >
              {data?.length === 0 && (
                <text
                  x="50%"
                  y="50%"
                  className={styles.emptyDataMessage}
                  textAnchor="middle"
                >
                  {Messages.DASHBOARD_PRECIPITATION_EMPTY_DATA}
                </text>
              )}
              <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.75} />
              <XAxis dataKey="month" tickMargin={20} fontSize={14} />
              <YAxis>
                <Label
                  fill="#bbb"
                  value="Precipitações (mm)"
                  position="left"
                  angle={-90}
                  fontSize={14}
                  style={{ textAnchor: 'middle' }}
                />
              </YAxis>
              <Tooltip content={renderTooltip} />
              <Bar
                dataKey="historicalMean"
                barSize={100}
                fill={HISTORICAL_CHART_COLORS[4]}
                label={{
                  position: 'top',
                  fill: HISTORICAL_CHART_COLORS[4],
                  fontWeight: 700,
                  offset: 10,
                  formatter: (value: number): string => `${value.toFixed(2)}`,
                }}
              />
              <Line
                type="linear"
                dataKey="mean"
                stroke={HISTORICAL_CHART_COLORS[0]}
                strokeWidth={3}
                activeDot={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}

        <div className={styles.legend}>
          <div className={styles.legendItem}>
            <div className={styles.legendLabel}>
              <div
                className={classNames(styles.legendIcon, styles.legendIconBlue)}
              ></div>
              <span className={styles.legendLabelText}>
                Média de chuvas{' '}
                <span className={styles.legendLabelTextInfo}>
                  (1982 - {lastYear})
                </span>
              </span>
            </div>
            <div className={styles.legendLabel}>
              <div
                className={classNames(styles.legendIcon)}
                style={{ background: HISTORICAL_CHART_COLORS[0] }}
              />
              <span className={styles.legendLabelText}>Ano atual</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { YearPrecipitationMeanCard }
