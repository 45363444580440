import { useCallback, useEffect, useState } from 'react'

import { getReadAllAnimals } from 'app/core/services'
import {
  getExportCollectiveMovement,
  getImportCollectiveMovementTemplate,
  postCreateCollectiveMovement,
  postImportCollectiveMovement,
} from 'app/core/services/collective-movement'
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  CollectiveMomentsAnimalsResponseData,
  CollectiveMovementCreateRequestData,
  CollectiveMovementProps,
  CollectiveMovementsCreateProps,
  ExportCollectiveMovementCreateRequestData,
  ImportCollectiveMovementProps,
} from 'app/core/types/collective-movements'
import {
  CollectiveMovementsHook,
  CollectiveMovementsHookProps,
} from 'app/core/types/hooks'
import { StorageKeys } from 'app/core/types/storage'
import {
  addToast,
  dateForFileName,
  downloadFile,
  generateXlsxTemplate,
  getPlural,
  handleHttpError,
  paginateItems,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useCollectiveMovements = ({
  page,
}: CollectiveMovementsHookProps): CollectiveMovementsHook => {
  const pluralizeRule = /(.*)(ão)$/i
  const pluralizeReplacement = '$1ões'

  const savedAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.collective_movements_review) as string
  ) as CollectiveMovementsCreateProps[]
  const savedFailedAnimals = JSON.parse(
    localStorage.getItem(
      StorageKeys.collective_movements_review_failed
    ) as string
  )
  const savedCollectiveMovement = JSON.parse(
    localStorage.getItem(StorageKeys.collective_movements_review_info) as string
  ) as CollectiveMovementProps

  const [animals, setAnimals] = useState<CollectiveMovementsCreateProps[]>(
    () => savedAnimals || []
  )
  const [failedMovements, setFailedMovements] = useState<
    Partial<CollectiveMovementsCreateProps>[]
  >(() => savedFailedAnimals || [])
  const [paginatedAnimals, setPaginatedAnimals] =
    useState<CollectiveMomentsAnimalsResponseData>()
  const [collectiveMovement, setCollectiveMovement] =
    useState<CollectiveMovementProps>(() => savedCollectiveMovement || {})
  const [isLoading, setIsLoading] = useState(false)

  const searchAnimals = useCallback(
    async (filters: Partial<AnimalFilterProps>): Promise<void> => {
      if (filters) {
        setIsLoading(true)

        try {
          const animals = (await getReadAllAnimals(
            filters
          )) as CollectiveMomentsAnimalsResponseData

          if (!animals.items.length) {
            addToast({
              message: Messages.ANIMALS_NOT_FOUND,
            })

            setIsLoading(false)

            return
          }

          setAnimals(prevState => {
            const filteredAnimals = animals.items.filter(newAnimal => {
              const alreadyAdded = prevState.some(
                existingAnimal => existingAnimal.id === newAnimal.id
              )

              if (alreadyAdded) {
                addToast({ message: `Animal já adicionado: ${newAnimal.id}` })
              }

              return !alreadyAdded
            })

            return [...prevState, ...filteredAnimals]
          })
          setIsLoading(false)
        } catch (e) {
          setIsLoading(false)
          const message = (e as Error).message
          addToast({ message })
          throw new Error(message)
        }
      }
    },
    []
  )

  const removeAnimal = (animalId: number): void => {
    if (animals) {
      const updatedAnimals = animals.filter(animal => animal.id !== animalId)
      setAnimals(updatedAnimals)
      handlePagination()
    }
  }

  const createCollectiveMovement = async (): Promise<void> => {
    setIsLoading(true)

    try {
      const animalIds = animals?.map(animal => animal.id)

      const request = {
        target_sector_id: Number(collectiveMovement.destinationSector?.value),
        animal_ids: animalIds,
      } as CollectiveMovementCreateRequestData

      const response = await postCreateCollectiveMovement(request)

      const failedMovements = [] as Partial<CollectiveMovementsCreateProps>[]
      let successfulMovements = animals

      if (response.length > 0) {
        response.forEach(error => {
          const animalId = Number(Object.keys(error)[0])
          const errorMessage = error[animalId]
          const failedMovement = animals.find(animal => animal.id === animalId)

          if (failedMovement) {
            failedMovements.push({
              stock_number: failedMovement.stock_number,
              birth_number: failedMovement.birth_number,
              electronic_eartag: failedMovement.electronic_eartag,
              error_message: errorMessage,
            })
          }

          successfulMovements = [
            ...successfulMovements.filter(animal => animal.id !== animalId),
          ]
        })

        setFailedMovements(failedMovements)
        setAnimals(successfulMovements)

        localStorage.setItem(
          StorageKeys.collective_movements_review_failed,
          JSON.stringify(failedMovements)
        )
        localStorage.setItem(
          StorageKeys.collective_movements_review,
          JSON.stringify(successfulMovements)
        )

        addToast({
          message: `Não foi possível criar ${getPlural(
            'movimentação',
            failedMovements.length,
            true,
            pluralizeRule,
            pluralizeReplacement
          )}. Verifique a planilha de inconsistências e tente novamente.`,
        })
      }

      if (successfulMovements.length) {
        addToast({
          message: `${getPlural(
            'Movimentação',
            successfulMovements.length,
            true,
            pluralizeRule,
            pluralizeReplacement
          )} ${getPlural('criada', successfulMovements.length)} com sucesso.`,
          type: 'success',
        })
      }
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePagination = useCallback(() => {
    if (animals) {
      const paginatedAnimals = paginateItems(animals, page)
      setPaginatedAnimals(paginatedAnimals)
    }
  }, [animals, page])

  const createStorageCollectiveMovement = (): void => {
    localStorage.setItem(
      StorageKeys.collective_movements_review,
      JSON.stringify(animals)
    )
    localStorage.setItem(
      StorageKeys.collective_movements_review_info,
      JSON.stringify(collectiveMovement)
    )
  }

  const removeStorageCollectiveMovement = (): void => {
    localStorage.removeItem(StorageKeys.collective_movements_review)
    localStorage.removeItem(StorageKeys.collective_movements_review_failed)
    localStorage.removeItem(StorageKeys.collective_movements_review_info)
  }

  const importCollectiveMovement = async (
    props: ImportCollectiveMovementProps
  ): Promise<void> => {
    try {
      setIsLoading(true)

      const animals = await postImportCollectiveMovement(props)

      if (!animals.length) {
        addToast({
          message: Messages.ANIMALS_NOT_FOUND,
        })

        setIsLoading(false)

        return
      }

      setAnimals(prevState => {
        const filteredAnimals = animals.filter(newAnimal => {
          const alreadyAdded = prevState.some(
            existingAnimal => existingAnimal.id === newAnimal.id
          )

          if (alreadyAdded) {
            addToast({ message: `Animal já adicionado: ${newAnimal.id}` })
          }

          return !alreadyAdded
        })

        return [...prevState, ...filteredAnimals]
      })

      setIsLoading(false)

      addToast({
        message: Messages.ANIMALS_IMPORT_SUCCESSFUL,
        type: 'success',
      })
    } catch (e) {
      setIsLoading(false)
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const importCollectiveMovementTemplate = async (): Promise<void> => {
    try {
      const { data } = await getImportCollectiveMovementTemplate()

      downloadFile({
        data: data,
        fileName: 'template-movimentacoes-coletivas',
      })
    } catch (e) {
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const exportCollectiveMovement = async (): Promise<void> => {
    try {
      const request = {
        animal_ids: animals?.map(animal => animal.id),
        origin_farm_id: Number(collectiveMovement?.originFarm?.value),
        origin_sector_id: Number(collectiveMovement?.originSector?.value),
        target_farm_id: Number(collectiveMovement?.destinationFarm?.value),
        target_sector_id: Number(collectiveMovement?.destinationSector?.value),
      } as ExportCollectiveMovementCreateRequestData

      const { data } = await getExportCollectiveMovement(request)

      downloadFile({
        data: data,
        fileName: `animais-movimentados-${dateForFileName()}`,
      })
    } catch (e) {
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const exportFailedMovements = (): void => {
    const templateFile = generateXlsxTemplate([
      [
        'Nº de Plantel',
        'Nº de Nascimento',
        'Nº de Brinco eletrônico',
        'Inconsistência',
      ],
      ...failedMovements.map(obj => Object.values(obj)),
    ])

    downloadFile({
      data: templateFile,
      fileName: `movimentacoes-${dateForFileName()}`,
    })
  }

  useEffect(() => {
    handlePagination()
  }, [handlePagination])

  return {
    animals: paginatedAnimals,
    setAnimals,
    collectiveMovement,
    setCollectiveMovement,
    searchAnimals,
    removeAnimal,
    createCollectiveMovement,
    createStorageCollectiveMovement,
    removeStorageCollectiveMovement,
    importCollectiveMovement,
    importCollectiveMovementTemplate,
    exportCollectiveMovement,
    isLoading,
    failedMovements,
    exportFailedMovements,
  }
}

export { useCollectiveMovements }
