import { Table } from '@tanstack/react-table'
import React from 'react'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  ShutdownCreateProps,
  ShutdownFailedProps,
} from 'app/core/types/shutdown'

type ShutdownFeedbackProps = {
  savedShutdown: ShutdownCreateProps[]
  shutdownDate: string
  table: Table<ShutdownCreateProps>
  exportFailedAnimals: () => void
  failedAnimals: ShutdownFailedProps[]
  handleFinish: () => void
}

const ShutdownFeedbackTemplate: React.FC<ShutdownFeedbackProps> = ({
  savedShutdown,
  shutdownDate,
  table,
  failedAnimals,
  exportFailedAnimals,
  handleFinish,
}) => {
  return (
    <>
      {savedShutdown && (
        <BoxedLayout
          title="Baixas de animais"
          date={shutdownDate}
          isChildrenSticky
          headerLargeButtonAction={handleFinish}
          headerLargeButtonLabel="Finalizar"
          {...(failedAnimals.length > 0 && {
            headerButtonAction: exportFailedAnimals,
            headerButtonLabel: 'Planilha de inconsistências',
            headerButtonIcon: <Icon name={IconNames['alert']} />,
          })}
        >
          <BoxedTable
            data={savedShutdown}
            table={table}
            title="Animais atualizados com sucesso"
            noDataMessage="Nenhum animal atualizado com sucesso."
            quantityLabel="Entradas"
            isTableHeaderSticky
          />
        </BoxedLayout>
      )}
    </>
  )
}

export { ShutdownFeedbackTemplate }
