/* eslint-disable @typescript-eslint/naming-convention */
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  BreedingServiceDetailsProps,
  BreedingStationAnimalResponseData,
  BreedingStationCreateRequestData,
  BreedingStationFilterProps,
  BreedingStationReportResponse,
  BreedingStationReadResponseData,
  BreedingStationUpdateRequestData,
} from 'app/core/types/breeding'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'
import Papa from 'papaparse'

const baseUrl = '/breeding_station/'

const getReadAllBreedingStations = async (
  page: number,
  size: number,
  filters?: BreedingStationFilterProps
): Promise<BreedingStationReadResponseData> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadBreedingStation = async (
  stationId: string | number
): Promise<BreedingServiceDetailsProps> => {
  const url = `${baseUrl}${stationId}/`

  const response = await http.get(url)
  return response.data
}

const postCreateBreedingStation = async (
  request: BreedingStationCreateRequestData
): Promise<BreedingServiceDetailsProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const patchUpdateBreedingStation = async (
  stationId: string | number,
  request: Partial<BreedingStationUpdateRequestData>
): Promise<BreedingServiceDetailsProps> => {
  const url = `${baseUrl}${stationId}/`

  const response = await http.patch(url, request)
  return response.data
}

const postAddBreedingStationAnimals = async (
  stationId: string | number,
  animalIds: number[]
): Promise<BreedingServiceDetailsProps> => {
  const url = `${baseUrl}${stationId}/add_animals/`

  const response = await http.post(url, animalIds)
  return response.data
}

const postRemoveBreedingStationAnimal = async (
  stationId: string | number,
  animalId: number
): Promise<BreedingServiceDetailsProps> => {
  const url = `${baseUrl}${stationId}/remove_animal/${animalId}/`

  const response = await http.post(url)
  return response.data
}

const getReadBreedingStationFemales = async (
  stationId: string | number,
  page: number,
  size?: number,
  filters?: Partial<AnimalFilterProps>
): Promise<BreedingStationAnimalResponseData> => {
  const url = `${baseUrl}${stationId}/females/`

  const response = await http.get(url, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadBreedingStationMales = async (
  stationId: string | number,
  page: number,
  size?: number,
  filters?: Partial<AnimalFilterProps>
): Promise<BreedingStationAnimalResponseData> => {
  const url = `${baseUrl}${stationId}/males/`

  const response = await http.get(url, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const deleteBreedingStation = async (
  breedingStationId: string | number
): Promise<void> => {
  const response = await http.delete(`${baseUrl}${breedingStationId}/`)

  return response.data
}

const getBreedingStationReport = async (
  filters: BreedingStationFilterProps
): Promise<BreedingStationReportResponse[]> => {
  const response = await http.get(`${baseUrl}report`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })

  const stations: BreedingStationReportResponse[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    stations.push({
      breeding_station_id: Number(item[0]),
      breeding_station_name: item[1],
      init_date: new Date(item[2]),
      final_date: new Date(item[3]),
      is_active: item[4] === 'True',
      animal_id: item[5]? Number(item[5]): undefined,
      animal_sex: item[6],
      stock_number: item[7],
      electronic_eartag: item[8],
      birth_number: item[9],
    })
  }

  return stations
}

export {
  deleteBreedingStation,
  getReadAllBreedingStations,
  getReadBreedingStation,
  getReadBreedingStationFemales,
  getReadBreedingStationMales,
  patchUpdateBreedingStation,
  postAddBreedingStationAnimals,
  postCreateBreedingStation,
  postRemoveBreedingStationAnimal,
  getBreedingStationReport,
}
