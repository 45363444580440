import React from 'react'

import {
  Chip,
  ChipColorVariant,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { AnimalSexLabel } from 'app/core/types/animal'
import { BirthProps } from 'app/core/types/birth'

import styles from './styles.module.scss'

type BirthExpandedInfoProps = {
  data: BirthProps
  onFatherClick: () => void
}

const BirthExpandedInfo: React.FC<BirthExpandedInfoProps> = ({
  data,
  onFatherClick,
}) => {
  return (
    <div className={styles.expandedSection}>
      {data.sex && (
        <div>
          <span className={styles.caption}>Sexo da cria</span>
          <Chip
            colorVariant={
              data.calf_sex === AnimalSexLabel.female
                ? ChipColorVariant.sexFemale
                : ChipColorVariant.sexMale
            }
          />
        </div>
      )}

      <div>
        <span className={styles.caption}>Tipo do parto</span>
        <Chip selected={false} label={data.birth_type} />
      </div>

      {data?.farm_name && (
        <div>
          <span className={styles.caption}>Fazenda</span>
          <Chip selected={false} label={data?.farm_name} />
        </div>
      )}

      {data?.sector_name && (
        <div>
          <span className={styles.caption}>Setor</span>
          <Chip selected={false} label={data?.sector_name} />
        </div>
      )}

      {data?.breed && (
        <div>
          <span className={styles.caption}>Raça</span>
          <Chip selected={false} label={data.breed} />
        </div>
      )}

      {data?.father_name && (
        <div onClick={onFatherClick}>
          <span className={styles.caption}>Pai / Grupo de repasse</span>
          <Typography text={data?.father_name} variant={TypographyVariant.h6} />
        </div>
      )}
    </div>
  )
}

export { BirthExpandedInfo }
