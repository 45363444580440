import { DEFAULT_ITEMS_PER_PAGE_DETAILS } from 'app/core/types/system'

type PaginateProps<T> = {
  items: T[]
  total: number
  pages: number
  size: number
  page: number
}

export const paginateItems = <T extends Record<string, unknown>>(
  items: T[],
  page = 1,
  pageSize = DEFAULT_ITEMS_PER_PAGE_DETAILS
): PaginateProps<T> => {
  const startIndex = (page - 1) * pageSize
  const endIndex = startIndex + pageSize
  const paginatedItems = items.slice(startIndex, endIndex)

  return {
    items: paginatedItems,
    total: items.length,
    pages: Math.ceil(items.length / pageSize),
    size: pageSize,
    page,
  }
}
