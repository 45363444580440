import { ColumnDef } from '@tanstack/react-table'

import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { FarmProps } from 'app/core/types/system'

const farmsListColumns = (): ColumnDef<FarmProps, unknown>[] => [
  {
    header: 'Fazenda',
    accessorKey: 'name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
]

export { farmsListColumns }
