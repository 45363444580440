import React from 'react'

import { LoginScreen1 } from 'assets/images'
import { LogoVertical } from 'assets/svg'

import { Typography, TypographyVariant } from 'components/atoms'

import styles from './styles.module.scss'

export interface ILoginLayoutProps {
  header: string
  subheader?: string
}

const LoginLayout: React.FC<ILoginLayoutProps> = ({
  children,
  header,
  subheader,
}) => {
  return (
    <div className={styles.page}>
      <main className={styles.main}>
        <div className={styles.logo}>
          <img src={LogoVertical} draggable={false} />
        </div>

        <div className={styles.mainContentWrapper}>
          <header className={styles.headerContainer}>
            <div className={styles.header}>
              <Typography text={header} variant={TypographyVariant.h3} />
              {subheader && (
                <Typography
                  text={subheader}
                  variant={TypographyVariant.p}
                  className={styles.subheader}
                />
              )}
            </div>
          </header>
          {children}
        </div>
      </main>

      <aside className={styles.aside}>
        <img src={LoginScreen1} draggable={false} />
      </aside>
    </div>
  )
}

export { LoginLayout }
