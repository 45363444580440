import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'

import { ButtonIconPosition, Icon, IconNames } from 'components/atoms'
import {
  AnimalDrawer,
  BoxedTable,
  ISelectButtonItem,
} from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { ReproductionLayout } from 'components/templates'

import { AnimalDrawerType, AnimalSexPlural } from 'app/core/types/animal'
import { FilterSections } from 'app/core/types/filters'
import {
  InseminationAnimalProps,
  InseminationAnimalResponseData,
  InseminationProps,
  InseminationTypes,
} from 'app/core/types/hormonal'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type InseminationDetailsTemplateProps = {
  insemination?: InseminationProps
  animals?: InseminationAnimalResponseData
  table: Table<InseminationAnimalProps>
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  setPage: Dispatch<SetStateAction<number>>
  page: number
  onSubmit: IFormProps['onSubmit']
  filterSections: FilterSections
  setActiveFilterSection: Dispatch<SetStateAction<string[]>>
  handleChange: (section: string[] | undefined) => void
  handleDisabled: (name: string) => boolean
  isLoading: boolean
  isLoadingExport: boolean
  showActionButton: boolean
  handleGoToReview: () => void
  alreadyCreatedHeaderSelectButtonItems: ISelectButtonItem[]
  headerSelectButtonItems: ISelectButtonItem[]
  resetFilters?: () => void
  filterIsActive?: boolean
}

const InseminationDetailsTemplate: React.FC<InseminationDetailsTemplateProps> =
  ({
    insemination,
    animals,
    table,
    isDrawerOpen,
    handleToggleDrawer,
    onSubmit,
    page,
    setPage,
    setActiveFilterSection,
    isLoading,
    isLoadingExport,
    showActionButton,
    handleGoToReview,
    alreadyCreatedHeaderSelectButtonItems,
    headerSelectButtonItems,
    resetFilters,
    filterIsActive,
  }) => {
    const [openDrawerType, setOpenDrawerType] = useState<AnimalDrawerType>()

    const handleResetFilters = (): void => {
      setActiveFilterSection([])
      resetFilters?.call(this)
    }

    const handleFilter = useCallback(
      (drawerType: AnimalDrawerType): void => {
        setOpenDrawerType(drawerType)
        handleToggleDrawer()
      },
      [handleToggleDrawer]
    )

    return (
      <ReproductionLayout
        backButton
        title="Cobertura/Inseminação"
        inseminationType={insemination?.type}
        breedingStationId={insemination?.breeding_station_id}
        breedingStationName={insemination?.breeding_station_name}
        iatfScheduleId={insemination?.iatfschedule_id}
        iatfScheduleName={insemination?.iatfschedule_name}
        {...(showActionButton && {
          headerLargeButtonAction: handleGoToReview,
          headerLargeButtonLabel: 'Revisar',
          headerLargeButtonIcon: <Icon name={IconNames['chevron-forward']} />,
          headerSelectButtonLabel: 'Planilha',
          headerSelectButtonItems: headerSelectButtonItems,
          headerSelectButtonIcon: <Icon name={IconNames['chevron-down']} />,
          headerSelectButtonIconPosition: ButtonIconPosition.right,
        })}
        {...(!showActionButton && {
          headerSelectButtonLabel: 'Planilha',
          headerSelectButtonItems: alreadyCreatedHeaderSelectButtonItems,
          headerSelectButtonIcon: <Icon name={IconNames['chevron-down']} />,
          headerSelectButtonIconPosition: ButtonIconPosition.right,
          headerSelectButtonLoading: isLoadingExport,
        })}
      >
        <BoxedTable
          data={animals?.items || []}
          table={table}
          currentPage={page}
          totalPages={animals?.pages}
          totalItems={animals?.total}
          setPage={setPage}
          title="Animais"
          mainButtonLabel="Incluir"
          mainButtonIcon={IconNames['add-circle']}
          mainButtonAction={(): void => handleFilter(AnimalDrawerType.include)}
          mainButtonProps={{
            disabled: !showActionButton,
            ...(!showActionButton
              ? {
                  tooltip: Messages.INSEMINATION_INACTIVE_TOOLTIP,
                  tooltipPosition: 'left',
                }
              : {}),
          }}
          secondaryButtonLabel={'Filtrar'}
          secondaryButtonIcon={IconNames.filter}
          secondaryButtonAction={(): void =>
            handleFilter(AnimalDrawerType.filter)
          }
          secondaryButtonClassName={
            filterIsActive ? styles.filterButtonActive : ''
          }
          noDataMessage={
            filterIsActive
              ? Messages.ANIMALS_NOT_FOUND
              : Messages.ANIMAL_NOT_ADDED
          }
          isTableHeaderSticky
          isLoading={isLoading}
          disableLastCellNavigation
          disableCellsNavigationByIndex={
            insemination?.type === InseminationTypes.natural_cover
              ? [3, 4]
              : [3, 4, 5, 6]
          }
        />

        <AnimalDrawer
          includeTitle={`Incluir ${AnimalSexPlural.female}`}
          filterTitle={`Filtrar ${AnimalSexPlural.female}`}
          isOpen={isDrawerOpen}
          onToggle={handleToggleDrawer}
          onReset={handleResetFilters}
          onSubmit={onSubmit}
          drawerType={openDrawerType}
          hasIATFScheduleDropdown
          hasAptitudeCheckbox
        />
      </ReproductionLayout>
    )
  }

export { InseminationDetailsTemplate }
