import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { SearchTable } from 'components/molecules'
import { CustomTable } from 'components/organisms'

import { ServiceTypeProps } from '.'
import styles from '../../styles.module.scss'

type ServiceTypesTabTemplateProps = {
  table: Table<ServiceTypeProps>
  setGlobalFilter: Dispatch<SetStateAction<string>>
}

const ServiceTypesTabTemplate: React.FC<ServiceTypesTabTemplateProps> = ({
  table,
  setGlobalFilter,
}) => (
  <>
    <header className={styles.parametersHeader}>
      <SearchTable setGlobalFilter={setGlobalFilter} />
    </header>
    <CustomTable table={table} disableNavigation />
  </>
)

export { ServiceTypesTabTemplate }
