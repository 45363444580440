import { useCallback, useEffect, useState } from 'react'

import {
  deleteMeasurement,
  getReadPrecipitations,
  patchUpdateMeasurement,
  postCreateMeasurement,
} from 'app/core/services/precipitations'
import { PrecipitationHook } from 'app/core/types/hooks'
import {
  CreateMeasurementRequestData,
  PrecipitationFilterProps,
  PrecipitationProps,
  UpdateMeasurementRequestData,
} from 'app/core/types/precipitation'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

const usePrecipitations = ({
  stationIds,
  externalStationIds,
  init_date,
  end_date,
}: PrecipitationFilterProps): PrecipitationHook => {
  const [precipitations, setPrecipitations] = useState<PrecipitationProps[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const readPrecipitations = useCallback(async () => {
    if (stationIds || externalStationIds) {
      if (stationIds?.length === 0 && externalStationIds?.length === 0) {
        setPrecipitations([])
        addToast({
          message: Messages.PRECIPITATIONS_STATION_REQUIRED,
          type: 'warning',
        })

        return
      }

      setIsLoading(true)
      try {
        const precipitations = await getReadPrecipitations(
          stationIds || [],
          externalStationIds || [],
          init_date,
          end_date
        )
        setPrecipitations(precipitations)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    }
  }, [stationIds, externalStationIds, init_date, end_date])

  const createMeasurement = useCallback(
    async (request: CreateMeasurementRequestData) => {
      try {
        const precipitation = await postCreateMeasurement(request)

        setPrecipitations(prevState => [precipitation, ...prevState])

        await readPrecipitations()

        addToast({
          message: Messages.PRECIPITATIONS_MEASURE_CREATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    [readPrecipitations]
  )

  const updateMeasurement = useCallback(
    async (measurementId: number, request: UpdateMeasurementRequestData) => {
      try {
        const precipitation = await patchUpdateMeasurement(
          measurementId,
          request
        )

        setPrecipitations(prevState => [precipitation, ...prevState])

        await readPrecipitations()

        addToast({
          message: Messages.PRECIPITATIONS_MEASURE_UPDATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    [readPrecipitations]
  )

  const deleteMeasurementItem = useCallback(
    async (measurementId: number) => {
      try {
        await deleteMeasurement(measurementId)

        await readPrecipitations()

        addToast({
          message: Messages.PRECIPITATIONS_MEASURE_DELETE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    [readPrecipitations]
  )

  useEffect(() => {
    readPrecipitations()
  }, [readPrecipitations])

  return {
    precipitations,
    createMeasurement,
    updateMeasurement,
    deleteMeasurementItem,
    isLoading,
  }
}

export { usePrecipitations }
