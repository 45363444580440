import { useCallback, useEffect, useState } from 'react'

import {
  getInseminators,
  patchUpdateInseminator,
  postCreateInseminator,
} from 'app/core/services'
import { InseminatorsHook } from 'app/core/types/hooks'
import {
  InseminatorCreateRequestData,
  InseminatorFilterProps,
  InseminatorReadResponseData,
  InseminatorUpdateRequestData,
} from 'app/core/types/inseminator'
import { DEFAULT_ITEMS_PER_PAGE_DETAILS } from 'app/core/types/system'
import {
  addToast,
  dateForFileName,
  downloadFile,
  generateXlsxTemplate,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useInseminators = (): InseminatorsHook => {
  const [inseminators, setInseminators] = useState<
    InseminatorReadResponseData | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)

  const readInseminators = useCallback(
    async (filters?: Partial<InseminatorFilterProps>): Promise<void> => {
      try {
        setIsLoading(true)

        const data = await getInseminators({
          size: DEFAULT_ITEMS_PER_PAGE_DETAILS,
          ...filters,
        })

        setInseminators(data)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const createInseminator = useCallback(
    async (payload: InseminatorCreateRequestData) => {
      try {
        setIsLoading(true)

        await postCreateInseminator(payload)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const updateInseminator = useCallback(
    async (
      id: number,
      payload: Partial<InseminatorUpdateRequestData>
    ): Promise<void> => {
      try {
        setIsLoading(true)

        await patchUpdateInseminator(id, payload)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const exportInseminators = (): void => {
    const templateFile = generateXlsxTemplate([
      ['ID', 'Nome', 'Fazenda', 'Status'],
      ...(inseminators?.items
        ? inseminators.items.map(({ id, name, farm_name, status }) => [
            id,
            name,
            farm_name,
            status ? 'Ativo' : 'Inativo',
          ])
        : []),
    ])

    downloadFile({
      data: templateFile,
      fileName: `inseminadores-${dateForFileName()}`,
    })

    addToast({ message: Messages.INSEMINATOR_EXPORT_SUCCESS, type: 'success' })
  }

  useEffect(() => {
    readInseminators()
  }, [readInseminators])

  return {
    inseminators,
    readInseminators,
    createInseminator,
    updateInseminator,
    exportInseminators,
    isLoading,
  }
}

export { useInseminators }
