import React from 'react'

import { Chip, ChipColorVariant } from 'components/atoms'

import { AnimalSexLabel, AnimalSexPlural } from 'app/core/types/animal'
import { DashboardAnimalCount } from 'app/core/types/dashboard'

import styles from './styles.module.scss'

type AnimalCountTableData = Record<
  string,
  { farm: string } & {
    [key in AnimalSexLabel]: {
      label: AnimalSexPlural
      age_grouped_counts: Record<string, number>
      total: number
    }
  }
>

const getParsedData = (
  animalCount: DashboardAnimalCount[]
): AnimalCountTableData =>
  animalCount?.reduce((acc, animalCountItem) => {
    const itemTotal = Object.values(animalCountItem.age_grouped_counts).reduce(
      (sum, item) => sum + item.count,
      0
    )
    const parsedAgeGroups = Object.values(
      animalCountItem.age_grouped_counts
    ).reduce(
      (ageGroupsCounts, item) => ({
        ...ageGroupsCounts,
        [item.label]: item.count,
      }),
      {}
    )
    acc[animalCountItem.farm_name] = {
      ...acc[animalCountItem.farm_name],
      farm: animalCountItem.farm_name,
      [animalCountItem.animal_sex]: {
        label: AnimalSexPlural[animalCountItem.animal_sex],
        age_grouped_counts: parsedAgeGroups,
        total: itemTotal,
      },
    }
    return acc
  }, {} as AnimalCountTableData)

type DashboardCountTableProps = {
  animalCount?: DashboardAnimalCount[]
}

const DashboardCountTable: React.FC<DashboardCountTableProps> = ({
  animalCount,
}) => {
  const data = getParsedData(animalCount ?? [])
  const ageGroups = Object.values(
    animalCount?.[0].age_grouped_counts ?? {}
  ).map(item => item.label)
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>
            <span>Fazenda</span>
          </th>
          <th>
            <span>Sexo</span>
          </th>
          {ageGroups.map((item, index) => (
            <th key={`header-${index}`}>
              <span>{item}</span>
            </th>
          ))}
          <th>
            <span>Total</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.values(data ?? {}).map((item, farmIndex) => (
          <tr key={`row-${farmIndex}`}>
            <td>{item.farm}</td>
            <td>
              <Chip
                colorVariant={ChipColorVariant.sexMale}
                className={styles.tableChip}
              />
              <br />
              <Chip
                colorVariant={ChipColorVariant.sexFemale}
                className={styles.tableChip}
              />
            </td>
            {ageGroups.map((ageGroup, ageGroupIndex) => (
              <td key={`row-${farmIndex}-column-${ageGroupIndex}`}>
                <span>{item.male?.age_grouped_counts[ageGroup] ?? 0}</span>
                <br />
                <span>{item.female?.age_grouped_counts[ageGroup] ?? 0}</span>
              </td>
            ))}
            <td>
              <span>{item.male?.total ?? 0}</span>
              <br />
              <span>{item.female?.total ?? 0}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export { DashboardCountTable }
