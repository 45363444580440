/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react'

import {
  getExportServiceOrders,
  getListServiceOrder,
  getServiceOrderHistory,
  patchUpdateServiceOrder,
  postCreateServiceOrder,
} from 'app/core/services/service-order'
import { ServiceOrderHook, ServiceOrderPageInfo } from 'app/core/types/hooks'
import {
  ListServiceOrderProps,
  ServiceOrderHistoryProps,
  ServiceOrderInfoProps,
  ServiceOrderProps,
  ServiceOrderUpdateData,
} from 'app/core/types/service-order'
import {
  addToast,
  arrayToCsv,
  dateForFileName,
  dateTimeFormat,
  downloadFile,
  getFormattedDateValueOrNull,
  getValueOrNull,
  handleHttpError,
  parseMinutesToHours,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useServiceOrder = (): ServiceOrderHook => {
  const [filters, setFilters] = useState<ListServiceOrderProps>()
  const [serviceOrders, setServiceOrders] = useState<ServiceOrderInfoProps[]>(
    []
  )
  const [serviceOrderPageInfo, setServiceOrderPageInfo] =
    useState<ServiceOrderPageInfo>({
      currentPage: 1,
      totalPages: 1,
      total: 0,
    })
  const [history, setHistory] = useState<ServiceOrderHistoryProps[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const listServiceOrders = useCallback(async (): Promise<void> => {
    if (filters) {
      try {
        const { farm_id, service_type, page } = filters

        if (service_type === null || typeof farm_id === 'undefined') {
          setServiceOrders([])
          setServiceOrderPageInfo({
            currentPage: 1,
            total: 0,
            totalPages: 1,
          })

          return
        }

        setIsLoading(true)

        const serviceOrdersResponse = await getListServiceOrder(filters, page)

        setServiceOrders(serviceOrdersResponse.items ?? [])
        setServiceOrderPageInfo({
          currentPage: serviceOrdersResponse.page ?? 1,
          total: serviceOrdersResponse.total ?? 0,
          totalPages: serviceOrdersResponse.pages ?? 1,
        })
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    }
  }, [filters])

  const createServiceOrder = useCallback(
    async (serviceOrder: ServiceOrderProps) => {
      try {
        const serviceOrderDate = dateTimeFormat(serviceOrder.date, true)
        const isCreatedInTheFilteredDate =
          filters?.init_date === serviceOrderDate &&
          filters?.final_date === serviceOrderDate

        const data = await postCreateServiceOrder(serviceOrder)

        if (isCreatedInTheFilteredDate) {
          const newServiceOrder = {
            ...data,
            new: true,
          }

          const oldServiceOrders = serviceOrders.map(serviceOrder => ({
            ...serviceOrder,
            new: false,
          }))

          setServiceOrders([newServiceOrder, ...oldServiceOrders])
          setServiceOrderPageInfo(prevState => ({
            currentPage: prevState.currentPage,
            total: prevState.total + 1,
            totalPages: prevState.totalPages,
          }))
        } else {
          listServiceOrders()
        }

        addToast({
          message: Messages.SERVICE_ORDER_CREATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e)
      }
    },
    [filters, listServiceOrders, serviceOrders]
  )

  const updateServiceOrder = useCallback(
    async (data: ServiceOrderUpdateData) => {
      try {
        const { serviceOrderId, payload } = data

        await patchUpdateServiceOrder(serviceOrderId, payload)

        listServiceOrders()

        addToast({
          message: Messages.SERVICE_ORDER_UPDATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e)
      }
    },
    [listServiceOrders]
  )

  const exportServiceOrders = useCallback(
    async (initDate: string, finalDate: string) => {
      try {
        setIsLoadingExport(true)

        addToast({
          message: Messages.GENERATING_EXPORT_FILE,
          type: 'info',
        })

        const data = await getExportServiceOrders({
          ...filters,
          init_date: initDate,
          final_date: finalDate,
        })

        if (data.length === 0) {
          addToast({
            message: Messages.SERVICE_ORDER_NOT_FOUND,
          })

          return
        }

        const serviceOrders = data.map(serviceOrder => ({
          Veiculo: getValueOrNull(serviceOrder.machineryName),
          Data: getFormattedDateValueOrNull(serviceOrder.date),
          'Documento N': getValueOrNull(serviceOrder.bulletinNumber),
          'H. Normal': getValueOrNull(
            parseMinutesToHours(serviceOrder.duration ?? 0)
          ),
          'km/HR': getValueOrNull(serviceOrder.odometer),
          Matrícula: getValueOrNull(serviceOrder.executorRegistrationNumber),
          Cedente: getValueOrNull(serviceOrder.executorName),
          'Cód Empresarial': getValueOrNull(serviceOrder.classificationCode),
          Classificação: getValueOrNull(serviceOrder.classificationName),
          'Cód Gerencial': getValueOrNull(serviceOrder.costCenterCode),
          'Nome Gerencial': getValueOrNull(serviceOrder.costCenterName),
          'Filial Título': getValueOrNull(serviceOrder.farmName),
        }))

        const blob = arrayToCsv(serviceOrders, ';')

        downloadFile({
          data: blob,
          fileName: `boletins-${dateForFileName()}`,
          extension: 'csv',
        })

        addToast({
          message: Messages.SERVICE_ORDER_EXPORTED_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoadingExport(false)
      }
    },
    [filters]
  )

  const getHistory = useCallback(
    async (serviceOrderId: number): Promise<void> => {
      try {
        const data = await getServiceOrderHistory(serviceOrderId)

        setHistory(data)
      } catch (e) {
        handleHttpError(e)
      }
    },
    []
  )

  return {
    createServiceOrder,
    listServiceOrders,
    serviceOrders,
    serviceOrderPageInfo,
    history,
    updateServiceOrder,
    exportServiceOrders,
    setFilters,
    getHistory,
    isLoading,
    isLoadingExport,
  }
}

export { useServiceOrder }
