import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import {
  getReadAllProducts,
  patchProduct,
  deleteProduct as deleteProductService,
  postProduct,
} from 'app/core/services'
import { ProductHook } from 'app/core/types/hooks'
import { ProductListProps } from 'app/core/types/products'
import { addToast } from 'app/core/utils'

const useProducts = (): ProductHook => {
  const [products, setProducts] = useState<ProductListProps[]>([])
  const [productsDroplist, setProductsDroplist] = useState<SelectItemProps[]>(
    []
  )

  const readAllProducts = useCallback(async (): Promise<void> => {
    try {
      const data = await getReadAllProducts()
      const dataSorted = data.sort((a, b) => (a.name > b.name ? 1 : -1))

      const PRODUCT_OPTIONS = dataSorted.map(e => ({
        label: e.name,
        value: e.name,
      }))
      setProducts(data)
      setProductsDroplist(PRODUCT_OPTIONS)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }, [])

  useEffect(() => {
    readAllProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateProduct = async (
    id: number,
    payload: Partial<Omit<ProductListProps, 'id'>>
  ): Promise<void> => {
    try {
      await patchProduct(id, payload)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }

  const deleteProduct = async (id: number): Promise<void> => {
    try {
      await deleteProductService(id)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }

  const createProduct = async (
    payload: Omit<ProductListProps, 'id'>
  ): Promise<void> => {
    try {
      await postProduct(payload)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }

  return {
    products,
    updateProduct,
    deleteProduct,
    createProduct,
    readAllProducts,
    productsDroplist,
  }
}

export { useProducts }
