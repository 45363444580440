import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useStockNumber } from 'app/core/hooks/stock-number'
import { NavigateList } from 'app/core/routes/routes'
import { StockNumberCreateProps } from 'app/core/types/animal'

import { stockNumberListColumns } from '../tables'
import { StockNumberFeedbackTemplate } from './template'

const StockNumberFeedback: React.FC = () => {
  const history = useHistory<NavigateList>()

  const [page, setPage] = useState<number>(1)

  const {
    animals,
    exportFailedAnimals,
    failedAnimals,
    isLoading,
    removeAnimal,
    removeStorage,
  } = useStockNumber({ page })

  const handleFinish = async (): Promise<void> => {
    removeStorage()
    history.push(NavigateList.animalList)
  }

  const columns = useMemo<ColumnDef<StockNumberCreateProps>[]>(
    () => stockNumberListColumns(removeAnimal, true),
    [removeAnimal]
  )

  const table = useReactTable({
    data: (animals?.items as StockNumberCreateProps[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <StockNumberFeedbackTemplate
      animals={animals}
      exportFailedAnimals={exportFailedAnimals}
      failedAnimals={failedAnimals}
      handleFinish={handleFinish}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      table={table}
    />
  )
}

export { StockNumberFeedback }
