import classNames from 'classnames'

import { InputText, Typography, TypographyVariant } from 'components/atoms'

import styles from './../styles.module.scss'

type Field = {
  name: string
  label: string
  description?: string
  defaultValue?: string
  suffix: string
}

type FieldsetProps = {
  title?: string
  fields: Field[]
  className?: string
  onChange?: (name: string, value: string) => void
}

const Fieldset: React.FC<FieldsetProps> = ({
  onChange,
  title,
  fields,
  className,
}) => (
  <>
    {title && (
      <Typography
        text={title}
        variant={TypographyVariant.h5}
        className={styles.reproductionFieldsetTitle}
      />
    )}
    <div className={classNames(styles.reproductionFieldset, className)}>
      {fields.map(item => (
        <div className={styles.reproductionFieldsetRow} key={item.name}>
          <div className={styles.reproductionFieldsetLabelContainer}>
            <span className={styles.reproductionFieldsetFieldLabel}>
              {item.label}
            </span>
            {item.description && (
              <span className={styles.reproductionFieldsetFieldDescription}>
                {item.description}
              </span>
            )}
          </div>
          <div className={styles.reproductionFieldsetFieldInputContainer}>
            <InputText
              name={item.name}
              htmlType="numeric"
              className={styles.reproductionFieldsetFieldInput}
              containerClassName={styles.reproductionFieldsetFieldInputWrapper}
              defaultValue={item.defaultValue}
              onChange={(e): void =>
                onChange?.(item.name, e.currentTarget.value)
              }
            />
            <span className={styles.reproductionFieldsetFieldInputSuffix}>
              {item.suffix}
            </span>
          </div>
        </div>
      ))}
    </div>
  </>
)

export { Fieldset }
