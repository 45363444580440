import React, { useState } from 'react'

import { Chip, Divider, Typography, TypographyVariant } from 'components/atoms'

import { AnimalSexLabel } from 'app/core/types/animal'
import { DashboardAnimalUnit } from 'app/core/types/dashboard'
import { FarmProps } from 'app/core/types/system'

import { DashboardAnimalUnitCategoriesBarChart } from './charts/bar'
import { DashboardAnimalUnitCategoriesPieChart } from './charts/pie'
import styles from './styles.module.scss'

type DashboardAnimalUnitCategoriesCardProps = {
  animalUnit?: DashboardAnimalUnit[]
  farms?: FarmProps[]
}

const DashboardAnimalUnitCategoriesCard: React.FC<DashboardAnimalUnitCategoriesCardProps> =
  ({ animalUnit, farms }) => {
    const [sex, setSex] = useState<AnimalSexLabel>(AnimalSexLabel.female)
    const [farm, setFarm] = useState<FarmProps | undefined>(undefined)
    return (
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <Typography
            text="Categorias"
            variant={TypographyVariant.h3}
            className={styles.cardTitle}
          />
          <div>
            <span className={styles.cardHeaderSubtitle}>Visualizar por</span>
            <div className={styles.chipsContainer}>
              <Chip
                label="Fêmeas"
                selected={sex === AnimalSexLabel.female}
                onClick={(): void => setSex(AnimalSexLabel.female)}
              />
              <Chip
                label="Machos"
                selected={sex === AnimalSexLabel.male}
                onClick={(): void => setSex(AnimalSexLabel.male)}
              />
            </div>
          </div>
        </div>
        <DashboardAnimalUnitCategoriesBarChart
          sex={sex}
          animalUnit={animalUnit}
        />
        <Divider className={styles.dividerHorizontal} />
        <div className={styles.pieChartsContainer}>
          <div className={styles.pieChart}>
            <Typography
              text="Fêmeas"
              variant={TypographyVariant.h3}
              className={styles.title}
            />

            <DashboardAnimalUnitCategoriesPieChart
              animalUnit={animalUnit}
              sex={AnimalSexLabel.female}
              farm={farm}
            />
          </div>
          <Divider className={styles.dividerVertical} vertical />
          <div className={styles.pieChart}>
            <Typography
              text="Machos"
              variant={TypographyVariant.h3}
              className={styles.title}
            />
            <DashboardAnimalUnitCategoriesPieChart
              animalUnit={animalUnit}
              sex={AnimalSexLabel.male}
              farm={farm}
            />
          </div>
        </div>
        <div className={styles.pieChartFooter}>
          <div className={styles.chipsContainer}>
            <Chip
              label="Total"
              selected={!farm}
              onClick={(): void => setFarm(undefined)}
            />
            {farms?.map((currentFarm, index) => (
              <Chip
                key={index}
                label={currentFarm.name}
                selected={farm === currentFarm}
                onClick={(): void => setFarm(currentFarm)}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

export { DashboardAnimalUnitCategoriesCard }
