import React from 'react'

import { NoDataFound } from 'assets/images'

import {
  Button,
  ButtonType,
  Divider,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import {
  CustomModal,
  ServiceOrderInfo,
  withLoading,
} from 'components/molecules'
import {
  OSDrawer,
  OSDrawerMode,
  ServiceOrderFilters,
  ServiceOrderPagination,
} from 'components/organisms'
import { OSLayout } from 'components/templates'

import { ServiceOrderPageInfo } from 'app/core/types/hooks'
import { NotificationProps } from 'app/core/types/notifications'
import {
  ListServiceOrderProps,
  ServiceOrderInfoProps,
} from 'app/core/types/service-order'
import { Messages } from 'config/messages'

import { ServiceOrdersLoadingTemplate } from './loading'
import styles from './styles.module.scss'

type ServiceOrdersTemplateProps = {
  activeFilters: ListServiceOrderProps
  serviceOrders: ServiceOrderInfoProps[]
  serviceOrderPageInfo: ServiceOrderPageInfo
  setServiceOrderPage: React.Dispatch<React.SetStateAction<number>>
  lastNotification?: NotificationProps
  toggleNotificationModal: () => void
  setEditItem: React.Dispatch<
    React.SetStateAction<ServiceOrderInfoProps | undefined>
  >
  handleReadNotification: (notificationId: number) => void
  editItem?: ServiceOrderInfoProps
  isEditDrawerOpen: boolean
  isNotificationModalOpen: boolean
  isLoading: boolean
}

const ServiceOrdersTemplate: React.FC<ServiceOrdersTemplateProps> = ({
  activeFilters,
  serviceOrders,
  serviceOrderPageInfo,
  setServiceOrderPage,
  lastNotification,
  toggleNotificationModal,
  setEditItem,
  handleReadNotification,
  editItem,
  isEditDrawerOpen,
  isNotificationModalOpen,
  isLoading,
}) => {
  const hasServiceTypeFiltered = activeFilters.service_type === null
  const hasFilters =
    activeFilters.contains_name ||
    activeFilters.service_type ||
    hasServiceTypeFiltered

  const currentPageItems = serviceOrders?.length

  return (
    <>
      <OSLayout largerContent>
        <ServiceOrderFilters.Root>
          <div>
            <ServiceOrderFilters.Date />
            <ServiceOrderFilters.Count total={serviceOrderPageInfo.total} />
          </div>
          <div className={styles.findAndCheckbox}>
            <ServiceOrderFilters.Text />
            <ServiceOrderFilters.ServiceType />
          </div>
        </ServiceOrderFilters.Root>

        {withLoading(
          <>
            {serviceOrders.length > 0 ? (
              <div className={styles.ordensBody}>
                {serviceOrders.map(bulletin => (
                  <ServiceOrderInfo
                    key={`bulletin-${bulletin.id}`}
                    onClick={(): void => setEditItem(bulletin)}
                    serviceOrderInfo={bulletin}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.noDataFound}>
                <img
                  src={NoDataFound}
                  draggable={false}
                  width={384}
                  height={256}
                  alt={Messages.SERVICE_ORDER_NOT_FOUND}
                />
                <Typography
                  text={
                    hasFilters
                      ? Messages.SERVICE_ORDER_NOT_FOUND_FILTERED
                      : Messages.SERVICE_ORDER_NOT_FOUND
                  }
                  variant={TypographyVariant.h5}
                  className={styles.noDataFoundText}
                />
              </div>
            )}

            <ServiceOrderPagination
              currentPage={serviceOrderPageInfo.currentPage}
              currentPageItems={currentPageItems}
              totalItems={serviceOrderPageInfo.total}
              totalPages={serviceOrderPageInfo.totalPages}
              setPage={setServiceOrderPage}
            />
          </>
        )(isLoading, ServiceOrdersLoadingTemplate)}

        {editItem && (
          <OSDrawer
            isDrawerOpen={isEditDrawerOpen}
            toggleDrawer={(): void => setEditItem(undefined)}
            mode={OSDrawerMode.edit}
            editItem={editItem}
          />
        )}
      </OSLayout>

      {lastNotification && (
        <CustomModal
          handleCloseModal={toggleNotificationModal}
          modalIsOpen={isNotificationModalOpen}
          modalHeader=""
        >
          <div className={styles.notificationModal}>
            <Typography text="Notificação" variant={TypographyVariant.h2} />
            <p>
              Mensagem: <br />
              <span>{lastNotification.message}</span>
            </p>

            <Divider darker />

            <Button
              testId="readNotificationButton"
              label="Ok, entendi"
              type={ButtonType.primary}
              onClick={(): void => handleReadNotification(lastNotification.id)}
            />
          </div>
        </CustomModal>
      )}
    </>
  )
}

export { ServiceOrdersTemplate }
