import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useInsemination, useInseminationAnimal } from 'app/core/hooks'
import { IParamProps, NavigateList } from 'app/core/routes/routes'

import { inseminationFeedbackColumns } from '../tables/feedback'
import { InseminationFeedbackTemplate } from './template'

const InseminationFeedback: React.FC = () => {
  const history = useHistory<NavigateList>()
  const { inseminationId } = useParams<IParamProps>()

  const [page, setPage] = useState(1)

  const { insemination } = useInsemination({ inseminationId })
  const {
    animals,
    clearInseminationStorage,
    exportFailedInseminations,
    failedInseminations,
    isLoading,
  } = useInseminationAnimal({
    inseminationId,
    page,
  })

  const handleFinishInsemination = (): void => {
    clearInseminationStorage()
    history.push(`${NavigateList.inseminationHistory}`)
  }

  const columns = useMemo(
    () => inseminationFeedbackColumns(insemination?.type),
    [insemination?.type]
  )

  const table = useReactTable({
    data: animals?.items || [],
    columns,
    getCoreRowModel: useMemo(() => getCoreRowModel(), []),
  })

  return (
    <InseminationFeedbackTemplate
      table={table}
      animals={animals}
      insemination={insemination}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      exportFailedInseminations={exportFailedInseminations}
      failedInseminations={failedInseminations}
      handleFinishInsemination={handleFinishInsemination}
    />
  )
}

export { InseminationFeedback }
