import axios from 'axios'

import { Messages } from 'config/messages'

import { addToast } from '../toast'

const handleHttpError = (e: unknown, throwException = true): void => {
  let message = (e as Error).message

  if (axios.isAxiosError(e) && e.response?.data['message']) {
    message = e.response?.data['message']
    addToast({ message })
  } else {
    addToast({ message: Messages.ERROR_MESSAGE })
  }

  if (throwException) {
    throw new Error(message)
  }
}

export { handleHttpError }
