import { useCallback, useState } from 'react'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem, useForm } from 'components/organisms'

import { useFarm, useSector } from 'app/core/hooks'
import { useMatingRecommendations } from 'app/core/hooks/mating-recommendation'
import { MatingRecommendationFilterProps } from 'app/core/types/mating-recommendation'

import { MatingRecommendationHistoryTemplate } from './template'

const MatingRecommendationHistory: React.FC = () => {
  const [filters, setFilters] = useState<MatingRecommendationFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const {
    recommendations,
    isLoading,
    exportMatingRecommendations,
    isLoadingExport,
  } = useMatingRecommendations({
    filters,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterMatingRecommendation = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      setPage(1)
      setFilters(filters as MatingRecommendationFilterProps)

      handleToggleDrawer()
    },
    []
  )

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Exportar',
      icon: IconNames.download,
      action: (): void => {
        exportMatingRecommendations()
      },
    },
  ]

  const {activeFarmsDroplist } = useFarm()
  const { sectorsDroplist } = useSector()

  const { Form, formState, reset } = useForm({
    onSubmit: handleFilterMatingRecommendation,
  })

  const activeFilters = Object.entries(formState.touchedFields).length

  const handleResetFilters = (): void => {
    setFilters(undefined)
    reset()
  }

  return (
    <MatingRecommendationHistoryTemplate
      recommendations={recommendations}
      currentPage={page}
      setCurrentPage={setPage}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      headerSelectButtonItems={selectButtonItems}
      isLoading={isLoading}
      isLoadingExport={isLoadingExport}
      onResetFilters={handleResetFilters}
      form={Form}
      activeFilters={activeFilters}
      sectors={sectorsDroplist ?? []}
      farms={activeFarmsDroplist ?? []}
    />
  )
}

export { MatingRecommendationHistory }
