import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useForm } from 'components/organisms'

import { useFarm, useSector } from 'app/core/hooks'
import { useDiagnosticGroup } from 'app/core/hooks/breeding/diagnostic_group'
import { NavigateList } from 'app/core/routes/routes'
import { GetDiagnosticsFilters } from 'app/core/types/breeding'
import { StorageKeys } from 'app/core/types/storage'

import { DiagnosticsHistoryTemplate } from './template'

const DiagnosticsHistory: React.FC = () => {
  const history = useHistory<NavigateList>()

  const { sectorsDroplist } = useSector()
  const { activeFarmsDroplist } = useFarm()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<GetDiagnosticsFilters>({})
  const [page, setPage] = useState<number>(1)

  const {
    groups,
    isLoading,
    deleteDiagnosticGroup,
    isLoadingExport,
    exportDiagnosticGroup,
  } = useDiagnosticGroup({ filters, page })

  const handleToggleDrawer = (): void => setIsDrawerOpen(prev => !prev)

  const handleFilterGroups = useCallback(
    async (data: unknown): Promise<void> => {
      setFilters(data as GetDiagnosticsFilters)
      setPage(1)
      handleToggleDrawer()
    },
    []
  )

  const { Form, formState, reset } = useForm({ onSubmit: handleFilterGroups })

  const handleFilterReset = (): void => {
    reset()
    setFilters({})
  }

  const activeFilters = Object.entries(formState.touchedFields).length

  const [selectedGroup, setSelectedGroup] = useState<number>()

  const handleDiagnosticCreate = (): void =>
    history.push(NavigateList.diagnosticsCreate)

  const handleGroupClick = (groupId: number): void => {
    history.push(`${NavigateList.diagnosticsDetails}${groupId}`)
  }

  const handleDeleteGroupClick = (groupId: number): void => {
    setSelectedGroup(groupId)
  }

  const handleDeleteModalConfirm = (id: number): void => {
    deleteDiagnosticGroup(id)
    setSelectedGroup(undefined)
  }

  const handleDeleteModalCancel = (): void => {
    setSelectedGroup(undefined)
  }

  const removeStorageDiagnostics = useCallback((): void => {
    localStorage.removeItem(StorageKeys.diagnostics_review)
    localStorage.removeItem(StorageKeys.diagnostics_review_info)
    localStorage.removeItem(StorageKeys.diagnostics_review_failed)
  }, [])

  useEffect(() => {
    removeStorageDiagnostics()
  }, [removeStorageDiagnostics])

  return (
    <DiagnosticsHistoryTemplate
      onDiagnosticCreateClick={handleDiagnosticCreate}
      form={Form}
      activeFilters={activeFilters}
      onFilterReset={handleFilterReset}
      groups={groups}
      onGroupClick={handleGroupClick}
      onDeleteGroupClick={handleDeleteGroupClick}
      selectedGroup={selectedGroup}
      onDeleteModalConfirm={handleDeleteModalConfirm}
      onDeleteModalCancel={handleDeleteModalCancel}
      sectors={sectorsDroplist}
      farms={activeFarmsDroplist}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      currentPage={page}
      setCurrentPage={setPage}
      isLoading={isLoading}
      onExportClick={exportDiagnosticGroup}
      isLoadingExport={isLoadingExport}
    />
  )
}

export { DiagnosticsHistory }
