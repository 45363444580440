import { ProductListProps } from 'app/core/types/products'
import { http } from 'interfaces/http'

const baseUrl = '/products/'

const getReadAllProducts = async (): Promise<ProductListProps[]> => {
  const response = await http.get(baseUrl)
  return response.data
}

const postProduct = async (
  payload: Omit<ProductListProps, 'id'>
): Promise<ProductListProps> => {
  const response = await http.post(baseUrl, payload)
  return response.data
}

const patchProduct = async (
  id: number,
  payload: Partial<Omit<ProductListProps, 'id'>>
): Promise<ProductListProps> => {
  const response = await http.patch(`${baseUrl}${id}`, payload)
  return response.data
}

const deleteProduct = async (id: number): Promise<ProductListProps> => {
  const response = await http.delete(`${baseUrl}${id}`)
  return response.data
}

export { getReadAllProducts, postProduct, patchProduct, deleteProduct }
