import React from 'react'

import {
  Button,
  ButtonSize,
  ButtonType,
  SelectItemProps,
} from 'components/atoms'
import {
  LabeledCard,
  LabeledCardButtonRow,
  LabeledCardDateRow,
  LabeledCardRow,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

type ScheduleCreateTemplateProps = {
  stationsList?: SelectItemProps[]
  stationDate?: { initDate: string; finalDate: string }
  protocolsList?: SelectItemProps[]
  handleSelectStation: (stationId: number) => void
  onSubmit: IFormProps['onSubmit']
}

const ScheduleCreateTemplate: React.FC<ScheduleCreateTemplateProps> = ({
  stationsList,
  stationDate,
  protocolsList,
  handleSelectStation,
  onSubmit,
}) => {
  const { Form, watch, watchEmptyValues } = useForm({ onSubmit })

  const { init_date: groupInitDate, final_date: groupFinalDate } = watch()

  return (
    <BoxedLayout title="Nova Programação IATF">
      <LabeledCard title="Informações gerais">
        <Form>
          <LabeledCardRow>
            <Form.Select
              label="Selecionar Estação de Monta"
              name="breed_station_id"
              placeholder="Selecione uma estação de monta"
              isLoading={!stationsList}
              options={stationsList}
              onChange={(value): void =>
                handleSelectStation(parseInt((value as SelectItemProps).value))
              }
              width={370}
            />

            {stationDate && (
              <>
                <Form.InputText
                  label="Nome da Programação IATF"
                  name="name"
                  placeholder="Inserir nome da programação"
                  width={370}
                />

                <Form.Select
                  label="Selecionar Protocolo Hormonal"
                  name="hormonal_protocol_id"
                  placeholder="Selecione um protocolo"
                  isLoading={!protocolsList}
                  options={protocolsList}
                  width={370}
                />

                <LabeledCardDateRow>
                  <Form.InputText
                    label="Data de início e fim"
                    name="date_start"
                    htmlType="date"
                    min={stationDate.initDate}
                    max={groupFinalDate || stationDate.finalDate}
                  />
                  <Form.InputText
                    emptyLabelSpacing
                    name="date_end"
                    htmlType="date"
                    min={groupInitDate || stationDate.initDate}
                    max={stationDate.finalDate}
                  />
                </LabeledCardDateRow>
              </>
            )}
          </LabeledCardRow>

          {stationDate && (
            <LabeledCardButtonRow>
              <Button
                label="Salvar"
                type={ButtonType.primary}
                size={ButtonSize.large}
                disabled={watchEmptyValues() || !watch().name}
              />
            </LabeledCardButtonRow>
          )}
        </Form>
      </LabeledCard>
    </BoxedLayout>
  )
}

export { ScheduleCreateTemplate }
