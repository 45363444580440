import React from 'react'

import classNames from 'classnames'

import styles from './styles.module.scss'

export enum ButtonType {
  primary = 'primary',
  outline = 'outline',
  ghost = 'ghost',
  destructive = 'destructive',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum ButtonIconPosition {
  left = 'left',
  right = 'right',
}

export interface IButtonProps {
  /**
   * The type of the button
   */
  type?: ButtonType
  /**
   * The size of the button
   */
  size?: ButtonSize
  /**
   * The content of the button
   */
  label?: string
  /**
   * The sub content of the button
   */
  subLabel?: string
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * Is the button disabled?
   */
  disabled?: boolean
  /**
   * Is the button loading?
   */
  loading?: boolean
  /**
   * A image component to display inside of the button
   */
  icon?: React.ReactElement | React.ReactNode
  /**
   * The icon position inside the button (left|right)
   */
  iconPosition?: ButtonIconPosition
  /**
   * Classname to add custom css
   */
  className?: string
  /**
   * Tooltip message on hover
   */
  tooltip?: string
  /**
   * Tooltip position
   */
  tooltipPosition?: 'left' | 'right'
  /**
   * Html button type
   */
  htmlType?: 'button' | 'submit' | 'reset' | undefined
  /**
   * Custom testId for testing
   */
  testId?: string
}

const Button = ({
  label,
  subLabel,
  onClick,
  size = ButtonSize.medium,
  type = ButtonType.primary,
  iconPosition = ButtonIconPosition.left,
  disabled = false,
  loading = false,
  icon,
  className,
  tooltip,
  tooltipPosition,
  htmlType,
  testId = 'button',
}: IButtonProps): JSX.Element => {
  const currentIcon = loading ? (
    <span className={styles.loadingWrapper}>
      <span className={styles.loadingSpinner}></span>
    </span>
  ) : (
    <span className={styles.iconWrapper}>{icon}</span>
  )

  const tooltipPositionClass =
    tooltipPosition &&
    styles[
      `tooltip${
        tooltipPosition.charAt(0).toUpperCase() + tooltipPosition.slice(1)
      }`
    ]

  return (
    <button
      data-testid={testId}
      type={htmlType}
      disabled={loading || disabled}
      className={classNames(
        styles.button,
        styles[type],
        styles[size],
        styles[iconPosition],
        className,
        tooltip && styles.tooltip,
        loading && styles.loading,
        tooltipPosition && tooltipPositionClass
      )}
      onClick={onClick}
      {...(tooltip && { 'data-hover': tooltip })}
    >
      {(icon || loading) && currentIcon}

      {subLabel ? (
        <div>
          <span className={styles.subLabel}>{label}</span>
          <span>{subLabel}</span>
        </div>
      ) : (
        <span>{label}</span>
      )}
    </button>
  )
}

export { Button }
