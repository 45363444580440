import { ColumnDef } from '@tanstack/react-table'

import {
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { isAdmin } from 'app/core/auth'
import { SectorProps } from 'app/core/types/system'

import styles from '../style.module.scss'

const farmSectorColumn = (
  handleEditClick: (id: number) => void
): ColumnDef<SectorProps, unknown>[] => [
  {
    accessorKey: 'sectors',
    header: (): JSX.Element => (
      <Typography
        text="Setores"
        variant={TypographyVariant.caption3}
        className={styles.caption}
      />
    ),
    cell: (info): JSX.Element => (
      <div>
        <Typography
          text={info.row.original.name}
          variant={TypographyVariant.h6}
        />
      </div>
    ),
  },
  {
    accessorKey: 'activity',
    header: (): JSX.Element => (
      <Typography
        text="Atividade pecuária"
        variant={TypographyVariant.caption3}
        className={styles.caption}
      />
    ),
    cell: (info): JSX.Element => (
      <div className={styles.activitiesCol}>
        {info.row.original.activities?.map((activity, i) => (
          <Chip
            key={`activity-${i}`}
            label={activity.activity}
            selected={false}
          />
        ))}
      </div>
    ),
  },
  {
    accessorKey: 'status',
    header: (): JSX.Element => (
      <Typography
        text="Status"
        variant={TypographyVariant.caption3}
        className={styles.caption}
      />
    ),
    cell: (info): JSX.Element => (
      <div className={styles.statusCol}>
        <Chip
          className={styles.chip}
          colorVariant={
            info.row.original.status
              ? ChipColorVariant.statusActive
              : ChipColorVariant.statusInactive
          }
        />
        {isAdmin() && (
          <Icon
            onClick={(): void => {
              handleEditClick(info.row.original.id)
            }}
            name={IconNames.pencil}
          />
        )}
      </div>
    ),
  },
]

export { farmSectorColumn }
