import React from 'react'

import { Chip } from 'components/atoms'

import styles from './styles.module.scss'

export interface IChipSelectProps {
  options: { label: string; value: unknown }[]
  value?: unknown[]
  onChange?: (value: unknown[]) => void
}

export const ChipsSelect = React.forwardRef<HTMLDivElement, IChipSelectProps>(
  ({ onChange, value = [], options }, ref) => {
    const getNewValues = (clickedValue: unknown): unknown[] => {
      if (value?.includes(clickedValue)) {
        return value?.filter((item: unknown): boolean => item !== clickedValue)
      }
      return value ? [...value, clickedValue] : []
    }
    return (
      <div className={styles.chipsWrapper} ref={ref}>
        {options?.map(option => (
          <Chip
            key={`${option.value}`}
            onClick={(): void => onChange?.(getNewValues(option.value))}
            label={option.label}
            className={styles.chip}
            selected={!!value?.some(item => item === option.value)}
          />
        ))}
      </div>
    )
  }
)
