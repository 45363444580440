import { useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'
import { format } from 'date-fns'

import {
  Divider,
  Icon,
  IconNames,
  Select,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { BarChart } from 'components/molecules/bar-chart'

import { useDashboard, useFarm } from 'app/core/hooks'
import { useMeasuringStation } from 'app/core/hooks/measuring-station'
import {
  CHART_COLORS,
  PERIOD_OPTIONS,
} from 'app/core/pages/dashboard/constants'
import { getPrecipitations } from 'app/core/services'
import {
  PrecipitationPeriodTypes,
  PrecipitationsFilterType,
  PrecipitationsMultiProps,
  PrecipitationsPeriodRequest,
} from 'app/core/types/dashboard'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { formatPrecipitation } from '../../helpers'
import { LoadingChart, LoadingDropdown } from '../../loading'
import styles from '../../styles.module.scss'

const renderTooltip = ({
  payload,
}: {
  payload?: {
    color?: string
    value?: number
    name?: string
    payload?: {
      stacks: Record<
        string,
        {
          label: string
          payload: {
            mean: number
          }
        }
      >
    }
  }[]
}): React.ReactNode => {
  const basePayload = payload?.[0]?.payload
  if (!basePayload) {
    return null
  }
  const hoveredDay = Object.values(basePayload.stacks)?.map(stack => {
    const farmName = stack.label
    const stackPayloadItem = payload?.find(
      payloadItem => payloadItem.name === `stacks.${farmName}.payload.mean`
    )
    return {
      color: stackPayloadItem?.color,
      name: farmName,
      value: stackPayloadItem?.value,
    }
  })
  return (
    <div className={styles.tooltip}>
      {hoveredDay.map((day, index) => (
        <div key={index} className={styles.tooltipRow}>
          <div
            className={styles.tooltipBox}
            style={{ background: day?.color }}
          />
          <div className={styles.tooltipTextContainer}>
            <span className={styles.tooltipText}>{day?.name}</span>
            <span
              className={classNames(
                styles.tooltipText,
                styles.tooltipTextHighlight
              )}
            >
              {formatPrecipitation(Number(day?.value) || 0)}
            </span>
          </div>
        </div>
      ))}
      <Divider className={styles.tooltipDivider} />
      <div className={styles.tooltipRow}>
        <div className={styles.tooltipBox} style={{ background: '#606062' }} />
        <div className={styles.tooltipTextContainer}>
          <span className={styles.tooltipText}>Total</span>
          <span
            className={classNames(
              styles.tooltipText,
              styles.tooltipTextHighlight
            )}
          >
            {formatPrecipitation(
              hoveredDay.reduce((sum, day) => sum + (day?.value ?? 0), 0)
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

type PrecipitationTotalCardProps = {
  stations: SelectItemProps[]
}

type PeriodProps = {
  startDate: Date | null
  endDate: Date | null
}

const PrecipitationTotalCard: React.FC<PrecipitationTotalCardProps> = ({
  stations,
}) => {
  const inputDateFormat = 'yyyy-MM-dd'
  const defaultPeriod = PrecipitationPeriodTypes.last_twelve_months

  const { formatAllStationsPrecipitations, getPrecipitationsPayloadFormat } =
    useDashboard()
  const { activeFarmsDroplist: farms } = useFarm()
  const { measuringStations } = useMeasuringStation()

  const [data, setData] = useState<PrecipitationsMultiProps[]>([])
  const [periodType, setPeriodType] =
    useState<PrecipitationPeriodTypes>(defaultPeriod)
  const [filterType, setFilterType] = useState<PrecipitationsFilterType>(
    PrecipitationsFilterType.station
  )
  const [period, setPeriod] = useState<PeriodProps>({
    startDate: null,
    endDate: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [activeStations, setActiveStations] =
    useState<SelectItemProps[]>(stations)
  const [activeFarm, setActiveFarm] = useState<SelectItemProps>()

  const payloadKeys: string[] = ['mean']

  const stackKeys =
    filterType === PrecipitationsFilterType.station
      ? activeStations.map(station => station.label)
      : measuringStations
          .filter(station => station.farm?.id === Number(activeFarm?.value))
          .map(station => station.name)

  const getData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { startDate, endDate, chartType } =
        getPrecipitationsPayloadFormat(periodType)

      setPeriod(prevState => ({
        ...prevState,
        startDate,
        endDate,
      }))

      const payload = {
        start_date: format(startDate, inputDateFormat),
        end_date: format(endDate, inputDateFormat),
        ...(filterType === PrecipitationsFilterType.station && {
          station_ids: activeStations.map(station => Number(station.value)),
        }),
        ...(filterType === PrecipitationsFilterType.farm && {
          farm_id: Number(activeFarm?.value),
        }),
      } as PrecipitationsPeriodRequest

      const precipitations = await getPrecipitations(payload)

      const formattedPrecipitations = formatAllStationsPrecipitations(
        precipitations,
        chartType,
        stations
      )

      setData(formattedPrecipitations)

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }, [
    activeFarm,
    activeStations,
    filterType,
    formatAllStationsPrecipitations,
    getPrecipitationsPayloadFormat,
    periodType,
    stations,
  ])

  useEffect(() => {
    getData()
  }, [filterType, getData])

  const handleFilter = (
    type: PrecipitationsFilterType,
    selectedItems: SelectItemProps | SelectItemProps[]
  ): void => {
    if (type === PrecipitationsFilterType.farm && selectedItems) {
      setFilterType(PrecipitationsFilterType.farm)
      setActiveFarm(selectedItems as SelectItemProps)
      setActiveStations([])
    }

    if (type === PrecipitationsFilterType.station && selectedItems) {
      setFilterType(PrecipitationsFilterType.station)
      setActiveStations(selectedItems as SelectItemProps[])
      setActiveFarm(undefined)
    }
  }

  return (
    <>
      <div className={styles.card}>
        <div className={styles.chartHeader}>
          <div>
            <Typography
              text="Média de precipitações"
              variant={TypographyVariant.h5}
            />
            {period.startDate && period.endDate && (
              <>
                <span className={styles.chartHeaderInfo}>
                  Período: {format(period.startDate, 'dd/MM/yyyy')} à{' '}
                  {format(period.endDate, 'dd/MM/yyyy')}
                </span>
              </>
            )}
          </div>
          <div className={styles.fields}>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>Período</span>
              {isLoading ? (
                <LoadingDropdown />
              ) : (
                <div className={styles.fieldWrapper}>
                  <Icon
                    className={styles.fieldIcon}
                    name={IconNames.calendar}
                  />
                  <Select
                    name="period"
                    centerText={false}
                    options={PERIOD_OPTIONS}
                    onChange={(value): void =>
                      setPeriodType(
                        (value as SelectItemProps)
                          .value as PrecipitationPeriodTypes
                      )
                    }
                    height="40px"
                    valueMargin="0 0 0 30px"
                    defaultValue={PERIOD_OPTIONS.find(
                      period => period.value === periodType
                    )}
                  />
                </div>
              )}
            </div>
            <div className={styles.field}>
              <span className={styles.fieldLabel}>Fazenda</span>
              {isLoading ? (
                <LoadingDropdown />
              ) : (
                <div className={styles.fieldWrapper}>
                  <Icon
                    className={styles.fieldIcon}
                    name={IconNames.information}
                  />
                  <Select
                    defaultValue={activeFarm}
                    name="farms"
                    centerText={false}
                    options={farms}
                    onChange={(value): void =>
                      handleFilter(
                        PrecipitationsFilterType.farm,
                        value as SelectItemProps[]
                      )
                    }
                    height="40px"
                    valueMargin="0 0 0 30px"
                  />
                </div>
              )}
            </div>
            <div className={classNames(styles.field, styles.multipleStations)}>
              <span className={styles.fieldLabel}>Estações</span>
              {isLoading ? (
                <LoadingDropdown />
              ) : (
                <div className={styles.fieldWrapper}>
                  <Icon
                    className={styles.fieldIcon}
                    name={IconNames.information}
                  />
                  <Select
                    defaultValue={activeStations}
                    name="stations"
                    centerText={false}
                    options={stations}
                    onChange={(value): void =>
                      handleFilter(
                        PrecipitationsFilterType.station,
                        value as SelectItemProps[]
                      )
                    }
                    height="40px"
                    valueMargin="0 0 0 30px"
                    isMulti
                    value={activeStations}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {isLoading ? (
          <LoadingChart />
        ) : (
          <BarChart
            cartesianGridProps={{ vertical: true }}
            containerProps={{
              width: '100%',
              height: 450,
              className: styles.container,
            }}
            xAxisProps={{
              dy: 15,
            }}
            yAxisProps={{
              label: {
                value: 'Precipitações (mm)',
                fill: '#bbb',
                angle: -90,
                position: 'insideCenter',
                dx: -35,
              },
            }}
            barChartProps={{
              margin: { left: 30, bottom: 100 },
              barGap: 0,
              barSize: 12,
            }}
            payloadKeys={payloadKeys}
            stackKeys={stackKeys}
            data={data}
            showStackLabel={false}
            colors={CHART_COLORS}
            legendProps={{
              wrapperStyle: {
                display: 'flex',
                bottom: 50,
              },
            }}
            showContentLabel={false}
            colorByPayload={false}
            tooltipProps={{
              cursor: {
                fill: 'rgba(160, 160, 160, 0.3)',
              },
              content: renderTooltip,
            }}
          />
        )}
      </div>
    </>
  )
}

export { PrecipitationTotalCard }
