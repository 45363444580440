import { useCallback, useEffect, useState } from 'react'

import {
  getAgeGroups as getAgeGroupsService,
  patchAgeGroup,
  postAgeGroup,
  deleteAgeGroup as deleteAgeGroupService,
} from 'app/core/services'
import { AgeGroup, AgeGroupHook } from 'app/core/types/age-group'
import { addToast } from 'app/core/utils'

const useAgeGroups = (): AgeGroupHook => {
  const [ageGroups, setAgeGroups] = useState<AgeGroup[]>([])

  const getAgeGroups = useCallback(async (): Promise<AgeGroup[]> => {
    try {
      const data = await getAgeGroupsService()
      setAgeGroups(data)
      return data
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }, [])

  const createAgeGroup = useCallback(
    async (
      payload: Pick<AgeGroup, 'name' | 'finish_day' | 'start_day'>
    ): Promise<AgeGroup> => {
      try {
        return await postAgeGroup(payload)
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    []
  )

  const updateAgeGroup = useCallback(
    async (
      id: number,
      payload: Pick<AgeGroup, 'name' | 'finish_day' | 'start_day'>
    ): Promise<AgeGroup> => {
      try {
        return await patchAgeGroup(id, payload)
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    []
  )

  const deleteAgeGroup = useCallback(async (id: number): Promise<AgeGroup> => {
    try {
      return await deleteAgeGroupService(id)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }, [])

  useEffect(() => {
    getAgeGroups()
  }, [getAgeGroups])

  return {
    getAgeGroups,
    ageGroups,
    createAgeGroup,
    deleteAgeGroup,
    updateAgeGroup,
  }
}

export { useAgeGroups }
