import React, { Dispatch, SetStateAction } from 'react'

import classNames from 'classnames'

import {
  Button,
  ButtonSize,
  ButtonType,
  IButtonProps,
  Icon,
  IconNames,
  QuantityBadge,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { Pagination } from 'components/molecules'

import { Messages } from 'config/messages'

import { CustomTable, CustomTableProps } from '../custom-table'
import styles from './styles.module.scss'

export type BoxedTableProps = Pick<CustomTableProps, 'table'> & {
  className?: string
  title?: string
  data: Record<string | number, unknown>[]
  quantityLabel?: string
  noDataMessage?: string
  mainButtonLabel?: string
  mainButtonIcon?: IconNames
  mainButtonAction?: () => void
  mainButtonProps?: IButtonProps
  secondaryButtonLabel?: string
  secondaryButtonIcon?: IconNames
  secondaryButtonAction?: () => void
  secondaryButtonClassName?: string
  isTableHeaderSticky?: boolean
  disableNavigation?: boolean
  disableLastCellNavigation?: boolean
  disableCellsNavigationByIndex?: number[]
  disableHeader?: boolean
  hideQuantityLabel?: boolean
  currentPage?: number
  totalPages?: number
  totalItems?: number
  setPage?: Dispatch<SetStateAction<number>>
  disablePaginationScrollTop?: boolean
  isLoading?: boolean
}

const BoxedTable: React.FC<BoxedTableProps> = ({
  className,
  title,
  data,
  table,
  isTableHeaderSticky,
  disableNavigation,
  disableLastCellNavigation,
  disableCellsNavigationByIndex,
  disableHeader,
  quantityLabel = 'Animal',
  noDataMessage = Messages.EMPTY_DATA,
  mainButtonLabel,
  mainButtonIcon,
  mainButtonAction,
  mainButtonProps,
  secondaryButtonLabel,
  secondaryButtonIcon,
  secondaryButtonAction,
  secondaryButtonClassName,
  hideQuantityLabel,
  currentPage,
  totalPages,
  totalItems,
  setPage,
  disablePaginationScrollTop,
  isLoading,
}) => {
  const stickyStyle = isTableHeaderSticky ? styles.sticky : ''
  const isPaginated = currentPage && setPage && totalPages && totalPages > 1

  return (
    <div
      className={classNames(
        styles.boxedTableWrapper,
        isPaginated ? styles.boxedTablePaginated : '',
        stickyStyle,
        className
      )}
    >
      <div
        className={
          title || !hideQuantityLabel || secondaryButtonLabel || mainButtonLabel
            ? styles.boxedTableTitle
            : ''
        }
      >
        <span>
          {title && <Typography text={title} variant={TypographyVariant.h5} />}

          {!!data.length && !hideQuantityLabel && (
            <QuantityBadge
              count={totalItems ? totalItems : data.length}
              label={quantityLabel}
            />
          )}
        </span>

        <span>
          {secondaryButtonLabel && (
            <Button
              label={secondaryButtonLabel}
              type={ButtonType.ghost}
              onClick={secondaryButtonAction}
              icon={<Icon name={secondaryButtonIcon} />}
              size={ButtonSize.small}
              className={secondaryButtonClassName}
            />
          )}
          {mainButtonLabel && (
            <Button
              label={mainButtonLabel}
              type={ButtonType.outline}
              onClick={mainButtonAction}
              icon={<Icon name={mainButtonIcon} />}
              {...mainButtonProps}
            />
          )}
        </span>
      </div>

      <div className={styles.tableWrapper}>
        <CustomTable
          table={table}
          disableNavigation={disableNavigation}
          disableCellsNavigationByIndex={disableCellsNavigationByIndex}
          disableLastCellNavigation={disableLastCellNavigation}
          disableHeader={disableHeader}
          isLoading={isLoading}
          noDataMessage={noDataMessage}
        />
      </div>

      {!!currentPage && !!setPage && !!totalPages && totalPages > 1 && (
        <div className={styles.paginationContainer}>
          <Pagination
            pages={totalPages}
            page={currentPage}
            setPage={setPage}
            disableScrollTop={disablePaginationScrollTop}
          />
        </div>
      )}
    </div>
  )
}

export { BoxedTable }
