import { addHours, format, formatISO, parse, parseISO, set } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const ptBrDateFormat = 'dd/MM/yyyy'
const ptBrDateFormatWithTime = 'dd/MM/yyyy HH:mm'
const enUsDateFormat = 'yyyy-MM-dd'

const monthName = (monthNumber: number, fullName?: boolean): string =>
  format(new Date(2000, monthNumber - 1), fullName ? 'MMMM' : 'MMM', {
    locale: ptBR,
  })

const dateTimeFormat = (
  dateTime: string,
  useAlternateFormatting = false
): string => {
  const dateWithoutTimeZone = dateTime.split('T')[0]
  const parsedDate = parseISO(dateWithoutTimeZone)

  return format(
    parsedDate,
    !useAlternateFormatting ? ptBrDateFormat : enUsDateFormat
  )
}

const dateTimeFormatWithTime = (dateTime: string): string =>
  format(parseISO(dateTime), ptBrDateFormatWithTime)

const dateTimeXslxFormat = (dateTime: string): Date => {
  const dateWithoutTimeZone = dateTime.split('T')[0]

  // workaround to make the hours show correctly on the generated xslx
  const parsedDate = addHours(parseISO(dateWithoutTimeZone), 1)

  return parsedDate
}

const dateShortMonthYearFormat = (dateString: string): string => {
  const monthShortFormatter = new Intl.DateTimeFormat('pt-BR', {
    month: 'short',
  })
  return `${monthShortFormatter
    .format(new Date(dateString))
    .substring(0, 3)}/${dateString.substring(2, 4)}`.toUpperCase()
}

const dateTimeISOFormat = (datetime: string): string => {
  const date = new Date(datetime)
  return date.toISOString()
}

const parseXlsxDate = (date: string): string => {
  if (typeof date === 'string' && date.includes('/')) {
    const [day, month, year] = date.split('/')
    return dateTimeISOFormat(`${new Date(`${year}-${month}-${day}`)}`)
  }

  return new Date(date).toISOString()
}

const parseMinutesToHours = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  return `${formattedHours}:${formattedMinutes}`
}

const parseServiceOrderDuration = (duration: number): string => {
  const hours = parseMinutesToHours(duration).split(':')
  return `${hours[0]}h ${hours[1]}min`
}

const parseInputDateToISO = (date: string): string =>
  formatISO(parse(date, enUsDateFormat, new Date()))

const dateToday = new Date().toISOString().split('T')[0]

const formattedTodayDate = format(new Date(), ptBrDateFormat)

const dateForFileName = (): string => format(new Date(), 'dd-MM-yyyy')

const parseDateToISO = (date: string): string => {
  const inputDate = parseISO(date)
  const currentTime = new Date()

  const updatedDate = set(inputDate, {
    hours: currentTime.getHours(),
    minutes: currentTime.getMinutes(),
    seconds: currentTime.getSeconds(),
    milliseconds: currentTime.getMilliseconds(),
  })

  return formatISO(updatedDate)
}

export {
  dateForFileName,
  dateShortMonthYearFormat,
  dateTimeFormat,
  dateTimeFormatWithTime,
  dateTimeISOFormat,
  dateTimeXslxFormat,
  dateToday,
  formattedTodayDate,
  monthName,
  parseDateToISO,
  parseInputDateToISO,
  parseMinutesToHours,
  parseServiceOrderDuration,
  parseXlsxDate,
}
