import { ColumnDef } from '@tanstack/react-table'

import { InputText } from 'components/atoms'
import { RemoveButton } from 'components/molecules'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { ElectronicEartagCreateProps } from 'app/core/types/animal'

import styles from './styles.module.scss'

const electronicEartagCreateColumns = (
  handleRemoveAnimal: (index: number) => void,
  onChangeRow: (index: number, field: string, value: unknown) => void
): ColumnDef<ElectronicEartagCreateProps, unknown>[] => [
  {
    header: '#',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <span className={styles.orderCell}>{info.row.index + 1}</span>
    ),
  },
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Nascimento',
    accessorKey: 'birth_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Novo Nº de Brinco Eletrônico',
    accessorKey: 'new_electronic_eartag',
    cell: (info): JSX.Element => (
      <InputText
        name={`new_electronic_eartag${info.row.index}`}
        htmlType="number"
        onChange={(e): void =>
          onChangeRow(
            info.row.index,
            'new_electronic_eartag',
            Number(e.target.value)
          )
        }
        placeholder="Insira um novo brinco eletrônico"
        defaultValue={info.getValue() as string}
      />
    ),
  },
  {
    id: 'delete',
    cell: (info): JSX.Element => (
      <RemoveButton
        itemId={info.row.original.id as number}
        handleRemoveItem={(): void =>
          handleRemoveAnimal(info.row.original.id as number)
        }
      />
    ),
  },
]

export { electronicEartagCreateColumns }
