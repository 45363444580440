import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { formatISO } from 'date-fns'
import { useAuthUser } from 'providers/auth-user'
import { useServiceOrder } from 'providers/service-orders'
import { useServiceOrderFilters } from 'providers/service-orders/filters'
import * as yup from 'yup'

import { useForm } from 'components/organisms'

import { useClassification, useCostCenter } from 'app/core/hooks'
import { ServiceOrderInfoProps } from 'app/core/types/service-order'
import { ServiceType } from 'app/core/types/system'
import { Messages } from 'config/messages'

import { serviceOrderListColumns } from './tables'
import { ServiceOrdersReviewTemplate } from './template'

const ServiceOrdersReview: React.FC = () => {
  const { selectedFarm } = useAuthUser()
  const {
    listServiceOrders,
    updateServiceOrder,
    serviceOrderPageInfo,
    serviceOrders,
    setFilters,
    isLoading,
  } = useServiceOrder()
  const {
    containsName,
    formattedDate,
    mechanizationCheckbox,
    generalServiceCheckbox,
  } = useServiceOrderFilters()

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [page, setPage] = useState<number>(1)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [activeServiceOrder, setActiveServiceOrder] =
    useState<ServiceOrderInfoProps>()

  const data = useMemo(() => serviceOrders || [], [serviceOrders])

  const getServiceType = useCallback((): ServiceType | null | undefined => {
    const isMechanization = mechanizationCheckbox === 'true'
    const isGeneralService = generalServiceCheckbox === 'true'

    if (isMechanization && isGeneralService) return
    if (isMechanization) return ServiceType.mechanization
    if (isGeneralService) return ServiceType.generalService

    return null
  }, [generalServiceCheckbox, mechanizationCheckbox])

  const handleModal = (): void => {
    setIsModalOpen(prevState => !prevState)
  }

  const table = useReactTable({
    data,
    columns: serviceOrderListColumns(handleModal, setActiveServiceOrder),
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  const filters = useMemo(() => {
    return {
      init_date: formatISO(formattedDate.currentDate, {
        representation: 'date',
      }),
      final_date: formatISO(formattedDate.finalDate, {
        representation: 'date',
      }),
      service_type: getServiceType(),
      contains_name: containsName,
      page,
    }
  }, [containsName, formattedDate, getServiceType, page])

  const handleModalConfirm = useCallback(
    async (data: Record<string, unknown>) => {
      setIsModalOpen(false)

      if (activeServiceOrder) {
        const { cost_center_id, classification_id } = data

        await updateServiceOrder({
          serviceOrderId: activeServiceOrder?.id,
          payload: {
            cost_center_id: Number(cost_center_id),
            classification_id: Number(classification_id),
          },
        })
      }
    },
    [activeServiceOrder, updateServiceOrder]
  )

  const validationSchema = yup.object({
    classification_id: yup
      .string()
      .required(Messages.YUP_REQUIRED_FIELD)
      .typeError(Messages.YUP_REQUIRED_FIELD),
    cost_center_id: yup
      .string()
      .required(Messages.YUP_REQUIRED_FIELD)
      .typeError(Messages.YUP_REQUIRED_FIELD),
  })

  const { Form, control, formState, handleSubmit, setValue, watch } = useForm({
    onSubmit: handleModalConfirm,
    validationSchema,
  })

  const costCenterId = watch('cost_center_id') as number
  const classificationId = watch('classification_id') as number

  const { costCenters } = useCostCenter(selectedFarm?.id)
  const { classifications } = useClassification({
    costCenterId:
      costCenters?.find(costCenter => costCenter.id === costCenterId)
        ?.parent_cost_center_id || undefined,
    farmId: Number(selectedFarm?.id),
  })

  const costCentersList = useMemo(() => costCenters, [costCenters])
  const classificationsList = useMemo(() => classifications, [classifications])

  const costCenterSelected = costCentersList?.find(
    costCenter => costCenter.id === costCenterId
  )?.name
  const classificationSelected = classificationsList?.find(
    classification => classification.id === classificationId
  )?.name

  useEffect(() => {
    if (filters)
      setFilters(prevState => ({
        ...prevState,
        ...filters,
      }))
  }, [filters, setFilters])

  useEffect(() => {
    listServiceOrders()
  }, [listServiceOrders])

  useEffect(() => {
    if (activeServiceOrder?.cost_center_id)
      setValue('cost_center_id', activeServiceOrder.cost_center_id)
    if (activeServiceOrder?.classification_id)
      setValue('classification_id', activeServiceOrder.classification_id)
  }, [
    activeServiceOrder?.cost_center_id,
    activeServiceOrder?.classification_id,
    setValue,
  ])

  return (
    <ServiceOrdersReviewTemplate
      activeFilters={filters}
      control={control}
      columnFilters={columnFilters}
      costCenters={costCentersList}
      classifications={classificationsList}
      costCenterId={costCenterId}
      classificationId={classificationId}
      costCenterSelected={costCenterSelected}
      classificationSelected={classificationSelected}
      form={Form}
      formState={formState}
      handleModal={handleModal}
      handleSubmit={handleSubmit}
      handleModalConfirm={handleModalConfirm}
      table={table}
      serviceOrders={serviceOrders}
      serviceOrderPageInfo={serviceOrderPageInfo}
      setPage={setPage}
      setValue={setValue}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
    />
  )
}

export { ServiceOrdersReview }
