import { ColumnDef } from '@tanstack/react-table'

import { Chip, ChipColorVariant } from 'components/atoms'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { AnimalSexLabel } from 'app/core/types/animal'
import { CollectiveMovementsCreateProps } from 'app/core/types/collective-movements'

import styles from '../create/styles.module.scss'

type AnimalColumnsProps = {
  originFarm: string | undefined
  destinationFarm: string | undefined
  destinationSector: string | undefined
}

const collectiveMovementsListColumns = ({
  originFarm,
  destinationFarm,
  destinationSector,
}: AnimalColumnsProps): ColumnDef<
  CollectiveMovementsCreateProps,
  unknown
>[] => [
  {
    header: '#',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <span className={styles.orderCell}>{info.row.index + 1}</span>
    ),
  },
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Nascimento',
    accessorKey: 'birth_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Sexo',
    accessorKey: 'sex',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      if (!value) return <span>-</span>
      return (
        <Chip
          colorVariant={
            value === AnimalSexLabel.female
              ? ChipColorVariant.sexFemale
              : ChipColorVariant.sexMale
          }
        />
      )
    },
  },
  {
    header: 'Fazenda',
    accessorKey: 'originFarm',
    cell: (): JSX.Element => <Chip label={originFarm} selected={false} />,
  },
  {
    header: 'Setor',
    accessorKey: 'sector_name',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      if (!value) return <span>-</span>
      return <Chip label={value as string} selected={false} />
    },
  },
  {
    header: 'Fazenda',
    accessorKey: 'destinationFarm',
    cell: (): JSX.Element => <Chip label={destinationFarm} />,
  },
  {
    header: 'Setor',
    accessorKey: 'destinationSector',
    cell: (): JSX.Element => <Chip label={destinationSector} />,
  },
]

export { collectiveMovementsListColumns }
