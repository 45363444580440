import { ServiceOrderCount } from './count'
import { ServiceOrderDateFilter } from './date'
import { ServiceOrderFiltersRoot } from './root'
import { ServiceOrderServiceTypeFilter } from './service-type'
import { ServiceOrderTextFilter } from './text'

export const ServiceOrderFilters = {
  Root: ServiceOrderFiltersRoot,
  Count: ServiceOrderCount,
  Date: ServiceOrderDateFilter,
  ServiceType: ServiceOrderServiceTypeFilter,
  Text: ServiceOrderTextFilter,
}
