import React from 'react'

import classNames from 'classnames'

import { Status as InputStatus } from 'components/enums/status'
import { ITextarea } from 'components/types/textarea'

import styles from './styles.module.scss'

export interface ITextareaProps extends ITextarea {
  status?: InputStatus
  placeholder?: string
  defaultValue?: string
  value?: string
  containerClassName?: string
  minLength?: number
  maxLength?: number
}

const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(
  (
    {
      name,
      onChange,
      onBlur,
      disabled = false,
      className,
      status = 'default',
      id,
      placeholder,
      defaultValue,
      value,
      containerClassName,
      minLength,
      maxLength,
      ...restProps
    },
    ref
  ): JSX.Element => {
    return (
      <div
        className={classNames(
          styles.textareaContainer,
          { [styles.disabled]: disabled },
          styles[status],
          containerClassName
        )}
      >
        <textarea
          id={id ?? name}
          className={classNames(styles.textarea, className)}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          {...restProps}
          ref={ref}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          minLength={minLength}
          maxLength={maxLength}
        ></textarea>
      </div>
    )
  }
)

export { Textarea }
