import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { Button, ButtonSize, ButtonType } from 'components/atoms'
import { CustomModal, FormCol } from 'components/molecules'
import { useForm } from 'components/organisms'
import { LoginLayout } from 'components/templates'

import { NavigateList } from 'app/core/routes/routes'
import { StorageKeys } from 'app/core/types/storage'
import { IUserPassword } from 'app/core/types/user'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'
import { http } from 'interfaces/http'

const RedefinePassword: React.FC = () => {
  const history = useHistory()
  const navigateTo = (): void => history.push(NavigateList.dashboard)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const userId = urlParams.get('id')
  const accessToken = urlParams.get('access_token')
  const firstAccess = urlParams.get('first_access')

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const validationSchema = yup.object({
    new_password: yup
      .string()
      .trim()
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z\d]{8,16}$/,
        Messages.YUP_PASSWORD_INVALID_CRITERIA
      )
      .required(Messages.YUP_REQUIRED_FIELD),
    confirm_password: yup
      .string()
      .oneOf(
        [yup.ref('new_password'), null],
        Messages.YUP_PASSWORD_INVALID_MATCH
      )
      .required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleRedefinePassword = async (data: unknown): Promise<void> => {
    const { new_password: newPassword, confirm_password: confirmPassword } =
      data as IUserPassword

    await http
      .post(
        `/auth/users/${userId}/redefine-password`,
        {
          new_password: newPassword,
          confirm_password: confirmPassword,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(() => {
        localStorage.setItem(StorageKeys.auth_token, accessToken as string)
        setModalIsOpen(true)
      })
      .catch(e => {
        addToast({ message: e.message })
      })
  }

  const { Form } = useForm({
    onSubmit: handleRedefinePassword,
    validationSchema,
  })

  return (
    <LoginLayout
      header={firstAccess ? 'Primeiro acesso' : 'Redefinir senha'}
      subheader={`${
        firstAccess
          ? Messages.SCREEN_FIRST_ACCESS
          : Messages.SCREEN_REDEFINE_PASSWORD
      }\n\n${Messages.PASSWORD_CRITERIA}`}
    >
      <Form>
        <FormCol>
          <div>
            <Form.InputText
              label="Nova senha"
              name="new_password"
              placeholder="Crie uma senha"
              htmlType="password"
            />
            <Form.InputText
              label="Confirma senha"
              name="confirm_password"
              placeholder="Confirme a senha"
              htmlType="password"
            />
          </div>

          <Button
            type={ButtonType.primary}
            label="Criar Nova Senha"
            size={ButtonSize.large}
          />
        </FormCol>
      </Form>

      <CustomModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={navigateTo}
        modalHeader="Senha atualizada"
        modalText={`Sua senha foi atualizada com sucesso!`}
        primaryButtonLabel="Entrar no Sistema"
        primaryButtonAction={navigateTo}
      />
    </LoginLayout>
  )
}

export { RedefinePassword }
