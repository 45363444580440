import { useCallback, useEffect, useState } from 'react'

import {
  getReadReproductionParams,
  patchUpdateReproductionParams,
} from 'app/core/services'
import { ReproductionParamsHook } from 'app/core/types/hooks'
import { ReproductionParamsProps } from 'app/core/types/system'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

const useReproductionParams = (): ReproductionParamsHook => {
  const [params, setParams] = useState<ReproductionParamsProps>()

  const readAllReproductionParams = useCallback(async (): Promise<void> => {
    try {
      const data = await getReadReproductionParams()
      setParams(data)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }, [])

  const updateParams = useCallback(
    async (request: Partial<ReproductionParamsProps>): Promise<void> => {
      if (request) {
        try {
          await patchUpdateReproductionParams(request)
          addToast({
            message: Messages.SYSTEM_UPDATE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          const message = (e as Error).message
          addToast({ message })
          throw new Error(message)
        }
      }
    },
    []
  )

  useEffect(() => {
    readAllReproductionParams()
  }, [readAllReproductionParams])

  return {
    params,
    updateParams,
  }
}

export { useReproductionParams }
