import { ChangeEvent, useEffect, useRef } from 'react'
import { GroupTypeBase } from 'react-select'

import {
  Chip,
  ChipColorVariant,
  IRadioButtonOption,
  RadioButton,
  SelectItemProps,
} from 'components/atoms'

import styles from './styles.module.scss'

type DurationSuggestionsProps = {
  durationTime: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  suggestions: string[]
}

type FarmChipProps = {
  itemId: string
  itemEntity?: { id: string | number; farm_name: string }[]
}

type GroupLabelProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  group: GroupTypeBase<any>
}

type OptionProps = {
  option: SelectItemProps
}

const DurationSuggestions: React.FC<DurationSuggestionsProps> = ({
  durationTime,
  onChange,
  suggestions,
}) => {
  const radioButtonsRef = useRef<HTMLDivElement>(null)

  const options: IRadioButtonOption[] = suggestions.map(suggestion => ({
    label: suggestion,
    value: suggestion,
  }))

  useEffect(() => {
    if (!suggestions.includes(durationTime)) {
      if (radioButtonsRef.current) {
        const radioButtons = radioButtonsRef.current.querySelectorAll(
          'input[type="radio"]'
        )
        radioButtons.forEach(
          (radioButton: Element) =>
            ((radioButton as HTMLInputElement).checked = false)
        )
      }
    }
  }, [durationTime, suggestions])

  return (
    <div ref={radioButtonsRef}>
      <RadioButton
        buttonMode
        defaultChecked={options.find(option => option.value === durationTime)}
        options={options}
        onChange={onChange}
        name="durationSuggestions"
        title="Duração"
      />
    </div>
  )
}

const FarmChip: React.FC<FarmChipProps> = ({ itemId, itemEntity }) => {
  if (!itemEntity) return <></>

  const farm = itemEntity.find(item => item.id.toString() === itemId)
  return (
    <Chip
      label={`Fazenda ${farm?.farm_name}`}
      colorVariant={ChipColorVariant.secondary}
    />
  )
}

const ClassificationOption: React.FC<OptionProps> = ({ option }) => {
  const { label, subLabel } = option

  const CHIP_CLASSIFICATION: Record<string, ChipColorVariant> = {
    cost: ChipColorVariant.cost,
    invest: ChipColorVariant.invest,
  }

  return (
    <div className={styles.formatOptionWithChip}>
      <div>{label}</div>
      <div className={styles.formatOptionWithChipSubLabel}>
        {subLabel}
        <Chip
          colorVariant={
            subLabel?.charAt(0) == '7'
              ? CHIP_CLASSIFICATION['invest']
              : CHIP_CLASSIFICATION['cost']
          }
        />
      </div>
    </div>
  )
}

const ServiceOption: React.FC<OptionProps> = ({ option }) => {
  const { label, subLabel } = option

  return (
    <div className={styles.formatOption}>
      <div>{label}</div>
      {subLabel !== 'null' && (
        <div className={styles.formatOptionSubLabel}>{subLabel}</div>
      )}
    </div>
  )
}

const GroupLabel: React.FC<GroupLabelProps> = ({ group }) => (
  <div className={styles.groupLabel}>
    <span>{group.label}</span>
  </div>
)

export {
  ClassificationOption,
  DurationSuggestions,
  FarmChip,
  GroupLabel,
  ServiceOption,
}
