import Skeleton from 'react-loading-skeleton'

import styles from './styles.module.scss'

const loadingCardsQuantity = 10
const loadingSkeletonColumns = 4

const ServiceOrdersLoadingTemplate: JSX.Element = (
  <div className={styles.serviceOrdersLoading}>
    {[...Array(loadingCardsQuantity)].map((_, cardIndex) => (
      <div key={cardIndex} className={styles.loadingCard}>
        {[...Array(loadingSkeletonColumns)].map((_, skeletonIndex) => (
          <div key={skeletonIndex}>
            <Skeleton height={25} />
            <Skeleton height={25} />
          </div>
        ))}
      </div>
    ))}
  </div>
)

export { ServiceOrdersLoadingTemplate }
