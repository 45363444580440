import {
  ProtocolActionProps,
  ProtocolProps,
  ProtocolReadResponseData,
} from 'app/core/types/hormonal'
import { http } from 'interfaces/http'

const baseUrl = '/hormonal/protocol/'

const getReadAllProtocols = async (
  initDate: string,
  finalDate: string,
  page: number,
  size: number
): Promise<ProtocolReadResponseData> => {
  const params = new URLSearchParams([
    ...(initDate ? [['init_date', initDate]] : []),
    ...(finalDate ? [['final_date', finalDate]] : []),
    ...(page ? [['page', page.toString()]] : []),
    ...(size ? [['size', size.toString()]] : []),
  ])

  const url = `${baseUrl}?${params}`

  const response = await http.get(url)
  return response.data
}

const getReadProtocol = async (
  protocolId: string | number
): Promise<ProtocolProps> => {
  const url = `${baseUrl}${protocolId}/`

  const response = await http.get(url)
  return response.data
}

const postCreateProtocol = async (name: string): Promise<ProtocolProps> => {
  const response = await http.post(baseUrl, { name })
  return response.data
}

const postCreateProtocolAction = async (
  protocolId: string | number,
  action: Omit<ProtocolActionProps, 'id'>
): Promise<ProtocolProps> => {
  const url = `${baseUrl}${protocolId}/action/`

  const response = await http.post(url, action)
  return response.data
}

const postDeleteProtocolAction = async (
  protocolId: string | number,
  actionId: string | number
): Promise<ProtocolProps> => {
  const url = `${baseUrl}${protocolId}/remove_action/`

  const response = await http.post(url, { hormonal_action_id: actionId })
  return response.data
}

export {
  getReadAllProtocols,
  getReadProtocol,
  postCreateProtocol,
  postCreateProtocolAction,
  postDeleteProtocolAction,
}
