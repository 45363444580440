import { AgeGroupCard } from './components/cards/age-groups'
import { BreedCard } from './components/cards/breed'
import { FarmCard } from './components/cards/farm'
import { InseminatorsCard } from './components/cards/inseminators'
import { ProductCard } from './components/cards/product'
import { ReproductionCard } from './components/cards/reproduction'
import { ShutdownCard } from './components/cards/shutdown'
import { BoxedLayout } from 'components/templates'

import { AgeGroupHook } from 'app/core/types/age-group'
import {
  BreedHook,
  FarmParametersHook,
  InseminatorsHook,
  ProductHook,
  ReproductionParamsHook,
} from 'app/core/types/hooks'
import { ShutdownReasonHook } from 'app/core/types/shutdown'

type SettingsTemplateProps = {
  shutdownHookProps: ShutdownReasonHook
  reproductionHookProps: ReproductionParamsHook
  ageGroupHookProps: AgeGroupHook
  breedHookProps: BreedHook
  productHookProps: ProductHook
  farmParametersHook: FarmParametersHook
  inseminatorsHook: InseminatorsHook
}

const SettingsTemplate: React.FC<SettingsTemplateProps> = ({
  ageGroupHookProps,
  shutdownHookProps,
  reproductionHookProps,
  breedHookProps,
  productHookProps,
  farmParametersHook,
  inseminatorsHook,
}) => {
  const {
    deathReasons,
    otherReasons,
    updateShutdownReason,
    deleteShutdownReason,
    createShutdownReason,
  } = shutdownHookProps
  const { params: reproductionParams, updateParams: updateReproductionParams } =
    reproductionHookProps
  const {
    getAgeGroups,
    ageGroups,
    deleteAgeGroup,
    createAgeGroup,
    updateAgeGroup,
  } = ageGroupHookProps
  const { addBreed, breeds, updateBreed, deleteBreed, readAllBreeds } =
    breedHookProps
  const {
    readAllProducts,
    deleteProduct,
    createProduct,
    updateProduct,
    products,
  } = productHookProps
  const { readFarmParameters, farmParameters, updateFarmParameters } =
    farmParametersHook

  return (
    <BoxedLayout title="Configurações do sistema">
      <ShutdownCard
        deathReasons={deathReasons}
        otherReasons={otherReasons}
        updateShutdownReason={updateShutdownReason}
        deleteShutdownReason={deleteShutdownReason}
        createShutdownReason={createShutdownReason}
      />
      <ReproductionCard
        params={reproductionParams}
        updateParams={updateReproductionParams}
      />
      <AgeGroupCard
        getAgeGroups={getAgeGroups}
        ageGroups={ageGroups}
        deleteAgeGroup={deleteAgeGroup}
        createAgeGroup={createAgeGroup}
        updateAgeGroup={updateAgeGroup}
      />
      <BreedCard
        breeds={breeds}
        addBreed={addBreed}
        updateBreed={updateBreed}
        deleteBreed={deleteBreed}
        readAllBreeds={readAllBreeds}
      />
      <ProductCard
        readAllProducts={readAllProducts}
        deleteProduct={deleteProduct}
        createProduct={createProduct}
        updateProduct={updateProduct}
        products={products}
      />
      <FarmCard
        readFarmParameters={readFarmParameters}
        farmParameters={farmParameters}
        updateFarmParameters={updateFarmParameters}
      />
      <InseminatorsCard {...inseminatorsHook} />
    </BoxedLayout>
  )
}

export { SettingsTemplate }
