import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import {
  getReadAllBreeds,
  postCreateBreed,
  deleteBreed as deleteBreedService,
  patchBreed,
} from 'app/core/services/system'
import { BreedHook } from 'app/core/types/hooks'
import { BreedCreateRequestData, BreedProps } from 'app/core/types/system'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

const useBreed = (): BreedHook => {
  const [breeds, setBreeds] = useState<BreedProps[]>()
  const [breedsDroplist, setBreedsDroplist] = useState<SelectItemProps[]>()

  const readAllBreeds = useCallback(async (): Promise<void> => {
    try {
      const data = await getReadAllBreeds()

      const BREED_OPTIONS = data.map(e => ({
        label: e.description,
        value: `${e.id}`,
      }))

      setBreeds(data)
      setBreedsDroplist(BREED_OPTIONS)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }, [])

  const addBreed = useCallback(
    async (request: BreedCreateRequestData): Promise<void> => {
      if (request) {
        try {
          await postCreateBreed(request)
          addToast({
            message: Messages.SYSTEM_BREED_CREATE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          const message = (e as Error).message
          addToast({ message })
          throw new Error(message)
        }
      }
    },
    []
  )

  const deleteBreed = useCallback(async (id: number): Promise<void> => {
    try {
      await deleteBreedService(id)
      addToast({
        message: Messages.SYSTEM_BREED_DELETE_SUCCESS,
        type: 'success',
      })
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }, [])

  const updateBreed = useCallback(
    async (
      id: number,
      request: Partial<BreedCreateRequestData>
    ): Promise<void> => {
      try {
        await patchBreed(id, request)
        addToast({
          message: Messages.SYSTEM_BREED_UPDATE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    []
  )

  useEffect(() => {
    readAllBreeds()
  }, [readAllBreeds])

  return {
    breeds,
    breedsDroplist,
    addBreed,
    updateBreed,
    deleteBreed,
    readAllBreeds,
  }
}

export { useBreed }
