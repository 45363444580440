import React from 'react'

import { Typography, TypographyVariant } from 'components/atoms'
import { TypographyWeight } from 'components/atoms/typography/paragraph'

import styles from './styles.module.scss'

type ScheduleExpandedInfoProps = {
  stationName: string
  protocolName: string
}

const ScheduleExpandedInfo: React.FC<ScheduleExpandedInfoProps> = ({
  stationName,
  protocolName,
}) => {
  return (
    <div className={styles.expandedContainer}>
      <div className={styles.expandedCol}>
        <Typography
          text="Estação de monta"
          variant={TypographyVariant.caption3}
          altColor
        />
        <Typography
          text={stationName}
          variant={TypographyVariant.p}
          weight={TypographyWeight.semiBold}
        />
      </div>
      <div className={styles.expandedCol}>
        <Typography
          text="Protocolo hormonal"
          variant={TypographyVariant.caption3}
          altColor
        />
        <Typography
          text={protocolName}
          variant={TypographyVariant.p}
          weight={TypographyWeight.semiBold}
        />
      </div>
    </div>
  )
}

export { ScheduleExpandedInfo }
