import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonIconPosition,
  ButtonType,
  Divider,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { CustomModal, SearchTable } from 'components/molecules'
import { CustomTable, ISelectButtonItem } from 'components/organisms'
import SelectButton from 'components/organisms/select-button'

import {
  ActiveImportProps,
  ImportHistoryResponse,
  MachineryProps,
} from 'app/core/types/system'
import { dateTimeFormatWithTime } from 'app/core/utils'
import { Messages } from 'config/messages'

import * as templateStyles from '../../styles.module.scss'

type MachineryTabTemplateProps = {
  table: Table<MachineryProps>
  setGlobalFilter: Dispatch<SetStateAction<string>>
  handleModalConfirm: () => Promise<void>
  handleShowMoreHistoryItems: () => void
  historyCount: number
  activeImport?: ActiveImportProps
  toggleHistoryModal: () => void
  toggleImportModal: () => void
  isHistoryModalOpen: boolean
  isImportModalOpen: boolean
  isImportLoading: boolean
  isLoading: boolean
  importedFiles: ImportHistoryResponse[]
  onImportFileClick: (name: string) => void
  sheetMenuOptions: ISelectButtonItem[]
}

const MachineryTabTemplate: React.FC<MachineryTabTemplateProps> = ({
  table,
  setGlobalFilter,
  handleModalConfirm,
  handleShowMoreHistoryItems,
  historyCount,
  activeImport,
  toggleHistoryModal,
  toggleImportModal,
  isHistoryModalOpen,
  isImportModalOpen,
  isImportLoading,
  isLoading,
  importedFiles,
  onImportFileClick,
  sheetMenuOptions,
}) => {
  const addedHasUpdate = activeImport && activeImport.added > 0
  const changedHasUpdate = activeImport && activeImport.changed > 0
  const deactivatedHasUpdate = activeImport && activeImport.disables > 0

  return (
    <>
      <Typography
        text="Este parâmetro é importado do WK Radar Empresarial. Para alterá-lo é necessário criar esta alteração no WK Radar Empresarial e exportar a planilha .xlsx de Maquinários, e então importar o arquivo aqui."
        variant={TypographyVariant.p}
      />

      <header className={templateStyles.default.parametersHeader}>
        <SearchTable setGlobalFilter={setGlobalFilter} />

        <div className={templateStyles.default.parametersActions}>
          <Button
            testId="historyButton"
            label="Ver histórico"
            onClick={toggleHistoryModal}
            type={ButtonType.ghost}
          />
          <SelectButton
            testId="sheetOptionsButton"
            icon={<Icon name={IconNames['chevron-down']} />}
            iconPosition={ButtonIconPosition.right}
            label="Planilha"
            items={sheetMenuOptions}
            loading={!isImportModalOpen ? isImportLoading : false}
          />
        </div>
      </header>

      <CustomTable table={table} isLoading={isLoading} disableNavigation />

      <CustomModal
        modalHeader=""
        modalIsOpen={isImportModalOpen}
        handleCloseModal={toggleImportModal}
      >
        <div className={templateStyles.default.importModal}>
          <Typography
            text="Importar parâmetros de maquinários"
            variant={TypographyVariant.h2}
          />

          <Typography
            text={`Arquivo: ${activeImport?.fileName}`}
            variant={TypographyVariant.p}
          />

          <Typography
            text="Esta ação irá atualizar os parâmetros atuais. Maquinários podem ser adicionados, alterados ou removidos."
            variant={TypographyVariant.p}
          />

          <ul className={templateStyles.default.importModalChanges}>
            <li
              {...(addedHasUpdate && {
                className: templateStyles.default.importModalChanged,
              })}
            >
              Adicionados: {activeImport?.added}
            </li>
            <li
              {...(changedHasUpdate && {
                className: templateStyles.default.importModalChanged,
              })}
            >
              Alterados: {activeImport?.changed}
            </li>
            <li
              {...(deactivatedHasUpdate && {
                className: templateStyles.default.importModalChanged,
              })}
            >
              Desativados: {activeImport?.disables}
            </li>
          </ul>

          <Divider darker />

          <div className={templateStyles.default.importModalActions}>
            <Button
              testId="applyChangesButton"
              onClick={handleModalConfirm}
              label="Aplicar alterações"
              type={ButtonType.primary}
              loading={isImportLoading}
            />
            <Button
              onClick={toggleImportModal}
              label="Cancelar"
              type={ButtonType.ghost}
            />
          </div>
        </div>
      </CustomModal>

      <CustomModal
        modalHeader=""
        modalIsOpen={isHistoryModalOpen}
        handleCloseModal={toggleHistoryModal}
      >
        <div className={templateStyles.default.importModal}>
          <Typography
            text="Histórico de importações de parâmetros de maquinários"
            variant={TypographyVariant.h2}
          />

          <Typography
            text="Histórico das planilhas xlsx de parâmetros de maquinários importados do WK Radar Empresarial"
            variant={TypographyVariant.p}
          />

          {importedFiles.length === 0 ? (
            <Typography
              className={templateStyles.default.emptyState}
              text={Messages.SYSTEM_PARAMETERS_IMPORT_EMPTY_DATA}
              variant={TypographyVariant.p}
            />
          ) : (
            <>
              <ul className={templateStyles.default.importModalHistory}>
                {importedFiles
                  .slice(0, historyCount)
                  .map(({ id, name, date }) => (
                    <li key={id}>
                      <Button
                        testId="downloadFileButton"
                        onClick={(): void => onImportFileClick(name)}
                        label={`Importação ${dateTimeFormatWithTime(date)}`}
                        type={ButtonType.outline}
                        icon={<Icon name={IconNames.download} />}
                      />
                    </li>
                  ))}
              </ul>

              <div className={templateStyles.default.importModalActions}>
                <Button
                  testId="showMoreButton"
                  disabled={historyCount > importedFiles.length}
                  onClick={handleShowMoreHistoryItems}
                  label="Mostrar mais"
                  type={ButtonType.ghost}
                />
              </div>
            </>
          )}
        </div>
      </CustomModal>
    </>
  )
}

export { MachineryTabTemplate }
