import { useCallback, useEffect, useState } from 'react'

import * as yup from 'yup'

import { SelectItemProps } from 'components/atoms'
import { CustomModal } from 'components/molecules'
import { useForm } from 'components/organisms'

import {
  ModalType,
  PrecipitationProps,
  UpdateMeasurementRequestData,
} from 'app/core/types/precipitation'
import { Messages } from 'config/messages'

import styles from '../../styles.module.scss'

type PrecipitationUpdateModalProps = {
  updateMeasurement: (
    measurementId: number,
    request: UpdateMeasurementRequestData
  ) => Promise<void>
  handleModal: (type: ModalType) => void
  isModalOpen: boolean
  stationsDroplist: SelectItemProps[]
  selectedItem: PrecipitationProps | undefined
}

const PrecipitationUpdateModal: React.FC<PrecipitationUpdateModalProps> = ({
  updateMeasurement,
  handleModal,
  isModalOpen,
  stationsDroplist,
  selectedItem,
}) => {
  const [selectedStation, setSelectedStation] = useState<string>()

  const handleModalUpdate = useCallback(
    (): void => handleModal(ModalType.update),
    [handleModal]
  )

  const handleUpdate = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      if (selectedItem) {
        const { date, rain, temp_min, temp_med, temp_max } = data

        const request = {
          date,
          rain,
          temp_min,
          temp_med,
          temp_max,
          station_id: selectedStation ? Number(selectedStation) : undefined,
        } as UpdateMeasurementRequestData

        await updateMeasurement(selectedItem?.id, request)

        handleModalUpdate()
      }
    },
    [handleModalUpdate, selectedItem, selectedStation, updateMeasurement]
  )

  const validationSchema = yup.object({
    date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    station_id: yup
      .mixed<SelectItemProps>()
      .required(Messages.YUP_REQUIRED_FIELD),
    temp_min: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    temp_med: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    temp_max: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    rain: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const { Form, handleSubmit, reset } = useForm({
    onSubmit: handleUpdate,
    validationSchema,
  })

  useEffect(() => {
    const selectedStation = stationsDroplist.find(
      station => station.value === String(selectedItem?.station_id)
    )

    const updatedForm = {
      ...selectedItem,
      station_id: selectedStation,
    }

    reset(updatedForm)
  }, [selectedItem, stationsDroplist, reset])

  return (
    <CustomModal
      modalHeader="Editar lançamento manual"
      modalIsOpen={isModalOpen}
      handleCloseModal={handleModalUpdate}
      primaryButtonLabel="Salvar"
      primaryButtonAction={handleSubmit(handleUpdate)}
      secondaryButtonLabel="Cancelar"
      secondaryButtonAction={handleModalUpdate}
    >
      <Form>
        <div className={styles.modalFormRow}>
          <Form.InputText
            label="Data e hora"
            htmlType="datetime-local"
            name="date"
          />
          <Form.Select
            label="Estação"
            name="station_id"
            options={stationsDroplist}
            onChange={(value): void =>
              setSelectedStation((value as SelectItemProps).value)
            }
          />
        </div>
        <div className={styles.modalFormRow}>
          <Form.InputText
            label="Temperatura mínima"
            htmlType="number"
            name="temp_min"
            appendHelperText="°C"
          />

          <Form.InputText
            label="Temperatura média"
            htmlType="number"
            name="temp_med"
            appendHelperText="°C"
          />

          <Form.InputText
            label="Temperatura máxima"
            htmlType="number"
            name="temp_max"
            appendHelperText="°C"
          />

          <Form.InputText
            label="Precipitação"
            htmlType="number"
            name="rain"
            appendHelperText="mm"
          />
        </div>
      </Form>
    </CustomModal>
  )
}

export { PrecipitationUpdateModal }
