import { SelectedFiles } from 'use-file-picker'

import {
  ImportServiceResponse,
  ServiceCreateRequestData,
  ServiceProps,
  ServiceUpdateRequestData,
  ServicesRequestData,
} from 'app/core/types/system'
import { parseSelectedFileToBlob, removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/service/'

const getAllServices = async (
  filters?: Partial<ServicesRequestData>
): Promise<ServiceProps[]> => {
  const response = await http.get(baseUrl, {
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

const postCreateService = async (
  request: ServiceCreateRequestData
): Promise<ServiceProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const patchUpdateService = async (
  serviceId: number,
  request: ServiceUpdateRequestData
): Promise<ServiceProps> => {
  const response = await http.patch(`${baseUrl}${serviceId}/`, request)
  return response.data
}

const deleteService = async (serviceId: number): Promise<void> => {
  const response = await http.delete(`${baseUrl}${serviceId}/`)
  return response.data
}

const postImportService = async (
  file: SelectedFiles
): Promise<ImportServiceResponse[]> => {
  const formData = new FormData()

  const fileBlob = parseSelectedFileToBlob(file)

  formData.append('file', fileBlob.data, fileBlob.name)

  const response = await http.post(`${baseUrl}import/`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export {
  deleteService,
  getAllServices,
  patchUpdateService,
  postCreateService,
  postImportService,
}
