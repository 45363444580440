import { useState } from 'react'

import classNames from 'classnames'

import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { isAdmin } from 'app/core/auth'

import styles from './styles.module.scss'
import OutsideClickHandler from 'react-outside-click-handler'

export interface IUserProfileItem {
  name: string
  action: () => void
  icon?: IconNames
  onlyAdmin?: boolean
}

export interface IUserProfileProps {
  items: IUserProfileItem[]
  image: string
  name?: string
  email?: string
  farmName?: string
  className?: string
  loading?: boolean
}

const UserProfile = ({
  items,
  image,
  name,
  email,
  farmName,
  className,
}: IUserProfileProps): JSX.Element => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleImageClick = (): void => {
    setTooltipVisible(!tooltipVisible)
  }

  return (
    <div className={classNames(styles.userProfile, className)} >
      <img className={styles.userProfilePhoto} src={image} draggable={false} onClick={handleImageClick} />
      {tooltipVisible && (
        <OutsideClickHandler onOutsideClick={handleImageClick}>
          <div className={styles.userProfileBox}>
            <img className={styles.titlePhoto} src={image} draggable={false} />
            <Typography
              text={name}
              variant={TypographyVariant.h5}
              className={styles.titleName}
            />
            <Typography
              text={farmName}
              variant={TypographyVariant.h6}
              className={styles.titleFarm}
            />
            <Typography
              text={email}
              variant={TypographyVariant.h6}
              className={styles.titleEmail}
            />
            <hr
              className={styles.divider}
            />
            <ul className={styles.userProfileItems}>
              {items
                .filter(item => (item.onlyAdmin ? isAdmin() : true))
                .map((item, i) => (
                  <li
                    key={i}
                    onClick={(): void => {
                      handleImageClick()
                      item.action()
                    }}
                  >
                    <Icon name={item.icon} />
                    {item.name}
                  </li>
                ))}
            </ul>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  )
}

export default UserProfile
