import { ColumnDef } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { LocationProps } from 'app/core/types/system'

import styles from './styles.module.scss'

const locationListColumns = (
  handleEdit: (location: LocationProps) => void,
  handleDelete: (location: LocationProps) => void
): ColumnDef<LocationProps, unknown>[] => [
  {
    header: 'Local',
    accessorKey: 'name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Fazenda',
    accessorKey: 'farm_name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <div className={styles.tableActions}>
        <Icon
          testId="edit-location"
          name={IconNames.pencil}
          onClick={(): void => handleEdit(info.row.original)}
        />
        <Icon
          testId="delete-location"
          name={IconNames.close}
          onClick={(): void => handleDelete(info.row.original)}
        />
      </div>
    ),
  },
]

export { locationListColumns }
