import { ColumnDef } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'

import { InseminatorProps } from 'app/core/types/inseminator'

import styles from '../../styles.module.scss'

const getInseminatorColumns = (
  handleEditClick: (item: InseminatorProps) => void
): ColumnDef<InseminatorProps, unknown>[] => [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Nome',
    accessorKey: 'name',
  },
  {
    header: 'Fazenda',
    accessorKey: 'farm_name',
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <div className={styles.tableActions}>
        <Icon
          name={IconNames.pencil}
          onClick={(): void => handleEditClick(info.row.original)}
        />
      </div>
    ),
  },
]

export { getInseminatorColumns }
