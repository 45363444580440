import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useHandlings } from 'app/core/hooks/handling'
import { IParamProps, NavigateList } from 'app/core/routes/routes'
import { HandlingTypes } from 'app/core/types/handling'

import { handlingListColumns } from '../tables'
import HandlingDetailsTemplate from './template'

const HandlingDetails: React.FC = () => {
  const history = useHistory<NavigateList>()
  const { handlingId } = useParams<IParamProps>()
  const { getHandling, handling, isLoading, exportHandlings } = useHandlings()
  const [filters, setFilters] = useState<Record<string, unknown>>({})
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const handleToggleDrawer = (): void =>
    setIsDrawerOpen(prevState => !prevState)

  const filterIsActive = !!Object.keys(filters ?? {}).length

  useEffect(() => {
    getHandling(Number(handlingId))
  }, [getHandling, handlingId, history])

  const table = useReactTable({
    data: handling?.handlings || [],
    columns: handlingListColumns({
      handlingType: handling?.type as HandlingTypes,
    }),
    getCoreRowModel: getCoreRowModel(),
  })

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      delete filters['filterType']
      setFilters(filters)
      getHandling(Number(handlingId), filters)
      handleToggleDrawer()
    },
    [handlingId, getHandling]
  )

  const handleFormReset = (): void => {
    setFilters({})
    getHandling(Number(handlingId))
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Exportar',
      icon: IconNames.download,
      action: (): void => {
        exportHandlings()
      },
    },
  ]

  return (
    <HandlingDetailsTemplate
      handling={handling}
      table={table}
      onReset={handleFormReset}
      filterIsActive={filterIsActive}
      isLoading={isLoading}
      activeFilters={
        filterIsActive
          ? Object.values(filters).filter(v => v != '').length
          : undefined
      }
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={handleToggleDrawer}
      headerSelectButtonItems={selectButtonItems}
      onSubmit={handleFilterAnimal}
    />
  )
}

export { HandlingDetails }
