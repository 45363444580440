import {
  InseminatorCreateRequestData,
  InseminatorFilterProps,
  InseminatorProps,
  InseminatorReadResponseData,
  InseminatorUpdateRequestData,
} from 'app/core/types/inseminator'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/inseminator/'

const getInseminators = async (
  filters?: Partial<InseminatorFilterProps>
): Promise<InseminatorReadResponseData> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

const postCreateInseminator = async (
  payload: InseminatorCreateRequestData
): Promise<InseminatorProps> => {
  const response = await http.post(`${baseUrl}`, payload)
  return response.data
}

const patchUpdateInseminator = async (
  id: number,
  request: Partial<InseminatorUpdateRequestData>
): Promise<InseminatorProps> => {
  const response = await http.patch(`${baseUrl}${id}/`, request)
  return response.data
}

export { getInseminators, patchUpdateInseminator, postCreateInseminator }
