import Skeleton from 'react-loading-skeleton'

import styles from './styles.module.scss'

const ListSectorLoadingTemplate: JSX.Element = (
  <div className={styles.sectorLoading}>
    <div className={styles.sectorLoadingCard}>
      <Skeleton height={50} count={20} />
    </div>
  </div>
)

export { ListSectorLoadingTemplate }
