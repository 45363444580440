import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import styles from './styles.module.scss'

type LoadingDropdownProps = {
  width?: number
}

const LoadingChart: React.FC = () => {
  const loadingMessages = [
    'Coletando informações do iCrop',
    'Formatando dados das estações',
    'Gerando gráfico customizado',
  ]

  const [messageIndex, setMessageIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex(prevIndex => (prevIndex + 1) % loadingMessages.length)
    }, 5000)

    return (): void => clearInterval(intervalId)
  }, [loadingMessages.length])

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loading}>
        <div className={styles.loadingFrame}></div>
        <div className={styles.loadingCircle}></div>
      </div>
      <p className={styles.loadingContainerText}>
        {loadingMessages[messageIndex]}...
      </p>
    </div>
  )
}

const LoadingDropdown: React.FC<LoadingDropdownProps> = ({ width = 300 }) => {
  return <Skeleton width={width} height={40} className={styles.fieldLoading} />
}

export { LoadingChart, LoadingDropdown }
