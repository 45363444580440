import { Table } from '@tanstack/react-table'
import React, { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  DrawerTitle,
} from 'components/molecules'
import { BoxedTable, useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { useSector } from 'app/core/hooks'
import { AnimalSexPlural } from 'app/core/types/animal'
import { BirthCreateProps } from 'app/core/types/birth'
import {
  FEMALE_REPRODUCTIVE_STATUS_FILTER,
  FilterSections,
} from 'app/core/types/filters'
import { Messages } from 'config/messages'

type AnimalCreateTemplateProps = {
  handleGoToReview: () => void
  animals: BirthCreateProps[]
  table: Table<BirthCreateProps>
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  schedulesDroplist?: SelectItemProps[]
  filterSections: FilterSections
  setActiveFilterSection: Dispatch<SetStateAction<string[]>>
  handleChange: (section: string[] | undefined) => void
  handleDisabled: (name: string) => boolean
}

const BirthCreateTemplate: React.FC<AnimalCreateTemplateProps> = ({
  animals,
  table,
  isDrawerOpen,
  handleGoToReview,
  handleToggleDrawer,
  onSubmit,
  schedulesDroplist,
  setActiveFilterSection,
  filterSections,
  handleChange,
  handleDisabled,
}) => {
  const { sectorsDroplist } = useSector()

  const { Form, reset } = useForm({ onSubmit })

  const resetFilters = (): void => {
    setActiveFilterSection([])
    reset()
  }

  return (
    <>
      <BoxedLayout
        title="Registrar Partos e Crias"
        headerLargeButtonAction={handleGoToReview}
        headerLargeButtonLabel="Revisar"
        headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
        headerLargeButtonIconPosition={ButtonIconPosition.right}
        isChildrenSticky
      >
        <BoxedTable
          data={animals}
          table={table}
          isTableHeaderSticky
          title="Animais"
          quantityLabel="Registros"
          noDataMessage={Messages.ANIMAL_NOT_ADDED}
          disableNavigation
          mainButtonLabel="Incluir"
          mainButtonIcon={IconNames['add-circle']}
          mainButtonAction={handleToggleDrawer}
        />
      </BoxedLayout>

      <CustomDrawer
        title={`Incluir ${AnimalSexPlural.female}`}
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerTitle text="Informações gerais" />

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                placeholder="Pesquisar nº de brinco eletrônico"
                name="electronic_eartag"
                disabled={handleDisabled('electronic_eartag')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                placeholder="Pesquisar nº de nascimento"
                name="birth_number"
                disabled={handleDisabled('birth_number')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                placeholder="Pesquisar nº de plantel"
                name="stock_number"
                disabled={handleDisabled('stock_number')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {sectorsDroplist && (
              <DrawerCol>
                <Form.Select
                  label="Setor"
                  name="sector_id"
                  options={sectorsDroplist}
                  disabled={handleDisabled('sector_id')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
            )}
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                placeholder="Pesquisar nome"
                name="name"
                disabled={handleDisabled('name')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Período de Nascimento"
                name="init_date"
                htmlType="date"
                disabled={handleDisabled('init_date')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />

              <Form.InputText
                emptyLabelSpacing
                name="final_date"
                htmlType="date"
                disabled={handleDisabled('final_date')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerTitle text="Informações adicionais" />

          <DrawerRow>
            <DrawerCol>
              <Form.Select
                label="Status reprodutivo"
                name="female_status"
                options={FEMALE_REPRODUCTIVE_STATUS_FILTER}
                disabled={handleDisabled('female_status')}
                onChange={(): void =>
                  handleChange(filterSections?.additionalInfoSection)
                }
              />
            </DrawerCol>
            <DrawerCol column>
              <label htmlFor="insemination_date_init">
                Vacas inseminadas entre
              </label>
              <div>
                <Form.InputText
                  name="insemination_date_init"
                  htmlType="date"
                  disabled={handleDisabled('insemination_date_init')}
                  onChange={(): void =>
                    handleChange(filterSections?.additionalInfoSection)
                  }
                />
                <Form.InputText
                  name="insemination_date_end"
                  htmlType="date"
                  disabled={handleDisabled('insemination_date_end')}
                  onChange={(): void =>
                    handleChange(filterSections?.additionalInfoSection)
                  }
                />
              </div>
            </DrawerCol>
            <DrawerCol column>
              <label htmlFor="birth_date_init">Vacas que pariram entre</label>
              <div>
                <Form.InputText
                  name="birth_date_init"
                  htmlType="date"
                  disabled={handleDisabled('birth_date_init')}
                  onChange={(): void =>
                    handleChange(filterSections?.additionalInfoSection)
                  }
                />
                <Form.InputText
                  name="birth_date_end"
                  htmlType="date"
                  disabled={handleDisabled('birth_date_end')}
                  onChange={(): void =>
                    handleChange(filterSections?.additionalInfoSection)
                  }
                />
              </div>
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol column>
              <label htmlFor="birth_forecast_start">
                Partos previstos entre
              </label>
              <div>
                <Form.InputText
                  name="birth_forecast_start"
                  htmlType="date"
                  disabled={handleDisabled('birth_forecast_start')}
                  onChange={(): void =>
                    handleChange(filterSections?.additionalInfoSection)
                  }
                />
                <Form.InputText
                  name="birth_forecast_end"
                  htmlType="date"
                  disabled={handleDisabled('birth_forecast_end')}
                  onChange={(): void =>
                    handleChange(filterSections?.additionalInfoSection)
                  }
                />
              </div>
            </DrawerCol>
            <DrawerCol>
              <Form.Select
                label="Programação IATF"
                name="iatf_schedule_id"
                isLoading={!schedulesDroplist?.length}
                options={schedulesDroplist}
                placeholder="Buscar Programação IATF"
                disabled={handleDisabled('iatf_schedule_id')}
                onChange={(): void =>
                  handleChange(filterSections?.additionalInfoSection)
                }
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Incluir"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>
    </>
  )
}

export { BirthCreateTemplate }
