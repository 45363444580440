import { getImportAnimalTemplate, importAnimal } from 'app/core/services'
import { ImportAnimalError, ImportAnimalProps } from 'app/core/types/animal'
import { addToast, downloadFile } from 'app/core/utils'
import { Messages } from 'config/messages'

const useImportAnimal = async (props: ImportAnimalProps): Promise<void> => {
  try {
    await importAnimal(props)

    addToast({
      message: 'Animal importado com sucesso!',
      type: 'success',
    })
  } catch (e) {
    if (e instanceof ImportAnimalError) {
      downloadFile({
        data: e.data,
        fileName: 'dados_invalidos_animal',
      })

      addToast({
        message: Messages.ERROR_SHEET_MESSAGE,
        type: 'warning',
      })

      return
    }

    addToast({ message: Messages.ERROR_MESSAGE })
  }
}

const useGetImportAnimalTemplate = async (): Promise<void> => {
  try {
    const { data } = await getImportAnimalTemplate()

    downloadFile({
      data: data,
      fileName: 'template_import_animal',
    })
  } catch (e) {
    addToast({ message: Messages.ERROR_MESSAGE })
  }
}

export { useGetImportAnimalTemplate, useImportAnimal }
