import classNames from 'classnames'

import { Icon, IconNames } from 'components/atoms'

import styles from './styles.module.scss'

export enum ButtonGroupSide {
  left = 'left',
  right = 'right',
}

type ButtonGroupItemProps = {
  iconName: IconNames
  label: string
  isActive?: boolean
  side: ButtonGroupSide
  onClick?: () => void
}

const ButtonGroupItem: React.FC<ButtonGroupItemProps> = ({
  iconName,
  label,
  isActive = false,
  side,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.buttonGroupItem,
        isActive && styles.buttonGroupItemActive,
        side === ButtonGroupSide.left
          ? styles.buttonGroupItemLeft
          : styles.buttonGroupItemRight
      )}
    >
      <Icon name={iconName} size="16px" />
      <span>{label}</span>
    </div>
  )
}

type ButtonGroupProps = {
  className?: string
  leftIconName: IconNames
  leftLabel: string
  active: ButtonGroupSide
  rightIconName: IconNames
  rightLabel: string
  onChange?: (value: ButtonGroupSide) => void
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  className,
  leftIconName,
  leftLabel,
  active,
  rightIconName,
  rightLabel,
  onChange,
}) => {
  return (
    <div className={classNames(styles.buttonGroup, className)}>
      <ButtonGroupItem
        side={ButtonGroupSide.left}
        isActive={active === ButtonGroupSide.left}
        iconName={leftIconName}
        label={leftLabel}
        onClick={(): void => onChange?.(ButtonGroupSide.left)}
      />
      <ButtonGroupItem
        side={ButtonGroupSide.right}
        isActive={active === ButtonGroupSide.right}
        iconName={rightIconName}
        label={rightLabel}
        onClick={(): void => onChange?.(ButtonGroupSide.right)}
      />
    </div>
  )
}
