import { Dispatch, SetStateAction } from 'react'

import { Icon, IconNames } from 'components/atoms'
import {
  AnimalEvaluations,
  AnimalInfo,
  AnimalInfoData,
  GenealogyTree,
  SearchAnimal,
} from 'components/organisms'
import { BoxedLayout } from 'components/templates'
import { InfoCard } from 'components/templates/animal-layout/helpers'

import { NavigateList } from 'app/core/routes/routes'
import { AnimalListProps, IAnimalEntity } from 'app/core/types/animal'
import { EvaluationProps } from 'app/core/types/evaluation'
import { MatingRecommendationProps } from 'app/core/types/mating-recommendation'

import { Calf } from './calf'
import { LastCalf } from './last-calf'
import { MatingRecommendationModal } from './modal'
import { AnimalRecommendations } from './recommendations'
import styles from './styles.module.scss'

type MatingRecommendationDetailsTemplateProps = {
  animal?: IAnimalEntity
  animalRecommendations?: MatingRecommendationProps[]
  animalEvaluations?: EvaluationProps[]
  animalCalfs?: AnimalListProps[]
  isModalOpen: boolean
  isMatingRecommendation: boolean
  isLoadingCalfs: boolean
  selectedCalf?: AnimalListProps
  selectedRecommendation?: MatingRecommendationProps
  setSelectedRecommendation: Dispatch<
    SetStateAction<MatingRecommendationProps | undefined>
  >
  handleDisposal: (destinationSectorId: number) => Promise<void>
  handleModal: (isMatingRecommendation?: boolean) => void
  handleSelectedCalf: (
    calfId?: number,
    isMatingRecommendation?: boolean
  ) => void
  handleSelectedRecommendation: (
    recommendation: MatingRecommendationProps
  ) => void
  handleNewEvaluation: (data: Record<string, unknown>) => Promise<void>
  handleRecommendation: (
    date: string,
    maleName: string,
    maleId?: number,
    isEditing?: boolean
  ) => Promise<void>
  onCalfClick: (event: React.MouseEvent<HTMLDivElement>, calfId: number) => void
}

const MatingRecommendationDetailsTemplate: React.FC<MatingRecommendationDetailsTemplateProps> =
  ({
    animal,
    animalRecommendations,
    animalEvaluations,
    animalCalfs,
    isModalOpen,
    isMatingRecommendation,
    isLoadingCalfs,
    selectedCalf,
    selectedRecommendation,
    setSelectedRecommendation,
    handleDisposal,
    handleSelectedRecommendation,
    handleModal,
    handleSelectedCalf,
    handleNewEvaluation,
    handleRecommendation,
    onCalfClick,
  }) => {
    const lastCalf = animalCalfs && animalCalfs[0]
    const animalHasCalfs = animalCalfs?.length

    return (
      <>
        <BoxedLayout
          title="Recomendações de Acasalamento"
          backButton
          headerLargeButtonAction={(): void => {
            if (isLoadingCalfs) {
              return
            }
            handleSelectedCalf(lastCalf ? lastCalf?.id : undefined, true)
          }}
          headerLargeButtonLabel="Recomendar"
          headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
          limitWidth
        >
          <SearchAnimal
            navigation={NavigateList.matingRecommendationDetails}
            findOnlyFemale
          />

          {animal && (
            <section className={styles.matingRecommendation}>
              <div className={styles.animalInfo}>
                <AnimalInfo
                  animal={animal}
                  show={[
                    AnimalInfoData.stockNumber,
                    AnimalInfoData.birthNumber,
                    AnimalInfoData.birthDate,
                    AnimalInfoData.status,
                  ]}
                />

                <LastCalf
                  animal={lastCalf}
                  handleSelectedCalf={handleSelectedCalf}
                  isLoading={isLoadingCalfs}
                  onClick={onCalfClick}
                />
              </div>

              <InfoCard title="Pedigree" collapsible fullWidth>
                <GenealogyTree genealogy={animal.genealogy} />
              </InfoCard>

              <AnimalRecommendations
                animalRecommendations={animalRecommendations}
                handleSelectedRecommendation={handleSelectedRecommendation}
              />

              {animalHasCalfs ? (
                <InfoCard
                  className={styles.evaluationsInfoCard}
                  title="Avaliações das crias do animal"
                  fullWidth
                >
                  {animalCalfs?.map(calf => (
                    <Calf
                      key={calf.id}
                      calf={calf}
                      handleSelectedCalf={handleSelectedCalf}
                      onClick={onCalfClick}
                    />
                  ))}
                </InfoCard>
              ) : (
                <AnimalEvaluations evaluations={animalEvaluations} />
              )}
            </section>
          )}
        </BoxedLayout>

        <MatingRecommendationModal
          animal={animal}
          isMatingRecommendation={isMatingRecommendation}
          isModalOpen={isModalOpen}
          handleDisposal={handleDisposal}
          handleModal={handleModal}
          handleNewEvaluation={handleNewEvaluation}
          handleRecommendation={handleRecommendation}
          selectedCalf={selectedCalf}
          selectedRecommendation={selectedRecommendation}
          setSelectedRecommendation={setSelectedRecommendation}
        />
      </>
    )
  }

export { MatingRecommendationDetailsTemplate }
