import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { debounce } from 'lodash-es'
import { SelectedFiles, useFilePicker } from 'use-file-picker'

import { ButtonType, IconNames } from 'components/atoms'
import { CustomModal } from 'components/molecules'
import { ISelectButtonItem } from 'components/organisms'

import { isAdmin } from 'app/core/auth'
import { useReproductionParams } from 'app/core/hooks'
import { useDiagnostics } from 'app/core/hooks/breeding/diagnostics'
import {
  IParamProps,
  NavigateList,
  NavigateParams,
} from 'app/core/routes/routes'
import { getDiagnosticType } from 'app/core/services'
import { DiagnosticAnimal } from 'app/core/types/breeding'
import { InseminationTypes } from 'app/core/types/hormonal'
import { DEFAULT_ITEMS_PER_PAGE_DETAILS } from 'app/core/types/system'
import {
  addToast,
  dateTimeFormat,
  dateTimeISOFormat,
  dateToday,
  diagnosticsAnimalsEmptyFields,
  downloadFile,
  generateXlsxTemplate,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { DiagnosticColumns } from '../tables'
import { DiagnosticsDetailsTemplate } from './template'

const DiagnosticsDetails: React.FC = () => {
  const history = useHistory<NavigateParams>()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [deleteDiagnostic, setDeleteDiagnostic] = useState<number>()
  const [page, setPage] = useState(1)
  const { diagnosticGroupId } = useParams<IParamProps>()
  const { params } = useReproductionParams()
  const { max_gestation_period: maxGestationDays } = params || {}

  const {
    diagnostics,
    setDiagnostics,
    headerInfo,
    getDiagnostics,
    updateDiagnostic,
    updateTableRow,
    tableRows,
    importDiagnostics,
    removeAnimal,
    removeDiagnostic,
    createDiagnosticsStorage,
    filterIsActive,
    exportDiagnostics,
    isLoading,
    filterDiagnosticAnimals,
  } = useDiagnostics({ diagnosticGroupId, page })

  const diagnosticsAlreadyCreated =
    diagnostics !== undefined &&
    diagnostics.items.length > 0 &&
    diagnostics.items.every(diagnostic => diagnostic.id !== undefined)

  const minimumGestationDays = params?.gestation_diagnosis_limit ?? 28

  const handleToggleDrawer = (): void =>
    setIsDrawerOpen(prevState => !prevState)

  const getDiagnosticWithCalculatedInformation = async (
    diagnostic: DiagnosticAnimal
  ): Promise<DiagnosticAnimal> => {
    const updatedDiagnostic = { ...diagnostic }
    if (!updatedDiagnostic.status) {
      return updatedDiagnostic
    }
    const diagnosticTypeResponse = await getDiagnosticType(
      updatedDiagnostic.animal?.id as number,
      dateTimeISOFormat(updatedDiagnostic.diagnostic_date)
    )
    if (
      !updatedDiagnostic.gestation_days &&
      !updatedDiagnostic.insemination_date
    ) {
      updatedDiagnostic.gestation_days = diagnosticTypeResponse.gestation_days
    }
    updatedDiagnostic.type = diagnosticTypeResponse.type
    updatedDiagnostic.birth_forecast = diagnosticTypeResponse.birth_forecast
    updatedDiagnostic.insemination_date =
      diagnosticTypeResponse.insemination_date

    if (
      diagnosticTypeResponse.gestation_days !== updatedDiagnostic.gestation_days
    ) {
      updatedDiagnostic.type = InseminationTypes.natural_cover
    }

    return updatedDiagnostic
  }

  const upsertDiagnostic = async (
    index: number,
    diagnostic: DiagnosticAnimal,
    maxGestationDays?: number
  ): Promise<void> => {
    try {
      const updatedDiagnostic = await getDiagnosticWithCalculatedInformation(
        diagnostic
      )
      if (
        updatedDiagnostic.status &&
        (updatedDiagnostic.gestation_days ?? 0) < minimumGestationDays
      ) {
        updateTableRow(index, {
          ...updatedDiagnostic,
          error_message: `Dias de gestação precisa ser maior ou igual a ${minimumGestationDays}`,
        })
        return
      }
      if (
        maxGestationDays &&
        (updatedDiagnostic.gestation_days ?? 0) > maxGestationDays
      ) {
        updateTableRow(index, {
          ...updatedDiagnostic,
          error_message: `Dias de gestação não pode ser maior do que ${maxGestationDays}`,
        })
        return
      }

      if (updatedDiagnostic.id) {
        updateDiagnostic(index, updatedDiagnostic)

        return
      }

      updateTableRow(index, {
        ...updatedDiagnostic,
        error_message: undefined,
      })
    } catch (err) {
      addToast({ message: Messages.ERROR_MESSAGE, type: 'error' })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeTableField = useCallback(
    debounce(
      async (index: number, field: string, value: unknown): Promise<void> => {
        setDiagnostics(prevState =>
          prevState.map((item, i) => {
            const currentIndex =
              page === 1
                ? index
                : DEFAULT_ITEMS_PER_PAGE_DETAILS * (page - 1) + index

            if (i !== currentIndex) {
              return item
            }

            const updatedRow = {
              ...item,
              [field]: value,
            }

            const isComplete =
              updatedRow.diagnostic_date !== undefined &&
              updatedRow.status !== undefined

            if (isComplete) {
              upsertDiagnostic(
                index,
                updatedRow as DiagnosticAnimal,
                maxGestationDays
              )

              return item
            }
            return updatedRow
          })
        )
      },
      500
    ),
    [page]
  )

  const handleApplyFilters = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      filterDiagnosticAnimals(filters)
      setIsDrawerOpen(false)
    },
    [filterDiagnosticAnimals]
  )

  const handleDeleteModalClose = (): void => {
    setDeleteDiagnostic(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteDiagnostic) {
      await removeDiagnostic(deleteDiagnostic)
      handleDeleteModalClose()
    }
  }, [deleteDiagnostic, removeDiagnostic])

  const columns = useMemo(
    () =>
      DiagnosticColumns(
        removeAnimal,
        setDeleteDiagnostic,
        onChangeTableField,
        isAdmin()
      ),
    [removeAnimal, setDeleteDiagnostic, onChangeTableField]
  )

  const table = useReactTable({
    data: tableRows,
    columns,
    getCoreRowModel: useMemo(() => getCoreRowModel(), []),
  })

  const handleGoToReview = (): void => {
    if (!diagnostics?.items) {
      addToast({ message: Messages.BREEDING_DIAGNOSTICS_REQUIRED })
      return
    }

    const invalidAnimals = diagnosticsAnimalsEmptyFields(diagnostics?.items, [
      'diagnostic_date',
      'status',
      'gestation_days',
    ])

    if (!invalidAnimals.length) {
      setDiagnostics(prevRows => {
        return prevRows.map(row => {
          return {
            ...row,
            error_message: undefined,
          }
        })
      })

      createDiagnosticsStorage()

      history.push(`${NavigateList.diagnosticsReview}${diagnosticGroupId}`)
    } else {
      invalidAnimals.forEach(invalidAnimal => {
        setDiagnostics(prevRows => {
          return prevRows.map(row => {
            if (row.id === invalidAnimal) {
              return {
                ...row,
                error_message: Messages.REQUIRED_FIELDS,
              }
            }
            return row
          })
        })
      })

      addToast({ message: Messages.REQUIRED_FIELDS })
    }
  }

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const handleImportSheet = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    await importDiagnostics(selectedFile)
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          [
            'Nº de Plantel',
            'Nº de Brinco eletrônico',
            'Nº de Nascimento',
            'Data do diagnóstico',
            'Diagnóstico',
            'Dias de gestação',
          ],
          ['', '', '', dateTimeFormat(dateToday), 'P', ''],
        ])

        downloadFile({
          data: templateFile,
          fileName: 'template-diagnosticos',
        })
      },
    },
  ]

  const alreadyCreatedSelectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Exportar',
      icon: IconNames.download,
      action: (): void => {
        exportDiagnostics()
      },
    },
  ]

  const handleResetFilters = useCallback(async (): Promise<void> => {
    getDiagnostics()
  }, [getDiagnostics])

  useEffect(() => {
    getDiagnostics()
  }, [getDiagnostics])

  return (
    <>
      <DiagnosticsDetailsTemplate
        headerInfo={headerInfo}
        table={table}
        diagnostics={diagnostics}
        showActionButton={!diagnosticsAlreadyCreated}
        isDrawerOpen={isDrawerOpen}
        isLoading={isLoading}
        handleToggleDrawer={handleToggleDrawer}
        onSubmit={handleApplyFilters}
        handleGoToReview={handleGoToReview}
        page={page}
        setPage={setPage}
        alreadyCreatedHeaderSelectButtonItems={alreadyCreatedSelectButtonItems}
        headerSelectButtonItems={selectButtonItems}
        resetFilters={handleResetFilters}
        filterIsActive={filterIsActive}
      />

      <CustomModal
        modalIsOpen={!!deleteDiagnostic}
        handleCloseModal={handleDeleteModalClose}
        modalHeader={`Excluir Diagnóstico`}
        modalText={`Você tem certeza que deseja excluir este diagnóstico?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteModalConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModalClose}
      />
    </>
  )
}

export { DiagnosticsDetails }
