import { useCallback, useEffect, useState } from 'react'

import * as XLSX from 'xlsx'

import { getReadAllAnimals, postUpdateAnimalBulk } from 'app/core/services'
import {
  AnimalCreateProps,
  AnimalFilterProps,
  ElectronicEartagAnimalsResponseData,
  ElectronicEartagCreateProps,
  ImportElectronicEartagProps,
} from 'app/core/types/animal'
import { ElectronicEartagHook } from 'app/core/types/hooks'
import { StorageKeys } from 'app/core/types/storage'
import {
  addToast,
  dateForFileName,
  downloadFile,
  generateXlsxTemplate,
  getPlural,
  handleHttpError,
  paginateItems,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useElectronicEartag = ({
  page,
}: {
  page?: number | undefined
}): ElectronicEartagHook => {
  const savedAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.electronic_eartag_review) as string
  ) as ElectronicEartagCreateProps[]
  const savedFailedAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.electronic_eartag_review_failed) as string
  ) as ElectronicEartagCreateProps[]

  const [animals, setAnimals] = useState<ElectronicEartagCreateProps[]>(
    () => savedAnimals || []
  )
  const [failedAnimals, setFailedAnimals] = useState<
    ElectronicEartagCreateProps[]
  >(() => savedFailedAnimals || [])
  const [paginatedAnimals, setPaginatedAnimals] =
    useState<ElectronicEartagAnimalsResponseData>()
  const [isLoading, setIsLoading] = useState(false)

  const updateAnimals = async (): Promise<void> => {
    setIsLoading(true)

    try {
      const updatedAnimals = animals.map(animal => ({
        animal_id: animal.id,
        electronic_eartag: animal.new_electronic_eartag?.toString(),
      })) as AnimalCreateProps[]

      addToast({
        message: Messages.ANIMALS_UPDATING,
        type: 'info',
      })

      const failedAnimals = [] as ElectronicEartagCreateProps[]
      let successfulAnimals = animals

      const response = await postUpdateAnimalBulk(updatedAnimals)

      if (response.length > 0) {
        response.forEach(error => {
          const animalId = Number(Object.keys(error)[0])
          const errorMessage = error[animalId]

          const failedAnimal = animals.find(state => state?.id === animalId)

          if (failedAnimal) {
            failedAnimals.push({
              stock_number: failedAnimal.stock_number,
              electronic_eartag: failedAnimal.electronic_eartag,
              new_electronic_eartag: failedAnimal.new_electronic_eartag,
              error_message: errorMessage,
            })
          }

          successfulAnimals = [
            ...successfulAnimals.filter(animal => animal.id !== animalId),
          ]
        })

        setFailedAnimals(failedAnimals)
        setAnimals(successfulAnimals)

        localStorage.setItem(
          StorageKeys.electronic_eartag_review_failed,
          JSON.stringify(failedAnimals)
        )

        addToast({
          message: `Não foi possível atualizar ${getPlural(
            'animal',
            failedAnimals.length,
            true
          )}. Verifique a planilha de inconsistências e tente novamente.`,
        })
      }

      if (successfulAnimals.length > 0) {
        addToast({
          message: `${successfulAnimals.length} animais atualizados com sucesso.`,
          type: 'success',
        })
      }
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const searchAnimals = useCallback(
    async (filters: Partial<AnimalFilterProps>): Promise<void> => {
      if (filters) {
        setIsLoading(true)

        try {
          const animals = (await getReadAllAnimals(
            filters
          )) as ElectronicEartagAnimalsResponseData

          if (!animals.items.length) {
            addToast({
              message: Messages.ANIMALS_NOT_FOUND,
            })

            setIsLoading(false)

            return
          }

          setAnimals(prevState => {
            const filteredAnimals = animals.items.filter(newAnimal => {
              const alreadyAdded = prevState.some(
                existingAnimal => existingAnimal.id === newAnimal.id
              )

              if (alreadyAdded) {
                addToast({
                  message: `Animal já adicionado: ${newAnimal.stock_number}`,
                })
              }

              return !alreadyAdded
            })

            return [...prevState, ...filteredAnimals]
          })
          setIsLoading(false)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  const removeAnimal = (animalId: number): void => {
    if (animals) {
      const updatedAnimals = animals.filter(animal => animal.id !== animalId)
      setAnimals(updatedAnimals)
      handlePagination()
    }
  }

  const xlsxToAnimals = async (
    content: string
  ): Promise<ElectronicEartagCreateProps[]> => {
    setIsLoading(true)

    const data = new Uint8Array(content as unknown as ArrayBuffer)
    const workbook = XLSX.read(data, {
      type: 'array',
      cellText: false,
    })

    const animals = [] as Partial<ElectronicEartagCreateProps>[]

    await Promise.all(
      workbook.SheetNames.map(async sheetName => {
        const worksheet = workbook.Sheets[sheetName]
        const rows = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false,
          raw: true,
        }) as ImportElectronicEartagProps[]

        for (let i = 1; i < rows.length; i++) {
          const row = rows[i]

          if (!row[0] && !row[1] && !row[2]) {
            throw new Error('Preencha algum animal na planilha de importação.')
          }

          const importedStockNumber = String(row[0] || '').trim()
          const importedElectronicEartag = String(row[1] || '').trim()
          const importedBirthNumber = String(row[2] || '')
          const newElectronicEartag = row[3] ? row[3] : null

          const filters = {
            stock_number_or_birth_number_or_electronic_eartag: String(
              row[0] || row[1] || row[2]
            )
              .trim()
              .replace(/\n/g, ''),
          } as AnimalFilterProps

          const foundAnimal = (await getReadAllAnimals(filters)).items[0]

          if (foundAnimal && !foundAnimal.is_active) {
            addToast({
              message: `Animal inativo: ${row[0] || row[1] || row[2]}`,
            })

            continue
          }

          const animal = foundAnimal
            ? {
                id: foundAnimal.id,
                animal_id: foundAnimal.id,
                birth_number: foundAnimal.birth_number,
                electronic_eartag: foundAnimal.electronic_eartag,
                stock_number: foundAnimal.stock_number,
                new_electronic_eartag: newElectronicEartag || undefined,
              }
            : {
                birth_number: importedBirthNumber,
                electronic_eartag:
                  importedElectronicEartag !== ''
                    ? importedElectronicEartag
                    : undefined,
                stock_number: importedStockNumber,
                new_electronic_eartag: newElectronicEartag || undefined,
                error_message: `Animal não encontrado: ${
                  row[0] || row[1] || row[2]
                }`,
              }

          animals.push(animal)
        }
      })
    )

    setIsLoading(false)

    return animals
  }

  const exportFailedAnimals = (): void => {
    const templateFile = generateXlsxTemplate([
      [
        'Nº de Plantel',
        'Nº de Brinco eletrônico',
        'Novo Nº de Brinco eletrônico',
        'Inconsistência',
      ],
      ...failedAnimals.map(obj => Object.values(obj)),
    ])

    downloadFile({
      data: templateFile,
      fileName: `brinco-eletronico-${dateForFileName()}`,
    })
  }

  const handlePagination = useCallback(() => {
    if (animals) {
      const paginatedAnimals = paginateItems(animals, page)
      setPaginatedAnimals(paginatedAnimals)
    }
  }, [animals, page])

  const removeStorage = (): void => {
    localStorage.removeItem(StorageKeys.electronic_eartag_review)
    localStorage.removeItem(StorageKeys.electronic_eartag_review_failed)
  }

  useEffect(() => {
    if (animals) {
      localStorage.setItem(
        StorageKeys.electronic_eartag_review,
        JSON.stringify(animals)
      )
    }
  }, [animals])

  useEffect(() => {
    handlePagination()
  }, [handlePagination])

  return {
    animals: paginatedAnimals,
    exportFailedAnimals,
    failedAnimals,
    isLoading,
    removeStorage,
    removeAnimal,
    searchAnimals,
    setAnimals,
    updateAnimals,
    xlsxToAnimals,
  }
}

export { useElectronicEartag }
