export interface IProductProps {
  name?: string
  quantity?: number | string
}

export type ProductListProps = {
  id: number
  name: string
  manufacturer: string
  code: string
}

export enum ProductQuantityUnit {
  ml = 'ml',
  'Dose(s)' = 'dose(s)',
}

export const PRODUCT_QUANTITY_UNIT = Object.entries(ProductQuantityUnit).map(
  ([label, value]) => ({ label, value })
)
