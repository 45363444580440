import { Table } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { BirthCreateProps } from 'app/core/types/birth'

type BirthFeedbackTemplateProps = {
  animals?: BirthCreateProps[]
  exportFailedAnimals: () => void
  failedAnimals: BirthCreateProps[]
  handleFinish: () => void
  isLoading: boolean
  table: Table<BirthCreateProps>
}

const BirthFeedbackTemplate: React.FC<BirthFeedbackTemplateProps> = ({
  animals,
  exportFailedAnimals,
  failedAnimals,
  handleFinish,
  isLoading,
  table,
}) => {
  return (
    <BoxedLayout
      title="Cadastro de partos"
      headerLargeButtonAction={handleFinish}
      headerLargeButtonLabel="Finalizar"
      {...(failedAnimals.length > 0 && {
        headerButtonAction: exportFailedAnimals,
        headerButtonLabel: 'Planilha de inconsistências',
        headerButtonIcon: <Icon name={IconNames['alert']} />,
      })}
    >
      <BoxedTable
        data={animals || []}
        table={table}
        quantityLabel="Entradas"
        title="Partos criados com sucesso"
        noDataMessage="Nenhum parto criado com sucesso."
        isLoading={isLoading}
        disableNavigation
      />
    </BoxedLayout>
  )
}

export { BirthFeedbackTemplate }
