import { formatISO } from 'date-fns'
import Papa from 'papaparse'

import {
  ServiceOrderExportProps,
  ServiceOrderHistoryProps,
  ServiceOrderInfoProps,
  ServiceOrderListProps,
  ServiceOrderListResponse,
  ServiceOrderProps,
} from 'app/core/types/service-order'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/service_order/'

const postCreateServiceOrder = async (
  serviceOrder: Partial<ServiceOrderProps>
): Promise<ServiceOrderInfoProps> => {
  const response = await http.post(baseUrl, serviceOrder)
  return response.data
}

const getListServiceOrder = async (
  filters?: Partial<ServiceOrderListProps>,
  page?: number,
  size?: number
): Promise<ServiceOrderListResponse> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page: page ?? 1, size },
  })
  return response.data
}

const patchUpdateServiceOrder = async (
  serviceOrderId: number,
  payload: Partial<ServiceOrderProps>
): Promise<ServiceOrderInfoProps> => {
  const response = await http.patch(`${baseUrl}${serviceOrderId}/`, {
    ...payload,
    updated_at: formatISO(new Date()),
  })
  return response.data
}

const getExportServiceOrders = async (
  filters?: Partial<ServiceOrderListProps>
): Promise<Partial<ServiceOrderExportProps>[]> => {
  const response = await http.get(`${baseUrl}export/`, {
    params: { ...removeEmptyFilters(filters) },
  })

  const serviceOrders: Partial<ServiceOrderExportProps>[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    serviceOrders.push({
      bulletinNumber: Number(item[1]),
      date: item[2] ? new Date(item[2]) : undefined,
      duration: Number(item[3]),
      odometer: Number(item[4]),
      executorRegistrationNumber: Number(item[5]),
      executorName: item[6],
      classificationCode: item[7],
      classificationName: item[8],
      machineryName: item[9],
      costCenterCode: Number(item[10]),
      costCenterName: item[11],
      farmName: item[12],
    })
  }

  return serviceOrders
}

const getServiceOrderHistory = async (
  serviceOrderId: number
): Promise<ServiceOrderHistoryProps[]> => {
  const response = await http.get(`${baseUrl}${serviceOrderId}/history/`)
  return response.data
}

export {
  getExportServiceOrders,
  getListServiceOrder,
  getServiceOrderHistory,
  patchUpdateServiceOrder,
  postCreateServiceOrder,
}
