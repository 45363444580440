import { Table } from '@tanstack/react-table'
import Skeleton from 'react-loading-skeleton'

import {
  CheckboxButton,
  ICheckboxButtonOption,
  InputText,
  SelectItemProps,
} from 'components/atoms'
import { LabeledCard, LabeledCardCol, withLoading } from 'components/molecules'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  CreateMeasurementRequestData,
  ModalState,
  ModalType,
  PrecipitationProps,
  UpdateMeasurementRequestData,
} from 'app/core/types/precipitation'
import { onlyAlphanumericString } from 'app/core/utils'

import {
  PrecipitationCreateModal,
  PrecipitationDeleteModal,
  PrecipitationUpdateModal,
} from './modal'
import styles from './styles.module.scss'

type PrecipitationTemplateProps = {
  handleStation: (
    isChecked: boolean,
    value: string,
    isExternalStation: boolean
  ) => void
  handlePeriod: (name: string, value: string) => void
  handleModal: (type: ModalType) => void
  isModalOpen: ModalState
  isLoading: boolean
  isLoadingStations: boolean
  table: Table<PrecipitationProps>
  precipitations: PrecipitationProps[]
  stationsCheckboxList: ICheckboxButtonOption[]
  stationsDroplist: SelectItemProps[]
  createMeasurement: (request: CreateMeasurementRequestData) => Promise<void>
  updateMeasurement: (
    measurementId: number,
    request: UpdateMeasurementRequestData
  ) => Promise<void>
  deleteMeasurementItem: (measurementId: number) => Promise<void>
  selectedItem: PrecipitationProps | undefined
}

const PrecipitationTemplate: React.FC<PrecipitationTemplateProps> = ({
  handleStation,
  handlePeriod,
  handleModal,
  isLoading,
  isLoadingStations,
  isModalOpen,
  table,
  precipitations,
  stationsCheckboxList,
  stationsDroplist,
  createMeasurement,
  updateMeasurement,
  deleteMeasurementItem,
  selectedItem,
}) => {
  const stationsLoadingTemplate = (): JSX.Element => (
    <div className={styles.stationsLoading}>
      <Skeleton width={200} height={34} count={5} />
    </div>
  )

  return (
    <BoxedLayout
      title="Precipitações"
      headerLargeButtonAction={(): void => handleModal(ModalType.create)}
      headerLargeButtonLabel="Novo lançamento"
    >
      <LabeledCard title="Filtros">
        <div className={styles.precipitationFilters}>
          <LabeledCardCol>
            <label className={styles.label}>
              Selecione uma ou mais estações
            </label>
            {withLoading(
              <CheckboxButton
                chipButtonMode
                name="stations"
                options={stationsCheckboxList || []}
                onChange={(e): void =>
                  handleStation(
                    e.currentTarget.checked,
                    onlyAlphanumericString(e.currentTarget.value),
                    e.currentTarget.value.includes('external')
                  )
                }
              />
            )(isLoadingStations, stationsLoadingTemplate())}
          </LabeledCardCol>
          <div className={styles.selectPeriod}>
            <div>
              <label className={styles.label} htmlFor="init_date">
                Período
              </label>
              <InputText
                htmlType="date"
                name="init_date"
                onChange={(e): void =>
                  handlePeriod(e.currentTarget.name, e.currentTarget.value)
                }
              />
            </div>
            <div className={styles.selectPeriodEndDate}>
              <InputText
                htmlType="date"
                name="end_date"
                onChange={(e): void =>
                  handlePeriod(e.currentTarget.name, e.currentTarget.value)
                }
              />
            </div>
          </div>
        </div>
      </LabeledCard>

      <BoxedTable
        data={precipitations}
        table={table}
        title="Informações"
        disableNavigation
        hideQuantityLabel
        isLoading={isLoading}
        noDataMessage="Nenhuma informação para ser exibida."
      />

      <PrecipitationCreateModal
        createMeasurement={createMeasurement}
        handleModal={handleModal}
        isModalOpen={isModalOpen.create}
        stationsDroplist={stationsDroplist}
      />

      <PrecipitationUpdateModal
        updateMeasurement={updateMeasurement}
        handleModal={handleModal}
        isModalOpen={isModalOpen.update}
        stationsDroplist={stationsDroplist || []}
        selectedItem={selectedItem}
      />

      <PrecipitationDeleteModal
        deleteMeasurementItem={deleteMeasurementItem}
        handleModal={handleModal}
        isModalOpen={isModalOpen.delete}
        selectedItem={selectedItem}
      />
    </BoxedLayout>
  )
}
export { PrecipitationTemplate }
