import { HandledAnimalProps } from 'app/core/types/handling'

const hasFilledAnimal = (animals: HandledAnimalProps[]): boolean => {
  return animals.some(
    animal =>
      'birth_number' in animal ||
      'electronic_eartag' in animal ||
      'current_weight' in animal
  )
}

export { hasFilledAnimal }
