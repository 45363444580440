import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { formatISO } from 'date-fns'
import { useAuthUser } from 'providers/auth-user'
import { useServiceOrder } from 'providers/service-orders'
import { useServiceOrderFilters } from 'providers/service-orders/filters'

import { Chip, ChipColorVariant, SelectItemProps } from 'components/atoms'

import { useNotifications } from 'app/core/hooks/notifications'
import { ServiceOrderInfoProps } from 'app/core/types/service-order'
import { ServiceType } from 'app/core/types/system'

import styles from './styles.module.scss'
import { ServiceOrdersTemplate } from './template'

const CHIP_CLASSIFICATION: Record<string, ChipColorVariant> = {
  cost: ChipColorVariant.cost,
  invest: ChipColorVariant.invest,
}

export const formattedOptionClassification: (
  option: SelectItemProps
) => ReactNode = ({ label, subLabel }: SelectItemProps) => (
  <div className={styles.formatOptionClassification}>
    <div>{label}</div>
    <div className={styles.formatOptionClassificationSubLabel}>
      {subLabel}
      <Chip
        colorVariant={
          subLabel?.charAt(0) == '7'
            ? CHIP_CLASSIFICATION['invest']
            : CHIP_CLASSIFICATION['cost']
        }
      />
    </div>
  </div>
)

const ServiceOrders: React.FC = () => {
  const { user } = useAuthUser()
  const {
    listServiceOrders,
    serviceOrders,
    serviceOrderPageInfo,
    setFilters,
    isLoading,
  } = useServiceOrder()
  const { notifications, readNotification } = useNotifications(user?.id)
  const {
    containsName,
    formattedDate,
    mechanizationCheckbox,
    generalServiceCheckbox,
  } = useServiceOrderFilters()

  const [page, setPage] = useState<number>(1)
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(true)
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false)
  const [editItem, setEditItem] = useState<ServiceOrderInfoProps>()

  const lastNotification =
    notifications.length > 0 ? notifications[0] : undefined

  const toggleNotificationModal = (): void => {
    setIsNotificationModalOpen(prevState => !prevState)
  }

  const handleReadNotification = useCallback(
    async (notificationId: number) => {
      setIsNotificationModalOpen(false)
      await readNotification(notificationId)
    },
    [readNotification]
  )

  const getServiceType = useCallback((): ServiceType | null | undefined => {
    const isMechanization = mechanizationCheckbox === 'true'
    const isGeneralService = generalServiceCheckbox === 'true'

    if (isMechanization && isGeneralService) return
    if (isMechanization) return ServiceType.mechanization
    if (isGeneralService) return ServiceType.generalService

    return null
  }, [generalServiceCheckbox, mechanizationCheckbox])

  const filters = useMemo(() => {
    return {
      init_date: formatISO(formattedDate.currentDate, {
        representation: 'date',
      }),
      final_date: formatISO(formattedDate.finalDate, {
        representation: 'date',
      }),
      service_type: getServiceType(),
      contains_name: containsName,
      page,
    }
  }, [containsName, formattedDate, getServiceType, page])

  useEffect(() => {
    if (filters)
      setFilters(prevState => ({
        ...prevState,
        ...filters,
      }))
  }, [filters, setFilters])

  useEffect(() => {
    listServiceOrders()
  }, [listServiceOrders])

  useEffect(() => setIsEditDrawerOpen(Boolean(editItem)), [editItem])

  return (
    <ServiceOrdersTemplate
      activeFilters={filters}
      serviceOrders={serviceOrders}
      serviceOrderPageInfo={serviceOrderPageInfo}
      setServiceOrderPage={setPage}
      lastNotification={lastNotification}
      toggleNotificationModal={toggleNotificationModal}
      handleReadNotification={handleReadNotification}
      editItem={editItem}
      setEditItem={setEditItem}
      isEditDrawerOpen={isEditDrawerOpen}
      isNotificationModalOpen={isNotificationModalOpen}
      isLoading={isLoading}
    />
  )
}

export { ServiceOrders }
