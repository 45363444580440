import React from 'react'

import { Icon, IconNames } from '../icon'
import styles from './styles.module.scss'

export type TooltipProps = {
  label: string
  tooltip: string
}

const Tooltip: React.FC<TooltipProps> = ({ label, tooltip }) => {
  return (
    <div className={styles.tooltip}>
      <span>{label}</span>
      <span data-hover={tooltip} className={styles.tooltipIconContainer}>
        <Icon name={IconNames.information} className={styles.tooltipIcon} />
      </span>
    </div>
  )
}

export { Tooltip }
