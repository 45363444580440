import {
  ShutdownReasonResponseProps,
  ShutdownType,
} from 'app/core/types/shutdown'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/shutdown_reason/'

const getShutdownReasons = async (
  type?: ShutdownType
): Promise<ShutdownReasonResponseProps[]> => {
  const response = await http.get(baseUrl, { params: { type } })
  return response.data
}

const postShutdownReason = async (
  type: ShutdownType,
  description: string
): Promise<ShutdownReasonResponseProps> => {
  const response = await http.post(baseUrl, { type, description })
  return response.data
}

const deleteShutdownReason = async (
  id: number
): Promise<ShutdownReasonResponseProps> => {
  const url = `${baseUrl}${id}/`
  const response = await http.delete(url)
  return response.data
}

const patchShutdownReason = async (
  id: number,
  description: string
): Promise<ShutdownReasonResponseProps> => {
  const url = `${baseUrl}${id}/`
  const response = await http.patch(url, { description })
  return response.data
}

export {
  getShutdownReasons,
  postShutdownReason,
  deleteShutdownReason,
  patchShutdownReason,
}
