import { ColumnDef } from '@tanstack/react-table'

import { RemoveButton } from 'components/molecules'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { BreedingServiceAnimalProps } from 'app/core/types/breeding'

const breedingStationMalesColumns = (
  handleRemoveAnimal: (animalId: number) => void,
  isBreedingStationActive?: boolean
): ColumnDef<BreedingServiceAnimalProps>[] => [
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Pai ou Grupo de Repasse',
    accessorKey: 'father_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Avô Paterno',
    accessorKey: 'grandfather_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <RemoveButton
        itemId={info.row.original.id}
        disabled={!isBreedingStationActive}
        handleRemoveItem={handleRemoveAnimal}
      />
    ),
  },
]

export { breedingStationMalesColumns }
