import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { NavigateList } from 'app/core/routes/routes'
import { postCreateDiagnostic } from 'app/core/services'
import { DiagnosticCreateRequestData } from 'app/core/types/breeding'
import { addToast } from 'app/core/utils'

import { DiagnosticsCreateTemplate } from './template'

const DiagnosticsCreate: React.FC = () => {
  const history = useHistory()
  const [createdGroupId, setCreatedGroupId] = useState<number | undefined>()

  const navigateToDetail = (): void =>
    history.push(`${NavigateList.diagnosticsDetails}${createdGroupId}`)

  const handleCreate = async (data: unknown): Promise<void> => {
    const { responsible, date } = data as DiagnosticCreateRequestData
    const parsedDate = new Date(date).toISOString()
    try {
      const data = await postCreateDiagnostic({ date: parsedDate, responsible })
      setCreatedGroupId(data.id)
    } catch (error) {
      addToast({ message: 'Some unexpected error ocurred' })
    }
  }

  return (
    <DiagnosticsCreateTemplate
      handleCreate={handleCreate}
      isSuccessModalOpen={!!createdGroupId}
      navigateToDetail={navigateToDetail}
    />
  )
}

export { DiagnosticsCreate }
