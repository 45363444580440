/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'

import {
  endOfYear,
  parse,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { SelectItemProps } from 'components/atoms'

import {
  HistorialPrecipitationsPeriodProps,
  HistorialPrecipitationsProps,
  HistoricalPrecipitationsResponseData,
  PrecipitationPeriodTypes,
  PrecipitationsChartType,
  PrecipitationsMultiProps,
  PrecipitationsPayloadFormatProps,
  PrecipitationsResponseData,
} from 'app/core/types/dashboard'
import { DashboardHook } from 'app/core/types/hooks'
import { monthName } from 'app/core/utils'

const useDashboard = (): DashboardHook => {
  const allMonths = Array.from({ length: 12 }, (_, i) => i + 1)
  const firstYearWithData = 1982

  const getPrecipitationsPayloadFormat = useCallback(
    (period: PrecipitationPeriodTypes): PrecipitationsPayloadFormatProps => {
      let startDate
      let chartType
      const today = new Date()
      const currentYear = today.getFullYear()
      let endDate = today

      switch (period) {
        case PrecipitationPeriodTypes.last_twelve_months:
          startDate = startOfMonth(subMonths(today, 12))
          chartType = PrecipitationsChartType.month
          break
        case PrecipitationPeriodTypes.last_five_years:
          startDate = startOfYear(subYears(new Date(currentYear - 4, 0, 1), 1))
          endDate = endOfYear(subYears(new Date(currentYear, 0, 1), 1))
          chartType = PrecipitationsChartType.year
          break
        case PrecipitationPeriodTypes.current_year:
          startDate = startOfYear(today)
          chartType = PrecipitationsChartType.month
          break
        case PrecipitationPeriodTypes.since_beginning:
          startDate = new Date(firstYearWithData, 0, 1)
          endDate = endOfYear(subYears(new Date(currentYear, 0, 1), 1))
          chartType = PrecipitationsChartType.year
          break
      }

      return {
        startDate,
        endDate,
        chartType,
      }
    },
    []
  )

  const formatHistoricalData = useCallback(
    (
      data: HistoricalPrecipitationsResponseData
    ): HistorialPrecipitationsProps => {
      const historical: HistorialPrecipitationsPeriodProps[] = allMonths.map(
        month => ({
          month: monthName(month),
          mean: data.historical[month.toString()] || 0,
        })
      )

      const current: HistorialPrecipitationsPeriodProps[][] = Object.entries(
        data.current
      ).map(([year, yearData]) => {
        return allMonths.map(month => {
          const monthKey = month.toString()
          return {
            month: monthName(month),
            monthYear: `${monthName(month)}/${year}`,
            mean:
              (yearData as unknown as Record<string, number>)[monthKey] || 0,
          }
        })
      })

      return {
        historical,
        current,
      }
    },
    []
  )

  const formatYearData = useCallback(
    (
      data: HistoricalPrecipitationsResponseData
    ): HistorialPrecipitationsPeriodProps[] => {
      return allMonths.map((month, index) => {
        const monthFullName = monthName(month, true)
        const capitalizedMonth = `${monthFullName
          .charAt(0)
          .toUpperCase()}${monthFullName.slice(1)}`

        return {
          month: monthName(month),
          monthFullName: capitalizedMonth,
          historicalMean: data.historical[index + 1] || 0,
          mean: data.current[index + 1] || null,
        }
      })
    },
    []
  )

  const formatAllStationsPrecipitations = useCallback(
    (
      precipitations: PrecipitationsResponseData,
      type: PrecipitationsChartType,
      stations: SelectItemProps[]
    ) => {
      const formattedPrecipitations: PrecipitationsMultiProps[] = []
      const byMonth = type === PrecipitationsChartType.month
      const byYear = type === PrecipitationsChartType.year

      if (byMonth) {
        for (const stationId in precipitations) {
          const stationData = precipitations[stationId]
          const stationInfo = stations.find(
            station => String(station.value) === stationId
          )

          if (!stationInfo) {
            continue
          }

          for (const year in stationData) {
            const yearData = stationData[year]

            for (const month in yearData) {
              const mean = yearData[month]
              const label = `${monthName(parseInt(month))}/${year}`
              const stationLabel = stationInfo.label

              const stack = {
                label: stationLabel,
                payload: { mean },
              }

              let existingMonth = formattedPrecipitations.find(
                station => station.label === label
              )

              if (!existingMonth) {
                existingMonth = {
                  label,
                  stacks: {},
                }
                formattedPrecipitations.push(existingMonth)
              }

              existingMonth.stacks[stationLabel] = stack
            }
          }
        }
      }

      if (byYear) {
        for (const station of stations) {
          const stationId = station.value.toString()
          const stationLabel = station.label

          const stationData = precipitations[stationId]

          if (stationData) {
            for (const year in stationData) {
              const yearData = stationData[year]
              const yearLabel = year

              const yearStacks: Record<
                string,
                { label: string; payload: { mean: number } }
              > = {}

              const mean =
                Object.values(yearData).reduce((sum, value) => sum + value, 0) /
                Object.keys(yearData).length

              yearStacks[stationLabel] = {
                label: stationLabel,
                payload: {
                  mean,
                },
              }

              const existingYear = formattedPrecipitations.find(
                station => station.label === yearLabel
              )

              if (existingYear) {
                existingYear.stacks[stationLabel] = yearStacks[stationLabel]
              } else {
                formattedPrecipitations.push({
                  label: yearLabel,
                  stacks: yearStacks,
                })
              }
            }
          }
        }
      }

      formattedPrecipitations.sort((a, b) => {
        const dateA = parse(
          a.label,
          byMonth ? 'MMMM/yyyy' : 'yyyy',
          new Date(),
          {
            locale: ptBR,
          }
        )
        const dateB = parse(
          b.label,
          byMonth ? 'MMMM/yyyy' : 'yyyy',
          new Date(),
          {
            locale: ptBR,
          }
        )
        return dateA.getTime() - dateB.getTime()
      })

      return formattedPrecipitations
    },
    []
  )

  return {
    formatAllStationsPrecipitations,
    formatHistoricalData,
    formatYearData,
    getPrecipitationsPayloadFormat,
  }
}

export { useDashboard }
