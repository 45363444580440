import React from 'react'

import { HerdManagement, ServiceOrders } from 'assets/images'
import { LogoHorizontal } from 'assets/svg'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  Typography,
  TypographyVariant
} from 'components/atoms'

import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom'
import { NavigateList, NavigateParams } from 'app/core/routes/routes'

const SelectServiceTemplate: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = (path): void => history.push(path)

  return (
    <div className={styles.page}>
      <header className={styles.headerContainer} />
      <div className={styles.logo}>
        <img src={LogoHorizontal} draggable={false} />
      </div>
      <div className={styles.whiteBox}>
        <Typography
          text="Qual produto do Grupo Guanabara deseja acessar?"
          variant={TypographyVariant.h5}
          className={styles.titleText}
        />
        <div className={styles.containerBox}>
          <div className={styles.imagesBox}>
            <div className={styles.textBox}>
              <Typography
                text={"Gestão de Rebanhos"}
                variant={TypographyVariant.h2}
                className={styles.serviceText}
              />
              <Button
                className={styles.dashboardButton}
                type={ButtonType.outline}
                label="Acessar"
                size={ButtonSize.medium}
                icon={<Icon name={IconNames['chevron-forward']} />}
                iconPosition={ButtonIconPosition.right}
                onClick={(): void => navigateTo(NavigateList.dashboard)}
              />
            </div>
            <img src={HerdManagement} className={styles.imageBackground} draggable={false} />
          </div>
          <div className={styles.imagesBox}>
            <div className={styles.textBox}>
              <Typography
                text="Boletins de Serviços"
                variant={TypographyVariant.h2}
                className={styles.serviceText}
              />
              <Button
                className={styles.ordersButton}
                type={ButtonType.outline}
                label="Acessar"
                size={ButtonSize.medium}
                icon={<Icon name={IconNames['chevron-forward']} />}
                iconPosition={ButtonIconPosition.right}
                onClick={(): void => navigateTo(NavigateList.serviceOrders)}
              />
            </div>
            <img src={ServiceOrders} className={styles.imageBackground} draggable={false} />
          </div>
        </div>
      </div>
    </div >

  )
}

export { SelectServiceTemplate }
