/* eslint-disable @typescript-eslint/naming-convention */
import Papa from 'papaparse'

import { AnimalFilterProps } from 'app/core/types/animal'
import {
  InseminationAnimalCreateRequestData,
  InseminationAnimalCreateResponseData,
  InseminationAnimalReadResponseData,
  InseminationAnimalUpdateRequestData,
  InseminationAnimalUpdateResponseData,
  InseminationCreateRequestData,
  InseminationFilterProps,
  InseminationProps,
  InseminationReadResponseData,
  InseminationReportResponse,
} from 'app/core/types/hormonal'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/insemination'

const getReadAllInseminations = async (
  filters?: InseminationFilterProps,
  page?: number,
  size?: number
): Promise<InseminationReadResponseData> => {
  const response = await http.get(`${baseUrl}/`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadInsemination = async (
  inseminationId?: string | number
): Promise<InseminationProps> => {
  const url = `${baseUrl}/${inseminationId}/`
  const response = await http.get(url)
  return response.data
}

const getReadAnimalsByInsemination = async (
  inseminationId: string | number,
  page?: number,
  size?: number,
  filters?: Partial<AnimalFilterProps>
): Promise<InseminationAnimalReadResponseData> => {
  const url = `${baseUrl}/${inseminationId}/inseminations/`

  const response = await http.get(url, {
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const postCreateInsemination = async (
  request: InseminationCreateRequestData
): Promise<InseminationProps> => {
  const url = `${baseUrl}/`

  const response = await http.post(url, request)
  return response.data
}

const postAddInseminationAnimal = async (
  inseminationId: string | number,
  request: InseminationAnimalCreateRequestData[]
): Promise<InseminationAnimalCreateResponseData> => {
  const url = `${baseUrl}/${inseminationId}/add_insemination/`
  const response = await http.post(url, request)

  return response.data
}

const postRemoveInseminationAnimal = async (
  inseminationId: number,
  inseminatedAnimalId: number
): Promise<[]> => {
  const url = `${baseUrl}/${inseminationId}/remove_insemination/`

  const response = await http.post(url, [inseminatedAnimalId])
  return response.data
}

const patchUpdateInseminationAnimal = async (
  inseminatedAnimalId: number,
  request: InseminationAnimalUpdateRequestData
): Promise<InseminationAnimalUpdateResponseData> => {
  const url = `${baseUrl}/${inseminatedAnimalId}/update_insemination/`

  const response = await http.patch(url, request)
  return response.data
}

const deleteGroupInsemination = async (groupId: number): Promise<void> => {
  const response = await http.delete(`${baseUrl}/${groupId}/`)

  return response.data
}

const getInseminationReport = async (
  filters: InseminationFilterProps
): Promise<InseminationReportResponse[]> => {
  const response = await http.get(`${baseUrl}/report`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })

  const inseminations: InseminationReportResponse[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    inseminations.push({
      id: Number(item[0]),
      breeding_station_id: Number(item[1]),
      breeding_station_name: item[2],
      iatf_schedule_id: Number(item[3]),
      iatf_schedule_name: item[4],
      animal_id: Number(item[5]),
      birth_number: item[6],
      electronic_eartag: item[7],
      stock_number: item[8],
      insemination_date: new Date(item[9]),
      insemination_type: item[10],
      heat_type: item[11],
      breeding_animal_id: Number(item[12]),
      breeding_animal_stock_number: item[13],
      breeding_group_breeder_id: Number(item[14]),
      breeding_group_name: item[15],
      semen_implanted: Number(item[16]),
      semen_implanted_stock_number: item[17],
      inseminator_id: Number(item[18]),
      responsible: item[19],
    })
  }

  return inseminations
}

export {
  deleteGroupInsemination,
  getReadAllInseminations,
  getReadAnimalsByInsemination,
  getReadInsemination,
  patchUpdateInseminationAnimal,
  postAddInseminationAnimal,
  postCreateInsemination,
  postRemoveInseminationAnimal,
  getInseminationReport,
}
