import React from 'react'
import { SelectServiceTemplate } from './template'

const SelectService: React.FC = () => {
  return (
    <SelectServiceTemplate />
  )
}

export { SelectService }
