import Skeleton from 'react-loading-skeleton'

import styles from './styles.module.css'

const loadingCardsQuantity = 10
const loadingSkeletonColumns = 4

const Loading = (): JSX.Element => {
  return (
    <div data-testid="loading-component">
      <div className={styles.loadingHeader}>
        <Skeleton height={25} width={145} />
        <Skeleton height={25} width={95} />
      </div>

      {[...Array(loadingCardsQuantity)].map((_, cardIndex) => (
        <div key={cardIndex} className={styles.loadingCard}>
          {[...Array(loadingSkeletonColumns)].map((_, skeletonIndex) => (
            <div key={skeletonIndex}>
              <Skeleton height={25} />
              <Skeleton height={25} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const withLoading =
  (
    /**
     * The element (invoked component) to be rendered when loading is done
     */
    WrappedComponent: JSX.Element
  ) =>
  (
    /**
     * Boolean that controls whether the component is loading or not
     */
    isLoading: boolean,
    /**
     * Add this argument if you want to create a customized template layout
     */
    loadingTemplate?: JSX.Element
  ): JSX.Element =>
    isLoading ? (
      loadingTemplate ? (
        loadingTemplate
      ) : (
        <Loading />
      )
    ) : (
      WrappedComponent
    )

export { Loading, withLoading }
