import { Table } from '@tanstack/react-table'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'

import { Icon, IconNames } from 'components/atoms'
import { AnimalDrawer, BoxedTable } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { ReproductionLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import {
  AnimalDrawerType,
  AnimalSexLabel,
  AnimalSexPlural,
} from 'app/core/types/animal'
import {
  BreedingGroupAnimalResponseData,
  BreedingLoadingState,
  BreedingServiceAnimalProps,
  BreedingStationTemplateProps,
  BreedingStationUpdateRequestData,
} from 'app/core/types/breeding'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type BreedingStationDetailsTemplateProps = {
  station?: BreedingStationTemplateProps
  handleUpdateStation: (
    data: Partial<BreedingStationUpdateRequestData>
  ) => Promise<void>
  females?: BreedingGroupAnimalResponseData
  tableFemales: Table<BreedingServiceAnimalProps>
  femalePage: number
  setFemalePage: Dispatch<SetStateAction<number>>
  males?: BreedingGroupAnimalResponseData
  tableMales: Table<BreedingServiceAnimalProps>
  malePage: number
  setMalePage: Dispatch<SetStateAction<number>>
  selectedSex?: AnimalSexLabel
  isDrawerOpen: boolean
  isLoading?: BreedingLoadingState
  isLoadingUpdate: boolean
  setInitialDate: Dispatch<SetStateAction<string>>
  setFinalDate: Dispatch<SetStateAction<string>>
  handleToggleDrawer: (selectedSex?: AnimalSexLabel) => void
  onSubmit: IFormProps['onSubmit']
  resetFilters?: () => void
  filterIsActive?: BreedingLoadingState
  isLoadingExport: boolean
  onExportClick: () => void
}

const BreedingStationDetailsTemplate: React.FC<BreedingStationDetailsTemplateProps> =
  ({
    station,
    handleUpdateStation,
    females,
    tableFemales,
    femalePage,
    setFemalePage,
    males,
    tableMales,
    malePage,
    setMalePage,
    selectedSex,
    isDrawerOpen,
    isLoading,
    isLoadingUpdate,
    handleToggleDrawer,
    onSubmit,
    setInitialDate,
    setFinalDate,
    resetFilters,
    filterIsActive,
    isLoadingExport,
    onExportClick,
  }) => {
    const [openDrawerType, setOpenDrawerType] = useState<AnimalDrawerType>()

    const defaultBoxedTableProps = {
      disableLastCellNavigation: true,
      mainButtonLabel: 'Incluir',
      mainButtonIcon: IconNames['add-circle'],
      noDataMessage:
        filterIsActive?.male || filterIsActive?.female
          ? Messages.ANIMALS_NOT_FOUND
          : Messages.ANIMAL_NOT_ADDED,
    }

    const handleResetFilters = (): void => {
      resetFilters?.call(this)
    }

    const handleFilter = useCallback(
      (drawerType: AnimalDrawerType, sex: AnimalSexLabel): void => {
        setOpenDrawerType(drawerType)
        handleToggleDrawer(sex)
      },
      [handleToggleDrawer]
    )

    const isActive = station?.is_active

    return (
      <>
        {station && (
          <ReproductionLayout
            backButton
            title={station.name}
            initialDate={station.init_date}
            finalDate={station.final_date}
            isLoadingDates={isLoadingUpdate}
            headerButtonLabel="Exportar"
            headerButtonIcon={<Icon name={IconNames.download} />}
            headerButtonAction={onExportClick}
            headerButtonIsLoading={isLoadingExport}
            {...(isAdmin() && {
              setInitialDate,
              setFinalDate,
            })}
            {...(!isActive &&
              isAdmin() && {
                headerLargeButtonAction: (): Promise<void> =>
                  handleUpdateStation({ is_active: true }),
                headerLargeButtonLabel: 'Reativar estação',
                headerLargeButtonIcon: <Icon name={IconNames.pencil} />,
                headerLargeButtonLoading: isLoadingUpdate,
              })}
          >
            {females && (
              <BoxedTable
                title={AnimalSexPlural.female}
                table={tableFemales}
                data={females.items || []}
                currentPage={femalePage}
                totalPages={females.pages}
                totalItems={females.total}
                setPage={setFemalePage}
                disablePaginationScrollTop
                isLoading={isLoading?.female}
                isTableHeaderSticky
                mainButtonAction={(): void =>
                  handleFilter(AnimalDrawerType.include, AnimalSexLabel.female)
                }
                secondaryButtonLabel={'Filtrar'}
                secondaryButtonIcon={IconNames.filter}
                secondaryButtonAction={(): void => {
                  handleFilter(AnimalDrawerType.filter, AnimalSexLabel.female)
                }}
                secondaryButtonClassName={
                  filterIsActive?.female
                    ? styles.filterButtonActive
                    : styles.filterButton
                }
                mainButtonProps={{
                  disabled: !isActive && !isAdmin(),
                  ...(!isActive && !isAdmin()
                    ? {
                        tooltip:
                          Messages.BREEDING_STATION_ONLY_ADMIN_CAN_ADD_ANIMALS,
                        tooltipPosition: 'left',
                      }
                    : {}),
                }}
                {...defaultBoxedTableProps}
              />
            )}
            {males && (
              <BoxedTable
                title={AnimalSexPlural.male}
                table={tableMales}
                data={males.items || []}
                currentPage={malePage}
                totalPages={males.pages}
                totalItems={males.total}
                setPage={setMalePage}
                disablePaginationScrollTop
                isLoading={isLoading?.male}
                isTableHeaderSticky
                mainButtonAction={(): void =>
                  handleFilter(AnimalDrawerType.include, AnimalSexLabel.male)
                }
                secondaryButtonLabel={'Filtrar'}
                secondaryButtonIcon={IconNames.filter}
                secondaryButtonAction={(): void =>
                  handleFilter(AnimalDrawerType.filter, AnimalSexLabel.male)
                }
                secondaryButtonClassName={
                  filterIsActive?.male
                    ? styles.filterButtonActive
                    : styles.filterButton
                }
                mainButtonProps={{
                  disabled: !isActive && !isAdmin(),
                  ...(!isActive && !isAdmin()
                    ? {
                        tooltip:
                          Messages.BREEDING_STATION_ONLY_ADMIN_CAN_ADD_ANIMALS,
                        tooltipPosition: 'left',
                      }
                    : {}),
                }}
                {...defaultBoxedTableProps}
              />
            )}
          </ReproductionLayout>
        )}

        <AnimalDrawer
          includeTitle={`Incluir ${
            selectedSex && AnimalSexPlural[selectedSex]
          }`}
          filterTitle={`Filtrar ${selectedSex && AnimalSexPlural[selectedSex]}`}
          isOpen={isDrawerOpen}
          onToggle={handleToggleDrawer}
          onReset={handleResetFilters}
          onSubmit={onSubmit}
          drawerType={openDrawerType}
        />
      </>
    )
  }

export { BreedingStationDetailsTemplate }
