import React from 'react'

import {
  useAgeGroups,
  useBreed,
  useFarmParameters,
  useInseminators,
  useProducts,
  useReproductionParams,
  useShutdownReasons,
} from 'app/core/hooks'

import { SettingsTemplate } from './template'

const Settings: React.FC = () => {
  const shutdownHookProps = useShutdownReasons()
  const reproductionHookProps = useReproductionParams()
  const ageGroupHookProps = useAgeGroups()
  const breedHookProps = useBreed()
  const productHookProps = useProducts()
  const farmParametersHook = useFarmParameters()
  const inseminatorsHook = useInseminators()

  return (
    <SettingsTemplate
      shutdownHookProps={shutdownHookProps}
      reproductionHookProps={reproductionHookProps}
      ageGroupHookProps={ageGroupHookProps}
      breedHookProps={breedHookProps}
      productHookProps={productHookProps}
      farmParametersHook={farmParametersHook}
      inseminatorsHook={inseminatorsHook}
    />
  )
}

export { Settings }
