import React, { useState } from 'react'

import classNames from 'classnames'

import { DashboardCountCharts } from './components/charts'
import { SectionRow } from './components/section-row'
import { DashboardCountTable } from './components/table'
import { IconNames, Typography, TypographyVariant } from 'components/atoms'
import { ButtonGroup, ButtonGroupSide, withLoading } from 'components/molecules'

import { AnimalSexLabel } from 'app/core/types/animal'
import { DashboardAnimalCount } from 'app/core/types/dashboard'

import { DashboardLoadingTemplate } from '../../loading'
import styles from './styles.module.scss'

type DashboardCountTabProps = {
  animalCount?: DashboardAnimalCount[]
}

enum FarmDataType {
  chart = 'chart',
  table = 'table',
}

const DashboardCountTab: React.FC<DashboardCountTabProps> = ({
  animalCount,
}) => {
  const [farmDataType, setFarmDataType] = useState<FarmDataType>(
    FarmDataType.chart
  )
  return withLoading(
    <>
      <div className={styles.card}>
        <Typography
          text="Total"
          variant={TypographyVariant.h5}
          className={styles.cardTitle}
        />
        <SectionRow
          className={classNames(styles.totalSection, styles.totalSectionFemale)}
          animalCount={animalCount}
          sex={AnimalSexLabel.female}
        />
        <SectionRow
          className={styles.totalSection}
          animalCount={animalCount}
          sex={AnimalSexLabel.male}
        />
        <SectionRow className={styles.totalSection} animalCount={animalCount} />
      </div>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <Typography
            text="Fazendas"
            variant={TypographyVariant.h5}
            className={styles.cardTitle}
          />
          <ButtonGroup
            className={styles.chartButtonGroup}
            leftIconName={IconNames['bar-chart-outline']}
            leftLabel="Gráfico"
            rightIconName={IconNames.list}
            rightLabel="Tabela"
            active={
              farmDataType === FarmDataType.chart
                ? ButtonGroupSide.left
                : ButtonGroupSide.right
            }
            onChange={(side): void =>
              setFarmDataType(
                side === ButtonGroupSide.left
                  ? FarmDataType.chart
                  : FarmDataType.table
              )
            }
          />
        </div>

        {farmDataType === FarmDataType.chart ? (
          <DashboardCountCharts animalCount={animalCount} />
        ) : (
          <DashboardCountTable animalCount={animalCount} />
        )}
      </div>
    </>
  )(animalCount === undefined, DashboardLoadingTemplate)
}

export { DashboardCountTab }
