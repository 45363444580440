import classNames from 'classnames'

import {
  AnimalCategory,
  AnimalCowReproductiveStatus,
} from 'app/core/types/animal'

import { Icon, IconNames } from '../icon'
import { Typography, TypographyVariant } from '../typography'
import styles from './styles.module.scss'

export enum ChipIconPosition {
  left = 'left',
  right = 'right',
}

export enum ChipColorVariant {
  statusActive = 'statusActive',
  statusInactive = 'statusInactive',
  speciesBovine = 'speciesBovine',
  speciesEquine = 'speciesEquine',
  speciesBubaline = 'speciesBubaline',
  sexFemale = 'sexFemale',
  sexMale = 'sexMale',
  shutdownSale = 'shutdownSale',
  shutdownDeath = 'shutdownDeath',
  shutdownOther = 'shutdownOther',
  categoryGrowing = 'categoryGrowing',
  categorySteer = 'categorySteer',
  categoryHeifer = 'categoryHeifer',
  categoryBreedingBull = 'categoryBreedingBull',
  categoryCow = 'categoryCow',
  categoryYearlingBull = 'categoryYearlingBull',
  breedPurebred = 'breedPurebred',
  breedMixed = 'breedMixed',
  breedComposite = 'breedComposite',
  suitableTrue = 'suitableTrue',
  suitableFalse = 'suitableFalse',
  offspringTrue = 'offspringTrue',
  offspringFalse = 'offspringFalse',
  cowPregnant = 'cowPregnant',
  cowOpen = 'cowOpen',
  herdTrue = 'herdTrue',
  herdFalse = 'herdFalse',
  associationTrue = 'associationTrue',
  associationFalse = 'associationFalse',
  defaultTrue = 'defaultTrue',
  defaultFalse = 'defaultFalse',
  cowInseminated = 'cowInseminated',
  breedZebu = 'breedZebu',
  breedEuropean = 'breedEuropean',
  cost = 'cost',
  invest = 'invest',
  secondary = 'secondary',
}

const CHIP_VARIANT_LABELS: { [key in ChipColorVariant]: string } = {
  statusActive: 'Ativo',
  statusInactive: 'Inativo',
  speciesBovine: 'Bovino',
  speciesEquine: 'Equino',
  speciesBubaline: 'Búfalo',
  sexFemale: 'Fêmea',
  sexMale: 'Macho',
  shutdownSale: 'Venda',
  shutdownDeath: 'Morte',
  shutdownOther: 'Outros',
  categoryGrowing: AnimalCategory.growing,
  categorySteer: AnimalCategory.steer,
  categoryHeifer: AnimalCategory.heifer,
  categoryBreedingBull: AnimalCategory.breedingBull,
  categoryCow: AnimalCategory.cow,
  categoryYearlingBull: AnimalCategory.yearlingBull,
  breedPurebred: 'Puro',
  breedMixed: 'Misto',
  breedComposite: 'Composto',
  suitableTrue: 'Apto',
  suitableFalse: 'Não apto',
  offspringTrue: 'Sim',
  offspringFalse: 'Não',
  cowPregnant: AnimalCowReproductiveStatus.pregnant,
  cowOpen: AnimalCowReproductiveStatus.empty,
  herdTrue: 'Sim',
  herdFalse: 'Não',
  associationTrue: 'Sim',
  associationFalse: 'Não',
  defaultTrue: 'Sim',
  defaultFalse: 'Não',
  cowInseminated: 'Vaca inseminada',
  breedZebu: 'Zebu',
  breedEuropean: 'Europeu',
  cost: 'Custo',
  invest: 'Investimento',
  secondary: 'Secundário',
}

const CHIP_VARIANT_ICONS: { [key in ChipColorVariant]?: IconNames } = {
  statusActive: IconNames['radio-button-on'],
  statusInactive: IconNames['radio-button-off'],
  speciesBovine: undefined,
  speciesEquine: undefined,
  speciesBubaline: undefined,
  sexFemale: IconNames.female,
  sexMale: IconNames.male,
  shutdownSale: undefined,
  shutdownDeath: undefined,
  shutdownOther: undefined,
  categoryGrowing: undefined,
  categorySteer: undefined,
  categoryHeifer: undefined,
  categoryBreedingBull: undefined,
  categoryCow: undefined,
  categoryYearlingBull: undefined,
  breedPurebred: undefined,
  breedMixed: undefined,
  breedComposite: undefined,
  suitableTrue: undefined,
  suitableFalse: undefined,
  offspringTrue: undefined,
  offspringFalse: undefined,
  cowPregnant: undefined,
  cowOpen: undefined,
  herdTrue: undefined,
  herdFalse: undefined,
  associationTrue: undefined,
  associationFalse: undefined,
  defaultTrue: undefined,
  defaultFalse: undefined,
  cowInseminated: undefined,
  breedZebu: undefined,
  breedEuropean: undefined,
  cost: undefined,
  invest: undefined,
  secondary: undefined,
}

export interface IChipProps {
  /**
   * The type of the chip
   */
  selected?: boolean
  /**
   * The content of the chip
   */
  label?: string
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * Is the chip disabled?
   */
  disabled?: boolean
  /**
   * An icon from the iconography to display inside of the chip
   */
  icon?: IconNames
  /**
   * An alternative text about the icon to help assistive technology
   */
  iconAlt?: string
  /**
   * The icon position inside the chip (left|right)
   */
  iconPosition?: ChipIconPosition
  /**
   * Classname to add custom css
   */
  className?: string
  /**
   * Special pre-selection of themed colors
   *
   * Icons cannot be manually selected in this mode,
   * since they are automatically set by variants when needed
   */
  colorVariant?: ChipColorVariant
}

const Chip = ({
  label,
  onClick,
  selected = true,
  disabled = false,
  icon,
  iconAlt,
  iconPosition = ChipIconPosition.left,
  colorVariant,
  className,
}: IChipProps): JSX.Element => {
  if (!label && !colorVariant) {
    return <span>-</span>
  }

  return (
    <span
      role="button"
      className={classNames(
        styles.chip,
        styles[onClick ? 'interactive' : ''],
        styles[disabled ? 'disabled' : 'enabled'],
        styles[selected || colorVariant ? 'selected' : 'unselected'],
        styles[iconPosition],
        styles[colorVariant ? colorVariant : 'basic'],
        styles[colorVariant === ChipColorVariant.secondary ? 'secondary' : ''],
        className
      )}
      onClick={onClick}
    >
      {icon && !colorVariant && (
        <Icon
          name={icon}
          alt={iconAlt || ''}
          size="16px"
          className={styles.icon}
        />
      )}

      {colorVariant && (
        <Icon
          name={CHIP_VARIANT_ICONS[colorVariant]}
          alt={iconAlt || ''}
          size="16px"
          className={styles.icon}
        />
      )}

      {label && !colorVariant && (
        <span>
          <Typography
            text={label}
            variant={TypographyVariant.label}
            className={styles.chipLabel}
          />
        </span>
      )}

      {colorVariant && (
        <span>
          <Typography
            text={label ? label : CHIP_VARIANT_LABELS[colorVariant]}
            variant={TypographyVariant.label}
            className={styles.chipLabel}
          />
        </span>
      )}
    </span>
  )
}

export { Chip }
