import { Table } from '@tanstack/react-table'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'

import { ButtonIconPosition, Icon, IconNames } from 'components/atoms'
import {
  AnimalDrawer,
  BoxedTable,
  ISelectButtonItem,
} from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { ReproductionLayout } from 'components/templates'

import { AnimalDrawerType } from 'app/core/types/animal'
import {
  ScheduleAnimalProps,
  ScheduleAnimalResponseData,
  ScheduleProps,
} from 'app/core/types/hormonal'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type ScheduleDetailsTemplateProps = {
  schedule?: ScheduleProps
  animals?: ScheduleAnimalResponseData
  table: Table<ScheduleAnimalProps>
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  page: number
  setPage: Dispatch<SetStateAction<number>>
  resetFilters?: () => void
  filterIsActive?: boolean
  headerSelectButtonItems: ISelectButtonItem[]
  isLoading: boolean
}

const ScheduleDetailsTemplate: React.FC<ScheduleDetailsTemplateProps> = ({
  schedule,
  animals,
  table,
  isDrawerOpen,
  handleToggleDrawer,
  onSubmit,
  page,
  setPage,
  resetFilters,
  filterIsActive,
  headerSelectButtonItems,
  isLoading,
}) => {
  const [openDrawerType, setOpenDrawerType] = useState<AnimalDrawerType>()

  const handleResetFilters = (): void => {
    resetFilters?.call(this)
  }

  const handleFilter = useCallback(
    (drawerType: AnimalDrawerType): void => {
      setOpenDrawerType(drawerType)
      handleToggleDrawer()
    },
    [handleToggleDrawer]
  )

  return (
    <>
      {schedule && (
        <ReproductionLayout
          backButton
          title={schedule.name}
          initialDate={schedule.date_start}
          finalDate={schedule.date_end}
          breedingStationId={schedule.breeding_station_id}
          breedingStationName={schedule.breeding_station_name}
          hormonalProtocolId={schedule.hormonal_protocol_id}
          hormonalProtocolName={schedule.hormonal_protocol_name}
          headerSelectButtonLabel="Planilha"
          headerSelectButtonItems={headerSelectButtonItems}
          headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
          headerSelectButtonIconPosition={ButtonIconPosition.right}
        >
          {animals && (
            <BoxedTable
              title="Animais"
              table={table}
              data={animals.items || []}
              totalItems={animals.total}
              totalPages={animals.pages}
              currentPage={page}
              setPage={setPage}
              quantityLabel="Fêmea"
              mainButtonAction={(): void =>
                handleFilter(AnimalDrawerType.include)
              }
              disableLastCellNavigation={true}
              disableCellsNavigationByIndex={[3, 5, 6, 7]}
              mainButtonLabel="Incluir"
              mainButtonIcon={IconNames['add-circle']}
              noDataMessage={
                filterIsActive
                  ? Messages.ANIMALS_NOT_FOUND
                  : Messages.ANIMAL_NOT_ADDED
              }
              isTableHeaderSticky
              secondaryButtonLabel={'Filtrar'}
              secondaryButtonIcon={IconNames.filter}
              secondaryButtonAction={(): void =>
                handleFilter(AnimalDrawerType.filter)
              }
              secondaryButtonClassName={
                filterIsActive ? styles.filterButtonActive : ''
              }
              isLoading={isLoading}
            />
          )}
        </ReproductionLayout>
      )}

      <AnimalDrawer
        isOpen={isDrawerOpen}
        onToggle={handleToggleDrawer}
        onReset={handleResetFilters}
        onSubmit={onSubmit}
        drawerType={openDrawerType}
        hasIATFScheduleDropdown
        hasAptitudeCheckbox
      />
    </>
  )
}

export { ScheduleDetailsTemplate }
