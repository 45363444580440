import { ColumnDef } from '@tanstack/react-table'

import {
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { isAdmin } from 'app/core/auth'
import { SectorProps } from 'app/core/types/system'

import styles from '../styles.module.scss'

const SectorColumn = (
  handleEditClick: (id: number) => void
): ColumnDef<SectorProps, unknown>[] => [
    {
      accessorKey: 'name',
      header: (): JSX.Element => (
        <Typography
          text="Nome"
          variant={TypographyVariant.caption3}
          className={styles.caption}
        />
      ),
      cell: (info): JSX.Element => (
        <div>
          <Typography
            text={info.row.original.name}
            variant={TypographyVariant.h6}
            className={!info.row.original.status ? styles.inactive : ''}
          />
        </div>
      ),
    },
    {
      accessorKey: 'farm',
      header: (): JSX.Element => (
        <Typography
          text="Fazenda"
          variant={TypographyVariant.caption3}
          className={styles.caption}
        />
      ),
      cell: (info): JSX.Element => (
        <Chip
          label={info.row.original.farm?.name}
          className={!info.row.original.status ? styles.inactive : ''}
        />
      ),
    },
    {
      accessorKey: 'activities',
      header: (): JSX.Element => (
        <Typography
          text="Atividade pecuária"
          variant={TypographyVariant.caption3}
          className={styles.caption}
        />
      ),
      cell: (info): JSX.Element => (
        <div className={styles.activitiesCol}>
          {info.row.original.activities?.map((activity, i) => (
            <Chip
              key={`activity-${i}`}
              label={activity.activity}
              selected={false}
              className={!info.row.original.status ? styles.inactive : ''}
            />
          ))}
        </div>
      ),
    },
    {
      accessorKey: 'status',
      header: (): JSX.Element => (
        <Typography
          text="Status"
          variant={TypographyVariant.caption3}
          className={styles.caption}
        />
      ),
      cell: (info): JSX.Element => (
        <div className={styles.statusCol}>
          <Chip
            className={styles.chip}
            colorVariant={
              info.row.original.status
                ? ChipColorVariant.statusActive
                : ChipColorVariant.statusInactive
            }
          />
          {isAdmin() && (
            <Icon
              onClick={(): void => {
                handleEditClick(info.row.original.id)
              }}
              name={IconNames.pencil}
            />
          )}
        </div>
      ),
    },
  ]

export { SectorColumn }
