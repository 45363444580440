import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primereact/autocomplete'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Icon, IconNames, InputText } from 'components/atoms'
import { Status } from 'components/enums'

import { getReadAllAnimals } from 'app/core/services'
import {
  AnimalFilterProps,
  AnimalListProps,
  AnimalSexLabel,
} from 'app/core/types/animal'
import {
  HeatTypes,
  UpdateAnimalInseminationData,
} from 'app/core/types/hormonal'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from '../styles.module.scss'

type AnimalFilterInputProps = {
  animalId: number
  inseminationId: number | undefined
  inputName: 'semen_implanted' | 'breeding_animal_id'
  updateAnimal: ({
    inseminatedAnimalId,
    inseminationId,
    request,
  }: UpdateAnimalInseminationData) => Promise<void>
  defaultValue: string
  setValue: (value: unknown) => void
  breedingStationId?: number
  heatType: HeatTypes | undefined
}

enum InputMode {
  search = 'search',
  filled = 'filled',
}

const autoCompleteItemTemplate = (item: AnimalListProps): JSX.Element => {
  return (
    <ul>
      {item.name && (
        <li>
          Nome do animal:
          <span>{item.name}</span>
        </li>
      )}
      {item.stock_number && (
        <li>
          Nº de Plantel:
          <span>{item.stock_number}</span>
        </li>
      )}
      {item.birth_number && (
        <li>
          Nº de Nascimento:
          <span>{item.birth_number}</span>
        </li>
      )}
    </ul>
  )
}

const AnimalFilterInput: React.FC<AnimalFilterInputProps> = ({
  animalId,
  inseminationId,
  inputName,
  setValue,
  updateAnimal,
  defaultValue,
  breedingStationId,
  heatType,
}) => {
  const [animalSuggestions, setAnimalSuggestions] = useState<AnimalListProps[]>(
    []
  )
  const [inputMode, setInputMode] = useState<InputMode>(
    defaultValue !== undefined ? InputMode.filled : InputMode.search
  )

  const minimumNumberOfCharacters = 3
  const isSemen = inputName === 'semen_implanted'
  const isBreedingAnimal = inputName === 'breeding_animal_id'

  const { control } = useForm({})

  const searchAnimals = async (
    event: AutoCompleteCompleteEvent
  ): Promise<void> => {
    const { query } = event

    if (query === '' || query.length < minimumNumberOfCharacters) {
      return
    }

    const filters = {
      belongs_to_herd: isSemen ? 'false' : 'true',
      ...(!isSemen && { sex: AnimalSexLabel.male }),
      ...(breedingStationId && {
        breeding_station_with_group: breedingStationId,
      }),
      like_stock_number_or_birth_number_or_electronic_eartag: query,
    } as AnimalFilterProps

    try {
      const animals = await getReadAllAnimals(filters)

      if (animals.items.length === 0) {
        setAnimalSuggestions([])
        return
      }

      setAnimalSuggestions(animals.items)
    } catch (e) {
      addToast({ message: Messages.ANIMALS_NOT_FOUND })
    }
  }

  const handleOnSelect = (e: AutoCompleteSelectEvent): void => {
    const selectedAnimal = e.value as AnimalListProps

    setValue(selectedAnimal.id)

    updateAnimal({
      inseminatedAnimalId: animalId,
      inseminationId,
      request: {
        heat_type: isBreedingAnimal ? HeatTypes.natural_heat : heatType,
        ...(isSemen && {
          semen_implanted: selectedAnimal.id,
          semen_implanted_stock_number: selectedAnimal.stock_number,
        }),
        ...(isBreedingAnimal && {
          breeding_animal_id: selectedAnimal.id,
          breeding_animal_stock_number: selectedAnimal.stock_number,
        }),
      },
    })

    addToast({
      message: isSemen ? 'Sêmen implantado.' : 'Animal reprodutor vinculado.',
      type: 'success',
    })
  }

  useEffect(() => {
    if (defaultValue !== '') {
      setInputMode(InputMode.filled)
    } else {
      setInputMode(InputMode.search)
    }
  }, [defaultValue])

  return (
    <div className={styles.searchAnimal}>
      {inputMode === InputMode.search && (
        <Controller
          name={inputName}
          control={control}
          render={({ field }): JSX.Element => (
            <AutoComplete
              autoFocus
              className={styles.searchAnimalAutoComplete}
              inputId={field.name}
              value={field.value}
              field="stock_number"
              onChange={field.onChange}
              inputRef={field.ref}
              suggestions={animalSuggestions}
              completeMethod={searchAnimals}
              itemTemplate={autoCompleteItemTemplate}
              onSelect={handleOnSelect}
              emptyMessage={Messages.ANIMALS_NOT_FOUND}
              showEmptyMessage
              placeholder={Messages.ANIMAL_SEARCH_MULTIPLE_FILTERS}
            />
          )}
        />
      )}
      {inputMode === InputMode.filled && (
        <InputText
          name={inputName}
          status={defaultValue ? Status.success : undefined}
          onChange={(): void => setInputMode(InputMode.search)}
          value={defaultValue}
          placeholder={Messages.ANIMAL_SEARCH_MULTIPLE_FILTERS}
        />
      )}
      {defaultValue && (
        <Icon
          className={styles.searchAnimalLinkedIcon}
          name={IconNames.checkmark}
        />
      )}
    </div>
  )
}

export { AnimalFilterInput }
