import {
  AnimalUnitPeriodTypes,
  DashboardAnimalCount,
  DashboardAnimalUnit,
  HistorialPrecipitationsPeriodRequest,
  HistoricalPrecipitationsResponseData,
  PrecipitationsPeriodRequest,
  PrecipitationsResponseData,
} from 'app/core/types/dashboard'
import { http } from 'interfaces/http'

const baseUrl = '/dashboard/'

export const getAnimalsCount = async (): Promise<DashboardAnimalCount[]> => {
  const response = await http.get(`${baseUrl}count/`)
  return response.data
}

export const getAnimalsUnit = async (
  period: AnimalUnitPeriodTypes
): Promise<DashboardAnimalUnit[]> => {
  const response = await http.get(`${baseUrl}animal-unit/`, {
    params: {
      period_type: period,
    },
  })
  return response.data
}

export const getPrecipitations = async (
  request: PrecipitationsPeriodRequest
): Promise<PrecipitationsResponseData> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { start_date, end_date, station_ids, farm_id } = request

  const response = await http.get(`${baseUrl}precipitation/`, {
    paramsSerializer: { indexes: null },
    params: {
      start_date,
      end_date,
      station_ids,
      farm_id,
    },
  })
  return response.data
}

export const getHistoricalPrecipitations = async (
  request: HistorialPrecipitationsPeriodRequest
): Promise<HistoricalPrecipitationsResponseData> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { station_id, type } = request

  const response = await http.get(`${baseUrl}mean-precipitation/`, {
    paramsSerializer: { indexes: null },
    params: {
      station_id,
      type,
    },
  })
  return response.data
}
