import { AxiosResponse } from 'axios'

import {
  CollectiveMovementCreateRequestData,
  CollectiveMovementsCreateProps,
  ExportCollectiveMovementCreateRequestData,
  ImportCollectiveMovementProps,
} from 'app/core/types/collective-movements'
import { parseSelectedFileToBlob } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/collective_movement'

const postCreateCollectiveMovement = async (
  request: CollectiveMovementCreateRequestData
): Promise<[]> => {
  const response = await http.post(`${baseUrl}/`, request)
  return response.data
}

const postImportCollectiveMovement = async (
  props: ImportCollectiveMovementProps
): Promise<CollectiveMovementsCreateProps[]> => {
  const formData = new FormData()

  const fileBlob = parseSelectedFileToBlob(props.file)

  formData.append('file', fileBlob.data, fileBlob.name)

  const response = await http.post(
    `${baseUrl}/import/?target_sector_id=${props.target_sector_id}`,
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return response.data
}

const getImportCollectiveMovementTemplate = async (): Promise<
  AxiosResponse<Blob>
> => {
  return http.get(`${baseUrl}/import_template/`, {
    responseType: 'blob',
  })
}

const getExportCollectiveMovement = async ({
  animal_ids,
  origin_farm_id,
  origin_sector_id,
  target_farm_id,
  target_sector_id,
}: ExportCollectiveMovementCreateRequestData): Promise<AxiosResponse<Blob>> => {
  const url = `${baseUrl}/export/`

  return http.get(url, {
    paramsSerializer: { indexes: null },
    params: {
      animal_ids,
      origin_farm_id,
      origin_sector_id,
      target_farm_id,
      target_sector_id,
    },
    responseType: 'blob',
  })
}

export {
  getExportCollectiveMovement,
  getImportCollectiveMovementTemplate,
  postCreateCollectiveMovement,
  postImportCollectiveMovement,
}
