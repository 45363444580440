import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAnimal } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalCreateProps } from 'app/core/types/animal'
import { StorageKeys } from 'app/core/types/storage'
import { SectorProps } from 'app/core/types/system'

import { animalListColumns } from '../tables'
import { AnimalReviewTemplate } from './template'

const AnimalReview: React.FC = () => {
  const history = useHistory()

  const reviewAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.animals_review) as string
  ) as AnimalCreateProps[]
  const savedSector = JSON.parse(
    localStorage.getItem(StorageKeys.animals_sector_review) as string
  ) as SectorProps
  const { addAnimal } = useAnimal({})

  if (!savedSector || !reviewAnimals) {
    history.push(NavigateList.animalList)
  }

  const animalsBreedFormat = reviewAnimals?.map(animal => {
    const {
      is_composite_breed: isCompositeBreed,
      breed_first_id: breedFirstId,
      breed_first_percentage: breedFirstPercentage,
      breed_second_id: breedSecondId,
      breed_second_percentage: breedSecondPercentage,
      stock_number: stockNumber,
    } = animal

    if (stockNumber) {
      animal.stock_number = stockNumber
    }

    if (!isCompositeBreed) {
      animal.breeds = []
    }

    if (animal.breeds && breedFirstId && breedFirstPercentage) {
      animal.breeds.push({
        breed_id: parseInt(breedFirstId as string),
        percentage: parseFloat(breedFirstPercentage),
      })
    }

    if (animal.breeds && breedSecondId && breedSecondPercentage) {
      animal.breeds.push({
        breed_id: parseInt(breedSecondId as string),
        percentage: parseFloat(breedSecondPercentage),
      })
    }

    return animal
  })

  const [animals, setAnimals] =
    useState<AnimalCreateProps[]>(animalsBreedFormat)

  const defaultAnimalsTable = useReactTable({
    data: reviewAnimals,
    columns: animalListColumns({ hasAnimalId: false }),
    getCoreRowModel: getCoreRowModel(),
  })

  const handleAnimals = (): void => {
    addAnimal({
      animals,
      setAnimals,
      sector: savedSector,
      route: NavigateList.animalFeedback,
    })
  }

  return (
    <AnimalReviewTemplate
      handleAnimals={handleAnimals}
      sector={savedSector.name}
      animals={reviewAnimals}
      table={defaultAnimalsTable}
    />
  )
}

export { AnimalReview }
