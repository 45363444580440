import React from 'react'

import classNames from 'classnames'

import {
  Button,
  ButtonSize,
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { AnimalHistoryProps, IAnimalHeader } from 'app/core/types/animal'
import { dateTimeFormat } from 'app/core/utils'

import {
  CHIP_BREEDS,
  CHIP_CATEGORY,
  CHIP_SEX,
  CHIP_SHUTDOWN_REASON,
  CHIP_SPECIES,
} from '../../consts'
import styles from './styles.module.scss'

interface IAnimalHeaderProps {
  header: IAnimalHeader
  shutdown?: AnimalHistoryProps
  buttonAction: () => void
  isAdminUser: boolean
}

const AnimalHeader: React.FC<IAnimalHeaderProps> = ({
  header,
  shutdown,
  buttonAction,
  isAdminUser,
}) => {
  const shutdownReason = shutdown?.data.find(
    item => item.field === 'reason'
  )?.value
  const shutdownDate = shutdown?.data.find(
    item => item.field === 'shutdown_date'
  )?.value as string
  return (
    <header className={styles.header}>
      <div className={styles.statusContainer}>
        <div className={styles.status}>
          <Chip
            colorVariant={
              header.is_active
                ? ChipColorVariant.statusActive
                : ChipColorVariant.statusInactive
            }
          />
          {!header.is_active && shutdown && (
            <>
              <Typography text="Baixa" variant={TypographyVariant.caption3} />
              {shutdownReason && (
                <Chip colorVariant={CHIP_SHUTDOWN_REASON[shutdownReason]} />
              )}
              <Typography text="Em" variant={TypographyVariant.caption3} />
              {shutdownDate && (
                <Typography
                  text={dateTimeFormat(shutdownDate)}
                  variant={TypographyVariant.caption3}
                  className={styles.shutdownDate}
                />
              )}
            </>
          )}
          <Chip colorVariant={CHIP_SPECIES[header.species]} />
          {header.breed && <Chip colorVariant={CHIP_BREEDS[header?.breed]} />}
          <Chip colorVariant={CHIP_SEX[header.sex]} />
        </div>

        {(header.is_active || isAdminUser) && (
          <Button
            label={header.is_active ? 'Editar ficha' : 'Reverter Baixa'}
            size={ButtonSize.medium}
            icon={<Icon name={IconNames.pencil} />}
            onClick={buttonAction}
          />
        )}
      </div>

      <div className={styles.identifiers}>
        <Typography
          text={header.name || 'Sem nome'}
          variant={TypographyVariant.h4}
          className={classNames(styles.name, header.name ? '' : styles.empty)}
        />

        <Chip colorVariant={CHIP_CATEGORY[header.category]} />

        <div className={styles.numbersWrapper}>
          <div className={styles.stockNumberContainer}>
            <Typography
              text="Nº de plantel"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={header.stock_number || 'Sem número'}
              variant={TypographyVariant.h3}
              className={classNames(
                styles.stockNumber,
                header.stock_number ? '' : styles.empty
              )}
            />
          </div>

          <div className={styles.numbersSeparator} />

          <div className={styles.earTagNumberContainer}>
            <Typography
              text="Nº do brinco eletrônico"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={header.electronic_eartag?.toString() || 'Sem brinco'}
              variant={TypographyVariant.h3}
              className={classNames(
                styles.earTagNumber,
                header.electronic_eartag ? '' : styles.empty
              )}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export { AnimalHeader }
