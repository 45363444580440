import { FarmCreateRequestData, FarmProps, ZipCodeResponseData } from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/farm/'
const zipCodeUrl = '/system_parameters/zip_code/'

const getReadAllFarms = async (): Promise<FarmProps[]> => {
  const response = await http.get(baseUrl)
  return response.data
}

const postCreateFarm = async (
  request: FarmCreateRequestData
): Promise<FarmProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const postEditFarm = async (
  farmId: number,
  request: FarmCreateRequestData
): Promise<FarmProps> => {
  const response = await http.patch(`${baseUrl}${farmId}/`, request)
  return response.data
}


const getReadZipCode = async (zipCode: number): Promise<ZipCodeResponseData> => {
  const response = await http.get(`${zipCodeUrl}${zipCode}/`)
  return JSON.parse(response.data);
}

export { getReadAllFarms, postCreateFarm, getReadZipCode, postEditFarm }
