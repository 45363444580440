import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectedFiles } from 'use-file-picker'

import { postCreateShutdown } from 'app/core/services'
import { AnimalShutdownHookProps, ShutdownHook } from 'app/core/types/hooks'
import {
  ShutdownCreateProps,
  ShutdownFailedProps,
  ShutdownReasonStorageProps,
  ShutdownRequestProps,
} from 'app/core/types/shutdown'
import { StorageKeys } from 'app/core/types/storage'
import {
  addToast,
  dateForFileName,
  dateTimeISOFormat,
  downloadFile,
  generateXlsxTemplate,
  getPlural,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { xlsxToShutdown } from './helpers'

const useShutdown = (): ShutdownHook => {
  const history = useHistory()

  const savedShutdown = JSON.parse(
    localStorage.getItem(StorageKeys.shutdown_review) as string
  ) as ShutdownCreateProps[]
  const savedShutdownType = JSON.parse(
    localStorage.getItem(StorageKeys.shutdown_type_review) as string
  ) as ShutdownReasonStorageProps
  const savedShutdownDate = localStorage.getItem(
    StorageKeys.shutdown_date_last_review
  ) as string
  const savedFailedAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.shutdown_review_failed) as string
  ) as ShutdownFailedProps[]

  const [shutdownDate, setShutdownDate] = useState(
    savedShutdownDate || new Date().toISOString()
  )
  const [failedAnimals, setFailedAnimals] = useState<ShutdownFailedProps[]>(
    () => savedFailedAnimals || []
  )
  const [animals, setAnimals] = useState<ShutdownCreateProps[]>(
    savedShutdown || []
  )
  const [isLoading, setIsLoading] = useState(false)

  const addShutdown = async (props: AnimalShutdownHookProps): Promise<void> => {
    const { animals, shutdownDescription, route } = props

    const shutdownEntries = animals.map(animal => ({
      animal_id: animal.id,
      weight: animal.weight || null,
      shutdown_reason_id:
        animal.shutdown_description || parseInt(shutdownDescription),
      shutdown_date: dateTimeISOFormat(shutdownDate),
    })) as ShutdownRequestProps[]

    try {
      setIsLoading(true)

      const response = await postCreateShutdown(shutdownEntries)

      const failedAnimals = [] as ShutdownFailedProps[]
      let successfulAnimals = animals

      if (response.length > 0) {
        response.forEach(error => {
          const animalId = Number(Object.keys(error)[0])
          const errorMessage = error[animalId]

          const failedAnimal = animals.find(state => state?.id === animalId)

          if (failedAnimal) {
            failedAnimals.push({
              stock_number: failedAnimal.stock_number,
              electronic_eartag: failedAnimal.electronic_eartag,
              birth_number: failedAnimal.birth_number,
              error_message: errorMessage,
            })
          }

          successfulAnimals = [
            ...successfulAnimals.filter(animal => animal.id !== animalId),
          ]
        })

        setFailedAnimals(failedAnimals)
        setAnimals(successfulAnimals)

        localStorage.setItem(
          StorageKeys.shutdown_review_failed,
          JSON.stringify(failedAnimals)
        )
        localStorage.setItem(
          StorageKeys.shutdown_date_last_review,
          shutdownDate
        )

        addToast({
          message: `Não foi possível baixar ${getPlural(
            'animal',
            failedAnimals.length,
            true
          )}. Verifique a planilha de inconsistências e tente novamente.`,
        })
      }

      localStorage.setItem(
        StorageKeys.shutdown_review,
        JSON.stringify(successfulAnimals)
      )

      if (successfulAnimals.length > 0) {
        addToast({
          message: `${successfulAnimals.length} animais baixados com sucesso.`,
          type: 'success',
        })
      }
      history.push(route)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const importShutdown = async (file: SelectedFiles): Promise<void> => {
    try {
      setIsLoading(true)

      const shutdowns = (await xlsxToShutdown(
        file.filesContent[0].content
      )) as ShutdownCreateProps[]

      setAnimals(prevState => [...prevState, ...shutdowns])

      setIsLoading(false)

      addToast({
        message: Messages.SHUTDOWN_IMPORT_SUCCESSFUL,
        type: 'success',
      })
    } catch (e) {
      setIsLoading(false)
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const exportFailedAnimals = (): void => {
    const templateFile = generateXlsxTemplate([
      [
        'Nº de Plantel',
        'Nº de Brinco eletrônico',
        'Nº de Nascimento',
        'Inconsistência',
      ],
      ...failedAnimals.map(obj => Object.values(obj)),
    ])

    downloadFile({
      data: templateFile,
      fileName: `baixas-${dateForFileName()}`,
    })
  }

  const removeStorage = (): void => {
    localStorage.removeItem(StorageKeys.shutdown_date_last_review)
    localStorage.removeItem(StorageKeys.shutdown_descriptions)
    localStorage.removeItem(StorageKeys.shutdown_last_review)
    localStorage.removeItem(StorageKeys.shutdown_review)
    localStorage.removeItem(StorageKeys.shutdown_review_failed)
    localStorage.removeItem(StorageKeys.shutdown_type_last_review)
    localStorage.removeItem(StorageKeys.shutdown_type_review)
  }

  return {
    addShutdown,
    animals,
    setAnimals,
    exportFailedAnimals,
    failedAnimals,
    importShutdown,
    savedShutdownType,
    shutdownDate,
    setShutdownDate,
    removeStorage,
    isLoading,
  }
}

export { useShutdown }
