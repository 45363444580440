import { ColumnDef } from '@tanstack/react-table'

import { Icon, IconNames } from 'components/atoms'

import { BreedProps } from 'app/core/types/system'

import styles from '../../styles.module.scss'

const breedColumns = (
  handleEditClick: (item: BreedProps) => void,
  handleRemoveClick: (item: BreedProps) => void
): ColumnDef<BreedProps, unknown>[] => [
  {
    header: 'Espécie',
    accessorKey: 'specie',
  },
  {
    header: 'Raça',
    accessorKey: 'description',
  },
  {
    header: 'Abreviação',
    accessorKey: 'abbreviation',
  },
  {
    header: 'Europeu?',
    accessorKey: 'is_european',
    cell: (info): JSX.Element => <span>{info.getValue() ? 'Sim' : 'Não'}</span>,
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <div className={styles.tableActions}>
        <Icon
          name={IconNames.pencil}
          onClick={(): void => handleEditClick(info.row.original)}
        />
        <Icon
          name={IconNames.close}
          onClick={(): void => handleRemoveClick(info.row.original)}
        />
      </div>
    ),
  },
]

export { breedColumns }
