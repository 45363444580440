import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { BoxedTable } from 'components/organisms'

import { SectorProps } from 'app/core/types/system'

import styles from '../styles.module.scss'
import { SectorColumn } from './column'

type SectorTableProps = {
  sectors: SectorProps[]
  onEditClick?: (sectorId: number) => void
}

const SectorTable: React.FC<SectorTableProps> = ({ sectors, onEditClick }) => {
  const sectorTable = useReactTable({
    data: sectors,
    columns: SectorColumn(sectorId => {
      if (onEditClick) onEditClick(sectorId)
    }),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <BoxedTable
        className={styles.sectorTable}
        data={sectors}
        table={sectorTable}
        hideQuantityLabel
        disableNavigation
      />
    </>
  )
}

export { SectorTable }
