import { createColumnHelper } from '@tanstack/react-table'

import { Chip, IconNames } from 'components/atoms'

import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import { UserEntity, UserType, UserTypeLabel } from 'app/core/types/user'

import styles from '../../styles.module.scss'

const columnHelper = createColumnHelper<UserEntity>()

const userColumns = (navigateTo: NavigateParams) => {
  const onUserClick = (row: UserEntity) =>
    navigateTo(NavigateList.userUpdate, {
      id: row.id,
      email: row.email as string,
      type: row.type as UserType,
      name: row.name as string,
      farm_name: row.farm_name as string,
      is_active: row.is_active as boolean,
      registration_number: row.registration_number as number,
      birth_date: row.birth_date as string,
    })

  return [
    columnHelper.accessor('name', {
      header: 'Nome',
      cell: (info): JSX.Element => (
        <span
          className={info.row.original.is_active ? '' : styles.nameDisabled}
        >
          {info.row.original.name}
        </span>
      ),
      meta: {
        onClick: info => onUserClick(info),
      },
    }),
    columnHelper.accessor('email', {
      header: 'E-mail',
      cell: (info): JSX.Element => (
        <span
          className={info.row.original.is_active ? '' : styles.nameDisabled}
        >
          {info.row.original.email}
        </span>
      ),
      meta: {
        onClick: info => onUserClick(info),
      },
    }),
    columnHelper.accessor('type', {
      header: 'Tipo',
      cell: (info): JSX.Element => (
        <Chip
          label={UserTypeLabel[info.row.original.type]}
          className={styles.chipType}
        />
      ),
      meta: {
        onClick: info => onUserClick(info),
      },
    }),
    columnHelper.accessor('farm_name', {
      header: 'Fazenda',
      cell: (info): JSX.Element => (
        <Chip label={info.row.original.farm_name} className={styles.chipFarm} />
      ),
      meta: {
        onClick: info => onUserClick(info),
      },
    }),
    columnHelper.accessor('is_active', {
      header: 'Status',
      cell: (info): JSX.Element => (
        <Chip
          label={info.row.original.is_active ? 'Ativo' : 'Inativo'}
          icon={
            info.row.original.is_active
              ? IconNames['radio-button-on']
              : IconNames['radio-button-off']
          }
          className={
            info.row.original.is_active
              ? styles.chipActive
              : styles.chipDisabled
          }
          disabled={!info.row.original.is_active}
        />
      ),
      meta: {
        onClick: info => onUserClick(info),
      },
    }),
  ]
}

export { userColumns }
