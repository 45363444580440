import { AnimalSex } from 'app/core/types/animal'
import {
  BreedingGroupAnimalResponseData,
  BreedingServiceAnimalReport,
  BreedingStationAnimalResponseData,
} from 'app/core/types/breeding'
import {
  addToast,
  breedingGroupIdFormat,
  downloadFile,
  generateXlsxTemplate,
  getFormattedDateValueOrNull,
  getValueOrNull,
} from 'app/core/utils'
import { Messages } from 'config/messages'

export enum BreedingAnimalReportType {
  station,
  group,
}

export type GetBreedingAnimalsReportParams = {
  type: BreedingAnimalReportType
  id: number
  page: number
  femalesTotal: number
  malesTotal: number
  breedingGroupName?: string
}

export type BreedingAnimalReportFactory = {
  getFemales: (
    id: number,
    page: number,
    total: number
  ) => Promise<
    BreedingStationAnimalResponseData | BreedingGroupAnimalResponseData
  >
  getMales: (
    id: number,
    page: number,
    total: number
  ) => Promise<
    BreedingStationAnimalResponseData | BreedingGroupAnimalResponseData
  >
}

export const createGetBreedingAnimalReportParams = (
  id: number,
  femaleTotal: number,
  maleTotal: number,
  type?: BreedingAnimalReportType,
  breedingGroupName?: string,
): GetBreedingAnimalsReportParams => ({
  id,
  page: 1,
  femalesTotal: femaleTotal > 0 ? femaleTotal : 1,
  malesTotal: maleTotal > 0 ? maleTotal : 1,
  type: type === undefined ? BreedingAnimalReportType.station : type,
  breedingGroupName,
})

const getBreedingAnimals = async (
  factory: BreedingAnimalReportFactory,
  params: GetBreedingAnimalsReportParams
): Promise<BreedingServiceAnimalReport[]> => {
  const femaleData = await factory.getFemales(
    params.id,
    params.page,
    params.femalesTotal
  )

  const malesData = await factory.getMales(
    params.id,
    params.page,
    params.malesTotal
  )

  const reportFemales = femaleData.items.map(female => ({
    ...female,
    sex: AnimalSex.female,
  })) as BreedingServiceAnimalReport[]

  const reportMales = malesData.items.map(male => ({
    ...male,
    sex: AnimalSex.male,
  })) as BreedingServiceAnimalReport[]

  return [...reportFemales, ...reportMales]
}

const getReport = (
  params: GetBreedingAnimalsReportParams,
  animals: BreedingServiceAnimalReport[]
): Blob => {
  const sheetHeader = [
    'Nº de Plantel',
    'Nº de Brinco eletrônico',
    'Sexo',
    'Nascimento',
    'Último Parto',
    'Status Reprodutivo',
    'Pai/Grupo de repasse',
    'Avô paterno',
  ]

  const formattedAnimals = animals.map(animal => {
    return [
      getValueOrNull(animal.stock_number),
      getValueOrNull(animal.electronic_eartag),
      getValueOrNull(animal.sex),
      getFormattedDateValueOrNull(new Date(animal.birth_date)),
      animal.last_calving
        ? getFormattedDateValueOrNull(new Date(animal.last_calving))
        : null,
      getValueOrNull(animal.reproductive_status),
      getValueOrNull(animal.father_number),
      getValueOrNull(animal.grandfather_number),
    ]
  })

  if (params.type === BreedingAnimalReportType.group) {
    sheetHeader.unshift('N° do Grupo de Repasse', 'Nome')

    formattedAnimals.forEach(animal => {
      animal.unshift(breedingGroupIdFormat(params.id), params.breedingGroupName)
    })
  }

  const templateFile = generateXlsxTemplate([sheetHeader, ...formattedAnimals])

  return templateFile
}

export const exportBreedingAnimals = async (
  factory: BreedingAnimalReportFactory,
  params: GetBreedingAnimalsReportParams,
  templateFileName: string
): Promise<void> => {
  addToast({
    message: Messages.GENERATING_EXPORT_FILE,
    type: 'info',
  })

  const animals = await getBreedingAnimals(factory, params)

  downloadFile({
    data: getReport(params, animals),
    fileName: templateFileName,
  })

  addToast({ message: Messages.ANIMAL_EXPORTED_SUCCESS, type: 'success' })
}
