/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  useBreedingStation,
  useInsemination,
  useSchedule,
  useSchedules,
} from 'app/core/hooks'
import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import 'app/core/types/breeding'
import {
  HeatTypes,
  InseminationCreateFormatData,
  InseminationTypes,
} from 'app/core/types/hormonal'
import { addToast, dateTimeISOFormat, dateToday } from 'app/core/utils'
import { Messages } from 'config/messages'

import { InseminationCreateTemplate } from './template'

const InseminationCreate: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = (path, state?): void =>
    history.push(path, state)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [inseminationType, setInseminationType] = useState<InseminationTypes>()
  const [heatType, setHeatType] = useState<HeatTypes>()
  const [inseminationId, setInseminationId] = useState<number>()
  const [selectedScheduleId, setSelectedScheduleId] = useState<string>()

  const { addInsemination } = useInsemination({})
  const { schedulesDroplist } = useSchedules({ size: 50 })
  const { schedule } = useSchedule({
    scheduleId: selectedScheduleId,
  })
  const { stationsDroplistOptions } = useBreedingStation({ size: 500 })

  const handleCreateInsemination = async (data: unknown): Promise<void> => {
    const dataCopy = data as InseminationCreateFormatData
    const request = schedule
      ? ({
          ...dataCopy,
          date: dateTimeISOFormat(dateToday),
          breeding_station_id: {
            label: schedule?.breeding_station_name,
            value: String(schedule.breeding_station_id),
          },
        } as InseminationCreateFormatData)
      : dataCopy

    if (!request.breeding_station_id) {
      addToast({ message: Messages.BREEDING_STATION_REQUIRED })

      return
    }

    addInsemination(request, setInseminationId, setIsModalOpen)
  }

  const navigateToDetails = (): void => {
    navigateTo(`${NavigateList.inseminationDetails}${inseminationId}`, {
      inseminationType,
      heatType,
    })
  }

  return (
    <InseminationCreateTemplate
      navigateTo={navigateTo}
      isModalOpen={isModalOpen}
      setSelectedScheduleId={setSelectedScheduleId}
      schedule={schedule}
      schedulesDroplist={schedulesDroplist}
      inseminationType={inseminationType}
      setInseminationType={setInseminationType}
      heatType={heatType}
      setHeatType={setHeatType}
      breedingStationOptions={stationsDroplistOptions}
      handleCloseModal={navigateToDetails}
      onSubmit={handleCreateInsemination}
    />
  )
}

export { InseminationCreate }
