import classNames from 'classnames'

import { Typography, TypographyVariant } from 'components/atoms'

import { AnimalSexLabel, AnimalSexPlural } from 'app/core/types/animal'
import { DashboardAnimalCount } from 'app/core/types/dashboard'

import styles from './styles.module.scss'

type SectionRowItemProps = { label: string; value: string; isActive?: boolean }

const SectionRowItem: React.FC<SectionRowItemProps> = ({
  label,
  value,
  isActive = false,
}) => {
  return (
    <div
      className={classNames(
        styles.totalSectionRowItem,
        isActive && styles.totalSectionRowItemActive
      )}
    >
      <Typography
        text={label}
        variant={TypographyVariant.caption3}
        className={styles.totalSectionRowItemLabel}
      />
      <Typography
        text={value}
        variant={TypographyVariant.h4}
        className={styles.totalSectionRowItemValue}
      />
    </div>
  )
}

type SectionRowProps = {
  className: string
  sex?: AnimalSexLabel
  animalCount?: DashboardAnimalCount[]
}

export const SectionRow: React.FC<SectionRowProps> = ({
  animalCount,
  sex,
  className,
}) => {
  const titleIndex = {
    [AnimalSexLabel.female]: AnimalSexPlural.female,
    [AnimalSexLabel.male]: AnimalSexPlural.male,
  }
  const title = sex ? titleIndex[sex] : undefined

  const ageGroupCountSum =
    animalCount
      ?.map(count => {
        return count.age_grouped_counts?.filter(
          ageGroup => ageGroup.animal_sex === sex
        )
      })
      .flat()
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr.label]: (acc[curr.label] ?? 0) + curr.count,
        }),
        {} as Record<string, number>
      ) ?? 0

  const totalSum =
    animalCount
      ?.map(count => count.age_grouped_counts)
      .flat()
      .reduce((acc, curr) => acc + curr.count, 0) ?? 0

  const groups = !sex ? { total: totalSum } : ageGroupCountSum

  const ageGroups = (Object.values(animalCount?.[0].age_grouped_counts ?? {})
    .map(item => {
      if (item.animal_sex === sex) return item.label
    })
    .filter(item => item !== undefined) ?? []) as string[]

  const total = Object.values(groups).reduce((sum, item) => sum + item, 0)

  const periodSeparator = Intl.NumberFormat('de-DE')

  return (
    <div className={className}>
      <Typography
        text={title ?? 'Totais'}
        variant={TypographyVariant.caption2}
        className={styles.totalSectionTitle}
      />
      <div className={styles.totalSectionRow}>
        {ageGroups.map((item, index) => (
          <SectionRowItem
            key={`age-group-${index}`}
            label={item}
            value={periodSeparator.format(groups && groups[item])}
          />
        ))}
        <SectionRowItem
          label={title ? `Total de ${title}` : 'Total Geral'}
          value={periodSeparator.format(total)}
          isActive
        />
      </div>
    </div>
  )
}
