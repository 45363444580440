import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

import { ServiceType } from 'app/core/types/system'

import { serviceTypesListColumns } from './tables'
import { ServiceTypesTabTemplate } from './template'

export type ServiceTypeProps = {
  id: number
  name: keyof typeof ServiceType
}

const ServiceTypesTab: React.FC = () => {
  const [globalFilter, setGlobalFilter] = useState('')

  const serviceTypes = (
    Object.values(ServiceType) as unknown as (keyof typeof ServiceType)[]
  ).map((key, index) => ({ id: index, name: key })) as ServiceTypeProps[]

  const table = useReactTable({
    data: serviceTypes || [],
    columns: serviceTypesListColumns(),
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <ServiceTypesTabTemplate table={table} setGlobalFilter={setGlobalFilter} />
  )
}

export { ServiceTypesTab }
