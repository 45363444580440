import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'

import {
  Chip,
  InputText,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { NavigateList } from 'app/core/routes/routes'
import { DefaultLinkProps } from 'app/core/types/breeding'
import { addToast, dateTimeFormat } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type ReproductionLayoutSectionProps = {
  title: string
  linkUrl?: string
  dateValues?: string[]
  setInitialDate?: Dispatch<SetStateAction<string>>
  setFinalDate?: Dispatch<SetStateAction<string>>
  singleValue?: string | JSX.Element
  multipleValues?: DefaultLinkProps[]
  isLoadingDates?: boolean
}

type ButtonNavigationReturnProps = {
  role: string
  onClick: () => void
}

enum DateEnum {
  initialDate = 'initialDate',
  finalDate = 'finalDate',
}

const ReproductionLayoutSection: React.FC<ReproductionLayoutSectionProps> = ({
  linkUrl,
  title,
  dateValues,
  setInitialDate,
  setFinalDate,
  singleValue,
  multipleValues,
  isLoadingDates: isLoading,
}) => {
  const history = useHistory()

  const navigationButtonProps = (url: string): ButtonNavigationReturnProps => ({
    role: 'button',
    onClick: (): void => history.push(url),
  })

  const [isInitialDateInputVisible, setIsInitialDateInputVisible] =
    useState(false)
  const [isFinalDateInputVisible, setIsFinalDateInputVisible] = useState(false)

  const dateInputRef = useRef<HTMLInputElement>(null)

  const initialDate = dateValues && dateTimeFormat(dateValues[0])
  const initialDateFormat = dateValues && dateTimeFormat(dateValues[0], true)
  const finalDate = dateValues && dateTimeFormat(dateValues[1])
  const finalDateFormat = dateValues && dateTimeFormat(dateValues[1], true)

  const toggleInitialDateInput = (): void => {
    setIsInitialDateInputVisible(!isInitialDateInputVisible)
  }

  const toggleFinalDateInput = (): void => {
    setIsFinalDateInputVisible(!isFinalDateInputVisible)
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: DateEnum
  ): void => {
    const targetDate = new Date(
      e.currentTarget.value.replace(/-/g, '/') || new Date()
    )

    if (!dateValues || !initialDateFormat || !finalDateFormat) return

    if (dateValues) {
      if (
        name === DateEnum.initialDate &&
        targetDate > new Date(dateValues[1])
      ) {
        addToast({ message: Messages.DATE_INITIAL_INVALID_RANGE })
        return
      }

      if (name === DateEnum.finalDate && targetDate < new Date(dateValues[0])) {
        addToast({ message: Messages.DATE_FINAL_INVALID_RANGE })
        return
      }
    }

    if (setInitialDate && setFinalDate && name === DateEnum.initialDate) {
      setInitialDate(dateTimeFormat(targetDate.toISOString(), true))
      setFinalDate(finalDateFormat)
      toggleInitialDateInput()
    }

    if (setInitialDate && setFinalDate && name === DateEnum.finalDate) {
      setInitialDate(initialDateFormat)
      setFinalDate(dateTimeFormat(targetDate.toISOString(), true))
      toggleFinalDateInput()
    }
  }

  useEffect(() => {
    if (
      (isInitialDateInputVisible || isFinalDateInputVisible) &&
      dateInputRef.current != null
    ) {
      dateInputRef?.current.focus()
    }
  }, [isInitialDateInputVisible, isFinalDateInputVisible])

  return (
    <span className={styles.layoutSection}>
      <span className={styles.layoutSectionTitle}>{title}</span>

      {dateValues && (
        <span className={styles.layoutSectionValues}>
          {setInitialDate && isInitialDateInputVisible ? (
            <InputText
              name="initial_date"
              onBlur={(e): void => handleInputChange(e, DateEnum.initialDate)}
              htmlType="date"
              defaultValue={initialDateFormat}
              max={finalDateFormat}
              ref={dateInputRef}
            />
          ) : isLoading ? (
            <Skeleton width={90} height={20} />
          ) : (
            <span
              role="button"
              onClick={toggleInitialDateInput}
              className={setInitialDate ? styles.layoutSectionDateRange : ''}
            >
              <Typography
                text={`${initialDate}`}
                variant={TypographyVariant.p}
                className={styles.value}
              />
            </span>
          )}

          <div className={styles.dateSeparator} />

          {setFinalDate && isFinalDateInputVisible ? (
            <InputText
              name="final_date"
              onBlur={(e): void => handleInputChange(e, DateEnum.finalDate)}
              htmlType="date"
              defaultValue={finalDateFormat}
              min={initialDateFormat}
              ref={dateInputRef}
            />
          ) : isLoading ? (
            <Skeleton width={90} height={20} />
          ) : (
            <span
              role="button"
              onClick={toggleFinalDateInput}
              className={setFinalDate ? styles.layoutSectionDateRange : ''}
            >
              <Typography
                text={`${finalDate}`}
                variant={TypographyVariant.p}
                className={styles.value}
              />
            </span>
          )}
        </span>
      )}

      {singleValue && (
        <span
          className={styles.layoutSectionValues}
          {...(linkUrl && navigationButtonProps(linkUrl))}
        >
          <Typography
            text={singleValue}
            variant={TypographyVariant.p}
            className={linkUrl ? styles.link : styles.value}
          />
        </span>
      )}

      {multipleValues && (
        <span className={styles.layoutSectionValues}>
          {multipleValues.length > 0 &&
            multipleValues.map((group, i) => (
              <Chip
                label={group.name}
                selected={false}
                key={i}
                onClick={(): void =>
                  history.push(
                    `${NavigateList.breedingGroupDetails}${group.id}`
                  )
                }
              />
            ))}
        </span>
      )}
    </span>
  )
}

export { ReproductionLayoutSection }
