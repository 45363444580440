import React from 'react'
import Modal from 'react-modal'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import styles from './styles.module.scss'

export interface ICustomModalProps {
  modalHeader: string
  modalText?: string
  primaryButtonLabel?: string
  primaryButtonAction?: () => void
  primaryButtonType?: ButtonType
  primaryButtonLoading?: boolean
  primaryButtonDisabled?: boolean
  secondaryButtonLabel?: string
  secondaryButtonAction?: () => void
  secondaryButtonType?: ButtonType
  secondaryButtonDisabled?: boolean
  modalIsOpen: boolean
  handleCloseModal: () => void
  children?: React.ReactElement
}

const CustomModal: React.FC<ICustomModalProps> = ({
  modalHeader,
  modalText,
  primaryButtonLabel,
  primaryButtonAction,
  primaryButtonType,
  primaryButtonLoading,
  primaryButtonDisabled,
  secondaryButtonLabel,
  secondaryButtonAction,
  secondaryButtonType,
  secondaryButtonDisabled,
  modalIsOpen,
  handleCloseModal,
  children,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(96, 96, 98, 0.5)',
          zIndex: 150,
        },
        content: {
          position: 'relative',
          minWidth: 520,
          padding: '2em 1.5em',
          margin: '0 auto',
          borderRadius: 8,
          border: 0,
          boxShadow: '0px 4px 4px #00000040',
        },
      }}
    >
      <div className={styles.modalClose} title="Fechar">
        <Icon name={IconNames.close} onClick={handleCloseModal} />
      </div>
      {modalHeader && (
        <Typography
          text={modalHeader}
          variant={TypographyVariant.h5}
          className={styles.modalHeaderText}
        />
      )}
      {modalText && (
        <Typography
          text={modalText}
          variant={TypographyVariant.p}
          className={styles.modalText}
        />
      )}
      {children}
      <div className={styles.buttonContainer}>
        {primaryButtonLabel && (
          <Button
            testId="modal-primary-button"
            label={primaryButtonLabel}
            size={ButtonSize.large}
            type={primaryButtonType || ButtonType.primary}
            onClick={primaryButtonAction}
            className={styles.primaryButton}
            loading={primaryButtonLoading}
            disabled={primaryButtonDisabled}
          />
        )}
        {secondaryButtonLabel && (
          <Button
            testId="modal-secondary-button"
            label={secondaryButtonLabel}
            size={ButtonSize.large}
            type={secondaryButtonType || ButtonType.ghost}
            onClick={secondaryButtonAction}
            disabled={secondaryButtonDisabled}
          />
        )}
      </div>
    </Modal>
  )
}

export { CustomModal }
