import { useCallback, useEffect, useMemo, useState } from 'react'

import { SelectedFiles } from 'use-file-picker'

import {
  deleteDiagnostic,
  getDiagnosticsDetails,
  getReadAllAnimals,
  patchUpdateDiagnostic,
  postAddDiagnostic,
} from 'app/core/services'
import {
  AnimalDrawerType,
  AnimalFilterProps,
  AnimalSexLabel,
} from 'app/core/types/animal'
import {
  Diagnostic,
  DiagnosticAnimal,
  DiagnosticAnimalReadResponseProps,
  DiagnosticCreateData,
  DiagnosticRow,
  Diagnostics,
  DiagnosticsHeaderInfo,
  NEGATIVE_OPTION,
  POSITIVE_OPTION,
} from 'app/core/types/breeding'
import { BreedingDiagnosticsHook } from 'app/core/types/hooks'
import { InseminationTypes } from 'app/core/types/hormonal'
import { StorageKeys } from 'app/core/types/storage'
import { FILTER_ANIMALS_SIZE } from 'app/core/types/system'
import {
  addToast,
  dateForFileName,
  dateTimeFormat,
  dateTimeISOFormat,
  dateTimeXslxFormat,
  downloadFile,
  generateXlsxTemplate,
  getPlural,
  getValueOrNull,
  handleHttpError,
  paginateItems,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { xlsxToDiagnostics } from './helpers'

const useDiagnostics = ({
  diagnosticGroupId,
  page,
}: {
  diagnosticGroupId: string
  page?: number
}): BreedingDiagnosticsHook => {
  const savedDiagnostics = JSON.parse(
    localStorage.getItem(StorageKeys.diagnostics_review) as string
  ) as Diagnostics

  const savedFailedDiagnostics = JSON.parse(
    localStorage.getItem(StorageKeys.diagnostics_review_failed) as string
  )
  const savedDiagnosticsInfo = JSON.parse(
    localStorage.getItem(StorageKeys.diagnostics_review_info) as string
  ) as DiagnosticsHeaderInfo

  const [diagnostics, setDiagnostics] = useState<Diagnostics>(
    () => savedDiagnostics || []
  )
  const [failedDiagnostics, setFailedDiagnostics] = useState<Diagnostics>(
    () => savedFailedDiagnostics || []
  )
  const [paginatedDiagnostics, setPaginatedDiagnostics] =
    useState<DiagnosticAnimalReadResponseProps>()
  const [headerInfo, setHeaderInfo] = useState<DiagnosticsHeaderInfo>(
    () =>
      savedDiagnosticsInfo || {
        responsible: '',
        date: '',
      }
  )
  const [filterIsActive, setFilterIsActive] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const handlePagination = useCallback(() => {
    if (diagnostics) {
      const paginatedDiagnostics = paginateItems(diagnostics, page)
      setPaginatedDiagnostics(paginatedDiagnostics)
    }
  }, [diagnostics, page])

  const getDiagnostics = useCallback(
    async (filters?: Record<string, unknown>) => {
      if (diagnosticGroupId && !savedDiagnostics) {
        try {
          setIsLoading(true)
          const data = await getDiagnosticsDetails(
            Number(diagnosticGroupId),
            filters
          )

          const { date, responsible, diagnostics } = data
          setHeaderInfo({ date, responsible })
          setDiagnostics(diagnostics ?? [])
          setFilterIsActive(!!Object.keys(filters ?? {}).length)
        } catch (e) {
          const message = (e as Error).message
          addToast({ message })
        } finally {
          setIsLoading(false)
        }
      }
    },
    [diagnosticGroupId, savedDiagnostics]
  )

  const createDiagnosticsStorage = (): void => {
    localStorage.setItem(
      StorageKeys.diagnostics_review,
      JSON.stringify(diagnostics)
    )
    localStorage.setItem(
      StorageKeys.diagnostics_review_info,
      JSON.stringify(headerInfo)
    )
  }

  const clearDiagnosticsStorage = (): void => {
    localStorage.removeItem(StorageKeys.diagnostics_review)
    localStorage.removeItem(StorageKeys.diagnostics_review_info)
    localStorage.removeItem(StorageKeys.diagnostics_review_failed)
  }

  const updateTableRow = useCallback(
    (index: number, values: Diagnostic): void => {
      setDiagnostics(prevState => {
        const newState = [...prevState]
        newState[index] = {
          ...prevState[index],
          id: values.id,
          status: values.status,
          gestation_days: values.gestation_days,
          insemination_date: values.insemination_date,
          birth_forecast: values.birth_forecast,
          type: values.type,
          diagnostic_date: values.diagnostic_date,
          error_message: values.error_message,
        }
        return newState
      })
    },
    [setDiagnostics]
  )

  const updateDiagnostic = async (
    index: number,
    data: DiagnosticAnimal
  ): Promise<void> => {
    if (diagnosticGroupId) {
      try {
        const diagnostic = await patchUpdateDiagnostic({
          groupId: Number(diagnosticGroupId),
          diagnosticId: data.id,
          status: data.status,
          gestation_days: data.gestation_days,
          diagnostic_date: dateTimeISOFormat(data.diagnostic_date),
        })

        if (Object.keys(diagnostic).length === 1) {
          addToast({ message: Object.values(diagnostic)[0] as string })

          return
        }

        updateTableRow(index, { ...diagnostic })

        addToast({ message: Messages.DATA_UPDATE, type: 'success' })
      } catch (e) {
        handleHttpError(e, false)
      }
    }
  }

  const insertDiagnostic = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)

      const payload = diagnostics.map(diagnostic => ({
        status: diagnostic.status,
        animal_id: diagnostic.animal?.id,
        gestation_days: diagnostic.gestation_days,
        diagnostic_date: dateTimeISOFormat(
          diagnostic.diagnostic_date as string
        ),
      })) as DiagnosticCreateData[]

      const response = await postAddDiagnostic(
        Number(diagnosticGroupId),
        payload
      )

      const failedDiagnostics = [] as Diagnostics
      let successfulDiagnostics = diagnostics

      if (response.fail.length) {
        response.fail.forEach(error => {
          const animalId = Number(Object.keys(error)[0])
          const errorMessage = error[animalId]
          const failedDiagnostic = diagnostics.find(
            diagnostic => diagnostic.animal?.id === animalId
          )

          if (failedDiagnostic) {
            failedDiagnostics.push({
              stock_number: failedDiagnostic.animal?.stock_number,
              electronic_eartag: failedDiagnostic.animal?.electronic_eartag,
              error_message: errorMessage,
            })
          }

          successfulDiagnostics = [
            ...successfulDiagnostics.filter(
              diagnostic => diagnostic.animal?.id !== animalId
            ),
          ]
        })

        setFailedDiagnostics(failedDiagnostics)
        setDiagnostics(successfulDiagnostics)

        localStorage.setItem(
          StorageKeys.diagnostics_review_failed,
          JSON.stringify(failedDiagnostics)
        )
        localStorage.setItem(
          StorageKeys.diagnostics_review,
          JSON.stringify(successfulDiagnostics)
        )
        localStorage.setItem(
          StorageKeys.diagnostics_review_info,
          JSON.stringify(headerInfo)
        )

        addToast({
          message: `Não foi possível criar ${getPlural(
            'diagnóstico',
            failedDiagnostics.length,
            true
          )}. Verifique a planilha de inconsistências e tente novamente.`,
        })
      }

      if (response.success.length > 0) {
        addToast({
          message: `${successfulDiagnostics.length} diagnósticos criados com sucesso.`,
          type: 'success',
        })
      }
    } catch (e) {
      handleHttpError(e, false)
    } finally {
      setIsLoading(false)
    }
  }, [diagnosticGroupId, diagnostics, headerInfo])

  const removeDiagnostic = useCallback(
    async (diagnosticId: number) => {
      if (diagnosticGroupId) {
        try {
          setIsLoading(true)

          await deleteDiagnostic(Number(diagnosticGroupId), diagnosticId)

          setDiagnostics(prevState =>
            prevState.filter(diagnostic => diagnostic.id !== diagnosticId)
          )

          addToast({
            message: Messages.BREEDING_DIAGNOSTICS_DELETED_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e, false)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [diagnosticGroupId]
  )

  const tableRows: DiagnosticRow[] = useMemo(
    () =>
      (paginatedDiagnostics &&
        paginatedDiagnostics.items.map(item => ({
          stock_number: item.animal?.stock_number,
          electronic_eartag: item.animal?.electronic_eartag,
          diagnostic_date: item.diagnostic_date && item.diagnostic_date,
          status:
            item.status === true
              ? POSITIVE_OPTION
              : item.status === false
              ? NEGATIVE_OPTION
              : undefined,
          insemination_date:
            item.insemination_date && dateTimeFormat(item.insemination_date),
          type: item.type
            ? item.type === 'Inseminação'
              ? InseminationTypes.artificial_insemination
              : InseminationTypes.natural_cover
            : undefined,
          gestation_days: item.gestation_days,
          birth_forecast:
            item.birth_forecast && dateTimeFormat(item.birth_forecast),
          id: item.id,
          animal_id: item.animal?.id,
          error_message: item.error_message,
        }))) ||
      [],
    [paginatedDiagnostics]
  )

  const removeAnimal = (index: number): void => {
    if (diagnostics) {
      setDiagnostics(prevState =>
        prevState.filter((_, itemIndex) => index !== itemIndex)
      )
      handlePagination()
    }
  }

  const importDiagnostics = async (file: SelectedFiles): Promise<void> => {
    try {
      setIsLoading(true)
      const diagnostics = await xlsxToDiagnostics(file.filesContent[0].content)

      const sortItemsWithError = [...diagnostics].sort((a, b) => {
        if (a.error_message && !b.error_message) return -1
        if (!a.error_message && b.error_message) return 1
        return 0
      })

      setDiagnostics(prevState => [...prevState, ...sortItemsWithError])

      addToast({
        message: Messages.BREEDING_DIAGNOSTICS_IMPORT_SUCCESSFUL,
        type: 'success',
      })
    } catch (e) {
      addToast({ message: Messages.ERROR_MESSAGE })
    } finally {
      setIsLoading(false)
    }
  }

  const exportFailedDiagnostics = (): void => {
    const templateFile = generateXlsxTemplate([
      ['Nº de Plantel', 'Nº de Brinco eletrônico', 'Inconsistência'],
      ...failedDiagnostics.map(obj => Object.values(obj)),
    ])

    downloadFile({
      data: templateFile,
      fileName: `diagnosticos-${dateForFileName()}`,
    })
  }

  const exportDiagnostics = (): void => {
    if (diagnostics) {
      const templateFile = generateXlsxTemplate([
        [
          'Nº de Nascimento',
          'Nº de Brinco eletrônico',
          'Nº de Plantel',
          'Data do diagnóstico',
          'Diagnóstico',
          'Data da inseminação',
          'Tipo do diagnóstico',
          'Dias de gestação',
          'Previsão de parto',
        ],
        ...diagnostics.map(
          ({
            animal,
            diagnostic_date,
            status,
            insemination_date,
            type,
            gestation_days,
            birth_forecast,
          }) => {
            return [
              animal?.birth_number,
              getValueOrNull(animal?.electronic_eartag, 'string'),
              animal?.stock_number,
              diagnostic_date ? dateTimeXslxFormat(diagnostic_date) : null,
              status ? 'Positivo' : 'Negativo',
              insemination_date ? dateTimeXslxFormat(insemination_date) : null,
              type
                ? type === 'Inseminação'
                  ? InseminationTypes.artificial_insemination
                  : InseminationTypes.natural_cover
                : null,
              gestation_days,
              birth_forecast ? dateTimeXslxFormat(birth_forecast) : null,
            ]
          }
        ),
      ])

      downloadFile({
        data: templateFile,
        fileName: `diagnosticos-${dateForFileName()}`,
      })
    }
  }

  const filterDiagnosticAnimals = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      try {
        const filterType = filters['filterType']
        delete filters['filterType']

        const formData = {
          ...filters,
          sex: AnimalSexLabel.female,
          is_active: 'true',
        } as AnimalFilterProps

        if (filterType == AnimalDrawerType.include) {
          setIsLoading(true)
          const animals = await getReadAllAnimals(
            formData,
            page,
            FILTER_ANIMALS_SIZE
          )

          if (!animals.items.length) {
            addToast({
              message: Messages.HORMONAL_ANIMAL_NOT_FOUND,
            })

            return
          }

          setDiagnostics(prevState => [
            ...prevState,
            ...animals.items.map(animal => ({
              diagnostic_date: dateTimeFormat(headerInfo.date, true),
              animal: {
                id: animal.id,
                electronic_eartag: animal.electronic_eartag,
                stock_number: animal.stock_number,
              },
            })),
          ])
        } else {
          getDiagnostics(filters as AnimalFilterProps)
        }
      } catch (e) {
        const message = (e as Error).message
        addToast({ message, type: 'error' })
      } finally {
        setIsLoading(false)
      }
    },
    [getDiagnostics, page, setDiagnostics, headerInfo.date]
  )

  useEffect(() => {
    handlePagination()
  }, [handlePagination])

  return {
    diagnostics: paginatedDiagnostics,
    setDiagnostics,
    headerInfo,
    setHeaderInfo,
    getDiagnostics,
    removeDiagnostic,
    createDiagnosticsStorage,
    clearDiagnosticsStorage,
    updateTableRow,
    updateDiagnostic,
    insertDiagnostic,
    importDiagnostics,
    tableRows,
    removeAnimal,
    failedDiagnostics,
    exportFailedDiagnostics,
    filterIsActive,
    exportDiagnostics,
    isLoading,
    filterDiagnosticAnimals,
  }
}

export { useDiagnostics }
