import Skeleton from 'react-loading-skeleton'

import styles from './styles.module.scss'

const ListMeasuringStationLoadingTemplate: JSX.Element = (
  <div className={styles.measuringStationLoading}>
    <div className={styles.measuringStationLoadingCard}>
      <Skeleton height={50} count={20} />
    </div>
  </div>
)

export { ListMeasuringStationLoadingTemplate }
