import { ColumnFiltersState, Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import {
  Control,
  FieldValues,
  FormState,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form'

import { ButtonType, Typography, TypographyVariant } from 'components/atoms'
import { CustomModal } from 'components/molecules'
import {
  CustomTable,
  ServiceOrderFilters,
  ServiceOrderPagination,
  TreeView,
} from 'components/organisms'
import { FormType } from 'components/organisms/form/types'
import { OSLayout } from 'components/templates'

import { ServiceOrderPageInfo } from 'app/core/types/hooks'
import {
  ListServiceOrderProps,
  ServiceOrderInfoProps,
} from 'app/core/types/service-order'
import { ClassificationProps, CostCenterProps } from 'app/core/types/system'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type ServiceOrdersReviewTemplateProps = {
  activeFilters: ListServiceOrderProps
  control: Control<FieldValues>
  columnFilters: ColumnFiltersState
  costCenters?: CostCenterProps[]
  classifications?: ClassificationProps[]
  costCenterId: number
  classificationId: number
  costCenterSelected?: string
  classificationSelected?: string
  handleSubmit: UseFormHandleSubmit<FieldValues>
  handleModal: () => void
  handleModalConfirm: (data: Record<string, unknown>) => Promise<void>
  form: FormType
  formState: FormState<FieldValues>
  table: Table<ServiceOrderInfoProps>
  serviceOrders: ServiceOrderInfoProps[]
  serviceOrderPageInfo: ServiceOrderPageInfo
  setPage: Dispatch<SetStateAction<number>>
  setValue: UseFormSetValue<FieldValues>
  isLoading: boolean
  isModalOpen: boolean
}

const ServiceOrdersReviewTemplate: React.FC<ServiceOrdersReviewTemplateProps> =
  ({
    activeFilters,
    control,
    columnFilters,
    costCenters,
    classifications,
    costCenterId,
    classificationId,
    costCenterSelected,
    classificationSelected,
    form: Form,
    formState,
    handleSubmit,
    handleModal,
    handleModalConfirm,
    table,
    serviceOrders,
    serviceOrderPageInfo,
    setPage,
    setValue,
    isLoading,
    isModalOpen,
  }) => {
    const hasServiceTypeFiltered = activeFilters.service_type === null
    const hasFilters =
      activeFilters.contains_name ||
      activeFilters.service_type ||
      hasServiceTypeFiltered ||
      columnFilters.length > 0
    const currentPageItems = serviceOrders?.length

    return (
      <OSLayout largerContent>
        <ServiceOrderFilters.Root>
          <div>
            <ServiceOrderFilters.Date />
            <ServiceOrderFilters.Count total={serviceOrderPageInfo.total} />
          </div>
          <div className={styles.findAndCheckbox}>
            <ServiceOrderFilters.Text />
            <ServiceOrderFilters.ServiceType />
          </div>
        </ServiceOrderFilters.Root>

        <section className={styles.review}>
          <CustomTable
            table={table}
            isLoading={isLoading}
            noDataMessage={
              hasFilters
                ? Messages.SERVICE_ORDER_NOT_FOUND_FILTERED
                : Messages.SERVICE_ORDER_NOT_FOUND
            }
            disableNavigation
            sortable
          />
        </section>

        <ServiceOrderPagination
          currentPage={serviceOrderPageInfo.currentPage}
          currentPageItems={currentPageItems}
          totalItems={serviceOrderPageInfo.total}
          totalPages={serviceOrderPageInfo.totalPages}
          setPage={setPage}
        />

        <CustomModal
          modalHeader="Editar boletim"
          modalIsOpen={isModalOpen}
          handleCloseModal={handleModal}
          primaryButtonAction={handleSubmit(handleModalConfirm)}
          primaryButtonLabel="Atualizar boletim"
          primaryButtonType={ButtonType.primary}
        >
          <Form>
            <div className={styles.treeViewContainer}>
              {costCenters && (
                <section className={styles.treeView}>
                  <div className={styles.treeViewLabel}>
                    <Typography
                      text="Centro de custo"
                      variant={TypographyVariant.label}
                    />
                    {costCenterSelected && (
                      <Typography
                        text={` - ${costCenterSelected}`}
                        variant={TypographyVariant.label}
                      />
                    )}
                  </div>
                  <TreeView
                    containerClassName={styles.treeViewComponentContainer}
                    control={control}
                    errorMessage={formState?.errors.cost_center_id?.message}
                    fieldName="cost_center_id"
                    items={costCenters}
                    onSelect={(id: number): void =>
                      setValue('cost_center_id', id)
                    }
                    value={costCenterId}
                  />
                </section>
              )}
              {classifications && (
                <section className={styles.treeView}>
                  <div className={styles.treeViewLabel}>
                    <Typography
                      text="Classificação"
                      variant={TypographyVariant.label}
                    />
                    {classificationSelected && (
                      <Typography
                        text={` - ${classificationSelected}`}
                        variant={TypographyVariant.label}
                      />
                    )}
                  </div>
                  <TreeView
                    containerClassName={styles.treeViewComponentContainer}
                    control={control}
                    errorMessage={formState?.errors.classification_id?.message}
                    fieldName="classification_id"
                    items={classifications}
                    onSelect={(id: number): void =>
                      setValue('classification_id', id)
                    }
                    value={classificationId}
                  />
                </section>
              )}
            </div>
          </Form>
        </CustomModal>
      </OSLayout>
    )
  }

export { ServiceOrdersReviewTemplate }
