/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import ReactSwitch from 'react-switch'

import { CheckboxButton, Select, SelectItemProps } from 'components/atoms'

import {
  ProtocolActionProps,
  ScheduleAnimalProps,
  ScheduleUpdateAnimalRequestData,
} from 'app/core/types/hormonal'

import { IMPLANT_DROPLIST, SCORE_DROPLIST } from './helpers'
import styles from './styles.module.scss'

const scheduleColumnsEditable = (
  selectedAction: ProtocolActionProps | undefined,
  updateAnimal: (
    requestBody: ScheduleUpdateAnimalRequestData[]
  ) => Promise<void>
): Partial<ColumnDef<ScheduleAnimalProps>> => {
  const columns: Partial<ColumnDef<ScheduleAnimalProps>> = {
    cell: ({ getValue, row: { original }, column: { id } }) => {
      const inputId = id as keyof ScheduleAnimalProps & string
      const initialValue = getValue()

      const [value, setValue] = useState(initialValue)

      const {
        id: scheduledAnimalId,
        body_condition_score,
        implanted,
        implant_used,
        actions,
      } = original
      const dayActions = actions.filter(
        action => action.hormonal_action_day === selectedAction?.day
      )

      const SCHEDULED_ANIMAL_OBJECT: ScheduleUpdateAnimalRequestData[] = [
        {
          id: scheduledAnimalId,
          body_condition_score,
          implanted,
          implant_used,
          actions: dayActions ? dayActions : [],
        },
      ]

      const rowOpacity = (): void => {
        const colImplantedIndex = 5
        const colDeleteIndex = 8

        const cells = document
          .getElementById(`id-${original.id}`)
          ?.getElementsByTagName('td')

        if (cells && original.implanted) {
          Array.from(cells).forEach((cell, colIndex) => {
            if (colIndex !== colImplantedIndex && colIndex !== colDeleteIndex) {
              cell.style.opacity = '0.5'
            }
          })
        }

        if (cells && !original.implanted) {
          Array.from(cells).forEach(cell => (cell.style.opacity = '1'))
        }
      }

      const onSelect = (selectedValue: string | boolean | undefined): void => {
        setValue(selectedValue)

        const updatedSchedule =
          inputId === 'executed'
            ? [
                {
                  ...SCHEDULED_ANIMAL_OBJECT[0],
                  actions: original.actions
                    .filter(
                      action =>
                        action.hormonal_action_day === selectedAction?.day
                    )
                    .map(action => ({
                      ...action,
                      executed: selectedValue,
                    })),
                },
              ]
            : [
                {
                  ...SCHEDULED_ANIMAL_OBJECT[0],
                  [inputId]: selectedValue,
                },
              ]

        updateAnimal(updatedSchedule)
      }

      useEffect(() => {
        rowOpacity()
        setValue(initialValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [initialValue])

      if (inputId === 'executed') {
        const action =
          actions.find(
            action => action.hormonal_action_id === selectedAction?.id
          )?.executed || false

        return (
          <span>
            <CheckboxButton
              name="executed"
              options={[{ value: 'true' }]}
              defaultChecked={{ value: action?.toString() }}
              onChange={(e): void => onSelect(e.currentTarget.checked)}
            />
          </span>
        )
      }

      if (inputId === 'actions') {
        return (
          <span className={styles.action}>{selectedAction?.action || '-'}</span>
        )
      }

      if (inputId === 'body_condition_score') {
        return (
          <Select
            name={inputId}
            options={SCORE_DROPLIST}
            defaultValue={SCORE_DROPLIST.find(
              e => e.value === body_condition_score.toString()
            )}
            onChange={(value): void =>
              onSelect((value as SelectItemProps).value)
            }
            placeholder="Nota"
            centerText
          />
        )
      }

      if (inputId === 'implant_used') {
        return (
          <Select
            name={inputId}
            options={IMPLANT_DROPLIST}
            defaultValue={IMPLANT_DROPLIST.find(e => e.value === implant_used)}
            onChange={(value): void =>
              onSelect((value as SelectItemProps).value)
            }
            placeholder="Selecione"
            centerText
          />
        )
      }

      if (inputId === 'implanted') {
        return (
          <label
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              width: 100,
            }}
          >
            <ReactSwitch
              checked={!!value}
              onChange={onSelect}
              onColor="#C6DEC5"
              offColor="#C2C2C2"
              onHandleColor="#6A9068"
              offHandleColor="#606062"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
              className="react-switch"
              id="material-switch"
            />
            <span>{value ? 'Sim' : 'Não'}</span>
          </label>
        )
      }
    },
  }
  return columns
}

export { scheduleColumnsEditable }
