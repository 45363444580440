import { withLoading } from 'components/molecules'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { SectorProps } from 'app/core/types/system'

import { ListSectorLoadingTemplate } from './loading'
import { SectorTable } from './table/table'

type SectorListTemplateProps = {
  isLoading: boolean
  sectors: SectorProps[]
  onCreateClick: () => void
  onEditClick: (sectorId: number) => void
}

const SectorListTemplate: React.FC<SectorListTemplateProps> = ({
  isLoading,
  sectors,
  onCreateClick,
  onEditClick,
}) => {
  return (
    <BoxedLayout
      title="Setores"
      headerLargeButtonLabel={isAdmin() ? 'cadastrar' : undefined}
      headerLargeButtonAction={isAdmin() ? onCreateClick : undefined}
    >
      {withLoading(
        <SectorTable
          sectors={sectors}
          onEditClick={isAdmin() ? onEditClick : undefined}
        />
      )(isLoading, ListSectorLoadingTemplate)}
    </BoxedLayout>
  )
}

export { SectorListTemplate }
