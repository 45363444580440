import { useState } from 'react'

import classNames from 'classnames'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'

import { isAdmin } from 'app/core/auth'

import styles from './styles.module.scss'

export interface IDropdownItem {
  title: string
  subTitle: string
  action: () => void
  icon?: IconNames
  onlyAdmin?: boolean
  hasDivider?: boolean
}

export interface IDropdownProps {
  items: IDropdownItem[]
  iconPosition?: ButtonIconPosition
  label?: string
  className?: string
  loading?: boolean
  selected?: string
}

const Dropdown = ({
  items,
  iconPosition = ButtonIconPosition.right,
  label,
  className,
  loading,
  selected,
}: IDropdownProps): JSX.Element => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleButtonClick = (): void => {
    setTooltipVisible(!tooltipVisible)
  }

  return (
    <div className={classNames(styles.dropdown, className)}>
      <Button
        label={label}
        subLabel={selected}
        type={ButtonType.ghost}
        onClick={handleButtonClick}
        iconPosition={iconPosition}
        icon={
          tooltipVisible ? (
            <Icon name={IconNames['chevron-up']} />
          ) : (
            <Icon name={IconNames['chevron-down']} />
          )
        }
        size={ButtonSize.small}
        loading={loading}
        className={styles.dropdownButton}
      />
      {tooltipVisible && (
        <ul className={styles.dropdownItems}>
          {items
            .filter(({ onlyAdmin }) => (onlyAdmin ? isAdmin() : true))
            .map(({ action, hasDivider, icon, subTitle, title }, i) => (
              <li
                key={i}
                onClick={(): void => {
                  handleButtonClick()
                  action()
                }}
                {...(hasDivider && { className: styles.hasDivider })}
              >
                <Icon name={icon} />
                {`${title} ${subTitle}`}
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}

export { Dropdown }
