import { formatISO } from 'date-fns'

import {
  Chip,
  ChipColorVariant,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { CHIP_SEX } from 'components/templates/animal-layout/consts'
import { Col, InfoCard, Row } from 'components/templates/animal-layout/helpers'

import { AnimalHistoryTypes, IAnimalEntity } from 'app/core/types/animal'
import {
  calcAverageDailyGain,
  dateTimeFormat,
  formatWeight,
} from 'app/core/utils'

import styles from './styles.module.scss'

export enum AnimalInfoData {
  stockNumber = 'stock_number',
  birthNumber = 'birth_number',
  birthDate = 'birth_date',
  electronicEartag = 'electronic_eartag',
  status = 'status',
  sex = 'sex',
  averageDailyGain = 'adg',
  weaningWeight = 'weaning_weight',
}

type AnimalInfoProps = {
  animal: IAnimalEntity
  show: AnimalInfoData[]
}

const AnimalInfoItem: React.FC<{
  label: string
  value?: string | React.ReactNode
  emptyValue?: string
}> = ({ label, value, emptyValue = 'Sem informação' }) => (
  <Col>
    <Typography text={label} variant={TypographyVariant.caption3} altColor />
    <Typography
      text={value || emptyValue}
      variant={TypographyVariant.p}
      className={value ? '' : styles.empty}
    />
  </Col>
)

const AnimalInfo: React.FC<AnimalInfoProps> = ({ animal, show }) => {
  const { additional_information, basic_information, header, history } = animal

  const averageDailyGain = calcAverageDailyGain(
    additional_information.birth_weight,
    additional_information.weight,
    basic_information.birth_date,
    formatISO(new Date())
  )

  const weaningWeight = history
    .find(item => item.type === AnimalHistoryTypes.weaning_handling)
    ?.data.find(data => data.field === 'weight')?.value

  return (
    <InfoCard title="Informações do animal">
      <Row className={styles.animalInfoRow}>
        {show.includes(AnimalInfoData.stockNumber) && (
          <AnimalInfoItem
            label="Nº de Plantel"
            value={header.stock_number}
            emptyValue="Sem número"
          />
        )}
        {show.includes(AnimalInfoData.birthNumber) && (
          <AnimalInfoItem
            label="Nº de nascimento"
            value={basic_information.birth_number}
            emptyValue="Sem número"
          />
        )}
        {show.includes(AnimalInfoData.birthDate) && (
          <AnimalInfoItem
            label="Data de nascimento"
            value={dateTimeFormat(basic_information.birth_date)}
          />
        )}
        {show.includes(AnimalInfoData.electronicEartag) && (
          <AnimalInfoItem
            label="Brinco Eletrônico"
            value={header.electronic_eartag}
            emptyValue="Sem brinco"
          />
        )}
        {show.includes(AnimalInfoData.sex) && header.sex && (
          <Col>
            <Typography
              text="Sexo"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Chip colorVariant={CHIP_SEX[header.sex]} />
          </Col>
        )}
        {show.includes(AnimalInfoData.status) && (
          <Col>
            <Typography
              text="Status"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Chip
              colorVariant={
                header.is_active
                  ? ChipColorVariant.statusActive
                  : ChipColorVariant.statusInactive
              }
            />
          </Col>
        )}
        {show.includes(AnimalInfoData.averageDailyGain) && (
          <AnimalInfoItem
            label="GMD (Vida)"
            value={formatWeight(averageDailyGain)}
          />
        )}
        {show.includes(AnimalInfoData.weaningWeight) && weaningWeight && (
          <AnimalInfoItem
            label="Peso na desmama"
            value={formatWeight(weaningWeight as number)}
            emptyValue="Sem peso"
          />
        )}
      </Row>
    </InfoCard>
  )
}

export { AnimalInfo }
