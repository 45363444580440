import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectedFiles } from 'use-file-picker'
import * as XLSX from 'xlsx'

import { SelectItemProps } from 'components/atoms'

import { NavigateList } from 'app/core/routes/routes'
import { getReadAllAnimals } from 'app/core/services'
import {
  deleteRemoveSchedule,
  deleteRemoveScheduleAnimal,
  getIATFScheduleReport,
  getReadAllSchedules,
  getReadAnimalsBySchedule,
  getReadSchedule,
  postAddScheduleAnimal,
  postCreateSchedule,
  postUpdateScheduleAnimal,
} from 'app/core/services/hormonal/iatf-schedules'
import {
  AnimalFilterProps,
  AnimalListProps,
  AnimalReadResponseProps,
  AnimalRequestData,
  AnimalSexLabel,
} from 'app/core/types/animal'
import {
  ScheduleAnimalHook,
  ScheduleAnimalHookProps,
  ScheduleHook,
  ScheduleHookProps,
  SchedulesHook,
  SchedulesHookProps,
} from 'app/core/types/hooks'
import {
  ImplantUseList,
  ImportedIATFProps,
  ScheduleAnimalActionProps,
  ScheduleAnimalProps,
  ScheduleAnimalResponseData,
  ScheduleCreateRequestData,
  ScheduleCreateRequestFormatData,
  ScheduleFailedAnimalProps,
  ScheduleFilterProps,
  ScheduleProps,
  ScheduleReadResponseData,
  ScheduleUpdateAnimalRequestData,
} from 'app/core/types/hormonal'
import {
  DEFAULT_ITEMS_PER_PAGE_LIST,
  FILTER_ANIMALS_SIZE,
} from 'app/core/types/system'
import {
  addToast,
  arrayToCsv,
  dateForFileName,
  dateTimeISOFormat,
  dateTimeXslxFormat,
  downloadFile,
  generateXlsxTemplate,
  getFormattedDateValueOrNull,
  getPlural,
  getValueOrNull,
  handleHttpError,
  paginateItems,
  parseNumberFromSheet,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useSchedules = ({
  filters,
  page,
  size,
}: SchedulesHookProps): SchedulesHook => {
  const [schedules, setSchedules] = useState<ScheduleReadResponseData>()
  const [schedulesDroplist, setSchedulesDroplist] = useState<SelectItemProps[]>(
    []
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllSchedules = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)

      const data = await getReadAllSchedules(
        filters,
        page,
        size || DEFAULT_ITEMS_PER_PAGE_LIST
      )

      const SCHEDULE_OPTIONS = data.items.map(e => ({
        label: e.name,
        value: e.id.toString(),
      }))

      if (data.total === 0) {
        addToast({
          message: Messages.SCHEDULE_NOT_FOUND,
          type: 'warning',
        })
      }

      setSchedules(data)
      setSchedulesDroplist(SCHEDULE_OPTIONS)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [filters, page, size])

  const deleteSchedule = useCallback(
    async (scheduleId: number): Promise<void> => {
      try {
        await deleteRemoveSchedule(scheduleId)

        setSchedules(prev => {
          if (!prev) return prev

          return {
            ...prev,
            items: prev.items.filter(s => s.id !== scheduleId),
          }
        })

        addToast({
          message: Messages.SCHEDULE_DELETE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      }
    },
    []
  )

  const loadSchedulesOptions = useCallback(
    async (filters): Promise<SelectItemProps[]> => {
      try {
        const data = await getReadAllSchedules(
          filters,
          page,
          size || DEFAULT_ITEMS_PER_PAGE_LIST
        )

        const options = data.items.map(e => ({
          label: e.name,
          value: e.id.toString(),
        }))

        return options || []
      } catch (e) {
        return []
      }
    },
    [page, size]
  )

  const exportSchedules = useCallback(async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getIATFScheduleReport(filters as ScheduleFilterProps)

      const schedules = data.map(schedule => ({
        Nome: schedule.name,
        'Data início': getFormattedDateValueOrNull(schedule.date_start),
        'Data fim': getFormattedDateValueOrNull(schedule.date_end),
        'Estação de monta': getValueOrNull(schedule.breeding_station_name),
        'Protocólo Hormonal': getValueOrNull(schedule.hormonal_protocol_name),
        'Nº de Plantel': getValueOrNull(schedule.stock_number),
        'Brinco eletrônico': getValueOrNull(schedule.electronic_eartag),
        'Score corporal':
          (schedule.body_condition_score ?? 0) > 0
            ? getValueOrNull(schedule.body_condition_score)
            : null,
        'Implante perdido':
          schedule.implanted === undefined
            ? ''
            : schedule.implanted
            ? 'Sim'
            : 'Não',
        'Implante utilizado': schedule.implant_used,
        Ações: schedule.actions,
      }))

      const blob = arrayToCsv(schedules, ';')

      downloadFile({
        data: blob,
        fileName: `programacoes-iatf-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({
        message: Messages.SCHEDULE_EXPORT_SUCCESS,
        type: 'success',
      })
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters])

  useEffect(() => {
    readAllSchedules()
  }, [readAllSchedules])

  return {
    schedules,
    schedulesDroplist,
    isLoading,
    deleteSchedule,
    readAllSchedules,
    loadSchedulesOptions,
    exportSchedules,
    isLoadingExport,
  }
}

const useSchedule = ({ scheduleId }: ScheduleHookProps): ScheduleHook => {
  const history = useHistory<NavigateList>()

  const [schedule, setSchedule] = useState<ScheduleProps>()

  const readSchedule = useCallback(async (): Promise<void> => {
    if (scheduleId) {
      try {
        const data = await getReadSchedule(scheduleId)
        setSchedule(data)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [scheduleId])

  const addSchedule = useCallback(
    async (request: ScheduleCreateRequestFormatData): Promise<void> => {
      if (request) {
        const formatRequest = {
          ...request,
          breed_station_id: parseInt(request.breed_station_id.value),
          hormonal_protocol_id: parseInt(request.hormonal_protocol_id.value),
          date_start: dateTimeISOFormat(request.date_start),
          date_end: dateTimeISOFormat(request.date_end),
        } as ScheduleCreateRequestData

        try {
          const data = await postCreateSchedule(formatRequest)
          history.push(`${NavigateList.iatfScheduleDetails}${data.id}`)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [history]
  )

  useEffect(() => {
    readSchedule()
  }, [readSchedule])

  return {
    schedule,
    addSchedule,
  }
}

const useScheduleAnimal = ({
  scheduleId,
  page,
}: ScheduleAnimalHookProps): ScheduleAnimalHook => {
  const { schedule } = useSchedule({ scheduleId })

  const [animals, setAnimals] = useState<ScheduleAnimalProps[]>()
  const [failedAnimals, setFailedAnimals] =
    useState<ScheduleFailedAnimalProps[]>()
  const [paginatedAnimals, setPaginatedAnimals] =
    useState<ScheduleAnimalResponseData>()
  const [filterIsActive, setFilterIsActive] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isImplantUseValid = (value: string): value is ImplantUseList =>
    Object.values(ImplantUseList)
      .map(v => v.toLowerCase())
      .includes(value.toLowerCase() as ImplantUseList)

  const isBodyConditionValid = (value: number): boolean =>
    value >= 1 && value <= 5

  const readAnimals = useCallback(
    async (filters?: Record<string, unknown>): Promise<void> => {
      if (scheduleId) {
        try {
          setIsLoading(true)

          const animals = await getReadAnimalsBySchedule(scheduleId, {
            ...filters,
            load_actions: 'true',
          })

          const onlyActiveAnimals = animals.filter(animal => animal.is_active)

          setAnimals(onlyActiveAnimals)
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [scheduleId]
  )

  const addAnimalsToIATF = useCallback(
    async (
      animals: AnimalReadResponseProps | AnimalListProps[]
    ): Promise<void> => {
      if (scheduleId) {
        const animalsTyped = Array.isArray(animals) ? animals : animals.items
        const animalIds = animalsTyped.map(animal => animal.id)

        let successfulAnimals = 0

        if (!animalIds.length) {
          addToast({
            message: Messages.HORMONAL_ANIMAL_NOT_FOUND,
          })

          return
        }

        const failedAnimals = [] as ScheduleFailedAnimalProps[]

        for (const animalId of animalIds) {
          try {
            const response = await postAddScheduleAnimal(scheduleId, animalId)

            if (response.message) {
              const failedAnimal = animalsTyped.find(
                animal => animal.id === animalId
              )

              failedAnimals.push({
                stock_number: failedAnimal?.stock_number,
                electronic_eartag: failedAnimal?.electronic_eartag,
                birth_number: failedAnimal?.birth_number,
                error_message: response.message,
              })
            } else {
              successfulAnimals += 1
            }
          } catch (e) {
            handleHttpError(e, false)
          }
        }

        showUserFeedback(failedAnimals, successfulAnimals)

        readAnimals()
      }
    },
    [readAnimals, scheduleId]
  )

  const addAnimal = useCallback(
    async (
      filters: AnimalFilterProps,
      breedingStationId: number
    ): Promise<void> => {
      if (scheduleId) {
        try {
          setIsLoading(true)

          const animals = await getReadAllAnimals(
            {
              ...filters,
              sex: AnimalSexLabel.female,
              breeding_station_id: breedingStationId,
              is_active: 'true',
            },
            1,
            FILTER_ANIMALS_SIZE
          )

          await addAnimalsToIATF(animals)
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleId]
  )

  const removeAnimal = useCallback(
    async (scheduleAnimalId: number): Promise<void> => {
      if (scheduleId && scheduleAnimalId) {
        try {
          setIsLoading(true)

          await deleteRemoveScheduleAnimal(scheduleId, scheduleAnimalId)
          readAnimals()
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleId]
  )

  const updateAnimal = useCallback(
    async (
      request: ScheduleUpdateAnimalRequestData[],
      showToast = true
    ): Promise<void> => {
      if (request) {
        try {
          setIsLoading(true)

          await postUpdateScheduleAnimal(request)
          readAnimals()

          if (showToast) {
            addToast({
              message: Messages.DATA_UPDATE,
              type: 'success',
            })
          }
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleId]
  )

  const handleImportSheet = useCallback(
    async (file: SelectedFiles) => {
      if (scheduleId) {
        try {
          setIsLoading(true)

          const importedData = new Uint8Array(
            file.filesContent[0].content as unknown as ArrayBuffer
          )

          const workbook = XLSX.read(importedData, {
            type: 'array',
            cellText: false,
            cellDates: true,
          })

          const failedAnimals = [] as ScheduleFailedAnimalProps[]
          let successfulAnimals = 0

          await Promise.all(
            workbook.SheetNames.map(async sheetName => {
              const worksheet = workbook.Sheets[sheetName]
              const rows = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                blankrows: false,
                dateNF: 'd"/"m"/"yyyy',
                raw: true,
              }) as ImportedIATFProps[]

              for (let i = 1; i < rows.length; i++) {
                const row = rows[i]

                const importedAnimalInfo = String(row[0] || row[1] || row[2])
                  .trim()
                  .replace(/\n/g, '')

                const bodyConditionValue = parseNumberFromSheet(row[3])
                const hasBodyConditionValid =
                  typeof bodyConditionValue === 'number' &&
                  isBodyConditionValid(bodyConditionValue)

                const bodyConditionScore = hasBodyConditionValid
                  ? bodyConditionValue
                  : undefined

                const implanted =
                  row[4] !== undefined &&
                  String(row[4]).trim().toLowerCase() === 'sim'

                const implantUsed = isImplantUseValid(String(row[5]).trim())
                  ? String(row[5]).trim().charAt(0).toUpperCase() +
                    String(row[5]).trim().slice(1).toLowerCase()
                  : undefined

                const filters = {
                  stock_number_or_birth_number_or_electronic_eartag:
                    importedAnimalInfo,
                } as AnimalFilterProps

                const animal = (await getReadAllAnimals(filters)).items[0]

                if (animal) {
                  const schedule = await postAddScheduleAnimal(
                    scheduleId,
                    animal.id
                  )

                  if (schedule.message) {
                    failedAnimals.push({
                      stock_number: animal?.stock_number,
                      electronic_eartag: animal?.electronic_eartag,
                      birth_number: animal?.birth_number,
                      error_message: schedule.message,
                    })

                    continue
                  }

                  await updateAnimal(
                    [
                      {
                        id: schedule.id,
                        body_condition_score: bodyConditionScore || 0,
                        implant_used: implantUsed || '',
                        implanted,
                        actions: [],
                      },
                    ],
                    false
                  )

                  successfulAnimals += 1
                } else {
                  failedAnimals.push({
                    stock_number: importedAnimalInfo,
                    error_message: Messages.ANIMAL_NOT_FOUND,
                  })
                }
              }
            })
          )

          showUserFeedback(failedAnimals, successfulAnimals)
        } catch (e) {
          addToast({ message: Messages.ERROR_MESSAGE })
        } finally {
          setIsLoading(false)
        }
      }
    },
    [scheduleId, updateAnimal]
  )

  const handlePagination = useCallback(() => {
    if (animals) {
      const paginatedAnimals = paginateItems(animals, page)
      setPaginatedAnimals(paginatedAnimals)
    }
  }, [animals, page])

  const filterAnimals = useCallback(
    async (filters?: AnimalRequestData): Promise<void> => {
      readAnimals(filters)
      setFilterIsActive(!!Object.keys(filters ?? {}).length)
    },
    [readAnimals]
  )

  const showUserFeedback = (
    failedAnimals: ScheduleFailedAnimalProps[],
    successfulAnimals: number
  ): void => {
    if (failedAnimals.length > 0) {
      setFailedAnimals(failedAnimals)

      addToast({
        message: `Não foi possível adicionar ${getPlural(
          'animal',
          failedAnimals.length,
          true
        )}. Verifique a planilha de inconsistências e tente novamente.`,
      })
    }

    if (successfulAnimals > 0) {
      addToast({
        message: `${getPlural('Animal', successfulAnimals, true)} ${getPlural(
          'adicionado',
          successfulAnimals
        )} com sucesso.`,
        type: 'success',
      })
    }
  }

  const exportSchedules = (): void => {
    const startDate = schedule?.date_start
      ? dateTimeXslxFormat(schedule.date_start)
      : null
    const endDate = schedule?.date_end
      ? dateTimeXslxFormat(schedule.date_end)
      : null

    if (animals) {
      const templateFile = generateXlsxTemplate([
        [
          'Nº de Nascimento',
          'Nº de Brinco eletrônico',
          'Nº de Plantel',
          'Dia da aplicação',
          'Produto aplicado',
          'Score corporal',
          'Implante perdido',
          'Uso do implante',
          'Último parto',
          'Data início',
          'Data fim',
        ],
        ...animals.map(schedule => {
          const executedActions = schedule.actions.filter(
            action => action.executed
          )
          const maxExecutedAction = executedActions.reduce(
            (maxAction, currentAction) => {
              return currentAction.hormonal_action_day >
                maxAction.hormonal_action_day
                ? currentAction
                : maxAction
            },
            {
              hormonal_action_day: -1,
              hormonal_action_name: undefined,
            } as Omit<
              ScheduleAnimalActionProps,
              | 'id'
              | 'executed'
              | 'date_created'
              | 'iatf_schedule_animal_id'
              | 'hormonal_action_id'
            >
          )

          const maxExecutedHormonalActionDay =
            maxExecutedAction.hormonal_action_day !== -1
              ? maxExecutedAction.hormonal_action_day
              : null
          const maxExecutedHormonalActionName =
            maxExecutedAction.hormonal_action_name !== null
              ? maxExecutedAction.hormonal_action_name
              : null

          return [
            schedule.animal?.birth_number,
            getValueOrNull(schedule.animal?.electronic_eartag),
            schedule.animal?.stock_number,
            maxExecutedHormonalActionDay,
            maxExecutedHormonalActionName,
            schedule.body_condition_score,
            schedule.implanted ? 'Sim' : 'Não',
            schedule.implant_used || null,
            schedule.animal.last_calving
              ? dateTimeXslxFormat(schedule.animal.last_calving)
              : null,
            startDate,
            endDate,
          ]
        }),
      ])

      downloadFile({
        data: templateFile,
        fileName: `protocolos-iatf-${dateForFileName()}`,
      })
    }
  }

  const exportFailedAnimals = (): void => {
    if (failedAnimals) {
      const templateFile = generateXlsxTemplate([
        [
          'Nº de Plantel',
          'Nº de Nascimento',
          'Nº de Brinco eletrônico',
          'Inconsistência',
        ],
        ...failedAnimals.map(obj => Object.values(obj)),
      ])

      downloadFile({
        data: templateFile,
        fileName: `programacao-iatf-${dateForFileName()}`,
      })
    }
  }

  useEffect(() => {
    readAnimals()
  }, [readAnimals])

  useEffect(() => {
    handlePagination()
  }, [handlePagination])

  return {
    animals: paginatedAnimals,
    addAnimal,
    removeAnimal,
    updateAnimal,
    filterAnimals,
    filterIsActive,
    handleImportSheet,
    exportSchedules,
    failedAnimals,
    exportFailedAnimals,
    isLoading,
  }
}

export { useSchedule, useScheduleAnimal, useSchedules }
