import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import { getAllCostCenters } from 'app/core/services/system/cost-center'
import { CostCenterHook } from 'app/core/types/hooks'
import { CostCenterProps } from 'app/core/types/system'
import { handleHttpError } from 'app/core/utils'

const useCostCenter = (farmId?: string): CostCenterHook => {
  const [costCenters, setCostCenters] = useState<CostCenterProps[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const costCentersDroplist = costCenters.map(costCenter => {
    return {
      label: costCenter.name,
      value: `${costCenter.id}`,
    }
  }) as SelectItemProps[]

  const readAllCostCenters = useCallback(
    async (classificationId?: number): Promise<void> => {
      try {
        setIsLoading(true)

        const data = await getAllCostCenters({
          classification_id: classificationId,
          farm_id: Number(farmId),
        })
        setCostCenters(data)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    [farmId]
  )

  useEffect(() => {
    readAllCostCenters()
  }, [readAllCostCenters])

  return {
    costCenters,
    costCentersDroplist,
    readAllCostCenters,
    isLoading,
  }
}

export { useCostCenter }
