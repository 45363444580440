import classNames from 'classnames'

import styles from './styles.module.scss'

type DividerProps = {
  darker?: boolean
  vertical?: boolean
  className?: string
}

const Divider: React.FC<DividerProps> = ({ className, darker, vertical }) => {
  return (
    <hr
      className={classNames(
        className,
        styles.divider,
        vertical && styles.dividerVertical,
        styles[darker ? 'darker' : '']
      )}
    />
  )
}

export { Divider }
