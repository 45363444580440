import { useCallback, useState } from 'react'

import * as yup from 'yup'

import { SelectItemProps } from 'components/atoms'
import { CustomModal } from 'components/molecules'
import { useForm } from 'components/organisms'

import {
  CreateMeasurementRequestData,
  ModalType,
} from 'app/core/types/precipitation'
import { onlyAlphanumericString } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from '../../styles.module.scss'

type PrecipitationCreateModalProps = {
  createMeasurement: (request: CreateMeasurementRequestData) => Promise<void>
  handleModal: (type: ModalType) => void
  isModalOpen: boolean
  stationsDroplist: SelectItemProps[]
}

const PrecipitationCreateModal: React.FC<PrecipitationCreateModalProps> = ({
  createMeasurement,
  handleModal,
  isModalOpen,
  stationsDroplist,
}) => {
  const [selectedStation, setSelectedStation] = useState<string>()

  const handleModalCreate = useCallback(
    (): void => handleModal(ModalType.create),
    [handleModal]
  )

  const handleCreate = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const { date, rain, temp_min, temp_med, temp_max } = data

      const request = {
        date,
        rain,
        temp_min: temp_min || 0,
        temp_med: temp_med || 0,
        temp_max: temp_max || 0,
        station_id: Number(onlyAlphanumericString(String(selectedStation))),
        is_external_station: selectedStation?.toString().includes('external'),
      } as CreateMeasurementRequestData

      await createMeasurement(request)

      handleModalCreate()
    },
    [createMeasurement, handleModalCreate, selectedStation]
  )

  const validationSchema = yup.object({
    date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    station_id: yup
      .mixed<SelectItemProps>()
      .required(Messages.YUP_REQUIRED_FIELD),
    rain: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const { Form, handleSubmit } = useForm({
    onSubmit: handleCreate,
    validationSchema,
  })

  return (
    <CustomModal
      modalHeader="Novo lançamento manual"
      modalIsOpen={isModalOpen}
      handleCloseModal={handleModalCreate}
      primaryButtonLabel="Salvar"
      primaryButtonAction={handleSubmit(handleCreate)}
      secondaryButtonLabel="Cancelar"
      secondaryButtonAction={handleModalCreate}
    >
      <div className={styles.modalForm}>
        <Form>
          <div className={styles.modalFormRow}>
            <Form.InputText
              label="Data e hora"
              htmlType="datetime-local"
              name="date"
            />
            <Form.Select
              label="Estação"
              name="station_id"
              options={stationsDroplist}
              onChange={(value): void =>
                setSelectedStation((value as SelectItemProps).value)
              }
            />
          </div>
          <div className={styles.modalFormRow}>
            <Form.InputText
              label="Temperatura mínima"
              htmlType="number"
              name="temp_min"
              appendHelperText="°C"
            />

            <Form.InputText
              label="Temperatura média"
              htmlType="number"
              name="temp_med"
              appendHelperText="°C"
            />

            <Form.InputText
              label="Temperatura máxima"
              htmlType="number"
              name="temp_max"
              appendHelperText="°C"
            />

            <Form.InputText
              label="Precipitação"
              htmlType="number"
              name="rain"
              appendHelperText="mm"
            />
          </div>
        </Form>
      </div>
    </CustomModal>
  )
}

export { PrecipitationCreateModal }
