import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'
import { debounce } from 'lodash-es'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  EnvironmentMessage,
  Icon,
  IconNames,
  InputText,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { Pagination } from 'components/molecules'
import filterButtonStyles from 'components/molecules/data-counter/styles.module.scss'
import { ISelectButtonItem, Menu } from 'components/organisms'
import SelectButton from 'components/organisms/select-button'

import { dateTimeFormat, dateToday, getPlural } from 'app/core/utils'

import styles from './styles.module.scss'

export type BoxedLayoutProps = {
  title: string
  date?: string
  setDate?: Dispatch<SetStateAction<string>>
  setDateMaxToday?: boolean
  dateLabel?: string
  quantity?: number
  quantityLabel?: string
  headerButtonLabel?: string
  headerButtonIcon?: JSX.Element
  headerButtonLoading?: boolean
  headerButtonAction?: () => void
  headerButtonFilterActiveFilters?: number
  headerLargeButtonLabel?: string
  headerLargeButtonIcon?: JSX.Element
  headerLargeButtonIconPosition?: ButtonIconPosition
  headerLargeButtonAction?: () => void
  headerLargeButtonLoading?: boolean
  bottomLeftButtonLabel?: string
  bottomLeftButtonIcon?: JSX.Element
  bottomLeftButtonAction?: () => void
  bottomRightButtonLabel?: string
  bottomRightButtonIcon?: JSX.Element
  bottomRightButtonAction?: () => void
  headerHighlightedData?: string
  headerHighlightedLabel?: string
  isChildrenSticky?: boolean
  currentPage?: number
  totalPages?: number
  currentPageItems?: number
  currentPageItemsLabel?: string
  setCurrentPage?: Dispatch<SetStateAction<number>>
  headerSelectButtonLabel?: string
  headerSelectButtonItems?: ISelectButtonItem[]
  headerSelectButtonIcon?: JSX.Element
  headerSelectButtonIconPosition?: ButtonIconPosition
  headerSelectButtonLoading?: boolean
  backButton?: boolean
  limitWidth?: boolean
  exportButton?: boolean
  exportButtonAction?: () => void
  exportButtonLoading?: boolean
}

const BoxedLayout: React.FC<BoxedLayoutProps> = ({
  children,
  title,
  date,
  setDate,
  setDateMaxToday,
  dateLabel,
  quantity,
  quantityLabel,
  headerButtonLabel,
  headerButtonIcon,
  headerButtonAction,
  headerButtonFilterActiveFilters,
  headerLargeButtonLabel,
  headerLargeButtonIcon,
  headerLargeButtonIconPosition,
  headerLargeButtonAction,
  headerLargeButtonLoading,
  bottomLeftButtonLabel,
  bottomLeftButtonIcon,
  bottomLeftButtonAction,
  bottomRightButtonLabel,
  bottomRightButtonIcon,
  bottomRightButtonAction,
  headerHighlightedData,
  headerHighlightedLabel,
  isChildrenSticky,
  currentPage,
  totalPages,
  currentPageItems,
  currentPageItemsLabel,
  setCurrentPage,
  headerSelectButtonLabel,
  headerSelectButtonItems,
  headerSelectButtonIcon,
  headerSelectButtonIconPosition,
  headerSelectButtonLoading,
  backButton,
  limitWidth,
  exportButton,
  exportButtonAction,
  exportButtonLoading,
  headerButtonLoading,
}) => {
  const history = useHistory()
  const [isDateInputVisible, setIsDateInputVisible] = useState(false)

  const dateInputRef = useRef<HTMLInputElement>(null)

  const hasPagination =
    currentPage && totalPages && totalPages > 1 && setCurrentPage
  const hasBottomButtons = bottomLeftButtonAction || bottomRightButtonAction
  const hasFooter = hasPagination || hasBottomButtons

  const quantityFormat = quantity
    ? new Intl.NumberFormat('de-DE').format(quantity)
    : undefined
  const defaultQuantityLabel =
    quantity && quantity <= 1 ? 'Animal adicionado' : 'Animais adicionados'

  const handleShowInput = (): void => {
    setIsDateInputVisible(true)
  }

  const handleHideInput = (): void => {
    setIsDateInputVisible(false)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (setDate) {
      setDate(e.currentTarget.value)
    }
  }

  useEffect(() => {
    if (isDateInputVisible && dateInputRef.current != null) {
      dateInputRef?.current.focus()
    }
  }, [isDateInputVisible])

  return (
    <>
      <EnvironmentMessage />

      <div className={styles.page}>
        <Menu />

        <main
          className={classNames(
            styles.main,
            limitWidth && styles.mainLimitedWidth
          )}
        >
          <header className={styles.headerContainer}>
            <div className={styles.header}>
              <div className={styles.headerInfoWrapper}>
                <Typography
                  text={title}
                  variant={TypographyVariant.h4}
                  className={styles.headerText}
                />

                {date !== undefined && (
                  <span
                    className={classNames(
                      styles.headerDate,
                      setDate ? styles.dateEdit : ''
                    )}
                    role="button"
                    onClick={handleShowInput}
                  >
                    <span className={styles.headerDateTitle}>
                      {dateLabel || 'Data'}
                    </span>

                    {isDateInputVisible && setDate ? (
                      <InputText
                        name="date"
                        onChange={handleInputChange}
                        onBlur={handleHideInput}
                        htmlType="date"
                        defaultValue={date}
                        ref={dateInputRef}
                        {...(setDateMaxToday && { max: dateToday })}
                      />
                    ) : (
                      <Typography
                        text={dateTimeFormat(date)}
                        variant={TypographyVariant.h6}
                      />
                    )}
                  </span>
                )}

                {headerHighlightedData && (
                  <span className={styles.highlightLabel}>
                    {headerHighlightedLabel && (
                      <span className={styles.caption3}>
                        {headerHighlightedLabel}
                      </span>
                    )}
                    <span className={styles.highlightBadge}>
                      <span className={styles.caption3}>
                        {headerHighlightedData}
                      </span>
                    </span>
                  </span>
                )}

                {quantityFormat && (
                  <span className={styles.quantityBadge}>
                    <span className={styles.caption3}>{quantityFormat}</span>
                    <span className={styles.caption3}>
                      {quantityLabel || defaultQuantityLabel}
                    </span>
                  </span>
                )}
              </div>

              <div className={styles.headerActionWrapper}>
                {backButton && (
                  <Button
                    label="Voltar"
                    htmlType="button"
                    type={ButtonType.ghost}
                    onClick={(): void => history.goBack()}
                    icon={<Icon name={IconNames['chevron-back']} />}
                  />
                )}

                {headerButtonAction && (
                  <Button
                    label={
                      headerButtonFilterActiveFilters
                        ? String(headerButtonFilterActiveFilters)
                        : headerButtonLabel
                    }
                    type={ButtonType.ghost}
                    onClick={debounce(headerButtonAction, 300)}
                    icon={headerButtonIcon}
                    size={ButtonSize.small}
                    {...(headerButtonFilterActiveFilters && {
                      className: filterButtonStyles.activeItems,
                    })}
                    loading={headerButtonLoading}
                  />
                )}

                {exportButton && exportButtonAction && (
                  <Button
                    label="Exportar"
                    type={ButtonType.ghost}
                    onClick={exportButtonAction}
                    icon={<Icon name={IconNames.download} />}
                    size={ButtonSize.small}
                    loading={exportButtonLoading}
                  />
                )}

                {headerSelectButtonItems && (
                  <SelectButton
                    icon={headerSelectButtonIcon}
                    iconPosition={headerSelectButtonIconPosition}
                    label={headerSelectButtonLabel}
                    items={headerSelectButtonItems}
                    loading={headerSelectButtonLoading}
                  />
                )}

                {headerLargeButtonAction && (
                  <Button
                    label={headerLargeButtonLabel}
                    onClick={debounce(headerLargeButtonAction, 300)}
                    icon={headerLargeButtonIcon}
                    iconPosition={headerLargeButtonIconPosition}
                    loading={headerLargeButtonLoading}
                  />
                )}
              </div>
            </div>
          </header>

          <section
            className={
              bottomLeftButtonAction || bottomRightButtonAction || currentPage
                ? styles.contentWrapperWithFooter
                : styles.contentWrapper
            }
          >
            {isChildrenSticky && (
              <div className={styles.stickyScrollingSpace} />
            )}
            {children}
          </section>

          {hasFooter && (
            <footer className={styles.footer}>
              {!bottomLeftButtonAction && !currentPageItems && <div />}

              {bottomLeftButtonAction && (
                <Button
                  label={bottomLeftButtonLabel}
                  type={ButtonType.outline}
                  onClick={bottomLeftButtonAction}
                  icon={bottomLeftButtonIcon}
                  size={ButtonSize.large}
                />
              )}

              {bottomRightButtonAction && (
                <Button
                  label={bottomRightButtonLabel}
                  onClick={bottomRightButtonAction}
                  icon={bottomRightButtonIcon}
                  size={ButtonSize.large}
                />
              )}

              {currentPageItems && currentPageItemsLabel && (
                <span className={styles.currentPageItems}>
                  <span className={styles.caption3}>Exibindo</span>

                  <Typography
                    text={getPlural(
                      currentPageItemsLabel,
                      currentPageItems,
                      true
                    )}
                    variant={TypographyVariant.h6}
                  />
                </span>
              )}

              {totalPages && currentPage && setCurrentPage && (
                <Pagination
                  pages={totalPages}
                  page={currentPage}
                  setPage={setCurrentPage}
                />
              )}
            </footer>
          )}
        </main>
      </div>
    </>
  )
}

export { BoxedLayout }
