import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import Skeleton from 'react-loading-skeleton'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { ReproductionLayout } from 'components/templates'

import {
  DiagnosticAnimalReadResponseProps,
  DiagnosticRow,
  DiagnosticsHeaderInfo,
} from 'app/core/types/breeding'
import { dateTimeFormat } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from '../details/styles.module.scss'

type DiagnosticsReviewTemplateProps = {
  table: Table<Partial<DiagnosticRow>>
  diagnostics: DiagnosticAnimalReadResponseProps | undefined
  headerInfo: DiagnosticsHeaderInfo
  handleCreateDiagnostic: () => Promise<void>
  page: number
  setPage: Dispatch<SetStateAction<number>>
  isLoading: boolean
}

const DiagnosticsReviewTemplate: React.FC<DiagnosticsReviewTemplateProps> = ({
  table,
  diagnostics,
  headerInfo,
  handleCreateDiagnostic,
  page,
  setPage,
  isLoading,
}) => {
  return (
    <ReproductionLayout
      backButton
      title="Diagnóstico Reprodutivo de Gestação"
      diagnosticDate={
        headerInfo.date ? (
          dateTimeFormat(headerInfo.date)
        ) : (
          <Skeleton width={100} height={18} />
        )
      }
      responsible={
        headerInfo.responsible || <Skeleton width={100} height={18} />
      }
      headerLargeButtonAction={handleCreateDiagnostic}
      headerLargeButtonLabel="Salvar"
      headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
    >
      {diagnostics?.items && (
        <BoxedTable
          title="Animais"
          table={table}
          data={diagnostics?.items}
          className={styles.boxedTable}
          quantityLabel="Fêmea"
          noDataMessage={Messages.ANIMAL_NOT_ADDED}
          disableLastCellNavigation
          isTableHeaderSticky
          currentPage={page}
          totalPages={diagnostics.pages}
          totalItems={diagnostics.total}
          setPage={setPage}
          isLoading={isLoading}
        />
      )}
    </ReproductionLayout>
  )
}

export { DiagnosticsReviewTemplate }
