import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { isAdmin } from 'app/core/auth'
import { NavigateList } from 'app/core/routes/routes'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { ParametersTemplate } from './template'

const Parameters: React.FC = () => {
  const history = useHistory()

  const checkPermission = useCallback((): void => {
    if (!isAdmin()) {
      addToast({ message: Messages.SYSTEM_PARAMETERS_ONLY_ADMIN })

      return history.push(NavigateList.serviceOrders)
    }
  }, [history])

  useEffect(() => {
    checkPermission()
  }, [checkPermission])

  return <ParametersTemplate />
}

export { Parameters }
