import { IProductProps } from './products'
import { DefaultPagination } from './system'

export enum HandlingTypes {
  first_handling = 'Primeiro Manejo',
  fourth_month_handling = 'Manejo de 4º Mês',
  weaning_handling = 'Manejo de Desmama',
  fattening_handling = 'Manejo de Engorda',
  sanitary_handling = 'Manejo Sanitário',
}

export const HandlingTypesFilter = {
  all: 'Todos',
  ...HandlingTypes,
}

export type HandlingParams = {
  handlingType: string
  farmName: string
  farmId: string
  sectorId: string
}

export type HandledAnimalsError = Record<string, string>[]

export type HandledAnimalProps = {
  id?: number
  animal_id?: number
  birth_number?: string
  birth_date?: string
  birth_weight?: number
  electronic_eartag?: string
  farm_prefix?: string
  average_daily_gain?: number
  current_weight?: number
  product?: IProductProps
  product_name?: string[]
  product_quantity?: number[]
  product_count?: number
  product_dose?: string[]
  stock_number?: string
  is_active?: boolean
  is_composite_breed?: boolean
  series?: string
  added_from_filter?: boolean
  error_message?: string
  [key: string]: unknown // Index signature
}

export type HandlingEntity = {
  id: number
  type: HandlingTypes
  date: string
  farm_id?: number
  animals?: number
  handlings?: HandledAnimalProps[]
}

export type HandlingReadResponseData = {
  items: HandlingEntity[]
} & DefaultPagination

export type HandlingHistoryProps = HandledAnimalProps &
  Pick<HandlingEntity, 'type' | 'date'>

export type HandlingResponseData = {
  erros: HandledAnimalsError
  handling_group_id: number
}

export type ImportHandlingProps = {
  stock_number: string
  electronic_eartag: string
  birth_number: string
  weight: string
  [index: number]: string
}

export type HandlingCreateRequestData = {
  date: string
  type: HandlingTypes
  farm_id: number | undefined
  handlings: HandledAnimalProps[] | undefined
}

export type HandlingStreamProps = {
  type: string
  date?: Date
  electronic_eartag: string
  birth_number: string
  stock_number: string
  is_active: boolean
  current_weight: number
  average_daily_gain: number
}
