import React from 'react'
import { useHistory } from 'react-router-dom'

import { useFarm } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'

import { FarmListTemplate } from './template'

const FarmList: React.FC = () => {
  const history = useHistory()
  const { farms, isLoading } = useFarm()

  const handleFarmCreate = (): void => history.push(NavigateList.farmCreate)

  const handleFarmEdit = (farmId: number): void =>
    history.push(`${NavigateList.farmEdit}${farmId}`)

  const handleSectorEdit = (sectorId: number): void =>
    history.push(`${NavigateList.sectorEdit}${sectorId}`)

  return (
    <FarmListTemplate
      isLoading={isLoading}
      farms={farms || []}
      onCreateClick={handleFarmCreate}
      onEditClick={handleFarmEdit}
      onSectorEditClick={handleSectorEdit}
    />
  )
}

export { FarmList }
