import { useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'
import { format, subYears } from 'date-fns'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  Icon,
  IconNames,
  Select,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { useDashboard } from 'app/core/hooks'
import { HISTORICAL_CHART_COLORS } from 'app/core/pages/dashboard/constants'
import { getHistoricalPrecipitations } from 'app/core/services'
import {
  HistorialPrecipitationsProps,
  HistoricalPrecipitationsType,
} from 'app/core/types/dashboard'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { formatPrecipitation } from '../../helpers'
import { LoadingChart, LoadingDropdown } from '../../loading'
import styles from '../../styles.module.scss'

const renderTooltip = ({
  payload,
}: {
  payload?: {
    color?: string
    name?: number
    fill?: string
    payload?: {
      month?: string
      monthYear?: string
      mean?: number
      [key: number]: number
    }
  }[]
}): React.ReactNode => {
  if (!payload) return null

  return (
    <div className={styles.tooltip}>
      {payload.map((item, index) => {
        return (
          <>
            <div key={index} className={styles.tooltipRow}>
              <div
                className={styles.tooltipBox}
                style={{ background: item?.color }}
              />
              <div className={styles.tooltipTextContainer}>
                <span className={styles.tooltipText}>
                  {item.payload?.monthYear}
                </span>
                <span
                  className={classNames(
                    styles.tooltipText,
                    styles.tooltipTextHighlight
                  )}
                >
                  {item.payload &&
                    formatPrecipitation(Number(item.payload.mean))}
                </span>
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}

type HistoricalPrecipitationMeanCardProps = {
  stations: SelectItemProps[]
}

const HistoricalPrecipitationMeanCard: React.FC<HistoricalPrecipitationMeanCardProps> =
  ({ stations }) => {
    const noStations = stations.length === 0
    const defaultStationId = Number(stations[0].value)
    const lastYear = format(subYears(new Date(), 1), 'yyyy')

    const { formatHistoricalData } = useDashboard()

    const [data, setData] = useState<HistorialPrecipitationsProps>()
    const [dataLegend, setDataLegend] = useState<string[]>([])
    const [stationId, setStationId] = useState<number>(defaultStationId)
    const [isLoading, setIsLoading] = useState(false)

    const getData = useCallback(async () => {
      try {
        setIsLoading(true)

        const payload = {
          type: HistoricalPrecipitationsType.last_three_years,
          station_id: stationId,
        }

        const precipitations = await getHistoricalPrecipitations(payload)

        const formattedPrecipitations = formatHistoricalData(precipitations)

        setData(formattedPrecipitations)
        setDataLegend(Object.keys(precipitations.current))
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        addToast({ message: Messages.ERROR_MESSAGE })
      }
    }, [formatHistoricalData, stationId])

    useEffect(() => {
      getData()
    }, [getData])

    return (
      <>
        <div className={styles.card}>
          <div className={styles.chartHeader}>
            <div>
              <Typography
                text="Índices Pluviométricos"
                variant={TypographyVariant.h5}
                className={styles.cardTitle}
              />
              <Typography
                text="Últimos três anos"
                variant={TypographyVariant.p}
              />
            </div>
            <div className={styles.fields}>
              <div className={styles.field}>
                <span className={styles.fieldLabel}>Estação</span>
                {noStations || isLoading ? (
                  <LoadingDropdown />
                ) : (
                  <div className={styles.fieldWrapper}>
                    <Icon
                      className={styles.fieldIcon}
                      name={IconNames.information}
                    />
                    <Select
                      name="station"
                      centerText={false}
                      options={stations}
                      onChange={(value): void =>
                        setStationId(Number((value as SelectItemProps).value))
                      }
                      height="40px"
                      valueMargin="0 0 0 30px"
                      defaultValue={stations.find(
                        station => Number(station.value) === stationId
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {isLoading ? (
            <LoadingChart />
          ) : (
            <ResponsiveContainer width="100%" height="100%" minHeight={400}>
              <ComposedChart
                className="recharts-precipitation"
                width={500}
                height={300}
                data={data?.historical}
                margin={{ left: 30, top: 30, bottom: 15 }}
              >
                {data?.historical.length === 0 && (
                  <text
                    x="50%"
                    y="50%"
                    className={styles.emptyDataMessage}
                    textAnchor="middle"
                  >
                    {Messages.DASHBOARD_PRECIPITATION_EMPTY_DATA}
                  </text>
                )}
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.75} />
                <XAxis
                  allowDuplicatedCategory={false}
                  dataKey="month"
                  tickMargin={20}
                  fontSize={14}
                />
                <YAxis>
                  <Label
                    fill="#bbb"
                    value="Precipitações (mm)"
                    position="left"
                    angle={-90}
                    fontSize={14}
                    style={{ textAnchor: 'middle' }}
                  />
                </YAxis>
                <Tooltip content={renderTooltip} />
                <Bar
                  dataKey="mean"
                  barSize={100}
                  fill={HISTORICAL_CHART_COLORS[4]}
                  label={{
                    position: 'top',
                    fill: HISTORICAL_CHART_COLORS[4],
                    fontWeight: 700,
                    offset: 5,
                    formatter: (value: number): string => `${value.toFixed(2)}`,
                  }}
                />
                {data?.current.map((year, index) => (
                  <Line
                    data={year}
                    type="linear"
                    dataKey="mean"
                    stroke={HISTORICAL_CHART_COLORS[index]}
                    strokeWidth={3}
                    activeDot={false}
                    dot={false}
                  />
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          )}

          <div className={styles.legend}>
            <div className={styles.legendItem}>
              <div className={styles.legendLabel}>
                <div
                  className={classNames(
                    styles.legendIcon,
                    styles.legendIconBlue
                  )}
                ></div>
                <span className={styles.legendLabelText}>
                  Média de chuvas{' '}
                  <span className={styles.legendLabelTextInfo}>
                    (1982 - {lastYear})
                  </span>
                </span>
              </div>
              {dataLegend.map((legend, index) => (
                <div className={styles.legendLabel}>
                  <div
                    className={classNames(styles.legendIcon)}
                    style={{ background: HISTORICAL_CHART_COLORS[index] }}
                  />
                  <span className={styles.legendLabelText}>{legend}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }

export { HistoricalPrecipitationMeanCard }
