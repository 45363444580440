import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  StockNumberAnimalsResponseData,
  StockNumberCreateProps,
} from 'app/core/types/animal'

type StockNumberFeedbackTemplateProps = {
  animals: StockNumberAnimalsResponseData | undefined
  exportFailedAnimals: () => void
  failedAnimals: StockNumberCreateProps[]
  handleFinish: () => void
  isLoading: boolean
  page: number
  setPage: Dispatch<SetStateAction<number>>
  table: Table<StockNumberCreateProps>
}

const StockNumberFeedbackTemplate: React.FC<StockNumberFeedbackTemplateProps> =
  ({
    animals,
    exportFailedAnimals,
    failedAnimals,
    handleFinish,
    isLoading,
    page,
    setPage,
    table,
  }) => {
    return (
      <BoxedLayout
        title="Atualização de número de plantel"
        headerLargeButtonAction={handleFinish}
        headerLargeButtonLabel="Finalizar"
        {...(failedAnimals.length > 0 && {
          headerButtonAction: exportFailedAnimals,
          headerButtonLabel: 'Planilha de inconsistências',
          headerButtonIcon: <Icon name={IconNames['alert']} />,
        })}
      >
        <BoxedTable
          data={animals?.items || []}
          table={table}
          quantityLabel="Entradas"
          title="Animais atualizados com sucesso"
          noDataMessage="Nenhum animal atualizado com sucesso."
          currentPage={page}
          totalPages={animals?.pages}
          totalItems={animals?.total}
          setPage={setPage}
          isLoading={isLoading}
          disableLastCellNavigation
        />
      </BoxedLayout>
    )
  }

export { StockNumberFeedbackTemplate }
