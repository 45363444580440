import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { ReproductionLayout } from 'components/templates'

import {
  InseminationAnimalProps,
  InseminationAnimalResponseData,
  InseminationProps,
  InseminationTypes,
} from 'app/core/types/hormonal'
import { Messages } from 'config/messages'

type InseminationReviewTemplateProps = {
  insemination?: InseminationProps
  animals?: InseminationAnimalResponseData
  table: Table<InseminationAnimalProps>
  setPage: Dispatch<SetStateAction<number>>
  page: number
  isLoading: boolean
  handleCreateInsemination: () => Promise<void>
}

const InseminationReviewTemplate: React.FC<InseminationReviewTemplateProps> = ({
  insemination,
  animals,
  table,
  page,
  setPage,
  isLoading,
  handleCreateInsemination,
}) => {
  return (
    <ReproductionLayout
      backButton
      title="Cobertura/Inseminação"
      inseminationType={insemination?.type}
      breedingStationId={insemination?.breeding_station_id}
      breedingStationName={insemination?.breeding_station_name}
      iatfScheduleId={insemination?.iatfschedule_id}
      iatfScheduleName={insemination?.iatfschedule_name}
      headerLargeButtonAction={handleCreateInsemination}
      headerLargeButtonLabel="Salvar"
      headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
    >
      <BoxedTable
        data={animals?.items || []}
        table={table}
        currentPage={page}
        totalPages={animals?.pages}
        totalItems={animals?.total}
        setPage={setPage}
        title="Animais"
        noDataMessage={Messages.ANIMAL_NOT_ADDED}
        isTableHeaderSticky
        isLoading={isLoading}
        disableLastCellNavigation
        disableCellsNavigationByIndex={
          insemination?.type === InseminationTypes.natural_cover
            ? [3, 4]
            : [3, 4, 5, 6]
        }
      />
    </ReproductionLayout>
  )
}

export { InseminationReviewTemplate }
