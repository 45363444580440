import Skeleton from 'react-loading-skeleton'
import styles from './styles.module.scss'

const loadingCardsQuantity = 1
const loadingSkeletonColumns = 4

const UpdateUserLoading: JSX.Element = (
    <div data-testid="loading-component">
      <div className={styles.loadingHeader}>
        <Skeleton height={25} width={145} />
      </div>

      {[...Array(loadingCardsQuantity)].map((_, cardIndex) => (
        <div key={cardIndex} className={styles.loadingCard}>
          {[...Array(loadingSkeletonColumns)].map((_, skeletonIndex) => (
            <div key={skeletonIndex}>
              <Skeleton height={25} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )


  export { UpdateUserLoading }