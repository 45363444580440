import { debounce } from 'lodash-es'

import { IIconProps, Icon, IconNames } from 'components/atoms'

type RemoveButtonProps = {
  handleRemoveItem: (itemId: number) => void
  itemId: number
  disabled?: boolean
} & IIconProps

const RemoveButton: React.FC<RemoveButtonProps> = ({
  itemId,
  disabled,
  handleRemoveItem,
  ...restProps
}) => (
  <Icon
    {...restProps}
    disabled={disabled}
    name={IconNames.close}
    onClick={debounce(
      () => {
        handleRemoveItem(itemId)
      },
      1000,
      { leading: true, trailing: false }
    )}
  />
)

export { RemoveButton }
