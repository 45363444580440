/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react'

import { InputDebounced } from 'components/atoms'

type SearchTableProps = {
  setGlobalFilter: Dispatch<SetStateAction<string>>
}

const SearchTable = ({
  setGlobalFilter,
}: SearchTableProps): React.ReactElement => {
  return (
    <InputDebounced
      autoFocus
      initialValue={''}
      handleReset={(): void => setGlobalFilter('')}
      onChange={(value): void => setGlobalFilter(value as string)}
    />
  )
}

export { SearchTable }
