import { AgeGroup } from 'app/core/types/age-group'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/age_group/'

const getAgeGroups = async (): Promise<AgeGroup[]> => {
  const response = await http.get(baseUrl)
  return response.data
}

const postAgeGroup = async (
  payload: Pick<AgeGroup, 'name' | 'start_day' | 'finish_day'>
): Promise<AgeGroup> => {
  const response = await http.post(baseUrl, payload)
  return response.data
}

const patchAgeGroup = async (
  id: number,
  payload: Pick<AgeGroup, 'name' | 'start_day' | 'finish_day'>
): Promise<AgeGroup> => {
  const response = await http.patch(`${baseUrl}${id}/`, payload)
  return response.data
}

const deleteAgeGroup = async (id: number): Promise<AgeGroup> => {
  const response = await http.delete(`${baseUrl}${id}/`)
  return response.data
}

export { deleteAgeGroup, getAgeGroups, patchAgeGroup, postAgeGroup }
