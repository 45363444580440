import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  DrawerTitle,
} from 'components/molecules'
import { BoxedTable, ISelectButtonItem, useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { useSector } from 'app/core/hooks'
import {
  StockNumberAnimalsResponseData,
  StockNumberCreateProps,
} from 'app/core/types/animal'
import {
  ANIMAL_SEX_FILTER,
  ASSOCIATION_ANIMALS_FILTER,
  FilterSections,
  REPRODUCTIVE_STATUS_FILTER,
} from 'app/core/types/filters'
import { Messages } from 'config/messages'

type StockNumberCreateTemplateProps = {
  animals: StockNumberAnimalsResponseData | undefined
  filterSections: FilterSections
  handleChange: (section: string[] | undefined) => void
  handleDisabled: (name: string) => boolean
  handleGoToReview: () => void
  handleToggleDrawer: () => void
  headerSelectButtonItems: ISelectButtonItem[]
  isDrawerOpen: boolean
  isLoading: boolean
  onSubmit: IFormProps['onSubmit']
  page: number
  setActiveFilterSection: Dispatch<SetStateAction<string[]>>
  setPage: Dispatch<SetStateAction<number>>
  table: Table<StockNumberCreateProps>
}

const StockNumberCreateTemplate: React.FC<StockNumberCreateTemplateProps> = ({
  animals,
  filterSections,
  handleChange,
  handleDisabled,
  handleGoToReview,
  handleToggleDrawer,
  headerSelectButtonItems,
  isDrawerOpen,
  isLoading,
  onSubmit,
  page,
  setActiveFilterSection,
  setPage,
  table,
}) => {
  const inputCellIndex = 5

  const { sectorsDroplist } = useSector()
  const { Form, reset } = useForm({ onSubmit })

  const resetFilters = (): void => {
    setActiveFilterSection([])
    reset()
  }

  return (
    <BoxedLayout
      title="Atualização de número de plantel"
      headerLargeButtonAction={handleGoToReview}
      headerLargeButtonLabel="Revisar"
      headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
      headerSelectButtonLabel="Planilha"
      headerSelectButtonItems={headerSelectButtonItems}
      headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
      headerSelectButtonIconPosition={ButtonIconPosition.right}
    >
      <BoxedTable
        data={animals?.items || []}
        table={table}
        quantityLabel="Entradas"
        title="Animais"
        mainButtonLabel="Incluir"
        mainButtonIcon={IconNames['add-circle']}
        mainButtonAction={handleToggleDrawer}
        noDataMessage={Messages.ANIMAL_NOT_ADDED}
        currentPage={page}
        totalPages={animals?.pages}
        totalItems={animals?.total}
        setPage={setPage}
        isLoading={isLoading}
        disableCellsNavigationByIndex={[inputCellIndex]}
        disableLastCellNavigation
      />

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerTitle text="Informações gerais" />

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                placeholder="Pesquisar nº de brinco eletrônico"
                name="electronic_eartag"
                disabled={handleDisabled('electronic_eartag')}
                htmlType="number"
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                placeholder="Pesquisar nº de nascimento"
                name="birth_number"
                disabled={handleDisabled('birth_number')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                placeholder="Pesquisar nº de plantel"
                name="stock_number"
                disabled={handleDisabled('stock_number')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {sectorsDroplist && (
              <DrawerCol>
                <Form.Select
                  label="Setor"
                  name="sector_id"
                  options={sectorsDroplist}
                  disabled={handleDisabled('sector_id')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </DrawerCol>
            )}
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                placeholder="Pesquisar nome"
                name="name"
                disabled={handleDisabled('name')}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
            <DrawerCol column>
              <label htmlFor="init_date">Período de Nascimento</label>
              <div>
                <Form.InputText
                  name="init_date"
                  htmlType="date"
                  disabled={handleDisabled('init_date')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />

                <Form.InputText
                  name="final_date"
                  htmlType="date"
                  disabled={handleDisabled('final_date')}
                  onChange={(): void =>
                    handleChange(filterSections?.generalInfoSection)
                  }
                />
              </div>
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.RadioButton
                chipButtonMode
                title="Sexo do Animal"
                name="sex"
                disabled={handleDisabled('sex')}
                options={ANIMAL_SEX_FILTER}
                onChange={(): void =>
                  handleChange(filterSections?.generalInfoSection)
                }
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerTitle text="Reprodução" />

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Desmamaram entre"
                name="weaning_date_init"
                htmlType="date"
                disabled={handleDisabled('weaning_date_init')}
                onChange={(): void =>
                  handleChange(filterSections?.reproductionSection)
                }
              />

              <Form.InputText
                emptyLabelSpacing
                name="weaning_date_end"
                htmlType="date"
                disabled={handleDisabled('weaning_date_end')}
                onChange={(): void =>
                  handleChange(filterSections?.reproductionSection)
                }
              />
            </DrawerCol>
            <DrawerCol>
              <Form.CheckboxButton
                emptyLabelSpacing
                name="aptitude"
                options={REPRODUCTIVE_STATUS_FILTER}
                disabled={handleDisabled('aptitude')}
                onChange={(): void =>
                  handleChange(filterSections?.reproductionSection)
                }
              />
            </DrawerCol>
            <DrawerCol>
              <Form.CheckboxButton
                emptyLabelSpacing
                name="association_animal"
                options={ASSOCIATION_ANIMALS_FILTER}
                disabled={handleDisabled('association_animal')}
                onChange={(): void =>
                  handleChange(filterSections?.reproductionSection)
                }
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Aplicar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>
    </BoxedLayout>
  )
}

export { StockNumberCreateTemplate }
