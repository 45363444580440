import React from 'react'

import { BarChart, BarChartData } from 'components/molecules/bar-chart'

import { CHART_COLORS } from 'app/core/pages/dashboard/constants'
import { AnimalSexLabel, AnimalSexPlural } from 'app/core/types/animal'
import { DashboardAnimalUnit } from 'app/core/types/dashboard'
import { dateShortMonthYearFormat } from 'app/core/utils'

import styles from './styles.module.scss'

type AgeGroupedAnimalUnitData = Record<string, number>

type GenderGroupedAnimalUnitData = {
  male: AgeGroupedAnimalUnitData
  female: AgeGroupedAnimalUnitData
}

export type ChartData = Record<
  string,
  {
    date: string
    farms: Record<string, GenderGroupedAnimalUnitData>
    total: GenderGroupedAnimalUnitData
  }
>

type DashboardAnimalUnitCategoriesBarChartProps = {
  sex: AnimalSexLabel
  animalUnit?: DashboardAnimalUnit[]
}

const parseChartData = (
  animalUnit: DashboardAnimalUnit[],
  sex: AnimalSexLabel
): { payloadKeys: string[]; stackKeys: string[]; data: BarChartData } => {
  const payloadKeys: string[] = []
  const stackKeys: string[] = []
  const dataIndex = animalUnit.reduce((currentDataIndex, item) => {
    if (item.animal_sex !== sex) {
      return currentDataIndex
    }
    const formattedDate = dateShortMonthYearFormat(item.date)
    const currentDate = currentDataIndex[formattedDate] ?? {
      label: formattedDate,
      stacks: {},
    }
    const currentDateGender = currentDate.stacks[item.animal_sex] ?? {
      label: AnimalSexPlural[item.animal_sex],
      payload: {},
    }
    if (!stackKeys.includes(item.animal_sex)) {
      stackKeys.push(item.animal_sex)
    }
    item.age_grouped_animal_units.forEach(ageGroupCount => {
      if (!payloadKeys.includes(ageGroupCount.label)) {
        payloadKeys.push(ageGroupCount.label)
      }
      const sum =
        (currentDateGender.payload[ageGroupCount.label] ?? 0) +
        ageGroupCount.animal_unit
      currentDateGender.payload[ageGroupCount.label] = Number(sum.toFixed(2))
    })
    return {
      ...currentDataIndex,
      [formattedDate]: {
        ...currentDate,
        stacks: {
          ...currentDate.stacks,
          [item.animal_sex]: currentDateGender,
        },
      },
    }
  }, {} as Record<string, BarChartData[0]>)
  const data = Object.values(dataIndex)
  return { data, payloadKeys, stackKeys }
}

const DashboardAnimalUnitCategoriesBarChart: React.FC<DashboardAnimalUnitCategoriesBarChartProps> =
  ({ sex, animalUnit }) => {
    const { payloadKeys, stackKeys, data } = parseChartData(
      animalUnit ?? [],
      sex
    )

    return (
      <BarChart
        containerProps={{
          width: '100%',
          height: 450,
          className: styles.container,
        }}
        xAxisProps={{
          dy: 15,
        }}
        barChartProps={{
          margin: { left: 50, bottom: 100, right: 50 },
          barGap: 8,
          barSize: 64,
        }}
        payloadKeys={payloadKeys}
        stackKeys={stackKeys}
        data={data}
        showStackLabel={false}
        colors={CHART_COLORS}
        legendProps={{
          wrapperStyle: {
            display: 'flex',
            bottom: 50,
          },
        }}
      />
    )
  }

export { DashboardAnimalUnitCategoriesBarChart }
