import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useHandlings } from 'app/core/hooks/handling'
import { NavigateList } from 'app/core/routes/routes'

import { HandlingHistoryTemplate } from './template'

const HandlingHistory: React.FC = () => {
  const history = useHistory()
  const {
    getHandlings,
    handlings,
    exportHandlingsGroups,
    isLoading,
    isLoadingExport,
  } = useHandlings()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<Record<string, unknown>>({})
  const [page, setPage] = useState<number>(1)

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleFilterHandlings = async (
    data: Record<string, unknown>
  ): Promise<void> => {
    setPage(1)
    setFilters(data)
    handleToggleDrawer()
  }

  const resetFilters = (): void => {
    setPage(1)
    setFilters({})
  }

  const handleHandlingClick = (handlingId: number): void =>
    history.push(`${NavigateList.handlingDetails}${handlingId}`)

  const handleExport = async (): Promise<void> => {
    await exportHandlingsGroups(page, filters)
  }

  useEffect(() => {
    getHandlings(page, filters)
  }, [filters, getHandlings, page])

  return (
    <HandlingHistoryTemplate
      handlings={handlings}
      onSubmit={handleFilterHandlings}
      onReset={resetFilters}
      isDrawerOpen={isDrawerOpen}
      toggleDrawer={handleToggleDrawer}
      currentPage={page}
      onPageChange={setPage}
      onHandlingClick={handleHandlingClick}
      exportHandlingsGroups={handleExport}
      isLoading={isLoading}
      isLoadingExport={isLoadingExport}
    />
  )
}

export { HandlingHistory }
