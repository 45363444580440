import { ColumnDef } from '@tanstack/react-table'

import {
  Button,
  ButtonSize,
  ButtonType,
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { isAdmin } from 'app/core/auth'
import { MeasuringStationProps } from 'app/core/types/measuring-station'

import styles from '../styles.module.scss'

const MeasuringStationColumn = (
  handleEditClick: (id: number) => void,
  handleDeleteClick?: (id: number) => void
): ColumnDef<MeasuringStationProps, unknown>[] => [
  {
    accessorKey: 'name',
    header: (): JSX.Element => (
      <Typography
        text="Nome"
        variant={TypographyVariant.caption3}
        className={styles.caption}
      />
    ),
    cell: (info): JSX.Element => (
      <div>
        <Typography
          text={info.row.original.name}
          variant={TypographyVariant.h6}
          className={!info.row.original.status ? styles.inactive : ''}
        />
      </div>
    ),
  },
  {
    accessorKey: 'farm',
    header: (): JSX.Element => (
      <Typography
        text="Fazenda"
        variant={TypographyVariant.caption3}
        className={styles.caption}
      />
    ),
    cell: (info): JSX.Element => (
      <Chip
        label={info.row.original.farm?.name}
        className={!info.row.original.status ? styles.inactive : ''}
      />
    ),
  },
  {
    accessorKey: 'status',
    header: (): JSX.Element => (
      <Typography
        text="Status"
        variant={TypographyVariant.caption3}
        className={styles.caption}
      />
    ),
    cell: (info): JSX.Element => (
      <div className={styles.statusCol}>
        <Chip
          className={styles.chip}
          colorVariant={
            info.row.original.status
              ? ChipColorVariant.statusActive
              : ChipColorVariant.statusInactive
          }
        />
        {isAdmin() && (
          <div className={styles.statusColButtons}>
            <Button
              label={'Editar'}
              size={ButtonSize.small}
              type={ButtonType.ghost}
              icon={<Icon name={IconNames.pencil} />}
              onClick={(): void => {
                handleEditClick(info.row.original.id)
              }}
            />
            {handleDeleteClick && (
              <Button
                label={'Excluir'}
                size={ButtonSize.small}
                type={ButtonType.ghost}
                icon={<Icon name={IconNames.close} />}
                onClick={(): void => {
                  handleDeleteClick(info.row.original.id)
                }}
              />
            )}
          </div>
        )}
      </div>
    ),
  },
]

export { MeasuringStationColumn }
