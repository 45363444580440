import { Table } from '@tanstack/react-table'
import React, { Dispatch, SetStateAction, useImperativeHandle } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  InputText,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DrawerButtonContainer,
} from 'components/molecules'
import { BoxedTable } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import {
  ProtocolActionProps,
  ProtocolProps,
  ProtocolValuesProps,
} from 'app/core/types/hormonal'
import { Messages } from 'config/messages'

import { FormRef } from '.'
import styles from './styles.module.scss'

type ProtocolDetailsTemplateProps = {
  defaultActionsValues: ProtocolValuesProps
  protocol: ProtocolProps
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  selectedAction?: ProtocolActionProps
  removeProtocolAction: (actionId: number) => void
  table: Table<ProtocolActionProps>
  isDrawerOpen: boolean
  isLoading: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  parentRef: React.RefObject<FormRef>
}

const ProtocolDetailsTemplate: React.FC<ProtocolDetailsTemplateProps> = ({
  defaultActionsValues: defaultValues,
  protocol,
  isModalOpen,
  setIsModalOpen,
  selectedAction,
  removeProtocolAction,
  table,
  isDrawerOpen,
  isLoading,
  handleToggleDrawer,
  onSubmit,
  parentRef,
}) => {
  const maxActions = 7

  const { register, control, handleSubmit, reset, watch } = useForm({
    defaultValues,
  })

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'actions',
  })

  const isFormValid = watch().actions.every(({ action }) => action !== '')

  useImperativeHandle(parentRef, () => ({
    resetForm(): void {
      reset()
    },
  }))

  return (
    <>
      <BoxedLayout
        backButton
        title={protocol.name}
        date={protocol.date_created}
      >
        <BoxedTable
          data={protocol.actions}
          table={table}
          title="Dias do protocolo"
          mainButtonLabel="Incluir"
          mainButtonIcon={IconNames['add-circle']}
          mainButtonAction={handleToggleDrawer}
          noDataMessage={Messages.PROTOCOL_ACTION_EMPTY}
          disableNavigation
          hideQuantityLabel
          isLoading={isLoading}
        />
      </BoxedLayout>

      <CustomDrawer
        title="Incluir dia do protocolo"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((_, index) => {
            const isFirstRow = index === 0

            return (
              <section key={index} className={styles.protocolDrawerRow}>
                <div className={styles.protocolDrawerDay}>
                  {isFirstRow && (
                    <label htmlFor={`actions.${index}.day`}>
                      Dia da aplicação
                    </label>
                  )}
                  <InputText
                    htmlType="number"
                    placeholder="Incluir dia"
                    defaultValue="0"
                    {...register(`actions.${index}.day`, { required: true })}
                  />
                </div>
                <div className={styles.protocolDrawerAction}>
                  {isFirstRow && (
                    <label htmlFor={`actions.${index}.action`}>Ação</label>
                  )}
                  <InputText
                    placeholder="Incluir ação"
                    {...register(`actions.${index}.action`, {
                      required: true,
                    })}
                  />
                </div>
                <div className={styles.protocolDrawerDose}>
                  {isFirstRow && (
                    <label htmlFor={`actions.${index}.dose`}>Quantidade</label>
                  )}
                  <InputText
                    htmlType="number"
                    placeholder="Incluir quantidade"
                    step=".01"
                    defaultValue="1"
                    {...register(`actions.${index}.dose`, {
                      required: true,
                    })}
                  />
                </div>
                <div className={styles.protocolDrawerProvider}>
                  {isFirstRow && (
                    <label htmlFor={`actions.${index}.provider`}>
                      Fabricante
                    </label>
                  )}
                  <InputText
                    placeholder="Incluir fabricante"
                    {...register(`actions.${index}.provider`)}
                  />
                </div>
                <div className={styles.protocolDrawerRemove}>
                  {fields.length > 1 && !isFirstRow && (
                    <Button
                      label="Remover"
                      size={ButtonSize.small}
                      type={ButtonType.ghost}
                      icon={<Icon name={IconNames['remove']} />}
                      onClick={(): void => remove(index)}
                    />
                  )}
                </div>
              </section>
            )
          })}

          <div className={styles.protocolDrawerRow}>
            <Button
              label="Adicionar"
              size={ButtonSize.medium}
              type={ButtonType.outline}
              icon={<Icon name={IconNames['add-circle']} />}
              iconPosition={ButtonIconPosition.left}
              onClick={(): void => {
                append(defaultValues.actions)
              }}
              disabled={fields.length >= maxActions}
            />
          </div>

          <DrawerButtonContainer>
            <Button
              label="Incluir"
              type={ButtonType.primary}
              size={ButtonSize.large}
              disabled={!isFormValid}
            />
          </DrawerButtonContainer>
        </form>
      </CustomDrawer>

      {selectedAction && (
        <CustomModal
          modalIsOpen={isModalOpen}
          handleCloseModal={(): void => setIsModalOpen(false)}
          modalHeader="Excluir dia do protocolo hormonal"
          modalText={`Você tem certeza que deseja excluir o D${selectedAction.day} do protocolo hormonal?`}
          secondaryButtonLabel="Cancelar"
          secondaryButtonAction={(): void => setIsModalOpen(false)}
          primaryButtonLabel="Excluir"
          primaryButtonAction={(): void =>
            removeProtocolAction(selectedAction.id)
          }
          primaryButtonType={ButtonType.destructive}
        />
      )}
    </>
  )
}

export { ProtocolDetailsTemplate }
