/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table'
import { useEffect, useState } from 'react'

import { AnimalFilterInput } from './components'
import { InputText, Select, SelectItemProps } from 'components/atoms'

import {
  HEAT_TYPE_OPTIONS,
  HeatTypes,
  InseminationAnimalProps,
  UpdateAnimalInseminationData,
} from 'app/core/types/hormonal'
import { InseminatorProps } from 'app/core/types/inseminator'
import { dateTimeFormat, dateTimeISOFormat } from 'app/core/utils'

import styles from './styles.module.scss'

const inseminationColumnsEditable = (
  updateAnimal: ({
    inseminatedAnimalId,
    inseminationId,
    request,
  }: UpdateAnimalInseminationData) => Promise<void>,
  breedingStationId?: number,
  heatType?: HeatTypes,
  inseminators?: InseminatorProps[]
): Partial<ColumnDef<InseminationAnimalProps>> => {
  const columns: Partial<ColumnDef<InseminationAnimalProps>> = {
    cell: ({ getValue, row: { original }, column: { id } }) => {
      const inputId = id as keyof InseminationAnimalProps & string
      const initialValue = getValue()
      const [value, setValue] = useState(initialValue)

      const inseminatorsDropdown = inseminators?.map(inseminator => {
        return { label: inseminator.name, value: inseminator.id.toString() }
      })

      const onBlur = (selectedValue: string): void => {
        if (selectedValue != original[inputId] && selectedValue) {
          setValue(selectedValue)
          updateAnimal({
            inseminatedAnimalId: original.animal_id,
            inseminationId: original.id,
            request: {
              [inputId]:
                inputId === 'insemination_date'
                  ? dateTimeISOFormat(selectedValue)
                  : selectedValue,
            },
          })
        }
      }

      const onSelect = (selectedValue: string | boolean | undefined): void => {
        setValue(selectedValue)
        updateAnimal({
          inseminatedAnimalId: original.animal_id,
          inseminationId: original.id,
          request: {
            [inputId]: selectedValue,
            ...(inputId === 'inseminator_id'
              ? {
                  responsible_inseminator: inseminators?.find(
                    inseminator => inseminator.id === Number(selectedValue)
                  )?.name,
                }
              : {}),
          },
        })
      }

      const textInputArray: (keyof InseminationAnimalProps)[] = [
        'insemination_date',
      ]

      useEffect(() => {
        setValue(initialValue)
      }, [initialValue])

      const hasInseminationDate =
        inputId === 'insemination_date' && original.insemination_date

      const defaultHeatType = original.heat_type ? original.heat_type : heatType

      if (inputId === 'heat_type') {
        return (
          <Select
            name={inputId}
            options={HEAT_TYPE_OPTIONS}
            defaultValue={HEAT_TYPE_OPTIONS.find(
              e => e.label === defaultHeatType
            )}
            onChange={(value): void =>
              onSelect((value as SelectItemProps).value)
            }
            centerText
          />
        )
      }

      if (inputId === 'inseminator_id') {
        return (
          <Select
            placeholder={
              original.responsible_inseminator || 'Selecione um inseminador'
            }
            name={inputId}
            options={inseminatorsDropdown}
            onChange={(value): void =>
              onSelect((value as SelectItemProps).value)
            }
            defaultValue={inseminatorsDropdown?.find(
              e => e.value === original.inseminator_id?.toString()
            )}
            centerText
          />
        )
      }

      if (inputId === 'semen_implanted') {
        return (
          <span className={styles.input}>
            <AnimalFilterInput
              animalId={original.animal_id}
              inseminationId={original.id}
              inputName={inputId}
              defaultValue={original.semen_implanted_stock_number || ''}
              updateAnimal={updateAnimal}
              setValue={setValue}
              heatType={defaultHeatType}
            />
          </span>
        )
      }

      if (inputId === 'breeding_animal_id') {
        return (
          <span className={styles.input}>
            <AnimalFilterInput
              animalId={original.animal_id}
              breedingStationId={breedingStationId}
              inseminationId={original.id}
              inputName={inputId}
              defaultValue={original.breeding_animal_stock_number || ''}
              updateAnimal={updateAnimal}
              setValue={setValue}
              heatType={defaultHeatType}
            />
          </span>
        )
      }

      if (textInputArray.includes(inputId)) {
        return (
          <span className={styles.input}>
            <InputText
              name={inputId}
              onBlur={(e): void => onBlur(e.target.value)}
              defaultValue={
                hasInseminationDate
                  ? dateTimeFormat(value as string, true)
                  : (value as string)
              }
              placeholder="Insira aqui"
              htmlType={inputId === 'insemination_date' ? 'date' : 'text'}
            />
          </span>
        )
      }

      return <></>
    },
  }
  return columns
}

export { inseminationColumnsEditable }
