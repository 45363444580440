import axios from 'axios'

import { StorageKeys } from 'app/core/types/storage'

const http = axios.create({
  baseURL:
    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}` ||
    'http://localhost:8000',
  withCredentials: true,
})

http.interceptors.request.use(config => {
  const token = `Bearer ${localStorage.getItem(StorageKeys.auth_token)}`

  if (token) {
    config.headers.setAuthorization(token)
  }

  return Promise.resolve(config)
})

export { http }
