import { IconNames, Typography, TypographyVariant } from 'components/atoms'
import { TypographyWeight } from 'components/atoms/typography/paragraph'
import { ExpandCard, withLoading } from 'components/molecules'
import { BoxedLayout } from 'components/templates'
import {
  BreedCol,
  Col,
  Row,
  RowSingleItem,
} from 'components/templates/animal-layout/helpers'

import { isAdmin } from 'app/core/auth'
import { FarmProps } from 'app/core/types/system'
import { cnpjMask, zipCodeMask } from 'app/core/utils/input_mask'

import style from './style.module.scss'
import { FarmSectorTable } from './table/table'

type FarmListTemplateProps = {
  isLoading: boolean
  farms: FarmProps[]
  onCreateClick: () => void
  onEditClick: (farmId: number) => void
  onSectorEditClick: (farmId: number) => void
}

const FarmListTemplate: React.FC<FarmListTemplateProps> = ({
  isLoading,
  farms,
  onCreateClick,
  onEditClick,
  onSectorEditClick,
}) => {
  return (
    <BoxedLayout
      title="Fazendas"
      headerLargeButtonLabel={isAdmin() ? 'cadastrar' : undefined}
      headerLargeButtonAction={isAdmin() ? onCreateClick : undefined}
    >
      {withLoading(
        <>
          {farms.map((farm, i) => {
            return (
              <ExpandCard
                farm={farm}
                buttonIcon={IconNames.pencil}
                buttonLabel={isAdmin() ? 'editar' : undefined}
                buttonAction={
                  isAdmin()
                    ? (): void => {
                        onEditClick(farm.id)
                      }
                    : undefined
                }
                key={`farm-${i}`}
                className={style.farmCard}
              >
                <div className={style.farmCardContent}>
                  <RowSingleItem>
                    <Typography
                      text="informações administrativas"
                      variant={TypographyVariant.caption3}
                      className={style.caption}
                    />
                  </RowSingleItem>
                  <Row>
                    <BreedCol>
                      <Row>
                        <Col>
                          <Typography
                            text="cnpj"
                            variant={TypographyVariant.caption3}
                            className={style.caption}
                          />
                          <Typography
                            text={cnpjMask(farm.cnpj)}
                            variant={TypographyVariant.p}
                            weight={TypographyWeight.semiBold}
                          />
                        </Col>
                        <Col>
                          <Typography
                            text="inscrição estadual"
                            variant={TypographyVariant.caption3}
                            className={style.caption}
                          />
                          <Typography
                            text={farm.state_registration}
                            variant={TypographyVariant.p}
                            weight={TypographyWeight.semiBold}
                          />
                        </Col>
                      </Row>
                    </BreedCol>
                    <BreedCol>
                      <Row>
                        <Col>
                          <Typography
                            text="área total"
                            variant={TypographyVariant.caption3}
                            className={style.caption}
                          />
                          <Typography
                            text={`${farm.total_area} ha`}
                            variant={TypographyVariant.p}
                            weight={TypographyWeight.semiBold}
                          />
                        </Col>
                        <Col>
                          <Typography
                            text="área produtiva"
                            variant={TypographyVariant.caption3}
                            className={style.caption}
                          />
                          <Typography
                            text={`${farm.productive_area} ha`}
                            variant={TypographyVariant.p}
                            weight={TypographyWeight.semiBold}
                          />
                        </Col>
                      </Row>
                    </BreedCol>
                  </Row>
                  <RowSingleItem>
                    <Typography
                      text="informações da fazenda"
                      variant={TypographyVariant.caption3}
                      className={style.caption}
                    />
                  </RowSingleItem>
                  <BreedCol>
                    <Row>
                      <Col>
                        <Typography
                          text="cep"
                          variant={TypographyVariant.caption3}
                          className={style.caption}
                        />
                        <Typography
                          text={zipCodeMask(farm.zip_code.toString())}
                          variant={TypographyVariant.p}
                          weight={TypographyWeight.semiBold}
                        />
                      </Col>
                      <Col>
                        <Typography
                          text="endereço"
                          variant={TypographyVariant.caption3}
                          className={style.caption}
                        />
                        <Typography
                          text={farm.address}
                          variant={TypographyVariant.p}
                          weight={TypographyWeight.semiBold}
                        />
                      </Col>
                      <Col>
                        <Typography
                          text="número"
                          variant={TypographyVariant.caption3}
                          className={style.caption}
                        />
                        <Typography
                          text={farm.address_number}
                          variant={TypographyVariant.p}
                          weight={TypographyWeight.semiBold}
                        />
                      </Col>
                      <Col>
                        <Typography
                          text="bairro"
                          variant={TypographyVariant.caption3}
                          className={style.caption}
                        />
                        <Typography
                          text={farm.neighborhood}
                          variant={TypographyVariant.p}
                          weight={TypographyWeight.semiBold}
                        />
                      </Col>
                      <Col>
                        <Typography
                          text="complemento"
                          variant={TypographyVariant.caption3}
                          className={style.caption}
                        />
                        <Typography
                          text={farm.complement}
                          variant={TypographyVariant.p}
                          weight={TypographyWeight.semiBold}
                        />
                      </Col>

                      <Col>
                        <Typography
                          text="cidade"
                          variant={TypographyVariant.caption3}
                          className={style.caption}
                        />
                        <Typography
                          text={farm.city}
                          variant={TypographyVariant.p}
                          weight={TypographyWeight.semiBold}
                        />
                      </Col>
                      <Col>
                        <Typography
                          text="estado"
                          variant={TypographyVariant.caption3}
                          className={style.caption}
                        />
                        <Typography
                          text={farm.state}
                          variant={TypographyVariant.p}
                          weight={TypographyWeight.semiBold}
                        />
                      </Col>
                    </Row>
                  </BreedCol>
                  <FarmSectorTable
                    farm={farm}
                    onSectorEditClick={onSectorEditClick}
                  />
                </div>
              </ExpandCard>
            )
          })}
        </>
      )(isLoading)}
    </BoxedLayout>
  )
}

export { FarmListTemplate }
