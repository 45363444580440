import { useCallback, useState } from 'react'

export type SectionDrawerHook = {
  handleDisabled: (name: string) => boolean
  handleChange: (
    field: string,
    value: string | undefined,
    section: string[] | undefined
  ) => void
  resetActiveFilterSection: () => void
}

const useSectionDrawer = (): SectionDrawerHook => {
  const [activeFilterSection, setActiveFilterSection] = useState<string[]>([])
  const [activeFiltersBySection, setActiveFiltersBySection] = useState<
    {
      field?: string
      value?: string | undefined
    }[]
  >([])

  const handleDisabled = useCallback(
    (name: string) =>
      activeFilterSection.length > 0 && !activeFilterSection.includes(name),
    [activeFilterSection]
  )

  const handleChange = useCallback(
    (
      field: string,
      value: string | undefined,
      section: string[] | undefined
    ): void => {
      if (section) setActiveFilterSection(section)

      const getActiveFiltersByIndex = () => {
        const existingFilterIndex = activeFiltersBySection.findIndex(
          filter => filter.field === field
        )

        if (existingFilterIndex !== -1) {
          const updatedFilters = [...activeFiltersBySection]

          value === undefined
            ? updatedFilters.splice(existingFilterIndex, 1)
            : (updatedFilters[existingFilterIndex] = { field, value })

          return updatedFilters
        }

        return value !== undefined
          ? [...activeFiltersBySection, { field, value }]
          : activeFiltersBySection
      }

      const newActiveFilters = getActiveFiltersByIndex()

      setActiveFiltersBySection(newActiveFilters)

      const areFiltersEmpty =
        newActiveFilters.length === 0 ||
        newActiveFilters.every(filter => !filter.value)

      if (!value && areFiltersEmpty) {
        setActiveFiltersBySection([])
        setActiveFilterSection([])
      }
    },
    [setActiveFilterSection, activeFiltersBySection]
  )

  const resetActiveFilterSection = useCallback(() => {
    setActiveFilterSection([])
    setActiveFiltersBySection([])
  }, [])

  return {
    handleDisabled,
    handleChange,
    resetActiveFilterSection,
  }
}

export { useSectionDrawer }
