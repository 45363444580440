import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import {
  deleteMeasuringStation,
  getReadAllMeasuringStations,
  patchEditMeasuringStation,
  postCreateMeasuringStation,
} from 'app/core/services/measuring-station'
import { MeasuringStationHook } from 'app/core/types/hooks'
import {
  MeasuringStationCreateRequestData,
  MeasuringStationProps,
} from 'app/core/types/measuring-station'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

const useMeasuringStation = (): MeasuringStationHook => {
  const [allMeasuringStations, setAllMeasuringStations] =
    useState<MeasuringStationProps[]>()
  const [isLoading, setLoading] = useState<boolean>(false)

  const readAllMeasuringStations = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const data = await getReadAllMeasuringStations()

      setAllMeasuringStations(data)
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
    } finally {
      setLoading(false)
    }
  }, [])

  const addMeasuringStation = useCallback(
    async (request: MeasuringStationCreateRequestData): Promise<void> => {
      if (request) {
        try {
          await postCreateMeasuringStation(request)
          addToast({
            message: Messages.STATION_MEASUREMENT_CREATE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  const editMeasuringStation = useCallback(
    async (
      measuringStationID: number,
      request: MeasuringStationCreateRequestData
    ): Promise<void> => {
      if (request) {
        try {
          await patchEditMeasuringStation(measuringStationID, request)
          addToast({
            message: Messages.STATION_MEASUREMENT_EDIT_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  const delMeasuringStation = useCallback(
    async (measuringStationId: number): Promise<void> => {
      try {
        await deleteMeasuringStation(measuringStationId)

        await readAllMeasuringStations()

        addToast({
          message: Messages.STATION_MEASUREMENT_DELETE_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e)
      }
    },
    [readAllMeasuringStations]
  )

  const measuringStations = [...(allMeasuringStations ?? [])].filter(
    station => station.status
  )

  const measuringStationsOptions = measuringStations?.map(station => ({
    label: station.name,
    value: station.id,
  })) as unknown as SelectItemProps[]

  useEffect(() => {
    readAllMeasuringStations()
  }, [readAllMeasuringStations])

  return {
    measuringStations,
    allMeasuringStations,
    isLoading,
    addMeasuringStation,
    editMeasuringStation,
    delMeasuringStation,
    measuringStationsOptions,
  }
}

export { useMeasuringStation }
