import React from 'react'

import { BarChart, BarChartData } from 'components/molecules/bar-chart'

import { DashboardAnimalCount } from 'app/core/types/dashboard'

import styles from './styles.module.scss'

type DashboardCountTotalChartProps = {
  animalCount?: DashboardAnimalCount[]
}

const barColor = '#F6D378'

const parseAnimalCount = (
  animalCount: DashboardAnimalCount[]
): { payloadKeys: string[]; stackKeys: string[]; data: BarChartData } => {
  const payloadKeys: string[] = ['Total']
  const stackKeys: string[] = ['Total']
  const dataIndex = animalCount.reduce((currentDataIndex, item) => {
    const currentFarm = currentDataIndex[item.farm_name] ?? {
      label: item.farm_name,
      stacks: {},
    }
    const currentFarmStack = currentFarm.stacks['Total'] ?? {
      label: 'Total',
      payload: {},
    }

    item.age_grouped_counts.forEach(ageGroupCount => {
      currentFarmStack.payload.Total =
        (currentFarmStack.payload.Total ?? 0) + ageGroupCount.count
    })
    return {
      ...currentDataIndex,
      [item.farm_name]: {
        ...currentFarm,
        stacks: {
          Total: currentFarmStack,
        },
      },
    }
  }, {} as Record<string, BarChartData[0]>)
  const data = Object.values(dataIndex)
  return { data, payloadKeys, stackKeys }
}

const DashboardCountTotalChart: React.FC<DashboardCountTotalChartProps> = ({
  animalCount,
}) => {
  const { payloadKeys, stackKeys, data } = parseAnimalCount(animalCount ?? [])
  const colors = payloadKeys.map(() => barColor)

  return (
    <BarChart
      containerProps={{
        width: '100%',
        height: 450,
        className: styles.container,
      }}
      barChartProps={{
        margin: { left: 50, bottom: 100, right: 50 },
        barGap: 8,
        barSize: 128,
      }}
      payloadKeys={payloadKeys}
      stackKeys={stackKeys}
      data={data}
      colors={colors}
      showLegend={false}
      showStackLabel={false}
    />
  )
}

export { DashboardCountTotalChart }
