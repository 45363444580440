import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import * as yup from 'yup'

import { SelectItemProps } from 'components/atoms'
import { useForm } from 'components/organisms/form'

import { useFarm, useSector } from 'app/core/hooks'
import { IParamProps, NavigateList } from 'app/core/routes/routes'
import { SectorCreateRequestData } from 'app/core/types/system'
import { Messages, yupNumberFieldMax } from 'config/messages'

import { SectorCreateTemplate } from './template'

const SectorCreate: React.FC = () => {
  const { sectorId } = useParams<IParamProps>()
  const isEdit = !!sectorId

  const validationSchema = yup.object({
    name: yup
      .string()
      .max(50, yupNumberFieldMax('nome', 50))
      .required(Messages.YUP_REQUIRED_FIELD),
    farm: yup.mixed<SelectItemProps>().required(Messages.YUP_REQUIRED_FIELD),
    activities: yup
      .array()
      .of(yup.string())
      .min(1, Messages.YUP_REQUIRED_FIELD)
      .typeError(Messages.YUP_REQUIRED_FIELD)
      .required(Messages.YUP_REQUIRED_FIELD),
    status: yup.boolean().required(Messages.YUP_REQUIRED_FIELD),
  })

  const history = useHistory<NavigateList>()
  const { activeFarmsDroplist } = useFarm()
  const { addSector, allSectors: sectors, editSector } = useSector()
  const [sectorLoaded, setSectorLoaded] = useState(false)

  const onSubmit = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const requestData = data as SectorCreateRequestData
      requestData.farm_id = +(data.farm as SelectItemProps).value

      isEdit
        ? await editSector(+sectorId, requestData)
        : await addSector(requestData)
      history.replace(NavigateList.sectorList)
    },
    [addSector, history, editSector, isEdit, sectorId]
  )

  const { Form, setValue, control } = useForm({
    onSubmit,
    validationSchema,
    initialValues: { status: true },
  })

  useEffect(() => {
    const sector = sectors?.filter(sector => sector.id == +sectorId)[0]

    if (
      sectors?.length &&
      activeFarmsDroplist?.length &&
      sector &&
      !sectorLoaded
    ) {
      setValue('name', sector.name)
      setValue(
        'farm',
        activeFarmsDroplist?.find(farm => +farm.value === sector.farm?.id)
      )
      setValue(
        'activities',
        sector.activities?.map(activity => activity.activity)
      )
      setValue('status', sector.status)

      setSectorLoaded(true)
    }
  }, [sectorId, activeFarmsDroplist, sectors, sectorLoaded, setValue])

  return (
    <SectorCreateTemplate
      form={Form}
      formControl={control}
      farms={activeFarmsDroplist ?? []}
      isEdit={isEdit}
    />
  )
}

export { SectorCreate }
