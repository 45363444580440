import React from 'react'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { getPlural } from 'app/core/utils'

import styles from './styles.module.scss'

export type DataCounterProps = {
  counter?: number
  counterLabel?: string
  filterButtonAction?: () => void
  filterButtonActiveFilters?: number
}

const DataCounter: React.FC<DataCounterProps> = ({
  counter = 0,
  counterLabel = 'Registro',
  filterButtonAction,
  filterButtonActiveFilters,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.counter}>
        <Typography text={counter.toString()} variant={TypographyVariant.h4} />
        <span className={styles.caption3}>
          {getPlural(counterLabel, counter)}
        </span>
      </div>

      {filterButtonAction && (
        <Button
          label={
            filterButtonActiveFilters
              ? String(filterButtonActiveFilters)
              : 'Filtrar'
          }
          size={ButtonSize.small}
          icon={<Icon name={IconNames.filter} />}
          type={ButtonType.ghost}
          onClick={filterButtonAction}
          {...(filterButtonActiveFilters && { className: styles.activeItems })}
        />
      )}
    </div>
  )
}

export { DataCounter }
