import { useCallback, useEffect, useState } from 'react'

import {
  getShutdownReasons,
  postShutdownReason,
  deleteShutdownReason as deleteShutdownReasonService,
  patchShutdownReason,
} from 'app/core/services/system/shutdown'
import {
  ShutdownReasonHook,
  ShutdownReasonResponseProps,
  ShutdownType,
} from 'app/core/types/shutdown'
import { addToast } from 'app/core/utils'

const useShutdownReasons = (): ShutdownReasonHook => {
  const [allReasons, setAllReasons] = useState<ShutdownReasonResponseProps[]>(
    []
  )
  const [deathReasons, setDeathReasons] = useState<
    ShutdownReasonResponseProps[]
  >([])
  const [otherReasons, setOtherReasons] = useState<
    ShutdownReasonResponseProps[]
  >([])

  const get = async (
    type?: ShutdownType
  ): Promise<ShutdownReasonResponseProps[]> => {
    try {
      const data = await getShutdownReasons(type)
      return data
    } catch (e) {
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }

  const readAllShutdownReasons = useCallback(async () => {
    const data = await get()
    setAllReasons(data)
  }, [])

  const readDeathShutdownReasons = useCallback(async () => {
    const data = await get(ShutdownType.death)
    setDeathReasons(data)
  }, [])

  const readOtherShutdownReasons = useCallback(async () => {
    const data = await get(ShutdownType.other)
    setOtherReasons(data)
  }, [])

  useEffect(() => {
    readAllShutdownReasons()
  }, [readAllShutdownReasons])

  useEffect(() => {
    readDeathShutdownReasons()
  }, [readDeathShutdownReasons])

  useEffect(() => {
    readOtherShutdownReasons()
  }, [readOtherShutdownReasons])

  const syncState = async (): Promise<void> => {
    await readAllShutdownReasons()
    await readDeathShutdownReasons()
    await readOtherShutdownReasons()
  }

  const createShutdownReason = async (
    type: ShutdownType,
    description: string
  ): Promise<ShutdownReasonResponseProps> => {
    const response = await postShutdownReason(type, description)
    await syncState()
    return response
  }

  const deleteShutdownReason = async (
    id: number
  ): Promise<ShutdownReasonResponseProps> => {
    const response = await deleteShutdownReasonService(id)
    await syncState()
    return response
  }

  const updateShutdownReason = async (
    id: number,
    description: string
  ): Promise<ShutdownReasonResponseProps> => {
    const response = await patchShutdownReason(id, description)
    await syncState()
    return response
  }

  return {
    deleteShutdownReason,
    updateShutdownReason,
    createShutdownReason,
    allReasons,
    deathReasons,
    otherReasons,
  }
}

export { useShutdownReasons }
