import { SelectItemProps } from 'components/atoms'

import { AnimalListProps, IAnimalHeader, IAnimalIsActive } from './animal'

export enum ShutdownType {
  sale = 'Venda',
  death = 'Morte',
  other = 'Outros',
}

export enum ShutdownTypeKeys {
  sale = 'sale',
  death = 'death',
  other = 'other',
}

export enum ShutdownDescriptionLabel {
  sale = 'Comprador',
  death = 'Causa da morte',
  other = 'Causa da baixa',
}

export type ShutdownReasonStorageProps = {
  shutdown_type: string
  shutdown_description: string
}

export type ShutdownDescriptionStorageProps = {
  options: SelectItemProps[]
  selectedValue?: string
}

export type ShutdownReasonResponseProps = {
  id: number
  type: string
  description: string
}

export type ShutdownRequestProps = {
  animal_id: number
  shutdown_reason_id: number
  shutdown_date: string
  weight: number
}

export type ShutdownReasonHook = {
  allReasons: ShutdownReasonResponseProps[]
  deathReasons: ShutdownReasonResponseProps[]
  otherReasons: ShutdownReasonResponseProps[]
  updateShutdownReason: (
    id: number,
    description: string
  ) => Promise<ShutdownReasonResponseProps>
  createShutdownReason: (
    type: ShutdownType,
    description: string
  ) => Promise<ShutdownReasonResponseProps>
  deleteShutdownReason: (id: number) => Promise<ShutdownReasonResponseProps>
}

export type ShutdownCreateProps = {
  weight?: number
  error_message?: string
  [key: string]: unknown // Index signature
} & Pick<
  AnimalListProps,
  'id' | 'electronic_eartag' | 'stock_number' | 'birth_number' | 'sex'
> &
  Pick<IAnimalHeader, 'category'> &
  Partial<Pick<IAnimalIsActive, 'shutdown_description'>>

export type ShutdownFailedProps = {
  stock_number?: string
  electronic_eartag?: string
  birth_number?: string
  error_message?: string
}
