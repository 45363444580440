import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import {
  getReadAllSectors,
  patchEditSector,
  postCreateSector,
} from 'app/core/services/system'
import { SectorHook } from 'app/core/types/hooks'
import { SectorCreateRequestData, SectorProps } from 'app/core/types/system'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

const useSector = (): SectorHook => {
  const [sectors, setSectors] = useState<SectorProps[]>()
  const [allSectors, setAllSectors] = useState<SectorProps[]>()
  const [sectorsDroplist, setSectorsDroplist] = useState<SelectItemProps[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const readAllSectors = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const data = await getReadAllSectors()

      setAllSectors(data)

      const activeSectors = data.filter(sector => sector.status)

      const SECTOR_OPTIONS = activeSectors.map(e => ({
        label: e.name,
        value: `${e.id}`,
      }))

      setLoading(false)
      setSectors(activeSectors)
      setSectorsDroplist(SECTOR_OPTIONS)
    } catch (e) {
      setLoading(false)
      const message = (e as Error).message
      addToast({ message })
      throw new Error(message)
    }
  }, [])

  const addSector = useCallback(
    async (request: SectorCreateRequestData): Promise<void> => {
      if (request) {
        try {
          await postCreateSector(request)
          addToast({
            message: Messages.SYSTEM_SECTOR_CREATE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  const editSector = useCallback(
    async (farmId: number, request: SectorCreateRequestData): Promise<void> => {
      if (request) {
        try {
          await patchEditSector(farmId, request)
          addToast({
            message: Messages.SYSTEM_SECTOR_EDIT_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  useEffect(() => {
    readAllSectors()
  }, [readAllSectors])

  return {
    allSectors,
    sectors,
    sectorsDroplist,
    addSector,
    isLoading,
    editSector,
  }
}

export { useSector }
