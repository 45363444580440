/* eslint-disable @typescript-eslint/naming-convention */
import { GenealogyCard } from 'components/molecules'

import {
  AnimalParentType,
  AnimalParentTypes,
  IAnimalGenealogy,
  IAnimalParentProps,
} from 'app/core/types/animal'

import styles from './styles.module.scss'

type GenealogyTreeProps = {
  genealogy: IAnimalGenealogy
}

const GenealogyTree: React.FC<GenealogyTreeProps> = ({ genealogy }) => {
  const {
    father_info,
    mother_info,
    imported_info: {
      imported_father,
      imported_mother,
      imported_paternal_grandfather,
      imported_maternal_grandfather,
      imported_paternal_great_grandfather,
      imported_maternal_great_grandfather,
    },
  } = genealogy

  const getCardParent = (
    parent: IAnimalParentProps,
    importedParent: string | null
  ): string | IAnimalParentProps | null => {
    if (parent?.type == AnimalParentType.breeding_group) {
      const breeding_parent = { ...parent }
      breeding_parent.name = `GR-${breeding_parent.id}`
      return breeding_parent
    }

    return parent && parent?.type !== AnimalParentType.empty
      ? parent
      : importedParent
  }

  return (
    <section className={styles.genealogyTree}>
      <GenealogyCard
        parent={getCardParent(
          father_info?.great_grandfather,
          imported_paternal_great_grandfather
        )}
        parentType={AnimalParentTypes.father_great_grandfather}
      />

      <GenealogyCard
        parent={getCardParent(
          father_info?.grandfather,
          imported_paternal_grandfather
        )}
        parentType={AnimalParentTypes.father_grandfather}
      />

      <GenealogyCard
        parent={getCardParent(father_info, imported_father)}
        parentType={AnimalParentTypes.father}
      />

      <GenealogyCard
        parent={getCardParent(mother_info, imported_mother)}
        parentType={AnimalParentTypes.mother}
      />

      <GenealogyCard
        parent={getCardParent(
          mother_info?.grandfather,
          imported_maternal_grandfather
        )}
        parentType={AnimalParentTypes.mother_grandfather}
      />

      <GenealogyCard
        parent={getCardParent(
          mother_info?.great_grandfather,
          imported_maternal_great_grandfather
        )}
        parentType={AnimalParentTypes.mother_great_grandfather}
      />
    </section>
  )
}

export { GenealogyTree }
