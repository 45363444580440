import React from 'react'

import classNames from 'classnames'

import { Typography, TypographyVariant } from 'components/atoms'
import { Divider } from 'components/atoms/divider'

import styles from './styles.module.scss'

const FormSection: React.FC<{ text: string }> = ({ children, text }) => (
  <section className={styles.formSection}>
    <Typography
      text={text}
      variant={TypographyVariant.caption2}
      className={styles.formSectionTitle}
    />
    {children}
  </section>
)

const FormRow: React.FC<{ alignRight?: boolean }> = ({
  children,
  alignRight,
}) => (
  <div
    className={classNames(styles.formRow, alignRight ? styles.alignRight : '')}
  >
    {children}
  </div>
)

const FormCol: React.FC = ({ children }) => (
  <div className={styles.formCol}>{children}</div>
)

const FormButtonContainer: React.FC = ({ children }) => (
  <div className={styles.formButtonContainer}>{children}</div>
)

const DrawerTitle: React.FC<{ text: string }> = ({ text }) => (
  <div className={styles.drawerTitle}>
    <Typography text={text} variant={TypographyVariant.h6} />
    <Divider darker />
  </div>
)

const DrawerRow: React.FC<{ singleItem?: boolean; className?: string }> = ({
  children,
  singleItem,
  className,
}) => (
  <div
    className={
      className
        ? className
        : classNames(styles.drawerRow, singleItem && styles.drawerRowSingleItem)
    }
  >
    {children}
  </div>
)

const DrawerCol: React.FC<{ column?: boolean }> = ({ column, children }) => (
  <div
    className={classNames(styles.drawerCol, column && styles.drawerColColumn)}
  >
    {children}
  </div>
)

const DrawerButtonContainer: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={classNames(styles.drawerButtonContainer, className)}>
    {children}
  </div>
)

export {
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  DrawerTitle,
  FormButtonContainer,
  FormCol,
  FormRow,
  FormSection,
}
