import React from 'react'

import { Typography, TypographyVariant } from 'components/atoms'
import { Pagination } from 'components/molecules'

import styles from './styles.module.scss'

type ServiceOrderPaginationProps = {
  currentPage: number
  currentPageItems: number
  totalItems: number
  totalPages: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const ServiceOrderPagination: React.FC<ServiceOrderPaginationProps> = ({
  currentPage,
  currentPageItems,
  totalItems,
  totalPages,
  setPage,
}) => {
  if (!currentPageItems) return null

  return (
    <footer className={styles.footer}>
      {currentPageItems > 0 && (
        <span className={styles.currentPageItems}>
          <span className={styles.caption3}>Exibindo</span>

          <Typography
            text={
              totalItems > 1
                ? `${currentPageItems} Boletins`
                : `${currentPageItems} Boletim`
            }
            variant={TypographyVariant.h6}
          />
        </span>
      )}

      {totalPages > 1 && currentPage && (
        <Pagination pages={totalPages} page={currentPage} setPage={setPage} />
      )}
    </footer>
  )
}

export { ServiceOrderPagination }
