import { useCallback, useEffect, useState } from 'react'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import { CustomDrawer, DrawerButtonContainer } from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'

import {
  ENTRY_OPTIONS,
  EntryOptions,
  HANDLING_TYPES,
} from 'app/core/pages/handlings/create/consts'
import { SHUTDOWN_REASON_OPTIONS } from 'app/core/types/filters'
import {
  ShutdownDescriptionLabel,
  ShutdownDescriptionStorageProps,
  ShutdownReasonResponseProps,
  ShutdownType,
  ShutdownTypeKeys,
} from 'app/core/types/shutdown'
import { StorageKeys } from 'app/core/types/storage'
import { FarmProps, SectorProps } from 'app/core/types/system'

type AnimalListNewEntryProps = {
  isOpen: boolean
  onClose: () => void
  farms: FarmProps[]
  farmsDroplist: SelectItemProps[]
  sectors: SectorProps[]
  sectorsDroplist: SelectItemProps[]
  onSubmit: IFormProps['onSubmit']
  shutdownReasons: ShutdownReasonResponseProps[]
}

const AnimalListNewEntry: React.FC<AnimalListNewEntryProps> = ({
  isOpen,
  onClose,
  farms,
  farmsDroplist,
  sectors,
  sectorsDroplist,
  onSubmit,
  shutdownReasons,
}) => {
  const entryTypeForm = 'entry_type'
  const handlingTypeForm = 'handling_type'
  const farmNameForm = 'farm_name'
  const sectorIdForm = 'sector_id'
  const sectorNameForm = 'sector_name'
  const shutdownReasonForm = 'shutdown_reason'
  const shutdownDescriptionForm = 'shutdown_description'

  const [newEntryType, setNewEntryType] = useState<string | undefined>('')
  const [handlingType, setHandlingType] = useState<string | undefined>()
  const [selectedFarm, setSelectedFarm] = useState<FarmProps>()
  const [selectedSector, setSelectedSector] = useState<SectorProps>()
  const [selectedShutdownReason, setSelectedShutdownReason] = useState<string>()
  const [shutdownDescriptions, setShutdownDescriptions] =
    useState<SelectItemProps[]>()
  const [selectedShutdownDescription, setSelectedShutdownDescription] =
    useState<string>()

  const farmSectors = selectedFarm?.sectors
    .filter(sector => sector.status)
    .map(e => ({
      label: e.name,
      value: `${e.id}`,
    }))

  const entryTypeChecker = (): boolean | undefined => {
    switch (newEntryType) {
      case EntryOptions.handlingCreate:
        return !(!!handlingType && !!selectedFarm && !!selectedSector)
      case EntryOptions.animalCreate:
        return !selectedSector
      case EntryOptions.animalShutdown:
        return !(!!selectedShutdownReason && !!selectedShutdownDescription)
      case EntryOptions.collectiveMovement:
      case EntryOptions.stockNumber:
      case EntryOptions.electronicEartag:
        return false
      default:
        return true
    }
  }

  const handleFormSubmit = useCallback(
    (
      data: Record<string, unknown>,
      event: React.BaseSyntheticEvent | undefined
    ): Promise<void> => {
      const newData = {
        ...data,
        newEntryType,
        selectedFarm,
        selectedSector,
        handlingType,
        selectedShutdownReason,
        selectedShutdownDescription,
      }

      return onSubmit(newData, event)
    },
    [
      newEntryType,
      selectedFarm,
      selectedSector,
      handlingType,
      selectedShutdownReason,
      selectedShutdownDescription,
      onSubmit,
    ]
  )
  const { Form: NewEntryForm, setValue } = useForm({
    onSubmit: handleFormSubmit,
  })

  useEffect(() => {
    setSelectedShutdownDescription(undefined)

    if (selectedShutdownReason) {
      setShutdownDescriptions(
        shutdownReasons
          .filter(
            reason =>
              reason.type ===
              ShutdownType[
                selectedShutdownReason as keyof typeof ShutdownTypeKeys
              ]
          )
          .map(reason => ({
            label: reason.description,
            value: reason.id.toString(),
          }))
      )
    }
  }, [selectedShutdownReason, shutdownReasons])

  useEffect(() => {
    if (shutdownDescriptions) {
      localStorage.setItem(
        StorageKeys.shutdown_descriptions,
        JSON.stringify({
          options: shutdownDescriptions,
          selectedValue: selectedShutdownDescription,
        } as ShutdownDescriptionStorageProps)
      )
    }
  }, [selectedShutdownDescription, shutdownDescriptions])

  const handleEntryTypeChange = (value: unknown): void => {
    setNewEntryType(`${(value as SelectItemProps).value}`)

    setHandlingType(undefined)
    setSelectedFarm(undefined)
    setSelectedSector(undefined)
    setSelectedShutdownReason(undefined)
    setShutdownDescriptions(undefined)

    setValue(handlingTypeForm, undefined)
    setValue(farmNameForm, undefined)
    setValue(sectorIdForm, undefined)
    setValue(sectorNameForm, undefined)
    setValue(shutdownReasonForm, undefined)
    setValue(shutdownDescriptionForm, undefined)
  }

  return (
    <CustomDrawer title="Novo Lançamento" isOpen={isOpen} onClose={onClose}>
      <NewEntryForm>
        <NewEntryForm.Select
          label="Tipo de lançamento"
          placeholder="Selecione uma opção"
          name={entryTypeForm}
          options={ENTRY_OPTIONS}
          onChange={handleEntryTypeChange}
        />

        {farms &&
          farmsDroplist &&
          newEntryType === EntryOptions.handlingCreate && (
            <>
              <NewEntryForm.Select
                label="Tipo de manejo"
                placeholder="Selecione uma opção"
                name={handlingTypeForm}
                options={HANDLING_TYPES}
                onChange={(value): void =>
                  setHandlingType(`${(value as SelectItemProps).value}`)
                }
              />
              <NewEntryForm.Select
                label="Selecionar fazenda"
                placeholder="Selecione uma opção"
                name={farmNameForm}
                options={farmsDroplist}
                onChange={(e): void =>
                  setSelectedFarm(
                    farms.find(
                      farm =>
                        farm.id === parseInt((e as SelectItemProps)?.value)
                    )
                  )
                }
              />
              {sectors && farmSectors && (
                <NewEntryForm.Select
                  label="Selecionar setor"
                  placeholder="Selecione uma opção"
                  name={sectorNameForm}
                  options={farmSectors}
                  onChange={(e): void =>
                    setSelectedSector(
                      sectors.find(
                        sector =>
                          sector.id === parseInt((e as SelectItemProps)?.value)
                      )
                    )
                  }
                />
              )}
            </>
          )}

        {sectors &&
          sectorsDroplist &&
          newEntryType === EntryOptions.animalCreate && (
            <NewEntryForm.Select
              label="Selecionar setor"
              placeholder="Selecione uma opção"
              name={sectorIdForm}
              options={sectorsDroplist}
              onChange={(e): void =>
                setSelectedSector(
                  sectors.find(
                    sector =>
                      sector.id === parseInt((e as SelectItemProps)?.value)
                  )
                )
              }
            />
          )}

        {newEntryType === EntryOptions.animalShutdown && (
          <>
            <NewEntryForm.Select
              label="Motivo de baixa"
              placeholder="Selecione uma opção"
              name={shutdownReasonForm}
              options={SHUTDOWN_REASON_OPTIONS}
              onChange={(value): void =>
                setSelectedShutdownReason(`${(value as SelectItemProps).value}`)
              }
            />

            {selectedShutdownReason && (
              <NewEntryForm.Select
                label={
                  ShutdownDescriptionLabel[
                    selectedShutdownReason as keyof typeof ShutdownType
                  ]
                }
                placeholder="Selecione uma opção"
                name={shutdownDescriptionForm}
                options={shutdownDescriptions as SelectItemProps[]}
                onChange={(value): void =>
                  setSelectedShutdownDescription(
                    `${(value as SelectItemProps).value}`
                  )
                }
              />
            )}
          </>
        )}

        <DrawerButtonContainer>
          <Button
            type={ButtonType.primary}
            label="Avançar"
            size={ButtonSize.large}
            icon={<Icon name={IconNames['chevron-forward']} />}
            iconPosition={ButtonIconPosition.right}
            disabled={entryTypeChecker()}
          />
        </DrawerButtonContainer>
      </NewEntryForm>
    </CustomDrawer>
  )
}

export { AnimalListNewEntry }
