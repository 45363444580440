import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'

import { Typography, TypographyVariant } from 'components/atoms'
import { CustomTable } from 'components/organisms'

import { ProtocolActionProps } from 'app/core/types/hormonal'
import { Messages } from 'config/messages'

import { protocolActionColumns } from '../../tables'
import styles from './styles.module.scss'

type ProtocolExpandedInfoProps = {
  data: ProtocolActionProps[]
}

const ProtocolExpandedInfo: React.FC<ProtocolExpandedInfoProps> = ({
  data,
}) => {
  const table = useReactTable({
    data,
    columns: protocolActionColumns(),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <span className={styles.expandedSection}>
      {data.length ? (
        <CustomTable table={table} disableNavigation />
      ) : (
        <span className={styles.emptyMessage}>
          <Typography
            text={Messages.PROTOCOL_ACTION_EMPTY}
            variant={TypographyVariant.p}
          />
        </span>
      )}
    </span>
  )
}

export { ProtocolExpandedInfo }
