import { NotificationProps } from 'app/core/types/notifications'
import { http } from 'interfaces/http'

const baseUrl = '/notification/'

const getNotifications = async (): Promise<NotificationProps[]> => {
  const response = await http.get(`${baseUrl}`)
  return response.data
}

const postCreateNotification = async (message: string): Promise<void> => {
  const response = await http.post(`${baseUrl}`, {
    message,
  })
  return response.data
}

const postReadNotification = async (notificationId: number): Promise<void> => {
  const response = await http.post(`${baseUrl}read/${notificationId}/`)
  return response.data
}

export { getNotifications, postCreateNotification, postReadNotification }
