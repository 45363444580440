import React, { ReactNode, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { LogoHorizontal } from 'assets/svg'
import classNames from 'classnames'
import { useMenu } from 'providers/menu'

import {
  ButtonType,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { CustomModal } from 'components/molecules'

import { isAdmin } from 'app/core/auth'
import { NavigateList } from 'app/core/routes/routes'
import { StorageKeys } from 'app/core/types/storage'
import { isTestEnvironment } from 'config/environment'

import styles from './styles.module.scss'

export interface IMenuItemProps {
  id: string
  content: string | ReactNode
  icon?: IconNames
  onClick: () => void
  submenu?: Omit<IMenuItemProps[], 'submenu'>
  className?: string
  disabled?: boolean
  restricted?: boolean
}

export interface IMenuGroupProps {
  groupName?: string
  items: IMenuItemProps[]
}

const SubmenuItem: React.FC<{ item: IMenuItemProps }> = ({ item }) => (
  <button
    className={classNames(
      styles.menuItem,
      styles.submenuItem,
      item.disabled ? styles.disabled : ''
    )}
    onClick={item.onClick}
  >
    <p
      className={classNames(
        styles.menuItemLabel,
        styles.submenuItemLabel,
        item.disabled ? styles.disabled : ''
      )}
    >
      {item.content}
    </p>
  </button>
)

const MenuItem: React.FC<{
  item: IMenuItemProps
  submenuOpen: string
}> = ({ item, submenuOpen }) => (
  <>
    <button
      className={classNames(
        styles.menuItem,
        item.disabled ? styles.disabled : ''
      )}
      role="button"
      onClick={item.onClick}
    >
      {item.icon && <Icon name={item.icon} size="18px" />}
      <span className={styles.menuItemLabelWrapper}>
        <Typography
          text={item.content}
          variant={TypographyVariant.p}
          className={classNames(
            styles.menuItemLabel,
            item.disabled ? styles.disabled : ''
          )}
        />
        {item.submenu && (
          <Icon
            name={
              submenuOpen === item.content
                ? IconNames['chevron-up']
                : IconNames['chevron-down']
            }
            size="18px"
          />
        )}
      </span>
    </button>

    <div
      className={classNames(
        styles.submenuGroup,
        submenuOpen === item.content
          ? styles.submenuGroupOpen
          : styles.submenuGroupClose,
        item.disabled ? styles.disabled : ''
      )}
      id={item.id}
    >
      {item.submenu?.map((item, i) => {
        if (!item.restricted || (item.restricted && isAdmin())) {
          return <SubmenuItem item={item} key={`submenu${i}`} />
        }
      })}
    </div>
  </>
)

const Menu: React.FC = () => {
  const { submenuOpen, setSubmenuOpen } = useMenu()
  const history = useHistory()
  const navigateTo = (): void => history.push('/')

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const handleOpenSubmenu = (label: string): void => {
    if (submenuOpen === label) {
      return setSubmenuOpen('')
    }

    setSubmenuOpen(label)
  }

  const MENU_GROUP: IMenuGroupProps[] = [
    {
      items: [
        {
          id: 'dashboard',
          icon: IconNames['file-tray'],
          content: 'Dashboard',
          onClick: (): void => {
            history.push(NavigateList.dashboard)
          },
        },
      ],
    },
    {
      groupName: 'Animais',
      items: [
        {
          id: 'rebanho',
          icon: IconNames.cow,
          content: 'Rebanho',
          onClick: (): void => {
            history.push(NavigateList.animalList)
          },
        },
        {
          id: 'manejos',
          icon: IconNames.folder,
          content: 'Manejos',
          onClick: (): void => {
            history.push(NavigateList.handlingHistory)
          },
        },
        {
          id: 'reproducao',
          icon: IconNames['add-circle'],
          content: 'Reprodução',
          onClick: (): void => {
            handleOpenSubmenu('Reprodução')
          },
          submenu: [
            {
              id: 'estacoes_de_monta',
              content: 'Estações de Monta',
              onClick: (): void => {
                history.push(NavigateList.breedingStationHistory)
              },
            },
            {
              id: 'grupos_de_repasse',
              content: 'Grupos de Repasse',
              onClick: (): void => {
                history.push(NavigateList.breedingGroupHistory)
              },
            },
            {
              id: 'protocolo_hormonal',
              content: 'Protocolo Hormonal',
              onClick: (): void => {
                history.push(NavigateList.hormonalProtocolHistory)
              },
            },
            {
              id: 'programacao_iatf',
              content: 'Programação IATF',
              onClick: (): void => {
                history.push(NavigateList.iatfScheduleHistory)
              },
            },
            {
              id: 'lancamentos_de_cobertura_inseminacao',
              content: 'Lançamentos de Cobertura/Inseminação',
              onClick: (): void => {
                history.push(NavigateList.inseminationHistory)
              },
            },
            {
              id: 'diagnostico_reprodutivo_de_gestacao',
              content: 'Diagnóstico Reprodutivo',
              onClick: (): void => {
                history.push(NavigateList.diagnosticsHistory)
              },
            },
            {
              id: 'registro_partos',
              content: 'Registro de Partos',
              onClick: (): void => {
                history.push(NavigateList.birthHistory)
              },
            },
            {
              id: 'mating_recommendation',
              content: 'Recomendações de Acasalamento',
              onClick: (): void => {
                history.push(NavigateList.matingRecommendationHistory)
              },
            },
          ],
        },
        {
          id: 'avaliacao',
          icon: IconNames.star,
          content: 'Avaliação',
          onClick: (): void => {
            history.push(NavigateList.evaluationHistory)
          },
        },
      ],
    },
    {
      groupName: 'Fazendas',
      items: [
        {
          id: 'propriedades',
          icon: IconNames.briefcase,
          content: 'Propriedades',
          onClick: (): void => {
            handleOpenSubmenu('Propriedades')
          },
          submenu: [
            {
              id: 'fazendas',
              content: 'Fazendas',
              onClick: (): void => {
                history.push(NavigateList.farmList)
              },
            },
            {
              id: 'setores',
              content: 'Setores',
              onClick: (): void => {
                history.push(NavigateList.sectorList)
              },
            },
            {
              id: 'precipitacoes',
              content: 'Precipitações',
              onClick: (): void => {
                history.push(NavigateList.precipitation)
              },
            },
            {
              id: 'estacoes',
              content: 'Estações de medição',
              onClick: (): void => {
                history.push(NavigateList.measuringStationList)
              },
              restricted: true,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          icon: IconNames.people,
          id: 'usuarios',
          content: 'Usuários',
          onClick: (): void => {
            history.push(NavigateList.userList)
          },
          restricted: true,
        },
        {
          icon: IconNames.settings,
          id: 'configuracoes',
          content: 'Configurações',
          onClick: (): void => {
            history.push(NavigateList.settings)
          },
          restricted: true,
        },
      ],
    },
  ]

  const handleLogout = (): void => {
    localStorage.removeItem(StorageKeys.auth_token)
    navigateTo()
  }

  const handleToggleModal = (): void => {
    setModalIsOpen(!modalIsOpen)
  }

  return (
    <nav className={styles.menuContainer}>
      <div className={styles.menuGroupContainer}>
        <button
          className={styles.logoContainer}
          onClick={(): void =>
            history.push(
              isTestEnvironment ? NavigateList.home : NavigateList.dashboard
            )
          }
        >
          <img src={LogoHorizontal} className={styles.logo} />
        </button>

        {MENU_GROUP.map((group, i) => (
          <div className={styles.menuGroup} key={`menuGroup${i}`}>
            {group.groupName && (
              <span className={styles.menuGroupLabelContainer}>
                <span className={styles.menuGroupLabel}>{group.groupName}</span>
              </span>
            )}
            {group.items.map((item, i) => {
              if (!item.restricted || (item.restricted && isAdmin())) {
                return (
                  <MenuItem
                    item={item}
                    submenuOpen={submenuOpen}
                    key={`menu${i}`}
                  />
                )
              }
            })}
          </div>
        ))}
      </div>

      <span className={styles.menuItem} onClick={handleToggleModal}>
        <Icon name={IconNames.exit} />
        <Typography
          text="Sair"
          variant={TypographyVariant.p}
          className={classNames(styles.menuItemLabel, styles.logout)}
        />
      </span>

      <CustomModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleToggleModal}
        modalHeader="Encerrar sessão"
        modalText={`Tem certeza que deseja sair do sistema?`}
        primaryButtonLabel="Sair"
        primaryButtonAction={handleLogout}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={handleToggleModal}
      />
    </nav>
  )
}

export { Menu }
