import { useRef } from 'react'
import { Control, Controller } from 'react-hook-form'
import Switch from 'react-switch'

import {
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { LabeledCard } from 'components/molecules'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { SECTOR_ACTIVITIES } from 'app/core/types/system'

import styles from './styles.module.scss'

type SectorCreateTemplateProps = {
  form: FormType
  formControl: Control
  farms: SelectItemProps[]
  isEdit: boolean
}

const SectorCreateTemplate: React.FC<SectorCreateTemplateProps> = ({
  form: Form,
  formControl,
  farms,
  isEdit,
}) => {
  const buttonFormSubmitRef = useRef<HTMLButtonElement>(null)
  const title = isEdit ? 'Editar setor' : 'Cadastrar setor'

  return (
    <Form>
      <BoxedLayout
        title={title}
        backButton
        headerLargeButtonLabel="salvar"
        headerLargeButtonAction={(): void =>
          buttonFormSubmitRef.current?.click()
        }
      >
        {
          <div>
            <LabeledCard title="Informações do setor">
              <div className={styles.row}>
                <Form.InputText
                  label="Nome do setor"
                  name="name"
                  placeholder="Inserir nome"
                  labeledInputClassName={styles.rowItemCol2}
                />
                <Form.Select
                  options={farms}
                  label="Fazenda"
                  name="farm"
                  placeholder="Selecionar"
                />
              </div>
              <Divider className={styles.divider} />
              <div className={styles.row}>
                <Form.CheckboxButton
                  title="ATIVIDADE PECUÁRIA"
                  name="activities"
                  options={SECTOR_ACTIVITIES}
                  chipButtonMode
                />
                <div className={styles.switch}>
                  <Typography
                    variant={TypographyVariant.caption3}
                    text={'Status'}
                    className={styles.switchLabel}
                  />
                  <Controller
                    name="status"
                    control={formControl}
                    render={({ field }): JSX.Element => (
                      <div className={styles.switchRow}>
                        <Switch
                          checked={field.value}
                          onChange={(value): void => field.onChange(value)}
                          onColor="#C6DEC5"
                          offColor="#C2C2C2"
                          onHandleColor="#6A9068"
                          offHandleColor="#606062"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={48}
                          className="react-switch"
                          id="material-switch"
                        />
                        <Typography
                          variant={TypographyVariant.label}
                          text={field.value ? 'Ativo' : 'Inativo'}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            </LabeledCard>
          </div>
        }
      </BoxedLayout>
    </Form>
  )
}

export { SectorCreateTemplate }
