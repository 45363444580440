export const IS_YEARLING_BULL = [
  {
    label: 'Sim',
    value: 'true',
  },
  {
    label: 'Não',
    value: 'false',
  },
]
