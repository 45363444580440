import { useRef, useState } from 'react'

import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  format,
  nextSunday,
  startOfMonth,
  subDays,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { debounce } from 'lodash-es'

import { FiltersHook } from 'app/core/types/hooks'
import {
  ServiceOrderDateFormatTypes,
  ServiceOrderDateTypes,
  ServiceOrderFormattedDate,
} from 'app/core/types/service-order'

const useServiceOrderFilters = (): FiltersHook => {
  const inputContainsName = useRef<HTMLInputElement>(null)
  const currentDate = {
    currentDate: new Date(),
    finalDate: new Date(),
    dateType: ServiceOrderDateTypes.day,
    formatted: format(new Date(), ServiceOrderDateFormatTypes.day, {
      locale: ptBR,
    }),
  }

  const [containsName, setContainsName] = useState<string>()
  const [formattedDate, setFormattedDate] =
    useState<ServiceOrderFormattedDate>(currentDate)
  const [mechanizationCheckbox, setMechanizationCheckbox] =
    useState<string>('true')
  const [generalServiceCheckbox, setGeneralServiceCheckbox] =
    useState<string>('true')

  const handleCalendar = (
    count: number,
    dateType: ServiceOrderDateTypes
  ): void => {
    if (dateType === ServiceOrderDateTypes.day) {
      const addDate = addDays(formattedDate.currentDate, count)
      const dayFormatted = format(addDate, ServiceOrderDateFormatTypes.day, {
        locale: ptBR,
      })

      setFormattedDate({
        currentDate: addDate,
        finalDate: addDate,
        dateType: ServiceOrderDateTypes.day,
        formatted: dayFormatted,
      })
    } else if (dateType === ServiceOrderDateTypes.week) {
      const addDate = addWeeks(formattedDate.currentDate, count)
      const weekLastDay = nextSunday(addDate)
      const weekFirstDay = subDays(weekLastDay, 6)
      const weekFirstDayFormatted = format(
        weekFirstDay,
        ServiceOrderDateFormatTypes.week,
        {
          locale: ptBR,
        }
      )
      const weekLastDayFormatted = format(
        weekLastDay,
        ServiceOrderDateFormatTypes.week,
        {
          locale: ptBR,
        }
      )
      const weekFormatted = `${weekFirstDayFormatted} - ${weekLastDayFormatted}`

      setFormattedDate({
        currentDate: weekFirstDay,
        finalDate: weekLastDay,
        dateType: ServiceOrderDateTypes.week,
        formatted: weekFormatted,
      })
    } else if (dateType === ServiceOrderDateTypes.month) {
      const addMonth = addMonths(formattedDate.currentDate, count)
      const firstDayOfMonth = startOfMonth(addMonth)
      const lastDayOfMonth = endOfMonth(addMonth)
      const monthFormatted = format(
        addMonth,
        ServiceOrderDateFormatTypes.month,
        {
          locale: ptBR,
        }
      )

      setFormattedDate({
        currentDate: firstDayOfMonth,
        finalDate: lastDayOfMonth,
        dateType: ServiceOrderDateTypes.month,
        formatted: monthFormatted,
      })
    }
  }

  const handleCleanContainsName = (): void => {
    if (inputContainsName.current) {
      inputContainsName.current.value = ''
      setContainsName('')
    }
  }

  const onChangeContainsName = debounce(
    async (containsName: string): Promise<void> => {
      setContainsName(containsName)
    },
    500
  )

  return {
    containsName,
    formattedDate,
    handleCalendar,
    handleCleanContainsName,
    inputContainsName,
    mechanizationCheckbox,
    setMechanizationCheckbox,
    generalServiceCheckbox,
    setGeneralServiceCheckbox,
    onChangeContainsName,
  }
}

export { useServiceOrderFilters }
