import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import * as yup from 'yup'

import { SelectItemProps } from 'components/atoms'
import { useForm } from 'components/organisms/form'

import { useFarm } from 'app/core/hooks'
import { useMeasuringStation } from 'app/core/hooks/measuring-station'
import { IParamProps, NavigateList } from 'app/core/routes/routes'
import { MeasuringStationCreateRequestData } from 'app/core/types/measuring-station'
import { Messages, yupNumberFieldMax } from 'config/messages'

import { MeasuringStationCreateTemplate } from './template'

const MeasuringStationCreate: React.FC = () => {
  const { measuringStationId } = useParams<IParamProps>()
  const isEdit = !!measuringStationId

  const validationSchema = yup.object({
    name: yup
      .string()
      .max(50, yupNumberFieldMax('nome', 50))
      .required(Messages.YUP_REQUIRED_FIELD),
    farm: yup.mixed<SelectItemProps>().required(Messages.YUP_REQUIRED_FIELD),
    status: yup.boolean().required(Messages.YUP_REQUIRED_FIELD),
  })

  const history = useHistory<NavigateList>()
  const { activeFarmsDroplist } = useFarm()
  const { addMeasuringStation, allMeasuringStations, editMeasuringStation } =
    useMeasuringStation()
  const [measurementStationLoaded, setMeasurementStationLoaded] =
    useState(false)

  const onSubmit = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const requestData = data as MeasuringStationCreateRequestData
      requestData.farm_id = +(data.farm as SelectItemProps).value

      isEdit
        ? await editMeasuringStation(Number(measuringStationId), requestData)
        : await addMeasuringStation(requestData)

      history.replace(NavigateList.measuringStationList)
    },
    [
      addMeasuringStation,
      editMeasuringStation,
      history,
      isEdit,
      measuringStationId,
    ]
  )

  const { Form, control, setValue } = useForm({
    onSubmit,
    validationSchema,
    initialValues: { status: true },
  })

  useEffect(() => {
    const measuringStation = allMeasuringStations?.filter(
      station => station.id == Number(measuringStationId)
    )[0]

    if (
      measuringStation &&
      activeFarmsDroplist?.length &&
      !measurementStationLoaded
    ) {
      setValue('name', measuringStation.name)
      setValue(
        'farm',
        activeFarmsDroplist?.find(
          farm => +farm.value === measuringStation?.farm?.id
        )
      )
      setValue('status', measuringStation.status)

      setMeasurementStationLoaded(true)
    }
  }, [
    activeFarmsDroplist,
    allMeasuringStations,
    measurementStationLoaded,
    measuringStationId,
    setValue,
  ])

  return (
    <MeasuringStationCreateTemplate
      form={Form}
      formControl={control}
      farms={activeFarmsDroplist ?? []}
      isEdit={isEdit}
    />
  )
}

export { MeasuringStationCreate }
