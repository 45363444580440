import { useRef } from 'react'

import { Chip, Typography, TypographyVariant } from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import {
  LabeledCard,
  LabeledCardCol,
  LabeledCardRow,
} from 'components/molecules'
import { FormType } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { ANIMAL_SEX_OPTIONS_RADIO, COWHIDE_COLORS } from 'app/core/types/animal'
import { BIRTH_TYPES, BirthProps, BirthType } from 'app/core/types/birth'
import { dateTimeFormat } from 'app/core/utils'

import styles from './styles.module.scss'

type BirthUpdateTemplateProps = {
  birth: BirthProps
  birthType: BirthType | undefined
  form: FormType
  isAbortion: boolean
}

const BirthUpdateTemplate: React.FC<BirthUpdateTemplateProps> = ({
  birth,
  form: Form,
  isAbortion,
}) => {
  const buttonFormSubmitRef = useRef<HTMLButtonElement>(null)

  const handleFormSubmit = (): void => {
    buttonFormSubmitRef.current?.click()
  }

  return (
    <BoxedLayout
      backButton
      title="Editar Partos e Crias"
      headerLargeButtonLabel="Salvar"
      headerLargeButtonAction={handleFormSubmit}
    >
      <LabeledCard title="Registro de Parto">
        <Form>
          <div className={styles.formContainer}>
            <LabeledCardRow>
              <Form.InputText
                label="Nº de Plantel"
                name="mother_stock_number"
                value={birth.mother_stock_number}
                disabled
                labeledInputClassName={styles.field}
              />

              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="mother_electronic_eartag"
                value={birth.mother_electronic_eartag}
                disabled
                labeledInputClassName={styles.field}
              />
            </LabeledCardRow>

            <LabeledCardRow>
              <Form.InputText
                label="Data do parto"
                name="birth_date"
                htmlType="date"
                defaultValue={dateTimeFormat(birth.birth_date, true)}
                labeledInputClassName={styles.field}
              />

              <div className={styles.field}>
                <Form.Select
                  label="Tipo de parto"
                  name="birth_type"
                  options={BIRTH_TYPES}
                  defaultValue={BIRTH_TYPES.find(
                    type => type.value === birth.birth_type
                  )}
                  disabled
                />
              </div>
            </LabeledCardRow>
          </div>

          {!isAbortion && (
            <>
              <Typography
                text="Registro de Cria"
                variant={TypographyVariant.h5}
              />
              <Divider />

              <div className={styles.formContainer}>
                <LabeledCardRow>
                  <Form.InputText
                    label="Nº de Nascimento"
                    name="calf_birth_number"
                    defaultValue={birth.calf_birth_number?.toString()}
                    labeledInputClassName={styles.field}
                  />

                  <Form.InputText
                    label="Nome da cria"
                    name="calf_name"
                    defaultValue={birth.calf_name}
                    labeledInputClassName={styles.field}
                  />

                  <div className={styles.field}>
                    <Form.RadioButton
                      title="Sexo do animal"
                      name="calf_sex"
                      options={ANIMAL_SEX_OPTIONS_RADIO}
                      chipButtonMode
                    />
                  </div>
                </LabeledCardRow>

                <LabeledCardRow>
                  <Form.InputText
                    label="Peso"
                    name="calf_birth_weight"
                    htmlType="number"
                    defaultValue={birth.calf_birth_weight?.toString()}
                    labeledInputClassName={styles.field}
                    appendHelperText="kg"
                  />

                  <Form.InputText
                    label="Pai / Grupo de repasse"
                    name="father"
                    value={birth?.father_name}
                    disabled
                    labeledInputClassName={styles.field}
                  />
                </LabeledCardRow>

                <LabeledCardCol>
                  <Form.RadioButton
                    title="Cor / Pelagem da cria"
                    name="calf_cowhide_color"
                    options={COWHIDE_COLORS}
                    chipButtonMode
                  />
                </LabeledCardCol>

                <div className={styles.birthDetails}>
                  <div>
                    <label className={styles.caption}>Nascimento</label>
                    <Typography
                      text={dateTimeFormat(birth.birth_date)}
                      variant={TypographyVariant.h6}
                    />
                  </div>

                  {birth?.farm_name && (
                    <div>
                      <label className={styles.caption}>Fazenda</label>
                      <Chip label={birth?.farm_name} selected={false} />
                    </div>
                  )}

                  <div>
                    <label className={styles.caption}>Setor</label>
                    <Chip label={birth?.sector_name} selected={false} />
                  </div>

                  <div>
                    <label className={styles.caption}>Espécie</label>
                    <Chip label={birth.specie} selected={false} />
                  </div>

                  <div>
                    <label className={styles.caption}>Raça</label>
                    <Chip label={birth?.breed} selected={false} />
                  </div>
                </div>
              </div>
            </>
          )}

          <button
            type="submit"
            ref={buttonFormSubmitRef}
            className={styles.hiddenButton}
          >
            Salvar
          </button>
        </Form>
      </LabeledCard>
    </BoxedLayout>
  )
}

export { BirthUpdateTemplate }
