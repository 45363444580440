import { isThisMonth, isThisWeek, isToday } from 'date-fns'
import { useServiceOrderFilters } from 'providers/service-orders/filters'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'
import SelectButton from 'components/organisms/select-button'

import { ServiceOrderDateTypes } from 'app/core/types/service-order'

import styles from './styles.module.scss'

const ServiceOrderDateFilter: React.FC = () => {
  const { formattedDate, handleCalendar } = useServiceOrderFilters()

  const dateOptions: ISelectButtonItem[] = [
    {
      name: ServiceOrderDateTypes.day,
      action: (): void => {
        handleCalendar(0, ServiceOrderDateTypes.day)
      },
    },
    {
      name: ServiceOrderDateTypes.week,
      action: (): void => {
        handleCalendar(0, ServiceOrderDateTypes.week)
      },
    },
    {
      name: ServiceOrderDateTypes.month,
      action: (): void => {
        handleCalendar(0, ServiceOrderDateTypes.month)
      },
    },
  ]

  return (
    <div className={styles.calendarBody}>
      <Button
        className={styles.calendarButtonBack}
        type={ButtonType.primary}
        label=""
        size={ButtonSize.medium}
        icon={<Icon name={IconNames['chevron-back']} />}
        onClick={(): void => handleCalendar(-1, formattedDate.dateType)}
      />
      <Typography
        text={formattedDate.formatted}
        variant={TypographyVariant.h5}
        className={styles.calendar}
      />
      <Button
        className={styles.calendarButtonForward}
        type={ButtonType.primary}
        size={ButtonSize.medium}
        iconPosition={ButtonIconPosition.right}
        icon={<Icon name={IconNames['chevron-forward']} />}
        disabled={
          formattedDate.dateType == ServiceOrderDateTypes.day
            ? isToday(formattedDate.currentDate)
            : formattedDate.dateType == ServiceOrderDateTypes.week
            ? isThisWeek(formattedDate.currentDate)
            : isThisMonth(formattedDate.currentDate)
        }
        onClick={(): void => handleCalendar(1, formattedDate.dateType)}
      />
      <SelectButton
        icon={<Icon name={IconNames['chevron-down']} />}
        iconPosition={ButtonIconPosition.right}
        label={formattedDate.dateType}
        items={dateOptions}
        className={styles.calendarButtonSelect}
      />
    </div>
  )
}

export { ServiceOrderDateFilter }
