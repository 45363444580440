import { useCallback, useEffect, useState } from 'react'

import {
  SuggestionRequestData,
  getSuggestion,
} from 'app/core/services/system/classification-cost-center-suggestion'
import { SuggestionHook } from 'app/core/types/hooks'
import { SuggestionProps } from 'app/core/types/system'
import { handleHttpError } from 'app/core/utils'

const useSuggestion = (
  suggestionInput?: SuggestionRequestData
): SuggestionHook => {
  const [suggestion, setSuggestion] = useState<SuggestionProps>()
  const [isLoading, setIsLoading] = useState(false)

  const readSuggestion = useCallback(async (): Promise<void> => {
    try {
      if (suggestionInput?.locationId && suggestionInput.serviceId) {
        setIsLoading(true)
        const data = await getSuggestion(suggestionInput)
        setSuggestion(data)
      }
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [suggestionInput])

  useEffect(() => {
    readSuggestion()
  }, [readSuggestion])

  return {
    suggestion,
    isLoading,
  }
}

export { useSuggestion }
