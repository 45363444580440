import React, { Dispatch, SetStateAction } from 'react'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { HandlingReadResponseData } from 'app/core/types/handling'

import { HANDLING_TYPES_FILTER } from './consts'
import styles from './styles.module.scss'

type HandlingHistoryTemplateProps = {
  handlings?: HandlingReadResponseData
  onSubmit: IFormProps['onSubmit']
  onReset?: () => void
  isDrawerOpen: boolean
  toggleDrawer: () => void
  currentPage: number
  onPageChange: Dispatch<SetStateAction<number>>
  onHandlingClick: (handlingId: number) => void
  exportHandlingsGroups: () => Promise<void>
  isLoading: boolean
  isLoadingExport: boolean
}

const HandlingHistoryTemplate: React.FC<HandlingHistoryTemplateProps> = ({
  handlings,
  onSubmit,
  onReset,
  isDrawerOpen,
  toggleDrawer,
  currentPage,
  onPageChange,
  onHandlingClick,
  exportHandlingsGroups,
  isLoading,
  isLoadingExport,
}) => {
  const { Form, reset, formState } = useForm({ onSubmit })
  const activeFilters = Object.entries(formState.touchedFields).length

  const handleResetFilters = (): void => {
    reset()
    onReset?.call(this)
  }

  return (
    <BoxedLayout
      title="Histórico de Manejos"
      setCurrentPage={onPageChange}
      currentPage={currentPage}
      totalPages={handlings?.pages}
      headerButtonLabel="Exportar"
      headerButtonIcon={<Icon name={IconNames['download']} />}
      headerButtonAction={exportHandlingsGroups}
      headerButtonLoading={isLoadingExport}
    >
      {withLoading(
        <>
          <DataCounter
            counter={handlings?.total}
            filterButtonAction={toggleDrawer}
            filterButtonActiveFilters={activeFilters}
          />

          <div className={styles.handlingsContainer}>
            {handlings?.items?.map((handling, i) => (
              <ExpandCard
                expandable={false}
                title={handling.type}
                singleDate={handling.date}
                dateLabel="Data do Manejo"
                animals={handling.animals}
                navigation={(): void => onHandlingClick(handling.id)}
                buttonLabel="Ver mais"
                buttonIcon={IconNames['chevron-forward']}
                buttonIconPosition={ButtonIconPosition.right}
                buttonSize={ButtonSize.small}
                key={`handling-${i}`}
              />
            ))}
          </div>
        </>
      )(isLoading)}

      <CustomDrawer
        title="Filtrar Manejos"
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
        reset={handleResetFilters}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.Select
                label="Tipo de manejo"
                name="type"
                options={HANDLING_TYPES_FILTER}
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Período do manejo"
                name="init_date"
                htmlType="date"
              />

              <Form.InputText
                emptyLabelSpacing
                name="final_date"
                htmlType="date"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Aplicar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>
    </BoxedLayout>
  )
}

export { HandlingHistoryTemplate }
