/* eslint-disable @typescript-eslint/no-explicit-any */
import { Getter } from '@tanstack/react-table'
import { Dispatch, ReactNode, ReactPortal, SetStateAction } from 'react'

import { Icon, IconNames } from 'components/atoms'

import { dateTimeFormat } from 'app/core/utils'

type RowHandlerProps = {
  data: unknown[]
  setData: Dispatch<SetStateAction<any[]>>
  selectedRowIndex: number
}

const handleDeleteRow = (
  data: unknown[],
  setData: Dispatch<SetStateAction<any[]>>,
  selectedRowIndex: number
): void => {
  const dataCopy = data.filter((_, index) => index !== selectedRowIndex)
  setData(dataCopy)
}

const DeleteRowButton: React.FC<RowHandlerProps> = ({
  data,
  setData,
  selectedRowIndex,
}) => (
  <Icon
    name={IconNames.close}
    onClick={(): void => handleDeleteRow(data, setData, selectedRowIndex)}
  />
)

const cellEmptyState = (
  getValue: Getter<ReactNode | ReactPortal>
): JSX.Element => {
  const value = getValue()
  return <span>{value || '-'}</span>
}

const cellEmptyStaticValue = (value: string | number): JSX.Element => {
  return <span>{value || '-'}</span>
}

const cellEmptyDate = (value: string): JSX.Element => {
  return <span>{value ? dateTimeFormat(value) : '-'}</span>
}

const formatNumber = (
  getValue: Getter<ReactNode | ReactPortal>
): JSX.Element => {
  return <span>{Number(getValue()).toFixed(2)}</span>
}

export {
  DeleteRowButton,
  cellEmptyDate,
  cellEmptyState,
  cellEmptyStaticValue,
  formatNumber,
  handleDeleteRow,
}
