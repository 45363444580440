import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useForm } from 'components/organisms'

import {
  useBreedingStation,
  useFarm,
  useInsemination,
  useInseminationAnimal,
  useSector,
} from 'app/core/hooks'
import { NavigateParams } from 'app/core/routes/routes'
import { deleteGroupInsemination } from 'app/core/services'
import { InseminationFilterProps } from 'app/core/types/hormonal'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

import { InseminationHistoryTemplate } from './template'

const InseminationHistory: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = (path): void => history.push(path)

  const { sectorsDroplist } = useSector()
  const { activeFarmsDroplist } = useFarm()
  const { stationsDroplistOptions } = useBreedingStation({})

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<InseminationFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [deleteGroupInseminationId, setDeleteGroupInseminationId] =
    useState<number>()

  const {
    inseminations,
    isLoading,
    readAllInseminations,
    exportInseminations,
    isLoadingExport,
  } = useInsemination({
    filters,
    page,
  })
  const { clearInseminationStorage } = useInseminationAnimal({})

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterInsemination = async (data: unknown): Promise<void> => {
    setFilters(data as InseminationFilterProps)
    handleToggleDrawer()
  }

  const handleDeleteModalClose = (): void => {
    setDeleteGroupInseminationId(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteGroupInseminationId) {
      try {
        await deleteGroupInsemination(Number(deleteGroupInseminationId))

        readAllInseminations()

        addToast({
          message: Messages.INSEMINATION_GROUP_DELETED_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      }

      handleDeleteModalClose()
    }
  }, [deleteGroupInseminationId, readAllInseminations])

  const { Form, formState, reset } = useForm({
    onSubmit: handleFilterInsemination,
  })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    setFilters(undefined)
    reset()
  }

  useEffect(() => {
    clearInseminationStorage()
  }, [clearInseminationStorage])

  return (
    <InseminationHistoryTemplate
      navigateTo={navigateTo}
      entries={inseminations}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      page={page}
      setPage={setPage}
      isLoading={isLoading}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDeleteModalConfirm={handleDeleteModalConfirm}
      deleteGroupInseminationId={deleteGroupInseminationId}
      setDeleteGroupInseminationId={setDeleteGroupInseminationId}
      onExportClick={exportInseminations}
      isLoadingExport={isLoadingExport}
      sectors={sectorsDroplist ?? []}
      form={Form}
      activeFilters={activeFilters}
      onFormReset={resetFilters}
      farms={activeFarmsDroplist ?? []}
      breedingStations={stationsDroplistOptions ?? []}
    />
  )
}

export { InseminationHistory }
