import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'

import axios from 'axios'
import * as yup from 'yup'

import { ButtonType, IconNames } from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { CustomModal, ExpandCard } from 'components/molecules'
import { BoxedTable, useForm } from 'components/organisms'

import {
  ShutdownReasonHook,
  ShutdownReasonResponseProps,
  ShutdownType,
} from 'app/core/types/shutdown'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './../../styles.module.scss'
import { shutdownReasonColumns } from './table'

type ShutdownCardProps = Pick<
  ShutdownReasonHook,
  | 'deathReasons'
  | 'otherReasons'
  | 'deleteShutdownReason'
  | 'createShutdownReason'
  | 'updateShutdownReason'
>

const ShutdownCard: React.FC<ShutdownCardProps> = ({
  deathReasons,
  otherReasons,
  deleteShutdownReason,
  createShutdownReason,
  updateShutdownReason,
}) => {
  const [modalType, setModalType] = useState<ShutdownType | undefined>()
  const [deleteItem, setDeleteItem] = useState<
    ShutdownReasonResponseProps | undefined
  >()
  const [patchItem, setPatchItem] = useState<
    ShutdownReasonResponseProps | undefined
  >()

  const validationSchema = yup.object({
    description: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleDeleteModalConfirm = async (): Promise<void> => {
    try {
      const id = deleteItem?.id
      if (!id) {
        throw new Error('Missing delete item id')
      }
      handleDeleteModalClose()
      await deleteShutdownReason(id)
      addToast({ message: 'Item deletado com sucesso', type: 'success' })
    } catch (err) {
      if (
        axios.isAxiosError(err) &&
        err.response?.data.message === 'Motivo de baixa em uso'
      ) {
        addToast({
          message: Messages.SYSTEM_SHUTDOWN_RELATION_ERROR,
        })
        return
      }

      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const handleDeleteModalClose = (): void => {
    setDeleteItem(undefined)
    setModalType(undefined)
  }

  const handleUpsertModalClose = (): void => {
    setPatchItem(undefined)
    setModalType(undefined)
  }

  const handleAddClick = (type: ShutdownType): void => {
    setModalType(type)
    reset()
  }

  const handleFormSubmit = useCallback(
    async (data: unknown): Promise<void> => {
      try {
        const { description } = data as { description: string }
        if (!modalType) {
          throw Error('Missing shutdown type')
        }
        const type = modalType
        const id = patchItem?.id
        handleUpsertModalClose()
        if (!id) {
          await createShutdownReason(type, description)
          addToast({ message: 'Adicionado com sucesso', type: 'success' })
          return
        }
        await updateShutdownReason(id, description)
        addToast({
          message: 'Alteração realizada com sucesso',
          type: 'success',
        })
      } catch (err) {
        addToast({ message: 'Aconteceu um erro inesperado', type: 'error' })
      }
    },
    [createShutdownReason, modalType, patchItem?.id, updateShutdownReason]
  )

  const { Form, handleSubmit, setValue, reset } = useForm({
    onSubmit: handleFormSubmit,
    validationSchema,
    initialValues: { description: patchItem?.description ?? '' },
  })

  const handleEditClick = useCallback(
    (type: ShutdownType, item: ShutdownReasonResponseProps): void => {
      reset()
      setValue('description', item.description)
      setModalType(type)
      setPatchItem(item)
    },
    [setValue, reset]
  )

  const handleRemoveClick = useCallback(
    (type: ShutdownType, item: ShutdownReasonResponseProps): void => {
      setModalType(type)
      setDeleteItem(item)
    },
    []
  )

  const deathColumns = useMemo<ColumnDef<ShutdownReasonResponseProps>[]>(
    () =>
      shutdownReasonColumns(
        ShutdownType.death,
        handleEditClick,
        handleRemoveClick
      ),
    [handleEditClick, handleRemoveClick]
  )

  const otherColumns = useMemo<ColumnDef<ShutdownReasonResponseProps>[]>(
    () =>
      shutdownReasonColumns(
        ShutdownType.other,
        handleEditClick,
        handleRemoveClick
      ),
    [handleEditClick, handleRemoveClick]
  )

  const deathReasonsTable = useReactTable({
    data: deathReasons ?? [],
    columns: deathColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  const otherReasonsTable = useReactTable({
    data: otherReasons ?? [],
    columns: otherColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <ExpandCard
        className={styles.settingsExpandCard}
        title="Baixas de Animais"
      >
        <div className={styles.cardContent}>
          <Divider darker />

          <BoxedTable
            className={styles.settingsBoxedTable}
            data={deathReasons}
            title="Causas de morte"
            table={deathReasonsTable}
            disableHeader
            hideQuantityLabel
            mainButtonLabel="Incluir"
            mainButtonIcon={IconNames['add-circle']}
            mainButtonAction={(): void => handleAddClick(ShutdownType.death)}
            disableNavigation
          />

          <BoxedTable
            className={styles.settingsBoxedTable}
            data={otherReasons}
            title="Outros"
            table={otherReasonsTable}
            disableHeader
            hideQuantityLabel
            mainButtonLabel="Incluir"
            mainButtonIcon={IconNames['add-circle']}
            mainButtonAction={(): void => handleAddClick(ShutdownType.other)}
            disableNavigation
          />
        </div>
        <CustomModal
          modalIsOpen={!deleteItem && !!modalType}
          handleCloseModal={handleUpsertModalClose}
          modalHeader={
            patchItem
              ? `Editar Causa de ${modalType?.toLowerCase()}`
              : `Incluir Causa de ${modalType?.toLowerCase()}`
          }
          primaryButtonLabel="SALVAR"
          primaryButtonAction={handleSubmit(handleFormSubmit)}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleUpsertModalClose}
        >
          <div className={styles.form}>
            <Form>
              <Form.InputText
                name="description"
                placeholder="Inserir nome"
                label="Nome"
              />
            </Form>
          </div>
        </CustomModal>

        <CustomModal
          modalIsOpen={!!deleteItem && !!modalType}
          handleCloseModal={handleDeleteModalClose}
          modalHeader={`Excluir Causa de ${modalType?.toLowerCase()}`}
          modalText={`Você tem certeza que deseja excluir o motivo de baixa ${deleteItem?.description}?`}
          primaryButtonLabel="EXCLUIR"
          primaryButtonAction={handleDeleteModalConfirm}
          primaryButtonType={ButtonType.destructive}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleDeleteModalClose}
        />
      </ExpandCard>
    </>
  )
}

export { ShutdownCard }
