import { useHistory } from 'react-router-dom'

import { Typography, TypographyVariant } from 'components/atoms'

import { NavigateList } from 'app/core/routes/routes'
import { MatingRecommendationProps } from 'app/core/types/mating-recommendation'
import { dateTimeFormat } from 'app/core/utils'

import styles from './styles.module.scss'

type RecommendationCardProps = {
  recommendation: MatingRecommendationProps
}

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  recommendation,
}) => {
  const history = useHistory()

  const femaleInfo = (
    <div className={styles.recommendationFemale}>
      <Typography text="Fêmea" variant={TypographyVariant.h5} />

      <div className={styles.recommendationItems}>
        <div>
          <Typography
            text="Nome"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Typography
            text={recommendation.female_name || '-'}
            variant={TypographyVariant.p}
            className={recommendation.female_name ? '' : styles.empty}
          />
        </div>

        <div>
          <Typography
            text="Nº de Plantel"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Typography
            text={recommendation.female_stock_number || 'Sem número'}
            variant={TypographyVariant.p}
            className={recommendation.female_stock_number ? '' : styles.empty}
          />
        </div>

        <div>
          <Typography
            text="Nº de nascimento"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Typography
            text={recommendation.female_birth_number || '-'}
            variant={TypographyVariant.p}
            className={recommendation.female_birth_number ? '' : styles.empty}
          />
        </div>

        {recommendation.female_birth_date && (
          <div>
            <Typography
              text="Data de nascimento"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={dateTimeFormat(recommendation.female_birth_date)}
              variant={TypographyVariant.p}
              className={recommendation.female_birth_date ? '' : styles.empty}
            />
          </div>
        )}
      </div>
    </div>
  )

  const maleInfo = (
    <div className={styles.recommendationMale}>
      <div>
        <Typography text="Macho recomendado" variant={TypographyVariant.h5} />
        <div className={styles.recommendationItems}>
          <div>
            <Typography
              text="Nome"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={recommendation.male_name || '-'}
              variant={TypographyVariant.p}
              className={recommendation.male_name ? '' : styles.empty}
            />
          </div>

          <div>
            <Typography
              text="Nº de Plantel"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={recommendation.male_stock_number || 'Sem número'}
              variant={TypographyVariant.p}
              className={recommendation.male_stock_number ? '' : styles.empty}
            />
          </div>

          <div>
            <Typography
              text="Nº de nascimento"
              variant={TypographyVariant.caption3}
              altColor
            />
            <Typography
              text={recommendation.male_birth_number || '-'}
              variant={TypographyVariant.p}
              className={recommendation.male_birth_number ? '' : styles.empty}
            />
          </div>
        </div>
      </div>

      <div>
        <Typography text="Data" variant={TypographyVariant.caption3} altColor />
        <Typography
          text={dateTimeFormat(recommendation.date)}
          variant={TypographyVariant.p}
        />
      </div>
    </div>
  )

  return (
    <>
      <div
        className={styles.recommendation}
        onClick={(): void =>
          history.push(
            `${NavigateList.matingRecommendationDetails}${recommendation?.female_id}`
          )
        }
      >
        {femaleInfo}
        {maleInfo}
      </div>
    </>
  )
}

export { RecommendationCard }
