import Skeleton from 'react-loading-skeleton'

import classNames from 'classnames'

import styles from './styles.module.scss'

const DashboardLoadingTemplate: JSX.Element = (
  <div className={styles.dashboardLoading}>
    <Skeleton width={200} height={20} />

    <div className={styles.dashboardLoadingCard}>
      <Skeleton count={10} height={90} />
    </div>

    <div
      className={classNames(
        styles.dashboardLoadingCard,
        styles.dashboardLoadingCardSingleItem
      )}
    >
      <Skeleton height={400} />
    </div>
  </div>
)

export { DashboardLoadingTemplate }
