import React from 'react'

import classNames from 'classnames'

import {
  Button,
  ButtonSize,
  ButtonType,
  EnvironmentMessage,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { Menu } from 'components/organisms'

import styles from './styles.module.scss'

export interface IDefaultLayoutProps {
  header: string
  hasFixedHeight?: boolean
  subheader?: string
  hasButton?: boolean
  buttonType?: ButtonType
  buttonSize?: ButtonSize
  buttonLabel?: string
  buttonIcon?: IconNames
  ButtonIconAlt?: string
  buttonAction?: () => void
}

const DefaultLayout: React.FC<IDefaultLayoutProps> = ({
  children,
  header,
  hasFixedHeight,
  subheader,
  buttonType = ButtonType.primary,
  buttonSize = ButtonSize.medium,
  buttonLabel = '',
  buttonIcon,
  ButtonIconAlt = '',
  buttonAction,
}) => {
  return (
    <>
      <EnvironmentMessage />

      <div
        className={classNames(
          styles.page,
          hasFixedHeight ? styles.fixedHeight : ''
        )}
      >
        <Menu />

        <main className={styles.main}>
          <header className={styles.headerContainer}>
            <div className={styles.header}>
              <Typography text={header} variant={TypographyVariant.h3} />
              {subheader && (
                <Typography
                  text={subheader}
                  variant={TypographyVariant.caption3}
                  className={styles.subheader}
                />
              )}
            </div>

            {buttonAction && (
              <Button
                type={buttonType}
                label={buttonLabel}
                size={buttonSize}
                icon={
                  <Icon
                    name={buttonIcon || IconNames['add-circle']}
                    alt={ButtonIconAlt}
                  />
                }
                onClick={buttonAction}
              />
            )}
          </header>
          {children}
        </main>
      </div>
    </>
  )
}

export { DefaultLayout }
