import classNames from 'classnames'

import { Typography, TypographyVariant } from 'components/atoms'

import { NavigateList } from 'app/core/routes/routes'
import {
  AnimalParentType,
  AnimalParentTypes,
  IAnimalParentProps,
} from 'app/core/types/animal'
import { animalFatherName } from 'app/core/utils'

import styles from './styles.module.scss'

type GenealogyCardProps = {
  parent: string | IAnimalParentProps | null
  parentType: AnimalParentTypes
}

const GenealogyCard: React.FC<GenealogyCardProps> = ({
  parent: animal,
  parentType,
}) => {
  const isImportedParent = typeof animal === 'string'
  const parent = animal as IAnimalParentProps
  const isEmpty =
    !isImportedParent &&
    (parent === null ||
      parent?.type === AnimalParentType.empty ||
      !parent?.type)

  const gridArea =
    Object.keys(AnimalParentTypes)[
      Object.values(AnimalParentTypes).indexOf(parentType)
    ]
  const isBreedingGroup =
    parent &&
    !isImportedParent &&
    parent?.type === AnimalParentType.breeding_group
      ? true
      : false

  const isClickable =
    parent && !isImportedParent && typeof parent?.id !== 'undefined'

  const navigateTo = (id: number | undefined): void => {
    const page = isBreedingGroup
      ? NavigateList.breedingGroupDetails
      : NavigateList.animalRecord
    window.open(`${page}${id}/`, '_blank')
  }

  return (
    <div
      className={classNames(
        styles.genealogyParent,
        isClickable && styles.genealogyParentClickable
      )}
      style={{ gridArea }}
      {...(isClickable && {
        onClick: (): void => navigateTo(parent?.id),
      })}
    >
      <div
        className={classNames(
          styles.genealogyCard,
          isEmpty && styles.genealogyCardEmpty,
          isImportedParent && styles.genealogyCardImported
        )}
      >
        <Typography variant={TypographyVariant.p} text={parentType} />
        {isEmpty ? (
          <span className={styles.empty}>
            Não há parentesco correspondente.
          </span>
        ) : (
          <div className={styles.animalInfo}>
            <span>
              <Typography
                altColor
                variant={TypographyVariant.caption3}
                text="Nome"
              />
              <Typography
                variant={TypographyVariant.p}
                text={isImportedParent ? parent : animalFatherName(parent)}
              />
            </span>
            {!isImportedParent && parent?.stock_number && (
              <span>
                <Typography
                  altColor
                  variant={TypographyVariant.caption3}
                  text="Nº Plantel"
                />
                <Typography
                  variant={TypographyVariant.p}
                  text={isBreedingGroup ? '-' : parent?.stock_number}
                />
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export { GenealogyCard }
