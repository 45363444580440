import { FarmParametersProps } from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/farm_ua/'

const getFarmParameters = async (): Promise<FarmParametersProps> => {
  const response = await http.get(baseUrl)
  return response.data
}

const patchFarmParameters = async (
  request: Partial<FarmParametersProps>
): Promise<FarmParametersProps> => {
  const response = await http.patch(baseUrl, request)
  return response.data
}

export { getFarmParameters, patchFarmParameters }
