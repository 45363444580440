import React from 'react'
import { useHistory } from 'react-router-dom'

import { Chip, Typography, TypographyVariant } from 'components/atoms'

import { NavigateList } from 'app/core/routes/routes'
import { DefaultLinkProps } from 'app/core/types/breeding'

import styles from './styles.module.scss'

type BreedingExpandedInfoProps = {
  title: string
  singleValue?: string
  multipleValues?: DefaultLinkProps[]
}

const BreedingExpandedInfo: React.FC<BreedingExpandedInfoProps> = ({
  title,
  singleValue,
  multipleValues,
}) => {
  const history = useHistory()

  return (
    <span className={styles.expandedSection}>
      <Typography text={title} variant={TypographyVariant.caption3} />

      {singleValue && (
        <span className={styles.expandedValues}>
          <Typography
            text={singleValue}
            variant={TypographyVariant.p}
            className={styles.value}
          />
        </span>
      )}

      {multipleValues &&
        multipleValues.length > 0 &&
        multipleValues.map((group, i) => (
          <span className={styles.expandedValues}>
            <Chip
              label={group.name}
              selected={false}
              key={i}
              onClick={(): void =>
                history.push(`${NavigateList.breedingGroupDetails}${group.id}`)
              }
            />
          </span>
        ))}
    </span>
  )
}

export { BreedingExpandedInfo }
