import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useAnimal, useEvaluations } from 'app/core/hooks'
import { useMatingRecommendations } from 'app/core/hooks/mating-recommendation'
import { IParamProps, NavigateList } from 'app/core/routes/routes'
import { AnimalListProps } from 'app/core/types/animal'
import { CollectiveMovementCreateRequestData } from 'app/core/types/collective-movements'
import { EvaluationCreateRequestData } from 'app/core/types/evaluation'
import {
  MatingRecommendationCreateRequestData,
  MatingRecommendationProps,
} from 'app/core/types/mating-recommendation'
import {
  calcAverageDailyGain,
  dateTimeISOFormat,
  dateToday,
} from 'app/core/utils'

import { MatingRecommendationDetailsTemplate } from './template'

const MatingRecommendationDetails: React.FC = () => {
  const { animalId } = useParams<IParamProps>()

  const history = useHistory<NavigateList>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedCalf, setSelectedCalf] = useState<AnimalListProps>()
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<MatingRecommendationProps>()
  const [isMatingRecommendation, setIsMatingRecommendation] = useState(false)

  const {
    animalRecommendations,
    animalCalfs,
    getAnimalCalfs,
    createRecommendation,
    editRecommendation,
    isLoadingCalfs,
    sendAnimalToDisposal,
  } = useMatingRecommendations({
    animalId,
  })
  const { animalEvaluations, createEvaluation } = useEvaluations({ animalId })
  const { animal } = useAnimal({ animalId })

  const handleNewEvaluation = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const { score, date, evaluation, weight } = data

      const avg = calcAverageDailyGain(
        selectedCalf?.birth_weight as number,
        weight as number,
        selectedCalf?.birth_date as string,
        dateToday
      )

      const request = {
        animal_id: Number(selectedCalf?.id),
        score: Number(score),
        date: dateTimeISOFormat(date as string),
        evaluation,
        ...(weight ? { weight: Number(weight), average_daily_gain: avg } : {}),
      } as EvaluationCreateRequestData

      await createEvaluation(request)

      await getAnimalCalfs()

      setSelectedCalf(undefined)
    },
    [createEvaluation, selectedCalf, getAnimalCalfs]
  )

  const handleDisposal = useCallback(
    async (destinationSectorId: number) => {
      const request = {
        target_sector_id: destinationSectorId,
        animal_ids: [animal?.id],
      } as CollectiveMovementCreateRequestData

      await sendAnimalToDisposal(request)
    },
    [animal?.id, sendAnimalToDisposal]
  )

  const handleRecommendation = useCallback(
    async (
      date: string,
      maleName: string,
      maleId?: number,
      isEditing?: boolean
    ): Promise<void> => {
      const request = {
        date: dateTimeISOFormat(date),
        male_name: maleName,
        male_id: maleId,
      } as MatingRecommendationCreateRequestData

      if (isEditing && selectedRecommendation) {
        await editRecommendation(selectedRecommendation.id, request)
        return
      }

      await createRecommendation({
        ...request,
        female_id: Number(animalId),
      })
    },
    [animalId, createRecommendation, editRecommendation, selectedRecommendation]
  )

  const handleModal = (): void => {
    setIsModalOpen(prevState => !prevState)
  }

  const handleSelectedCalf = useCallback(
    (calfId?: number, isMatingRecommendation?: boolean): void => {
      const calf = animalCalfs && animalCalfs.find(calf => calf.id === calfId)

      if (calf) {
        setSelectedCalf(calf)
      }

      setIsMatingRecommendation(!!isMatingRecommendation)
      handleModal()
    },
    [animalCalfs]
  )

  const handleSelectedRecommendation = useCallback(
    (recommendation: MatingRecommendationProps) => {
      setSelectedRecommendation(recommendation)
      setIsMatingRecommendation(true)
      handleModal()
    },
    []
  )

  const handleCalfClick = (
    event: React.MouseEvent<HTMLDivElement>,
    calfId: number
  ): void => {
    const { tagName } = event.target as HTMLButtonElement

    if (tagName !== 'BUTTON' && tagName !== 'SPAN' && tagName !== 'I') {
      history.push(`${NavigateList.animalRecord}${calfId}`)
    }
  }

  return (
    <MatingRecommendationDetailsTemplate
      animal={animal}
      animalRecommendations={animalRecommendations}
      animalCalfs={animalCalfs}
      animalEvaluations={animalEvaluations}
      isModalOpen={isModalOpen}
      isMatingRecommendation={isMatingRecommendation}
      isLoadingCalfs={isLoadingCalfs}
      handleDisposal={handleDisposal}
      handleSelectedRecommendation={handleSelectedRecommendation}
      handleModal={handleModal}
      handleSelectedCalf={handleSelectedCalf}
      selectedCalf={selectedCalf}
      selectedRecommendation={selectedRecommendation}
      setSelectedRecommendation={setSelectedRecommendation}
      handleNewEvaluation={handleNewEvaluation}
      handleRecommendation={handleRecommendation}
      onCalfClick={handleCalfClick}
    />
  )
}

export { MatingRecommendationDetails }
