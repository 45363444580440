import React, { ReactElement, useState } from 'react'

import classNames from 'classnames'

import { SelectItemProps } from '../../../../components/atoms'
import { Divider } from 'components/atoms/divider'
import { withLoading } from 'components/molecules'
import { BoxedLayout } from 'components/templates'

import {
  AnimalUnitPeriodTypes,
  DashboardAnimalCount,
  DashboardAnimalUnit,
} from 'app/core/types/dashboard'
import { FarmProps } from 'app/core/types/system'

import { DashboardLoadingTemplate } from './loading'
import styles from './styles.module.scss'
import { DashboardAnimalUnitTab } from './tabs/animal-unit'
import { DashboardCountTab } from './tabs/count'
import { PrecipitationTab } from './tabs/precipitation'

type DashboardTemplateProps = {
  animalCount?: DashboardAnimalCount[]
  animalUnit?: DashboardAnimalUnit[]
  populateAnimalUnit: (period: AnimalUnitPeriodTypes) => Promise<void>
  farms?: FarmProps[]
  measurementStationOptions: SelectItemProps[]
}

enum TabsTypes {
  animals_count = 'animals_count',
  animals_unit = 'animals_unit',
  precipitation = 'precipitation',
}

type TabButtonProps = {
  activeTab: TabsTypes
  tabType: TabsTypes
  onClick: (value: TabsTypes) => Promise<void>
}

const TabButton: React.FC<TabButtonProps> = ({
  activeTab,
  tabType,
  onClick,
  children,
}) => (
  <span
    className={classNames(
      styles.tab,
      activeTab === tabType && styles.tabActive
    )}
    onClick={(): Promise<void> => onClick(tabType)}
  >
    {children}
  </span>
)

type TabPageProps = {
  activeTab: TabsTypes
  tabType: TabsTypes
  children: ReactElement
}

const TabPage: React.FC<TabPageProps> = ({ activeTab, tabType, children }) =>
  activeTab === tabType ? children : null

const DashboardTemplate: React.FC<DashboardTemplateProps> = ({
  animalCount,
  animalUnit,
  farms,
  populateAnimalUnit,
  measurementStationOptions,
}) => {
  const [activeTab, setActiveTab] = useState<TabsTypes>(TabsTypes.animals_count)

  const defaultAnimalUnitPeriod = AnimalUnitPeriodTypes.last_twelve_months

  const handleTabClick = async (tab: TabsTypes): Promise<void> => {
    if (tab === activeTab) {
      return
    }
    setActiveTab(tab)

    if (tab === TabsTypes.animals_unit) {
      await populateAnimalUnit(defaultAnimalUnitPeriod)
    }
  }

  return (
    <BoxedLayout title="Dashboard">
      {withLoading(
        <>
          <div className={styles.tabContainer}>
            <TabButton
              activeTab={activeTab}
              tabType={TabsTypes.animals_count}
              onClick={handleTabClick}
            >
              Quantidade de Animais
            </TabButton>
            <TabButton
              activeTab={activeTab}
              tabType={TabsTypes.animals_unit}
              onClick={handleTabClick}
            >
              Unidade Animal
            </TabButton>
            <TabButton
              activeTab={activeTab}
              tabType={TabsTypes.precipitation}
              onClick={handleTabClick}
            >
              Precipitações
            </TabButton>
          </div>
          <Divider darker />
          <TabPage activeTab={activeTab} tabType={TabsTypes.animals_count}>
            <DashboardCountTab animalCount={animalCount} />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.animals_unit}>
            <DashboardAnimalUnitTab
              animalUnit={animalUnit}
              populateAnimalUnit={populateAnimalUnit}
              defaultAnimalUnitPeriod={defaultAnimalUnitPeriod}
              farms={farms}
            />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.precipitation}>
            <PrecipitationTab
              measurementStationOptions={measurementStationOptions}
            />
          </TabPage>
        </>
      )(animalCount === undefined, DashboardLoadingTemplate)}
    </BoxedLayout>
  )
}

export { DashboardTemplate }
