export enum IconNames {
  'add-circle' = 'add-circle-outline',
  alert = 'alert-circle-outline',
  briefcase = 'briefcase-outline',
  checkmark = 'checkmark-circle-outline',
  'chevron-back' = 'chevron-back',
  'chevron-down' = 'chevron-down',
  'chevron-forward' = 'chevron-forward',
  'chevron-up' = 'chevron-up',
  'close-circle' = 'close-circle-outline',
  close = 'close',
  cow = 'cow',
  ellipsis = 'ellipsis-vertical',
  exit = 'exit-outline',
  'file-tray' = 'file-tray-full-outline',
  information = 'information-circle-outline',
  list = 'list-outline',
  people = 'people-outline',
  person = 'person-circle-outline',
  'radio-button-off' = 'radio-button-off',
  'radio-button-on' = 'radio-button-on',
  ribbon = 'ribbon-outline',
  settings = 'settings-outline',
  star = 'star-outline',
  warning = 'warning-outline',
  pencil = 'pencil',
  female = 'female',
  filter = 'filter',
  male = 'male',
  search = 'search',
  calendar = 'calendar',
  download = 'download',
  check = 'check',
  'checkbox-unchecked' = 'checkbox-unchecked',
  'checkbox-checked' = 'checkbox-checked',
  add = 'add',
  remove = 'remove',
  upload = 'upload',
  dot = 'dot',
  clipboard = 'clipboard',
  folder = 'folder',
  'bar-chart-outline' = 'bar-chart-outline',
}
