import { useCallback, useEffect, useState } from 'react'

import { useAuthUser } from 'providers/auth-user'
import { useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useCostCenter, useFarm, useImport } from 'app/core/hooks'
import { ImportEntity, ImportedParameterType } from 'app/core/types/system'
import { downloadFile, generateXlsxTemplate } from 'app/core/utils'

import { CostCenterTabTemplate } from './template'

const CostCenterTab: React.FC = () => {
  const HISTORY_ITEMS_VISIBLE = 5

  const { activeFarmsDroplist } = useFarm()
  const { selectedFarm } = useAuthUser()
  const [screenSelectedFarmId, setScreenSelectedFarmId] = useState<
    string | undefined | null
  >(selectedFarm?.id)

  const { costCenters, readAllCostCenters, isLoading } = useCostCenter(
    screenSelectedFarmId || undefined
  )
  const {
    activeImport,
    importCostCenters,
    handleImportFile,
    toggleModal: toggleImportModal,
    isModalOpen: isImportModalOpen,
    isLoading: isImportLoading,
    importedFiles,
    loadImportedParameters,
    downloadImportedParameterFile,
  } = useImport()

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [historyCount, setHistoryCount] = useState(HISTORY_ITEMS_VISIBLE)

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file =>
      handleImportFile(file, ImportEntity.COST_CENTER),
  })

  const handleModalConfirm = useCallback(async () => {
    await importCostCenters()
    await readAllCostCenters()
  }, [importCostCenters, readAllCostCenters])

  const toggleHistoryModal = (): void => {
    setIsHistoryModalOpen(prevState => !prevState)
  }

  const handleShowMoreHistoryItems = (): void => {
    setHistoryCount(prevState => prevState + HISTORY_ITEMS_VISIBLE)
  }

  const handleFileDownload = useCallback(
    async (fileName: string) => {
      downloadImportedParameterFile(fileName)
    },
    [downloadImportedParameterFile]
  )

  const sheetMenuOptions: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => openFileSelector(),
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          ['Nome', 'Código', 'Código WK', 'Filial'],
          ['Centro de custo de exemplo', '123', '1', 'Todas'],
          ['Centro de custo de exemplo 2', '456', '1.0.1', 1004],
          ['Centro de custo de exemplo 3', '789', '1.0.2', 2002],
        ])

        downloadFile({
          data: templateFile,
          fileName: 'template-centros-de-custo',
        })
      },
    },
  ]

  useEffect(() => {
    loadImportedParameters(ImportedParameterType.cost_center)
  }, [loadImportedParameters])

  useEffect(() => {
    if (selectedFarm) setScreenSelectedFarmId(selectedFarm.id || null)
  }, [selectedFarm])

  return (
    <CostCenterTabTemplate
      farmsDroplist={activeFarmsDroplist}
      costCenters={costCenters}
      handleModalConfirm={handleModalConfirm}
      handleShowMoreHistoryItems={handleShowMoreHistoryItems}
      historyCount={historyCount}
      activeImport={activeImport}
      toggleHistoryModal={toggleHistoryModal}
      toggleImportModal={toggleImportModal}
      isHistoryModalOpen={isHistoryModalOpen}
      isImportModalOpen={isImportModalOpen}
      isImportLoading={isImportLoading}
      isLoading={isLoading}
      importedFiles={importedFiles}
      onImportFileClick={handleFileDownload}
      sheetMenuOptions={sheetMenuOptions}
      screenSelectedFarmId={screenSelectedFarmId}
      setScreenSelectedFarmId={setScreenSelectedFarmId}
    />
  )
}

export { CostCenterTab }
