import { useCallback, useEffect, useRef, useState } from 'react'

import { useAuthUser } from 'providers/auth-user'
import { useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useClassification, useFarm, useImport } from 'app/core/hooks'
import { useNotifications } from 'app/core/hooks/notifications'
import { ImportEntity, ImportedParameterType } from 'app/core/types/system'
import { downloadFile, generateXlsxTemplate } from 'app/core/utils'

import { ClassificationTabTemplate } from './template'

export type ClassificationFormRef = {
  resetForm: () => void
}

const ClassificationTab: React.FC = () => {
  const ref = useRef<ClassificationFormRef>(null)
  const HISTORY_ITEMS_VISIBLE = 5

  const { activeFarmsDroplist } = useFarm()
  const { selectedFarm } = useAuthUser()
  const [screenSelectedFarmId, setScreenSelectedFarmId] = useState<
    string | undefined | null
  >(selectedFarm?.id)

  const { classifications, readAllClassifications, isLoading } =
    useClassification({ farmId: Number(screenSelectedFarmId) || undefined })
  const {
    activeImport,
    importClassification,
    handleImportFile,
    toggleModal: toggleImportModal,
    isModalOpen: isImportModalOpen,
    isLoading: isImportLoading,
    importedFiles,
    loadImportedParameters,
    downloadImportedParameterFile,
  } = useImport()
  const { createNotification } = useNotifications()

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [historyCount, setHistoryCount] = useState(HISTORY_ITEMS_VISIBLE)

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file =>
      handleImportFile(file, ImportEntity.CLASSIFICATION),
  })

  const handleModalConfirm = useCallback(
    async (data: Record<string, unknown>) => {
      const { message } = data

      await importClassification()
      await readAllClassifications()

      if (message && message !== '') await createNotification(message as string)

      ref?.current?.resetForm()
    },
    [createNotification, importClassification, readAllClassifications]
  )

  const toggleHistoryModal = (): void => {
    setIsHistoryModalOpen(prevState => !prevState)
  }

  const handleShowMoreHistoryItems = (): void => {
    setHistoryCount(prevState => prevState + HISTORY_ITEMS_VISIBLE)
  }

  const handleFileDownload = useCallback(
    async (fileName: string) => {
      downloadImportedParameterFile(fileName)
    },
    [downloadImportedParameterFile]
  )

  const sheetMenuOptions: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => openFileSelector(),
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          [
            'Nome',
            'Código',
            'Código WK',
            'Filial',
            'Código WK do Centro de Custo',
          ],
          ['Classificação de exemplo', '123', '1', 'Todas', ''],
          ['Classificação de exemplo 2', '456', '1.0.1', 1004, '1.0.1'],
          ['Classificação de exemplo 3', '789', '1.0.2', 2002, '1.0.2'],
        ])

        downloadFile({
          data: templateFile,
          fileName: 'template-classificacoes',
        })
      },
    },
  ]

  useEffect(() => {
    loadImportedParameters(ImportedParameterType.classification)
  }, [loadImportedParameters])

  useEffect(() => {
    if (selectedFarm) setScreenSelectedFarmId(selectedFarm.id || null)
  }, [selectedFarm])

  return (
    <ClassificationTabTemplate
      classifications={classifications}
      farmsDroplist={activeFarmsDroplist}
      handleModalConfirm={handleModalConfirm}
      handleShowMoreHistoryItems={handleShowMoreHistoryItems}
      historyCount={historyCount}
      activeImport={activeImport}
      toggleHistoryModal={toggleHistoryModal}
      toggleImportModal={toggleImportModal}
      isHistoryModalOpen={isHistoryModalOpen}
      isImportModalOpen={isImportModalOpen}
      isImportLoading={isImportLoading}
      isLoading={isLoading}
      parentRef={ref}
      importedFiles={importedFiles}
      onImportFileClick={handleFileDownload}
      sheetMenuOptions={sheetMenuOptions}
      screenSelectedFarmId={screenSelectedFarmId}
      setScreenSelectedFarmId={setScreenSelectedFarmId}
    />
  )
}

export { ClassificationTab }
