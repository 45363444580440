import { SectorCreateRequestData, SectorProps } from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/sector/'

const getReadAllSectors = async (): Promise<SectorProps[]> => {
  const response = await http.get(baseUrl)
  return response.data
}

const postCreateSector = async (
  request: SectorCreateRequestData
): Promise<SectorProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const patchEditSector = async (
  sectorId: number,
  request: SectorCreateRequestData
): Promise<SectorProps> => {
  const response = await http.patch(`${baseUrl}${sectorId}/`, request)
  return response.data
}

export { getReadAllSectors, postCreateSector, patchEditSector }
