import {
  AssociationCreateRequestData,
  AssociationDetailsResponseData,
  AssociationProps,
} from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/association/'

const getReadAllAssociations = async (): Promise<AssociationProps[]> => {
  const response = await http.get(baseUrl)
  return response.data
}

const getReadAssociation = async (
  associationId: string | number
): Promise<AssociationDetailsResponseData> => {
  const url = `${baseUrl}${associationId}/`

  const response = await http.get(url)
  return response.data
}

const postCreateAssociation = async (
  request: AssociationCreateRequestData
): Promise<AssociationProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

export { getReadAllAssociations, getReadAssociation, postCreateAssociation }
