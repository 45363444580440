import { Table } from '@tanstack/react-table'

import { AnimalListProps } from 'app/core/types/animal'
import { HandledAnimalProps } from 'app/core/types/handling'
import { addToast } from 'app/core/utils'
import { http } from 'interfaces/http'

const getAnimal = async (
  /**
   * Table row dynamic index provided by ReactTable.
   */
  rowIndex: number,
  /**
   * Name of the input which triggered the function.
   * It's also the API search parameter key.
   */
  selectedInputName: keyof AnimalListProps | string,
  /**
   * Current selectedInputName value.
   * It's also the API search parameter value.
   */
  searchTerm: string,
  /**
   * ReactTable function that updates the cell value.
   */
  setInputValue: Table<HandledAnimalProps>
): Promise<void> => {
  if (!searchTerm) {
    return
  }

  await http
    .get(`/animals/`, {
      params: { [selectedInputName]: searchTerm, is_active: true },
    })
    .then(res => {
      const animal = res.data.items[0] as AnimalListProps

      const updateFields: (keyof AnimalListProps)[] = [
        'id',
        'animal_id',
        'birth_number',
        'electronic_eartag',
        'is_active',
        'birth_date',
        'birth_weight',
      ]

      if (!animal) {
        updateFields.forEach(fieldName =>
          setInputValue.options.meta?.updateData(rowIndex, fieldName, undefined)
        )

        setInputValue.options.meta?.updateData(
          rowIndex,
          selectedInputName,
          searchTerm
        )

        addToast({ message: 'O animal não foi encontrado.' })

        return
      }

      updateFields.forEach(fieldName =>
        setInputValue.options.meta?.updateData(
          rowIndex,
          fieldName,
          fieldName === 'animal_id' ? animal.id : animal[fieldName]
        )
      )

      addToast({ message: `Animal encontrado.`, type: 'success' })
    })
    .catch(() => {
      addToast({ message: 'O animal não foi encontrado.' })
    })
}

export { getAnimal }
