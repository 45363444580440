import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectItemProps } from 'components/atoms'

import { NavigateList } from 'app/core/routes/routes'
import {
  getReadAllProtocols,
  getReadProtocol,
  postCreateProtocol,
  postCreateProtocolAction,
  postDeleteProtocolAction,
} from 'app/core/services/hormonal'
import { ProtocolHook, ProtocolHookProps } from 'app/core/types/hooks'
import {
  ProtocolActionProps,
  ProtocolProps,
  ProtocolReadResponseData,
} from 'app/core/types/hormonal'
import { DEFAULT_ITEMS_PER_PAGE_LIST } from 'app/core/types/system'
import { addToast, getPlural, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

const useProtocol = ({
  protocolId,
  initDate,
  finalDate,
  page,
  size,
}: ProtocolHookProps): ProtocolHook => {
  const history = useHistory<NavigateList>()

  const [protocols, setProtocols] = useState<ProtocolReadResponseData>()
  const [protocolsDroplist, setProtocolsDroplist] = useState<SelectItemProps[]>(
    []
  )
  const [protocol, setProtocol] = useState<ProtocolProps>()
  const [isLoading, setIsLoading] = useState(false)

  const sortProtocolActionsByDay = (
    protocols: ProtocolProps[]
  ): ProtocolProps[] => {
    if (Array.isArray(protocols)) {
      const sortedProtocols = protocols.map(protocol => {
        const sortedObj = { ...protocol }
        sortedObj.actions.sort((a, b) => a.day - b.day)
        return sortedObj
      })

      return sortedProtocols
    }

    return protocols
  }

  const readAllProtocols = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    try {
      const data = await getReadAllProtocols(
        initDate || '',
        finalDate || '',
        page || 1,
        size || DEFAULT_ITEMS_PER_PAGE_LIST
      )

      const sortedProtocols = sortProtocolActionsByDay(data.items)

      const fetchedProtocols = {
        ...data,
        items: sortedProtocols,
      } as ProtocolReadResponseData

      const PROTOCOL_OPTIONS = sortedProtocols.map(protocol => ({
        label: protocol.name,
        value: protocol.id.toString(),
      }))

      if (data.total === 0) {
        addToast({
          message: Messages.PROTOCOLS_NOT_FOUND,
          type: 'warning',
        })
      }

      setProtocols(fetchedProtocols)
      setProtocolsDroplist(PROTOCOL_OPTIONS)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [initDate, finalDate, page, size])

  const readProtocol = useCallback(async (): Promise<void> => {
    if (protocolId) {
      try {
        setIsLoading(true)

        const data = await getReadProtocol(protocolId)
        const sortedProtocols = sortProtocolActionsByDay([data])
        setProtocol(sortedProtocols[0])
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    }
  }, [protocolId])

  const addProtocol = useCallback(
    async (name: string): Promise<void> => {
      if (name) {
        try {
          const data = await postCreateProtocol(name)
          addToast({
            message: Messages.PROTOCOL_CREATE_SUCCESS,
            type: 'success',
          })

          history.push(`${NavigateList.hormonalProtocolDetails}${data.id}`)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [history]
  )

  const addProtocolActions = useCallback(
    async (actions: Omit<ProtocolActionProps, 'id'>[]): Promise<void> => {
      if (protocolId && actions) {
        try {
          setIsLoading(true)

          let actionsCreated = 0

          await Promise.all(
            actions.map(async action => {
              await postCreateProtocolAction(protocolId, action)
              actionsCreated += 1
            })
          )

          if (actionsCreated > 0) {
            addToast({
              message: `${actionsCreated} ${getPlural(
                'Dia',
                actionsCreated
              )} de protocolo ${getPlural(
                'criado',
                actionsCreated
              )} com sucesso.`,
              type: 'success',
            })
          }

          readProtocol()
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [protocolId, readProtocol]
  )

  const removeProtocolAction = useCallback(
    async (actionId: string | number): Promise<void> => {
      if (protocolId && actionId) {
        try {
          setIsLoading(true)

          const data = await postDeleteProtocolAction(protocolId, actionId)

          addToast({
            message: Messages.PROTOCOL_ACTION_DELETE_SUCCESS,
            type: 'success',
          })

          setProtocol(data)
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [protocolId]
  )

  useEffect(() => {
    readAllProtocols()
    readProtocol()
  }, [readAllProtocols, readProtocol])

  return {
    protocols,
    protocolsDroplist,
    protocol,
    addProtocol,
    addProtocolActions,
    removeProtocolAction,
    isLoading,
  }
}

export { useProtocol }
