import pluralize from 'pluralize'

pluralize.addPluralRule(/l$/i, 'is')

const getPlural = (
  text: string,
  count: number,
  inclusive?: boolean,
  rule?: string | RegExp,
  ruleReplacementText?: string
): string => {
  if (rule && ruleReplacementText) {
    pluralize.addPluralRule(rule, ruleReplacementText)
  }

  return pluralize(text, count, inclusive)
}

const breedingGroupIdFormat = (groupId: number | string): string =>
  `GR ${groupId.toString().padStart(4, '000')}`

const sanitizeString = (string: string): string =>
  string
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^\w\s-]/gi, '')

const onlyAlphanumericString = (string: string): string =>
  string.replace(/\D/g, '')

export {
  breedingGroupIdFormat,
  getPlural,
  onlyAlphanumericString,
  sanitizeString,
}
