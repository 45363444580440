import React from 'react'
import { useHistory } from 'react-router-dom'

import { useSector } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'

import { SectorListTemplate } from './template'

const SectorList: React.FC = () => {
  const history = useHistory<NavigateList>()
  const { allSectors, isLoading } = useSector()

  const handleSectorCreate = (): void => history.push(NavigateList.sectorCreate)

  const handleSectorEdit = (sectorId: number): void =>
    history.push(`${NavigateList.sectorEdit}${sectorId}`)

  return (
    <SectorListTemplate
      isLoading={isLoading}
      sectors={allSectors || []}
      onCreateClick={handleSectorCreate}
      onEditClick={handleSectorEdit}
    />
  )
}

export { SectorList }
