import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useUser } from 'app/core/hooks/user'
import { NavigateParams } from 'app/core/routes/routes'

import { userColumns } from './tables'
import { UserListTemplate } from './template'

const UserList: React.FC = () => {
  const history = useHistory()
  const { users, isLoading } = useUser(true)

  const navigateTo: NavigateParams = (path, params): void =>
    history.push(path, params)

  const usersSortedByStatus = useMemo(
    () => users?.sort((a, b) => Number(b.is_active) - Number(a.is_active)),
    [users]
  )

  const table = useReactTable({
    data: usersSortedByStatus || [],
    columns: userColumns(navigateTo),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <UserListTemplate
      navigateTo={navigateTo}
      table={table}
      isLoading={isLoading}
    />
  )
}

export { UserList }
