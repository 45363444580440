import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useState } from 'react'

import { useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useImport, useMachinery } from 'app/core/hooks'
import { ImportEntity, ImportedParameterType } from 'app/core/types/system'
import { downloadFile, generateXlsxTemplate } from 'app/core/utils'

import { machineryListColumns } from './tables'
import { MachineryTabTemplate } from './template'

const MachineryTab: React.FC = () => {
  const HISTORY_ITEMS_VISIBLE = 5

  const { machineries, readAllMachinery, isLoading } = useMachinery()
  const {
    activeImport,
    importMachinery,
    handleImportFile,
    toggleModal: toggleImportModal,
    isModalOpen: isImportModalOpen,
    isLoading: isImportLoading,
    importedFiles,
    loadImportedParameters,
    downloadImportedParameterFile,
  } = useImport()

  const [globalFilter, setGlobalFilter] = useState('')
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [historyCount, setHistoryCount] = useState(HISTORY_ITEMS_VISIBLE)

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file =>
      handleImportFile(file, ImportEntity.MACHINERY),
  })

  const handleModalConfirm = useCallback(async () => {
    await importMachinery()
    await readAllMachinery()
  }, [importMachinery, readAllMachinery])

  const toggleHistoryModal = (): void => {
    setIsHistoryModalOpen(prevState => !prevState)
  }

  const handleShowMoreHistoryItems = (): void => {
    setHistoryCount(prevState => prevState + HISTORY_ITEMS_VISIBLE)
  }

  const table = useReactTable({
    data: machineries || [],
    columns: machineryListColumns(),
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const handleFileDownload = useCallback(
    async (fileName: string) => {
      downloadImportedParameterFile(fileName)
    },
    [downloadImportedParameterFile]
  )

  const sheetMenuOptions: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => openFileSelector(),
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          [
            'Nome',
            'Código',
            'Número da Frota',
            'Quilometragem atual',
            'Filial',
          ],
          ['Maquinário de exemplo', '123', 'FG001', 123000, 1004],
        ])

        downloadFile({
          data: templateFile,
          fileName: 'template-maquinarios',
        })
      },
    },
  ]

  useEffect(() => {
    loadImportedParameters(ImportedParameterType.machinery)
  }, [loadImportedParameters])

  return (
    <MachineryTabTemplate
      table={table}
      setGlobalFilter={setGlobalFilter}
      handleModalConfirm={handleModalConfirm}
      handleShowMoreHistoryItems={handleShowMoreHistoryItems}
      historyCount={historyCount}
      activeImport={activeImport}
      toggleHistoryModal={toggleHistoryModal}
      toggleImportModal={toggleImportModal}
      isHistoryModalOpen={isHistoryModalOpen}
      isImportModalOpen={isImportModalOpen}
      isImportLoading={isImportLoading}
      isLoading={isLoading}
      importedFiles={importedFiles}
      onImportFileClick={handleFileDownload}
      sheetMenuOptions={sheetMenuOptions}
    />
  )
}

export { MachineryTab }
