import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useProtocol, useSchedule, useScheduleAnimal } from 'app/core/hooks'
import { IParamProps } from 'app/core/routes/routes'
import { AnimalDrawerType, AnimalFilterProps } from 'app/core/types/animal'
import { ProtocolActionProps } from 'app/core/types/hormonal'
import { downloadFile, generateXlsxTemplate } from 'app/core/utils'

import { scheduleColumns, scheduleColumnsEditable } from '../tables'
import { ScheduleDetailsTemplate } from './template'

const ScheduleDetails: React.FC = () => {
  const { scheduleId } = useParams<IParamProps>()

  const { schedule } = useSchedule({ scheduleId })
  const { protocol } = useProtocol({
    protocolId: schedule?.hormonal_protocol_id,
  })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedAction, setSelectedAction] = useState<
    ProtocolActionProps | undefined
  >()
  const [page, setPage] = useState(1)

  const {
    animals,
    removeAnimal,
    addAnimal,
    updateAnimal,
    filterAnimals,
    filterIsActive,
    handleImportSheet,
    exportSchedules,
    failedAnimals,
    exportFailedAnimals,
    isLoading,
  } = useScheduleAnimal({
    scheduleId,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleRemoveAnimal = (animalId: number): void => {
    removeAnimal(animalId)
  }

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      if (!schedule) {
        handleToggleDrawer()

        return
      }

      const filterType = filters['filterType']
      delete filters['filterType']

      const updatedFilters = {
        ...filters,
      } as AnimalFilterProps

      filterType == AnimalDrawerType.filter
        ? filterAnimals(updatedFilters)
        : addAnimal(updatedFilters, schedule.breeding_station_id)

      handleToggleDrawer()
    },
    [filterAnimals, addAnimal, schedule]
  )

  useEffect(() => {
    if (protocol) {
      setSelectedAction(protocol.actions[0])
    }
  }, [protocol])

  const table = useReactTable({
    data: animals?.items || [],
    columns: scheduleColumns(
      animals?.items || [],
      updateAnimal,
      protocol?.actions || [],
      selectedAction,
      setSelectedAction,
      handleRemoveAnimal
    ),
    defaultColumn: scheduleColumnsEditable(selectedAction, updateAnimal),
    getCoreRowModel: getCoreRowModel(),
  })

  const handleResetFilters = useCallback(async (): Promise<void> => {
    filterAnimals({} as AnimalFilterProps)
  }, [filterAnimals])

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          [
            'Nº de Plantel',
            'Nº de Brinco Eletrônico',
            'Nº de Nascimento',
            'Score corporal',
            'Implante perdido',
            'Uso do implante',
          ],
          ['', '', '', '', 'Não', ''],
        ])

        downloadFile({
          data: templateFile,
          fileName: 'template-programacao-iatf',
        })
      },
    },
    {
      name: 'Exportar animais',
      icon: IconNames.download,
      action: (): void => {
        exportSchedules()
      },
    },
    ...(failedAnimals && failedAnimals.length > 0
      ? [
          {
            name: 'Planilha de inconsistências',
            icon: IconNames.alert,
            action: (): void => {
              exportFailedAnimals()
            },
          },
        ]
      : []),
  ]

  return (
    <ScheduleDetailsTemplate
      schedule={schedule}
      animals={animals}
      table={table}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterAnimal}
      page={page}
      setPage={setPage}
      resetFilters={handleResetFilters}
      filterIsActive={filterIsActive}
      headerSelectButtonItems={selectButtonItems}
      isLoading={isLoading}
    />
  )
}

export { ScheduleDetails }
