import { ColumnDef } from '@tanstack/react-table'

import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { UserEntity } from 'app/core/types/user'

const executersListColumns = (): ColumnDef<UserEntity, unknown>[] => [
  {
    header: 'Nome completo',
    accessorKey: 'name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de matrícula',
    accessorKey: 'registration_number',
    accessorFn: (row): string => row.registration_number?.toString() || '',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Fazenda',
    accessorKey: 'farm_name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (): JSX.Element => <></>,
  },
]

export { executersListColumns }
