import { AxiosResponse } from 'axios'

import {
  ImportHistoryResponse,
  ImportedParameterType,
} from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/'

const getImportedParameters = async (
  type: ImportedParameterType
): Promise<ImportHistoryResponse[]> => {
  const response = await http.get(`${baseUrl}imported_parameters/`, {
    params: { type },
  })
  return response.data
}

const getImportedFile = async (
  fileName: string
): Promise<AxiosResponse<Blob>> => {
  return http.get(`${baseUrl}imported_parameter_file/`, {
    params: { name: fileName },
    responseType: 'blob',
  })
}

export { getImportedParameters, getImportedFile }
