/* eslint-disable @typescript-eslint/naming-convention */
import Papa from 'papaparse'

import { AnimalFilterProps } from 'app/core/types/animal'
import {
  BreedingGroupAnimalResponseData,
  BreedingGroupCreateRequestFormatData,
  BreedingGroupFilterProps,
  BreedingGroupProps,
  BreedingGroupReadResponseData,
  BreedingGroupReportResponse,
} from 'app/core/types/breeding'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/breeding_group/'

const getReadAllBreedingGroups = async (
  filters?: BreedingGroupFilterProps,
  page?: number,
  size?: number
): Promise<BreedingGroupReadResponseData> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadBreedingGroup = async (
  groupId: string | number
): Promise<BreedingGroupProps> => {
  const url = `${baseUrl}${groupId}/`

  const response = await http.get(url)
  return response.data
}

const getReadBreedingGroupFemales = async (
  groupId: string | number,
  page: number,
  size?: number,
  filters?: Partial<AnimalFilterProps>
): Promise<BreedingGroupAnimalResponseData> => {
  const url = `${baseUrl}${groupId}/females/`

  const response = await http.get(url, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadBreedingGroupMales = async (
  groupId: string | number,
  page: number,
  size?: number,
  filters?: Partial<AnimalFilterProps>
): Promise<BreedingGroupAnimalResponseData> => {
  const url = `${baseUrl}${groupId}/males/`

  const response = await http.get(url, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const postCreateBreedingGroup = async (
  request: BreedingGroupCreateRequestFormatData
): Promise<BreedingGroupProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const postAddGroupAnimal = async (
  groupId: string | number,
  animalsId: number[]
): Promise<Record<string, string>[]> => {
  const url = `${baseUrl}${groupId}/add_animal/`

  const response = await http.post(url, animalsId)
  return response.data
}

const postRemoveBreedingGroupAnimal = async (
  groupId: string | number,
  animalsId: number
): Promise<BreedingGroupAnimalResponseData> => {
  const url = `${baseUrl}${groupId}/remove_animal/`

  const response = await http.post(url, [animalsId])
  return response.data
}

const deleteBreedingGroup = async (
  breedingGroupId: string | number
): Promise<void> => {
  const response = await http.delete(`${baseUrl}${breedingGroupId}/`)

  return response.data
}

const getBreedingGroupReport = async (
  filters: BreedingGroupFilterProps
): Promise<BreedingGroupReportResponse[]> => {
  const response = await http.get(`${baseUrl}report`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })

  const breedingGroups: BreedingGroupReportResponse[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    breedingGroups.push({
      breeding_group_id: Number(item[0]),
      breeding_group_name: item[1],
      init_date: new Date(item[2]),
      final_date: new Date(item[3]),
      is_active: item[4] === 'True',
      animal_id: item[5] !== '' ? Number(item[5]) : undefined,
      animal_sex: item[6],
      stock_number: item[7],
      electronic_eartag: item[8],
      birth_number: item[9],
    })
  }

  return breedingGroups
}

export {
  deleteBreedingGroup,
  getReadAllBreedingGroups,
  getReadBreedingGroup,
  getReadBreedingGroupFemales,
  getReadBreedingGroupMales,
  postAddGroupAnimal,
  postCreateBreedingGroup,
  postRemoveBreedingGroupAnimal,
  getBreedingGroupReport,
}
