import { Table } from '@tanstack/react-table'
import React from 'react'

import { Icon, IconNames } from 'components/atoms'
import { CustomTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { HandledAnimalProps, HandlingEntity } from 'app/core/types/handling'

type HandlingFeedbackTemplateProps = {
  handling: HandlingEntity
  table: Table<HandledAnimalProps>
  handleFinishHandling: () => void
  exportFailedHandlings: () => void
  failedHandlings: Partial<HandledAnimalProps>[]
}

const HandlingFeedbackTemplate: React.FC<HandlingFeedbackTemplateProps> = ({
  handling,
  table,
  handleFinishHandling,
  exportFailedHandlings,
  failedHandlings,
}) => {
  return (
    <>
      <BoxedLayout
        title={handling.type}
        date={handling.date}
        dateLabel="Data do Manejo"
        quantity={handling.handlings?.length}
        quantityLabel="Animais atualizados com sucesso"
        headerLargeButtonAction={handleFinishHandling}
        headerLargeButtonLabel="Finalizar"
        {...(failedHandlings.length > 0 && {
          headerButtonAction: exportFailedHandlings,
          headerButtonLabel: 'Planilha de inconsistências',
          headerButtonIcon: <Icon name={IconNames['alert']} />,
        })}
      >
        <CustomTable table={table} disableNavigation />
      </BoxedLayout>
    </>
  )
}

export default HandlingFeedbackTemplate
