import { AnimalListProps } from './animal'
import { DefaultPagination } from './system'

export type MatingRecommendationProps = {
  id: number
  date: string
  female_id: number
  female_name?: string
  female_stock_number?: string
  female_birth_number?: string
  female_birth_date?: string
  female_is_active: boolean
  male_id: number
  male_name?: string
  male_stock_number?: string
  male_birth_number?: string
  male_is_active: boolean
}

export type MatingRecommendationFilterProps = {
  animal_id: number
  animal_name: string
  birth_date_init: string
  birth_date_end: string
  sector_id: number
} & Pick<AnimalListProps, 'birth_number' | 'stock_number' | 'electronic_eartag'>

export type MatingRecommendationReadResponseData = {
  items: MatingRecommendationProps[]
} & DefaultPagination

export type MatingRecommendationCreateRequestData = {
  date: string
  female_id: number
  male_name: string
  male_id?: string
}

export type MatingRecommendationEditRequestData = Omit<
  MatingRecommendationCreateRequestData,
  'female_id'
>

export enum ModalSteps {
  EVALUATION_STEP = 1,
  RECOMMENDATION_STEP = 2,
}

export type MatingRecommendationExportResponseData = {
  id: number
  birth_number: string
  stock_number: string
  birth_date: Date
  status: boolean
  calf_birth_date?: Date
  calf_stock_number?: string
  calf_birth_number?: string
  last_evaluation_score?: number
  last_evaluation_date?: Date
  male_name: string
  male_id?: string
  male_stock_number?: string
  male_birth_number?: string
  male_birth_date?: Date
  last_recommendation_date: string
}
