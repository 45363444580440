import React, { ReactNode, useState } from 'react'

import {
  BarChart as RechartsBarChat,
  Bar,
  XAxis,
  YAxis,
  Legend,
  LabelList,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  ResponsiveContainerProps,
  XAxisProps,
  LegendProps,
  YAxisProps,
  TooltipProps,
  CartesianGridProps,
} from 'recharts'
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart'
import { Props as LabelProps } from 'recharts/types/component/Label'
import { CartesianViewBox } from 'recharts/types/util/types'

import { numberWithThousandDotSeparatorFormat } from 'app/core/utils/number'

import styles from './styles.module.scss'

type GetStackLabelListProps = { stackKey: string }

type StackLabelListProps = {
  dataKey: string
  position: LabelProps['position']
  className: string
  dy: number
}

type GetBarPropsArgs = {
  color: string
  stackKey: string
  isFirst?: boolean
  payloadKey: string
}

type BarProps = {
  dy: number
  dataKey: string
  stackId: string
  fill: string
  onMouseOver: (data: { tooltipPayload: { value: number }[] }) => void
  onMouseLeave: () => void
}

type GetContentLabelListProps = { stackKey: string; payloadKey: string }

type ContentLabelListProps = {
  dataKey: string
  position: LabelProps['position']
  className: string
  content: (props: LabelProps) => ReactNode
}

type BarChartDataPayload = Record<string, number>

type BarChartDataStacks = Record<
  string,
  { label: string; payload: BarChartDataPayload }
>

export type BarChartData = {
  label: string
  stacks: BarChartDataStacks
}[]

type BarChartProps = {
  containerProps?: Omit<ResponsiveContainerProps, 'children'>
  barChartProps?: Omit<CategoricalChartProps, 'data'>
  legendProps?: Omit<LegendProps, 'ref'>
  xAxisProps?: XAxisProps
  yAxisProps?: YAxisProps
  colors: string[]
  payloadKeys: string[]
  stackKeys: string[]
  data: BarChartData
  showLegend?: boolean
  showStackLabel?: boolean
  showContentLabel?: boolean
  colorByPayload?: boolean
  tooltipProps?: TooltipProps<number, string>
  cartesianGridProps?: CartesianGridProps
}

const getContentLabelListProps = ({
  stackKey,
  payloadKey,
}: GetContentLabelListProps): ContentLabelListProps => {
  return {
    dataKey: `stacks.${stackKey}.payload.${payloadKey}`,
    position: 'inside',
    className: styles.contentLabelList,
    content: (props): ReactNode => {
      const viewBox = props.viewBox as CartesianViewBox
      const minimumBarSize = 16
      if (
        !viewBox.x ||
        !viewBox.y ||
        !viewBox.width ||
        !viewBox.height ||
        viewBox.height < minimumBarSize
      ) {
        return null
      }
      const halfWidth = viewBox.width / 2
      const halfheight = viewBox.height / 2
      return (
        <text
          offset={props.offset}
          x={viewBox.x + halfWidth}
          y={viewBox.y + halfheight}
          textAnchor="middle"
          className={props.className}
          style={props.style}
        >
          <tspan x={viewBox.x + halfWidth} dy="0.355em">
            {numberWithThousandDotSeparatorFormat(
              Math.round(Number(props.value))
            )}
          </tspan>
        </text>
      )
    },
  }
}

const getStackLabelListProps = ({
  stackKey,
}: GetStackLabelListProps): StackLabelListProps => {
  return {
    dataKey: `stacks.${stackKey}.label`,
    position: 'bottom',
    className: styles.stackLabel,
    dy: 10,
  }
}

const renderLegend = ({
  payload,
}: {
  payload?: { color?: string; value: number }[]
}): ReactNode => (
  <div className={styles.legend}>
    {payload?.map((entry, index) => (
      <div key={`legend-${index}`} className={styles.legendContent}>
        <div
          className={styles.legendIcon}
          style={{ background: entry.color }}
        />
        <span className={styles.legendText}>{entry.value}</span>
      </div>
    ))}
  </div>
)

const BarChart: React.FC<BarChartProps> = ({
  containerProps,
  barChartProps,
  colors,
  payloadKeys,
  stackKeys,
  data,
  showLegend = true,
  showStackLabel = true,
  xAxisProps,
  yAxisProps,
  legendProps,
  showContentLabel = true,
  colorByPayload = true,
  tooltipProps,
  cartesianGridProps,
}) => {
  const [hoveredBarData, setHoveredBarData] = useState<
    { name: string; value: number; color: string } | undefined
  >()

  const getBarProps = ({
    color,
    stackKey,
    isFirst = false,
    payloadKey,
  }: GetBarPropsArgs): BarProps => ({
    dy: isFirst ? 50 : 0,
    dataKey: `stacks.${stackKey}.payload.${payloadKey}`,
    stackId: stackKey,
    fill: color,
    onMouseOver: (data): void =>
      setHoveredBarData({
        name: payloadKey,
        value: data.tooltipPayload[0].value,
        color,
      }),
    onMouseLeave: (): void => setHoveredBarData(undefined),
  })

  const renderTooltip = ({ active }: { active?: boolean }): ReactNode => {
    if (!active || !hoveredBarData) return null
    return (
      <div className={styles.tooltip}>
        <div
          className={styles.tooltipBox}
          style={{ background: hoveredBarData?.color }}
        />
        <span className={styles.tooltipText}>
          {hoveredBarData?.name}:{' '}
          <span className={styles.tooltipTextHighlight}>
            {numberWithThousandDotSeparatorFormat(
              Number(hoveredBarData?.value)
            )}
          </span>
        </span>
      </div>
    )
  }

  const {
    tickLine: xAxisTickLine = false,
    axisLine: xAxisLine = false,
    dataKey: xAxisDataKey = 'label',
    dy: xAxisDy = 45,
    className: xAxisClassName = styles.xAxis,
    ...restXAxisProps
  } = xAxisProps ?? {}

  const {
    tickLine: yAxisTickLine = false,
    axisLine: yAxisLine = false,
    label: yAxisLabel = {
      value: 'QUANTIDADE',
      angle: -90,
      position: 'insideCenter',
      dx: -50,
      className: styles.yAxisLabel,
    },
    className: yAxisClassname = styles.yAxis,
    ...yAxisRestProps
  } = yAxisProps ?? {}

  const legendDefaultWrapperStyle = {
    position: 'relative',
    marginTop: '60px',
    display: 'flex',
  } as React.CSSProperties

  const legendDefaultPayload = colorByPayload
    ? payloadKeys.map((payloadKey, index) => ({
        value: payloadKey,
        id: `payload${index}`,
        color: colors[index],
      }))
    : stackKeys.map((stackKey, index) => ({
        value: stackKey,
        id: `stack${index}`,
        color: colors[index],
      }))

  const {
    content: legendContent = renderLegend,
    wrapperStyle: legendWrapperStyle = legendDefaultWrapperStyle,
    payload: legendPayload = legendDefaultPayload,
    ...legendRestProps
  } = legendProps ?? {}

  const {
    cursor: tooltipCursor = { fill: 'transparent' },
    content: tooltipContent = renderTooltip,
    ...restTooltipProps
  } = tooltipProps ?? {}

  const { vertical: cartesianGridVertical = false } = cartesianGridProps ?? {}

  return (
    <ResponsiveContainer {...containerProps}>
      <RechartsBarChat {...barChartProps} data={data}>
        <Tooltip
          cursor={tooltipCursor}
          content={tooltipContent}
          {...restTooltipProps}
        />
        <CartesianGrid vertical={cartesianGridVertical} />
        <XAxis
          tickLine={xAxisTickLine}
          axisLine={xAxisLine}
          dataKey={xAxisDataKey}
          dy={xAxisDy}
          className={xAxisClassName}
          {...restXAxisProps}
        />
        <YAxis
          tickLine={yAxisTickLine}
          axisLine={yAxisLine}
          label={yAxisLabel}
          className={yAxisClassname}
          {...yAxisRestProps}
        />
        {stackKeys.map((stackKey, stackIndex) =>
          payloadKeys.map((payloadKey, payloadIndex) => (
            <Bar
              key={`${stackKey}-${payloadKey}`}
              {...getBarProps({
                color: colors[colorByPayload ? payloadIndex : stackIndex],
                isFirst: payloadIndex === 0,
                stackKey,
                payloadKey,
              })}
            >
              {showContentLabel && (
                <LabelList
                  {...getContentLabelListProps({
                    stackKey,
                    payloadKey,
                  })}
                />
              )}
              {showStackLabel && payloadIndex === 0 && (
                <LabelList {...getStackLabelListProps({ stackKey })} />
              )}
            </Bar>
          ))
        )}
        {showLegend && (
          <Legend
            content={legendContent}
            wrapperStyle={legendWrapperStyle}
            payload={legendPayload}
            {...legendRestProps}
          />
        )}
      </RechartsBarChat>
    </ResponsiveContainer>
  )
}

export { BarChart }
