import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useDiagnostics } from 'app/core/hooks/breeding/diagnostics'
import { IParamProps, NavigateList } from 'app/core/routes/routes'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { DiagnosticColumnsReview } from '../tables'
import { DiagnosticsReviewTemplate } from './template'

const DiagnosticsReview: React.FC = () => {
  const history = useHistory<NavigateList>()
  const { diagnosticGroupId } = useParams<IParamProps>()

  const [page, setPage] = useState(1)

  const {
    diagnostics,
    removeAnimal,
    headerInfo,
    tableRows,
    insertDiagnostic,
    isLoading,
  } = useDiagnostics({
    diagnosticGroupId,
    page,
  })

  const handleCreateDiagnostic = async (): Promise<void> => {
    if (diagnostics?.items.length === 0) {
      addToast({ message: Messages.BREEDING_DIAGNOSTICS_REQUIRED })
      return
    }

    await insertDiagnostic()

    history.push(`${NavigateList.diagnosticsFeedback}${diagnosticGroupId}`)
  }

  const columns = useMemo(
    () => DiagnosticColumnsReview(removeAnimal),
    [removeAnimal]
  )

  const table = useReactTable({
    data: tableRows,
    columns,
    getCoreRowModel: useMemo(() => getCoreRowModel(), []),
  })

  return (
    <DiagnosticsReviewTemplate
      table={table}
      headerInfo={headerInfo}
      diagnostics={diagnostics}
      handleCreateDiagnostic={handleCreateDiagnostic}
      page={page}
      setPage={setPage}
      isLoading={isLoading}
    />
  )
}

export { DiagnosticsReview }
