import { ButtonType } from 'components/atoms'
import { CustomModal } from 'components/molecules'

import { ModalType, PrecipitationProps } from 'app/core/types/precipitation'

type PrecipitationDeleteModalProps = {
  deleteMeasurementItem: (measurementId: number) => Promise<void>
  handleModal: (type: ModalType) => void
  isModalOpen: boolean
  selectedItem: PrecipitationProps | undefined
}

const PrecipitationDeleteModal: React.FC<PrecipitationDeleteModalProps> = ({
  deleteMeasurementItem,
  handleModal,
  isModalOpen,
  selectedItem,
}) => {
  const handleModalDelete = (): void => handleModal(ModalType.delete)

  const handleModalConfirm = async (): Promise<void> => {
    if (selectedItem) {
      await deleteMeasurementItem(selectedItem.id)

      handleModalDelete()
    }
  }

  return (
    <CustomModal
      modalIsOpen={isModalOpen}
      handleCloseModal={handleModalDelete}
      modalHeader={`Excluir lançamento manual`}
      modalText={`Você tem certeza que deseja excluir o lançamento?`}
      primaryButtonLabel="Excluir"
      primaryButtonAction={handleModalConfirm}
      primaryButtonType={ButtonType.destructive}
      secondaryButtonLabel="Cancelar"
      secondaryButtonAction={handleModalDelete}
    />
  )
}

export { PrecipitationDeleteModal }
